import {
    FORM_INITIAL_VALIDATION,
    FORM_DATA_VALIDATION,
    FORM_FIELD_LOSE_FOCUS,
    FORM_EMPTY_VALID
} from '../actions/types';
import Validator from '../helpers/Validator';
import {VALID, NOT_FOCUSED} from '../helpers/ValidatorTypes';

let initialState = false;
export default (state = [], action) => {
    const formId = action.payload ? action.payload.formId : -1;
    let form = formId in state ? state[formId] : {};
    switch (action.type) {
        case FORM_INITIAL_VALIDATION:
            initialState = true;
            return {
                ...state,
                [formId]: Validator.initialValidation(action.payload.form, action.payload.schema, action.payload.data)
            };
        case FORM_DATA_VALIDATION:
            if(!initialState) return state;
            return {
                ...state,
                [formId]: Validator.validate(action.payload.form, action.payload.schema, action.payload.data, action.payload.id, form)
            };
        case FORM_EMPTY_VALID:
            initialState = false;
            return {
                ...state,
                [formId]: {}
            };
        case FORM_FIELD_LOSE_FOCUS:
            form[action.payload.id] = form[action.payload.id] && form[action.payload.id].type === NOT_FOCUSED ? {type: VALID} : {type: form[action.payload.id]};
            return {
                ...state,
                [formId]: form
            };
        default:
            return state;
    }
};