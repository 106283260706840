import React, {useState} from 'react';
import {AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const getPercent = (value, total) => {
	const ratio = total > 0 ? value / total : 0;
  
  return toPercent(ratio, 2);
};

const toPercent = (decimal, fixed = 0) => {
	return `${(decimal * 100).toFixed(fixed)}%`;
};
const renderTooltipContent = (o) => {
	const { payload, label } = o;
  const total = payload.reduce((result, entry) => (result + entry.value), 0);
  
  return (
  	<div className="customized-tooltip-content">
    	<p className="total">{`${label} (Total: ${total})`}</p>
      <ul className="list">
      	{
        	payload.map((entry, index) => (
          	<li key={`item-${index}`} style={{color: entry.color}}>
            	{`${entry.name}: ${entry.value}(${getPercent(entry.value, total)})`}
            </li>
          ))
        }
      </ul>
    </div>
  );
};
const renderLegend = (props) => {
  const { payload } = props;

  return (
    <div>
      {
        payload.map((entry, index) => (
          <span key={`item-${index}`} style={index===0 ? {color: '#8884d8'} : {color: '#82ca9d'}}><FiberManualRecordIcon/>{entry.value}</span>
        ))
      }
    </div>
  );
}
function StackedAreaChart(props){
	
  	return (
    	<AreaChart width={600} height={400} data={props.data} stackOffset="expand"
            margin={{top: 10, right: 30, left: 0, bottom: 0}} >
        <XAxis dataKey="date"/>
        <YAxis tickFormatter={toPercent}/>
        <Tooltip content={renderTooltipContent}/>
        <Area type='monotone' dataKey='returned' stackId="1" stroke='#8884d8' fill='#8884d8' />
        <Area type='monotone' dataKey='new' stackId="1" stroke='#264d00' fill='#82ca9d' />
        <Legend content={renderLegend}/>
      </AreaChart>
    );
}

export default StackedAreaChart;
