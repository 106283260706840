import React, { Component }  from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form'
import { renderTextField, renderSelectField } from '../../common/MaterialUiForm'
import Grid from '@material-ui/core/Grid';
import { ToastContainer, toast } from 'react-toastify'
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css'
import DatePickerComponent from '../form/types/DatePickerComponent'
import Textarea from '../form/types/Textarea'
import IntegrationReactSelect from '../../common/IntegrationReactSelect'
import { getCollaborationsFormData } from "../../../actions/collaborationActions";
import Select2 from '../../common/Select2'
import { change } from 'redux-form';

const styles = theme => ({
  root: {
    htmlFontSize: 20,
  }
  });

  const validate = values => {
  const errors = {}
  const requiredFields = [
    'content',
    'dueDate',
    'status',
    'assignees',
    'areas',
    'description'
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'This value should not be blank.'
    }
  })

  return errors
}
class CollaborationFrom extends Component {
    constructor(props) {
     super(props);
     this.state = {
           formData: [],
       }
     }

    componentDidMount() {
         this.props.getCollaborationsFormData()
    }

     componentWillReceiveProps(nextProps) {
  
       if(nextProps.collaboration.formData){
           this.setState({
              formData: nextProps.collaboration.formData
           })
       }
    }
     changeFieldValue(id, value) {
            this.props.change(id, value);
        }

       render () {
  const { handleSubmit, pristine, reset, submitting, classes } = this.props;

 let types = [{ id: 1,  name: 'Ongoing'}, { id: 2,  name: 'Closed'}];
  return (
   <Grid container className="noPadding" direction="row" spacing={0}>
    <form onSubmit={handleSubmit} style={{display: 'flex', flexWrap: 'wrap', width: '100%'}}>
     <Grid container  direction="row" spacing={0} className="registerNotification-form">
     <Grid md={1} lg={1} item className="text-center"></Grid>
     <Grid md={11} lg={11} item><h4 className={classNames("margin-top")}><strong>Collaboration Form</strong></h4></Grid>
<Grid md={1} lg={1} item className="text-center"></Grid>
  <Grid md={5} lg={5} xs={12} item className={classes.root} style={{paddingLeft: 15, paddingRight: 15}}>
     <Field
          name="areas"
          id="outlined-dense-first"
          type='text'
          component={renderTextField}
          label="Areas of collaborations *"
          variant="outlined"
          className="textField"
        />
        <Field
         name="status"
         className={classNames("formControl")}
         id="outlined-dense-first"
         type='text'
         component={renderSelectField}
         label="Status *"
         variant="outlined"
         className="textField"
                    >
                    {types}
        </Field>
      </Grid>
      <Grid md={5} lg={5} xs={12} item className={classes.root} style={{paddingLeft: 15, paddingRight: 15}}>
         <Field
           name="dueDate"
           id="outlined-dense-first"
           type='text'
           component={DatePickerComponent}
           label="Due Date *"
           variant="outlined"
           className="textField"
         />
        </Grid>
<Grid md={1} lg={1} item className="text-center"></Grid>
<Grid md={1} lg={1} item className="text-center"></Grid>
      <Grid md={10} lg={10} xs={12} item className="text-left" style={{paddingLeft: 15, paddingRight: 15}}>
              <Field
               name="description"
               id="outlined-dense-first"
               type='text'
               component={Textarea}
               label="Description *"
               variant="outlined"
               className="textField"
             />
           {this.state.formData && typeof this.state.formData.assignees !== 'undefined' &&  <span>
            <Field
               name="assignees"
               id="outlined-dense-first"
               type='text'
               component={Select2}
               label="Assignees"
               variant="outlined"
               className="textField"
               field={this.state.formData.assignees}
               options={this.state.formData.assignees.options}

             />
              <Field
               name="contacts"
               id="outlined-dense-first"
               type='text'
               component={Select2}
               label="Contacts"
               variant="outlined"
               className="textField"
               field={this.state.formData.contacts}
               options={this.state.formData.contacts.options}

             />
              <Field
               name="organizations"
               id="outlined-dense-first"
               type='text'
               component={Select2}
               label="Organizations"
               variant="outlined"
               className="textField"
               field={this.state.formData.organizations}
               options={this.state.formData.organizations.options}
             />
             </span>}
        </Grid>
             <Grid md={12} lg={12} xs={12} item className="text-center" >
                  <button type="submit" disabled={pristine || submitting} className="button-submit">
                    {this.props.initialValues && this.props.initialValues.id ?
                             <span>Save</span>
                             :
                            <span> Submit</span>
                    }
                 </button>
                  </Grid>
        </Grid>
        </form>
        </Grid>
  );
  }
}
CollaborationFrom.propTypes = {
  classes: PropTypes.object.isRequired,
  getCollaborationsFormData: PropTypes.func.isRequired,
  collaboration:    PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  collaboration: state.collaboration
});

CollaborationFrom = withStyles(styles)(CollaborationFrom)

export default connect(mapStateToProps, {getCollaborationsFormData})(reduxForm({form: 'CollaborationFrom', enableReinitialize: true, keepDirtyOnReinitialize: true, validate})(CollaborationFrom))
