import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextTruncate from 'react-text-truncate';


class NewsItem extends Component {



    render() {
        return (
             <Grid item lg={4} md={6} sm={6} xs={12} className="text-left">
                  {this.props.newsItem &&
                <div className="project-content">
                <div className="contribution-preview">
                    <a  style={{ color: 'black'}}  href={`/news/${this.props.newsItem.id}`}>
                        <img  alt="contribution" src={this.props.newsItem.photo}/>
                        <h4>
                          <TextTruncate
                            line={1}
                            truncateText="…"
                            text={this.props.newsItem.title}
                            containerClassName="projectTitle text-center text-uppercase "/>
                        </h4>
                        <div className="category">
                        <h5 className={this.props.newsItem.categoryName}>
                          <TextTruncate
                            line={1}
                            truncateText="…"
                            text={this.props.newsItem.categoryName}
                            containerClassName="contentType text-center text-uppercase Event"/>
                        </h5>
                        </div>
                    </a>
                   
                </div>
            </div>

                    }

                   </Grid>
                );
    }
}

NewsItem.propTypes = {
    newsItem: PropTypes.object.isRequired,
};


export default connect()(NewsItem);
