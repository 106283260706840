import {
  GET_SINGLE_NEWS,
  GET_NEWS,
  NEWS_LOADING,
  GET_NEWS_CATEGORIES,
  GET_NEWS_OFFSET,
  GET_NEWS_TOTAL,
  CLEAR_NEWS
} from '../actions/types';

const initialState = {
    singleNews: {},
    news: null,
    newsCategories: [],
    loading: false,
    total: "0",
    offset: 0,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case NEWS_LOADING:
            return {
                ...state,
                loading: true
            };
            case GET_NEWS_OFFSET:
            return {
                ...state,
                offset: action.payload
            };
            case GET_NEWS_TOTAL:
            return {
                ...state,
                total: action.payload
            };
        case GET_SINGLE_NEWS:
            return {
                ...state,
                singleNews: action.payload,
                loading: false
            };

        case GET_NEWS:
            let previousNews = state.news;
            let result = null;
            if(previousNews){
                 Array.prototype.push.apply(previousNews, action.payload); 
                 result = previousNews;
            }else{
                result = action.payload;
            }
            return {
                ...state,
                news: result,
                loading: false
            };

        case GET_NEWS_CATEGORIES:
            return {
                ...state,
                newsCategories: action.payload,
                loading: false
            };
        case CLEAR_NEWS:
        return {
                ...state,
                news: null,
                loading: false
            };
        default:
            return state;
}
}
