import {
        GET_REGIONS,
        GET_COUNTRIES,
        GET_SELECTED
} from '../actions/types';

const initialState = {
    regionsFilter: null,
    countriesFilter: null,
    selectedFilter: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_REGIONS:
            return {
                ...state,
                regionsFilter: action.payload
            };
        case  GET_COUNTRIES:
            return {
                ...state,
                countriesFilter: action.payload
            };
        case  GET_SELECTED:
            return {
                ...state,
                selectedFilter: action.payload
            };

        default:
            return state;
}
}
