import React, { Component } from 'react';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import TextTruncate from 'react-text-truncate'


class FromTheSameAuthor extends Component {
render() {

const contributions = this.props.contributions;
        return (
<div className="related-contributions col-xs-12" >
    <h4 className="text-uppercase text-gray">from the same contributor</h4>
    {contributions.map((value, i) => (
            <span key={i}>
            {i < 3 &&
    <div className="col-md-4 col-sm-6" >
        <div className="contribution-preview">
            <a className="col-md-12" href={ value.href} style={{ color: 'black'}} >
                <img src={ value.photo} alt="image"/>
                <h4>
                    <TextTruncate
                        line={1}
                        truncateText="…"
                        text=  { value.projectTitle }
                        containerClassName="projectTitle text-center text-uppercase"
                        />
                </h4>
                <h5 className={classNames("contentType text-center text-uppercase", value.contentTypeName)} >{ value.contentTypeName}</h5>
            </a>

        </div>
    </div>
    }
    </span>
   ))}
</div>
        );
  }
}

export default FromTheSameAuthor;
