import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Layout from '../../../components/admin/layout/Layout'
import { getAccount, createDataAccess } from "../../../actions/accountActions";
import Paper from '@material-ui/core/Paper';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import classNames from 'classnames';
import Comments from '../comments/Comments'
import Collaborations from '../collaborations/Collaborations'
import TextTruncate from 'react-text-truncate'
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Consents from './Consents'
import DataAccess from './DataAccess'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Questionnaires from './Questionnaires'
import { getOrgQuestionnaires, getContactQuestionnaires } from '../../../actions/questionnaireActions';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: "20px 10px"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width: '100%',
    height: '100%',
    color: '#000000de'
  },
  labelData:{
    padding: "8px 17px 8px 0",
    color: "#777",
    textAlign: "right",
    width: "initial",
    fontWeight: "normal"
  },
  bigAvatar:{
    width: 100,
   height: 100,
  },
  formGroup: {
      margin: 15,
      marginLeft: 50,
      width: '60%'
  }
});

class AccountProfile extends Component {
  constructor() {
    super();
    this.state = {
       openDataAccess: false,
       email: ''
    };
  }

     componentDidMount() {
         this.props.getAccount(this.props.match.params.id)
         this.props.getOrgQuestionnaires(this.props.match.params.id)
         this.props.getContactQuestionnaires(this.props.match.params.id)
         
    }

    componentWillReceiveProps(nextProps) {

    }

    createDataAccess = () => {
        this.setState({
            openDataAccess: true
        })
    }

    closeDataAccess = () => {
        this.setState({
            openDataAccess: false,
            email: ''
        })
    }

    sendDataAccess = (e) => {
        e.preventDefault()
        this.props.createDataAccess(this.state.email, this.props.match.params.id)
        this.setState({
            openDataAccess: false,
            email: ''
        })
    }

    onChange = (e) => {
    this.setState({ email: e.target.value });
  }

  render() {
    const { classes } = this.props;
    const { account } = this.props.account;

    return (
        <Layout>
                {account &&
                   <Grid container className={classNames(classes.root)} spacing={2} direction="row" justify="flex-start"  >
                    <Grid item md={12} lg={12} xs={12} className="profile-breadcrumb">
                      <div className="profile-breadcrumb">
                        <a href={"/admin/accounts/list/"+account.listId}><i className="fa fa-fw fa-chevron-left"></i>{account.listName}</a>
                       </div>

                    </Grid>
                    <Grid item md={12} lg={12} xs={12} container spacing={2}>
                      <Grid md={6} lg={6} xs={12} item container justify="flex-start" direction="row">
                        <Paper className={classes.paper} >
                         <Grid item md={12} lg={12} xs={12} container direction="row" justify="flex-start" className="profile-information align-center">
                          <Grid lg={2} md={3} xs={4} item className="profile-pic">
                          {account.avatar ?
                            <Avatar alt={account.firstName && account.lastName} src={account.avatar} className={classes.bigAvatar} />

                              :
                           <Avatar alt={account.firstName && account.lastName} className={classes.bigAvatar}>
                            <AccountBalanceIcon color="action" />
                           </Avatar>
                           }
                           </Grid>
                            <Grid lg={8} md={6} xs={8} item>
                            {account.companyName && account.listType==1 && <h1 className="accountTitle">{ account.companyName }</h1>}
                            {account.firstName && account.lastName &&
                                   <h1 className="accountTitle">{account.title && <span style={{marginRight:15}}>{account.title}</span> }{account.firstName +' '+ account.lastName }</h1>
                            }

                            </Grid>
                            <Grid md={1} lg={1} xs={12} item>
                            <Button href={"/admin/accounts/edit/"+account.listId+"/"+account.id} variant="outlined">Edit</Button>
                            </Grid>
                          </Grid>
                          <Grid item md={12} lg={12} xs={12} container direction="row" justify="flex-start" className="profile-information">
                            <Grid md={12} lg={12} xs={12} item className="profile-desk">

                                 <div className="m-top30">
                                    {account.email &&
                                            <p><span className={classes.labelData}>Email: </span>{ account.email }<br/></p>
                                    }
                                    {account.phone &&
                                            <p><span className={classes.labelData}>Phone: </span>{ account.phone }<br/></p>
                                    }
                                     {account.website &&
                                            <p><span className={classes.labelData}>Personal Website: </span>{ account.website }<br/></p>
                                    }
                                   {account.contactEmail &&
                                            <p><span className={classes.labelData}>Contact Email: </span>{ account.contactEmail }<br/></p>
                                    }
                                    {account.contactPhone &&
                                            <p><span className={classes.labelData}>Contact Phone: </span>{ account.contactPhone }<br/></p>
                                    }
                                    {account.contactWebsite &&
                                           <p><span className={classes.labelData}>Contact Website: </span>{ account.contactWebsite }<br/></p>
                                    }
                                    {account.contactLinkedIn &&
                                           <p><span className={classes.labelData}>LinkedIn: </span>{ account.contactLinkedIn }<br/></p>
                                    }
                                    {account.contactSkype &&
                                           <p><span className={classes.labelData}>Contact Skype: </span>{ account.contactSkype }<br/></p>
                                    }
                                    {account.contactFax &&
                                           <p><span className={classes.labelData}>Contact Fax: </span>{ account.contactFax }<br/></p>
                                    }
                                    {account.contactFacebook &&
                                           <p><span className={classes.labelData}>Contact Facebook: </span>{ account.contactFacebook }<br/></p>
                                    }
                                    {account.contactTwitter &&
                                           <p><span className={classes.labelData}>Contact Twitter: </span>{ account.contactTwitter }<br/></p>
                                    }
                                    {account.department &&
                                             <p><span className={classes.labelData}>Department: </span>{ account.department }<br/></p>
                                    }
                                     {account.position &&
                                            <p><span className={classes.labelData}>Position: </span>{ account.position }<br/></p>
                                    }
                                    {account.hasContributions &&
                                     <p><span className={classes.labelData}>Has Contributions: </span>{ account.hasContributions }<br/></p>}

                                     <p><span className={classes.labelData}>Count Contributions: </span>{ account.contributionsCount }<br/></p>
                                    {account.listType==2 &&
                                        <p><span className={classes.labelData}>Organization name: </span>{ account.headquarters }<br/></p>  }
                                    {account.listType==1 &&
                                        <p><span className={classes.labelData}>Headquarters: </span>{ account.headquarters }<br/></p>  }
                                    {account.subscribeDate &&
                                     <p><span className={classes.labelData}>Subscribe Date: </span>{ account.subscribeDate }<br/></p>}
                                    {account.subscribeStatus &&
                                     <p><span className={classes.labelData}>Subscribe Status: </span>{ account.subscribeStatus }<br/></p>}
                                    {account.signatory &&
                                     <p><span className={classes.labelData}>Global Compact Signatory: </span>{ account.signatory }<br/></p>}
                                    {account.addresses && account.addresses.length > 0  &&
                                     <div>{account.addresses.map((address, i) => (
                                     <div key={i}>
                                     {address.city &&
                                            <p><span className={classes.labelData}>City:  </span>{address.city}<br/></p>
                                    }
                                    {address.country &&
                                            <p  key={i}><span className={classes.labelData}>Country:  </span>{address.country}<br/></p>
                                    }
                                    </div>
                                    ))}</div>
                                    }
                                    {account.analysisCategories &&
                                         <div>{Object.keys(account.analysisCategories).map((k, i) => (
                                            <p  key={k}><span className={classes.labelData}>{k}:  </span>{account.analysisCategories[k]}<br/></p>
                                    ))}</div>
                                     }

                                </div>
                            </Grid>
                              <Grid md={12} lg={12} xs={12} item>
                              <p>{account.listType == 2 ? <span className={classes.labelData}>Bio</span> : <span className={classes.labelData}>Organigramme:</span>}<span dangerouslySetInnerHTML={{__html: account.description}}></span></p>
                               </Grid>
                              </Grid>
                             <Grid item md={12} lg={12} xs={12} container direction="row"  justify="flex-start">
                             {account.roles && account.roles.length > 0 && account.listType === 2 &&
                             <Grid md={12} lg={12} xs={12} item>
                              <h3 className="margin-top">Focal points</h3>
                              <Table className={classNames("m-bot30 mobileTable" , classes.table)} aria-label="simple table">
                               <TableHead>
                                <TableRow>
                                  <TableCell>Organization</TableCell>
                                  <TableCell>Role</TableCell>
                                 </TableRow>
                               </TableHead>
                                <TableBody>
                                   {account.roles && account.roles.map((role, key) => (
                                      <TableRow key={key}>
                                      <TableCell>{role.org}</TableCell>
                                      <TableCell>{role.role}</TableCell>
                                      </TableRow>
                                   ))}
                                </TableBody>
                              </Table>
                             </Grid>
                             }
                             {account.orgFocalPoints && account.orgFocalPoints.length > 0 && account.listType === 1 &&
                             <Grid md={12} lg={12} xs={12} item>
                              <h3 className="margin-top">Focal points</h3>
                              <Table className={classNames("m-bot30 mobileTable" , classes.table)} aria-label="simple table">
                               <TableHead>
                                <TableRow>
                                  <TableCell>Contact</TableCell>
                                  <TableCell>Role</TableCell>
                                 </TableRow>
                               </TableHead>
                                <TableBody>
                                   {account.orgFocalPoints && account.orgFocalPoints.map((role, key) => (
                                      <TableRow key={key}>
                                      <TableCell><span dangerouslySetInnerHTML={{ __html: role.account }}></span></TableCell>
                                      <TableCell>{role.role}</TableCell>
                                      </TableRow>
                                   ))}
                                </TableBody>
                              </Table>
                             </Grid>
                             }
                             {account.contacts && account.contacts.length > 0 && account.listType === 1 &&
                             <Grid md={12} lg={12} xs={12} item>
                              <h3 className="margin-top">Contacts</h3>
                              <Table className={classNames("m-bot30 mobileTable" , classes.table)} aria-label="simple table">
                               <TableHead>
                                <TableRow>
                                  <TableCell>Name</TableCell>
                                  <TableCell>Email</TableCell>
                                 </TableRow>
                               </TableHead>
                                <TableBody>
                                   {account.contacts && account.contacts.map((contact, key) => (
                                      <TableRow key={key}>
                                      <TableCell><a href={"/crm/accounts/"+contact.id+"/profile"}>{contact.firstName} {contact.lastName}</a></TableCell>
                                      <TableCell>{contact.email}</TableCell>
                                      </TableRow>
                                   ))}
                                </TableBody>
                              </Table>
                             </Grid>
                             }
                             {account.branches && account.branches.length > 0 && account.listType === 1 &&
                             <Grid md={12} lg={12} xs={12} item>
                               <h3 className="margin-top">Branches</h3>
                               <Table className={classNames("m-bot30 mobileTable" , classes.table)} aria-label="simple table">
                                <TableHead>
                                 <TableRow>
                                   <TableCell>Name</TableCell>
                                  </TableRow>
                                </TableHead>
                                 <TableBody>
                                    {account.branches && account.branches.map((branch, key) => (
                                       <TableRow key={key}>
                                       <TableCell><a href={"/crm/accounts/"+branch.id+"/profile"}>{branch.companyName}</a></TableCell>
                                       </TableRow>
                                    ))}
                                 </TableBody>
                               </Table>
                             </Grid>
                             }
                            {account.consents && account.consents.length > 0 &&
                            <Consents/>
                            }
                            <button onClick={(() => this.createDataAccess(account.id))} className="button-primary">Create data access</button>
                            {account.dataAccess && account.dataAccess.length > 0 &&
                            <DataAccess/>
                            }
                             <Dialog
                                onClose={this.closeDataAccess}
                                aria-labelledby="simple-dialog-title"
                                open={this.state.openDataAccess}
                                maxWidth="md"
                                fullWidth
                                BackdropProps={{
                                classes: {
                                 root: classes.rootDialog
                                }
                               }}>
                                 <DialogTitle id="simple-dialog-title">Send data to: <span className="pull-right pointer" onClick={this.closeDataAccess}>
                                 <i className="far fa-times-circle"></i></span></DialogTitle>
                                 <DialogContent>
                                   <form onSubmit={this.sendDataAccess}>
                                      <input type="text" value={this.state.email} onChange={this.onChange} placeholder="Email Address" className="form-control"/>
                                      <DialogActions>
                                      <button type="submit" className="button-primary">Send</button>
                                     </DialogActions>
                                   </form>
                                   </DialogContent>
                                </Dialog>
                             <Comments id={this.props.match.params.id} type="admin/account"/>
                           </Grid>
                            </Paper>
                          </Grid>
                        <Grid md={6} lg={6} xs={12} item>
                            <Paper className={classes.paper}>
                            <Collaborations accountId={this.props.match.params.id}/>
                            {this.props.questionnaire.orgQuests && account.listType === 1 && <Questionnaires questionnaires={this.props.questionnaire.orgQuests}/>}
                            {this.props.questionnaire.contactQuests && account.listType === 2 && <Questionnaires questionnaires={this.props.questionnaire.contactQuests}/>}
                            </Paper>
                          </Grid>

                    </Grid>
                    </Grid>
                    }

        </Layout>
    );
  }
}

AccountProfile.propTypes = {
  getAccount: PropTypes.func.isRequired,
  createDataAccess: PropTypes.func.isRequired,
  account:    PropTypes.object.isRequired,
  getOrgQuestionnaires: PropTypes.func.isRequired,
  questionnaire:  PropTypes.func.isRequired,
  getContactQuestionnaires: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
 account: state.account,
 questionnaire: state.questionnaire
});

AccountProfile = withStyles(styles)(AccountProfile)

export default connect( mapStateToProps, { getAccount, createDataAccess, getOrgQuestionnaires, getContactQuestionnaires })(withRouter(AccountProfile));
