import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Form from './Form';
import Layout from '../../../components/admin/layout/Layout'
import { onSave } from "../../../actions/formActions";
import { SubmissionError } from 'redux-form'
import showAToast from '../../../helpers/showAToast'
import { getLogActivityWithGeoIp } from '../../../actions/profileActions';

const styles = theme => ({
        root: {
            flexGrow: 1,
        }
    });

class Main extends Component {
    constructor() {
        super();
        this.state = {
            formRoute: null,
            saveRoute: null
        };
    }

    componentDidMount() {
        let titleArr = this.props.title;
        titleArr.map((item, key) => titleArr[key] = item);
      //  this.props.getLogActivityWithGeoIp(titleArr.join(' '), 'GET')
        let formRoute = this.props.formRoute;
        let saveRoute = this.props.saveRoute;
        if (typeof this.props.match.params.type !== 'undefined') {
            saveRoute = saveRoute + this.props.match.params.type + '/';
        }
        if (this.props.saveRoute[this.props.saveRoute.length - 1] === '/' && typeof this.props.match.params.id !== 'undefined') {
            saveRoute = saveRoute + this.props.match.params.id;
        }
        if (typeof this.props.match.params.type !== 'undefined') {
            formRoute = formRoute + this.props.match.params.type + '/';
        }
        if (typeof this.props.match.params.id !== 'undefined') {
            formRoute = formRoute + this.props.match.params.id;
        }
        this.setState({
            saveRoute: saveRoute,
            formRoute: formRoute
        })
    }
    
 validate = (values, requiredFields) => {
   //  console.log(values)
  var errors = {}  
  requiredFields.forEach(field => {
     if (!values[field] && values[field] === 'generalTerms') {
            errors[field] = 'You have not agreed to the site terms'
        } else if (!values[field] && values[field] === 'imageTerms') {
            errors[field] = 'You have not agreed to the content terms'
        } else if (!values[field] || (Array.isArray(values[field]) && !values[field].length > 0) ) {
            errors[field] = 'required'
        } else if (typeof values[field] === 'object' && Object.entries(values[field]).length === 0) {
            errors[field] = 'required'
        }

        if (field === 'city' && !/^[a-zA-Z0-9\'\-\ ]+$/.test(values[field])) {            
            errors[field] = 'invalid';
        } 
        //for questionnaire create form - sections points must be whole number
        if(field === 'section' && Array.isArray(values[field]) && values[field].length > 0){
            values[field].map((row) => {
                var rx = new RegExp(/^\d+$/);
                if(row.points && !rx.test(row.points)){
                 errors[field] = 'invalid';
                 showAToast("Please add the section points as a whole number.", 'errorPoints', 'error')
               }
            })
        }
  })
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'Invalid email address'
  }
  return errors
}

    submit = values => {
          var requiredFields = [];
        if(this.props.formProperties['initializeFromState2'].schema && this.props.formProperties['initializeFromState2'].schema.required){
            requiredFields = this.props.formProperties['initializeFromState2'].schema.required;
        }
         var errors = this.validate(values, requiredFields)
   
         if(Object.keys(errors).length > 0){
              showAToast('Please fill in all required fields!', 'submitForm', 'error')  
             throw new SubmissionError(errors)
         }
         
        let data = {}
        var formDataField = {};
        const formData = Object.assign(formDataField, values);
        let formJson = {};
        let fields = this.props.formProperties['initializeFromState2'].form.fields;
        let isForm = false;
        if (typeof fields !== 'undefined') {
            var body = new FormData();
            Object.keys(fields).forEach((key) => {
                let field = fields[key]
                if (field.type === 'switch' && (formData[field.id] == null || formData[field.id] == false)) {
                    body.append(field.id, 0);
                    formJson[field.id] = 0;
                } else if (field.type === 'switch' && (formData[field.id] !== null || formData[field.id] !== false)) {
                    body.append(field.id, 1);
                    formJson[field.id] = 1;
                }
                if (field.id in formData) {
                    if (field.type === 'tags') {
                        let tagsArray = []
                        if (Array.isArray(formData[field.id])) {
                            formData[field.id].map(obj => (
                                        tagsArray.push(obj.value)
                                        ))
                            body.append(field.id, tagsArray);
                            formJson[field.id] = tagsArray;
                        } else {
                            console.log(field.id)
                                console.log(formData)
                            if(formData[field.id]){                                
                                body.append(field.id, formData[field.id].value);
                                formJson[field.id] = formData[field.id].value
                            }
                           
                        }

                    } else if (field.type === 'file') {
                        isForm = true;
                        let files = formData[field.id];

                        if (typeof files === 'number') {
                            body.append(field.id + '[]', files)
                        }
                        if (typeof files === 'object' && files.length > 0) {
                            files.map((file, key) => {
                                body.append(field.id + '[]', formData[field.id][key]['file'])
                            })
                        }
                    } else if (field.type === 'collection') {
                        isForm = true;
                        if (field.collectionType === 'contacts') {
                            let contacts = JSON.stringify({'contacts': formData[ field.id ]});
                            body.append(field.id, contacts);
                            formJson[field.id] = contacts;
                        } else if (field.collectionType === 'address') {
                            let addresses = JSON.stringify({'addresses': formData[ field.id ]});
                            body.append(field.id, addresses);
                            formJson[field.id] = addresses;
                        }

                    } else if (field.type === 'selectCreatable') {
                        let selectCreatable = [];
                        if (field.isMulti && Array.isArray(formData[ field.id ])) {
                            formData[ field.id ].map(selectValue => {
                                selectCreatable.push(selectValue[field.send]);
                            })
                        } else {
                            if (formData[ field.id ] && formData[ field.id ][field.send]) {
                                selectCreatable.push(formData[ field.id ][field.send]);
                            }
                        }

                        if (selectCreatable) {
                            body.append(field.id, selectCreatable);
                            formJson[field.id] = selectCreatable;
                        }
                    } else if (field.type === 'collections') {
                        body.append(field.id, JSON.stringify(formData[ field.id ]));
                        formJson[field.id] = JSON.stringify(formData[ field.id ]);
                    } else if (field.type === 'sections') {
                        body.append(field.id, JSON.stringify(formData[ field.id ]));
                        formJson[field.id] = JSON.stringify(formData[ field.id ]);
                    } else if (field.type === 'checkbox') {
                        if (formData[field.id] == null || formData[field.id] == false) {
                            body.append(field.id, 0);
                            formJson[field.id] = 0;
                        } else if (formData[field.id] !== null || formData[field.id] !== false) {
                            body.append(field.id, 1);
                            formJson[field.id] = 1;
                        }
                    } else if (field.type !== 'switch') {
                        body.append(field.id, formData[ field.id ]);
                        formJson[field.id] = formData[ field.id ];
                    }



                } else {
                    /* if(field.type === 'switch' && field.required && formData[field.id] == null){
                     body.append(field.id, false);  
                     formData[field.id] = false;
                     }*/
                }
            });

            if (isForm) {                
                data = body;
            } else {                 
                data = formJson
            }

            let redirectUrl = this.props.redirectUrl;
            if (typeof this.props.formProperties['initializeFromState2'] !== 'undefined' && typeof this.props.formProperties['initializeFromState2'].form.redirectUrl !== 'undefined') {
                redirectUrl = this.props.formProperties['initializeFromState2'].form.redirectUrl;
            }

            this.props.onSave(this.state.saveRoute, data, isForm, redirectUrl, this.props.history);

        }
    }
    render() {

        const {classes} = this.props;

        return (
                <Layout>
                    {this.state.formRoute &&
                                    <Form 
                                        onSubmit={this.submit} 
                                        form={'initializeFromState2'}
                                        formRoute={this.state.formRoute}
                                        saveRoute={this.state.saveRoute}
                                        title={this.props.title}
                                        isNew={this.props.isNew}
                                        isAdditionalId={this.props.isAdditionalId}
                                        />

                    }                 
                </Layout>
                );
    }
}

Main.propTypes = {
    onSave: PropTypes.func.isRequired,
    formProperties: PropTypes.object.isRequired,
    getLogActivityWithGeoIp: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
        formProperties: state.formProperties
    });

Main = withStyles(styles)(Main)

export default connect(mapStateToProps, {onSave, getLogActivityWithGeoIp})(withRouter(Main));
