import client from './client';
import { toast } from 'react-toastify'

import {
  SAVE_FOR_LATER_LIST,
  SAVE_FOR_LATER_LOADING,
  GET_ERRORS
} from './types';

 
export const postSaveForLater = (data) => dispatch => {    
  dispatch(setSaveForLaterLoading());
  client
    .post('api/user-setup/save/for/later', data)
    .then(res => {
       
        if(res.data.list){
      dispatch({
        type: SAVE_FOR_LATER_LIST,
        payload: res.data.list
      })
        }
        if(res.data.message){
       toast.success(res.data.message, {autoClose: 10000})
        }
  })
    .catch(err => {       
      dispatch({
        type: SAVE_FOR_LATER_LIST,
        payload: {}
      })
       
  });
};


export const setSaveForLaterLoading = () => {
  return {
    type: SAVE_FOR_LATER_LOADING
  };
};


