import React, { useState, useEffect }  from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Layout from '../../../components/admin/layout/Layout'
import {
  PieChart, Pie, Sector, Cell,
} from 'recharts';
import ReactSpeedometer from "react-d3-speedometer"
import {getUserTrackReportAdmin } from '../../../actions/profileActions'
import VerticalChart from './VerticalChart'
import Button from '@material-ui/core/Button';
import ReactToPrint, { PrintContextConsumer }  from "react-to-print";
import SimpleLineChart from './SimpleLineChart'
import SimplePieChart from './SimplePieChart'
import {DatePicker as DatePickerComponent} from 'antd';
import moment from 'moment'
import StackedAreaChart from './StackedAreaChart'

const { RangePicker } = DatePickerComponent;

const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});
function UserTrackReport(props) {
    const [start, setStart] = useState(moment().subtract(30, 'd'));
    const [end, setEnd] = useState(moment());
    const [focusedInput, setFocusedInput] = useState(false);

    useEffect(() => {
    props.getUserTrackReportAdmin(moment().subtract(30, 'd').format(), moment().format())
   }, []);

    function handleDatesChange(event) {
        setStart(event[0])
        setEnd(event[1])
        let start = event[0];
        let end = event[1];
        props.getUserTrackReportAdmin(start.format(), end.format())        
    }
  var topTenCountries = [];
  if(props.profile.userTrackReport && props.profile.userTrackReport.topTenCountries){
      props.profile.userTrackReport.topTenCountries.map(row => {         
         topTenCountries.push({name: row.name, value: parseInt(row.value) })
     })
  }
  console.log(topTenCountries)
    return (
     <Layout>
       <Grid container spacing={0} style={{background: 'white'}}>
            <Grid item md={3} lg={3} xs={6} className="text-center" style={{ margin: 15}}>
                <RangePicker
                    defaultValue={[start, end]}
                    onChange={handleDatesChange}
                    format = {'DD.MM.YYYY'}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => {
                        setFocusedInput(focusedInput)
                    }}/>
            </Grid>
            <Grid container spacing={6} className="text-right" style={{marginTop: 20 }}>
                 {props.profile.userTrackReport &&
                    <>
                <Grid item xs={12} md={6} lg={6}>
                     <h4 style={{margin: "0 0 20px 20px"}} className="text-left">Number of new registrations</h4>
                    <SimpleLineChart data={props.profile.userTrackReport.registrationByDate} keyY="registration" keyX="date"/>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                     <h4 style={{margin: "0 0 20px 20px"}} className="text-left">Total visitors</h4>
                    <SimpleLineChart data={props.profile.userTrackReport.usersTotal} keyY="users" keyX="date"/>
               </Grid>
               <Grid item xs={12} md={6} lg={6}>
                    {topTenCountries &&<div className="text-left">
                        <h4 style={{margin: "0 0 20px 20px"}}>Top ten countries of visitors coming to the website</h4>
                    <SimplePieChart data={topTenCountries}/>
                   </div>}
                </Grid>
               <Grid item xs={12} md={6} lg={6}>
                   {props.profile.userTrackReport.topTenRegions &&
                    <div className="text-left" style={{margin: 30}}>
                    <h4 style={{marginLeft: 20}}>Top regions of visitors coming to the website</h4>
                    <SimplePieChart data={props.profile.userTrackReport.topTenRegions}/>
                   </div>}
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                   {props.profile.userTrackReport.fiveMostVisitedPages &&
                           <div className="text-left" style={{margin: 30}}>
                    <h4 style={{margin: "0 0 20px 20px"}}>Five most visited pages: </h4>
                      <ul>
                          {props.profile.userTrackReport.fiveMostVisitedPages.map((page, k) => (
                                  <li key={k} className="pageAction"> {(k+1) +'. '+ page.action}: <strong>{page.count}</strong></li>
                           ))}
                      </ul>
                        </div>}
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                         {props.profile.userTrackReport.mergedReturnNewUsers &&
                           <div className="text-left" style={{margin: 30}}>
                    <h4 style={{margin: "0 0 20px 20px"}}>Returning vs. new visitors: </h4>
                        <StackedAreaChart data={props.profile.userTrackReport.mergedReturnNewUsers}/>
                        </div>}
                    </Grid>
                    </>}
            </Grid>
       </Grid>
      </Layout>
    );

}

UserTrackReport.propTypes = {
  profile: PropTypes.object.isRequired,
  getUserTrackReportAdmin: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

UserTrackReport = withStyles(styles)(UserTrackReport)

export default connect( mapStateToProps, { getUserTrackReportAdmin })(withRouter(UserTrackReport));
