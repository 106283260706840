import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import { clearCurrentProfile } from '../../actions/profileActions';
import Grid from '@material-ui/core/Grid';
import LoginForm from '../layout/LoginForm'

class ProfileActions extends Component {

constructor(props) {
    super(props);

    this.state = {
      profile: props.profileItem,
     }
  }

    onLogoutClick(e) {
    e.preventDefault();
    this.props.clearCurrentProfile();
    this.props.logoutUser();
  }


    render() {


    let membersMenu = []
    let result = []
            if(this.state.profile){
                       if(this.state.profile.roles.includes('ROLE_CONTRIBUTOR') || this.state.profile.roles.includes('ROLE_ADMIN') || this.state.profile.roles.includes('ROLE_SUPER_ADMIN')){
                          membersMenu.push(<Link to="/contributors/forum" key="1">Contributors Forum</Link>);
                        }                   
                        if( this.state.profile.roles.includes('ROLE_MEMBER') || this.state.profile.roles.includes('ROLE_ADMIN') || this.state.profile.roles.includes('ROLE_SUPER_ADMIN')){
                       membersMenu.push(<Link to="/forum" key="3">Members Forum</Link>);
                        }
                        if( this.state.profile.roles.includes('ROLE_MEMBER') || this.state.profile.roles.includes('ROLE_ADMIN') || this.state.profile.roles.includes('ROLE_SUPER_ADMIN')){
                         membersMenu.push(<Link to="/users/member" key="4">Members List</Link>);
                       }
                       if( this.state.profile.roles.includes('ROLE_MEMBER') && this.state.profile.roles.includes('ROLE_ADMIN')===false && this.state.profile.roles.includes('ROLE_SUPER_ADMIN')===false){
                         membersMenu.push(<Link to="/members/documents" key="7">Document Management</Link>);
                        }
                        if( this.state.profile.roles.includes('ROLE_ADMIN') || this.state.profile.roles.includes('ROLE_SUPER_ADMIN')){
                         membersMenu.push(<Link to="/admin/documents" key="8">Document Management</Link>);
                        }                              
                        if( this.state.profile.roles.includes('ROLE_QUESTIONNAIRE') || this.state.profile.roles.includes('ROLE_ADMIN') || this.state.profile.roles.includes('ROLE_SUPER_ADMIN')){
                           membersMenu.push(<Link to="/user/questionnaires" title="Questionnaires" key="5">Questionnaires</Link>); 
                        }
                        if( this.state.profile.roles.includes('ROLE_MEMBER')){
                           membersMenu.push(<Link to="/members/questionnaires" title="Sustainability Assessment Profile" key="15">Sustainability Assessment Profile</Link>); 
                        }
                      if(this.state.profile.roles.includes('ROLE_CONTRIBUTOR') || this.state.profile.roles.includes('ROLE_ADMIN') || this.state.profile.roles.includes('ROLE_MEMBER') || this.state.profile.roles.includes('ROLE_SUPER_ADMIN')){
                            membersMenu.push(<Link to="/contribute/my-contributions" key="10">My Contributions</Link>);
                        }
                 }

    return (
                <Grid container spacing={0} className="frontend-user-panel">
                            <Grid item container md={12} style={{padding: "0 25px"}}>
                               <Grid item md={3} sm={8} xs={12}>
                                <h1>
                                    <span className="ng-binding">{this.state.profile.firstName} {this.state.profile.lastName}</span>
                                </h1>
                                </Grid>
                                <Grid item md={6} sm={6} xs={6}>
                                  <a className="logout" href="#" onClick={this.onLogoutClick.bind(this)} target="_self">Sign out</a>
                                </Grid>
                                <Grid item md={3} sm={6} xs={6} className="pull-right">                                
                                    <span className="blue-button pull-right" style={{cursor: 'pointer'}} >                                
                                        <LoginForm role="contribute"/>
                                    </span>
                                    </Grid>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} className="frontend-user-panel-menu">
                                <div className="pull-left">
                                   <Link to="/contribute/profile">Your profile</Link>
                                   <Link to="/contribute/profile/change-password">Change password</Link>
                                </div>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} className="members-menu">
                                <div className="pull-right members-menuMobile">
                                    {membersMenu}
                                </div>
                            </Grid>
                            <div className="clearfix"></div>
                </Grid>
            );
    }
}

ProfileActions.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  clearCurrentProfile:  PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logoutUser, clearCurrentProfile })(
  ProfileActions
);
