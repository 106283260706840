import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { requestedPath } from '../../actions/authActions';

const PrivateRoute = ({ component: Component, auth,  ...rest }) => {
    
 return <Route
    {...rest}
    render={(props) => {
      if(auth.isAuthenticated === true){
       return <Component  {...rest} {...props} />
        }else{       
        rest.requestedPath(props.location.pathname)
       return <Redirect to="/login" />
        }
        }
    }
  />
  };

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
  requestedPath: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {requestedPath})(PrivateRoute);
