import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import { toast } from 'react-toastify'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';
import showAToast from '../../../helpers/showAToast';

const drawerWidth = 300;
const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: 100,
    height: "85%"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
   paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width: '100%',
  //  height: '100%',
    color: '#000000de'
  },
    extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'fixed',
    top: theme.spacing(15),
    left: theme.spacing(2),
    background: '#203648',
  }
});

class QuestNav extends Component {
  constructor() {
    super();
    this.state = {
      openMenu: false
    };
  }

     componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {

    }

     handleClickSection = (id) => event => {
        event.preventDefault()
        if(this.props.history.location.pathname.includes('/user/questionnaire/indentification/')){
            if(this.props.questionnaire.questFill.filledIdentification){
                window.location = '/questionnaire/fill/'+this.props.id+'/'+id;
            }else{
                showAToast("Please, first fill current section.", 'currentSection', 'default')
               /* toast("Please, first fill current section.", {
                        position: "top-center",
                        autoClose: 10000,
                        toastClassName: 'Toastify'})*/
            }
        }else if(this.props.questionnaire.questFill && this.props.questionnaire.questFill.section.id === id){
            showAToast("This is current section.", 'currentThis', 'default')
           /* toast("This is current section.", {
                        position: "top-center",
                        autoClose: 10000,
                        toastClassName: 'Toastify'})*/
            }else if(this.props.questionnaire.questFill && (this.props.questionnaire.questFill.submitted.includes(id) ||  this.props.questionnaire.questFill.submitted.includes(this.props.questionnaire.questFill.section.id))){
                window.location = '/questionnaire/fill/'+this.props.id+'/'+id;
            }else{
                showAToast("Please, first fill current section.", 'currentSection', 'default')
             /*   toast("Please, first fill current section. ", {
                        position: "top-center",
                        autoClose: 10000,
                        toastClassName: 'Toastify'})*/
            }
    }

    handleDrawerClose = () => {
        this.setState({
            openMenu: false
        })
    }

    handleDrawerOpen = () => {
        this.setState({
            openMenu: !this.state.openMenu
        })
    }


  render() {
      const { classes } = this.props;
      const {questFill} = this.props.questionnaire;
      var identification = this.props.history.location.pathname.includes('/user/questionnaire/indentification/')
    return (
      <Grid container spacing={0} >
        <Fab variant="extended" color="primary" aria-label="add" className={classes.fab}  onClick={() => this.handleDrawerOpen()}>
          <MenuIcon className={classes.extendedIcon} />
          Navigation
        </Fab>
        <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={this.state.openMenu}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader} >
          <IconButton style={{float: "right"}} onClick={() => this.handleDrawerClose()} >
            <ArrowBackIosIcon />
          </IconButton>
        </div>
        <Divider />
        <List style={{color: 'white'}}>
             <ListItem button key={'index'}  selected={identification}>
              <a href={"/user/questionnaire/indentification/"+this.props.id} >
              <ListItemText primary="Manufacturer/Supplier Identification"/>
              </a>
            </ListItem>
          {questFill.quest.sections && questFill.quest.sections.map((section, index) => (
            <ListItem button key={index}  selected={this.props.questionnaire.questFill.section.id === section.id && !identification}>
              <a href="#" onClick={this.handleClickSection(section.id)} >
              <ListItemText primary={section.name} />
              </a>
            </ListItem>
          ))}
        </List>
      </Drawer>
      </Grid>
    );
  }
}

QuestNav.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

QuestNav = withStyles(styles)(QuestNav)

export default connect( mapStateToProps, {  })(withRouter(QuestNav));
