import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUserContribution, postEditContribution } from '../../actions/contributionActions';
import Spinner from '../common/Spinner';
import Grid from '@material-ui/core/Grid';
import Navbar from '../layout/Navbar';
import Footer from '../layout/Footer';
import { Link } from 'react-router-dom';
import ReactDom from 'react-dom';
import ContributionEvent from './elements/contribution/ContributionEvent'
import ContributionProject from './elements/contribution/ContributionProject'
import ContributionStory from './elements/contribution/ContributionStory'
import ContributionPublication from './elements/contribution/ContributionPublication'
import ContributionTraining from './elements/contribution/ContributionTraining'
import ContributionTool from './elements/contribution/ContributionTool'
import FromTheSameAuthor from './elements/contribution/FromTheSameAuthor'
import Diff from 'react-stylable-diff';
import { config } from '../../config';

class MyContribution extends Component {

  constructor(props) {
    super(props);
    this.state = {
        height: null
    }
  }

  componentDidMount() {
    this.props.getUserContribution(this.props.match.params.id);
    this.setState({height: window.innerHeight*0.55});

  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
        this.props.getSingleNews(this.props.match.params.id);
        ReactDom.findDOMNode(this).scrollIntoView();
    }
  }

  handleClick = (value) => {

      this.props.postEditContribution(this.props.match.params.id, {button: value}, this.props.history)
  }

  render() {
    const { contribution, contributions } = this.props.contribution;
    let height = this.state.height*0.55;
 
    return(
    <div className="individual-contribution-page" >
    <Navbar/>
 <div>
             {contribution &&
            <div>

        <div className="blue-area">
        </div>
        <div className="max-width individual-contribution-page-content">

            <div className="col-md-8 text-left">
               {contribution.humanFlag &&
                        <div className="text-white human-flag" >
                        { contribution.humanFlag }
                    </div>
                       }
                  <span>
                    <div className="clearfix" style= {{ marginBottom: '5px' }}></div>
                    {contribution.isAuthor && contribution.isPendingAuthorReview &&
                            <span>
                        <button className="btn btn-sm btn-success" style={{ marginRight: 5}} onClick={value => this.handleClick('accept')}><i className="fas fa-check"></i> Accept changes</button>
                        <button className="btn btn-sm btn-danger " style={{ marginRight: 5}} onClick={value => this.handleClick('reject')}><i className="fas fa-times"></i> Reject</button>
                        <a href={'/contribute/my-contribution/edit/'+this.props.match.params.id} className="btn btn-sm btn-info"><i className="fas fa-pencil-alt"></i> Edit with changes</a>
                        </span>
                        }
               </span>

                <div className="location">
                    <br/>
                    You are here:
                    {contribution.regionsText &&
                            <span>
                        {contribution.reflection && contribution.isPendingAuthorReview ?
                            <Diff type="sentences" inputA={contribution.reflection.regionsText} inputB={contribution.regionsText}/>
                            :
                            <span style={{marginLeft: 10}}>
                                        {contribution.regionsText}
                            </span>
                        }
                     </span>
                    }
                </div>
                {contribution.reflection && contribution.isPendingAuthorReview ?
                 <h1 className="text-white text-bold">
                 <Diff type="words" inputA={contribution.reflection.projectTitle} inputB={contribution.projectTitle}/></h1>
                :
                    <h1 className="text-white text-bold">{contribution.projectTitle}</h1>
                }



                <span className="text-dark-blue-old ng-binding" >Posted on { contribution.createdAt}</span>
                <img alt="contribution" src={contribution.photo} />
                <div className="col-md-12 list-style noPadding description" >
                 {contribution.reflection && contribution.isPendingAuthorReview &&
                 <p className="description text-left" style={{backgroundColor: '#c9eed3'}}  dangerouslySetInnerHTML={{__html:contribution.reflection.description }}></p>
                 }
                    <p className="description text-left" style={{backgroundColor: '#cccc00'}} dangerouslySetInnerHTML={{__html: contribution.description}}></p>

                </div>
                {contribution.outcomes &&
                <div className="col-md-12 noPadding outcomes" ng-if="">
                    <h3 className="text-capitalize">outcomes</h3>
                 {contribution.reflection && contribution.isPendingAuthorReview &&
                 <p className="description text-left" style={{backgroundColor: '#c9eed3'}}  dangerouslySetInnerHTML={{__html:contribution.reflection.outcomes }}></p>
                 }
                    <p className="description text-left" style={{backgroundColor: '#cccc00'}} dangerouslySetInnerHTML={{__html: contribution.outcomes}}></p>
                </div>
                }
                <div className="white-box col-md-12">
                {contribution.leadOrganization &&
                    <div className="col-md-12" >
                        <h5 className="text-uppercase text-gray">lead organization</h5>
                         {contribution.reflection && contribution.isPendingAuthorReview ?
                          <div><Diff type="words" inputA={contribution.reflection.leadOrganization} inputB={contribution.leadOrganization}/></div>
                         :
                        <p>{ contribution.leadOrganization}</p>
                }
                    </div>
                    }
                    {contribution.organizationTypesText &&
                    <div className="col-md-12" >
                        <h5 className="text-uppercase text-gray">organization type</h5>
                        {contribution.reflection && contribution.isPendingAuthorReview ?
                          <div><Diff type="words" inputA={contribution.reflection.organizationTypesText} inputB={contribution.organizationTypesText}/></div>
                         :
                        <p>{ contribution.organizationTypesText}</p>
                }

                    </div>
                    }
                    {contribution.partners &&
                    <div className="col-md-12" ng-if="">
                        <h5 className="text-uppercase text-gray">partners</h5>
                        {contribution.reflection && contribution.isPendingAuthorReview ?
                          <div><Diff type="words" inputA={contribution.reflection.partners} inputB={contribution.partners}/></div>
                         :
                        <p>{ contribution.partners}</p>
                }

                        <hr/>
                    </div>
                    }
                    {contribution.budget &&
                    <div className="col-md-12">
                        <h5 className="text-uppercase text-gray">budget</h5>
                        <div><Diff type="words" inputA={contribution.reflection.budget} inputB={contribution.budget}/> { contribution.currency &&
                                <span>{contribution.currency.sign}</span>}
                        </div>

                        <hr/>
                    </div>
                    }
                    {contribution.contactName &&
                            <div className="col-md-12" >
                        <h5 className="text-uppercase text-gray">contact name</h5>
                         {contribution.isAuthor && contribution.isPendingAuthorReview ?
                            <div><Diff type="words" inputA={contribution.reflection.contactName} inputB={contribution.contactName}/> </div>
                            :
                             <div>{contribution.contactName}</div>
                     }

                        <hr/>
                    </div>
                    }
                    {contribution.contact &&
                    <div className="col-md-12" ng-if="">
                        <h5 className="text-uppercase text-gray">contact email</h5>
                        {contribution.isAuthor && contribution.isPendingAuthorReview ?
                        <div className="text-black"><Diff type="words" inputA={contribution.reflection.contact} inputB={contribution.contact}/></div>
                        :
                          <div className="text-black">{contribution.contact}</div>
                        }
                        <hr/>
                    </div>
                    }
                    {contribution.city &&
                    <div className="col-md-12">
                        <h5 className="text-uppercase text-gray">city</h5>
                        {contribution.isAuthor && contribution.isPendingAuthorReview ?
                        <div className="text-black"><Diff type="words" inputA={contribution.reflection.city} inputB={contribution.city}/></div>
                        :
                           <div className="text-black">{contribution.city}</div>
                        }
                    </div>
                    }
                </div>
            </div>
            <div className="col-md-4 text-left">
              {contribution.contentType == 0 &&
                       <ContributionProject contribution={contribution} />}
              {contribution.contentType == 1 &&
                      <ContributionEvent contribution={contribution} />}
              {contribution.contentType == 2 &&
                       <ContributionStory contribution={contribution} />}
              {contribution.contentType == 3 &&
                       <ContributionPublication contribution={contribution} />}
              {contribution.contentType == 4 &&
                       <ContributionTraining contribution={contribution} />}
              {contribution.contentType == 5 &&
                       <ContributionTool contribution={contribution} />}

            </div>
            {contributions &&
            <FromTheSameAuthor contributions={contributions}/>
            }
        </div>
         </div>
        }
    </div>
    <Footer/>
    </div>
    )

  }
}
  MyContribution.propTypes = {
    getUserContribution: PropTypes.func.isRequired,
    contribution: PropTypes.object.isRequired,
    postEditContribution: PropTypes.func.isRequired,
  };

  const mapStateToProps = state => ({
          contribution: state.contribution.contribution
  });

export default connect(mapStateToProps, { getUserContribution, postEditContribution })(MyContribution);
