import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Popup from '../../../common/Popup';


export default class PopupResources extends Component {
    render() {
        const settings = this.props.settings;
        const id = 'popupResources';
        return (
                <Popup locale={this.props.settings.locale}
                   title={ 'Documents'}
                   onClose={() => this.props.closePopup('popupResources')}>
                <ul>

                    <li className="col-sm-6 marginBottom" onClick={() => this.props.closePopup('popupResources')}>
                        <Link title="Document Management" to={'/admin/documents'}>
                           <i className="far fa-file"></i>
                            <span style={{ paddingLeft: 20 }}>Document Management</span>
                        </Link>
                    </li>
                    <li className="col-sm-6 marginBottom" onClick={() => this.props.closePopup('popupResources')}>
                        <Link title="Resources" to={'/admin/resources'}>
                           <i className="far fa-file"></i>
                            <span style={{ paddingLeft: 20 }}>Resources</span>
                        </Link>
                    </li>
                    {/* <li className="col-sm-6 marginBottom" onClick={() => this.props.closePopup('popupResources')}>
                        <Link title="Shared files" to={'/admin/documents/sharing'}>
                            <i className="far fa-file"></i>
                            <span style={{ paddingLeft: 20 }}>Shared files</span>
                        </Link>
                    </li>*/}
                     <li className="col-sm-6 marginBottom" onClick={() => this.props.closePopup('popupResources')}>
                        <Link title="Deleted files" to={'/admin/documents/deleted'}>
                           <i className="far fa-file"></i>
                            <span style={{ paddingLeft: 20 }}>Deleted files</span>
                        </Link>
                    </li>

                </ul>
            </Popup>
        )
    }
}
