import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Navbar from '../layout/Navbar';
import homepage from '../../img/homepage.png';
import Members from './Members'
import Footer from '../layout/Footer'
import News from './News'
import Sectors from '../frontend/elements/Sectors'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon';
import { config } from '../../config'
import { getLogActivityWithGeoIp } from '../../actions/profileActions';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
   paper:{
    backgroundColor: 'transparent',
    boxShadow: 'none',
  }
});


class Landing extends Component {

    componentDidMount() {
       this.props.getLogActivityWithGeoIp('Home page', 'GET', 'Website usage')
    }

render() {
    const { classes } = this.props;

return (

<div className={classes.root}>
      <Grid container>
    <div className="page-content  page-animation slideup"  >

        <div className="homepage">
        <Grid item xs={12}>
            <div className="heroshot">
                <img src={homepage} alt="background"/>
                <div className="text-area">
                    <h1 className="text-capitalize text-white text-bold">Saving Lives Sustainably</h1>
                    <h3 className="text-gray">We bring together United Nations agencies and global health financing<br/>
                        institutions, committed to introducing sustainable procurement in the<br/>
                        global health sector and beyond.</h3>
                </div>
                <div className="bounce">
                  <a href="#first-section">
                    <Icon className={classNames(classes.icon, 'fa fa-angle-down text-white')}/>
                  </a>
                </div>
            </div>
        </Grid>
        <Grid container className="homepage-about" spacing={0}>
        {/*
              <Grid item md={8} xs={10} className="center-col">
                    <h2>Why sustainable manufacturing?</h2><br/>
                    <p>With the newly adopted United Nations Sustainable Development Goals,
                        sustainable manufacturing is fully integrated in the ambitious framework and widely
                        recognized as a key element to promote sustainable and inclusive economic growth.
                        As an innovative way of doing business and creating value, sustainable manufacturing represents the creation of manufactured
                        products that use processes that minimize negative environmental impacts, conserve energy and natural resources, are safe for
                        employees, communities, and consumers and are economically sound.
                        </p><br/><br/>
                        <a className="background-lightblue blue-button text-white text-uppercase" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSfbb8mpCRlyqv6ZLXwDUHkhX_dlTv_2G2j53YQW8XXw-wFIjg/viewform">
                        Register</a>
               </Grid>*/}
              <Grid item md={8} xs={10} className="center-col">
                        <p>
                        <a className="anchor-link" id="first-section"><span>Through a transparent and inclusive engagement process</span></a>
                        and by leveraging its normative and market power, the SPHS Task Team is dedicated<br/>
                        to lowering the environmental impact of its procurement, with the aim of improving<br/> human health and well-being.

                        Due to these efforts
                        <span>3,700+ experts, institutions and companies are<br/> collaborating with us.</span>
                        The SPHS has established partnerships with UN Member States,<br/>
                        philanthropic organizations, businesses, academia and various other stakeholders.
                    </p>
               </Grid>
            </Grid>

            <Grid container className="homepage-sing-up" spacing={0}>
                  <Grid item md={8} xs={10} className="center-col element-above">
                <div>
                    <img width={150} src={config.API_URL +"/images/org/56533a.png?v1.0"} alt="sign up icon" style={{width: '150px'}}/>
                    <h1 className="text-white">Join us</h1><p>for a future in which saving lives and saving the planet are two sides<br/>
                        of the same coin. The future starts today.</p>
                    <a href="/contribute/register" className="button">sign up</a>
                </div>
                </Grid>
                <img src={config.API_URL +"/images/org/921004.png?v1.0"} alt="sign up background"/>
            </Grid>

              <div className="homepage-knowledge-practice">
              <Grid container spacing={0}>
                <Grid item md={8} xs={10}  className="center-col">
                  <Paper classes={{root:classes.paper}}>
                    <h1 className="text-black">Knowledge &amp; Practice</h1>
                    <p>Sharing knowledge and practice to advance sustainability<br/>
                      in the procurement of health commodities.<br/>
                      Please select category or
                      <a style={{color:'#0FAD91'}} href={`/knowledge-practice/search`}> browse all</a></p>
                  </Paper>
                </Grid>
              </Grid>
              </div>
              <div className="focus-area-page">
            <Sectors/>
            </div>
            <Members/>
            <News/>
              <div className="homepage-locations">
                <h2>Explore by location</h2>
                <p className="text-center text-white margin-t-15">The <a href="https://esa.un.org/MigFlows/Definition%20of%20regions.pdf" target="_blank"  style={{color: "#52b6c7"}}>United Nations Geoscheme system</a> has been used for the Region and Country mappings.</p>

                <div className="max-width">
                <Grid container
                      direction="row"
                      justify="center"

                      >
                    <Grid item md={2} xs={3} className="five-column text-left">
                        <ul>
                            <li>
                                <a href="/knowledge-practice?location%5B%5D=2">Africa</a>
                            </li>
                            <li>
                                <a href="/knowledge-practice?location%5B%5D=3">East Africa</a>
                            </li>
                            <li>
                                <a href="/knowledge-practice?location%5B%5D=24">Middle Africa</a>
                            </li>
                            <li>
                                <a href="/knowledge-practice?location%5B%5D=33">North Africa</a>
                            </li>
                            <li>
                                <a href="/knowledge-practice?location%5B%5D=41">Southern Africa</a>
                            </li>
                            <li>
                                <a href="/knowledge-practice?location%5B%5D=47">West Africa</a>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item md={2} xs={3} className="five-column text-left">
                        <ul>
                            <li>
                                <a href="/knowledge-practice?location%5B%5D=65">Americas</a>
                            </li>
                            <li>
                                <a href="/knowledge-practice?location%5B%5D=279">Caribbean</a>
                            </li>
                            <li>
                                <a href="/knowledge-practice?location%5B%5D=106">Central America</a>
                            </li>
                            <li>
                                <a href="/knowledge-practice?location%5B%5D=274">South America</a>
                            </li>
                            <li>
                                <a href="/knowledge-practice?location%5B%5D=280">North America</a>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item md={2} xs={3} className="five-column text-left">
                        <ul>
                            <li>
                                <a href="/knowledge-practice?location%5B%5D=126">Asia</a>
                            </li>
                            <li>
                                <a href="/knowledge-practice?location%5B%5D=127">Central Asia</a>
                            </li>
                            <li>
                                <a href="/knowledge-practice?location%5B%5D=133">East Asia</a>
                            </li>
                            <li>
                                <a href="/knowledge-practice?location%5B%5D=141">South Asia</a>
                            </li>
                            <li>
                                <a href="/knowledge-practice?location%5B%5D=151">South East Asia</a>
                            </li>
                            <li>
                                <a href="/knowledge-practice?location%5B%5D=163">Western Asia</a>
                            </li>

                        </ul>
                    </Grid>
                    <Grid item md={2} xs={3} className="five-column text-left">
                        <ul>
                            <li>
                                <a href="/knowledge-practice?location%5B%5D=182">Europe</a>
                            </li>
                            <li>
                                <a href="/knowledge-practice?location%5B%5D=183">Eastern Europe</a>
                            </li>
                            <li>
                                <a href="/knowledge-practice?location%5B%5D=194">Northern Europe</a>
                            </li>
                            <li>
                                <a href="/knowledge-practice?location%5B%5D=213">Southern Europe</a>
                            </li>
                            <li>
                                <a href="/knowledge-practice?location%5B%5D=230">Western Europe</a>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item md={2} xs={3} className="five-column text-left">
                        <ul>
                            <li>
                                <a href="/knowledge-practice?location%5B%5D=240">Oceania</a>
                            </li>
                            <li>
                                <a href="/knowledge-practice?location%5B%5D=242">Australia and<br/> New Zealand</a>
                            </li>
                            <li>
                                <a href="/knowledge-practice?location%5B%5D=277">Melanesia</a>
                            </li>
                            <li>
                                <a href="/knowledge-practice?location%5B%5D=251">Micronesia</a>
                            </li>
                            <li>
                                <a href="/knowledge-practice?location%5B%5D=259">Polynesia</a>
                            </li>
                        </ul>
                    </Grid>

                </Grid>
              </div>
            </div>
        </div>
        <Footer/>
    </div>
    <Navbar/>
    </Grid>
</div>


);
}
}

Landing.propTypes = {
    auth: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    getLogActivityWithGeoIp: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

Landing = withStyles(styles)(Landing)
export default connect(mapStateToProps, {getLogActivityWithGeoIp})(Landing);
