import React from 'react';
import './loader.css';

export default class GridLoader extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loaders: []
        };

        this.unmount = false;
        this.setNext = this.setNext.bind(this);
    }

    componentDidMount() {
        this.interval = this.setNext();
    }

    setNext() {
        setTimeout(() => {
            if (this.unmount) return;

            let loaders = this.state.loaders;
            if (loaders.length > 6) loaders = [];
            let pos = loaders.length % 2 === 0 ? 'even' : 'odd';
            loaders.push(<div className={'gridLoadRow'}>
                <div className={'rowCheckbox'}></div>
                <div className={'gridRow ' + pos}></div>
                <div className={'clearfix'}></div>
            </div>);

            this.setState({loaders});
            this.interval = this.setNext();
        }, 300);
    }

    componentWillUnmount() {
        this.unmount = true;
    }

    render() {
        return (
            <div style={{height: '500px'}}>
                <div className={'gridLoadRow'}>
                    <div className={'rowCheckbox'} style={{width: '15px', height: '15px', marginTop: '1px'}}></div>
                    <div className={'gridRow no-radius'}></div>
                    <div className={'clearfix'}></div>
                </div>
                {this.state.loaders.map((item, key) => <div key={key}>{item}</div>)}
            </div>
        );
    }
}