import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import logoSide from '../../img/logo-side-menu.png';
import Navbar from '../layout/Navbar';
import { withRouter } from 'react-router-dom';
import { getCurrentProfile } from '../../actions/profileActions';
import ProfileActions from '../dashboard/ProfileActions';
import Spinner from '../common/Spinner';
import ChangePasswordForm from '../forms/ChangePasswordForm'
import {changePassword} from '../../actions/authActions'

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
         this.props.getCurrentProfile();
    }

    componentWillReceiveProps(nextProps) {

    }

    submit = values => {

       let userData;
       userData = {
        current_password: values.current_password,
        plainPassword: {
          first: values.first,
          second: values.second
        }
       }
       this.props.changePassword(userData, this.props.profile.profile.id, this.props.history);
    }

    render() {

    const { profile, loading } = this.props.profile;
    let dashboardContent;
    if (profile === null) {
        dashboardContent = <Spinner />;
    }else{
        dashboardContent = <ProfileActions profileItem={profile}/>
    }


        return (
                <div className="container-fluid" >

            <div className="page-content  slide page-animation">
            {dashboardContent}
                <div className="clearfix "></div>
                <div className="changePass">
                    <div className="col-lg-3"></div>
                        <div className="col-lg-6">
                        <h3>Change password</h3>
                          <ChangePasswordForm onSubmit={this.submit} />

                        </div>

                </div>

            </div>




         <Navbar/>
        </div>



        );
    }
}

ChangePassword.propTypes = {
                getCurrentProfile: PropTypes.func.isRequired,
                auth: PropTypes.object.isRequired,
                profile: PropTypes.object.isRequired,
                changePassword: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
                profile: state.profile,
                auth: state.auth
});

export default connect(mapStateToProps, { getCurrentProfile, changePassword })(withRouter(ChangePassword));
