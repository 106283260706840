import {
    GET_TYPES,
    GET_CONTRIBUTION,
    GET_CONTRIBUTIONS,
    GET_CONTRIBUTIONS_TOTAL,
    GET_CONTRIBUTIONS_OFFSET,
    GET_CONTRIBUTION_EVENTS,
    LOAD_MORE_CONTRIBUTIONS,
    GET_CONTRIBUTION_FORM_DATA,
    GET_CONTRIBUTIONS_MAP
} from '../actions/types';

const initialState = {
    types: null,
    contribution: {},
    contributions: [],
    contributionsMap: [],
    events: [],
    total: "0",
    offset: 0,
    formData: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_TYPES:
            return {
                ...state,
                types: action.payload
            };
             case LOAD_MORE_CONTRIBUTIONS:
             let array = state.contributions  
             Array.prototype.push.apply(array, action.payload) 
            return {
                ...state,
                contributions: array
            };
        case GET_CONTRIBUTION:
            return {
                ...state,
                contribution: action.payload
            };
         case GET_CONTRIBUTIONS:
            return {
                ...state,
                contributions: action.payload
            };
        case GET_CONTRIBUTIONS_TOTAL:
            return {
                ...state,
                total: action.payload
            };
        case GET_CONTRIBUTIONS_OFFSET:
            return {
                ...state,
                offset: action.payload
            };
        case GET_CONTRIBUTION_EVENTS:
          return {
                ...state,
                events: action.payload
            };
        case GET_CONTRIBUTIONS_MAP:
            return {
                ...state,
                contributionsMap: action.payload
            };
          case GET_CONTRIBUTION_FORM_DATA:
            return {
                ...state,
                formData: action.payload
            };    
        default:
            return state;
}
}
