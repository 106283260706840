import React from 'react';
import {connect} from 'react-redux';
import {AgGridReact} from "ag-grid-react";
import 'ag-grid-community/src/styles/ag-grid.scss';
import 'ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material.scss';
import 'react-dates/lib/css/_datepicker.css';
import 'antd/dist/antd.css'
import './grid-style.css';
import '../../../css/forms.css'
import Layout from '../../../components/admin/layout/Layout'
import moment from 'moment';
import 'react-dates/initialize';
//import 'react-dates/lib/css/_datepicker.css';
import ModalConfirm from '../../common/ModalConfirm';
import {DatePicker as DatePickerComponent} from 'antd';
import {DateRangePicker, SingleDatePicker, DayPickerRangeController} from 'react-dates';
import Message from '../../common/Message';
import {
    GRID_MOUNT,
    GRID_CONFIRM_DIALOG_SHOW,
    GRID_DATA_LOADED,
    GRID_HEADER_SHOW_LOADER
} from "../../../actions/types";
import {
    onGridMount,
    onGridFetchData,
    onGridEmptyData,
    onGridApiLoad,
    onQuickFilterChange,
    onDatesChange,
    onRowSelection,
    onConfirmActionTrigger,
    onHeaderOptionSelected,
    onShowMessage,
    onHideMessage,
    onClickCalculateButtonClick
} from "../../../actions/gridActions";
import Header from "./Header";
import BulkActions from "./BulkActions";
import Columns from './Columns';
import BooleanRenderer from "./BooleanRenderer";
import InOutRenderer from "./InOutRenderer";
import CustomClassRenderer from "./CustomClassRenderer";
import CustomCellRenderer from './CustomCellRenderer';
import SingleLinkRenderer from './SingleLinkRenderer';
import ImageRenderer from './ImageRenderer';
import LoadingOverlay from './LoadingOverlay';
import GridLoader from "./GridLoader";
import HtmlRenderer from './HtmlRenderer';
import { getLogActivityWithGeoIp } from '../../../actions/profileActions';
import CustomTooltip from './CustomTooltip'
import DataTransferCellRenderer from './DataTransferCellRenderer'
import { withRouter } from 'react-router-dom'
import ConventionsCellRenderer from './ConventionsCellRenderer'
import IfCellRenderer from './IfCellRenderer'

const { RangePicker } = DatePickerComponent;




class Grid extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: '',
            type: '',
            showMessage: false,
            message: '',
            error: false,
            paginationPageSize: 100,
            startDate: window.location.pathname === 'admin/contributions' ? moment('01.01.1990','DD.MM.YYYY') : moment(this.props.gridProperties.startDate),
            endDate: moment(this.props.gridProperties.endDate)
        };

        this.apiLoad = false;
        this.gridLoad = false;
        this.dataLoaded = false;

        this.onGridReady = this.onGridReady.bind(this);
        this.quickFilterChange = this.quickFilterChange.bind(this);
        this.handleDatesChange = this.handleDatesChange.bind(this);
        this.handleSingleDateChange = this.handleSingleDateChange.bind(this);
        this.openConfirmDialog = this.openConfirmDialog.bind(this);
        this.acceptConfirmDialog = this.acceptConfirmDialog.bind(this);
        this.updateGrid = this.updateGrid.bind(this);
        this.setDefaultFilter = this.setDefaultFilter.bind(this);
        this.clearInput = this.clearInput.bind(this);
        this.onFilterModified = this.onFilterModified.bind(this);
        this.calculateTotals = this.calculateTotals.bind(this);
        this.onSortChanged = this.onSortChanged.bind(this);
        this.onCalculateClick = this.onCalculateClick.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);

        this.monthToComparableNumber = this.monthToComparableNumber.bind(this);
        this.dateComparator = this.dateComparator.bind(this);
    }

    componentDidMount() {
        
    console.log(window.location.pathname)
        this.setState({
            startDate: (window.location.pathname === '/admin/contributions' || window.location.pathname === '/admin/news') ? moment('01.01.1990','DD.MM.YYYY') : moment(this.props.gridProperties.startDate),
            endDate: moment(this.props.gridProperties.endDate)
        })
        this.updateGrid();        
    }

    updateDimensions(width) {
        let pinned = this.columnApi.getDisplayedLeftColumns();
        if(width < 767) {
          this.columnApi.setColumnsPinned(pinned, false);
        }
      }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match && prevProps.match.params.id !== this.props.match.params.id) {
            this.updateGrid();
        }
    }

    updateGrid() {
        this.gridLoad = false;
        this.dataLoaded = false;
        this.props.onLoader(true);
        this.props.onEmptyData();
        if (this.props.title) {
            let titleArr = this.props.title;
            if(Array.isArray(titleArr)){
            titleArr.map((item, key) => titleArr[key] = item);
            document.title = titleArr.join(' ');
            titleArr = titleArr.join(' ');
            }else{
                document.title = titleArr
            }
          //  this.props.getLogActivityWithGeoIp(titleArr, 'GET');
        }

        let gridRoute = this.props.gridRoute;
        let gridDataRoute = this.props.gridDataRoute;
        if(this.props.match){
            const id = this.props.match.params.id;
            if (id) {
                gridRoute = gridRoute + id;
                gridDataRoute = gridDataRoute + id;
            }
        }

        this.props.onMount({gridRoute, gridDataRoute});
        this.props.onGridMount(gridRoute)
            .then(() => {
                this.gridLoad = true;
                if (this.gridLoad && this.apiLoad) {
                    this.setDefaultFilter();
                }
                if (this.gridLoad && this.dataLoaded) {
                    this.props.onLoader(false);
                }
            });

        if (gridDataRoute) {
            let dates = "";
            let start = moment().subtract(31, 'd');
            if(gridDataRoute.includes('contributions-list') || gridDataRoute.includes('news-list')){
                start = moment('01.01.1990','DD.MM.YYYY');
            }
            let end = moment().add(1, 'd');
            this.props.onDatesChange(start, end);
        this.setState({
            startDate: start,
            endDate: end
        })
            start = start.year() + '-' + (start.month() + 1) + '-' + start.date();
            end = end.year() + '-' + (end.month() + 1) + '-' + end.date();
            dates = '?startDate=' + start + '&endDate=' + end;

            this.props.onGridFetchData(gridDataRoute + dates).then(() => {
                this.dataLoaded = true;
                if (this.gridLoad && this.dataLoaded) {
                    this.props.onLoader(false);
                }
            });
        }
    }


    firstDataRendered  = () => {
        this.setDefaultFilter();
    }

    setDefaultFilter(selectedFilter = false) {
        const header = this.props.gridProperties.header;
        let toCheck = selectedFilter ? selectedFilter : header ? header.selectedFilter : null;
        if (header && toCheck) {
            header.options.forEach(option => {
                if (option.label === toCheck) {
                    this.props.onHeaderOptionSelected(option);
                }
            });
        }
    }

    onGridReady(params) {
        this.props.onGridApiLoad(params.api, params.columnApi, this.props.gridProperties.header)
            .then(() => {
                this.apiLoad = true;
                if (this.gridLoad && this.apiLoad) {
                    this.setDefaultFilter();
                }
            });
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.setState({data: this.props.data});
        this.setState({pinnedRows: this.props.pinnedRows});
        this.updateDimensions(window.innerWidth)
    }

    quickFilterChange(val) {
        this.props.onQuickFilterChange(val);
    }

    onSortChanged(){
        let sortedCols = [];
        this.columnApi.columnController.gridColumns.map(col => {
            if(col.sort){
                //sortedCols[0]['id'] = col.colId;
                //sortedCols[0]['sort'] = col.sort;
                sortedCols.push(col.colId);
                sortedCols.push(col.sort);
            }
        });
        this.setState({sortedCols: sortedCols});
    }

    handleDatesChange(event) {
        let start = event[0];
        let end = event[1];
        this.setState({
            startDate: start,
            endDate: end
        })
        this.props.onDatesChange(start, end);
        start = start.year() + '-' + (start.month() + 1) + '-' + start.date();
        end = end.year() + '-' + (end.month() + 1) + '-' + end.date();

        let selectedFilter = this.props.gridProperties.header.selectedFilter;
        this.props.onGridFetchData(this.props.gridDataRoute + '?startDate=' + start + '&endDate=' + end)
            .then(() => {
                this.setDefaultFilter(selectedFilter);
            });
    }

    onCalculateClick(){
        let cols = this.columnApi.getAllDisplayedColumns();
        let visibleColumns = {};
        let i=0;
        cols.map((value) => {
            if(value.colDef.isDimension === true){
                visibleColumns[i] = [];
                visibleColumns[i].push(value.colDef.field);
                visibleColumns[i].push(value.colDef.headerName);
                visibleColumns[i].push(value.colDef.type);

                i++;
            }
        });
        let route = this.props.gridDataRoute+this.props.match.params.id+'/calculateTotal';
        this.props.onCalculateButtonClick(route, this.state.filterOptions, this.props.gridProperties.startDate, this.props.gridProperties.endDate, this.props.gridProperties.quickFilter, this.state.sortedCols, visibleColumns);

    }

    calculateTotals(data){
        this.setState({pinnedRows: data});
    }

    handleSingleDateChange(event) {

        let date = event;

        date = date.year() + '-' + (date.month() + 1) + '-' + date.date();

        let selectedFilter = this.props.gridProperties.header.selectedFilter;
        this.props.onGridFetchData(this.props.gridProperties.gridDataRoute + '?endDate=' + date)
            .then(() => {
                this.setDefaultFilter(selectedFilter);
            });
    }

    openConfirmDialog() {
        this.props.onConfirmDialogShow();
    }

    acceptConfirmDialog() {
        this.props.onConfirmActionTrigger(this.props.gridProperties.confirm.payload)
       /* .then((response) => {
        console.log(response)
            if(typeof response !== 'undefined'){
                if ('errors' in response) {
                    this.props.showMessage(response.errors, true);
                    setTimeout(() => this.props.hideMessage(), 5000);
                }
                else {
                    this.props.showMessage(response.success, false);
                    setTimeout(() => this.props.hideMessage(), 5000);
                }
              }
            })*/

    }

    onFilterModified(){
            this.setState({filterOptions: false});
            var gridFilters = this.gridApi.filterManager.allFilters;
            let filters = Object.entries(gridFilters);

            var filterOptions = [];

            filters.map((value) => {
                var items = {'id': value[0], 'filter': this.gridApi.getFilterInstance(value[0]).filter, 'filterText' : this.gridApi.getFilterInstance(value[0]).filterText};
                filterOptions.push(items);
            })

           this.setState({filterOptions: filterOptions});
    }

    clearInput() {
        this.refs.searchString.value = '';
        this.quickFilterChange('');
    }

    stringComparator(string1, string2) {
        if (string1 < string2)
          return -1;
        if (string1 > string2)
          return 1;
        return 0;
    }

    numberComparator(number1, number2) {

        if (number1 === null && number2 === null) {
            return 0;
        }
        if (number1 === null) {
            return -1;
        }
        if (number2 === null) {
            return 1;
        }
        return number1 - number2;
    }

    dateComparator(date1, date2) {
        var date1Number = this.monthToComparableNumber(date1);
        var date2Number = this.monthToComparableNumber(date2);

        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }

    monthToComparableNumber(date) {
        if (date === undefined || date === null || date.length !== 10) {
            return null;
        }
        var yearNumber = date.substring(6, 10);
        var monthNumber = date.substring(3, 5);
        var dayNumber = date.substring(0, 2);
        var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
        return result;
    }

    render() {
        const customRenderers = {
            booleanRenderer: BooleanRenderer,
            inOutRenderer: InOutRenderer,
            customClassRenderer: CustomClassRenderer,
            linksRenderer: CustomCellRenderer,
            singleLinkRenderer: SingleLinkRenderer,
            imageRenderer: ImageRenderer,
            customLoadingOverlay: LoadingOverlay,
            htmlRenderer: HtmlRenderer,
            customTooltip: CustomTooltip,
            dataTransferCellRenderer: DataTransferCellRenderer,
            conventionsCellRenderer: ConventionsCellRenderer,
            ifCellRenderer: IfCellRenderer
        };

        const gridProperties = this.props.gridProperties;

        const columns = gridProperties.columns ? gridProperties.columns.map(col => {
            if(col.comparator === 'date') {
                col.comparator = this.dateComparator;
            }
            if(col.comparator === 'number') {
                col.comparator = this.numberComparator;
            }
            if(col.comparator === 'string') {
                col.comparator = this.stringComparator;
            }

                return col;
        }) : [];

        const settings = []//this.props.settings;

        return <Layout>
        <div id="ag-grid-div">

            {
                gridProperties.confirm && gridProperties.confirm.show &&
                <ModalConfirm onClose={this.openConfirmDialog}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div>
                                <div className="text-center">

                                    <h3>
                                        Are you sure?
                                    </h3>
                                    <button className="button-outlines green text-center"
                                            onClick={this.openConfirmDialog}>
                                        No
                                    </button>
                                    <button className="button-primary-edited text-center btn-ok"
                                            onClick={this.acceptConfirmDialog}>
                                       Yes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </ModalConfirm>
            }

            {/*show header*/}
            <Header title={document.title} gridDataRoute={this.props.gridDataRoute}/>

            {/*show grid*/}
                <div>
                    <div className="grid-panel col-md-12 col-xs-12">
                        <div className="col-md-12 col-xs-12" style={{'height': '100%', 'width': '100%'}}>
                            {
                                gridProperties.bulkActions &&
                                gridProperties.bulkActions.map((bulk, key) => {
                                    return <div className="col-md-4 col-xs-3 col-lg-3 bulk-container" key={key}
                                    >
                                        <BulkActions bulk={bulk} sortedCols={this.state.sortedCols}  filterOptions={this.state.filterOptions} />
                                    </div>
                                })
                            }

                            <div className="col-md-5 col-xs-3 col-lg-3" style={{marginTop: 23, marginLeft: 15}}>
                                {
                                    this.props.gridProperties.selectedRowsCount > 0 &&
                                    <div className="col-md-12 no-gutter">
                                        <span className={this.props.gridProperties.selectedRowsCount > 0 ? "selected-rows-count" : ''}
                                              onClick={() => this.props.onRowSelection(true)}>
                                            {this.props.gridProperties.selectedRowsCount}&nbsp;
                                            {(this.props.gridProperties.selectedRowsCount == 1 ? 'record' : 'records') + ' selected'}
                                            {
                                                this.props.gridProperties.selectedRowsCount > 0 ? <sup>X</sup> : ''
                                            }
                                        </span>
                                    </div>
                                }
                            </div>

                            {/*show datepicker*/}
                            {
                                gridProperties.options && gridProperties.options.enableDatepicker &&
                                <div className="date-range col-md-3 col-xs-12">
                                    <RangePicker
                                        allowEmpty={true}
                                        value={[this.state.startDate, this.state.endDate]}
                                        onChange={this.handleDatesChange}
                                        format = {'DD.MM.YYYY'}
                                        focusedInput={this.state.focusedInput}
                                        onFocusChange={(focusedInput) => {
                                            this.setState({focusedInput})
                                        }}

                                    />
                                </div>
                            }
                            {
                                gridProperties.options && gridProperties.options.enableSingleDatepicker &&
                                <div className="date col-md-3 col-xs-12">
                                    <DatePickerComponent
                                        defaultValue={moment(this.props.gridProperties.endDate)}
                                        onChange={this.handleSingleDateChange}
                                        format = {'DD.MM.YYYY'}
                                        focusedInput={this.state.focusedInput}
                                        onFocusChange={(focusedInput) => {
                                            this.setState({focusedInput})
                                        }}
                                    />
                                </div>
                            }

                            {
                                gridProperties.options && gridProperties.columns && gridProperties.options && gridProperties.options.enableColumns &&
                                <div className="pull-right columns-container">
                                    <Columns columns={gridProperties.columns}
                                             visibleColumns={gridProperties.visibleColumns}/>
                                </div>
                            }

                            {   gridProperties.options && gridProperties.options.showCalculateButton &&
                                <div className="pull-right columns-container">
                                    <div className="columns-button">
                                        <div className="columns-button-open">
                                            <a href="#">
                                                <i className="fa fa-refresh"
                                                           aria-hidden="true" onClick={this.onCalculateClick}></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/*show quick filter*/}
                            {
                                gridProperties.options && gridProperties.options.enableQuickFilter &&

                                <div className="search-field-container col-md-4 col-xs-10 pull-right">
                                    <div>
                                        <div className="search-field pull-left">
                                            <i className="fa fa-search"
                                               aria-hidden="true"></i>
                                            <input type="text"
                                                   ref="searchString"
                                                   onChange={(e) => this.quickFilterChange(e.target.value)}
                                                   className="search-field-input"/>
                                            <span onClick={this.clearInput}><i className="fa fa-times" aria-hidden="true"></i></span>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>

                        {
                            this.props.data && !this.props.gridProperties.showLoader &&
                            <div className="ag-theme-material table-inbox" style={{minWidth: '100%'}}>
                                <AgGridReact
                                    columnDefs={columns ? columns : []}
                                    rowData={this.props.data}
                                    rowSelection={'multiple'}
                                    sortable={true}
                                    onSortChanged={this.onSortChanged}
                                    filter={true}
                                    pinnedBottomRowData={this.props.pinnedRows}
                                    pagination={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    resizable={true}
                                    suppressRowClickSelection={true}
                                    frameworkComponents={customRenderers}
                                    loadingOverlayComponent={'customLoadingOverlay'}
                                    onGridReady={this.onGridReady}
                                    enableCellTextSelection={true}
                                    onSelectionChanged={() => this.props.onRowSelection()}
                                    quickFilterText={gridProperties.quickFilter}
                                    onFilterModified={this.onFilterModified}
                                    onFirstDataRendered={this.firstDataRendered}
                                    tooltipShowDelay={0}
                                    reactNext={true}
                                     defaultColDef={{
                                        sortable: true,
                                        filter: true,
                                        resizable: true,
                                   //     tooltipComponent: 'customTooltip'
                                      }}
                                    localeText={{
                                        page: 'Page',
                                        to:  'to',
                                        of:  'of',
                                    }}
                                >
                                </AgGridReact>
                            </div>
                        }
                        {
                            this.props.gridProperties.showLoader && <GridLoader/>
                        }
                    </div>
                    <div style={{position: "absolute", top: "0px", left: "0px"}}>

                    </div>
                </div>
        </div>
        </Layout>
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    gridProperties: state.gridProperties,
    data: state.gridData.data,
    pinnedRows: state.gridData.pinnedRows
});

const mapDispatchToProps = dispatch => ({
    getLogActivityWithGeoIp: (action, method) => dispatch(getLogActivityWithGeoIp(action, method)),
    onMount: (payload) => dispatch({type: GRID_MOUNT, payload}),
    onGridMount: (route) => dispatch(onGridMount(route)),
    onQuickFilterChange: (value) => dispatch(onQuickFilterChange(value)),
    onDatesChange: (startDate, endDate, route) => dispatch(onDatesChange(startDate, endDate, route)),
    onRowSelection: (clear) => dispatch(onRowSelection(clear)),
    onGridApiLoad: (gridApi, gridColumnApi, header) => dispatch(onGridApiLoad(gridApi, gridColumnApi, header)),
    onConfirmDialogShow: () => dispatch({type: GRID_CONFIRM_DIALOG_SHOW}),
    onConfirmActionTrigger: (action) => dispatch(onConfirmActionTrigger(action)),
    onGridFetchData: (route) => dispatch(onGridFetchData(route)),
    showMessage: (text, isError) => dispatch(onShowMessage(text, isError)),
    hideMessage: () => dispatch(onHideMessage()),
    onHeaderOptionSelected: (option) => dispatch(onHeaderOptionSelected(option)),
    onEmptyData: () => dispatch(onGridEmptyData()),
    onLoader: (value) => dispatch({
            type: GRID_HEADER_SHOW_LOADER,
            payload: value
    }),
    onCalculateButtonClick: (route, filter, startDate, endDate, quickFilter, sortedCols, visibleColumns) => dispatch(onClickCalculateButtonClick(route, filter, startDate, endDate, quickFilter, sortedCols, visibleColumns))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Grid));
