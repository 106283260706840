export default function getFormRoutes() {
    return [
        {
            title: ['Analysis Category', '›', 'New'],
            path: '/admin/create/category',
            saveRoute: '/api/admin/analysis-categories/create/',
            formRoute: '/api/admin/analysis-category/data/',
            redirectUrl: '/admin/analysis-categories',
            isNew: true
        },
        {
            title: ['Analysis Category', '›', 'Edit'],
            path: '/admin/category/:id/edit',
            saveRoute: '/api/admin/analysis-categories/create/',
            formRoute: '/api/admin/analysis-category/data/',
            redirectUrl: '/admin/analysis-categories',
            isNew: true
        },
        {
            title: ['Analysis Code', '›', 'New'],
            path: '/admin/create/codes',
            saveRoute: '/api/admin/analysis-codes/create/',
            formRoute: '/api/admin/analysis-codes/data/',
            redirectUrl: '/admin/analysis-codes',
            isNew: true
        },
        {
            title: ['Analysis Code', '›', 'Edit'],
            path: '/admin/codes/:id/edit',
            saveRoute: '/api/admin/analysis-codes/create/',
            formRoute: '/api/admin/analysis-codes/data/',
            redirectUrl: '/admin/analysis-codes',
            isNew: true
        },
        {
            title: ['News', '›', 'New'],
            path: '/admin/news/create',
            saveRoute: '/api/admin/news-form/create/',
            formRoute: '/api/admin/news-form/data/',
            redirectUrl: '/admin/news',
            isNew: true
        },
        {
            title: ['News', '›', 'Edit'],
            path: '/admin/news/edit/:id',
            saveRoute: '/api/admin/news-form/create/',
            formRoute: '/api/admin/news-form/data/',
            redirectUrl: '/admin/news',
            isNew: false
        },
         {
            title: ['Authors', '›', 'New'],
            path: '/admin/news-authors/create',
            saveRoute: '/api/admin/authors-form/create/',
            formRoute: '/api/admin/authors-form/data/',
            redirectUrl: '/admin/news-authors',
            isNew: true
        },
        {
            title: ['Authors', '›', 'Edit'],
            path: '/admin/news-authors/edit/:id',
            saveRoute: '/api/admin/authors-form/create/',
            formRoute: '/api/admin/authors-form/data/',
            redirectUrl: '/admin/news-authors',
            isNew: true
        },
         {
            title: ['News Categories', '›', 'New'],
            path: '/admin/news-categories/create',
            saveRoute: '/api/admin/categories-form/create/',
            formRoute: '/api/admin/categories-form/data/',
            redirectUrl: '/admin/news-categories',
            isNew: true
        },
        {
            title: ['News Categories', '›', 'Edit'],
            path: '/admin/news-categories/edit/:id',
            saveRoute: '/api/admin/categories-form/create/',
            formRoute: '/api/admin/categories-form/data/',
            redirectUrl: '/admin/news-categories',
            isNew: true
        },
         {
            title: ['User', '›', 'New'],
            path: '/admin/users/create',
            saveRoute: '/api/admin/users-form/create',
            formRoute: '/api/admin/users-form/data/',
            redirectUrl: '/admin/users/active',
            isNew: true
        },
        {
            title: ['User', '›', 'Edit'],
            path: '/admin/users/edit/:id',
            saveRoute: '/api/admin/users-form/edit/',
            formRoute: '/api/admin/users-form/data/',
            redirectUrl: '/admin/users/active',
            isNew: true
        },
         {
            title: ['Member', '›', 'New'],
            path: '/admin/members/create',
            saveRoute: '/api/admin/members-form/create',
            formRoute: '/api/admin/members-form/data/',
            redirectUrl: '/admin/members',
            isNew: true
        },
        {
            title: ['Member', '›', 'Edit'],
            path: '/admin/members/edit/:id',
            saveRoute: '/api/admin/members-form/create/',
            formRoute: '/api/admin/members-form/data/',
            redirectUrl: '/admin/members',
            isNew: true
        },
         {
            title: ['Sdg', '›', 'New'],
            path: '/admin/sdgs/create',
            saveRoute: '/api/admin/sdgs-form/create',
            formRoute: '/api/admin/sdgs-form/data/',
            redirectUrl: '/admin/sdgs',
            isNew: true
        },
        {
            title: ['Sdg', '›', 'Edit'],
            path: '/admin/sdgs/edit/:id',
            saveRoute: '/api/admin/sdgs-form/create/',
            formRoute: '/api/admin/sdgs-form/data/',
            redirectUrl: '/admin/sdgs',
            isNew: true
        },
        {
            title: ['Focus area', '›', 'New'],
            path: '/admin/focus-areas/create',
            saveRoute: '/api/admin/focus-areas-form/create',
            formRoute: '/api/admin/focus-areas-form/data/',
            redirectUrl: '/admin/focus-areas',
            isNew: true
        },
        {
            title: ['Focus area', '›', 'Edit'],
            path: '/admin/focus-areas/edit/:id',
            saveRoute: '/api/admin/focus-areas-form/create/',
            formRoute: '/api/admin/focus-areas-form/data/',
            redirectUrl: '/admin/focus-areas',
            isNew: true
        },
         {
            title: ['Location', '›', 'New'],
            path: '/admin/locations/create',
            saveRoute: '/api/admin/regions-form/create/',
            formRoute: '/api/admin/regions-form/data/',
            redirectUrl: '/admin/locations',
            isNew: true
        },
        {
            title: ['Location', '›', 'Edit'],
            path: '/admin/locations/edit/:id',
            saveRoute: '/api/admin/regions-form/create/',
            formRoute: '/api/admin/regions-form/data/',
            redirectUrl: '/admin/locations',
            isNew: true
        },
       
        {
            title: ['Email Template', '›', 'New'],
            path: '/admin/create/templates-email',
            saveRoute: '/api/admin/template-form/email/create',
            formRoute: '/api/admin/template-form/email/data/',
            redirectUrl: '/admin/templates/emails',
            isNew: true
        },
        {
            title: ['Email Template', '›', 'Edit'],
            path: '/admin/templates-emails/:id/edit',
            saveRoute: '/api/admin/template-form/email/create/',
            formRoute: '/api/admin/template-form/email/data/',
            redirectUrl: '/admin/templates/emails',
            isNew: true
        },
        {
            title: ['Email Template', '›', 'Edit'],
            path: '/admin/accounts/create/1/:id/edit',
            saveRoute: '/api/admin/accounts/create/',
            formRoute: '/api/admin/accounts/data/',
            redirectUrl: '/admin/accounts/1',
            isNew: true
        },       
        {
            title: ['List', '›', 'Edit'],
            path: '/admin/account-list/edit/:id',
            saveRoute: '/api/admin/account-list-form/create/',
            formRoute: '/api/admin/account-list-form/data/',
            redirectUrl: '/admin/account-lists',
            isNew: true
        },
           {
            title: ['Notification', '›', 'New'],
            path: '/admin/notifications/create',
            saveRoute: '/api/admin/notifications-form/create/',
            formRoute: '/api/admin/notifications-form/data/',
            redirectUrl: '/admin/notifications',
            isNew: true
        },
        {
            title: ['Notification', '›', 'Edit'],
            path: '/admin/notifications/edit/:id',
            saveRoute: '/api/admin/notifications-form/create/',
            formRoute: '/api/admin/notifications-form/data/',
            redirectUrl: '/admin/notifications',
            isNew: false
        },
        {
            title: ['Help', '›', 'New'],
            path: '/help/create',
            saveRoute: '/api/admin/help/create/',
            formRoute: '/api/admin/help/data',
            redirectUrl: '/help',
            isNew: true
        },
        {
            title: ['Help', '›', 'Edit'],
            path: '/help/edit/:id',
            saveRoute: '/api/admin/help/create/',
            formRoute: '/api/admin/help/data/',
            redirectUrl: '/help',
            isNew: true
        },
       
        {
            title: ['Questionnaire', '›', 'Send'],
            path: '/admin/questionnaire/send/:id',
            saveRoute: '/api/admin/qustionnaire/send/organization/',
            formRoute: '/api/admin/qustionnaire/send/form/',
            redirectUrl: '/admin/accounts/list/2',
            isNew: true
        },
        {
            title: ['Products', '›', 'New'],
            path: '/admin/products/create',
            saveRoute: '/api/admin/products-form/create/',
            formRoute: '/api/admin/products-form/data/',
            redirectUrl: '/admin/products',
            isNew: true
        },
        {
            title: ['Products', '›', 'Edit'],
            path: '/admin/products/edit/:id',
            saveRoute: '/api/admin/products-form/create/',
            formRoute: '/api/admin/products-form/data/',
            redirectUrl: '/admin/products',
            isNew: true
        },
        {
            title: ['Products Category', '›', 'New'],
            path: '/admin/products-category/create',
            saveRoute: '/api/admin/products-category-form/create/',
            formRoute: '/api/admin/products-category-form/data/',
            redirectUrl: '/admin/products-category',
            isNew: true
        },
        {
            title: ['Products Category', '›', 'Edit'],
            path: '/admin/products-category/edit/:id',
            saveRoute: '/api/admin/products-category-form/create/',
            formRoute: '/api/admin/products-category-form/data/',
            redirectUrl: '/admin/products-category',
            isNew: true
        },
         {
            title: ['Measures', '›', 'New'],
            path: '/admin/measures/create',
            saveRoute: '/api/admin/measures-form/create/',
            formRoute: '/api/admin/measures-form/data/',
            redirectUrl: '/admin/measures',
            isNew: true
        },
        {
            title: ['Measures', '›', 'Edit'],
            path: '/admin/measures/edit/:id',
            saveRoute: '/api/admin/measures-form/create/',
            formRoute: '/api/admin/measures-form/data/',
            redirectUrl: '/admin/measures',
            isNew: true
        },
        {
            title: ['Suppliers', '›', 'New'],
            path: '/admin/suppliers/create',
            saveRoute: '/api/admin/suppliers-form/create/',
            formRoute: '/api/admin/suppliers-form/data/',
            redirectUrl: '/admin/suppliers',
            isNew: true
        },
        {
            title: ['Suppliers', '›', 'Edit'],
            path: '/admin/suppliers/edit/:id',
            saveRoute: '/api/admin/suppliers-form/create/',
            formRoute: '/api/admin/suppliers-form/data/',
            redirectUrl: '/admin/suppliers',
            isNew: true
        },
        {
            title: ['Purchase', '›', 'New'],
            path: '/admin/purchases/create',
            saveRoute: '/api/admin/purchases-form/create/',
            formRoute: '/api/admin/purchases-form/data/',
            redirectUrl: '/admin/purchases',
            isNew: true
        },
        {
            title: ['Purchase', '›', 'Edit'],
            path: '/admin/purchases/edit/:id',
            saveRoute: '/api/admin/purchases-form/create/',
            formRoute: '/api/admin/purchases-form/data/',
            redirectUrl: '/admin/purchases',
            isNew: true
        },
          {
            title: ['Questionnaire', '›', 'Receivers'],
            path: '/admin/questionnaire/exclude/:id',
            saveRoute: '/api/admin/qustionnaire/exclude/organization/',
            formRoute: '/api/admin/qustionnaire/exclude/form/',
            redirectUrl: '/admin/quest-results',
            isNew: true
        },
         {
            title: ['Procurement', '›', 'New'],
            path: '/admin/procurement/create',
            saveRoute: '/api/admin/procurements-form/create/',
            formRoute: '/api/admin/procurements-form/data/',
            redirectUrl: '/admin/procurements',
            isNew: true
        },
        {
            title: ['Procurement', '›', 'Edit'],
            path: '/admin/procurement/edit/:id',
            saveRoute: '/api/admin/procurements-form/create/',
            formRoute: '/api/admin/procurements-form/data/',
            redirectUrl: '/admin/procurements',
            isNew: true
        },
        {
            title: ['Partition', '›', 'New'],
            path: '/admin/partition/create',
            saveRoute: '/api/admin/partition-form/create/',
            formRoute: '/api/admin/quest-partition/data/',
            redirectUrl: '/admin/partitions',
            isNew: true
        },
        {
            title: ['Partition', '›', 'Edit'],
            path: '/admin/partition/edit/:id',
            saveRoute: '/api/admin/partition-form/create/',
            formRoute: '/api/admin/quest-partition/data/',
            redirectUrl: '/admin/partitions',
            isNew: false
        },
     ];
}
