import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import Spinner from '../common/Spinner';
import {replaceFile, getFile, downloadFile, changePrivacy, sendMessage, sendFile} from '../../actions/documentActions'
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles'
import Dropzone from "react-dropzone"
import Avatar from '@material-ui/core/Avatar';
import { toast } from 'react-toastify'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {config} from '../../config'
import Select from 'react-select'
import Comments from '../admin/comments/Comments'
import Navbar from '../layout/Navbar';
import { getCurrentProfile } from '../../actions/profileActions';
import ProfileActions from '../dashboard/ProfileActions';
import { getLogActivityWithGeoIp } from '../../actions/profileActions';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: "20px 10px"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width: '100%',
    height: '100%',
    color: '#000000de'
  },
   bigAvatar:{
    width: 100,
   height: 100,
  },
  labelData:{
    padding: "8px 17px 8px 0",
    color: "#777",
    textAlign: "right",
    width: "initial",
    fontWeight: "normal"
  },
  toolbar: {
    alignItems: 'center',
    padding: 0,
  },
   margin: {
    margin: theme.spacing(1),
  },
   paperDialog: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width: '100%',
    height: 'auto',
    color: '#000000de'
  },
});

class MemberFileProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
        file: null,
        openReplace: false,
        openPrivacy: false,
        openSendMessage: false,
        sendMessage: '',
        sendUsers: [],
        openSendFile: false,
        sendText: '',
        errorSendFile: '',
        logged: false
        }
    }

    componentDidMount() {
       this.props.getFile('/api/members/files/get/profile/'+this.props.match.params.id)
       this.props.getCurrentProfile();    
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.profile && nextProps.profile.profile && nextProps.profile.profile.roles.includes('ROLE_MEMBER') && !this.state.logged){
            this.props.getLogActivityWithGeoIp('Landing on document page', 'GET', "Documents tool")
            this.setState({
                logged: true
            })
        }
    }

    handleSubmit = event => {
        event.preventDefault()
        if(this.state.file){
            var body = new FormData();
            body.append('file', this.state.file)
            body.append('fileToReplace', this.props.documents.file.id)
            this.props.replaceFile('/api/members/files/replace/file', body, this.props.history, this.props.documents.file.id)
            if(this.props.profile.profile && this.props.profile.profile.roles.includes('ROLE_MEMBER')){
             this.props.getLogActivityWithGeoIp('Replace file', 'GET', "Documents tool")
         }
        }else{
            toast.error('Please, upload file!', {autoClose: 10000})
        }
        this.setState({
            file: '',
            openReplace: false
        })
    }



     handleChange = (file) => {
        if(typeof file[0] !== 'undefined'){
            this.setState({
                file: file[0]
            });
        }
     }

    onDropRejected = () => {
         toast.error('Upload error - your file is too big or in wrong format.', {autoClose: 10000})
         // accept={"image/jpeg, text/plain, image/gif, image/png, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"}
    }

     clickFileEditDownload = (route) => event => {
        event.preventDefault()
        if(this.props.documents.file.name){
           this.props.downloadFile(route, this.props.documents.file.name)
           if(this.props.profile.profile && this.props.profile.profile.roles.includes('ROLE_MEMBER')){
           this.props.getLogActivityWithGeoIp('File downloaded for edit', 'GET', "Documents tool")
       }
            setTimeout(() => {
           this.props.getFile('/api/members/files/get/profile/'+this.props.match.params.id)
       }, 3000)
        }
    }

    clickFileDownload = (route) => event => {
        event.preventDefault()
        if(this.props.documents.file.name){
           this.props.downloadFile(route, this.props.documents.file.name)
           if(this.props.profile.profile && this.props.profile.profile.roles.includes('ROLE_MEMBER')){
           this.props.getLogActivityWithGeoIp('Download file', 'GET', "Documents tool")
       }
        }
    }

    closeReplace = () => {
         this.setState({
            file: '',
            openReplace: false
        })
    }

    openDialogReplace = () => {
         this.setState({
            openReplace: true
        })
    }

   openDialogSendMessage = (e) => {
       e.preventDefault()
         this.setState({
            openSendMessage: true
        })
    }

    closeSendMessage = () => {
         this.setState({
            openSendMessage: false,
            sendMessage: ''
        })
    }

     handleSubmitSendMessage = event => {
         event.preventDefault()
         var data = {
            email: this.props.documents.file.startEditEmail,
            fileId: this.props.match.params.id,
            message: this.state.sendMessage,
            type: 'admin',
            fileName: this.props.documents.file.name
        }

         this.props.sendMessage(data);
          this.setState({
            openSendMessage: false,
            sendMessage: ''
        })
     }

     handleChangeMessage = e => {
         this.setState({
            sendMessage: e.target.value
        })
     }

      openDialogSendFile = (e) => {
       e.preventDefault()
         this.setState({
            openSendFile: true
        })
    }

    closeSendFile = () => {
         this.setState({
            openSendFile: false,
            sendText: '',
            sendUsers: [],
            errorSendFile: '',
        })
    }

     handleSubmitSendFile = event => {
         event.preventDefault()
         var sendUsers = []
         if(this.state.sendUsers.length > 0){
          this.state.sendUsers.forEach((obj, k) => {
            return sendUsers.push(obj.value);
          })
           var dataFile = {
            fileId: this.props.match.params.id,
            fileName: this.props.documents.file.name,
            message: this.state.sendText,
            sendUsers: sendUsers
        }

         this.props.sendFile(dataFile);
          this.setState({
            openSendFile: false,
            sendText: '',
            errorSendFile: '',
            sendUsers: []
        })
    }else{
        this.setState({
            errorSendFile: 'Please, choose receivers!',
        })
    }
     }

     handleChangeTextFile = e => {
         this.setState({
            sendText: e.target.value
        })
     }

     onChangeSendUsers = (value) => {
       if(value && typeof value === 'object' && value.length > 0){
        this.setState({
            sendUsers: value,
            errorSendFile: ''
        });
        }else{
            this.setState({
                sendUsers: []
            })
        }
    }

    render() {
        const { classes } = this.props;
        const {file} = this.props.documents;
        const { profile, loading } = this.props.profile;
    let dashboardContent;
    if (profile === null || loading) {
        dashboardContent = <Spinner />;
    }else{
        dashboardContent = <ProfileActions profileItem={profile}/>
    }
       let extensions = ['doc', 'docx', 'xlsx', 'xls', 'csv', 'pdf'];
        return (
       <div className="container-fluid">
            <div className="member-document page-content slide page-animation">
             {dashboardContent}
                <div className="clearfix"></div>
                 <div className="page-content" style={{paddingBottom: "100", background: "#ebf0f3"}}>
                <Grid container spacing={0}>
            <Grid container className={classNames(classes.root)} spacing={2} direction="row" justify="flex-start"  >
                    <Grid item md={10} lg={10} xs={12} className="profile-breadcrumb center-col">
                      <div className="profile-breadcrumb">
                        <a href={"/members/documents"}><i className="fa fa-fw fa-chevron-left"></i>Files</a>
                       </div>
                    </Grid>
                    <Grid item md={10} lg={10} xs={12} container spacing={2} className="center-col">
                      <Grid md={6} lg={6} xs={12} item container justify="flex-start" direction="row">
                        <Paper className={classes.paper}>
                       {file &&
                         <Grid item md={12} lg={12} xs={12} container direction="row" justify="flex-start" className="profile-information align-center">

                            {(file.isImage || file.isPdf ||  extensions.includes(file.ext)) &&
                          <Grid lg={2} md={3} xs={4} item className="profile-pic">
                            <Avatar alt={file.name} src={file.thumb} className={classes.bigAvatar} />
                          </Grid>
                          }

                            <Grid lg={6} md={6} xs={6} item>
                            <h1 className="accountTitle">{ file.name }</h1>
                            </Grid>

                             <Grid md={12} lg={12} xs={12} item>

    <Toolbar className={classes.toolbar}>
           {!file.isImage && !file.startEdit && file.edit &&
          <Button href=""
  onClick={this.clickFileEditDownload('/api/members/document/start-edit/'+this.props.match.params.id+'/download')}
  variant="outlined"
   color="inherit"
   className={classes.margin}>Edit</Button>
           }
           <Button href='' onClick={this.clickFileDownload('/api/members/documents/files/'+this.props.match.params.id+'/download')}
         color="inherit"
        variant="outlined"
        className={classes.margin}>Download</Button>
         {this.props.auth.user.username === file.startEditEmail &&
                                 <Button href='' variant="outlined" onClick={this.openDialogReplace} className={classes.margin} color="primary">
                         Replace file</Button>
                                 }
        <Button variant="outlined" color="primary" onClick={this.openDialogSendFile} className={classes.margin} target="_blank">
            Send file
        </Button>
        {(file.isImage || file.isPdf) && file.privacy === 3  &&
          <Button variant="outlined" color="primary" href={config.API_URL+"/documents/file/"+file.hash+"/full/hash"} className={classes.margin} target="_blank">
            Preview
        </Button>
        }
    </Toolbar>
                            </Grid>

                            <Grid md={12} lg={12} xs={12} item className="profile-desk" style={{marginLeft:10}}>
                                 <div className="m-top30">
                                 {file.startEdit &&
                                             <p><span className={classes.labelData}>File is locked for editing by: </span>
                                    {(this.props.auth.user.username !== file.startEditEmail) ?
                                    <a href="#" onClick={this.openDialogSendMessage}>{ file.startEdit }</a>
                                    :
                                     <span>{ file.startEdit }</span>}
                                    </p>
                                    }
                                  {file.startEditDate &&
                                         <p><span className={classes.labelData}>Start edit date: </span>{ file.startEditDate }<br/></p>
                                 }
                                  {file.created &&
                                            <p><span className={classes.labelData}>Created at: </span>{ file.created }<br/></p>
                                    }
                                 {file.createdBy &&
                                            <p><span className={classes.labelData}>Created by: </span>{ file.createdBy }<br/></p>
                                    }
                                    {file.modified &&
                                            <p><span className={classes.labelData}>Modified at: </span>{ file.modified }<br/></p>
                                    }
                                    {file.modifiedBy &&
                                            <p><span className={classes.labelData}>Modified by: </span>{ file.modifiedBy }<br/></p>
                                    }

                                       </div>
                            </Grid>

                             <Comments id={this.props.match.params.id} type="file"/>
                          </Grid>


                       }
                        </Paper>
                      </Grid>
                      <Grid md={6} lg={6} xs={12} item container justify="flex-start" direction="row">
                        <Paper className={classes.paper}>
                        {file && file.versions && file.versions.length > 0 &&
                                <Grid md={12} lg={12} xs={12} item className="profile-desk">
                                 <h3>File versions</h3>
                                 {file.versions.map((version, k) =>
                                  <div className="m-top30" key={k}>
                                          <p><span className={classes.labelData}>Version {version.version}:</span>{ version.name }<br/></p>
                                          <p><span className={classes.labelData}>Created by: </span>{ version.createdBy }<br/></p>
                                          <p><span className={classes.labelData}>Created at: </span>{ version.created }<br/></p>
                                          {version.last &&
                                                  <p><span className={classes.labelData}>Last: </span><i className="fas fa-check"></i><br/></p>}
                                          <Button href='' onClick={this.clickFileDownload('/api/members/documents/files/'+version.id+'/version/download')} variant="outlined">Download</Button>
                                  </div>
                                  )}

                            </Grid>
                        }
                        </Paper>
                      </Grid>
                   </Grid>
            </Grid>
                            <Dialog
                                onClose={this.closeReplace}
                                aria-labelledby="simple-dialog-title"
                                open={this.state.openReplace}
                                maxWidth="sm"
                                fullWidth
                                BackdropProps={{
                                classes: {
                                 root: classes.rootDialog
                                }
                               }}>
                                 <DialogTitle id="simple-dialog-title">Replace file <span className="pull-right pointer" onClick={this.closeReplace}>
                                 <i className="far fa-times-circle"></i></span>
                                 </DialogTitle>
                                 <DialogContent>
                                   <form method="post" onSubmit={this.handleSubmit}>
                                    <label>Upload edited file version</label>
                                  <div style={{display: "flex", margin: "20px 0"}}>
                                     <Dropzone
                                            maxSize={20000000}
                                            onDrop={acceptedFiles => this.handleChange(acceptedFiles)}
                                            onDropRejected={this.onDropRejected}
                                            >
                                            {({getRootProps, getInputProps}) => (
                                              <section>
                                                <div {...getRootProps()}>
                                                  <input {...getInputProps()} />
                                                  <button type="button" className="upload-btn btn btn-success">
                                                  <i style={{marginRight: 5}} className="fa fa-upload"></i> Upload </button>

                                                </div>
                                              </section>
                                            )}
                                          </Dropzone>
                                        <button type="submit" className="btn btn-default" style={{marginLeft: 15}}>Submit</button>
                                          </div>
                                    </form>
                                   </DialogContent>
                                </Dialog>
                                     {this.props.documents.file &&
                                 <Dialog
                                onClose={this.closeSendMessage}
                                aria-labelledby="simple-dialog-title"
                                open={this.state.openSendMessage}
                                maxWidth="md"
                                fullWidth
                                BackdropProps={{
                                classes: {
                                 root: classes.rootDialog
                                }
                               }}>
                                 <DialogTitle id="simple-dialog-title">
                                Send message to {this.props.documents.file.startEdit} to unlock this file:
                                <span className="pull-right pointer" onClick={this.closeSendMessage}>
                                 <i className="far fa-times-circle"></i></span>
                                 </DialogTitle>
                                 <DialogContent>
                                   <form method="post" onSubmit={this.handleSubmitSendMessage}>
                                    <label> </label>
                                      <textarea onChange={this.handleChangeMessage} value={this.state.sendMessage}></textarea>
                                        <button type="submit" className="btn btn-default" >Send</button>
                                    </form>
                                   </DialogContent>
                                </Dialog>}
                                         {this.props.documents.file &&
                                 <Dialog
                                onClose={this.closeSendFile}
                                aria-labelledby="simple-dialog-title"
                                open={this.state.openSendFile}
                                maxWidth="md"
                                 scroll={'paper'}
                                fullWidth
                                PaperProps={{
                                    classes: {
                                 root: classes.paperDialog
                                 }
                                    }}
                                BackdropProps={{
                                classes: {
                                 root: classes.rootDialog
                                }
                               }}>
                                 <DialogTitle id="simple-dialog-title">
                                Send file
                                <span className="pull-right pointer" onClick={this.closeSendFile}>
                                 <i className="far fa-times-circle"></i></span>
                                 </DialogTitle>
                                 <DialogContent>
                                  <Grid container   direction="column" justify="space-between" alignItems="stretch"  >
                                  {this.state.errorSendFile &&  <Grid md={12} lg={12} xs={12} item>
                                       <div style={{color: 'red', fontSize: 18}}>
                                  {this.state.errorSendFile}
                                  </div>
                                        </Grid>}
                                   <form method="post" onSubmit={this.handleSubmitSendFile}>
                                   <Grid md={12} lg={12} xs={12} item>
                                       <div className="m-all30">
                                    <label>Message: </label>
                                      <textarea onChange={this.handleChangeTextFile} value={this.state.sendText} style={{width: '100%'}}></textarea>
                                       </div>
                                        </Grid>
                                       <Grid md={12} lg={12} xs={12} item>
                                       <div className="m-all30">
                                         <label>Send file to: </label>
                                         {this.props.documents.file.selectData && this.props.documents.file.selectData.length > 0 &&
                                                 <Select
                                                    isMulti
                                                    value={this.state.sendUsers}
                                                    onChange={(value) => this.onChangeSendUsers(value)}
                                                    onBlurResetsInput={false}
                                                    onCloseResetsInput={false}
                                                    menuPlacement={"auto"}
                                                    options={this.props.documents.file.selectData}
                                                    style={{width: '100%'}}
                                                />
                                        }
                                         </div>
                                        </Grid>
                                        <Grid md={12} lg={12} xs={12} item>
                                         <div className="m-all30">
                                        <button type="submit" className="btn btn-default">Send</button>
                                             </div>
                                        </Grid>
                                    </form>
                                    </Grid>
                                   </DialogContent>
                                </Dialog>}

       </Grid>
                 </div>
             </div>
           <Navbar/>
        </div>
        );
    }
}

MemberFileProfile.propTypes = {
    replaceFile: PropTypes.func.isRequired,
    getFile: PropTypes.func.isRequired,
    downloadFile: PropTypes.func.isRequired,
    documents: PropTypes.object.isRequired,
    auth:  PropTypes.object.isRequired,
    changePrivacy: PropTypes.func.isRequired,
    getCurrentProfile: PropTypes.func.isRequired,
    sendMessage: PropTypes.func.isRequired,
    sendFile: PropTypes.func.isRequired,
    getLogActivityWithGeoIp: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    documents: state.documents,
    auth: state.auth,
    profile: state.profile
});
MemberFileProfile = withStyles(styles)(MemberFileProfile)
export default connect(mapStateToProps, { replaceFile, getFile, downloadFile, changePrivacy, getCurrentProfile, sendMessage, sendFile, getLogActivityWithGeoIp })(withRouter(MemberFileProfile));
