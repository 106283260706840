import React from 'react';
import InputLabel from '@material-ui/core/InputLabel'
import {renderFromHelper} from '../../../common/MaterialUiForm'

const Textarea = ({label, field, input, meta: {error, touched} }) => (
            <span className="form-input-field">
      <InputLabel  style={{
          fontSize: 14,
          marginTop: 15,
          marginBottom: 16,
          textTransform: "uppercase",
          fontFamily: 'OpenSans ,sans-serif',
          color: "#333333"}} >{typeof field !== 'undefined' && field.label ? field.label : label}</InputLabel>
                    <textarea className="form-control" disabled={typeof field !== 'undefined' && field.label ?  field.disabled : null}
                              {...input}
                              >
                    </textarea>
                   {error && error !== "undefined" && touched && (
           <span>{renderFromHelper({ touched, error })}</span>
          )}
                </span>
            );

export default Textarea;
