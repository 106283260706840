import client from './client';
import { toast } from 'react-toastify'
import { getLogActivityWithGeoIp } from './profileActions'
import {
        GET_THREADS,
        GET_THREAD,
        DOWNLOAD_ATTACHMENT,
        DELETE_REPLY,
        GET_THREAD_FORM_DATA,
        SET_NEW_REPLY,
        GET_REPLY
        } from './types';

// Get threads
export const getThreads = (page, type) => dispatch => {
        client
                .get('/api/forum/all/' + page+'/'+type)
                .then(res =>
                    dispatch({
                        type: GET_THREADS,
                        payload: res.data
                    })
                )
                .catch(err => {
                    dispatch({
                        type: GET_THREADS,
                        payload: {}
                    })

                });
    }

// Get thread
export const getThread = (id, type, history) => dispatch => {
        client
                .get('/api/forum/thread/' + id+'/'+type)
                .then(res =>
                    dispatch({
                        type: GET_THREAD,
                        payload: res.data
                    })
                )
                .catch(err => {
                    dispatch({
                        type: GET_THREAD,
                        payload: {}
                    })
                    history.push('/forum')
                });
    };
    
 export const getReply = (id, type) => dispatch => {
        client
                .get('/api/forum/reply/' + id+'/'+type)
                .then(res =>
                    dispatch({
                        type: GET_REPLY,
                        payload: res.data
                    })
                )
                .catch(err => {
                    dispatch({
                        type: GET_REPLY,
                        payload: {}
                    })
                   
                });
    };
    
    export const setReply = () => dispatch => {
      
                    dispatch({
                        type: GET_REPLY,
                        payload: {}
                    })
                
    };
    
    

// Download attachment
export const downloadAttachment = (threadId, messageId, file, type) => dispatch => {
        client
                .get('/api/forum/' + threadId + '/file/' + messageId + '/access/' + file.id+'/'+type, {responseType: 'blob'})
                .then(res => {                  
                    var blob = new Blob([res.data], {type: res.headers['content-type']});
                    var downloadUrl = URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = downloadUrl;
                    a.download = file.name;
                    document.body.appendChild(a);
                    a.click();
                })
                .catch(err => {
                    toast.error('Download error!', {autoClose: 10000})
                });
    }

// Delete reply
export const deleteReply = (threadId, messageId, type, history) => dispatch => {
        client
                .post('/api/thread/' + threadId + '/reply/' + messageId + '/delete/'+type)
                .then(res => {
                    dispatch({
                        type: DELETE_REPLY,
                        payload: res.data
                    })
                    if(threadId == messageId){
                        history.push('/forum')
                    }
                })
                .catch(err => {
                    dispatch({
                        type: DELETE_REPLY,
                        payload: {}
                    })

                });
    };

export const createThread = (data, type, history, role=null) => dispatch => {   
        client
                .post('/api/forum/thread/create/'+type, data)
                .then(res => {
                    toast.success('Success!', {autoClose: 10000})
                  if(res.data.thread.id){
                      if(type === 'member'){
                           if(role === 'member'){
                    dispatch(getLogActivityWithGeoIp('New thread', 'POST', "Members' forum"))  
                           }
                    history.push('/forum/thread/'+res.data.thread.id)
                      }else{
                           if(role === 'contributor'){
                          dispatch(getLogActivityWithGeoIp('New thread', 'POST', "Contributors' forum")) 
                           }
                           history.push('/contributors/forum/thread/'+res.data.thread.id)
                      }
                  }
                }).catch(err => {
           
            toast.error('Error!', {autoClose: 10000})
        });
    }
    
export const editThread = (data, threadId, type) => dispatch => {   
        client
                .post('/api/forum/thread/edit/'+threadId+'/'+type, data)
                .then(res => {
                   
                    toast.success('Success!', {autoClose: 10000})
                  
                }).catch(err => {
            
            toast.error('Error!', {autoClose: 10000})
        });
    }    

export const getNewThreadFormData = (search) => dispatch => {
        client
            .get('/api/forum/form/data', {search: search})
            .then(res => {
                
                dispatch({
                    type: GET_THREAD_FORM_DATA,
                    payload: res.data
                })
            })
            .catch(err => {
               
                dispatch({
                    type: GET_THREAD_FORM_DATA,
                    payload: {}
                })
            });

}


export const postThreadReply = (data, threadId, type, role=null) => dispatch => {
        client
            .post('/api/forum/thread/'+threadId+'/reply/'+type, data)
            .then(res => {
                  if(type === 'member'){
                      if(role === 'member'){
                    dispatch(getLogActivityWithGeoIp('New comment', 'POST', "Members' forum"))    
                      }
                  }else{
                       if(role === 'contributor'){
                    dispatch(getLogActivityWithGeoIp('New comment', 'POST', "Contributors' forum")) 
                       }
                  }
                dispatch({
                    type: SET_NEW_REPLY,
                    payload: res.data
                })
            })
            .catch(err => {
                
                dispatch({
                    type: SET_NEW_REPLY,
                    payload: {}
                })
            });
}

export const postEditReply = (data, threadId, replyId, type) => dispatch => {
        client
            .post('/api/forum/thread/'+threadId+'/reply/'+replyId+'/edit/'+type, data)
            .then(res => {
                
                
            })
            .catch(err => {
                console.log(err)
               
            });
}



