import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import LoginForm from '../../layout/LoginForm'
import { postSaveForLater } from '../../../actions/userSetupActions'
import { config } from '../../../config'

class SaveForLater extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSavedForLater: false
        }
    }

    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.postSaveForLater()
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.userSetup.saveForLater && nextProps.userSetup.saveForLater !== 'undefined' && Object.keys(nextProps.userSetup.saveForLater).length !== 0) {

            if ( this.props.type === "contrib" && typeof nextProps.userSetup.saveForLater.contrib !== 'undefined') {
              
                if (nextProps.userSetup.saveForLater.contrib.indexOf(String(this.props.id)) > -1 || nextProps.userSetup.saveForLater.contrib.indexOf(this.props.id) > -1) {

                    this.setState({
                        isSavedForLater: true
                    })
                }else{

                    this.setState({
                        isSavedForLater: false
                    })
                }
            }
            if ( this.props.type === "news" && typeof nextProps.userSetup.saveForLater.contrib !== 'undefined') {
                if (nextProps.userSetup.saveForLater.news.indexOf(String(this.props.id)) > -1 || nextProps.userSetup.saveForLater.news.indexOf(this.props.id) > -1) {
                    this.setState({
                        isSavedForLater: true
                    })
                }else{
                    this.setState({
                        isSavedForLater: false
                    })
                }
            }
        }
    }

    handleSave = () => {
        let data = {
            type: this.props.type,
            id: this.props.id
        }
        if(this.state.isSavedForLater){
            data.action = "delete"
        }else{
            data.action = "add"
        }

        if (this.props.auth.isAuthenticated) {
            this.props.postSaveForLater(data)
        }
    }

    render() {
        const {isAuthenticated, user} = this.props.auth;

        return (
                <Grid item md={6} sm={8} xs={12}>
                    <div className="saved-for-later">
                        <div style={{cursor: 'pointer'}} onClick={this.handleSave}>
                            <div>
                                <p>
                                    <img src={config.API_URL +"/images/18x25/e32d5d.png?v1.0"} alt="Save for later"/>
                                    {this.state.isSavedForLater ?
                                                    <span>Remove from list</span>
                                                                :
                                                    <span>Save for later</span>
                                    }
                                </p>
                            </div>
                        </div>

                    </div>
                </Grid>
                                );
                    }
                }
        SaveForLater.propTypes = {
            auth: PropTypes.object.isRequired,
            id: PropTypes.number.isRequired,
            type: PropTypes.string.isRequired,
            postSaveForLater: PropTypes.func.isRequired,
            userSetup: PropTypes.object.isRequired,
        };
        const mapStateToProps = state => ({
                auth: state.auth,
                userSetup: state.userSetup
            });

        export default connect(mapStateToProps, {postSaveForLater})(SaveForLater);
