import React, {useState} from 'react';
import { PieChart, Pie, Sector, Cell, Legend, ResponsiveContainer } from 'recharts';
const data = [{name: 'Group A', value: 400}, {name: 'Group B', value: 300},
                  {name: 'Group C', value: 300}, {name: 'Group D', value: 200}];
const COLORS = ['#dcedc8', '#c5e1a5', '#aed581', '#9ccc65', '#689f38', '#558b2f',  
    '#E9967A', '#FF6347', '#663399', '#F0E68C', '#FFF0F5', '#00FF00', '#0000CD', '#C71585',
'#800080', '#FF00FF', '#00FF00', '#008080', '#00FFFF', '#7FFFD4'];

const RADIAN = Math.PI / 180;                    
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload, value }) => {
 
  const radius =20 + innerRadius + (outerRadius - innerRadius);
  const x  = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy  + radius * Math.sin(-midAngle * RADIAN); 
if(value !== 0){
  return (
     <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="hanging" >
    	{`${value.toFixed(2)}%`} {payload.name}
    </text>
  );
}else{
    return <text></text>
}
};

function PieCharts(props){
    	return (
                <ResponsiveContainer width={'100%'} height={400}>
    	<PieChart width={'100%'} height={400} >
        
        <Pie    
        dataKey="value"
          data={props.data} 
          cx={'50%'} 
          cy={'50%'} 
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={140}
          innerRadius={60}
          fill="#8884d8"   
          isAnimationActive={false}>
        {
        props.data.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} key={index}/>)
          }
        </Pie>
        <Legend
        payload={
          props.data.map(
          (item, i) => ({
            id: item.name,
            type: "circle",
            value: `${item.name} (${item.value.toFixed(2)}%)`,
            color: COLORS[i]
          })
    )
  }
/>
      </PieChart></ResponsiveContainer>
    ); 

}
export default PieCharts;
 