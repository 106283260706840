import React from "react";
import PropTypes from "prop-types";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const dataSet1 = [
    {
        name: "Johson",
        amount: 30000,
        sex: 'M',
        is_married: true
    },
    {
        name: "Monika",
        amount: 355000,
        sex: 'F',
        is_married: false
    },
    {
        name: "John",
        amount: 250000,
        sex: 'M',
        is_married: false
    },
    {
        name: "Josef",
        amount: 450500,
        sex: 'M',
        is_married: true
    },
    {
        headerName: "Supplier Name",
        field: "supplierName",
    }
];

class DownloadExcel extends React.Component {
     constructor() {
        super();
      }
  
    render() { 
        let columns = []
        if(this.props.columns && this.props.columns.length > 0){
          this.props.columns.map((column, key)=> {
                if(column.children && column.children.length > 0){
                    var i;
                    for (i = 0; i < column.children.length; i++) {
                        columns.push(column.children[i]);
                      }
                }else{
                    columns.push(column);
                }
            })
        }
       
        return (
            <ExcelFile filename={this.props.filename}  element={<button  className="btn btn-success" style={{width: '80%', marginRight: 20}}>Export Excel</button>}>               
            {this.props.data && this.props.data.length > 0 && columns && columns.length > 0 &&
                <ExcelSheet data={this.props.data} name={this.props.title}>
                {columns && columns.map((column, key)=> (
                    <ExcelColumn label={column.headerName} value={column.field} key={key}/>
                        ))}
                </ExcelSheet>}
            </ExcelFile>
        );
    }
}

DownloadExcel.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    filename:  PropTypes.string.isRequired,
};



export default DownloadExcel;