import React, { Component } from 'react';
import Sectors from './Sectors'
import Sdgs from './Sdgs'
import Contribute from '../Contribute'
import Grid from '@material-ui/core/Grid'
import SocialNetworks from './SocialNetworks'
import SaveForLater from '../SaveForLater'
import LoginForm from '../../../layout/LoginForm'
import Diff from 'react-stylable-diff'

class ContributionProject extends Component {
  render() {
    const contribution = this.props.contribution;   
return (
        <div>
        <div className="col-md-12 right-header" >


    <div className="col-md-12 clearfix">
        <hr className="blue-line col-sm-12 noPadding"/>
        
        <div className="col-sm-8 col-md-12 col-lg-8 noPadding" >   
           {contribution.projectAt && <div className="col-md-12 noPadding clearfix" >
                <div className="col-xs-6 noPadding ">
                    <h5 className="text-uppercase project-date text-dark-blue-old pull-left">project start: </h5>
                </div>
                <div className="col-xs-6 noPadding ">
                    <h5 className="text-white pull-left">            
                     {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor ?
                        <Diff type="sentences" inputA={contribution.reflection.projectAt} inputB={contribution.projectAt}/>
                          :
                          <span>{ contribution.projectAt}</span>
                      }
                    </h5>
                </div>                
            </div>}
            <div className="col-md-12 noPadding clearfix">
                <div className="col-xs-6 noPadding ">
                    <h5 className="pull-right text-uppercase project-date text-dark-blue-old pull-left">project end: </h5>
                </div>
                <div className="col-xs-6 noPadding ">
                  
                    {contribution.projectTo &&
                              <h5 className="text-white pull-left">
                {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor ?
                        <Diff type="sentences" inputA={contribution.reflection.projectTo} inputB={contribution.projectTo}/>
                          :
                          <span>{ contribution.projectTo}</span>
                      }
                 {!contribution.projectTo &&
                        <span>N/A</span>
                }
                    </h5>
                    }
                </div>
                
            </div>
        </div>
        
        {contribution.projectStatusName &&
        <div className="col-md-12 col-lg-12 noPadding clearfix">
            <div className="white-label">
       {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor ?
                        <Diff type="sentences" inputA={contribution.reflection.projectStatusName} inputB={contribution.projectStatusName}/>
                          :
                          <span>{ contribution.projectStatusName}</span>
                      }</div>
        </div>}
      
        <hr className="blue-line col-sm-12 col-md-12 noPadding" />
    </div>
   
</div>
{contribution.sectors &&
        <Sectors sectors={contribution.sectors}/>
}
{contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor &&
<Diff type="sentences" inputA={contribution.reflection.sectorsText} inputB={contribution.sectorsText}/>
}
 <LoginForm role="filter"/>
{contribution.href &&
<SocialNetworks url={contribution.href}/>
}
<SaveForLater id={contribution.id} type="contrib"/>

{contribution.sdg &&
<Sdgs sdgs={contribution.sdg}/>
}
{contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor &&
<Diff type="sentences" inputA={contribution.reflection.sdgText} inputB={contribution.sdgText}/>
}
{contribution.lessons &&
    <div className="col-md-12 col-sm-6 col-xs-12" >
        <h4 className="text-uppercase text-gray">lessons learnt</h4>
        {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor ?
        <span>
        <p className="description text-left" style={{backgroundColor: '#c9eed3'}}  dangerouslySetInnerHTML={{__html:contribution.reflection.lessons }}></p>
        <p className="description text-left" style={{backgroundColor: '#cccc00'}} dangerouslySetInnerHTML={{__html: contribution.lessons}}></p>
        </span>
        :
        <p className="description text-left" dangerouslySetInnerHTML={{__html: contribution.lessons}}></p>
    }
    </div>
 }   
    <div className="col-md-12 col-sm-6 col-xs-12">
        <h4 className="text-uppercase text-gray">project link</h4>
         {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor ?
          <Diff type="sentences" inputA={contribution.reflection.links} inputB={contribution.links}/>
         :
        <a href={contribution.links} target="_blank">{contribution.links}</a>
        }
    </div>

</div>);
  }
}

export default ContributionProject;


