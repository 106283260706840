import {
    FORM_DATA_LOAD,
    FORM_FIELD_UPDATE,
    IMAGE_UPLOAD_LOADING,
    IMAGE_UPLOAD_SUCCESSFUL,
    IMAGES_UPLOAD_SUCCESSFUL,
    FORM_REMOVE_IMAGE
} from '../actions/types';

const initialState = {   
    data: []
};

export default (state = initialState, action) => {
        
    const formId = action.payload ? action.payload.formId : -1;
    let form = formId in state ? state[formId] : {};
    let images = [];
    let imagesF = [];
    
    switch (action.type) {        
        case FORM_DATA_LOAD:
            
            return {
                ...state,
                data: action.payload.data && action.payload.data.length > 0 || Object.keys(action.payload.data).length > 0 ? action.payload.data : {}
            };
        case FORM_FIELD_UPDATE:
            form[action.payload.key] = action.payload.value;
            return {
                ...state,
                [formId]: form
            };

        case IMAGE_UPLOAD_SUCCESSFUL:
            form[action.payload.id] = action.payload.image;
            return {
                ...state,
                [formId]: form
            };
        case IMAGES_UPLOAD_SUCCESSFUL:
            images = state[action.payload.formId]['itemImages'];
            let currentForm = state[action.payload.formId];
            if (images) {
                images = images.concat(action.payload.images);
            } else {
                images = action.payload.images;
            }
            currentForm[action.payload.id] = images;  
            return {
                ...state,
                [formId]: currentForm
            };
        case FORM_REMOVE_IMAGE:
            images = state[action.payload.formId]['itemImages'];
            let remove = false;
            let id = action.payload ? action.payload.id : null;
            images.forEach(function (item, key) {
                if (item.id === id && remove === false) remove = key;
            });
            if(remove !== false) {
                images.splice(remove, 1);
            }
            return {
                ...state,
                images
            };
        default:
            return state;
    }
};