import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Navbar from '../layout/Navbar';
import Footer from '../layout/Footer'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { config } from '../../config';
import { getLogActivityWithGeoIp } from '../../actions/profileActions';

const styles = theme => ({
  empoloyProfile:{
    backgroundColor: '#203648'
  },
  contentText: {
    background: "#ebf0f3",
  },
  icon: {
    marginLeft: 5
  }
});


class Contacts extends Component {

      constructor() {
          super();
          this.state = {
              open: false
          };
      }
      
      componentDidMount(prevProps) {
        this.props.getLogActivityWithGeoIp('Contacts page', 'GET', "Website usage")
  }

      handleClickOpen = event => {
      event.preventDefault();
              this.setState({ [event.target.id]: true });
      };

      handleClose = id => {
              this.setState({ [id]: false });
      };

  render() {
  const { classes } = this.props;

  return (
     <div className="contacts">
       <Navbar/>
       <div className="page-content  page-animation slideup">
        <Grid container spacing={0}>
          <div className="heroshot">
            <img src={config.API_URL +"/images/org/2889a3.png?v1.0"} all={"Contacts pages's image"}/>
            <div className="text-area text-center col-md-8 col-md-offset-2">
            <h1 className="text-center text-white">Hello.</h1>
            <h2 className="text-gray"></h2>
            </div>
          </div>
                        <Grid item xs={12} sm container className="contacts-content">
                            <h1 className="text-left" style={{marginLeft: "6%"}}>Meet our team</h1>
                            <Grid container spacing={0}>
                              <Grid item md={4} xs={12} sm={6} className="employee">
                              <div>
                                  <span>
                                  <img src={config.API_URL +"/images/400x400/670444.jpeg"} alt="Rosemary Kumwenda"/>
                                  <a className="text-uppercase" id="contact1" onClick={this.handleClickOpen}>View profile</a>
                                    <div className="employee-social">
                                        <a href="mailto:rosemary.kumwenda@undp.org"  target="_blank"><Icon style={{ fontSize: 16 }} className="far fa-envelope"/></a>
                                        <a href="" target="_blank"><Icon className="fab fa-twitter"/></a>
                                        <a href="" target="_blank"><Icon className="fab fa-linkedin"/></a>
                                    </div>
                                </span>
                                  <h3>Rosemary Kumwenda</h3>
                                  <p>SPHS Coordinator</p>
                                  </div>
                          <Dialog
                            onClose={this.handleClose}
                            aria-labelledby="customized-dialog-title"
                            id="contact1"
                            className="Dialog-employeeProfile"
                            open={this.state.contact1}
                            maxWidth="md"
                            PaperProps={{
                                style: {
                                background:"transparent",
                                boxShadow: "none",
                              }
                              }}
                          >
                            <DialogActions>
                              <Button onClick={() => this.handleClose('contact1')} size="large" className="text-white uppercase">Close <Icon size="22"  className={classNames(classes.icon, 'fa fa-times-circle')}/></Button>
                              </DialogActions>
                            <DialogContent className="DialogContent-employeeProfile">
                               <div>
                                    <div>
                                        <div className="scroll customScroll">
                                            <h1>BWANJI.</h1>
                                            <div className="row center-col">
                                                <h2>Rosemary Kumwenda</h2>
                                                <img src={config.API_URL +"/images/400x400/670444.jpeg"} alt="Rosemary Kumwenda"/>
                                            </div>
                                            <div className="clearfix"></div>
                                            <p className="text-white">
                                              Rosemary comes with over 15 years of work experience in UNDP at country and regional levels working on Health and HIV. She spent 11 years as Assistant Resident Representative Poverty, HIV&AIDS Advisor in Zambia. Also served in UNDP Malawi Country Office as HIV and AIDS policy Advisor to the Government of Malawi. She led the UN Joint teams on focus areas related to UNDP’s comparative advantage including HIV and the Law, Key populations, HIV and Gender mainstreaming in development plans and strategies. In 2014 she joined the HHD team at the UNDP RSCA as Senior Policy Advisor for Sustainable Responses HIV, Health and Development, pioneering work on Co-Financing, Leveraging Funding for Health from Capital Development and supporting national and regional government to develop Innovative Strategies to increase Domestic Financing for Health. Rosemary has a Master’s degree in Public Health from the London school of Hygiene and Tropical Medicine (LHTM). She is a Medical Doctor trained at the University of Medicine and Pharmacy of Cluj-Napoca in Romania and was a medical practitioner for 10 years in both clinical care, rising to the rank of City of Lusaka Director of Health before joining UNDP. Rosemary speaks English, Romanian and French.
                                                </p>
                                                <div className="employee-social">
                                                    <h5 className="text-uppercase text-white">Get in touch</h5>
                                                    <a href="mailto:rosemary.kumwenda@undp.org" target="_blanck"><Icon className="far fa-envelope"/></a>
                                                    <a href="#" target="_blank"><Icon className="fab fa-twitter"/></a>
                                                    <a href="#" target="_blank"><Icon className="fab fa-linkedin"/></a>
                                                </div>
                                                <div className="clearfix"></div>

                                            </div>
                                        </div>
                                    </div>
                            </DialogContent>
                          </Dialog>
                        </Grid>
                        <Grid item md={4} xs={12} sm={6} className="employee">
                        <div>
                            <span>
                            <img src={config.API_URL +"/images/400x400/8d35d2.jpeg"} alt="Mirjana Milić"/>
                            <a className="text-uppercase" id="contact2" onClick={this.handleClickOpen}>View profile</a>
                              <div className="employee-social">
                                  <a href="mailto:mirjana.milic@undp.org" target="_blank"><Icon style={{ fontSize: 16 }} className="far fa-envelope"/></a>
                                  <a href="https://twitter.com/milic_mirjana" target="_blank"><Icon className="fab fa-twitter"/></a>
                                  <a href="https://www.linkedin.com/in/mirjana-milic-6b447640/" target="_blank"><Icon className="fab fa-linkedin"/></a>
                              </div>
                          </span>
                            <h3>Mirjana Milić</h3>
                            <p>Associate Coordinator</p>
                            </div>
                    <Dialog
                      onClose={this.handleClose}
                      aria-labelledby="customized-dialog-title"
                      id="contact2"
                      className="Dialog-employeeProfile"
                      open={this.state.contact2}
                      maxWidth="md"
                      PaperProps={{
                          style: {
                          background:"transparent",
                          boxShadow: "none",
                        }
                        }}
                    >
                      <DialogActions>
                        <Button onClick={() => this.handleClose('contact2')} size="large" className="text-white uppercase">Close <Icon size="22" className={classNames(classes.icon, 'fa fa-times-circle')}/></Button>
                        </DialogActions>
                      <DialogContent className="DialogContent-employeeProfile">
                         <div>
                              <div>
                                  <div className="scroll customScroll">
                                      <h1>ZDRAVO.</h1>
                                      <div className="row center-col">
                                          <h2>Mirjana Milić</h2>
                                          <img src={config.API_URL +"/images/400x400/8d35d2.jpeg"} alt="Mirjana Milić"/>
                                      </div>
                                      <div className="clearfix"></div>
                                      <p className="text-white">
                                        Mirjana is the Associate Coordinator of the UN Interagency Task Team on Sustainable Procurement in the Health Sector (SPHS). Mirjana has been working on the SPHS initiative since its establishment in 2012, and has assisted the Secretariat in the establishment of the global SPHS network, liaised with the private sector (profit and non-profit), public sector and experts from academic, scientific and civil society communities in order to adapt a methodology for introducing environmental criteria in procurement bidding processes. She focuses on partnership-building and resource mobilization and has practical experience in private and public administration, project management, establishment of relevant private and public relations, capacity building, advocacy and lobbying for policy change, innovative management approaches and sustainable development in the healthcare industry.
                                          </p>
                                          <div className="employee-social">
                                              <h5 className="text-uppercase text-white">Get in touch</h5>
                                              <a href="mailto:mirjana.milic@undp.org" target="_blanck"><Icon className="far fa-envelope"/></a>
                                              <a href="https://twitter.com/milic_mirjana" target="_blank"><Icon className="fab fa-twitter"/></a>
                                              <a href="https://www.linkedin.com/in/mirjana-milic-6b447640/" target="_blank"><Icon className="fab fa-linkedin"/></a>
                                          </div>
                                          <div className="clearfix"></div>

                                      </div>
                                  </div>
                              </div>
                      </DialogContent>
                    </Dialog>
                  </Grid>
                  <Grid item md={4} xs={12} sm={6} className="employee">
                  <div>
                      <span>
                      <img src={config.API_URL +"/images/360x360/df7ce5.png"} alt="Nevra Gomdeniz"/>
                      <a className="text-uppercase" id="contact3" onClick={this.handleClickOpen}>View profile</a>
                        <div className="employee-social">
                            <a href="mailto:nevra.gomdeniz@undp.org" target="_blank"><Icon  style={{ fontSize: 16 }} className="far fa-envelope"/></a>
                            <a href="#" target="_blank"><Icon className="fab fa-twitter"/></a>
                            <a href="https://www.linkedin.com/in/nevra-gomdeniz-71bb6261/" target="_blank"><Icon className="fab fa-linkedin"/></a>
                        </div>
                    </span>
                      <h3>Nevra Gomdeniz</h3>
                      <p>Communications Specialist</p>
                      </div>
                <Dialog
                onClose={this.handleClose}
                aria-labelledby="customized-dialog-title"
                id="contact3"
                className="Dialog-employeeProfile"
                open={this.state.contact3}
                maxWidth="md"
                PaperProps={{
                    style: {
                    background:"transparent",
                    boxShadow: "none",
                  }
                  }}
                >
                <DialogActions>
                  <Button onClick={() => this.handleClose('contact3')} size="large" className="text-white uppercase">Close <Icon size="22"  className={classNames(classes.icon, 'fa fa-times-circle')}/></Button>
                  </DialogActions>
                <DialogContent className="DialogContent-employeeProfile">
                   <div>
                        <div>
                            <div className="scroll customScroll">
                                <h1>MERHABA.</h1>
                                <div className="row center-col">
                                    <h2>Nevra Gomdeniz</h2>
                                    <img src={config.API_URL +"/images/400x400/e4b561.jpeg"} alt="Nevra Gomdeniz"/>
                                </div>
                                <div className="clearfix"></div>
                                <p className="text-white">
                                Nevra is the Communications Specialist of the UN Sustainable Procurement in the Health Sector (SPHS). Nevra specializes in Communication for Development (C4D), strategic communications in sustainable health care and climate action, tailored to international audiences through high-level global conferences, news outlets and interactive media. She has been working with the SPHS since 2015. Nevra's contribution to the initiative focuses on promoting international media coverage, organizing international conferences, implementing strategic communication strategies, liaising with external donor channels, building up innovative content distributions channels, and overseeing the management of the SPHS website and social media accounts. Following a B.A. degree in Political Science and Public Administration at Bilkent University (Turkey), Nevra earned her M.S. degree in Integrated Marketing and Corporate Communications from New York University (NYU). She has a background in journalism, with experience as a foreign news editor at the highest circulated newspapers in her home country, Turkey.
                                    </p>
                                    <div className="employee-social">
                                        <h5 className="text-uppercase text-white">Get in touch</h5>
                                        <a href="mailto:nevra.gomdeniz@undp.org" target="_blanck"><Icon className="far fa-envelope"/></a>
                                        <a href="#" target="_blank"><Icon className="fab fa-twitter"/></a>
                                        <a href="https://www.linkedin.com/in/nevra-gomdeniz-71bb6261/" target="_blank"><Icon className="fab fa-linkedin"/></a>
                                    </div>
                                    <div className="clearfix"></div>

                                </div>
                            </div>
                        </div>
                </DialogContent>
                </Dialog>
              </Grid>
          <div className="clearfix"></div>

                <Grid item md={4} xs={12} sm={6} className="employee">
                <div>
                    <span>
                    <img src={config.API_URL +"/images/400x400/fdd4e4.jpeg"} alt="Sharon Watkins"/>
                    <a className="text-uppercase" id="contact4" onClick={this.handleClickOpen}>View profile</a>
                      <div className="employee-social">
                          <a href="mailto:sharon.watkins@undp.org" target="_blank"><Icon  style={{ fontSize: 16 }} className="far fa-envelope"/></a>
                          <a href="#" target="_blank"><Icon className="fab fa-twitter"/></a>
                          <a href="https://www.linkedin.com/in/sharonwatkins/" target="_blank"><Icon className="fab fa-linkedin"/></a>
                      </div>
                  </span>
                    <h3>Sharon Watkins</h3>
                    <p>IT Specialist</p>
                    </div>
              <Dialog
              onClose={this.handleClose}
              aria-labelledby="customized-dialog-title"
              id="contact4"
              className="Dialog-employeeProfile"
              open={this.state.contact4}
              maxWidth="md"
              PaperProps={{
                  style: {
                  background:"transparent",
                  boxShadow: "none",
                }
                }}
              >
              <DialogActions>
                <Button onClick={() => this.handleClose('contact4')} size="large" className="text-white uppercase">Close <Icon size="22"  className={classNames(classes.icon, 'fa fa-times-circle')}/></Button>
                </DialogActions>
              <DialogContent className="DialogContent-employeeProfile">
                 <div>
                      <div>
                          <div className="scroll customScroll">
                              <h1>HI.</h1>
                              <div className="row center-col">
                                  <h2>Sharon Watkins</h2>
                                  <img src={config.API_URL +"/images/400x400/fdd4e4.jpeg"} alt="Sharon Watkins"/>
                              </div>
                              <div className="clearfix"></div>
                              <p className="text-white">
                              Sharon is the IT Consultant of the UN Interagency Task Team on Sustainable Procurement in the Health Sector (SPHS). Sharon is assisting the SPHS to identify new technology solutions, works to integrate and apply them to current business processes, and to the needs of the SPHS Secretariat, its procurement practitioners and vendors. She is responsible for identifying optimized solutions to facilitate relationship management with globally dispersed stakeholders, and for ensuring compatible solutions to enhance programming and management.
                                  </p>
                                  <div className="employee-social">
                                      <h5 className="text-uppercase text-white">Get in touch</h5>
                                      <a href="mailto:sharon.watkins@undp.org" target="_blanck"><Icon className="far fa-envelope"/></a>
                                      <a href="#" target="_blank"><Icon className="fab fa-twitter"/></a>
                                      <a href="https://www.linkedin.com/in/sharonwatkins/" target="_blank"><Icon className="fab fa-linkedin"/></a>
                                  </div>
                                  <div className="clearfix"></div>

                              </div>
                          </div>
                      </div>
              </DialogContent>
              </Dialog>
              </Grid>
              <Grid item md={4} xs={12} sm={6} className="employee">
              <div>
                  <span>
                  <img src={config.API_URL +"/images/org/9e97d1.png?v1.0"} alt="John Macauley"/>
                  <a className="text-uppercase" id="contact5" onClick={this.handleClickOpen}>View profile</a>
                    <div className="employee-social">
                    <a href="mailto:john.macauley@undp.org" target="_blanck"><Icon className="far fa-envelope"/></a>
                    </div>
                </span>
                  <h3 style={{marginTop: 28}}>John Macauley</h3>
                  <p>Regional HIV, Health and Development Programme Specialist UNDP Istanbul Regional Hub, United Nations Development Programme</p>
                  </div>
            <Dialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            id="contact5"
            className="Dialog-employeeProfile"
            open={this.state.contact5}
            maxWidth="md"
            PaperProps={{
                style: {
                background:"transparent",
                boxShadow: "none",
              }
              }}
            >
            <DialogActions>
              <Button onClick={() => this.handleClose('contact5')} size="large" className="text-white uppercase">Close <Icon size="22"  className={classNames(classes.icon, 'fa fa-times-circle')}/></Button>
              </DialogActions>
            <DialogContent className="DialogContent-employeeProfile">
               <div>
                    <div>
                        <div className="scroll customScroll">
                            <h1>HELLO.</h1>
                            <div className="row center-col">
                                <h2>John Macauley</h2>
                                <img src={config.API_URL +"/images/org/9e97d1.png?v1.0"} alt="John Macauley"/>
                            </div>
                            <div className="clearfix"></div>
                            <p className="text-white">
                            John has worked on HIV in Eastern Europe and Central Asia since 2004. As part of the HIV, Health and Development team, his work has focused on supporting national stakeholders (government and civil society) as they strengthen their capacities related to HIV prevention, treatment and care and other health issues. The main focus is to promote and protect the rights of at risk populations including drug users, sex workers and men who have sex with men. John works to capture and disseminate good practices in the area of HIV prevention, treatment and care and other strategic health issues. Before joining UNDP, he worked for the National Democratic Institute (NDI), promoting political participation of marginalized populations including Roma, women and youth.
                                </p>
                                <div className="employee-social">
                                    <h5 className="text-uppercase text-white">Get in touch</h5>
                                    <a href="mailto:john.macauley@undp.org" target="_blanck"><Icon className="far fa-envelope"/></a>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
            </DialogContent>
            </Dialog>
            </Grid>
            <div className="clearfix"></div>

                  <Grid item md={4} xs={12} sm={6} className="employee">
                  <div>
                      <span>
                      <img src={config.API_URL +"/images/org/4f4c3d.png?v1.0"} alt="Ian Milimo"/>
                      <a className="text-uppercase" id="contact6" onClick={this.handleClickOpen}>View profile</a>
                        <div className="employee-social">
                            <a href="mailto:ian.milimo@undp.org" target="_blank"><Icon  style={{ fontSize: 16 }} className="far fa-envelope"/></a>
                            <a href="https://www.linkedin.com/in/ian-milimo/" target="_blank"><Icon className="fab fa-linkedin"/></a>
                        </div>
                    </span>
                      <h3>Ian Milimo</h3>
                      <p>Project Manager, Sustainable Health in Procurement Project (SHiPP), UNDP</p>
                      </div>
                <Dialog
                onClose={this.handleClose}
                aria-labelledby="customized-dialog-title"
                id="contact6"
                className="Dialog-employeeProfile"
                open={this.state.contact6}
                maxWidth="md"
                PaperProps={{
                    style: {
                    background:"transparent",
                    boxShadow: "none",
                  }
                  }}
                >
                <DialogActions>
                  <Button onClick={() => this.handleClose('contact6')} size="large" className="text-white uppercase">Close <Icon size="22"  className={classNames(classes.icon, 'fa fa-times-circle')}/></Button>
                  </DialogActions>
                <DialogContent className="DialogContent-employeeProfile">
                   <div>
                        <div>
                            <div className="scroll customScroll">
                                <h1>HI.</h1>
                                <div className="row center-col">
                                    <h2>Ian Milimo</h2>
                                    <img src={config.API_URL +"/images/org/4f4c3d.png?v1.0"} alt="Ian Milimo"/>
                                </div>
                                <div className="clearfix"></div>
                                  <p className="text-white">
                                Ian Milimo has over 15 years of professional experience, working at both community and policy levels in development programming. Currently employed as Project Manager at the UNDP's Regional Hub for Europe, Ian is overseeing a global project, Sustainable Health in Procurement Project (SHiPP), implemented in 10 countries covering Asia, Africa, Europe and Latin America. Prior to this, Ian was the Assistant Resident Representative for Poverty Reduction and Sustainable Development Goals in Zambia for six years.
                                      </p>
                                    <div className="employee-social">
                                        <h5 className="text-uppercase text-white">Get in touch</h5>
                                        <a href="mailto:ian.milimo@undp.org" target="_blanck"><Icon className="far fa-envelope"/></a>
                                        <a href="https://www.linkedin.com/in/ian-milimo/" target="_blank"><Icon className="fab fa-linkedin"/></a>
                                    </div>
                                    <div className="clearfix"></div>

                                </div>
                            </div>
                        </div>
                </DialogContent>
                </Dialog>
                </Grid>
      </Grid>
                <div className="clearfix"></div>
    </Grid>
    <Grid item md={12} className="contacts-address background-dark-blue text-center text-white">
        <div className="max-width">
            <h2 className="text-white">UNDP Istanbul Regional Hub</h2>
            <a href="mailto:info@savinglivesustainably.org">info@savinglivesustainably.org</a>
        </div>
    </Grid>
          </Grid>
        <Footer/>
          </div>
      </div>

      );
    }
  }

Contacts.propTypes = {
    getLogActivityWithGeoIp: PropTypes.func.isRequired
  };

const mapStateToProps = state => ({});
Contacts = withStyles(styles)(Contacts)
export default connect(mapStateToProps, {getLogActivityWithGeoIp})(Contacts);

