import {
    GRID_API_LOAD, 
    GRID_DATA_LOADED, 
    GRID_HEADER_OPTION_SELECTED
} from "../actions/types";

export default (state = [], action) => {
       
    switch (action.type) {
        case GRID_DATA_LOADED:
            return action.payload;

        default:
            return state;
    }
};