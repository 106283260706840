import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

class DataAccess extends Component {
  constructor() {
    super();
    this.state = {

    };
  }

     componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {

    }


  render() {

     const { classes } = this.props;
       const { account } = this.props.account;

    return (
      <Grid container spacing={0} className="m-all30 mobileMargin10">
           <Grid item md={12} lg={12} xs={12} container spacing={2}>
           <h3>Data Access</h3>
          <Table className="mobileTable">
                <TableHead>
                    <TableRow>
                        <TableCell>Date Requested</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Email</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                 {account.dataAccess.map((access, i) => (
                <TableRow key={i}>
                <TableCell>{access.dateRequested}</TableCell>
                <TableCell>{access.type}</TableCell>
                <TableCell>{access.email}</TableCell>
                </TableRow>
                ))}
                </TableBody>
            </Table>
        </Grid>
      </Grid>
    );
  }
}

DataAccess.propTypes = {
  account:    PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  account: state.account
});

DataAccess = withStyles(styles)(DataAccess)

export default connect( mapStateToProps, {})(DataAccess);
