import React from 'react'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import MenuItem from '@material-ui/core/MenuItem'
import Chip from '@material-ui/core/Chip'
import { Editor } from '@tinymce/tinymce-react'
import { DatePicker } from "@material-ui/pickers";
import moment from 'moment';
import ErrorMessage from '../admin/form/ErrorMessage';
import Input from '@material-ui/core/Input';
import classNames from 'classnames';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const renderTmcField = ({
  label,
  input,
  field,
  meta: { touched, invalid, error },
  ...custom
}) => (
<div className="text-left" style={{width: '100%'}}>

     <InputLabel  style={{fontSize: 14, marginTop: 15, marginBottom: 16, textTransform: "uppercase",fontFamily: 'OpenSans ,sans-serif',
     color: "#333333"}} >{label}</InputLabel>
     <div className={field.id}>
        <Editor
    {...input}
    {...custom}
      className={field.id}
      id={field.id}
      value={input.value !== '' ? input.value : null}
      content={input.value}
      onBlur={(event) => { input.onChange(event.target.getContent()); }}
      init={{
        plugins: [
        "advlist autolink link image lists charmap print preview hr anchor pagebreak",
        "searchreplace wordcount visualblocks visualchars insertdatetime media nonbreaking",
        "table contextmenu directionality emoticons paste  textcolor code "
        ],
        toolbar1: "undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | styleselect",
        toolbar2: "|link unlink  anchor | image media | forecolor backcolor  | print preview code | fontselect fontsizeselect ",
        mode : "exact",
        width: '100%',        
        height: 300,}}
  />
</div>
   {error && error !== "undefined" && touched && (
           <span>{renderFromHelper({ touched, error })}</span>
          )}

      </div>
)



export const renderPasswordField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
<span>
     <InputLabel  style={{
          fontSize: 14,
          marginTop: 15,
          marginBottom: 16,
          textTransform: "uppercase",
          fontFamily: 'OpenSans ,sans-serif',
          color: "#333333"}} >{label}</InputLabel>
  <TextField
    fullWidth
    margin="normal"
    error={touched && invalid}
    {...input}
    placeholder={custom.placeholder}
    type="password"
    style={{fontSize: 16 }}
  />
  {error && error !== "undefined" && touched && (
           <span>{renderFromHelper({ touched, error })}</span>
          )}
</span>
)

export const renderDateField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
         <FormControl fullWidth>

  <TextField
   label={label}
    fullWidth
    type="date"
    margin="normal"
     InputLabelProps={{
          shrink: true,
        }}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />

   </FormControl>
)

export const renderDatePickerField = ({
  label,
  input,
  value,
  meta: { touched, invalid, error },
  ...custom
}) => (
    <FormControl fullWidth style={{fontSize: 16}} >
    <div className="text-left" style={{ fontSize: 14, marginTop: 15, textTransform: 'uppercase' }}>{label}</div>
      <DatePicker
      {...input}
      {...custom}
        value={input.value ? input.value : moment(new Date(), "DD-MM-YYYY")}
        animateYearScrolling
        format="DD-MM-YYYY"
        style={{fontSize: 16, marginTop: 16, marginBottom: 8}}/>
       {renderFromHelper({ touched, error })}
    </FormControl>
)

export const renderTextField = ({
  label,
  input,
  field,
  meta: { touched, invalid, error },
  ...custom
}) => (

<FormControl style={{background: 'none !important', width: '100%'}}>
    <span>
    <div className="text-left" style={{ fontSize: 14, marginTop: 15, textTransform: 'uppercase' }}><span dangerouslySetInnerHTML={{ __html: label }}></span></div>
  <TextField
    fullWidth
    margin="normal"
    error={touched && invalid}
    {...input}
        style={{background: "white" }}
   disabled={field ? field.disabled : false}
  />
  {renderFromHelper({ touched, error })}
   </span>
  </FormControl>
)

export const renderCheckbox = ({ disabled, className, input, style, label, meta: { touched, invalid, error } }) => (
  <div>
    <FormControlLabel
      control={<Checkbox
          checked={(typeof input.value === 'string' || typeof input.value === 'integer' ? parseInt(input.value) : input.value) ? true : false}
          onChange={input.onChange}
          disabled={disabled}
        />}      
      label={label}
      style={style}
      classes={className}
    />
    <div className="error" style={{color: 'red'}}>
     {renderFromHelper({ touched, error })}
   </div>
  </div>
)



export const radioButton = ({ options, input, ...rest }) => (
  <FormControl>
    <RadioGroup {...input} {...rest}>
    {options.map(field => {
        return <FormControlLabel value={field.value} control={<Radio />} label={field.label} />
    })}
    </RadioGroup>
  </FormControl>
)

export const renderFromHelper = ({ touched, error }) => {

  if (!(touched && error)) {
    return
  } else if(error && error!== 'undefined' && touched){

  return <FormHelperText variant="standard" error={true} style={{ color: '#f44336', fontSize: 14}}>
      <ErrorMessage
         error={error}/>
   </FormHelperText>
  }
}

export const renderSelectField = ({
  input,
  label,
  placeholder,
  meta: { touched, error },
  children,
  ...custom
}) => (
        <span>
<InputLabel style={{ marginTop: 15, cursor: "auto"}} htmlFor="select-native-simple">{label}</InputLabel>
  <FormControl error={touched && error && error !== 'undefined' ? touched : false} className={classNames("formControl")}>

    <Select
    style={{fontSize:14,padding: "0 15px", height: 48, lineHeight: 2.5}}
      {...input}
      {...custom}
      inputProps={{
        id: 'select-native-simple'
      }}
      variant="standard"

    >

    <MenuItem value="" style={{fontSize:14, paddingLeft: 15}}><em>None</em></MenuItem>
    {children.map((child, i) => (
          <MenuItem style={{fontSize:14, paddingLeft: 15}} key={i} value={child.id ? child.id : child}>{child.name ? child.name : child}</MenuItem>
                ))
     }
    </Select>
    {renderFromHelper({ touched, error })}
  </FormControl>
  </span>
)

export const renderSelectChipField = ({
  input,
  label,
  meta: { touched, error },
  children,
  value
}) => (
        <FormControl error={touched && error && error !== 'undefined' ? touched : false} style={{margin: 20,
    minWidth: '100%',
    maxWidth: 300,
    width: '100%'
    }}>
    <InputLabel htmlFor="select-native-simple">{label}</InputLabel>
    <Select
    multiple
    {...input}
      variant="standard"
      renderValue={selected => (
            <div >
              {selected.map(value => (
                <Chip key={value} label={value}  />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
    >
    <MenuItem value=""><em>None</em></MenuItem>
    {children.map((child, i) => (
          <MenuItem key={i} value={child.id ? child.id : child}>{child.name ? child.name : child}</MenuItem>
                ))
     }
    </Select>
    {renderFromHelper({ touched, error })}
  </FormControl>

)
