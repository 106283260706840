import client from './client';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify'
import showAToast from '../helpers/showAToast'

import { 
     CHECK_USER,
     GET_MESSAGE,
     GET_ORGANIZATIONS,
     GET_QUESTIONNAIRE,
     GET_SECTION,
     ADD_FIELD,
     CHANGE_FIELD,
     GET_QUEST_FILL,
     GET_USER_QUESTIONNAIRES,
     GET_ORG_QUESTIONNAIRES,
     GET_PRODUCT,
     GET_CONTACT_QUESTIONNAIRES,
     GET_REPORT_DАТА,
     GET_CONVENTION_REPORT
} from './types';

export const sendEmail = (email) => dispatch => {

        client
                .post('/web/check/email', {email: email})
                .then(res => {
                    dispatch({
                        type: CHECK_USER,
                        payload: res.data
                    })
                })
                .catch(err => {
                   dispatch({
                        type: CHECK_USER,
                        payload: {user: false}
                    })
                });
}

export const sendTest = (test, history) => dispatch => {

        client
                .post('/web/send/test', test)
                .then(res => {
                    if(res.data.message === 'ROLE_QUESTIONNAIRE'){
                         dispatch({
                            type: GET_MESSAGE,
                            payload: {message: 'ROLE_QUESTIONNAIRE'}
                        })
                    }
                })
                .catch(err => {
                   
                });
}

export const clearCheckUser = () => dispatch => {
    dispatch({
                        type: CHECK_USER,
                        payload: null
                    })
}

export const sendNotificationQuestionnaire = () => dispatch => {    
    client
                .get('/api/quietionnaire/notification')
                .then(res => {
                    
                })
                .catch(err => {
                   
                });
}

export const getOrganizations = () => dispatch => {    
             client
                .get('/web/questionnaire/get/organizations')
                .then(res => {
                    dispatch({
                        type: GET_ORGANIZATIONS,
                        payload: res.data
                    })
                })
                .catch(err => {
                   
                });
}

export const getQuestionnaire = (id) => dispatch => {    
             client
                .get('/api/admin/quest-form/single/'+id)
                .then(res => {
                    dispatch({
                        type: GET_QUESTIONNAIRE,
                        payload: res.data
                    })                  
                   
                    if(res.data.sections.length > 0){
                     dispatch({
                        type: GET_SECTION,
                        payload: res.data.sections[0]
                    })
                    }
                })
                .catch(err => {
                    dispatch({
                        type: GET_QUESTIONNAIRE,
                        payload: {}
                    })
                });
}

export const getSection = (id) => dispatch => {    
     client
                .get('/api/admin/section-form/single/'+id)
                .then(res => {
                  dispatch({
                        type: GET_SECTION,
                        payload: res.data
                    })  
                })
                .catch(err => {
                    dispatch({
                        type: GET_SECTION,
                        payload: {}
                    })
                });
}

export const addField = (field, id, questionId) => dispatch => {
   
    client
        .post('/api/admin/section-form/add/'+id + '/' + questionId, field)
        .then(res => {
            dispatch({
                    type: ADD_FIELD,
                    payload: res.data
                })
        })
        .catch(err => {
                dispatch({
                    type: ADD_FIELD,
                    payload: {}
                })
        });
}

export const changeField = (id, field) => dispatch => {
    client
        .post('/api/admin/section-form/change/'+id, field)
        .then(res => {
        })
        .catch(err => {
        });
}

export const deleteField = (id) => dispatch => {
     client
        .get('/api/admin/section-form/delete/'+id)
        .then(res => {

        })
        .catch(err => {

        });
}

export const getUserQuestionnaires = () => dispatch => {
     client
        .get('/api/questionnaire/user')
        .then(res => {
            dispatch({
                    type: GET_USER_QUESTIONNAIRES,
                    payload: res.data
                })

        })
        .catch(err => {

        });
}

export const getMemberQuestionnaires = () => dispatch => {
     client
        .get('/api/questionnaire/member')
        .then(res => {
            dispatch({
                    type: GET_USER_QUESTIONNAIRES,
                    payload: res.data
                })

        })
        .catch(err => {
         if(err.response && err.response.data){
            showAToast(err.response.data.error, 'errorMissingAccess', 'error')
         }
        });
}

export const getOrgQuestionnaires = (id) => dispatch => {
     client
        .get('/api/admin/questionnaire/organization/'+id)
        .then(res => {
            dispatch({
                    type: GET_ORG_QUESTIONNAIRES,
                    payload: res.data
                })

        })
        .catch(err => {

        });
}

export const sortFields = (data) => dispatch => {
    
    client
        .post('/api/admin/section-form/save-position', data)
        .then(res => {
        })
        .catch(err => {
        });
}

export const getQuestionnaireFill = (id, sectionId, history, lock = null) => dispatch => {
    
    var url = '/api/questionnaire/quest-fill/'+id+'/'
    if(sectionId){
        url = '/api/questionnaire/quest-fill/'+id+'/'+sectionId
    }
     client
        .get(url,{params: {lock: lock}})
        .then(res => {
             dispatch({
                    type: GET_QUEST_FILL,
                    payload: res.data
                })
        })
        .catch(err => {
            if(err.response && err.response.data && err.response.data.error === 'Missing role.'){
               history.push('/sustainability-assessments')
                toast.success("Please, first asnwer a few eligibility questions to access the Questionnaire Respondent secure area - click on 'I WANT TO TAKE THE QUESTIONNAIRE'. ", {
                    position: "top-center",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    toastClassName: 'Toastify'})
            }
            
            if(err.response && err.response.data && err.response.data.error === 'Missing access.'){
               history.push('/contribute/welcome')
                toast.success("You do not have access to this questionnaire.", {
                    position: "top-center",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    toastClassName: 'Toastify'})
            }
            
        });
}


export const getQuestionnaireResult = (id) => dispatch => {
     client
        .get('/api/questionnaire/quest-fill/'+id)
        .then(res => {
             dispatch({
                    type: GET_QUEST_FILL,
                    payload: res.data
                })
        })
        .catch(err => {})
}

export const addQuestion = (data) => dispatch => {
    client
        .post('/api/admin/question/save', data)
        .then(res => {
            dispatch({
                        type: GET_SECTION,
                        payload: res.data
                    })  
        })
        .catch(err => {
        });
}

export const deleteQuestion = (id) => dispatch => {
     client
        .get('/api/admin/question/delete/'+id)
        .then(res => {

        })
        .catch(err => {

        });
}

export const getProduct = (id) => dispatch => {
     client
        .get('/api/admin/products-profile/data/'+id)
        .then(res => {
             dispatch({
                        type: GET_PRODUCT,
                        payload: res.data
                    })  
        })
        .catch(err => {

        });
}

export const postOverrideQuest = (id) => dispatch => {
    client
        .post('/api/quest-fill/override/'+id)
        .then(res => {
             
        })
        .catch(err => {
        });
}

export const setDependency = (ids, data) => dispatch => {
    client
        .post('/api/admin/quest-field/dependency/set', {ids: ids, data: data})
        .then(res => {
             showAToast('Success!', 'depSuccess', 'success')
        })
        .catch(err => {
        });
}

export const getQuestionnaireFillAdmin = (id) => dispatch => {
  
     client
        .get('/api/admin/questionnaire/quest-fill/'+id)
        .then(res => {
             dispatch({
                    type: GET_QUEST_FILL,
                    payload: res.data
        })
        })
        .catch(err => {
          
        });
}

export const getQuestionnaireFillMember = (id) => dispatch => {
  
     client
        .get('/api/member/questionnaire/quest-fill/'+id)
        .then(res => {
             dispatch({
                    type: GET_QUEST_FILL,
                    payload: res.data
        })
        })
        .catch(err => {
              if(err.response && err.response.data){
            showAToast(err.response.data.error, 'errorMissingAccess', 'error')
         }
           
        });
}

export const getLockQuestionnaire = (id, history) => dispatch => {
    client
        .get('/api/admin/quest-list/'+id+'/lock/quest')
        .then(res => {            
             showAToast(res.data.message, 'lockSuccess', 'success')
             history.push("/admin/questionnaire/active")
        })
        .catch(err => {
            showAToast('Error! Questionnaire is not locked.', 'errorLock', 'error')
        });
}

export const unlockButton = (route) => dispatch => {
    
    client
        .get(route)
        .then(res => {            
             showAToast(res.data.message, 'unlockSuccess', 'success')
             
        })
        .catch(err => {
            showAToast('Error111!', 'errorLock', 'error')
        });
}

export const getContactQuestionnaires = (id) => dispatch => {
     client
        .get('/api/questionnaire/contact/'+id)
        .then(res => {
            dispatch({
                    type: GET_CONTACT_QUESTIONNAIRES,
                    payload: res.data
            })
        })
        .catch(err => {

        });
}

export const removeDependency = (id) => dispatch => {
     client
        .get('/api/admin/quest-field/dependency-remove/'+id)
        .then(res => {
            
        })
        .catch(err => {

        });
}

export const getQuestionnaireEAReport = (id) => dispatch => {
     client
        .get('/api/questionnaire/quest-fill/'+id)
        .then(res => {
             dispatch({
                    type: GET_QUEST_FILL,
                    payload: res.data
                })
        })
        .catch(err => {})
}

export const getQuestionnaireReport = (id) => dispatch => {
     client
        .get('/api/questionnaire/user-report/'+id)
        .then(res => {
             dispatch({
                    type: GET_QUEST_FILL,
                    payload: res.data
                })
        })
        .catch(err => {
            
        })
}

export const getQuestionnaireReportAdmin = (id) => dispatch => {
     client
        .get('/api/admin/questionnaire/user-report/'+id)
        .then(res => {
             dispatch({
                    type: GET_QUEST_FILL,
                    payload: res.data
                })
        })
        .catch(err => {
            
        })
}

export const getReportData = (type) => dispatch => {
     client
        .get('/api/admin/report/chart-data/'+type)
        .then(res => {
             dispatch({
                    type: GET_REPORT_DАТА,
                    payload: res.data
                })
        })
        .catch(err => {
            
        })
}

export const postOverrideQuestionnaire = (id) => dispatch => {
    client
        .post('/api/admin/quest-form/override/'+id)
        .then(res => {
             
        })
        .catch(err => {
        });
}

export const getConventionsReportData = () => dispatch => {
     client
        .get('/api/admin/get-conventions/report')
        .then(res => {
             dispatch({
                    type: GET_CONVENTION_REPORT,
                    payload: res.data
                })
        })
        .catch(err => {
            
        })
}