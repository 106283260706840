import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Navbar from '../layout/Navbar';
import Grid from '@material-ui/core/Grid';
import Footer from '../layout/Footer'
import Sectors from './elements/Sectors'
import Contribute from './elements/Contribute'
import Contributions from './elements/Contributions'
import Filters from '../filter/Filters'
import {getContributionsFilter } from '../../actions/contributionActions'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { getLogActivityWithGeoIp } from '../../actions/profileActions';

const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

class KnowledgePracticeSector extends Component {
    constructor(props) {
        super(props);
        this.state = {
           contributions: [],
           filter: []
        }
    }

        componentDidMount() {
            this.props.getLogActivityWithGeoIp('Knowledge Practice Sector', 'GET', "Website usage")
        }

    handleChangeFilter = value => {

    this.props.getContributionsFilter(value)
         this.setState({
             filter: value
         })
     }

     handleLoadMore = (offset) => {
         let data = this.state.filter
         data['offset'] = offset
          this.props.getContributionsFilter(data, true)
     }

    render() {
        const { classes } = this.props;
        const { sector } = this.props.sector;
        
        return(
                <div className={classes.root}>
      <Grid container spacing={0}>
        <div  className="page-content  slideup page-animation" >
          <div className="focus-area-page">

            {sector &&
            <div className={classNames("heroshot", sector.name)} >
            <img all="image" src={sector.poster}/>
            <div className="text-area text-center col-md-8 col-md-offset-2">
            <img all="image" src={sector.icon}/>
            <h1 className="text-white text-bold ng-binding">{sector.name}</h1>
            </div>
            </div>
            }
        <div className="filter-area">


                <Filters onChange={this.handleChangeFilter} filterTotal={this.props.contribution.total} filterOffset={this.props.contribution.offset} calendar={false} id={this.props.match.params.id}/>


                </div>
       {this.props.contribution.contributions.length > 1 &&
       <Contributions onLoadMore={this.handleLoadMore} />
       }



       </div>

        <Footer/>
        </div>

        <Navbar/>
            </Grid>
        </div>);
    }

}

KnowledgePracticeSector.propTypes = {
    classes: PropTypes.object.isRequired,
    contribution: PropTypes.object.isRequired,
    getContributionsFilter: PropTypes.func.isRequired,
    sector: PropTypes.object.isRequired,
    getLogActivityWithGeoIp: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
        sector: state.sector,
        contribution: state.contribution
    });

KnowledgePracticeSector = withStyles(styles)(KnowledgePracticeSector)

export default connect(mapStateToProps, {getContributionsFilter, getLogActivityWithGeoIp})(withRouter(KnowledgePracticeSector));
