import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles, useTheme } from '@material-ui/core/styles'
import {getQuestionnaire, addField, getSection, changeField, deleteField, sortFields, addQuestion} from '../../../actions/questionnaireActions'
import Header from '../layout/Header'
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import AddIcon from '@material-ui/icons/Add';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import SettingsIcon from '@material-ui/icons/Settings';
import Section from './Section'
import Paper from '@material-ui/core/Paper';
import FormBuilder from './FormBuilder'
import { onLoadForm } from "../../../actions/formActions";
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Fab from '@material-ui/core/Fab';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { toast } from 'react-toastify'
import {sortableContainer, sortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import DialogAddQuest from './Helpers/DialogAddQuest'
import DialogOpenOption from './Helpers/DialogOpenOption'
import showAToast from '../../../helpers/showAToast'
import DialogOptionsDependency from './Helpers/DialogOptionsDependency'
import { unlockButton } from "../../../actions/questionnaireActions";
import DialogConfirm from '../../common/DialogConfirm'
import Truncate from 'react-truncate';
import DialogConfirmOverrideQuest from './DialogConfirmOverrideQuest'

const SortableItem = sortableElement(({value}) => <li className="sortFields"><Truncate lines={1} ellipsis={'...'}>{value.name ? value.name : value.id}</Truncate></li>);

const SortableContainer = sortableContainer(({children}) => {
  return <ul>{children}</ul>;
});

const drawerWidth = 300;
const styles = theme => ({
  root: {
    flexGrow: 1,
  },
   appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
   drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: 60,
    background: '#203648',
    color: 'white'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
   paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width: '100%',
  //  height: '100%',
    color: '#000000de'
  },
   rootForm: {
      marginLeft: 20,
      width: '100%',
      color: 'white'

   },
   formLabel: {
       fontSize: 14,
       marginTop: 10,
       textTransform: 'uppercase'
   },
    margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'fixed',
    top: theme.spacing(15),
    left: theme.spacing(2),
    background: '#203648',
  },
});

class QuestionnaireBuilder extends Component {
  constructor() {
    super();
    this.state = {
      right: false,
      left: false,
      showSection: null,
      fieldInSetting: null,
      openOption: false,
      option: null,
      opKey: null,
      points: null,
      openConfirm: false,
      fieldForDelete: null,
      open: false,
      changedOption: false, //property to notice if question need new history version because option is changed
      questions: [],
      openAddQuest: false,
      question: [],
      questionId: null,
      clickedSave: true, // if button save changes is clicked
      fieldId: null,
      hasChanges: false, //property to detect changes,
      questionField: null,
       unlockRoute: null,
       openConfirmUnlock: false
    };
  }

  addQuestion = id => event => {
      event.preventDefault()
      if(this.props.questionnaire.quest.locked === 'locked'){
           showAToast("This questionnaire is locked.", 'locked', 'error')
      }else{
      this.props.getSection(id)
      this.setState({
        showSection: id,
        openAddQuest: true,
         questionId: null,
         left: false
    })
      }
  }

  editQuestion = question => event => {
     event.preventDefault()
     if(this.props.questionnaire.quest.locked === 'locked'){
           showAToast("This questionnaire is locked.", 'locked', 'error')
      }else{
      this.setState({
        question: question,
        openAddQuest: true
    })
      }
  }

    closeAddQuestion = () => {
      this.setState({openAddQuest: false, question: [] });
  }

    saveQuest = (question) => {
        question.section = this.state.showSection;
        this.props.addQuestion(question)
        this.setState({openAddQuest: false, question: [] })
        this.updateSection()
    }
    openModal = () => {
        if(this.props.questionnaire.quest.locked === 'locked'){
           showAToast("This questionnaire is locked.", 'locked', 'error')
      }else{
        this.setState({
            open: true,
            questionId: null,
         left: false
        })
    }
    }

    closeSort = () => {
       this.setState({
            open: false
        })
        this.props.onLoadForm('initializeFromState2', '/api/admin/section-form/data/'+this.state.showSection);
    }
    onSortEnd = ({oldIndex, newIndex}) => {
     this.setState({
          questions: arrayMove(this.state.questions, oldIndex, newIndex),
        });
         var dataSort = []
        this.state.questions.map((quest, k)=>{
            dataSort.push({position: k, questionId: quest.id})
        })
        this.props.sortFields(dataSort)

 }
     componentDidMount() {
       this.props.getQuestionnaire(this.props.match.params.id)
       document.body.style.overflow = 'auto';
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.questionnaire && nextProps.questionnaire.section && this.state.showSection === null && typeof nextProps.questionnaire.section.id !== 'undefined'){

            this.setState({
                showSection: nextProps.questionnaire.section.id,
            })
        }

         if(nextProps.questionnaire && nextProps.questionnaire.section && nextProps.questionnaire.section.questions){
              this.setState({
                questions: nextProps.questionnaire.section.questions
            })
         }
    }

  handleDrawerOpen = (questionId) => {
      if(this.props.questionnaire.quest.locked === 'locked'){
           showAToast("This questionnaire is locked.", 'locked', 'error')
      }else{
      this.setState({left: true, questionId: questionId });
  }
  };

   handleDrawerClose = (anchor) => {

    if(anchor === 'right'){
        if(this.state.clickedSave){
            this.setState({
                right: false,
                fieldInSetting: null,
                fieldId: null,
                clickedSave: false,
                hasChanges: false,
                changedOption: false,
                option: null,
                opKey: null,
                points: null
            });
        }else if(!this.state.clickedSave && this.state.hasChanges){
            showAToast("Your changes are not saved!", 'close', 'error')
        }else if(!this.state.clickedSave && !this.state.hasChanges){
             this.setState({
                right: false,
                fieldInSetting: null,
                fieldId: null,
                clickedSave: false,
                hasChanges: false,
                changedOption: false,
                option: null,
                opKey: null,
                points: null
            });
        }
    }else{
        this.setState({
            left: false,
            questionId: null
        })
    }
  }

  handleClickSection = id => event => {
      event.preventDefault()
     this.props.getSection(id)
      this.setState({
        showSection: id
    })
  }

  handleDrawerRightOpen = (field, question) => e =>  {
      e.preventDefault()
      if(this.props.questionnaire.quest.locked === 'locked'){
           showAToast("This questionnaire is locked.", 'locked', 'error')
      }else{
      if(this.state.right){
         this.updateSection()
      }
       this.setState({
          right: true,
          fieldInSetting: field,
          changedOption: false,
          clickedSave: false,
          fieldId: field.fieldId,
          questionField: question
   });
      }
  }

  handleDiscardChanges = e =>  {
      e.preventDefault()
       this.setState({
            right: false,
            fieldInSetting: null,
            changedOption: false,
            clickedSave: false,
            fieldId: null
      });
      this.updateSection()
  }


  handleDeleteOpen = (fieldId) => e => {
      e.preventDefault()
      if(this.props.questionnaire.quest.locked === 'locked'){
           showAToast("This questionnaire is locked.", 'locked', 'error')
      }else{
      this.setState({openConfirm: true, fieldForDelete: fieldId });
  }
  }

  handleOpenAddField = (fieldId) => e => {
      e.preventDefault()
      if(this.props.questionnaire.quest.locked === 'locked'){
           showAToast("This questionnaire is locked.", 'locked', 'error')
      }else{
      this.setState({left: true, fieldForDelete: fieldId });
  }
  }

  closeConfirm = () => {
      this.setState({openConfirm: false, fieldForDelete: null });
  }

  deleteField = () => {
       if(this.props.questionnaire.quest.locked === 'locked'){
           showAToast("This questionnaire is locked.", 'locked', 'error')
      }else{
      this.props.deleteField(this.state.fieldForDelete)
      this.setState({openConfirm: false, fieldForDelete: null });
       this.updateSection()
   }
  }

  submit = (values) => {

  }

  addField = (field) => {
       if(this.props.questionnaire.quest.locked === 'locked'){
           showAToast("This questionnaire is locked.", 'locked', 'error')
      }else{
      this.props.addField(field, this.state.showSection, this.state.questionId)
      this.updateSection()
  }
  }

    updateSection = () => {
        setTimeout(() => {
           this.props.getSection(this.state.showSection)
          this.props.onLoadForm('initializeFromState2', '/api/admin/section-form/data/'+this.state.showSection);
           }, 3000)
    }

   handleChangeSetting = event => {
        var settings = this.state.fieldInSetting;
        if(event.target.name === 'required' || event.target.name === 'isMulti' || event.target.name === 'preview' ){
            settings[event.target.name] = event.target.checked;
        }else{
            settings[event.target.name] = event.target.value
        }
        this.setState({
            fieldInSetting: settings,
            hasChanges: true
        })

   }

   handleChangeOption = (event) => {
       var field = this.state.fieldInSetting;
       var opt = this.state.fieldInSetting.options;
       var option = opt[this.state.opKey];
       option[event.target.name] = event.target.value;
       opt[this.state.opKey] = option
       field.options = opt;

        this.setState({
            fieldInSetting: field,
            option: option.label,
            points: option.points,
            changedOption: true,
            hasChanges: true
        })

   }


    //on click Save Changes in right section
   handleSaveChanges = (event) => {
       event.preventDefault()
       var fieldsPoints = 0;
       if(this.state.fieldInSetting){
         if(this.props.questionnaire.section.points){
           var fields = this.props.questionnaire.section.fields;

           if(fields.length > 0){
               fields.map((field, к) => {
                 if(field.fieldId !== this.state.fieldInSetting.fieldId){
                    fieldsPoints += field.pointsOptions ? parseInt(field.pointsOptions) : 0;
                 }else{
                  var options = this.state.fieldInSetting.options;
                  var radioOptionsPoints = 0;
                  if(options && options.length > 0){
                      options.map(option => {
                                if(this.state.fieldInSetting.type === "radio"){
                                    if(parseInt(option.points) > radioOptionsPoints){
                                        radioOptionsPoints = parseInt(option.points);
                                    }

                                }else{
                                    fieldsPoints += option.points ? parseInt(option.points) : 0;
                                }

                                })
                                if(radioOptionsPoints){
                                     fieldsPoints += radioOptionsPoints;
                                     radioOptionsPoints = 0;
                                }
                  }
                 }
                })
           }
       }

            if(fieldsPoints > this.props.questionnaire.section.points){
              showAToast('Your changes are not saved! Points of questions options are more than points of section!', 'points', 'error')
            }else{
           var data = this.state.fieldInSetting
           data.changedOption = this.state.changedOption
           this.setState({ clickedSave: true})
           this.props.changeField(this.state.fieldInSetting.fieldId, data)
           showAToast("Your changes have been saved successfully!", 'saved', 'success')
             this.updateSection()
              this.setState({
                right: false,
                fieldInSetting: null,
                fieldId: null,
                clickedSave: false,
                hasChanges: false,
                changedOption: false,
                option: null,
                opKey: null,
                points: null
            });
        }
       }
   }

  handleOpenChangeOption = (option,opKey, points) => {
  if(this.props.questionnaire.quest.locked === 'locked'){
           showAToast("This questionnaire is locked.", 'locked', 'error')
      }else{
      this.setState({
            openOption: true,
            option: option,
            opKey: opKey,
            points: points
        })
    }
  }

  closeOption = () => {
  if(this.state.option){
      this.setState({
          openOption: false,
          option: null,
          opKey: null,
          points: null
      })
  }else{
       showAToast("Option can not be empty!", 'options', 'error')
  }
  }

  addOption = event =>{
      event.preventDefault();
       if(this.props.questionnaire.quest.locked === 'locked'){
           showAToast("This questionnaire is locked.", 'locked', 'error')
      }else{
       var field = this.state.fieldInSetting;
       var opt = this.state.fieldInSetting.options;
       opt = [...opt, {value: opt.length, label: 'label'+(opt.length+1)}]
       field.options = opt;
        this.setState({
            fieldInSetting: field
        })
         this.props.changeField(field.fieldId, field)
         this.updateSection()
     }
  }

   deleteOption = event =>{
      event.preventDefault();
       if(this.props.questionnaire.quest.locked === 'locked'){
           showAToast("This questionnaire is locked.", 'locked', 'error')
      }else{
       var field = this.state.fieldInSetting;
       var opt = this.state.fieldInSetting.options;
       opt.splice(this.state.opKey, 1);
       field.options = opt;
        this.setState({
          fieldInSetting: field,
          openOption: false,
          option: null,
          opKey: null,
          changedOption: true
        })
    }
      /*   this.props.changeField(field.fieldId, field)
         setTimeout(() => {
      this.props.onLoadForm('initializeFromState2', '/api/admin/section-form/data/'+this.state.showSection);
       }, 3000)*/
  }

  handleEnter = (e) => {
       if(this.props.questionnaire.quest.locked === 'locked'){
           showAToast("This questionnaire is locked.", 'locked', 'error')
      }else{
    if (e.key === 'Enter') {
        if(this.state.option){
           this.setState({
                openOption: false,
                option: null,
                opKey: null,
                points: null
            })
          }else{
          showAToast("Option can not be empty!", 'options', 'error')
             }
          }
      }
  }

  handleNo = () => {
         this.setState({
             openConfirmUnlock: false,
             unlockRoute: null
         })
    }

    openConfirm = (route) => {
        this.setState({
             openConfirmUnlock: true,
             unlockRoute: route
         })
    }

    handleYes = () => {
        this.props.unlockButton(this.state.unlockRoute)
         this.setState({
             openConfirmUnlock: false,
             unlockRoute: null
         })
          setTimeout(() => {
         this.props.getQuestionnaire(this.props.match.params.id)
          }, 3000)
    }


  render() {
     const { classes } = this.props;
     const { quest } = this.props.questionnaire;
     const {profile} = this.props.profile;
    var locked = null;
    if(quest && quest.locked === 'locked'){
        locked = "This questionnaire is locked."
    }
    return (
      <Grid container spacing={0} >
          <DialogConfirm handleNo={this.handleNo} handleYes={this.handleYes} openConfirm={this.state.openConfirmUnlock}/>
          <div className={classes.appBar}>
          <Header/>
          </div>
      {quest &&
        <Grid container item md={12} lg={12} style={{marginTop: 60}}>
        {locked && <Grid  item md={12} lg={12} style={{marginTop: 60, fontSize: 20}} className="text-center">{locked}
            {this.props.auth.user.roles.includes('ROLE_SUPER_ADMIN') &&
               <Button variant="outlined" color="primary" href="#" onClick={(route) => this.openConfirm('/api/super-admin/unlock/questionnaire/'+quest.id)}  style={{marginLeft: 15}}>Unlock questionnaire</Button>
              } </Grid>}
              
 {profile && quest && quest.lockedById && quest.lockedById !== profile.id &&
         <Grid md={12} item container  justify="flex-start">
           <Grid item  md={2}></Grid>
           <Grid item  md={8}>
            <DialogConfirmOverrideQuest builder={true} id={this.props.match.params.id} formRoute={'/api/admin/section-form/data/'+this.state.showSection } form={'initializeFromState2'}/>
            </Grid>
         </Grid>}
         
         <Grid container item md={2} lg={2} style={{marginTop: 60}}>
             </Grid>
  {profile && (!quest.lockedBy || quest.lockedBy === null || quest.lockedById === profile.id) &&
         <Grid container item md={8} lg={8} style={{marginTop: 60}}>
             <a href={'/admin/questionnaire/edit/'+quest.id} style={{color: 'gray', marginBottom: 30}}> {quest.workName && <h4><ChevronLeftIcon/>{quest.workName}</h4>} {quest.name}</a>

             {quest.sections && quest.sections.length > 0 && quest.sections.map((section, key) => (
             <Paper className={classes.paper} key={key}>
              <h4>{section.partition}
              {this.state.showSection === section.id && section.id===this.props.questionnaire.section.id &&
               <span><Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={this.openModal}
               className={classes.margin}>Sort questions</Button>
               <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={this.addQuestion(section.id)}
              className={classes.margin}>Add question</Button>
              <DialogAddQuest
              handleCloseAddQuest={this.closeAddQuestion}
              openAddQuest={this.state.openAddQuest}
              question={this.state.question}
              saveQuest={this.saveQuest}/>
               </span>}
                        </h4>
              <a href="#" onClick={this.handleClickSection(section.id)}><h3>{section.name}
                        </h3>{section.points && <label>Points: {section.points}</label>}</a>

                  {this.state.showSection === section.id && section.id===this.props.questionnaire.section.id && this.props.questionnaire.section.questions.length > 0 &&
                        <FormBuilder
                            onSubmit={this.submit}
                            title={section.name}
                            form={'initializeFromState2'}
                            formRoute={'/api/admin/section-form/data/'+this.state.showSection }
                            saveRoute={''}
                            section={this.props.questionnaire.section}
                            alone={false}
                            next={parseInt(quest.sections.length) === parseInt(key+1) ? false : true}
                            handleDrawerRightOpen={(field, question) => this.handleDrawerRightOpen(field, question)}
                            handleDeleteOpen={(field) => this.handleDeleteOpen(field)}
                            handleDrawerOpen={(questionId) => this.handleDrawerOpen(questionId)}
                            editQuestion={(question) => this.editQuestion(question)}
                            isNew={true}
                            sectionPoints={section.points ? section.points : 0}
                            fieldId={this.state.fieldId}
                            questionId={this.state.questionId}
                          />
                        }
             </Paper>
              ))}
             </Grid>
             }
           <Grid container item md={2} lg={2} style={{marginTop: 60}}>

        </Grid>
    <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={this.state.left}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader} >
          <IconButton onClick={() => this.handleDrawerClose('left')} style={{float: 'right'}}>
            <CloseIcon style={{color: 'white'}} />
          </IconButton>
        </div>
        <Divider />
        <List style={{color: 'white'}}>
          {quest.fieldTypes && quest.fieldTypes.map((field, index) => (
            <ListItem button key={index} onClick={() => this.addField(field)}>
              <ListItemIcon style={{color: 'white'}}><i className={field.iconClass}></i></ListItemIcon>
              <ListItemText primary={field.label} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={this.state.right}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
      <div className={classes.drawerHeader} >
          <IconButton onClick={() => this.handleDrawerClose('right')} style={{float: 'right'}}>
            <CloseIcon style={{color: 'white'}} />
          </IconButton>
        </div>
        <List style={{color: 'white'}}>
            {this.state.fieldInSetting &&
            <form className={classes.rootForm} >
              <div className={classes.formLabel}>{'Label'}</div>
              <TextField
              id="outlined-basic"
              variant="outlined"
              name="label"
              onChange={this.handleChangeSetting}
              value={this.state.fieldInSetting.label || ''}
              InputProps={{  style: {color: 'white', fontSize: 20} }}/>
             <div className={classes.formLabel}>{'Header'}</div>
              <TextField
              id="outlined-basic"
              variant="outlined"
              name="header"
              onChange={this.handleChangeSetting}
              value={this.state.fieldInSetting.header || ''}
              InputProps={{  style: {color: 'white', fontSize: 20} }}/>
                      {this.state.fieldInSetting.type === 'checkbox' &&
           <span><div className={classes.formLabel}>{'Points'}</div>
              <TextField
              id="outlined-basic"
              variant="outlined"
              onChange={this.handleChangeSetting}
              name="points"
              value={this.state.fieldInSetting.points  || ''}
              InputProps={{  style: {color: 'white', fontSize: 20} }}/></span>}
               <FormGroup row>
                <FormControlLabel
                  control={<Switch
                      checked={Boolean(this.state.fieldInSetting.required)}
                      onChange={this.handleChangeSetting}
                      name="required" />}
                      label="Required"
                />
                </FormGroup>
                {this.state.fieldInSetting.type === 'file' &&
                 <span>
                 <FormGroup row>
                <FormControlLabel
                  control={<Switch
                      checked={Boolean(this.state.fieldInSetting.preview)}
                      onChange={this.handleChangeSetting}
                      name="preview" />}
                      label="Preview"
                />
                </FormGroup>

                </span>}
            {this.state.fieldInSetting.options &&
             <span>
              <div className={classes.formLabel}>{'Options'}</div>
                <div style={{overflowY: 'scroll', height: '100px', border: '1px solid #fff', width: '70%' }}>
         {this.state.fieldInSetting.options && this.state.fieldInSetting.options.length > 0 && this.state.fieldInSetting.options.map((option,opKey) => (
                 <div key={opKey} onClick={() => this.handleOpenChangeOption(option.label, opKey, option.points)}>{option.label}</div>
                  ))}
                </div>
              <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={this.addOption}
              className={classes.margin}><AddIcon />Add option</Button>
                {this.state.fieldInSetting.type === 'radio' &&
                            <DialogOptionsDependency
                                      field={this.state.fieldInSetting}
                                       question={this.state.questionField}
                                       updateSection={this.updateSection}/>}
                {this.state.fieldInSetting.type === 'select' && <FormGroup row>
                <FormControlLabel
                  control={<Switch
                      checked={Boolean(this.state.fieldInSetting.isMulti)}
                      onChange={this.handleChangeSetting}
                      name="isMulti" />}
                      label="Multiple"
                />
                </FormGroup>}
             </span>
          }
          <div>
          <Button onClick={this.handleSaveChanges} variant="contained" color="primary" className={classes.margin}>Save changes</Button>
          <Button onClick={this.handleDiscardChanges} variant="contained" color="secondary" className={classes.margin}>Discard</Button>
          </div>
            </form>
          }


        </List>
        <Divider />
       </Drawer>
                  <DialogOpenOption
                  openOption={this.state.openOption}
                  closeOption={this.closeOption}
                  option={this.state.option}
                  points={this.state.points}
                  handleChangeOption={this.handleChangeOption}
                  handleEnter={this.handleEnter}
                  deleteOption={this.deleteOption}/>
                            <Dialog
                        onClose={this.closeConfirm}
                        aria-labelledby="simple-dialog-title"
                        open={this.state.openConfirm}
                        maxWidth="sm"
                        fullWidth
                        BackdropProps={{
                        classes: {
                         root: classes.rootDialog
                        }
                       }}>

                         <DialogContent>
                         <section className="newPanel">
                            <div className="panel-body">
                                <div>
                                    <div className="text-center">

                                        <h3>
                                            Are you sure?
                                        </h3>
                                        <button className="button-outlines green text-center"
                                                onClick={this.closeConfirm}>
                                            No
                                        </button>
                                        <button className="button-primary-edited text-center btn-ok"
                                                onClick={this.deleteField}>
                                           Yes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>
                         </DialogContent>
                      </Dialog>
                       <Dialog
                        onClose={this.closeSort}
                        aria-labelledby="simple-dialog-title"
                        open={this.state.open}
                        maxWidth="sm"
                        fullWidth
                        >
                        <DialogTitle id="simple-dialog-title">Sort fields<span className="pull-right pointer" onClick={this.closeSort}>
                                 <i className="far fa-times-circle"></i></span>
                                 </DialogTitle>
                        <DialogContent>
                        <SortableContainer onSortEnd={this.onSortEnd}>
                         {this.state.questions && this.state.questions.map((field, index) => (
          <SortableItem key={`item-${field.id}`} index={index} value={field} />
        ))}
                        </SortableContainer>
                       </DialogContent>
                        </Dialog>

             </Grid>

                }
      </Grid>
    );
  }
}

QuestionnaireBuilder.propTypes = {
  questionnaire: PropTypes.object.isRequired,
   auth: PropTypes.object.isRequired,
  getQuestionnaire: PropTypes.func.isRequired,
  addField:  PropTypes.func.isRequired,
  getSection: PropTypes.func.isRequired,
  onLoadForm: PropTypes.func.isRequired,
  changeField: PropTypes.func.isRequired,
  deleteField: PropTypes.func.isRequired,
  sortFields: PropTypes.func.isRequired,
  addQuestion: PropTypes.func.isRequired,
   profile: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  questionnaire: state.questionnaire,
  auth: state.auth,
  profile: state.profile,
});

QuestionnaireBuilder = withStyles(styles)(QuestionnaireBuilder)

export default connect( mapStateToProps, { unlockButton, getQuestionnaire, addField, getSection, onLoadForm, changeField, deleteField, sortFields, addQuestion })(withRouter(QuestionnaireBuilder));
