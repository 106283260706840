import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {Link} from 'react-router-dom';
import {onCellActionTrigger, fileRedirect, fileDownload} from "../../../actions/gridActions";
import Tooltip from '@material-ui/core/Tooltip';

class CustomCellRenderer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            keys: {}
        };

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.generateRoute = this.generateRoute.bind(this);
        this.onCellActionTrigger = this.onCellActionTrigger.bind(this);
        this.getChildsFromList = this.getChildsFromList.bind(this);
        this.openCellMenu = this.openCellMenu.bind(this);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({keys: {}});
        }
    }

    generateRoute(link) {
        if (!('params' in link)) {
            return link.url;
        }

        var url = link.url;

        if ('sourceParams' in link.params || 'rowParams' in link.params) {
            url = link.url;

            if ('sourceParams' in link.params) {
                link.params.sourceParams.map((param) => {
                    url = url.replace('{' + param.target + '}', param.source);
                });
            }

            if ('rowParams' in link.params) {
                link.params.rowParams.map((param) => {

                if(typeof this.props[param.source] === 'object' && typeof this.props[param.source][param.target] === 'string'){
                    url = url.replace('{' + param.target + '}', this.props[param.source][param.target]);
                }else{
                    url = url.replace('{' + param.target + '}', this.props[param.source]);
                }
                });
            }

            return url;
        }

        link.params.map(param => {
            if (param.source in this.props) {
                url = url.replace('{' + param.target + '}', this.props[param.source]);
            }
        });

        return url;
    }

    getChildsFromList(list) {
        var source = this.props[list.source];

        var result = [];
        if (!('params' in list.link)) {
            return list.link.url;
        }

        source.map((item, key) => {
            var url = item.url ? item.url : list.link.url;

            if ('sourceParams' in list.link.params) {
                list.link.params.sourceParams.map((param) => {
                    url = url.replace('{' + param.target + '}', item[param.source]);
                });
            }

            if ('rowParams' in list.link.params) {
                list.link.params.rowParams.map((param) => {
                    url = url.replace('{' + param.target + '}', this.props[param.source]);
                });
            }

            result.push(<li className="submenus-item" key={key}>
                <a href={url} target={list.link.target ? list.link.target : ''} className="submenus-link" rel="noopener noreferrer">
                    {item[list['labelSource']]}
                </a>
            </li>);
        });

        return result;
    }

    openCellMenu(key, child) {
        if (child || child === 0) {
            let keys = this.state.keys;
            keys['dropdown' + key + '-' + child] = !this.state.keys['dropdown' + key + '-' + child];
            this.setState({
                currentRowOpened: key,
                currentRowChildOpened: child,
                keys
            });
        }
        else {
            let keys = this.state.keys;
            keys['dropdown' + key] = !this.state['dropdown' + key];
            this.setState({
                currentRowOpened: key,
                currentRowChildOpened: false,
                keys
            });
        }
    }

    onCellActionTrigger(button) {
        const route = this.generateRoute(button.link);
        this.props.onCellActionTrigger(button, this.props.node, route);
    }

    clickFileRedirect = (route) => event => {
        event.preventDefault()
        this.props.onFileRedirect(route)
    }

    clickFileDownload = (route) => event => {
        event.preventDefault()
        this.props.onFileDownload(route, this.props.data.name)
    }

    render() {

        return (
                <span>
        {this.props.buttons && this.props.buttons.length > 0 &&
            <span>
                {
                    this.props.buttons.map((button, key) => {
                        if ('checkPermissions' in button) {
                            let stop = false;
                            button.checkPermissions.map(item => {
                                if (!this.props.data[item]) {
                                    stop = true;
                                }
                            });
                            if (stop) return;
                        }
                        if ('children' in button) {
                            return <span className="dropdown-grid" key={key}>
                                <a href="#" className="btn" onClick={() => this.openCellMenu(key)}>
                                    <i className="fa fa-ellipsis-v" aria-hidden="true" style={{marginLeft: 10}}></i>
                                </a>
                                {
                                    this.state.keys['dropdown' + key] &&
                                    <nav className="submenus"
                                         ref={this.setWrapperRef}>
                                        <ul className="submenus-items">
                                            {
                                                button.children.map((child, childKey) => {
                                                    if ('checkPermissions' in child) {
                                                        let stop = false;
                                                        child.checkPermissions.map(item => {
                                                            if (!this.props.data[item]) {
                                                                stop = true;
                                                            }
                                                        });
                                                        if (stop) return;
                                                    }
                                                    if ('list' in child) {
                                                        const childEls = this.getChildsFromList(child.list);
                                                        return <li className="submenus-item" style={{marginLeft: 0}}
                                                                   key={childKey}>
                                                            <a href="#" className="submenus-link"
                                                               onClick={() => this.openCellMenu(key, childKey)}>
                                                               {child.label}
                                                                <i className="fa fa-angle-down"
                                                                   aria-hidden="true"></i>
                                                            </a>
                                                            {
                                                                this.state.keys['dropdown' + key + '-' + childKey] &&
                                                                <ul className="submenus-items inner-list">
                                                                    {childEls}
                                                                </ul>
                                                            }
                                                        </li>
                                                    }
                                                    else {
                                                        if ('link' in child && !('redirect' in child && child.redirect === false)) {{console.log('oks')}
                                                            var route = this.generateRoute(child.link);
                                                            return child.reactComponent ?
                                                                <li className="submenus-item"
                                                                    style={{marginLeft: 0}}
                                                                    key={childKey}>
                                                                    <a href={route}
                                                                          className="submenus-link"
                                                                          key={key}>
                                                                        {child.label}
                                                                    </a>
                                                                </li> :
                                                                <li className="submenus-item"
                                                                    style={{marginLeft: 0}}
                                                                    key={childKey}>
                                                                    <a href={route}
                                                                       target={child.link.target ? child.link.target : ''}
                                                                       className="submenus-link"
                                                                       rel="noopener noreferrer"
                                                                       key={key}>
                                                                        {child.label}
                                                                    </a>
                                                                </li>
                                                        }
                                                        else {
                                                            return <li className="submenus-item"
                                                                       style={{marginLeft: 0}}
                                                                       key={childKey}>
                                                                <a onClick={() => this.onCellActionTrigger(child)}
                                                                   className="submenus-link" key={key}>
                                                                    {child.label}
                                                                </a>
                                                            </li>
                                                        }
                                                    }
                                                })
                                            }
                                        </ul>
                                    </nav>
                                }
                                </span>
                        }
                        else {
                            if ('link' in button && !('redirect' in button && button.redirect === false)) {
                                var route = this.generateRoute(button.link);
                                return button.reactComponent ? <Tooltip placement="top" title={button.tooltip ? button.tooltip : ""}  key={key}><a href={route} className="marg" key={key}>
                                    <i className={button.icon} aria-hidden="true"></i>
                                </a></Tooltip> : <Tooltip placement="top" title={button.tooltip ? button.tooltip : ""}  key={key}><a href={route} target={button.link.target ? button.link.target : ''}
                                             className="marg" key={key} rel="noopener noreferrer">
                                    <i className={button.icon} aria-hidden="true"></i>
                                </a></Tooltip>
                            }else if('link' in button && ('fileRedirect' in button && button.fileRedirect == true)){
                               var route = this.generateRoute(button.link);
                               if(button.extensions.includes(this.props.data.extension)){
                               return <Tooltip placement="top" title={button.tooltip ? button.tooltip : ""}  key={key}><a href='#' onClick={this.clickFileRedirect(route)}
                                             className="marg" key={key}>
                                    <i className={button.icon} aria-hidden="true"></i>
                                </a></Tooltip>
                                }
                            }else if('download' in button && button.download == true){
                               var route = this.generateRoute(button.link);
                               return <Tooltip placement="top" title={button.tooltip ? button.tooltip : ""}  key={key}><a href='' onClick={this.clickFileDownload(route)}
                                             className="marg" key={key}>
                                    <i className={button.icon} aria-hidden="true"></i>
                                </a></Tooltip>

                            }else {
                            return <Tooltip placement="top" title={button.tooltip ? button.tooltip : ""}  key={key}>
                            <a onClick={() => this.onCellActionTrigger(button)} className="marg" key={key}>
                                    <i className={button.icon} aria-hidden="true"></i>
                                </a></Tooltip>
                            }
                        }
                    })
                }
          </span>
          }
          </span>);
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    gridProperties: state.gridProperties
});

const mapDispatchToProps = dispatch => ({
    onCellActionTrigger: (button, node, route) => dispatch(onCellActionTrigger(button, node, route)),
    onFileRedirect: (route) => dispatch(fileRedirect(route)),
    onFileDownload: (route, name) => dispatch(fileDownload(route, name))
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CustomCellRenderer);
