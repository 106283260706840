import React from "react";
import PropTypes from "prop-types";

const ImagePreview = ({ file, onDelete }) => (
 <span>
     {file &&
    <div key={file.name} className="render-preview">   
       
      <div className="image-container">
       {file.type.includes('image') ?  
        <img src={file.preview} alt={file.name} />
        :
         <img src='/images/file_Icon.png' alt={file.name} />
        }
      </div>
      
      <div className="details">
        {file.name} - {(file.size / 1024000).toFixed(2)}MB
      </div>
     
    </div>
    }
  </span>
 );

ImagePreview.propTypes = {
  file: PropTypes.object,
  onDelete: PropTypes.func
};

export default ImagePreview;