import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {getQuestionnaireFillAdmin} from '../../../actions/questionnaireActions'
import Form from './Form'
import { onSave, onSaveData } from "../../../actions/formActions";
import Layout from '../../../components/admin/layout/Layout'
import {Link} from 'react-router-dom';

const drawerWidth = 300;
const styles = theme => ({
  root: {
    flexGrow: 1,
  },
   drawerPaper: {
    width: drawerWidth,
    marginTop: 100,
 //   background: '#fff',
   // color: 'white'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
   paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width: '100%',
  //  height: '100%',
    color: '#000000de'
  },
    extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'fixed',
    top: theme.spacing(15),
    left: theme.spacing(2),
    background: '#203648',
  },
});

class QuestResult extends Component {
  constructor() {
    super();
    this.state = {
      openMenu: false
    };
  }

     componentDidMount() {
        this.props.getQuestionnaireFillAdmin(this.props.match.params.id)
    }

    componentWillReceiveProps(nextProps) {

    }

handleSave = (values, props) => {

}

submit = (values, props) => {

}



handleClickSection = (id) => event => {
    event.preventDefault()

}



  render() {
     const { classes } = this.props;
     const {questFill} = this.props.questionnaire;
     let url = null;
     let backUrl = null; 
      let nextUrl = null; 
     
     if(this.props.match.params.part == 1){
        url = '/api/supplier-quest/data/'+ this.props.match.params.id;
        nextUrl = '/admin/questionnaire/result/2/'+this.props.match.params.id
     }else{
         url = '/api/admin/quest/form-result/data/' +  this.props.match.params.id;
         backUrl = '/admin/questionnaire/result/1/'+this.props.match.params.id
     }

    return (
             <Layout>
      <Grid container className={classes.root} direction="row" justify="flex-start">
          <Grid item  md={1}></Grid>
           {questFill && <Grid item container md={10}  >
                   <Form
                            onSubmit={this.submit}
                            title={null}
                            form={'initializeFromState2'}
                            formRoute={url}
                            saveRoute={''}
                            section={questFill.section}
                            alone={true}
                            nextUrl={nextUrl}
                            backUrl={backUrl}
                            isNew={true}
                            handleSave={this.handleSave}
                            submitButton={false}
                            isResult={true}
                            download={true}
                          />
                </Grid>}
     <Grid item  md={2} style={{margin: 60}}> </Grid>
     
      </Grid>
       </Layout>
    );
  }
}

QuestResult.propTypes = {
  questionnaire: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getQuestionnaireFillAdmin: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  formProperties: PropTypes.object.isRequired,
  onSaveData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  questionnaire: state.questionnaire,
   formProperties: state.formProperties
});

QuestResult = withStyles(styles)(QuestResult)

export default withRouter(connect( mapStateToProps, { getQuestionnaireFillAdmin, onSave, onSaveData })(QuestResult));
