import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import ProfileActions from '../dashboard/ProfileActions';
import Spinner from '../common/Spinner';
import { getUserQuestionnaires } from '../../actions/questionnaireActions';
import Navbar from '../layout/Navbar';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Questionnaires from './Questionnaires'
import { getLogActivityWithGeoIp, getCurrentProfile } from '../../actions/profileActions';

const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

class UserQuestionnaires extends Component {
  constructor() {
    super();
    this.state = {
      logged: false
    };
  }

     componentDidMount() {
        this.props.getCurrentProfile();
        this.props.getUserQuestionnaires();
    }

  componentWillReceiveProps(nextProps) {
        if(nextProps.profile && nextProps.profile.profile && nextProps.profile.profile.roles.includes('ROLE_QUESTIONNAIRE') && !this.state.logged){
            this.props.getLogActivityWithGeoIp('User Questionnaires', 'GET', "QR SA tool")
            this.setState({
                logged: true
            })
        }
 }


  render() {
      const { classes } = this.props;

   const { profile } = this.props.profile;
    let dashboardContent;
    if (profile === null) {
        dashboardContent = <Spinner />;
    }else{
        dashboardContent = <ProfileActions profileItem={profile}/>
    }

    return (           
      <Grid container spacing={0} direction="row">
   <div className="container-fluid" >

            <div className="page-content  slide page-animation">
            {dashboardContent}
                <div className="clearfix "></div>
                <Grid item md={2} lg={2}></Grid>
                <Questionnaires questionnaires={this.props.questionnaire.usersQuests} report={true} role="user"/>
               
                </div>
                 <Navbar/>
                </div>
      </Grid>
    );
  }
}

UserQuestionnaires.propTypes = {
    getCurrentProfile: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    getUserQuestionnaires: PropTypes.func.isRequired,
    questionnaire:  PropTypes.object.isRequired,
    getLogActivityWithGeoIp: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
   profile: state.profile,
   auth: state.auth,
   questionnaire: state.questionnaire,
});

UserQuestionnaires = withStyles(styles)(UserQuestionnaires)

export default connect( mapStateToProps, { getCurrentProfile, getUserQuestionnaires, getLogActivityWithGeoIp })(withRouter(UserQuestionnaires));
