import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import LoginForm from '../../layout/LoginForm'
import { config } from '../../../config'

class Contribute extends Component {


    render() {
        const { isAuthenticated, user } = this.props.auth;
                return (
<Grid item  sm={6} md={12} xs={12} lg={12} className="contribution-area">
   {isAuthenticated  && (user.roles.includes('ROLE_CONTRIBUTOR') || user.roles.includes('ROLE_ADMIN') || user.roles.includes('ROLE_SUPER_ADMIN')) ?
             <span>
             <img src={config.API_URL +"/bundles/contribution/images/icons/icon-gradient.png?v1.0"} className="contribution-img" alt={"Contribute icon"}/>
               <h3 className="text-uppercase blue-button text-uppercase">Contribute</h3>
             </span>
             :
        <LoginForm role="contribute"/>
            }
</Grid>
);
  }
}
Contribute.propTypes = {
    auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {  })(Contribute);
