import React, { Component } from 'react';

export default class CustomTooltip extends Component {
        constructor(props) {
        super(props);
}
  getReactContainerClasses() {
    return ['custom-tooltip'];
  }

  render() {
       
   console.log(this.props.api)
  //  const data = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex).data;
      console.log(this.props.colDef.tooltipText)
      console.log(this.props)
    return (
       <div className={'panel panel-primary'}>
       
        <div className="panel-body">
         {this.props.colDef.tooltipText && <h4 style={{ whiteSpace: 'nowrap' }}>{this.props.colDef.tooltipText}</h4>}
          {this.props.colDef.headerTooltip && <h4 style={{ whiteSpace: 'nowrap' }}>{this.props.colDef.headerTooltip}</h4>}
        </div>
      </div>
    );
  }
}