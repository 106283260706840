import {
    FILE_PROFILE,
    GET_USER_MEMBERS,
    GET_USER_MEMBERS_FILES,
    ADD_USER_MEMBERS_FILE,
    REMOVE_USER_MEMBERS_FILE
} from '../actions/types';

const initialState = {
    userMembers: [],
    file: null,
    userMemberFiles: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FILE_PROFILE:
            return {
                ...state,
                file: action.payload
            };
         case GET_USER_MEMBERS:
            return {
                ...state,
                userMembers: action.payload
            };  
        case GET_USER_MEMBERS_FILES:
            return {
                ...state,
                userMemberFiles: action.payload
            }; 
         case ADD_USER_MEMBERS_FILE:
             let userMemberFiles = state.userMemberFiles
            return {
                ...state,
                userMemberFiles: [action.payload, ...userMemberFiles]
            }; 
         case REMOVE_USER_MEMBERS_FILE:
             let memberFiles = state.userMemberFiles
             if(memberFiles){
                 memberFiles.map((file, i) => {
                     if(parseInt(file.id) === parseInt(action.payload.id)){
                         memberFiles.splice(i, 1)
                     }
                 })
             }
            return {
                ...state,
                userMemberFiles: memberFiles
            };    
        default:
            return state;
    }
}