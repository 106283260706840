import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getContribution } from '../../../actions/contributionActions';
import Layout from '../../../components/admin/layout/Layout'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';



class EditContribution extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
       this.props.getContribution(this.props.match.params.id)
    }

    componentWillReceiveProps(nextProps) {

    }

    render() {

  

        return (
                <Layout>
                <div className="homepage-news">
                <div className="max-width">
                    <h1 className="text-center text-black">Edit Contribution</h1>
                    <Grid
                      container
                      spacing={8}
                      style={{
                        paddingLeft: 15,
                        paddingRight: 15
                      }}>
                    {'  '}
                    <div style={{clear: 'both' }}>
                    </div>
                    </Grid>
                    
                </div>
              </div>
</Layout>

                );
    }
}

EditContribution.propTypes = {
    getContribution: PropTypes.func.isRequired,
    contribution: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
        contribution: state.contribution
    });

export default connect(mapStateToProps, {getContribution})(EditContribution);
