import React, { Component }  from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form'
import { renderTextField, renderSelectField, renderTmcField } from '../../common/MaterialUiForm'
import Grid from '@material-ui/core/Grid';
import { ToastContainer, toast } from 'react-toastify'
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css'
import DatePickerComponent from '../form/types/DatePickerComponent'
import Textarea from '../form/types/Textarea'
import IntegrationReactSelect from '../../common/IntegrationReactSelect'

const styles = theme => ({
  root: {
    htmlFontSize: 20,
  },
  });

  const validate = values => {
  const errors = {}
  const requiredFields = [
    'content',
    'date',
    'type',
    'assignees'
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'This value should not be blank.'
    }
  })

  return errors
}
class NotificationForm extends Component {
    constructor(props) {
     super(props);
     }

   render () {
  const { handleSubmit, pristine, reset, submitting, classes } = this.props;
 let types = [{ id: 1,  name: 'Reminder'}, { id: 2,  name: 'Reminder&Email'}];
 let statuses = [{ id: 1,  name: 'Resolved'}, { id: 2,  name: 'Pending'}];
  return (
   <Grid container className="noPadding" direction="row" spacing={0}>
    <form onSubmit={handleSubmit} style={{display: 'flex', flexWrap: 'wrap', width: '100%'}}>
     <Grid container  direction="row" className="registerNotification-form">
<Grid md={1} lg={1} item className="text-center"></Grid>
  <Grid md={5} lg={5} xs={12} item className={classes.root} style={{paddingLeft: 15, paddingRight: 15}}>
     <Field
          name="title"
          id="outlined-dense-first"
          type='text'
          component={renderTextField}
          label="Title"
          variant="outlined"
          className="textField"
        />
        <Field
         name="type"
         className={classNames("formControl")}
         id="outlined-dense-first"
         type='text'
         component={renderSelectField}
         label="Type *"
         variant="outlined"
         className="textField"
                    >
                    {types}
        </Field>
      </Grid>
      <Grid md={5} lg={5} xs={12} item style={{paddingLeft: 15, paddingRight: 15}}>
         <Field
           name="date"
           id="outlined-dense-first"
           type='text'
           component={DatePickerComponent}
           label="Date *"
           variant="outlined"
           className="textField"
         />
         <Field
         name="status"
         className={classNames("formControl")}
         id="outlined-dense-first"
         type='text'
         component={renderSelectField}
         label="Status *"
         variant="outlined"
         className="textField"
                    >
                    {statuses}
        </Field>
        </Grid>
<Grid md={1} lg={1} item className="text-center"></Grid>
<Grid md={1} lg={1} item className="text-center"></Grid>
      <Grid md={10} lg={10} xs={12} item className="text-left" style={{paddingLeft: 15, paddingRight: 15}}>
              <Field
               name="content"
               id="outlined-dense-first"
               type='text'
               component={renderTmcField}
               label="Description *"
               variant="outlined"
               className="textField"
               field={{id: "content"}}
             />
             <Field
               name="assignees"
               id="outlined-dense-first"
               type='text'
               component={IntegrationReactSelect}
               label="Assignees"
               variant="outlined"
               className="textField"
               ismulti={true}
               clearable={true}
               options={this.props.admins}
             />
        </Grid>
             <Grid md={12} lg={12} xs={12} item className="text-center" >
                  <button type="submit" disabled={pristine || submitting} className="button-submit">
                     Submit
                 </button>
                  </Grid>
        </Grid>
        </form>
        </Grid>
  );
  }
}

NotificationForm.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({

});

NotificationForm = withStyles(styles)(NotificationForm)

export default connect(mapStateToProps, {})(reduxForm({form: 'NotificationForm', enableReinitialize: true, keepDirtyOnReinitialize: true, validate})(NotificationForm))
