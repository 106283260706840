import React from 'react';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import {onSelect2InputChange} from '../../../../actions/formActions';
import Select2 from './Select2';

class Collection extends React.Component {

        constructor(props) {
        super(props);
                this.state = {
                values: [],
                rows: [],
                options: [],
                field: null
                }
        }

    componentDidMount() {
         console.log(this.props.data[this.props.field.id])
        this.setState({
            options: this.props.field.options,
            field: this.props.field
        })
        if (this.props.data && typeof this.props.data[this.props.field.id] !== 'undefined'){
                var rows = []
            if (this.props.data[this.props.field.id].length > 0){
                this.props.data[this.props.field.id].map(row => (
                    rows.push(1)
                ))
            }

            this.setState({
                rows: rows,
                values: this.props.data[this.props.field.id]

            })
        }
    }

    onChange = (e, name, k, key) => {           
        var values = this.state.values;
       
        if (typeof values[k] === 'undefined'){
            values[k] = []
        }
        if(e.value && typeof e.value !== 'undefined'){
            values[k][key] = e.value;
        }else{
            values[k][key] = e.target.value;
        }
        this.setState({
            values: values
        })
        this.props.input.onChange(values)
    }

    addField = () => {
        var rows = this.state.rows;
        rows.push(1)
        this.setState({
            rows: rows
        })
    }

    removeField = (k) => {
        var rows = this.state.rows;
        var values = this.state.values;
        rows.splice(k, 1);
        values.splice(k, 1);
        this.setState({
            rows: rows,
            values: values
        })
        this.props.input.onChange(values)
    }

    loadOptions = (inputValue, callback, name, k, key, url) => {

        if (!inputValue) inputValue = -1;

        this.props.onInputChange(this.props.meta.form, this.props.field.id, inputValue, url)
        .then(response => {
                let options = [];
                if (response) {
                    options = response.map(option => {
                        return {
                            ...option,
                            'value': option.value,
                            'label': option.label
                        };
                    });
                }

                var fieldsOptions = this.state.options;
                fieldsOptions[name] = options;
                console.log(fieldsOptions)
                 this.setState({
                    options: fieldsOptions
                })

                if(options.length > 0) this.props.onNewOptions(options);
                else if(this.state.options.length > 0) this.props.onNewOptions(this.state.options);

             callback(options)

            });
    }


render() {
    const {field, label, input, meta: { touched, invalid, error }, custom} = this.props;
    
    return <>
        {this.state.rows.length > 0 &&
    <>{this.state.rows.map((val, k) => (
    <Grid container direction="row" spacing={4} key={k}>
        {this.state.field && this.state.field.collectionFields && field.collectionFields.length > 0 &&
        <>{this.state.field.collectionFields.map((row, key) => {
            if (row.type === 'select' && this.state.options && this.state.options[row.name]){
            return <Grid item md={row.col ? row.col : 6} key={key}>
            {row.label && <label>{row.label}</label>}
            <select className="form-control form-contacts"
            key={key}
            value={this.state.values[k] && this.state.values[k][key] ? this.state.values[k][key] : ''}
            onChange={(e) => this.onChange(e, row.name, k, key)}
            style={{height: 60}}
            >
                {row.empty && typeof row.empty !== 'undefined' &&
                <option value="" disabled defaultValue>{row.placeholder}</option>
                }
                {this.state.options[row.name].map((option, i) => (
                <option value={option.value} key={i}>{option.label}</option>
                                    ))}
            </select>
        </Grid>
        }
        if(row.type === 'asyncSelect' && this.state.options && this.state.options[row.name]){
           return <Grid item md={row.col ? row.col : 6} key={key}>
            {row.label && <label>{row.label}</label>}
              <AsyncSelect
                isMulti={row.isMulti}
                cacheOptions
                cacheOptions
                placeholder={row.placeholder ? row.placeholder : null}
                onChange={(e) => this.onChange(e, row.name, k, key)}
                onBlurResetsInput={false}
                onCloseResetsInput={false}
                menuPlacement={"auto"}
                loadOptions={(inputValue, callback) => this.loadOptions(inputValue, callback, row.name, k, key, row.optionsUrl)}
                defaultOptions={this.state.options[row.name]}
                value={this.state.values[k] ? this.state.options[row.name].find(o => o.value === this.state.values[k][key]) : null}
                onFocus={input.onFocus}
                style={{zIndex: 10000}}
                />

        </Grid>
        }
        if(row.type === 'text'){
         return <Grid item md={row.col ? row.col : 6} key={key}>
            {row.label && <label>{row.label}</label>}
            <input type="text" onChange={(e) => this.onChange(e, row.name, k, key)} name={row.name} placeholder={row.placeholder && row.placeholder} value={this.state.values[k] ? this.state.values[k][key] : ''} />
        </Grid>
        }
         if(row.type === 'hidden'){
         return <Grid item md={2} key={key}>
          <input type="hidden" onChange={(e) => this.onChange(e, row.name, k, key)} name={row.name} value={this.state.values[k] ? this.state.values[k][key] : ''}/>
        </Grid>
        }
        })}</>
        }
        
        <Grid item md={6}>
            <Button size="small" variant="outlined" color="secondary" onClick = {() => this.removeField(k)}>{field.buttonName ? <span>Remove {field.buttonName}</span> : <span>Remove field</span>}</Button>
            <br/><br/>
        </Grid>
    </Grid>
                                    ))}
    </>
    }
    <Button variant="outlined" color="primary" onClick = {this.addField}>
    {field.buttonName ? <span>Add {field.buttonName}</span> : <span>Add field</span>}
    </Button>
    </>
  }
}


Collection.defaultProps = {
    onNewOptions: () => {},
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    onInputChange: (formId, id, value, route) => dispatch(onSelect2InputChange(formId, id, value, route)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Collection);
