import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import logoSide from '../../img/logo-side-menu.png';
import Navbar from '../layout/Navbar';
import { withRouter } from 'react-router-dom';
import Spinner from '../common/Spinner';
import {loginLinkedin} from '../../actions/authActions'
import { getLogActivityWithGeoIp } from '../../actions/profileActions';

class LinkedinConnect extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        if(this.props.location.search.includes('?code=')){
           let code = this.props.location.search;
           code = code.split("?code=").pop()
           this.props.loginLinkedin(code, this.props.history)
        }
        this.props.getLogActivityWithGeoIp('Linkedin Connect page', 'GET', "Website usage")

    }

    componentWillReceiveProps(nextProps) {

    }


    render() {
        return (
        <div className="container-fluid">
            <div className="page-content slide page-animation" style={{marginTop: 100}}>
                <div className="clearfix "></div>
                <div className="changePass">
                    <div className="col-lg-3"></div>
                        <div className="col-lg-6 text-center">
                        <h1 style={{marginBottom: 30}}>Connecting</h1>
                            <Spinner />
                        </div>

                </div>

            </div>




         <Navbar/>
        </div>



        );
    }
}

LinkedinConnect.propTypes = {
    loginLinkedin: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    getLogActivityWithGeoIp: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
                auth: state.auth
});

export default connect(mapStateToProps, { loginLinkedin, getLogActivityWithGeoIp })(withRouter(LinkedinConnect));
