import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  paper: {
    top: 0,
  },
  rootItem: {
    fontSize: 22,
    marginTop: '2%',
    marginLeft: 20,
    background: 'white',
    textDecoration: 'none',
    '&:hover': {
      background: 'white',
    }
  },
  rootItemSmall: {
    fontSize: 18,
    marginTop: '0',
    marginLeft: 20,
    height: 20,
    background: 'white',
    textDecoration: 'none',
    '&:hover, &:focus': {
      background: 'white',
      outline: "0",
      color: "#000"
    }
  },
  bottomArea: {
    position: 'fixed',
    padding: 0,
    bottom: 0,
    width: '92%',
    height: '12%',
    background: '#33ad50',
    background: '-webkit-linear-gradient(right,#33ad50,#069c95)',
    background: '-o-linear-gradient(left,#33ad50,#069c95)',
    background: '-moz-linear-gradient(left,#33ad50,#069c95)',
    background: 'linear-gradient(to left,#33ad50,#069c95)',
    marginTop: '30px'
  },
  link: {
    color: 'white',
    padding: '35px',
    fontSize: '22px',
    textTransform: 'capitalize',
    '&:hover': {
      color: 'white',
      textDecoration: 'none',
    }
  },
  rootSmallItem: {
    color: '#999',
    fontSize: '14px',
    textDecoration: 'none',
    marginLeft: 20,
    '&:hover': {
      background: 'white',
      textDecoration: 'none',
    }
  }
});

const ITEM_HEIGHT = 48;

class MenuNavbarMobile extends Component {

  state = {
    anchorEl: null,
    height: 0,
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ height: window.innerHeight });
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {

    const { anchorEl } = this.state;
    const { classes } = this.props;

    let itemClass = classes.rootItem
    if (this.state.height < 820) {
      itemClass = classes.rootItemSmall
    }
    return (
      <div style={{ top: 0 }} >

        <center className="menu-button pull-right">
          <IconButton
            aria-label="Menu"
            aria-owns={anchorEl ? 'long-menu' : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <MenuIcon style={{ fontSize: 45, color: "#212529" }} />
          </IconButton>
          <span className="ext-dark-blue-old" >MENU</span>
        </center>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          style={{ top: 0 }}
          height="98%"
          PaperProps={{
            style: {
              width: "100%",
              minHeight: '98%',
              maxHeight: ITEM_HEIGHT * 4.5,//'950px',
              left: '70%',
              right: 0,
              top: 0,
              background: 'white',
              position: 'absolute',

            }
          }}
        >
          <MenuItem style={{ height: 60, background: 'white' }} classes={{ root: classes.rootItem }}>
            <Link onClick={this.handleClose} to="/" className="pull-left logo-hide-menu">
              <img alt="logo" src="/images/org/91ba9b.png?v1.0" style={{ width: 27, heght: 27, marginLeft: 20 }} />
            </Link>

            <div style={{ float: 'right', position: 'inherit', right: 0, left: '73%' }} className="pull-right ">
              <Icon style={{ float: 'right', border: 0, }} onClick={this.handleClose}>close</Icon>
            </div>
          </MenuItem>

          <MenuItem onClick={() => this.setState(state => ({ showOngoingProjects: !state.showOngoingProjects }))} classes={{ root: itemClass }} style={{ padding: 15 }}>
          Ongoing Projects
              {this.state.showOngoingProjects ? <ExpandLess /> : <ExpandMore />}
          </MenuItem>
          <Collapse in={this.state.showOngoingProjects} timeout="auto" unmountOnExit>
            <MenuItem onClick={this.handleClose} classes={{ root: itemClass }}><a className="text-light-gray" href="/shipp/shipp.html" className="text-uppercase" style={{color: "inherit"}}>
                Sustainable Health in <br/> Procurement Project – SHiPP
            </a></MenuItem>
            <MenuItem onClick={this.handleClose} classes={{ root: itemClass }}><a className="text-light-gray" href="https://shipp-stories.savinglivesustainably.org/index.html" className="text-uppercase" style={{color: "inherit"}}>
                SHiPP Impact Stories that Inspire
            </a></MenuItem>
          </Collapse>


          <MenuItem onClick={this.handleClose} component={Link} to="/who-we-are" classes={{ root: itemClass }}>Who we are</MenuItem>
          <MenuItem onClick={this.handleClose} component={Link} to="/knowledge-practice" classes={{ root: itemClass }}>Knowledge and Practice</MenuItem>
          <MenuItem onClick={this.handleClose} component={Link} to="/what-you-can-do" classes={{ root: itemClass }}>Global contributions map</MenuItem>
          <MenuItem onClick={this.handleClose} component={Link} to="/sustainability-assessments" classes={{ root: itemClass }}>Sustainability assessments</MenuItem>
          <MenuItem onClick={this.handleClose} component={Link} to="/calendar" classes={{ root: itemClass }}>Event calendar</MenuItem>

          <MenuItem onClick={() => this.setState(state => ({ showGlobalForums: !state.showGlobalForums }))} classes={{ root: itemClass }} style={{ padding: 15 }}>
            Global Forums
              {this.state.showGlobalForums ? <ExpandLess /> : <ExpandMore />}
          </MenuItem>
          <Collapse in={this.state.showGlobalForums} timeout="auto" unmountOnExit>
            <MenuItem onClick={this.handleClose} component={Link} to="/asia-forum" classes={{ root: itemClass }}>Asia Forum 2018</MenuItem>
            <MenuItem onClick={this.handleClose} component={Link} to="/africa-forum" classes={{ root: itemClass }}>Africa Forum 2019</MenuItem>
            <MenuItem onClick={this.handleClose} component={Link} to="/global-virtual-forum" classes={{root: itemClass}}>Global Forum 2020</MenuItem>
            <MenuItem onClick={this.handleClose} component={Link} to="/global-virtual-forum-2021" classes={{root: itemClass}}>Global Forum 2021</MenuItem>
            <MenuItem onClick={this.handleClose} component={Link} to="/global-virtual-forum-2022" classes={{root: itemClass}}>Global Forum 2022</MenuItem>
          </Collapse>

          <MenuItem onClick={() => this.setState(state => ({ showAnnualReports: !state.showAnnualReports }))} classes={{ root: itemClass }} style={{ padding: 15 }}>
            Annual Reports
              {this.state.showAnnualReports ? <ExpandLess /> : <ExpandMore />}
          </MenuItem>
          <Collapse in={this.state.showAnnualReports} timeout="auto" unmountOnExit>

            {[{ year: '2016', id: '68' }, { year: '2017', id: '95' }, { year: '2018', id: '105' },].map((report, key) => (
              <MenuItem component={Link} to={`/news/${report.id}`} className="text-uppercase" classes={{ root: itemClass }}>
                {report.year}
              </MenuItem>
            ))}

            <MenuItem classes={{ root: itemClass }}>
              <a href="/annual-report-2019/index.html" style={{ color: "inherit", padding: 15 }} className="text-uppercase">  2019</a>
            </MenuItem>
            <MenuItem   classes={{root: itemClass}}>
              <a href="/annual-report-2020/index.html" style={{color: "inherit", padding: 15}} className="text-uppercase">  2020</a>
            </MenuItem>
            <MenuItem   classes={{root: itemClass}}>
              <a href="/annual-report-2021/index.html" style={{color: "inherit", padding: 15}} className="text-uppercase">  2021</a>
            </MenuItem>
          </Collapse>

          <MenuItem onClick={this.handleClose} component={Link} to="/news" style={{marginBottom: 100}} classes={{ root: itemClass }}>News &amp; stories</MenuItem>

          <div className={classes.bottomArea}>
            <MenuItem onClick={this.handleClose} component={Link} to="/contacts" classes={{ root: classes.link }}>
              Contact Us
            </MenuItem>
          </div>
        </Menu>
      </div>
    );
  }
}


MenuNavbarMobile = withStyles(styles)(MenuNavbarMobile)
export default MenuNavbarMobile;
