import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Button from '@material-ui/core/Button';
import { setDependency } from '../../../../actions/questionnaireActions'
import showAToast from '../../../../helpers/showAToast'
import Select from 'react-select';

class DialogOptionsDependency extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        open: false,
        optionValue: [],
        fieldsValue: []
    };
  }

    openDependency = event =>{
      event.preventDefault();
      this.setState({
          open: true
      })
  }

  closeOption = () => {
      this.setState({
          open: false,
          fieldsValue: []
      })
  }

  handleChangeOption = (e) => {
      console.log(e)
       var options = e.target.options;
  var value = [];
  for (var i = 0, l = options.length; i < l; i++) {
    if (options[i].selected) {
      value.push(options[i].value);
    }
  }
 
  
      this.setState({
          optionValue: value
      })
  }

  handleChangeField = (event) => {

      var fieldsValue = this.state.fieldsValue
      if(fieldsValue.includes(event.target.value)){
            var index = fieldsValue.indexOf(event.target.value);
            if (index > -1) {
              fieldsValue.splice(index, 1);
            }
      }else{
          fieldsValue.push(event.target.value)
      }
      
      this.setState({
          fieldsValue: fieldsValue
      })
  }

  setDependency = (event) => {
      event.preventDefault()
       if(!this.state.fieldsValue){
           showAToast('Please, choose field!', 'chooseField', 'error')
       }
       if(!this.state.optionValue){
           showAToast('Please, choose option!', 'chooseOption', 'error')
       }
       if(this.state.fieldsValue && this.state.optionValue){
           this.props.setDependency(this.state.fieldsValue, {dependency: {fieldId: this.props.field.id, optionValue: this.state.optionValue, label: this.props.field.label, header: this.props.field.header}})
           this.setState({
                open: false
            })
              setTimeout(() => {
            this.props.updateSection()
             }, 3000)
       }

  }

  render() {
      var arrayTypes = ['file', 'checkboxGroup', 'text', 'textarea'];

    return  <div>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={this.openDependency}
              >Set dependency</Button>
        <Dialog
                        onClose={this.closeOption}
                        aria-labelledby="simple-dialog-title"
                        open={this.state.open}
                        maxWidth="sm"
                        fullWidth
                        >
                        <DialogTitle id="simple-dialog-title">Set dependency<span className="pull-right pointer" onClick={this.closeOption}>
                                 <i className="far fa-times-circle"></i></span>
                                 </DialogTitle>
                         <DialogContent>
                         <section className="newPanel">
                            <div className="panel-body">
                            <form>
                <div>
                <div className="m-bot15" style={{fontWeight: 600, fontSize: 16}}>Options</div>
                <select className="form-control" onChange={this.handleChangeOption} value={this.state.optionValue} name={this.props.fieldId} multiple>
                <option value="" disabled defaultValue>Please, choose option...</option>
         {this.props.field.options && this.props.field.options.length > 0 && this.props.field.options.map((option,opKey) => (
                 <option key={opKey} value={option.value}>{option.label}</option>
                  ))}
                </select>
                </div>
                <div className="margin-top">
                <div className="m-bot15" style={{fontWeight: 600, fontSize: 16}}>Fields</div>
                {this.props.question && this.props.question.fields && this.props.question.fields.map((questField, k) => {

                if(arrayTypes.includes(questField.type)){
              return   <div className="col-md-12 no-gutter" style={{marginBottom: 15}} key={k}>
              
                <div className="col-md-1 no-gutter"><input style={{cursor: "pointer"}} type="checkbox" className="form-control" value={questField.fieldId} name="questField" key={k} checked={this.state.fieldsValue.includes(String(questField.fieldId))} onChange={this.handleChangeField}/></div>
               <div className="col-md-11 no-gutter">
                <div><label><b>Label: </b></label>{questField.label}  </div>
                <div> <label><b>Header:</b></label> {questField.header}  </div>
                <div><label><b>Type: </b></label>{questField.type}  </div>
               </div>
              </div>
    }
    })}
    </div>
    <div className="m-top20"><Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={this.setDependency}
              >Set dependency</Button></div>
                            </form>

                            </div>
                        </section>
                         </DialogContent>
                        </Dialog>
   </div>
  }
}

DialogOptionsDependency.propTypes = {
  setDependency: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
 // questionnaire: state.questionnaire,
});

export default connect( mapStateToProps, { setDependency })(DialogOptionsDependency);
