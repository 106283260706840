import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Layout from '../../../components/admin/layout/Layout'
import {
  PieChart, Pie, Sector, Cell,
} from 'recharts';
import ReactSpeedometer from "react-d3-speedometer"
import {getQuestionnaireReport } from '../../../actions/questionnaireActions'
import Navbar from '../../layout/Navbar'
import Footer from '../../layout/Footer'
import VerticalChart from './VerticalChart'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import domtoimage from 'dom-to-image';
import saveAs from 'file-saver'
import Button from '@material-ui/core/Button';
import ReactToPrint, { PrintContextConsumer }  from "react-to-print";
import UserReportComponent from './UserReportComponent'
import { getLogActivityWithGeoIp, getCurrentProfile } from '../../../actions/profileActions';

const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

class UserReport extends Component {
  constructor() {
    super();
    this.state = {
      logged: false
    };
  }

     componentDidMount() {
       this.props.getQuestionnaireReport(this.props.match.params.id)
       this.props.getCurrentProfile();
    }

componentWillReceiveProps(nextProps) {
        if(nextProps.profile && nextProps.profile.profile &&  !this.state.logged){
            
        if(nextProps.profile.profile.roles.includes('ROLE_QUESTIONNAIRE')){
            this.props.getLogActivityWithGeoIp('Questionnaire User Report', 'GET', "QR SA tool")
            this.setState({
                logged: true
            })
        }else if(nextProps.profile.profile.roles.includes('ROLE_MEMBER')){
            this.props.getLogActivityWithGeoIp('Questionnaire User Report', 'GET', "Members SA tool")
            this.setState({
                logged: true
            })
        }
            
        }
 }

  render() {
    const {questFill} = this.props.questionnaire;
    
     const { classes } = this.props;
    return (   
             <Grid container spacing={0} > 
        
     <div className="page-content page-animation slideup" style={{top: 0}} >
     <Grid item md={10} lg={10} xs={6} className="text-right" style={{marginTop: 120 }}> 
     <ReactToPrint content={() => this.componentRef}>
          <PrintContextConsumer>
            {({ handlePrint }) => (
             <Button onClick={handlePrint} variant="outlined"> Print</Button>
            )}
          </PrintContextConsumer>
        </ReactToPrint>
         </Grid><Grid item md={2} lg={2} xs={6}></Grid>
               <UserReportComponent ref={el => (this.componentRef = el)} questFill={questFill}/>
        <Navbar/>
            <Footer/>
    </div>
     </Grid>
    );
  }
}

UserReport.propTypes = {
  questionnaire: PropTypes.object.isRequired,
  getQuestionnaireReport: PropTypes.func.isRequired,
  getLogActivityWithGeoIp: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  questionnaire: state.questionnaire,
   profile: state.profile,
});

UserReport = withStyles(styles)(UserReport)

export default connect( mapStateToProps, { getQuestionnaireReport, getLogActivityWithGeoIp, getCurrentProfile })(withRouter(UserReport));
