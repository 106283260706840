import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTypes } from '../../../actions/contributionActions';
import Layout from '../../../components/admin/layout/Layout'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withRouter } from 'react-router-dom';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';


const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    ontSize: 22,
    margin: 0,
        height: 48,
  },
   root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: "0 15px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
    maxWidth: 300,
    fontSize: 22,
    float: 'left',
    marginLeft: '15px',
    
  },
  formLabel: {
      fontSize: 14,
      fontWeight: 300,
  },
  formInput: {
      fontSize: 14,
  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      minWidth: 300,
    },
  },
};
class ContributionType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: ''
        }
    }

    componentDidMount() {
       this.props.getTypes()
    }

    componentWillReceiveProps(nextProps) {

    }
    
    handleChooseType = event => {
    this.setState({ type: event.target.value });
    
  };

  handleSubmitType = () => {         
      this.props.history.push('/admin/contributions/create/'+this.state.type)      
  }

    render() {
    const { classes } = this.props;
    const { types } = this.props.contribution;

        return (
                <Layout>
                
          <div>      
        <div className="dashboard">
                <div className="moduleSubMenu backMenu">
                    <div className="col-sm-12">
                        <h3 className="pull-left">
                            <Link to={'/admin/contributions'}>
                            <i className="ion-ios-arrow-back"></i></Link>  New Contribution
                        </h3>
                    </div>
                </div>
            </div>
            <div id="reactForm">
                <div className="row m-bot15 cmxform form-horizontal bucket-form ng-pristine ng-valid">
                    <div className="col-lg-12">
                        <section className={'panel'} style={{background: 'white'}}>
                            <header className="panel-heading"
                                    style={{display:'block'}}>
                               
                            </header>
                             <div className="panel-body">
                        {types &&
                        <FormControl fullWidth className={classes.formControl}>
                          <label htmlFor="select-types" style={{fontSize: 18, color: "#3e5262"}}>Types</label>
                          <Select
                            value={this.state.type}
                            onChange={this.handleChooseType}
                            input={<Input id="select-types" />}
                            MenuProps={MenuProps}
                          SelectDisplayProps={{style: {fontSize: 14, textAlign: "left" }}}
                            inputProps={{
                              name: 'type',
                              id: 'select-types"',
                              style: {fontSize: 14, padding: 10}
                            }}
                            style={{marginBottom: 30}}
                          >
                            {types.map(type => (
                              <MenuItem key={type.id} value={type.id} style={{fontSize: 14, padding: 10}} label={type.name}>
                                {type.name}
                              </MenuItem>
                            ))}
                          </Select>          
                            <Button onClick={this.handleSubmitType} style={{background: "#1cbbb4", border: 0, padding: "5px 20px", color: "#fff", borderRadius: 3, fontSize: 14}}>
                              Next
                            </Button>
                        </FormControl>}
                   </div>
                   </section>
                   </div>
                   </div>
                </div>
            </div>           
        </Layout>

                );
    }
}

ContributionType.propTypes = {
    getTypes: PropTypes.func.isRequired,
    contribution: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
        contribution: state.contribution
    });
ContributionType = withStyles(styles)(ContributionType);
export default connect(mapStateToProps, {getTypes})(withRouter(ContributionType));