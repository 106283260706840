import client from './client';

import {
  GET_MEMBER,
  GET_MEMBERS,
  MEMBER_LOADING,
  GET_ERRORS
} from './types';

// Get current profile
export const getMembers = () => dispatch => {
  dispatch(setMemberLoading());
  client
    .get('/web/members')
    .then(res =>
      dispatch({
        type: GET_MEMBERS,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_MEMBERS,
        payload: {}
      })
       
  });
};

// Get current profile
export const getMember = (id) => dispatch => {
  dispatch(setMemberLoading());
  client
    .get('/web/members/'+id )
    .then(res =>
      dispatch({
        type: GET_MEMBER,
        payload: res.data
      })
    )
    .catch(err => {
      dispatch({
        type: GET_MEMBER,
        payload: {}
      })
  });
};

// Member loading
export const setMemberLoading = () => {
  return {
    type: MEMBER_LOADING
  };
};