import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import Navbar from "../layout/Navbar";
import Grid from "@material-ui/core/Grid";
import Footer from "../layout/Footer";
import TextField from "@material-ui/core/TextField";
import { resetNewPassword } from "../../actions/authActions"
import ResetPasswordForm from '../forms/ResetPasswordForm'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

class ResetNewPassword extends Component {
  constructor() {
    super();
    this.state = {
      token: null
    };
  }

     componentDidMount() {
         const query = new URLSearchParams(this.props.location.search);
         const token = query.get('token')
         this.setState({
             token: token
         })
    }

    componentWillReceiveProps(nextProps) {

    }

    submit = values => {

       let userData;
       userData = {
        plainPassword: {
          first: values.first,
          second: values.second
        },
        token: this.state.token
       }
       this.props.resetNewPassword(userData, this.props.history);
    }
  render() {
    const TextFieldStyle = {
      color: "red",
      width: "30%"
    };

    const pStyle = {
      width: "30%",
      margin: "30px auto"
    };

     const { classes } = this.props;



    return (
            <div className={classes.root}>
      <Grid container spacing={0} >
        <div  className="page-content  slideup page-animation" >
            <div className="contribute-reset">
              <div className="header">
                     <div className="col-md-6 col-md-offset-3">
                         <h1 className="max-width text-center text-white">
                                     Sign up to become a Contributor and join the SPHS Community
                         </h1>
                     </div>
                     <div className="col-md-12 text-center">
                         <h4>All fields are required, unless indicated as optional</h4>
                     </div>
                 </div>
                <div className="clearfix "></div>
                <div className="resetPass">
                    <div className="col-lg-3"></div>
                        <div className="col-lg-6">
                        <h3>Reset password</h3>
                          <ResetPasswordForm onSubmit={this.submit} />

                        </div>
                </div>
            </div>
            <Footer/>
        </div>
        <Navbar/>
      </Grid>
     </div>





    );
  }
}

ResetNewPassword.propTypes = {
  resetNewPassword: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

ResetNewPassword = withStyles(styles)(ResetNewPassword)

export default connect( mapStateToProps, { resetNewPassword })(withRouter(ResetNewPassword));
