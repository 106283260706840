import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {onOpenBulk,
        onOpenBulkActionList,
        onBulkActionClick,
        exportBulkAction,
        onShowMessage,
        onHideMessage} from '../../../actions/gridActions';
import Message from '../../common/Message';

class BulkActions extends Component {

    constructor(props) {
        super(props);

        this.openBulk = this.openBulk.bind(this);
        this.openActionList = this.openActionList.bind(this);
        this.actionClick = this.actionClick.bind(this);

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    openBulk() {
        this.props.onOpenBulk(this.props.bulk);
    }

    openActionList(action) {
        this.props.onOpenActionList(this.props.bulk, action);
    }

    actionClick(action) {
        if(action.document){
            this.props.exportBulkAction(action.route, this.props.data.data)
        }else{
        this.props.onActionClick(action, this.props.bulk, this.props.data.data, this.props.filterOptions, this.props.gridProperties.startDate, this.props.gridProperties.endDate, this.props.gridProperties.quickFilter, this.props.sortedCols)
        .then((response) => {
                if ('errors' in response) {
                    this.props.showMessage(response.errors, true);
                    setTimeout(() => this.props.hideMessage(), 5000);
                }
                if ('success' in response) {
                    this.props.showMessage(response.success, false);
                    setTimeout(() => this.props.hideMessage(), 5000);

                }
            })
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.props.bulk.showDropdown) {
            this.openBulk();
        }
    }

    render() {
        return (
            <div>
                <nav role="navigation" className="nav">
                    <ul className="nav-items">
                        <li className="nav-item dropdown"  ref={this.setWrapperRef}>
                            <a href="#" className="nav-link" onClick={this.openBulk}>
                                <span>
                                <i style={{marginRight: 6}} className={this.props.bulk.icon} aria-hidden="true"></i>
                                {this.props.bulk.label}
                                </span>
                            </a>
                            {this.props.bulk.showDropdown &&
                            <nav className="submenus">
                                <ul className="submenus-items">
                                    {
                                        this.props.bulk.actions.map((action, key) => {
                                            if ('actions' in action) {
                                                const childrenEls = action.actions.map((child, childKey) => {                                                 
                                                    return <li className="submenus-item" key={childKey}>
                                                        <a href="#" onClick={() => this.actionClick(child)}
                                                           className="submenus-link">
                                                            {child.label}
                                                        </a>
                                                    </li>
                                                });
                                                return <li className="submenus-item" key={key}>
                                                    <span className="submenus-link" onClick={() => this.openActionList(action)}>
                                                        {action.label}
                                                        <i className="fa fa-angle-down" style={{marginLeft: 3, fontSize: 12}} aria-hidden="true"></i>
                                                    </span>
                                                    {
                                                        action.showDropdown &&
                                                        <ul className="inner-list">
                                                            {childrenEls}
                                                        </ul>
                                                    }
                                                </li>
                                            }
                                            else {   
                                                return <li className={'submenus-item' + (action.line ? ' border-del' : '')} style={{marginLeft: 0}} key={key}>
                                                    <a href="#" onClick={() => this.actionClick(action)}
                                                       className={'submenus-link' + (action.line ? ' text-red' : '')}>
                                                        {action.label}
                                                    </a>
                                                </li>
                                            }
                                        })
                                    }
                                </ul>
                            </nav>
                            }
                        </li>
                    </ul>
                </nav>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    gridProperties: state.gridProperties,
    data: state.gridData
});

const mapDispatchToProps = dispatch => ({
    onOpenBulk: (bulk) => dispatch(onOpenBulk(bulk)),
    onOpenActionList: (bulk, action) => dispatch(onOpenBulkActionList(bulk, action)),
    onActionClick: (action, bulk, data, filter, startDate, endDate, quickFilter, sortedCols) => dispatch(onBulkActionClick(action, bulk, data, filter, startDate, endDate, quickFilter, sortedCols)),
    showMessage: (text, isError) => dispatch(onShowMessage(text, isError)),
    hideMessage: () => dispatch(onHideMessage()),
    exportBulkAction: (route, data) => dispatch(exportBulkAction(route, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkActions);
