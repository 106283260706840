import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import profileReducer from './profileReducer';
import memberReducer from './memberReducer';
import newsReducer from './newsReducer';
import sectorReducer from './sectorReducer';
import sdgReducer from './sdgReducer';
import contributionReducer from './contributionReducer'
import codeReducer from './codeReducer'
import regionReducer from './regionReducer'
import userSetupReducer from './userSetupReducer'
import accountReducer from './accountReducer'
import membersForumReducer from './membersForumReducer'
import { reducer as formReducer } from 'redux-form'
import setupReducer from './setupReducer'
import gridData from './gridData'
import gridProperties from './gridProperties'
import settingsReducer from './settingsReducer'
import messageReducer from './messageReducer'
import formPropertiesReducer from './formPropertiesReducer'
import formDataReducer from './formDataReducer'
import formIsValidReducer from './formIsValidReducer'
import commentReducer from './commentReducer'
import socket from './socket'
import notificationReducer from './notificationReducer'
import collaborationReducer from './collaborationReducer'
import helpReducer from './helpReducer'
import documentReducer from './documentReducer'
import questionnaireReducer from './questionnaireReducer'

        export default combineReducers({
            auth: authReducer,
            errors: errorReducer,
            message: messageReducer,
            profile: profileReducer,
            member: memberReducer,
            news: newsReducer,
            sector: sectorReducer,
            sdg: sdgReducer,
            contribution: contributionReducer,
            code: codeReducer,
            region: regionReducer,
            userSetup: userSetupReducer,
            account: accountReducer,
            form: formReducer,
            threads: membersForumReducer,
            setups: setupReducer,
            gridProperties: gridProperties,
            gridData: gridData,
            settings: settingsReducer,
            formProperties: formPropertiesReducer,
            formData: formDataReducer,
            formIsValid: formIsValidReducer,
            comment: commentReducer,
            notification: notificationReducer,
            socket: socket,
            collaboration: collaborationReducer,
            help: helpReducer,
            documents: documentReducer,
            questionnaire: questionnaireReducer
        });
