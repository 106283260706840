import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import TextTruncate from 'react-text-truncate';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';


class EventItem extends Component {





    render() {

    let clear;
        if (this.props.index == 0) {
           clear = 'left';
        }else{
           clear = this.props.index % 3 == 0 ? 'left' : 'none';
        }

        return (
          <Grid item md={4} sm={6} xs={12} className="text-left">
            {this.props.contributionItem &&
              <div style={{clear: clear}}>
                <h1 style={{color: 'black'}}>{this.props.key}</h1>
                <p className={classNames('month', this.props.contributionItem.month)}>{this.props.contributionItem.month}</p>
                <div className="preview-member-project">
                  <a className=""  href={this.props.contributionItem.href}>
                    <img height="300" width="300"  src={this.props.contributionItem.photo} alt="event"/>
                    <div className="date">
                      <div className="pull-left">
                        <i className="fa far fa-calendar-check" style={{color: '#1cbbb4',fontSize: '25px', marginRight: '10px'}}></i>
                      </div>
                      <div className="pull-left">
                        <span>{ this.props.contributionItem.dateStart }</span><br/>
                        <span className="date-to">to { this.props.contributionItem.dateEnd  }</span>
                      </div>
                    </div>
                    <h3>
                      <TextTruncate
                        line={2}
                        truncateText="…"
                        text={this.props.contributionItem.projectTitle}
                        containerClassName="text-left"/>
                    </h3>
                    
                    <h4 style={{ height: 49 }}> 
                    {this.props.contributionItem.organizationName ?
                       <span> by { this.props.contributionItem.organizationName }</span>
                       :
                       <span>  { "   " }</span>        
                     }
                    </h4>
                    
                  </a>
                  <a className="location" style={{ paddingRight:'4px'}} href="#">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                  </a>
                  {this.props.contributionItem.city &&
                    <span style={{ color: '#999'}}>{ this.props.contributionItem.city }
                      {this.props.contributionItem.regions.length > 0 &&
                      <span>, </span>    }
                    </span>}
                    {this.props.contributionItem.regions.length > 0 &&
                    <span>
                      { this.props.contributionItem.regions.map((region, index) => (
                        <a href={`/calendar?location%5B%5D=${region.id}`} className="location" key={index} style={{ paddingLeft: '1px', paddingRight: '0px', cursor: 'pointer'}} >
                        {region.name}
                         <span style={{ color: '#999'}}>{index < this.props.contributionItem.regions.length - 1 ? ', ' : '' }</span>
                        </a>
                        ))
                      }
                    </span>
                  }
                </div>
              </div>
            }
          </Grid>
        );
    }
}

EventItem.propTypes = {
    contributionItem: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired
};


export default connect()(EventItem);
