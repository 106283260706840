import React from 'react';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';

function SimpleLineChart(props) {    
	
  return (
    	<LineChart width={600} height={300} data={props.data}
            margin={{top: 5, right: 30, left: 20, bottom: 5}}>
       <XAxis dataKey={props.keyX}/>
       <YAxis/>
       <CartesianGrid strokeDasharray="3 3"/>
       <Tooltip/>
       <Legend />
       <Line type="monotone" dataKey={props.keyY} stroke="#8884d8" activeDot={{r: 8}}/>
      </LineChart>
    );  
}
export default SimpleLineChart;