import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentProfile } from '../../actions/profileActions';
import Grid from '@material-ui/core/Grid';
import PopupAccounts from "./layout/sidebar/PopupAccounts";
import PopupWebsite from './layout/sidebar/PopupWebsite';
import PopupResources from './layout/sidebar/PopupResources';
import PopupAdministration from "./layout/sidebar/PopupAdministration";
import Layout from '../../components/admin/layout/Layout'
import PopupQuest from './layout/sidebar/PopupQuest';
import PopupReports from './layout/sidebar/PopupReports';

    class Internal extends Component {
        constructor(props) {
            super(props);

            this.state = {
                popupAccounts: false,
                popupAdministration: false,
                popupResources: false,
                popupWebsite: false,
                popupQuest: false,
                popupReports: false,
            };

            this.closePopup = this.closePopup.bind(this);
        }

    componentDidMount() {
        this.props.getCurrentProfile();
    }

    openModal(modal){
        if(modal === 'CRM'){
            this.setState({popupAccounts: true})
        }
        if(modal === 'CMS'){
            this.setState({popupWebsite: true})
        }
        if(modal === 'DMS'){
            this.setState({popupResources: true})
        }
        if(modal === 'Administration'){
            this.setState({popupAdministration: true})
        }
        if(modal === 'ОЕТ'){
            this.setState({popupQuest: true})
        }
        if(modal === 'Reports'){
            this.setState({popupReports: true})
        }
    }

    closePopup(id) {
        this.setState({
            [id]: false
        });
    }

    render() {
        return (
<Layout>
    <section id="container">
    <section className="wrapper">
        <div className="moduleSubMenu">
            <div className="col-lg-12">
                <div>
                    <ul className="breadcrumb">
                        <li><a href="/internal"><i className="fa fa-home"></i>&nbsp;</a></li>
                        <li>Dashboard</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="col-sm-12">
            <div className="col-md-12 col-sm-12 no-gutter">
                <section>
                    <div style={{ maxWidth: '1250px', margin: '0 auto'}}>
                        <div className="col-md-3 col-sm-6">
                            <a href="#" className="profile-nav alt" onClick={() => this.openModal('CRM')}>
                                <section className="panel text-center">
                                    <div className="user-heading alt wdgt-row">
                                        <i className="icon icon-accounts"></i>
                                    </div>
                                    <div className="panel-body title-box">
                                        <div className="wdgt-value">
                                            <h1 className="count">CRM</h1>
                                            <p>Customer relationship management</p>
                                        </div>
                                    </div>
                                </section>
                            </a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href="#" className="profile-nav alt" onClick={() => this.openModal('CMS')}>
                                <section className="panel text-center">
                                    <div className="user-heading alt wdgt-row">
                                         <i className="icon"><span className="glyphicon glyphicon-list-alt" aria-hidden="true"></span></i>
                                    </div>
                                    <div className="panel-body title-box">
                                        <div className="wdgt-value">
                                            <h1 className="count">Website</h1>
                                            <p>Content management system</p>
                                        </div>
                                    </div>
                                </section>
                            </a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href="#" className="profile-nav alt" onClick={() => this.openModal('DMS')}>
                                <section className="panel text-center">
                                    <div className="user-heading alt wdgt-row">
                                        <i className="icon"><span className="far fa-folder-open" aria-hidden="true"></span></i>
                                    </div>
                                    <div className="panel-body title-box">
                                        <div className="wdgt-value">
                                            <h1 className="count">Documents</h1>
                                            <p>Document management system</p>
                                        </div>
                                    </div>
                                </section>
                            </a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href="#" className="profile-nav alt" onClick={() => this.openModal('ОЕТ')}>
                                <section className="panel text-center">
                                    <div className="user-heading alt wdgt-row">
                                        <i className="icon"><span className="glyphicon glyphicon-th-list" aria-hidden="true"></span></i>
                                    </div>
                                    <div className="panel-body title-box">
                                        <div className="wdgt-value">
                                            <h1 className="count">SA Tool</h1>
                                            <p>Sustainability Assessment</p>
                                        </div>
                                    </div>
                                </section>
                            </a>
                        </div>
                        <div className="clearfix"></div>
                        <div className="col-md-3 col-sm-6">
                            <a href="/help" className="profile-nav alt">
                                <section className="panel text-center">
                                <div className="user-heading alt wdgt-row">
                                         <i className="icon"><span className="glyphicon glyphicon-info-sign" aria-hidden="true"></span></i>
                                    </div>
                                    <div className="panel-body title-box">
                                        <div className="wdgt-value">
                                            <h1 className="count">Help</h1>
                                            <p>Help</p>
                                        </div>
                                    </div>
                                </section>
                            </a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href="#" className="profile-nav alt" onClick={() => this.openModal('Administration')}>
                                <section className="panel text-center">
                                    <div className="user-heading alt wdgt-row">
                                      <i className="icon icon-administration"></i>
                                    </div>
                                    <div className="panel-body title-box">
                                        <div className="wdgt-value">
                                            <h1 className="count">Administration</h1>
                                            <p>Portal and user management</p>
                                        </div>
                                    </div>
                                </section>
                            </a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href="#" className="profile-nav alt" onClick={() => this.openModal('Reports')}>
                                <section className="panel text-center">
                                    <div className="user-heading alt wdgt-row">
                                      <i className="icon icon-reports"></i>
                                    </div>
                                    <div className="panel-body title-box">
                                        <div className="wdgt-value">
                                            <h1 className="count">Reports</h1>
                                            <p>Reports</p>
                                        </div>
                                    </div>
                                </section>
                            </a>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        {this.state.popupAccounts &&
            <PopupAccounts settings={this.props.settings} closePopup={(id) => this.closePopup(id)}/>}
        {this.state.popupWebsite &&
            <PopupWebsite settings={this.props.settings} closePopup={(id) => this.closePopup(id)}/>}
        {this.state.popupResources &&
            <PopupResources settings={this.props.settings} closePopup={(id) => this.closePopup(id)}/>}
        {this.state.popupAdministration &&
            <PopupAdministration settings={this.props.settings} closePopup={(id) => this.closePopup(id)}/>}
            {this.state.popupQuest &&
                <PopupQuest settings={this.props.settings} closePopup={(id) => this.closePopup(id)}/>}
                {this.state.popupReports &&
            <PopupReports settings={this.props.settings} closePopup={(id) => this.closePopup(id)}/>}
    </section>
    </section>
</Layout>
        );
    }
}

Internal.propTypes = {
    getCurrentProfile: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
        profile: state.profile,
        auth: state.auth,
        settings: state.settings
    });

export default connect(mapStateToProps, {getCurrentProfile})(Internal);
