import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import Dropzone from "react-dropzone";
import {GRID_HEADER_SHOW_OPTIONS, GRID_HEADER_OPTION_SELECTED} from '../../../actions/types';
import {onHeaderOptionSelected, exportButton, uploadButton, onGridFetchData, exportPdfButton, getAddionalData, exportExcelButton} from '../../../actions/gridActions';
import { toast } from 'react-toastify'
import HealthProcurementReport from '../reports/HealthProcurementReport'
import DownloadExcel from '../../common/DownloadExcel'
import DownloadExcelAdd from '../../common/DownloadExcelAdd'
import Description from '../reports/Description'

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
         file: null,
         filterValues: [],
         isLoading: true
        }
        this.optionClicked = this.optionClicked.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    componentDidMount(){
       
    }

     componentWillReceiveProps(nextProps) { 
         if(!nextProps.gridProperties.addionalData){       
      if(nextProps.gridProperties.header && nextProps.gridProperties.header.addionalReactComponent && nextProps.gridProperties.header.addionalReactComponent.component === 'HealthProcurementReport'){       
         this.props.getAddionalData('/api/admin/health-procurements-list')
     }
     }
     
     if(nextProps.gridProperties.header && !nextProps.gridProperties.header.addionalReactComponent && nextProps.gridProperties.addionalData){
               this.props.getAddionalData()
     }
         
   
       if(nextProps.gridProperties && nextProps.gridProperties.header && nextProps.gridProperties.header.selectFilters && nextProps.gridProperties.header.selectFilters.length > 0){
        var filterValues = this.state.filterValues;
            nextProps.gridProperties.header.selectFilters.map(filter => {            
            if(this.state.filterValues[filter.name] === ''  && filter.selectedOption){  
                filterValues[filter.name] = filter.selectedOption;          
            }
        });    
      
        this.setState({
               filterValues: filterValues,
               isLoading: false
        })
       }
    }

    optionClicked(option) {
        this.props.onOptionSelected(option);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.props.gridProperties.header.showOptions) {
            this.props.onShowOptions();
        }
    }

     expotClicked = (name, title) => {
        this.props.exportButton(this.props.data.data, this.props.gridProperties.addionalData, title, this.props.gridProperties.columns);
    }
    
    expotExcelClicked = (route) => {
        this.props.exportExcelButton(route);
    }

    exportPdfClicked  = (route, name) => {
         if(this.state.filterValues){             
            route = route +'?';
            for (const [key, value] of Object.entries(this.state.filterValues)) {          
                if(route.endsWith('?')){
                    route = route + key + '=' + value;
                }else{
                    route = route + '&' + key + '=' + value;
                }          
            }
         }         
    this.props.exportPdfButton(route, name);
    }

     handleChange = (file) => {
     if(typeof file[0] !== 'undefined'){
      var body = new FormData();
      body.append('file', file[0])
      this.props.uploadButton(this.props.gridProperties.header.additionalUploadButton.route, body)
     }
  }

   onDropRejected = () => {
      toast.error('Upload error - your file is too big or in wrong format.', {autoClose: 10000})
   }

   handleChangeOption = (event) => {
       var filterValues = this.state.filterValues;
       filterValues[event.target.name] = event.target.value;
        this.setState({
          filterValues: filterValues
      })
      
      var route2 = '/api/admin/health-procurements-list?'; 
      var route = this.props.gridDataRoute +'?';
      for (const [key, value] of Object.entries(filterValues)) {          
          if(route.endsWith('?')){
              route = route + key + '=' + value;
              route2 = route2 + key + '=' + value;
          }else{
              route = route + '&' + key + '=' + value;
              route2 = route2 + '&' + key + '=' + value;
          }          
      }
      this.props.getAddionalData(route2);
      this.props.gridFetchData(route);
       this.setState({
          isLoading: false
      })
  }

    render() {

        const header = this.props.gridProperties.header;
      const { addionalData } = this.props.gridProperties;
            
            let addionalDataObj = {};
            if(addionalData){
                addionalDataObj = addionalData;
            }
                    
        return (
            <div className="moduleSubMenu">
                <div className="col-sm-12">
                    {
                        header &&
                        <React.Fragment>
                            <nav role="navigation" className="nav">
                                <h3 className="module-title pull-left">

                                    {header.title ?
                                    <span>{header.title}</span>
                                                :
                                    <span> {this.props.title}</span>
                                    }
                                    
                                    {
                                        header.selectedFilter &&
                                        <span>
                                    &nbsp;
                                            <span style={{fontSize: '20px', color: '#a5a3a3'}}><i
                                                className="fa fa-angle-right"
                                                aria-hidden="true"></i></span>
                                            &nbsp;
                                            { header.selectedFilter}
                                            &nbsp;
                                </span>
                                    }
                                    &nbsp;
                                </h3>
                               
                                <ul className="nav-items newButton-mobile">
                                    {
                                        header.options && header.options.length > 0 &&
                                        <li
                                            className={'nav-item dropdown newMobile' + (header.additionalButton || !header.newButton ? 'add' : '')}
                                            onClick={() => this.props.onShowOptions()}
                                            ref={this.setWrapperRef}
                                        >
                                            <a href="#">
                                                <i className="fa fa-angle-down" aria-hidden="true"></i>
                                            </a>
                                            {
                                                header.showOptions &&
                                                <nav className="submenus">
                                                    <ul className="submenus-items">
                                                        {
                                                            header.options.map((option, key) => {
                                                                return <li
                                                                    className={'submenus-item' + (option.line ? ' border-del' : '')}
                                                                    style={{marginLeft: 0}}
                                                                    key={key}>
                                                                    <a href="#"
                                                                       onClick={() => this.optionClicked(option)}
                                                                       className="submenus-link">
                                                                       {option.label}
                                                                    </a>
                                                                </li>
                                                            })
                                                        }
                                                    </ul>
                                                </nav>
                                            }
                                        </li>
                                    }

                                    {
                                        header.newButton && !header.additionalButton &&

                                        <li className="nav-item">
                                            {
                                                header.formsNotReact &&
                                                <Link to={header.newButton} className="button-primary">
                                                    <i className="ion-plus-round"></i>
                                                    <span>
                                           New
                                        </span>
                                                </Link>
                                            }
                                            {
                                                !header.formsNotReact &&
                                                <Link to={header.newButton} className="button-primary">
                                                    <i className="ion-plus-round"></i>
                                                    <span>
                                            New
                                        </span>
                                                </Link>
                                            }
                                        </li>
                                    }

                                    {
                                        header.additionalButton &&
                                        <li className="nav-item">
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-success dropdown-toggle"
                                                        data-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false">
                                                        New
                                                    <span className="caret"></span>
                                                    <span className="sr-only">Toggle Dropdown</span>
                                                </button>
                                                <ul className="dropdown-menu">
                                                    {
                                                        header.additionalButton.map((tab, key) => {
                                                            if (tab.notReact) {
                                                                return <li key={key}>
                                                                    <a href={tab.link}>

                                                                    <span>
                                                                        {tab.label}
                                                                        </span>
                                                                    </a>
                                                                </li>
                                                            }
                                                            else return <li key={key}>
                                                                <Link to={tab.link}>

                                                                <span>
                                                                  {tab.label}
                                                                    </span>
                                                                </Link>
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </li>
                                    }
                                     {header.additionalLinkButton &&
                                         <li className="nav-item" style={{paddingRight: 10, paddingLeft: 10}}>
                                        <a href={header.additionalLinkButton.link} className="upload-btn btn btn-success">
                                           <i style={{marginRight: 5}} className={header.additionalLinkButton.icon}></i>
                                               {header.additionalLinkButton.label}
                                        </a>
                                  </li>
                                    }
                                       {header.additionalExportButon &&
                                     <li className="nav-item"  style={{ paddingRight: 60}}>
                                                         <div className="btn-group">
                                                         {addionalDataObj && Object.keys(addionalDataObj).length > 0 ?
                                                         <DownloadExcelAdd 
                                                         data={this.props.data.data} 
                                                         columns={this.props.gridProperties.columns} 
                                                         title={header.additionalExportButon.title}
                                                         addionalData={addionalDataObj} 
                                                         filename={header.additionalExportButon.filename} />  
                                                         :
                                                         <DownloadExcel
                                                         data={this.props.data.data} 
                                                         columns={this.props.gridProperties.columns} 
                                                         title={header.additionalExportButon.title}
                                                         filename={header.additionalExportButon.filename} 
                                                         />
                                                         }
                                                        </div>
                                                    </li>
                                }
                                {header.additionalExportExcelButon &&
                                     <li className="nav-item"  style={{ paddingRight: 70}}>
                                                         <div className="btn-group">
                                                         <button type="button"
                                                         onClick={() => this.expotExcelClicked(header.additionalExportExcelButon.route)}
                                                         className="btn btn-success" style={{width:'100%'}}>
                                                            {header.additionalExportExcelButon.label}
                                                        </button>
                                                        </div>
                                                    </li>
                                }
                                 {header.additionalExportPdfButon &&
                                     <li className="nav-item"  style={{paddingLeft: 80, paddingRight: 60}}>
                                                         <div className="btn-group">
                                                         <button type="button"
                                                         onClick={() => this.exportPdfClicked(header.additionalExportPdfButon.route, header.additionalExportPdfButon.fileName)}
                                                         className="btn btn-success" style={{width:'100%'}}>
                                                            {header.additionalExportPdfButon.label}
                                                        </button>
                                                        </div>
                                                    </li>
                                }
                                
                                {header.selectFilters && header.selectFilters.length > 0 && header.selectFilters.map((filter, selKey) => (
                                        <li className="nav-item" style={{paddingLeft: 80, paddingRight: 60}} key={filter.name}>
                                                         <div className="btn-group">
                                                      <select className="form-control" 
                                                      onChange={this.handleChangeOption} 
                                                      value={this.state.filterValues[filter.name]} 
                                                      name={filter.name}
                                                      style={{minWidth: 100, maxWidth: 110}}>
         {filter.options && filter.options.length > 0 && filter.options.map((option,opKey) => (
                 <option key={opKey} value={option.value}>{option.label}</option>
                  ))}
                </select>
                                                        </div>
                                                    </li>
                                                    ))}

                                 {header.additionalUploadButton &&
                                     <li className="nav-item" style={{paddingRight: 10}}>
                                            <div className="">
                                            <Dropzone
                                            maxSize={20000000}
                                            onDrop={acceptedFiles => this.handleChange(acceptedFiles)}
                                            onDropRejected={this.onDropRejected}
                                            >
                                            {({getRootProps, getInputProps}) => (
                                              <section>
                                                <div {...getRootProps()}>
                                                  <input {...getInputProps()} />
                                                  <button type="button" className="upload-btn btn btn-success">
                                                  <i style={{marginRight: 5}} className="fa fa-upload"></i> {header.additionalUploadButton.label}</button>

                                                </div>
                                              </section>
                                            )}
                                          </Dropzone>

                                           </div>
                                     </li>
                                }
                               
                                </ul>
                                {
                                    header.tabs &&
                                    <ul className="nav navbar-nav pull-right">
                                        {
                                            header.tabs.map((tab, key) => {
                                                if (tab.notReact) {
                                                    return <li className="nav-item" key={key}>
                                                        <a className={tab.active ? 'active' : ''} href={tab.link}>
                                                            {tab.label}
                                                        </a>
                                                    </li>
                                                }
                                                else return <li className="nav-item" key={key}>
                                                    <a className={tab.link === window.location.pathname ? 'active' : ''} href={tab.link}>
                                                        {tab.label}
                                                    </a>
                                                </li>
                                            })
                                        }
                                    </ul>
                                }

                            </nav>

                            {
                                this.props.count && this.props.count > 15000 &&
                                <span>You have more than 15000...</span>
                            }
                        </React.Fragment>
                    }
                </div>
                 {header && header.descriptionReports && <Description type={header.descriptionReports}/>}
                {addionalData && <HealthProcurementReport  addionalData={addionalData} />}
                 
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    gridProperties: state.gridProperties,
    data: state.gridData
});

const mapDispatchToProps = dispatch => ({
    onShowOptions: () => dispatch({type: GRID_HEADER_SHOW_OPTIONS}),
    onOptionSelected: (option) => dispatch(onHeaderOptionSelected(option)),
    exportButton: (name, addionalData, title, gridProperties) => dispatch(exportButton(name, addionalData, title, gridProperties)),
    exportPdfButton: (route, name) => dispatch(exportPdfButton(route, name)),
    uploadButton: (route, data) => dispatch(uploadButton(route, data)),
    gridFetchData: (route) => dispatch(onGridFetchData(route)),
    getAddionalData: (route) => dispatch(getAddionalData(route)),
    exportExcelButton: (route) => dispatch(exportExcelButton(route))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
