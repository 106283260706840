import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from './actions/authActions';
import { clearCurrentProfile } from './actions/profileActions';

import { Provider } from 'react-redux';
import store from './store';

import routes from './routes'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {Helmet} from "react-helmet";

import cryptoRandomString from 'crypto-random-string';
import Cookies from 'js-cookie'


import './css/layout.css';
import './css/style.css';
import './css/mobile.css';
import './App.css';
// Check for token
if (localStorage.jwtToken && localStorage.jwtToken !== 'undefined') {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Clear current Profile
    store.dispatch(clearCurrentProfile());
    // Redirect to login
  //  window.location.href = '/';
  }
}

class App extends Component {
   
  render() {
    if(!Cookies.get('userSession') && Cookies.get('CookieConsent')){
       Cookies.set('userSession', cryptoRandomString({length: 20}));
    }
    return (
      <Provider store={store}>
          <Helmet>
                <meta charSet="utf-8" />               
               <meta property="og:type" content="website"/>
                <meta property="og:url" content="http://sphs-backend.composityapp.com"/>
                <meta property="og:image" content="http://sphs.composityapp.com/static/media/homepage.723fef3a.png"/>
                <meta property="og:site_name" content="UN informal Interagency Task Team on Sustainable Procurement in the Health Sector"/>
                <meta property="og:description" content="UN informal Interagency Task Team on Sustainable Procurement in the Health Sector"/>
            </Helmet>
          <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router>
          <div className="App" style={{background: 'white'}}>
              {routes}
            <ToastContainer
           position="bottom-right"
           autoClose={5000}
           hideProgressBar={false}
           newestOnTop={false}
           closeOnClick
           rtl={false}
           pauseOnVisibilityChange
           draggable
           pauseOnHover
           />
          </div>
        </Router>
        </MuiPickersUtilsProvider>
      </Provider>
    );
  }
}

export default App;
