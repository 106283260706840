import {
    COMMENTS_WIDGET_LOADED,
    COMMENTS_WIDGET_UNLOADED,
    ADD_COMMENT,
    EDIT_COMMENT,
    DELETE_COMMENT
} from '../actions/types';

function parseComments(comments) {
    let dates = parseCommentsByDate(comments);
    let result = [];
    for(var i = 0; i < dates.length; i++) {
        result[i] = {
            date: dates[i].date,
            list: parseCommentsByUser(dates[i].list),
        };
    }
    return result;
}

function parseCommentsByDate(comments) {
    var list = [];
    var temp = [];
    var currentDate = null;
    var prevDate = null;
    var currDate = null;

    for (var i = 0; i < comments.length; i++) {
        prevDate = currDate;
        comments[i].created = new Date(comments[i].created);
        currDate = comments[i].created;
        var date = comments[i].created.getDate() + ' ' + comments[i].created.getMonth() + ' ' + comments[i].created.getFullYear();

        if (currentDate == null) {
            currentDate = date;
        }

        if (currentDate == date) {
            temp.push(comments[i]);
        }
        else {
            list.push({
                date: prevDate,
                list: temp
            });
            temp = [];
            temp.push(comments[i]);
            currentDate = date;
        }
    }

    if (temp.length) {
        list.push({
            date: currDate,
            list: temp
        });
    }
    return list;
}

function parseCommentsByUser(comments) {
    var list = [];
    var temp = [];
    var currentUser = null;

    for (var i = 0; i < comments.length; i++) {

        var user = comments[i].createdById;

        if (currentUser == null) {
            currentUser = user;
        }

        if (currentUser == user) {
            temp.push(comments[i]);
        }
        else {
            list.push(temp);
            temp = [];
            temp.push(comments[i]);
            currentUser = user;
        }
    }

    if (temp.length) {
        list.push(temp);
    }

    return list;
}

function comments(state = {}, action) {
    switch(action.type) {
        case COMMENTS_WIDGET_LOADED:
            return {
                original: action.comments,
                parsed: parseComments(action.comments)
            }
        case ADD_COMMENT:
            return {
                original: [action.comment].concat(state.original || []),
                parsed: parseComments([action.comment].concat(state.original || []))
            }
        case EDIT_COMMENT:
            let res = state.original.map(comment => {
                if(comment.id == action.id) {
                    comment.message = action.message;
                }
                return comment;
            });
            return {
                original: res,
                parsed: parseComments(res)
            }
        case DELETE_COMMENT:
            let result = state.original.filter(comment => comment.id !== action.id)
            return {
                original: result,
                parsed: parseComments(result)
            }
        default:
            return state;
    }
}

export default (state = {}, action) => {
    switch (action.type) {
        case COMMENTS_WIDGET_LOADED:
        case ADD_COMMENT:
        case EDIT_COMMENT:
        case DELETE_COMMENT:
            return {
                ...state,
                comments: comments(state.comments, action)
            };
        case COMMENTS_WIDGET_UNLOADED:
            return {};
        default:
            return state;
    }
};
