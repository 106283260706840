import React, { PureComponent } from 'react';
import {
  ComposedChart, Line, Area, Bar, XAxis, YAxis, CartesianGrid, Tooltip,
  Legend,
} from 'recharts';



const getPercent = (value, total) => {
	const ratio = total > 0 ? value / total : 0;
  
  return toPercent(ratio, 2);
};

const toPercent = (decimal, fixed = 0) => {
	return decimal+"%";
};
const renderTooltipContent = (o) => {
	const { payload, label } = o;
  const total = payload ? payload.reduce((result, entry) => (result + entry.value), 0) : 0;
  
  return (
  	<div className="customized-tooltip-content">
    	<p className="total"></p>
     {payload && <ul className="list">
      	{
        	payload.map((entry, index) => (
          	<li key={`item-${index}`} style={{color: (entry.color==='#413ea0' ? '#141300' : entry.color)}}>{console.log(entry.color)}
            	{`${entry.name}: ${entry.value+'%'}`}
            </li>
          ))
        }
      </ul>}
    </div>
  );
};

export default class VerticalChart extends React.Component {
 constructor() {
    super();
  }
  render() {
      
      var ticks = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    return (
      <ComposedChart
        layout="vertical"
        width={800}
        height={500}
        data={this.props.data}
        margin={{
          top: 20, right: 40, bottom: 20, left: 310,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis type="number" ticks={ticks} tickCount={11} tickFormatter={toPercent}/>
        <YAxis dataKey="Name" type="category"  tick={{ fontSize: 14, width: 300 }}/>
       <Tooltip content={renderTooltipContent}/>
        <Legend />        
        <Bar dataKey="Score" barSize={100} fill="#413ea0" />
        <Line dataKey="Average" stroke="#ff7300" />
      </ComposedChart>
    );
  }
}