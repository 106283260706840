import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import TextTruncate from 'react-text-truncate'
import { getSingleNews } from '../../actions/newsActions';
import Navbar from '../layout/Navbar';
import NewsItem from "./NewsItem";
import SaveForLater from './elements/SaveForLater'
import ReactDom from 'react-dom';
import SocialNetworks from "./elements/contribution/SocialNetworks"
import {Helmet} from "react-helmet";
import { config } from '../../config'
import { getLogActivityWithGeoIp } from '../../actions/profileActions';


class SingleNews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: null,
            show: false
        }
    }

    handleShowBio = () => {
        this.setState({ show: true });
    }

    handleClose = () => {
        this.setState({ show: false })
    }
    componentDidMount(){
        this.props.getSingleNews(this.props.match.params.id);
        this.setState({height: window.innerHeight*0.55});
        this.props.getLogActivityWithGeoIp('Single News page', 'GET', "Website usage")
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.props.getSingleNews(this.props.match.params.id);ReactDom.findDOMNode(this).scrollIntoView();
        }
    }

    render() {
        const { singleNews } = this.props;

        let relativeNews = this.props.singleNews.relativeNews;
        let newsItem;
        let authorsInfo;
        let show;

        let height = this.state.height*0.55;
        if(this.state.show === false){
            show = 'hidden';
        }else{
            show = 'visible';
        }

        if (relativeNews && relativeNews.length > 0) {
              newsItem = relativeNews.map(news => (

                <Grid item md={4} xs={12} sm={6} key={news.id}>

                    <div className="item background-white">
                        <div className="item-image">
                            <Link className="post-list-item" to={`/news/${news.id}`}>
                                <img src={news.photo} />
                            </Link>
                        </div>
                        <div className="item-body" style={{position: "relative"}}>
                        <Link className="post-list-item" to={`/news/${news.id}`}>
                            <h3> <TextTruncate
                                line={1}
                                truncateText="…"
                                text={ news.title}
                                containerClassName="text-left text-black"
                                /></h3>
                        </Link>

                            <div className="item-description">
                                <div>
                                <TextTruncate
                                    line={5}
                                    truncateText="…"
                                    text={news.previewText}
                                    containerClassName="description text-left"
                                    /></div>
                            </div>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12} className="item-created">
                                    <div className=" text-gray post-list-creaded text-uppercase">
                                        {   news.author &&
                                            <div>By {news.author}</div>
                                        }
                                        <div>{news.humanCreatedAt}</div>
                                    </div>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <div className="category">
                                    <div className={news.categoryName}>
                                    <p>{news.categoryName}</p></div>
                                    </div>
                                </Grid>
                            </Grid>

                        </div>
                    </div>
                </Grid>
              ));
            } else {
              newsItem = <h4>News not found...</h4>;
            }

            if(singleNews.categoryName ==="Voices"){
                authorsInfo = singleNews.authors.map(author => (
                <Grid item md={6} style={{height:height*0.55, minHeight: 290, position: 'relative'}}>
                   
                        <div className="author-profile">
                            <img src={author.photo} alt={author.name}/>
                            <h4> {author.name} </h4>
                            <h5> {author.position} </h5>
                         {/*}   <hr/>
                            <i className=" text-lightblue fa fa-caret-down" aria-hidden="true"></i> */}
                        </div>
                    
                        <Grid item md={12}>   
                    <div className="author-profile-information text-center">
                     {/*   <p className="text-gray"><i>{ author.tagline }</i></p> */}
                        <h5 onClick={this.handleShowBio} className="text-center text-uppercase" style={{color: 'grey'}}>Full bio<i className="fa fa-angle-down" aria-hidden="true"></i></h5>
                    </div>
                    </Grid>
                    <div className="author-bio" style={{ visibility: show}}>
                        <span className="text-uppercase" onClick={this.handleClose}>Close<i className="fa fa-times-circle" aria-hidden="true"></i></span>
                        <p className="text-gray"><i>{ author.tagline}</i></p>

                        <div dangerouslySetInnerHTML={{__html: author.bio}}></div>
                    </div>
                </Grid>
                ));
            }

        return (
        <div>
          <Navbar/>
            <div className="page-content page-animation slideup">
            <Grid container spacing={0}>
             
                <div className="post-profile">
                    <div className="blue-area" style={{height: height}}></div>
                    <div className="post-profile-content max-width no-pdng text-left" >
                        <div style={{position: "relative", paddingTop: 150}}>
                            <Grid container spacing={0}>
                                <Grid item md={8} style={{
                                  paddingLeft: 15,
                                  paddingRight: 15
                                }}>
                                    <h1 className="post-title text-white text-bold">{ singleNews.title}</h1>
                                    <div className="post-infobox">
                                        <div className="post-date">
                                            <span className="text-dark-blue-old">Posted on </span>
                                            <span className="text-white">

                                                { singleNews.humanCreatedAt}
                                            </span>
                                        </div>
                                    </div>
                                    <h3 className="subtitle">{singleNews.subtitle}</h3>
                                    <img className="main" src={singleNews.photo} alt={ singleNews.title}/>
                                    <div className="post-description" dangerouslySetInnerHTML={{__html: singleNews.text}}></div>
                                </Grid>
                                <Grid item md={4}>   
                                <Grid container spacing={0}  direction="row" style={{marginBottom: 10}}>
                                    {authorsInfo}
                                   </Grid>                             
                                    <div className="post-profile-social-networks"
                                    style={{
                                      paddingLeft: 15,
                                      paddingRight: 15,                                    
                                   
                                    
                                    }}>
                                    {singleNews.href &&
                                    <SocialNetworks url={singleNews.href} imageURL={singleNews.photo} title={singleNews.title}/>
                                    }
                                  {singleNews.id &&
                                            <SaveForLater id={singleNews.id} type="news"/>
                                    }
                                    </div>
                                  
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
                <div className="news-list" >
                    <div className="max-width">
                        <div className="post-list-box noPadding">
                            <div className="more-news-title">
                                <h3 className="text-uppercase">MORE NEWS AND STORIES</h3>
                            </div>
                               <Grid container spacing={3}  style={{
                                 paddingLeft: 15,
                                 paddingRight: 15
                               }}>
                                {newsItem}
                                <div style={{clear: 'both' }}>
                                </div>
                            </Grid>
                        </div>
                    </div>
                </div>
            </Grid>
           </div>
          </div>
        );
  }
}

SingleNews.propTypes = {
    getSingleNews: PropTypes.func.isRequired,
    getLogActivityWithGeoIp: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    singleNews: state.news.singleNews
});

export default connect(mapStateToProps, {getSingleNews, getLogActivityWithGeoIp})(SingleNews);
