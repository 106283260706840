import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {getQuestionnaireFill} from '../../../actions/questionnaireActions'
import Form from './Form'
import Navbar from '../../layout/Navbar'
import Footer from '../../layout/Footer'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import validate from './validator';
import { SubmissionError } from 'redux-form'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { onSave, onSaveData } from "../../../actions/formActions";
import { getCurrentProfile, getLogActivityWithGeoIp } from "../../../actions/profileActions";
import { Redirect } from 'react-router'
import QuestNav from './QuestNav'
import LinearProgress from '@material-ui/core/LinearProgress';
import DialogConfirmOverrideQuest from './Helpers/DialogConfirmOverrideQuest'
import showAToast from '../../../helpers/showAToast'

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'dark' ? 700 : 200],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);
const drawerWidth = 300;
const styles = theme => ({
  root: {
    flexGrow: 1,
  },
   drawerPaper: {
    width: drawerWidth,
    marginTop: 100,
 //   background: '#fff',
   // color: 'white'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
   paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width: '100%',
  //  height: '100%',
    color: '#000000de'
  },
    extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'fixed',
    top: theme.spacing(15),
    left: theme.spacing(2),
    background: '#203648',
  },
});

class FillQuest extends Component {
  constructor() {
    super();
    this.state = {
      openMenu: false,
      logged: false
    };
  }

     componentDidMount() {
        this.props.getQuestionnaireFill(this.props.match.params.id, this.props.match.params.sectionId, this.props.history, 1)
        this.props.getCurrentProfile()
    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.profile && nextProps.profile.profile && nextProps.profile.profile.roles.includes('ROLE_QUESTIONNAIRE') && !this.state.logged){
            this.props.getLogActivityWithGeoIp('User Fill Questionnaire', 'GET', "QR SA tool")
            this.setState({
                logged: true
            })
        }
 }

    updateQuest = () => {
        this.props.getQuestionnaireFill(this.props.match.params.id, this.props.match.params.sectionId, this.props.history, 1)
    }

    handleSave = (values, props) => {
        if(Object.keys(values).length > 0){
            this.sendValues(values, null)
        }
    }

    submit = (values, props) => {
         var errors = validate(values,this.props)
         var url = '/'
        if(this.props.questionnaire.questFill.nextSection){
         url = '/questionnaire/fill/'+this.props.match.params.id +'/'+ this.props.questionnaire.questFill.nextSection
        }else{
            url = '/user/questionnaires'
        }
         if(Object.keys(errors).length > 0){
             throw new SubmissionError(errors)
         }else if(values){
            this.sendValues(values, url)
         }
    }

    sendValues = (values, url) => {
     if(this.props.questionnaire.questFill.status === "completed"){
               showAToast('This questionnaire is completed!', 'errorCompleted', 'error')
           }else if(this.props.questionnaire.questFill.status === "cancelled"){
               showAToast('This questionnaire is cancelled!', 'errorCancelled', 'error')
           }else{
        let data = {}
        var formDataField = {};
        const formData = Object.assign(formDataField, values);
        let formJson = {};
        let fields = this.props.formProperties['initializeFromState2'].form.fields;

        let isForm = false;
        if(typeof fields !== 'undefined'){
            var body = new FormData();
            Object.keys(fields).forEach(( key ) => {
                let field = fields[key]

                if(field.id in formData){
                if(field.type === 'file'){
                         isForm = true;
                         let files = formData[field.id];

                         if(typeof files === 'number'){
                             body.append(field.id+'[]', files)
                         }
                         if(typeof files === 'object' && files.length > 0){
                            files.map((file, key) => {
                                body.append(field.id+'[]', formData[field.id][key]['file'])
                            })
                        }
                    }else if(field.type === 'select' || field.type === 'checkboxGroup'){

                       body.append(field.id, JSON.stringify(formData[ field.id ]));
                       formJson[field.id] =  JSON.stringify(formData[ field.id ]);
                    }else{
                        body.append(field.id, formData[ field.id ]);
                       formJson[field.id] =  formData[ field.id ];
                    }

                }
            });
            var button;
            if(url){
               body.append('button', 'submit');
               formJson['button'] =  'submit';
            }else{
               body.append('button', 'save');
               formJson['button'] =  'save';
            }
            if( this.props.questionnaire.questFill.nextSection){
                body.append('nextSection', this.props.questionnaire.questFill.nextSection);
                formJson['nextSection'] =  this.props.questionnaire.questFill.nextSection;
            }
            if (isForm) {
               data = body;
            }else{
                data = formJson
            }

            if(url){
               this.props.onSave('/api/section-fill/save/'+this.props.match.params.id +'/'+ this.props.questionnaire.questFill.section.id, data, isForm, url, this.props.history)
            }else{
                 this.props.onSaveData('/api/section-fill/save/'+this.props.match.params.id +'/'+ this.props.questionnaire.questFill.section.id, data, isForm)
            }
         }
      }
    }



  render() {
     const { classes } = this.props;
     const {questFill} = this.props.questionnaire;
     const {profile} = this.props.profile;
    if(questFill && !questFill.filledIdentification){
        return <Redirect to={'/user/questionnaire/indentification/'+this.props.match.params.id}/>;
    }
    var progressValue = 0;
    if(questFill){
        var sectionsNumber = questFill.quest.sections.length;
        var submittedNumber = questFill.submitted.length;
        if(questFill.filledIdentification){
            sectionsNumber +=1;
            submittedNumber +=1;
        }
        if(submittedNumber){
           progressValue = submittedNumber / (sectionsNumber / 100);
           progressValue = progressValue.toFixed(2);
        }
    }
    return (
      <div className="page-content page-animation slideup" style={{top: 0}}>
      <Grid container className={classes.root} direction="row" justify="flex-start">
          <Grid md={12} item className="text-center" container  justify="center">
           <Grid item  md={1}></Grid>
           <Grid item  md={6}><BorderLinearProgress value={progressValue} variant="determinate" style={{marginTop: 150}}/><span>{progressValue}%</span></Grid>
         </Grid>

         {profile && questFill && questFill.lockedById !== profile.id &&
         <Grid md={12} item container  justify="flex-start">
           <Grid item  md={2}></Grid>
           <Grid item  md={8}>
            <DialogConfirmOverrideQuest questionId={this.props.match.params.id} updateQuest={this.updateQuest}/>
            </Grid>
         </Grid>}
          <Grid item  md={1}></Grid>
          {questFill && <Grid item  md={12} className="text-center" container  justify="center" style={{marginTop: 20, fontSize: 16}}>
              <b style={{marginRight: 10}}>Questionnaire respondents:</b>{questFill.users}</Grid>    }
                <Grid item  md={1}></Grid>
           {profile && questFill && questFill.filledIdentification && (questFill.lockedBy === null || questFill.lockedById === profile.id) &&
           <Grid item container md={10}  >
                     <Form
                            onSubmit={this.submit}
                            title={questFill.section.name}
                            form={'initializeFromState2'}
                            formRoute={'/api/section-fill/data/'+this.props.match.params.sectionId + '/' +  this.props.match.params.id}
                            saveRoute={''}
                            section={questFill.section}
                            alone={true}
                            nextUrl={questFill.nextSection ? '/questionnaire/fill/'+this.props.match.params.id+'/'+questFill.nextSection : null}
                            backUrl={questFill.backSection ? '/questionnaire/fill/'+this.props.match.params.id+'/'+questFill.backSection : null}
                            isNew={true}
                            handleSave={this.handleSave}
                            submitButton={true}
                            download={false}
                          />
                </Grid>


        }
      {questFill &&<Grid item  md={2} style={{margin: 60}}>
          <QuestNav
          questionnaire={this.props.questionnaire}
          id={this.props.match.params.id}
            /></Grid>}

      </Grid>
                    <Navbar/>
            <Footer/>
    </div>
    );
  }
}

FillQuest.propTypes = {
  questionnaire: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  getQuestionnaireFill: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  formProperties: PropTypes.object.isRequired,
  onSaveData: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  getLogActivityWithGeoIp:  PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  questionnaire: state.questionnaire,
  formProperties: state.formProperties,
  form: state.form
});

FillQuest = withStyles(styles)(FillQuest)

export default withRouter(connect( mapStateToProps, { getQuestionnaireFill, onSave, onSaveData, getCurrentProfile, getLogActivityWithGeoIp })(FillQuest));
