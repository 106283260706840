import React from "react";
import {connect, touch} from 'react-redux';
import PropTypes from "prop-types";
import DropZone from "react-dropzone";
import ImagePreview from "./ImagePreview";
import Placeholder from "./placeholder";
import ShowError from "./showError";
import { toast } from 'react-toastify';
import saveAs from 'file-saver';
import Button from '@material-ui/core/Button';
import {downloadFile } from '../../actions/documentActions'
const imagefile = null;

class DropZoneComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            files: []
            };
    }

    componentDidMount() {
            if(this.props.data){
                let image = []
          this.props.data.map((file, key) => (
            image[key] = {
                name: file.name,
                preview: file.preview,
                size: file.size,
                type: file.type,
                fileId: file.fileId ? file.fileId : null
          }
          ));
         if(this.state.files.length < 1){
             this.setState({
              files: image
          });
         }
            }
        }

   
   
   componentWillUpdate(nextProps) {
    
  }

   handleOnDrop = (newImageFile, onChange) => {
    if (newImageFile.length <= 0) {
      toast.error("The file must be image under 2MB!");
    } else {
      toast.success("File uploaded successfully");
      const imageFile = {
        file: newImageFile[0],
        name: newImageFile[0].name,
        preview: URL.createObjectURL(newImageFile[0]),
        size: newImageFile[0].size,
        type: newImageFile[0].type
      };

      let files = this.state.files;
      if(this.state.filesMultiple){
          files.push(imageFile)
      }else{
          files[0] = imageFile;
      }
      
      this.props.input.onChange(files)  
      if(this.props.field){
      this.props.change(this.props.field.id, files)
  }
      this.setState({ files: files }, () => onChange(files));
        this.props.input.onChange(files)
    
    }
  };

  handleOnDelete = (e, onChange) => {
      e.preventDefault()

     let files = this.state.files;
     files.map((fileObj, key) => {         
        if(fileObj.preview === e.target.value){
            files.splice(key, 1)
        }
      })

      this.setState({ files: files }, () => onChange(files));

  }


  handleChange = (files) => {

  }
  
clickFileDownload = (route, name) => event => {
        event.preventDefault()        
        this.props.downloadFile(route, name)
       
    }

    render() {
        
    
 const { input: { onChange }, label, data, accept, field, meta: { error, touched }, ...custom } = this.props;
 let multiple = false;
 let preview = true;
 let download = false;
if(this.props.download){
    download = this.props.download;
}
 if(typeof field !== 'undefined'){
     multiple = field.multiple;
     preview = field.preview;     
 }else if(this.props.multiple && this.props.preview){
     multiple = this.props.multiple;
     preview = this.props.preview;
 }
 
   let disabled = false;
       if(this.props.field && this.props.field.disabled){
        disabled = this.props.field.disabled;  
       }

        return (
            <div className="preview-container">

       <div className="dropzoneLabel">{label}</div>
    <DropZone
      accept={ accept ? accept : "" }
      className="upload-container"
      onDrop={file => this.handleOnDrop(file, onChange)}
      maxSize={2000000}
      multiple={multiple}
     value={multiple ? this.state.files : this.state.files[0]}
     disabled={disabled}
    >

      {({ getRootProps, getInputProps }) =>
        this.state.files && this.state.files.length > 0 ? (
        <span>
        <Placeholder
            error={error}
            touched={touched}
            getInputProps={getInputProps}
            getRootProps={getRootProps}
          />

          {this.state.files.map((file, key) => (
        <span key={key}> 

       {preview ?
         <ImagePreview file={file}  key={key}  download={download}/>
         :
        <h5 style={{marginTop: 10}}>{file.name}</h5>
     }
     {download && <Button size="small" href='' onClick={this.clickFileDownload('/api/members/documents/files/'+file.fileId+'/download', file.name)}
        variant="outlined" style={{margin: 10}}>Download</Button>}
         <button type="button" className="dropzone-button" onClick={file => this.handleOnDelete(file, onChange)} value={file.preview} >x</button>

      </span>
        ))}
        </span>
        ) : (
        <span>

          <Placeholder
            error={error}
            touched={touched}
            getInputProps={getInputProps}
            getRootProps={getRootProps}
          />


          </span>
        )
      }
    </DropZone>
    <ShowError error={error} touched={touched} />
  </div>
        );
    }
}

const mapStateToProps = state => ({
    formData: state.formData,
    form: state.form
});
export default connect(mapStateToProps, {downloadFile})(DropZoneComponent);
