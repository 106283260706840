import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentProfile, getProfilesByUserId } from '../../actions/profileActions';
import Spinner from '../common/Spinner';
import ProfileActions from './ProfileActions';
import Navbar from '../layout/Navbar';
import Footer from '../layout/Footer';
import Grid from '@material-ui/core/Grid';
import logoSide from '../../img/logo-side-menu.png';



class MembersList extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.props.getCurrentProfile();
        this.props.getProfilesByUserId();
    }

    handleChange(event){
        this.props.getProfilesByUserId(event.target.value);
    }

    render() {
        const { profile, profiles } = this.props.profile;
        let dashboardContent;
        if (profile === null) {
            dashboardContent = <Spinner />;
        }else{
            dashboardContent = <ProfileActions profileItem={profile}/>
        }

        return (
          <div className="container-fluid" >
            <div className="page-content slide page-animation">
            <Grid container spacing={0}>
                <div>
                    <div className="page-content user-member-list">
                        {dashboardContent}
                        <div className="filter-area">
                            <div className="max-width">
                                <Grid item md={12} className="filter-selectors no-pdng">
                                    <Grid item md={3} xs={10} className="filter-search-box pull-right no-pdng">
                                    <div>
                                        <input onChange={this.handleChange} type="text" name="filterSearch" placeholder="Search" ng-model="filterSearch" ng-model-options="{ debounce: 1000 }"/>
                                      </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>

                        <div className="clearfix"></div>
                        <div className="max-width">
                            {profiles && profiles.map(member => (
                            <div className="col-md-3 col-sm-4 col-xs-6 member-content text-center">
                                <div className="knowledge-and-practicee">
                                    <img height="300" width="300" className="leading-image" src={ member.photo }/>

                                    <span>
                                        <h5>{ member.lastName } { member.firstName }</h5>
                                    </span>
                                    <span>
                                        <h5>{ member.email }</h5>
                                    </span>
                                    <a className="text-center" href={ member.href }>
                                       Contact Details
                                    </a>
                                </div>
                            </div>
                            ))
                            }
                        </div>
                    </div>
                    <Navbar/>
                </div>
            </Grid>
            </div>
          </div>
        );
    }
}

MembersList.propTypes = {
                getCurrentProfile: PropTypes.func.isRequired,
                getProfilesByUserId: PropTypes.func.isRequired,
                auth: PropTypes.object.isRequired,
                profile: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
                profile: state.profile,
                auth: state.auth
});

export default connect(mapStateToProps, { getCurrentProfile, getProfilesByUserId })(MembersList);
