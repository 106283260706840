import client from './client';

import {
  GET_CONTACTS_MAP,
} from './types';

 
export const getMapContacts = () => dispatch => {    
 
  client
    .get('/web/filter/contacts')
    .then(res => {        
      dispatch({
        type: GET_CONTACTS_MAP,
        payload: res.data
      })     
  })
    .catch(err => {       
      dispatch({
        type: GET_CONTACTS_MAP,
        payload: {}
      })
       
  });
};

export const setMapContacts = () => dispatch => {
     dispatch({
        type: GET_CONTACTS_MAP,
        payload: {}
      })
}








