import React, { Component } from "react";
import PropTypes from "prop-types";
import Link from '@material-ui/core/Link';
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames';
import { getCollaborationsForAccount, postCollaborationCreate, postCollaborationLineCreate, getDeleteCollaborationLine } from "../../../actions/collaborationActions";
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import CollaborationFrom from './CollaborationFrom'
import moment from 'moment'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CollaborationLineForm from './CollaborationLineForm'
import TextTruncate from 'react-text-truncate'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: "20px 10px",
    backgroundColor: "#f5f5f5",
    borderRadius: 4
  },
  btnNew: {
    marginLeft: 30,
    fontWeight: "bold"
  },
  btnRound: {
    fontSize: 14,
    width: 20,
    height: 20,
  },
  list: {
    maxWidth: "1000px",
  },
  linkTitle:{
    fontWeight: "700",
  },
  buttonRoot: {
      textDecoration: 'none',
    backgroundColor: '#4eb0e2'
},
  rootDialog: {
      backgroundColor: "transparent"
  },
  cardTd: {
    padding: "9px 0 9px 10px",
  },
  Card: {
    marginBottom: 10,
  },
 'Card:last-child': {
   marginBottom: 0
 }
});

class Collaborations extends Component {
  constructor() {
    super();
    this.state = {
      right: false,
      open: false,
      collaborationId: null,
      collaboration: null,
      collaborationLine: null
    };
  }

    componentDidMount() {
         this.props.getCollaborationsForAccount(this.props.accountId)
    }

    openForm = (side, open) => event => {
       // event.preventDefault()
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
          }

          this.setState({ [side]: open });
    }
    closeForm = (side, open) => event => {
        this.setState({
            [side]: open,
            collaboration: null
        });
    }
    handleOnSubmitCollaboration = (values) => {
        let data = [];
        let formData = Object.assign({}, values);

        if(values.assignees.length > 0){
            let assignees = [];
            values.assignees.map(assignee => {
               assignees.push(assignee.value)
            })
             formData.assignees = assignees;
        }
        if(values.contacts && values.contacts.length > 0){
            let contacts = [];
            values.contacts.map(contact => {
               contacts.push(contact.value)
            })
             formData.contacts = contacts;
        }
        if(values.organizations && values.organizations.length > 0){
            let organizations = [];
            values.organizations.map(organization => {
               organizations.push(organization.value)
            })
             formData.organizations = organizations;
        }

        formData.dueDate = moment(formData.dueDate, "DD-MM-YYYY").format();

        let collaborationId = this.state.collaboration ? this.state.collaboration.id : null;
        this.props.postCollaborationCreate(formData, this.props.accountId, collaborationId)
        this.setState({
           right: false,
           collaboration: null
       })
    }

    openFollowUpDate = (id) => {
        this.setState({
           open: true,
           collaborationId: id
       })
    }

     closeFollowUpDate = () => {
        this.setState({
           open: false,
           collaborationId: null,
           collaborationLine: null
       })
    }

    handleOnSubmitFollowUpDate = (values) => {
        
        values.followUpDate = moment(values.followUpDate, "DD-MM-YYYY HH:mm:ss").format();
        this.props.postCollaborationLineCreate(values)
        this.setState({
           open: false,
           collaborationId: null
       })
    }

    editCollaborations = (collaboration) => {
        this.setState({
           right: true,
           collaboration: collaboration
       })
    }

    editFollowUpDate = (collaborationLine, collaborationId) => {
         this.setState({
           open: true,
           collaborationLine: collaborationLine,
           collaborationId: collaborationId
       })
    }

    getDeleteCollaborationLine = (id) => {
        this.props.getDeleteCollaborationLine(id)
    }

     render() {
    const { classes } = this.props;
    const { collaborations } = this.props.collaboration;

    return (
           <Grid container className={classes.root} direction="row" justify="flex-start"  >

           <Grid item md={12} lg={12} xs={12} container  spacing={2}>
                 <Drawer anchor="right" open={this.state.right} onClose={this.closeForm('right', false)}>
                   <div className={classes.list} role="presentation">
                   <CollaborationFrom onSubmit={this.handleOnSubmitCollaboration} initialValues={this.state.collaboration}/>
                   </div>
                  </Drawer>
                 <h3 className="text-center">Collaborations</h3>
                 <Link onClick={this.openForm('right', true)} className={classNames("button-primary", classes.btnNew)}>NEW</Link>
                 {collaborations.length > 0 &&
                <Grid item md={12} lg={12} xs={12} container>
                 {collaborations.map((collaboration, key) => (
                <Grid item md={12} lg={12} xs={12} key={key} className="m-bot30">
                {collaboration.status===1 ? <h4 className="collaborationStatus">Ongoing</h4> : <h4 className="collaborationStatus">Closed</h4>}
                   <ExpansionPanel defaultExpanded={collaboration.status===1 ? true : false}>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                      >
                  <div>
                    <Link onClick={() => this.editCollaborations(collaboration)}><h4 className="margin-top"><i className="fa fa-angle-double-right" aria-hidden="true"></i>&nbsp;<span className={classes.linkTitle}>{collaboration.areas} ({collaboration.dueDate})</span></h4></Link>
                      <div className="task margin-top">
                        <div className="text-left" style={{marginLeft: 5}}>Add follow up date
                          <div className="dropdown div-inline">
                            <button className={classNames("btn-small", classes.btnRound)} type="button" onClick={() => this.openFollowUpDate(collaboration.id)}><i className="ion-android-add "></i></button>
                                <Dialog
                                onClose={this.closeFollowUpDate}
                                aria-labelledby="simple-dialog-title"
                                open={this.state.open}
                                maxWidth="lg"
                                fullWidth
                                BackdropProps={{
                                classes: {
                                 root: classes.rootDialog
                                }
                               }}>
                                    <DialogTitle id="simple-dialog-title">Follow up date <span className="pull-right pointer" onClick={this.closeFollowUpDate}><i className="far fa-times-circle"></i></span></DialogTitle>
                                   {this.state.collaborationId &&
                                      <CollaborationLineForm onSubmit={this.handleOnSubmitFollowUpDate} collaborationId={this.state.collaborationId} initialValues={this.state.collaborationLine}/>
                                   }
                                </Dialog>
                        </div>
                       </div>
                      </div>
                      </div>
                    </ExpansionPanelSummary>
                       {collaboration.lines.length > 0 &&
                               <Grid item md={12} lg={12} xs={12}>
                                   <p className="text-center margin-top">Follow up dates</p>
                                   {collaboration.lines.map((line, i) => (
                                       <Card className={classNames("CardContent", classes.Card)} key={i}>
                                             <CardContent>
                                              <table>
                                                  <thead>
                                                    <tr>
                                                      <th className={classes.cardTd}>Date</th>
                                                      <th className={classes.cardTd}>Activity</th>
                                                      <th className={classes.cardTd}>Comments</th>
                                                      <th className={classes.cardTd}></th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <td className={classes.cardTd}>{moment(line.followUpDate, "DD-MM-YYYY HH:mm:ss").format("MMM Do YYYY, h:mm a")}</td>
                                                        <td className={classes.cardTd}>{line.activities}</td>
                                                        <td className={classes.cardTd}>{line.comments}</td>
                                                        <td className={classes.cardTd}>
                                                        <button onClick={() => this.editFollowUpDate(line, collaboration.id)} className="btn btn-default">Edit</button>
                                                         &nbsp;
                                                         <button onClick={() => this.getDeleteCollaborationLine(line.id)} className="btn btn-default">Delete</button>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                              </table>
                                             </CardContent>
                                           </Card>
                                      ))}
                               </Grid>
                       }
                  </ExpansionPanel>

                  </Grid>
                             ))}
                 </Grid>
                 }
           </Grid>

            </Grid>
                );
     }

}

Collaborations.propTypes = {
  getCollaborationsForAccount: PropTypes.func.isRequired,
  postCollaborationLineCreate: PropTypes.func.isRequired,
  postCollaborationCreate: PropTypes.func.isRequired,
  getDeleteCollaborationLine: PropTypes.func.isRequired,
  collaboration:    PropTypes.object.isRequired,
  classes:    PropTypes.object.isRequired,
  accountId: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
 collaboration: state.collaboration
});

Collaborations = withStyles(styles)(Collaborations)

export default connect( mapStateToProps, { getCollaborationsForAccount, postCollaborationCreate, postCollaborationLineCreate, getDeleteCollaborationLine })(withRouter(Collaborations));
