import React, {Component} from 'react';
import SimpleBarChart from './SimpleBarChart'
import Grid from "@material-ui/core/Grid";


class PDFChartReportComponent extends Component {
  constructor() {
    super();  
    }
    render() {
        return (<> 
        <Grid container spacing={0} style={{background: 'white'}}>
            {this.props.data &&<Grid item lg={12} md={12} xs={12} > 
           <span>           
           {this.props.data.map((row, key) => (
                   <span key={key}>
                     <h3 style={{marginLeft: 10}}><div dangerouslySetInnerHTML={{ __html: row.name }} /></h3>
                       <SimpleBarChart data={row.chartData} />
                       </span>
                    ))}          
               </span>
            </Grid>}    
            </Grid>
            </>);
        }
}

export default PDFChartReportComponent;
