import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import PropTypes from 'prop-types';
import MenuLoader from './MenuLoader';
import MenuContent from './MenuContent';
import MenuMobile from './MenuMobile';
import MenuAdministration from './MenuAdministration';
import PopupAccounts from "./PopupAccounts";
import PopupAdministration from "./PopupAdministration";
import PopupReports from './PopupReports';
import PopupWebsite from './PopupWebsite';
import PopupResources from './PopupResources';
import PopupQuest from './PopupQuest';
import {
  onSidebarMount,
  onSidebarChange,
} from '../../../../actions/menuActions';
import Modal from '../../../common/ModalForm';
import { logoutUser } from '../../../../actions/authActions';
import { clearCurrentProfile } from '../../../../actions/profileActions';

class MainMenu extends Component {

  state = {
    popupAccounts: false,
    popupAdministration: false,
    popupReports: false,
    popupResources: false,
    popupWebsite: false,
    popupQuest: false,
  };

  componentDidMount() {
    this.props.onSidebarMount();
  }

  closePopup = (id) => {
    this.setState({
      [id]: false
    });
  }

  changeSidebar = () => {
    this.props.onSidebarChange(this.props.settings.sidebar === 'nav-collapse' ? 'nav-collapse closed' : 'nav-collapse');
  }

  componentDidUpdate = () => {
    let brand = document.getElementsByClassName('brand');

    if (this.props.settings.sidebar === 'nav-collapse') {
      let fastButton = document.getElementsByClassName('add-fast-button');

      if (fastButton.length > 0) {
        let fastWidth = fastButton[0].offsetWidth;
      }
    } else {
      brand[0].style.width = 'auto';
    }
  }

  render() {

    let menuIconsTheme = {};
    let closeMenuTheme = {};

    if (this.props.settings) {

      menuIconsTheme = {
        background: '-webkit-gradient(linear, left top, right top, from(' + this.props.settings.firstColor + '), to(' + this.props.settings.secondColor + '))',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
      };

      closeMenuTheme = {
        background: '-webkit-gradient(linear, left top, right top, from(' + this.props.settings.firstColor + '), to(' + this.props.settings.secondColor + '))',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
      };
    }

    return (
      <aside>

        {this.state.popupAccounts &&
          <PopupAccounts settings={this.props.settings} closePopup={(id) => this.closePopup(id)} />
        }

        {this.state.popupAdministration &&
          <PopupAdministration settings={this.props.settings} closePopup={(id) => this.closePopup(id)} />
        }

        {this.state.popupReports &&
          <PopupReports settings={this.props.settings} closePopup={(id) => this.closePopup(id)} />
        }

        {this.state.popupResources &&
          <PopupResources settings={this.props.settings} closePopup={(id) => this.closePopup(id)} />
        }

        {this.state.popupWebsite &&
          <PopupWebsite settings={this.props.settings} closePopup={(id) => this.closePopup(id)} />
        }

        {this.state.popupQuest &&
          <PopupQuest settings={this.props.settings} closePopup={(id) => this.closePopup(id)} />
        }

        { this.props.settings.sidebar === 'nav-collapse closed' &&
          <a href="#" onClick={this.changeSidebar} className="collapse-icon">
            <i className="fa fa-bars" aria-hidden="true"></i>
          </a>
        }

        <div id="sidebar" className={this.props.settings.sidebar ? this.props.settings.sidebar : "nav-collapse"}>
          {this.props.settings.sidebar && <div className="leftside-navigation">
            <ul className="sidebar-menu" id="nav-accordion">
              <MenuContent theme={menuIconsTheme}
                settings={this.props.settings}
                onPopupAccounts={() => this.setState({ popupAccounts: true })}
                onPopupAdministration={() => this.setState({ popupAdministration: true })}
                onPopupReports={() => this.setState({ popupReports: true })}
                onPopupResources={() => this.setState({ popupResources: true })}
                onPopupWebsite={() => this.setState({ popupWebsite: true })}
                onPopupQuest={() => this.setState({ popupQuest: true })}
              />
            </ul>

            {this.props.settings.sidebar && this.props.settings.sidebar === 'nav-collapse' &&
              <a id="closeMenu"
                href="#"
                onClick={this.changeSidebar}>
                <i style={closeMenuTheme} className="glyphicon glyphicon-chevron-left"></i>
                <span>Collapse menu</span>
              </a>
            }
  
            {this.props.settings.sidebar && this.props.settings.sidebar !== 'nav-collapse' &&
              <a id="closeMenu" className='active'
                href="#"
                onClick={this.changeSidebar}>
                <i style={closeMenuTheme} className="glyphicon glyphicon-chevron-left"></i>
                <span>Collapse menu</span>
              </a>
            }
  
            {!this.props.settings.sidebar &&
              <a id="closeMenu" href="/#" onClick={this.changeSidebar}>
                <i style={closeMenuTheme} className="glyphicon glyphicon-chevron-right"></i>
              </a>
            }
          </div>
          }

          {!this.props.settings.sidebar &&
            <div style={{ marginTop: '0px', marginLeft: '0px', textAlign: 'center' }}>
              <MenuLoader image={'/bundles/base/images/sidebar_dark.png'} />
            </div>
          }
        </div>

        {window.innerWidth < 1067 &&
          <MenuMobile 
          theme={menuIconsTheme} 
          settings={this.props.settings} 
          change={this.changeSidebar} 
          onPopupAccounts={() => this.setState({ popupAccounts: true })}
          onPopupAdministration={() => this.setState({ popupAdministration: true })}
          onPopupReports={() => this.setState({ popupReports: true })}
          onPopupResources={() => this.setState({ popupResources: true })}
          onPopupWebsite={() => this.setState({ popupWebsite: true })}
          onPopupQuest={() => this.setState({ popupQuest: true })}
          logoutUser={this.props.logoutUser}   
          clearCurrentProfile={this.props.clearCurrentProfile}
          />
        }

        <div className="mobile_sidebar" id="mobile_administration">
          <div className="leftside-navigation">
            <ul className="sidebar-menu">
              <MenuAdministration theme={menuIconsTheme} settings={this.props.settings} />
            </ul>
          </div>
        </div>
      </aside>
    );
  }
}

MainMenu.propTypes = {
  onSidebarMount: PropTypes.func.isRequired,
  onSidebarChange: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  settings: state.settings,
  profile: state.profile
});

const mapDispatchToProps = dispatch => ({
  onSidebarMount: (route) => dispatch(onSidebarMount(route)),
  onSidebarChange: (db, sidebar) => dispatch(onSidebarChange(db, sidebar)),
  logoutUser: () => dispatch(logoutUser()),
  clearCurrentProfile: () => dispatch(clearCurrentProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
