import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ModalConfirm from '../../common/ModalConfirm';
import {onShowColumns, onCheckColumn, onColumnUpdate} from '../../../actions/gridActions';
import {Link} from 'react-router-dom';

class Columns extends Component {

    constructor(props) {
        super(props);

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleEscClick = this.handleEscClick.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.showColumnsModal = this.showColumnsModal.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('keydown', this.handleEscClick);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.props.onShowColumns();
        }
    }

    handleEscClick(e) {
        if (e.keyCode === 27) {
            this.props.onShowColumns();
        }
    }

    showColumnsModal() {
        this.props.onShowColumns();
    }

    handleInputChange(key, childKey) {
        this.props.onCheckColumn(key, childKey)
            .then(() => {
            let cols = [];
            this.props.columns.map(column => {
                //if has children itterate them and check
                if (column.children) {
                    column.children.map(child => {
                        if (!child.hide) {
                            cols.push(child.field);
                        }
                    });
                } else {
                    if (!column.hide) {
                        cols.push(column.field);
                    }
                }
            });
            this.props.onColumnUpdate(this.props.gridProperties.options.enableColumns, cols);
        });
    }

    render() {
        return (
            <React.Fragment>
            <div className="col-md-6 columns-button">
                <div className="columns-button-open" onClick={this.showColumnsModal}>
                    <a href="#" className="" title={'Add columns'}>
                        <i className="fa fa-table" aria-hidden="true"></i>
                    </a>   
                </div>     
                {
                    this.props.gridProperties.showColumns &&
                    <ModalConfirm onClose={this.showColumnsModal}
                                  customClass={"column-selector"}
                                 >    
                        <section className="newPanel"
                                 ref={this.setWrapperRef}>

                            <div className="panel-body">
                                <div className="row">
                                    <ul className="">
                                        {this.props.columns.map((item, key) => {
                                            return !('hideFromList' in item) &&
                                                <div key={key}  className="column-selector-item">
                                                    {
                                                        'children' in item ?
                                                            <div>
                                                                <li key={key} className="">
                                                                    <label className="custom-checkbox">
                                                                        {item.headerName}
                                                                        <input name={item.id} type="checkbox" key={key}
                                                                               onChange={() => this.handleInputChange(key)}
                                                                               checked={!this.props.columns[key].hide}/>
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                </li>
                                                                <ul className="">
                                                                    {item.children.map((child, chkey) => {
                                                                        return <li key={chkey} className="list">
                                                                            <label className="custom-checkbox">
                                                                               {child.headerName}
                                                                                <input name={child.field} id={item.id}
                                                                                       type="checkbox" key={chkey}
                                                                                       onChange={() => this.handleInputChange(key, chkey)}
                                                                                       checked={!this.props.columns[key].children[chkey].hide}
                                                                                />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                        </li>
                                                                    })}
                                                                </ul>
                                                            </div>
                                                            :
                                                            <li key={key} className="">
                                                                <label className="custom-checkbox">
                                                                    {item.headerName}
                                                                    <input name={item.id} type="checkbox" key={key}
                                                                           onChange={() => this.handleInputChange(key)}
                                                                           checked={!this.props.columns[key].hide}/>
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                            </li>
                                                    }
                                                </div>
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </section>
                    </ModalConfirm>
                }
            </div>
            {this.props.gridProperties.header.settingsPath && this.props.settings.roles.includes('ROLE_SUPER_ADMIN') &&
                <div className="col-md-6">
                    <Link to={this.props.gridProperties.header.settingsPath} className="grid-settings" title={'Settings'}>
                        <i className="fas fa-cog" aria-hidden="true"></i>
                    </Link>
                </div>
            }
        </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    gridProperties: state.gridProperties,
    gridData: state.data
});

const mapDispatchToProps = dispatch => ({
    onShowColumns: () => dispatch(onShowColumns()),
    onCheckColumn: (key, childKey) => dispatch(onCheckColumn(key, childKey)),
    onColumnUpdate: (route, cols) => dispatch(onColumnUpdate(route, cols))
});

export default connect(mapStateToProps, mapDispatchToProps)(Columns);

