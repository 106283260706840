import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

 class DialogConfirmDeleteQuest extends React.Component {       
  constructor(props) {
    super(props);
    this.state = {
       
    };
  }
 componentDidMount() {
            
        }
    deleteQuest = () => {    
        this.props.confirmDeleteQuest(this.state.questionId)
    }
    
    confirmDeleteQuest = () => {     
      this.props.deleteQuestion(this.state.questionId)
    
  }
    
  render() {
    return <Dialog
                        onClose={this.props.closeDeleteQuest}
                        aria-labelledby="simple-dialog-title"
                        open={this.props.openConfirmDelete}
                        maxWidth="sm"
                        fullWidth
                       >

                         <DialogContent>
                         <section className="newPanel">
                            <div className="panel-body">
                                <div>
                                    <div className="text-center">

                                        <h3>
                                            Are you sure?
                                        </h3>
                                        <button className="button-outlines green text-center"
                                                onClick={this.props.closeDeleteQuest}>
                                            No
                                        </button>
                                        <button className="button-primary-edited text-center btn-ok"
                                                onClick={this.deleteQuest}>
                                           Yes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>
                         </DialogContent>
                      </Dialog>

 }
}

export default DialogConfirmDeleteQuest;
    
