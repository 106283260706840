import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {getQuestionnaireFill} from '../../../actions/questionnaireActions'
import Form from './Form'
import Navbar from '../../layout/Navbar'
import Footer from '../../layout/Footer'
import { onSave, onSaveData } from "../../../actions/formActions";
import QuestNav from './QuestNav'
import { SubmissionError } from 'redux-form'
import validate from './validator';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getCurrentProfile, getLogActivityWithGeoIp } from "../../../actions/profileActions";
import DialogConfirmOverrideQuest from './Helpers/DialogConfirmOverrideQuest'
import showAToast from '../../../helpers/showAToast'

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'dark' ? 700 : 200],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);
const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

class FillSupplierIndentification extends Component {
  constructor() {
    super();
    this.state = {
        logged: false
    };
  }

     componentDidMount() {
        this.props.getQuestionnaireFill(this.props.match.params.id, null, this.props.history, 1)
        this.props.getCurrentProfile()
    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.profile && nextProps.profile.profile && nextProps.profile.profile.roles.includes('ROLE_QUESTIONNAIRE') && !this.state.logged){
            this.props.getLogActivityWithGeoIp('User Fill Questionnaire', 'GET', "QR SA tool")
            this.setState({
                logged: true
            })
        }
 }

     updateQuest = () => {
        this.props.getQuestionnaireFill(this.props.match.params.id, null, this.props.history, 1)
    }

      handleSave = (values, props) => {
        if(Object.keys(values).length > 0){
            this.sendValues(values, null)
        }
    }

    submit = (values, props) => {
         //validate(values, this.props)
         var errors = validate(values, this.props)
         var url = '/questionnaire/fill/'+this.props.match.params.id +'/'+ this.props.questionnaire.questFill.section.id;
         if(Object.keys(errors).length > 0){
             throw new SubmissionError(errors)
         }else if(values){
            this.sendValues(values, url)
         }
    }

    sendValues = (values, url) => {
           if(this.props.questionnaire.questFill.status === "completed"){
               showAToast('This questionnaire is completed!', 'errorCompleted', 'error')
           }else{
        let data = {}
        var formDataField = {};
        const formData = Object.assign(formDataField, values);
        let formJson = {};
        let fields = this.props.formProperties['initializeFromState2'].form.fields;

        let isForm = false;
        if(typeof fields !== 'undefined'){
            Object.keys(fields).forEach(( key ) => {
                let field = fields[key]
                if(field.id in formData){
               if(field.type === 'select' || field.type === 'checkboxGroup'){
                       formJson[field.id] =  JSON.stringify(formData[ field.id ]);
                    }else if (field.type === 'tags') {
                        let tagsArray = []
                        if (Array.isArray(formData[field.id])) {
                            formData[field.id].map(obj => (
                            tagsArray.push(obj.value)
                            ))
                            formJson[field.id] = tagsArray;
                        } else {
                            formJson[field.id] = formData[field.id].value
                        }

                    }else{
                       formJson[field.id] =  formData[ field.id ];
                    }
                }
            });
                data = formJson

            if(url){
               this.props.onSave('/api/supplier-quest/save/'+this.props.match.params.id, data, isForm, url, this.props.history)
            }else{
                 this.props.onSaveData('/api/supplier-quest/save/'+this.props.match.params.id, data, isForm)
            }
         }
      }
    }


  render() {
   const { classes } = this.props;
   const {profile} = this.props.profile;
   const {questFill} = this.props.questionnaire;
   var progressValue = 0;
    if(questFill){
        var sectionsNumber = questFill.quest.sections.length;
        var submittedNumber = questFill.submitted.length;
        if(questFill.filledIdentification){
            sectionsNumber +=1;
            submittedNumber +=1;
        }
        if(submittedNumber){
           progressValue = submittedNumber / (sectionsNumber / 100);
           progressValue = progressValue.toFixed(2);
        }
    }
    return (
    <div>
      <div className="page-content  slideup page-animation">
      <Grid container className={classes.root} direction="row" justify="flex-start">
           <Grid md={12} item className="text-center" container  justify="center">
                <Grid item  md={1}></Grid>
                <Grid item  md={6}><BorderLinearProgress value={progressValue} variant="determinate" style={{marginTop: 150}}/><span>{progressValue}%</span></Grid>

           </Grid>
           {profile && questFill && questFill.lockedById !== profile.id &&
            <Grid md={12} item container  justify="flex-start">
                    <Grid item  md={2}></Grid>
                    <Grid item  md={8}>
                     <DialogConfirmOverrideQuest questionId={this.props.match.params.id} updateQuest={this.updateQuest}/>
                    </Grid>
            </Grid>}
          <Grid item  md={1}></Grid>
          {questFill && <Grid item  md={12} className="text-center" container  justify="center" style={{marginTop: 20, fontSize: 16}}>
              <b style={{marginRight: 10}}>Questionnaire respondents:</b>{questFill.users}</Grid>    }
                <Grid item  md={1}></Grid>
    <Grid item container md={10}  >
           {profile && questFill && (questFill.lockedBy === null || questFill.lockedById === profile.id) &&
                   <Form
                            onSubmit={this.submit}
                            title={'1. Supplier Identification'}
                            form={'initializeFromState2'}
                            formRoute={'/api/supplier-quest/data/'+ this.props.match.params.id}
                            saveRoute={''}
                            section={null}
                            alone={true}
                            nextUrl={questFill.nextSection ? '/questionnaire/fill/'+this.props.match.params.id+'/'+questFill.section.id : null}
                            backUrl={null}
                            isNew={true}
                            handleSave={this.handleSave}
                            submitButton={true}
                          />}
          </Grid>
          {questFill &&<Grid item  md={2} style={{margin: 60}}>
          <QuestNav
          questionnaire={this.props.questionnaire}
          id={this.props.match.params.id}
            /></Grid>}
      </Grid>
       <Footer/>
    </div>
      <Navbar/>

    </div>
    );
  }
}

FillSupplierIndentification.propTypes = {
  questionnaire: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onSaveData: PropTypes.func.isRequired,
  formProperties: PropTypes.object.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  getLogActivityWithGeoIp:  PropTypes.func.isRequired
};

const mapStateToProps = state => ({
   profile: state.profile,
   questionnaire: state.questionnaire,
   formProperties: state.formProperties,
   form: state.form
});

FillSupplierIndentification = withStyles(styles)(FillSupplierIndentification)

export default connect( mapStateToProps, { getQuestionnaireFill, onSave, onSaveData, getCurrentProfile, getLogActivityWithGeoIp })(withRouter(FillSupplierIndentification));
