import {
    FORM_MOUNT,
    FORM_LOADED,
    FORM_SAVE_VALIDATE,
    FORM_FIELD_SELECT2_OPTIONS,
    FORM_FIELD_SELECT2_ADD_OPTIONS,
    FORM_SAVE_DISABLE,
    FORM_SAVE_ENABLE,
    FORM_SHOW_LOADER
} from '../actions/types';
import {NOT_VALID, NOT_FOCUSED} from '../helpers/ValidatorTypes';

export default (state = {}, action) => {
    const formId = action.payload ? action.payload.formId : -1;
    let form = formId in state ? state[formId] : {};
    switch (action.type) {
        case FORM_MOUNT:
            return {
                ...state,
                [formId]: {
                    ...form,
                    formRoute: action.payload.formRoute,
                    saveRoute: action.payload.saveRoute
                }
            };
        case FORM_LOADED:
            return {
                ...state,
                [formId]: {
                    ...form,
                    schema: action.payload.schema,
                    form: action.payload.form,
                    isEdit: action.payload.isEdit,
                    showLoader: false
                }
            };
        case FORM_SAVE_VALIDATE:
            let enableSave = true;
            for (let key in action.payload.isValid) {
                let field = action.payload.isValid[key];
                if (field.type === NOT_VALID || (field.type === NOT_FOCUSED && 'errors' in field)) {
                    enableSave = false;
                }
            }
            form['enableSave'] = enableSave;
            return {
                ...state,
                [formId]: form
            };
        case FORM_FIELD_SELECT2_OPTIONS:
            
            let formProperties = form.form;
            if(typeof formProperties !='undefined'){
            for (let i = 0; i < formProperties.length; i++) {
                if (formProperties [i].id === action.payload.id) {
                    if (!("originalOptions" in form[i]))
                        formProperties[i].originalOptions = formProperties[i].options;

                    if (action.payload.options)
                        formProperties[i].options = action.payload.options;
                    else
                        formProperties[i].options = formProperties[i].originalOptions.filter(option => option.label.toLowerCase().indexOf(action.payload.value.toLowerCase()) > -1);
                    break;
                }
            }
            form.form = formProperties;
        }
            return {
                ...state,
                [formId]: form
            };

        case FORM_FIELD_SELECT2_ADD_OPTIONS:
            return {
                ...state,
                [formId]: action.payload.properties
            };

        case FORM_SAVE_DISABLE:
            form['enableSave'] = false;
            return {
                ...state,
                [formId]: form
            };
        case FORM_SAVE_ENABLE:
            form['enableSave'] = true;
            return {
                ...state,
                [formId]: form
            };

        case FORM_SHOW_LOADER:
            form['showLoader'] = action.payload.value;
            return {
                ...state,
                [formId]: form
            };

        default:
            return state;
    }
};