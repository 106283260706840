import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import { clearCurrentProfile } from '../../actions/profileActions';
import LoginForm from './LoginForm';
import MenuNavbar from './MenuNavbar';
import MenuNavbarMobile from './MenuNavbarMobile';
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { config } from '../../config';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({
  paper: {
    top: 0,
  },
  rootList: {
    display: "flex",
    margin: "0 auto",
    width: "33%",
  },
  rootItem: {
    fontSize: 14,
    color: "#0a0a0a",
    marginLeft: 20,
    background: 'white',
    textDecoration: 'none',
    fontFamily: "OpenSans, sans-serif",
    '&:hover, &:focus': {
      backgroundColor: "transparent",
      outline: "none",
    }
  },
  rootItemSmall: {
    fontSize: 14,
    color: "#0a0a0a",
    height: 20,
    marginLeft: 20,
    background: 'white',
    textDecoration: 'none',
    fontFamily: "OpenSans, sans-serif",
    margin: "0 auto",
    '&:hover, &:focus': {
      color: "#000",
      backgroundColor: "transparent",
      outline: "none",
    }
  },
  bottomArea: {
    position: 'fixed',
    padding: 0,
    bottom: 0,
    width: '100%',
    height: '12%',
    background: '#33ad50',
    background: '-webkit-linear-gradient(right,#33ad50,#069c95)',
    background: '-o-linear-gradient(left,#33ad50,#069c95)',
    background: '-moz-linear-gradient(left,#33ad50,#069c95)',
    background: 'linear-gradient(to left,#33ad50,#069c95)',
    marginTop: '30px'
  },
  link: {
    color: 'white',
    padding: '35px',
    fontSize: '22px',
    textTransform: 'capitalize',
    '&:hover': {
      color: 'white',
      textDecoration: 'none',
    }
  },
  rootSmallItem: {
    color: '#999',
    fontSize: '14px',
    textDecoration: 'none',
    fontFamily: "OpenSans, sans-serif",
    marginLeft: 20,
    '&:hover': {
      background: 'white',
      textDecoration: 'none',
    }
  }
});


const paperprops = {
  style: {
    background: 'white',
    position: 'absolute',
    overflow: 'hidden',
    width: "100%",
    maxWidth: "100%",
    height: 50
  }
}


class Navbar extends React.Component {

  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);


    this.state = {
      show: false,
      showMenu: false
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });

    if (window.innerWidth > 1067) {
      this.setState({ showMenu: true });
    } else {
      this.setState({ showMenu: false });
    }
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
    this.setState({ show: false });
  };

  handleMenuToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleShow(e) {
    e.preventDefault()
    this.setState({ show: true });
  }

  onLogoutClick(e) {
    e.preventDefault();
    this.props.clearCurrentProfile();
    this.props.logoutUser();
  }

  toggleMenu() {
    const currentState = this.state.showMenu;
    this.setState({ showMenu: !currentState });
  }

  render() {
    const { isAuthenticated, user } = this.props.auth;
    const { classes } = this.props;
    const { anchorEl } = this.state;

    const authLinks = (
      <span>
        <a className="login-link text-uppercase" title="Reading list" href="/reading-list">
          <img src="/images/25x25/1766d3.png?v1.0" className="login-linkNew-icon" />
                My list
      </a>
        {isAuthenticated &&
          <span>
            {(user.roles.includes('ROLE_ADMIN') || user.roles.includes('ROLE_SUPER_ADMIN')) ?
              <span>
                <a className="login-link text-uppercase" target="_parent" href="/internal">Admin area</a>
                <a className="login-link text-uppercase" href="/contribute/welcome" >Members area</a>
              </span>
              : user.roles.includes('ROLE_MEMBER') ?
                <a className="login-link text-uppercase" href="/contribute/welcome" >Members area</a>
                : user.roles.includes('ROLE_QUESTIONNAIRE') ?
                  <a className="login-link text-uppercase" href="/contribute/welcome" >Questionnaire area</a>
                  : user.roles.includes('ROLE_CONTRIBUTOR') ?
                    <a className="login-link text-uppercase" href="/contribute/welcome">Welcome</a>
                    : ''
            }
          </span>
        }
      </span>

    );

    const logout = <a href="#" onClick={this.onLogoutClick.bind(this)}>Logout</a>;

    let showMenu;

    if (this.state.showMenu == false) {
      showMenu = 'hidden';
    } else {
      showMenu = 'visible';
    }

    let menuMobile;

    if (window.innerWidth < 1100) {

      menuMobile = <MenuNavbarMobile />

    } else {
      menuMobile = (
        <span className="main-menu-social-bar pull-right" style={{ marginTop: 10 }}>
          <Link to="/contacts" className="text-uppercase login-link last">Contact Us</Link>
          <Link to="/sustainability-assessments" className="text-uppercase login-link" >Sustainability assessments</Link>
          <Link to="/what-you-can-do" className="text-uppercase login-link">Global contributions map</Link>
          <Link to="/knowledge-practice" className="text-uppercase login-link">Knowledge and Practice</Link>
          <Link to="/who-we-are" className="text-uppercase login-link">Who we are</Link>
          <span onClick={this.handleClick} className="text-uppercase login-link">ONGOING PROJECTS</span>

          <MenuNavbar/>
          <Menu
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleClose}
            style={{ top: 53, left: 0, right: 0 }}
            height="30%"
            autoFocus={false}
            PaperProps={paperprops}
            MenuListProps={{ className: classes.rootList }}

          >
            <span className="third-menu">
              <React.Fragment>
                <MenuItem title="Sustainable Health in Procurement Project" >
                  <a className="text-light-gray" href="/shipp/shipp.html" className="text-uppercase" style={{color: "inherit"}}>
                      Sustainable Health in Procurement Project – SHiPP
                  </a>
                </MenuItem>
                <MenuItem title="Sustainable Health in Procurement Project" >
                  <a className="text-light-gray" href="https://shipp-stories.savinglivesustainably.org/index.html" className="text-uppercase" style={{color: "inherit", paddingLeft:50}}>
                      SHiPP Impact Stories that Inspire
                  </a>
                </MenuItem>
              </React.Fragment>
            </span>
          </Menu>
        </span>
      )
    }

    return (
      <Grid container className="new-menu" style={{ position: 'absolute', top: 0, padding: "5px 15px" }}>
        <Grid item xs={8} sm={4} md={2} className="logo">
          <a href="/" data-effect="down" id="btn_homepage">
            <img src={config.API_URL + "/SPHSlogo.PNG"} alt="logo" />
          </a>
        </Grid>

        <Grid item xs={4} sm={8} md={10} style={{ margin: "auto" }}>

          <span display={{ xs: 'block', md: 'none' }} onClick={this.toggleMenu}><i className="ion-log-in"></i></span>

          <div className="main-menu-social-bar" style={{ visibility: showMenu, display: "grid" }}>
            {isAuthenticated && <span> {authLinks} </span>}
            {!isAuthenticated && <LoginForm role="menu"/>}
          </div>

          {menuMobile}

        </Grid>
      </Grid>
    );
  }
}

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth
});

Navbar = withStyles(styles)(Navbar)
export default connect(mapStateToProps, { logoutUser, clearCurrentProfile })(Navbar);
