import React, { Component } from 'react';

export default class CustomClassRenderer extends Component {
        
    constructor(props) {
         super(props);
        
          this.state = {
            value: this.props.value,
        };
    }

     render() {
        return (
            <span className={this.state.value.replace(/ /g,'')}>{this.state.value}</span>
        );
    }
}        