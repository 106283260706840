import {
    SETTINGS_LOAD,
    SETTINGS_SIDEBAR_CHANGE
} from "../actions/types";


export default (state = {}, action) => {
    
    switch (action.type) {
        case SETTINGS_LOAD:
            return {
                ...state,
                ...action.payload
            };

        case SETTINGS_SIDEBAR_CHANGE:
            return {
                ...state,
                sidebar: action.payload.sidebar
            };
        
        default:
            return state;
    }
};