import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ReactSelect from "react-select";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import NoSsr from "@material-ui/core/NoSsr";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import SimpleValue from "react-select-simple-value";
import FormHelperText from "@material-ui/core/FormHelperText";
import {renderFromHelper} from './MaterialUiForm'

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: "20px"
  },
  input: {
    display: "flex",
    padding: 0,
    height: "auto"
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden"
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 14,
    paddingLeft: 10,
    fontFamily: 'OpenSans ,sans-serif',
    color: "#808080",
  },
  paper: {
    position: "absolute",
    zIndex: 100000,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing(2)
  }
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          style: {
            background: "white",
            borderRadius: "4px",
            border: "1px solid #c7c7c7",
            marginTop: 15,
            paddingLeft: 10,
          },
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        fontSize: 14
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      style={{ background: "white" }}
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div
      className={props.selectProps.classes.valueContainer}
      style={{ background: "white" }}
    >
      {props.children}
    </div>
  );
}

function MultiValue(props) {
    

  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
      color="primary"
      size="medium"
      style={{ fontSize: 14 }}
    />
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

export const MySelect = ({ disabled, options, value, ismulti, ...props }) => {

  return (
    <SimpleValue options={options} value={value}>
      {simpleProps => (
        <ReactSelect {...props} {...simpleProps} isMulti={ismulti} value={value} isDisabled={disabled}/>
      )}
    </SimpleValue>
  );
};

class IntegrationReactSelect extends React.Component {
  state = {
    single: null,
    multi: null
  };

  render() {
    const {
      classes,
      theme,
      input: { value, onBlur, onChange },
      label,
      placeholder,
      ismulti,
      field,
      meta: { error, invalid, touched }
    } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        width: '100%',
        "& input": {
          fontSize: "14px!important"
        }
      })
    };

    return (
      <div className={classes.root}>
        <NoSsr>
          <MySelect
            classes={classes}
            styles={selectStyles}
            textFieldProps={{
              label: label,
              InputLabelProps: {
                shrink: true,
                style: {
                    fontSize: 18,
                    textTransform: 'uppercase',
                    fontFamily: 'OpenSans ,sans-serif',
                    color: "#333333"
                }
              }
            }}
            options={this.props.options}
            components={components}
            placeholder={placeholder}
            
            onChange={value => onChange(value)}
            ismulti={ismulti}
            value={value}
            disabled={typeof field !== 'undefined' ? field.disabled : false}
            onBlur= { event => event.preventDefault()}
          />
          {error && error !== "undefined" && touched && (
           <span>{renderFromHelper({ touched, error })}</span>
          )}
        </NoSsr>
      </div>
    );
  }
}

IntegrationReactSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(IntegrationReactSelect);
