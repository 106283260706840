import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getContribution } from '../../../actions/contributionActions';
import Spinner from '../../common/Spinner';
import EventItem from './EventItem';
import Grid from '@material-ui/core/Grid';
import Navbar from '../../layout/Navbar';
import Footer from '../../layout/Footer';
import { Link } from 'react-router-dom';
import ReactDom from 'react-dom';
import ContributionEvent from './contribution/ContributionEvent'
import ContributionProject from './contribution/ContributionProject'
import ContributionStory from './contribution/ContributionStory'
import ContributionPublication from './contribution/ContributionPublication'
import ContributionTraining from './contribution/ContributionTraining'
import ContributionTool from './contribution/ContributionTool'
import FromTheSameAuthor from './contribution/FromTheSameAuthor'
import { getLogActivityWithGeoIp } from '../../../actions/profileActions';

class SingleContribution extends Component {

  constructor(props) {
    super(props);
    this.state = {
        height: null
    }
  }

  componentDidMount() {
    this.props.getContribution(this.props.match.params.id);
    this.setState({height: window.innerHeight*0.55});
    this.props.getLogActivityWithGeoIp('Single Contribution page', 'GET', "Website usage")
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
        ReactDom.findDOMNode(this).scrollIntoView();
    }
  }

  render() {
    const { contribution, contributions } = this.props.contribution;
    let height = this.state.height*0.55;

    return(
      <div>
            <Navbar/>
    <div className="individual-contribution-page page-content page-animation slideup">

 <div>
             {contribution &&
            <div>

        <div className="blue-area">
        </div>
        <div className="max-width individual-contribution-page-content">

            <div className="col-md-8 text-left">
               {contribution.humanFlag &&
                  <span>
                    <div className="clearfix" style= {{ marginBottom: '5px' }}></div>
                    {contribution.isAuthor && contribution.isPendingAuthorReview &&
                            <span>
                        <button className="btn btn-sm btn-success fa fa-check" style="margin-right: 5px;">Accept changes</button>
                        <button className="btn btn-sm btn-danger fa fa-times" style="margin-right: 5px;"> Reject</button>
                        <button className="btn btn-sm btn-info fas fa-pencil">Edit with changes</button>
                        </span>
                        }
               </span>
               }
                <div className="location">
                    <br/>
                    You are here:
                    {contribution.regionsText &&
                    <span style={{marginLeft: 10}}>
                                {contribution.regionsText}
                    </span>
                    }
                </div>
                <h1 className="text-white text-bold">{contribution.projectTitle}</h1>


                <span className="text-dark-blue-old ng-binding" >Posted on { contribution.createdAt}</span>
                <img alt="contribution" src={contribution.photo} />
                <div className="col-md-12 list-style noPadding description" >
                    <p className="description text-left" dangerouslySetInnerHTML={{__html: contribution.description}}></p>
                </div>
                {contribution.outcomes &&
                <div className="col-md-12 noPadding outcomes" ng-if="">
                    <h3 className="text-capitalize">outcomes</h3>
                    <p className="description text-left" dangerouslySetInnerHTML={{__html: contribution.outcomes}}></p>
                </div>
                }
                <div className="white-box col-md-12">
                {contribution.leadOrganization &&
                    <div className="col-md-12" >
                        <h5 className="text-uppercase text-gray">lead organization</h5>
                        <p >{ contribution.leadOrganization}</p>

                    </div>
                    }
                    {contribution.organizationType && contribution.organizationType.length > 0 &&
                    <div className="col-md-12" >
                        <h5 className="text-uppercase text-gray">organization type</h5>
                        {contribution.organizationType.map((type, i) => (
                                 <p key={i}>{type.name}</p>
                                 )
                )}

                    </div>
                    }
                    {contribution.partners &&
                    <div className="col-md-12" ng-if="">
                        <h5 className="text-uppercase text-gray">partners</h5>
                        <p >{ contribution.partners}</p>

                        <hr/>
                    </div>
                    }
                    {contribution.budget &&
                    <div className="col-md-12">
                        <h5 className="text-uppercase text-gray">budget</h5>
                        <p>{ contribution.budget} { contribution.currency &&
                                <span>{contribution.currency.sign}</span>}
                        </p>

                        <hr/>
                    </div>
                    }
                    {contribution.contactName &&
                            <div className="col-md-12" >
                        <h5 className="text-uppercase text-gray">contact name</h5>
                            <p> {contribution.contactName}</p>

                        <hr/>
                    </div>
                    }
                    {contribution.contact &&
                    <div className="col-md-12" ng-if="">
                        <h5 className="text-uppercase text-gray">contact email</h5>
                        <p className="text-black">{contribution.contact}</p>

                        <hr/>
                    </div>
                    }
                    {contribution.city &&
                    <div className="col-md-12">
                        <h5 className="text-uppercase text-gray">city</h5>
                        <p className="text-black">{contribution.city}</p>

                    </div>
                    }
                </div>
            </div>
            <div className="col-md-4 text-left">
              {contribution.contentType == 0 &&
                       <ContributionProject contribution={contribution} />}
              {contribution.contentType == 1 &&
                      <ContributionEvent contribution={contribution} />}
              {contribution.contentType == 2 &&
                       <ContributionStory contribution={contribution} />}
              {contribution.contentType == 3 &&
                       <ContributionPublication contribution={contribution} />}
              {contribution.contentType == 4 &&
                       <ContributionTraining contribution={contribution} />}
              {contribution.contentType == 5 &&
                       <ContributionTool contribution={contribution} />}

            </div>
            {contributions &&
            <FromTheSameAuthor contributions={contributions}/>
            }
        </div>
         </div>
        }
    </div>
    <Footer/>
    </div>
    </div>
    )

  }
}
  SingleContribution.propTypes = {
          contribution: PropTypes.object.isRequired,
          getLogActivityWithGeoIp: PropTypes.func.isRequired
  };

  const mapStateToProps = state => ({
          contribution: state.contribution.contribution
  });

export default connect(mapStateToProps, {getContribution, getLogActivityWithGeoIp})(SingleContribution);
