import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getContribution } from '../../../actions/contributionActions';
import Header from './Header'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import MainMenu from './sidebar/MainMenu'
import Message from '../../common/Message';

import '../../../css/backend.css'


class Layout extends Component {

  state = {
    openMobileMenu: false,
  }

  render() {
    return (
      <div className="layout">
        <Header/>
        <MainMenu/>
        <section id="main-content"  className={this.props.settings.sidebar === 'nav-collapse closed' ? 'merge-left' : ''}>
          <section className='wrapper'>
            <div>
              { this.props.children }
            </div>
          </section>
        </section>
      </div>
    );
  }
}

Layout.propTypes = {
    children: PropTypes.node,
    settings: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
     settings: state.settings
});


export default connect(mapStateToProps, {})(Layout);
