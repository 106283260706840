import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextTruncate from 'react-text-truncate';
import { config } from '../../../config';


class ContributionItem extends Component {



    render() {
        return (
  <Grid item lg={4} md={6} sm={6} xs={12} className="text-left">
                  {this.props.contributionItem &&
                <div className="project-content">
                <div className="contribution-preview">
                    <a  style={{ color: 'black'}}  href={this.props.contributionItem.href}>
                        <img  alt="contribution" src={this.props.contributionItem.photo}/>
                        <h4>
                          <TextTruncate
                            line={1}
                            truncateText="…"
                            text={this.props.contributionItem.projectTitle}
                            containerClassName="projectTitle text-center text-uppercase "/>
                        </h4>
                        <h5 className={this.props.contributionItem.contentTypeName}>
                          <TextTruncate
                            line={1}
                            truncateText="…"
                            text={this.props.contributionItem.contentTypeName}
                            containerClassName="contentType text-center text-uppercase Event"/>
                        </h5>
                    </a>
                    {this.props.contributionItem.featured &&
                    <span  className="highlighted text-center">
                        <i>highlighted</i>
                        <img className="pull-right" src={config.API_URL +"/images/org/248b59.png?v1.0"} alt="highlighted"/>
                    </span>
                    }
                </div>
            </div>

                    }

                   </Grid>
                );
    }
}

ContributionItem.propTypes = {
    contributionItem: PropTypes.object.isRequired,
};


export default connect()(ContributionItem);
