import {
    GET_CONTACT_COUNTRIES,
    GET_ACCOUNT,
    GET_DUPLICATED_ACCOUNTS,
    RESOLVE_DUPLICATED_ACCOUNTS,
    GET_DUPLICATED_FORM,
    GET_CRM_REPORT
} from '../actions/types';

const initialState = {
    contactCountries: null,
    account: null,
    duplicatedAccounts: null,
    duplicatedForm: null,
    crmReport: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CONTACT_COUNTRIES:
            return {
                ...state,
                contactCountries: action.payload
            };
        case GET_ACCOUNT:
            return {
                ...state,
                account: action.payload
            };
        case GET_DUPLICATED_ACCOUNTS:
            return {
                ...state,
                duplicatedAccounts: action.payload
            }; 
        case RESOLVE_DUPLICATED_ACCOUNTS:
            let duplicated = state.duplicatedAccounts;
            let removed = action.payload;
          delete duplicated[removed]; 
            return {
                ...state,
                duplicatedAccounts: duplicated
            }; 
        case GET_DUPLICATED_FORM:
             return {
                ...state,
                duplicatedForm: action.payload
            };
         case GET_CRM_REPORT:
             return {
                ...state,
                crmReport: action.payload
            };    
        default:
            return state;
    }
}
