import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import isEmpty from '../../validation/is-empty';
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import TextTruncate from 'react-text-truncate'
import { Resizable, ResizableBox } from 'react-resizable';


class NewsItem extends Component {
  render() {
    const { news } = this.props;

    return (
        <Grid item md={4} xs={12} style={{
          paddingLeft: 10,
          paddingRight: 10
        }}>
                    <Link className="post-list-item background-white" to={`/news/${news.id}`}>
                        <img src={news.photo} />
                        <h3> <TextTruncate
                            line={1}
                            truncateText="…"
                            text={ news.title}
                            containerClassName="text-left"
                            /></h3>
                        <h5 className="description text-left" dangerouslySetInnerHTML={{__html: news.previewText}}></h5>
                        <div>
                            <h6><TextTruncate
                            line={1}
                            truncateText="…"
                            text={news.humanCreatedAt}
                            containerClassName="text-left"/>
                            </h6>
                        </div>
                    </Link>
                </Grid>


    );
  }
}

NewsItem.propTypes = {
  news: PropTypes.object.isRequired
};

export default NewsItem;
