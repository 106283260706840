import {
    SOCKET_CONNECTION_INIT,
    SOCKET_CONNECTION_SUCESS,
    SOCKET_CONNECTION_ERROR,
    SOCKET_CONNECTION_CLOSED,
    SOCKET_MESSAGE
} from '../actions/types';
import moment from 'moment'
import {receivedNotification} from '../actions/notificationActions';
import { config } from '../config'

const initialState = {
  connected: false,
  readyState: null,
  socket: null 
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SOCKET_CONNECTION_INIT:
      return Object.assign({}, state, {
        connected: false,
        socket: action.socket,
      });

    case SOCKET_CONNECTION_SUCESS:
      return Object.assign({}, state, {
        connected: true,
      });

    case SOCKET_CONNECTION_ERROR:
      return Object.assign({}, state, {
        connected: false,
      });

    case SOCKET_CONNECTION_CLOSED:
      return Object.assign({}, state, {
        connected: false,
        socket: null,
      });

    case SOCKET_MESSAGE:
        console.log('case')
       
         
      // Do your logic here with action.data
      

    default:
      return state;
  }
}

export function initializeSocket() {
  return (dispatch) => {
    
    const socket = new WebSocket('wss://5.189.140.229:8988');
  //   const socket = new WebSocket('ws://localhost:8080');
    dispatch(socketConnectionInit(socket));

    socket.onopen = function() {
        console.log('onopen')
      dispatch(socketConnectionSuccess());  
    };
    
    socket.onerror = function() {
      dispatch(socketConnectionError());  
    };

    socket.onmessage = function (event) {       
        console.log('onmessage')
         
       let eventData = JSON.parse(event.data)
      
       if(eventData.serverType === 'ADD_NOTIFICATION' && eventData.apiId === config.API_ID){
           dispatch(receivedNotification(eventData))
       }
       if(eventData.serverType === 'SOCKET_MESSAGE' && eventData.apiId === config.API_ID){
      dispatch(socketMessage(event.data));
  }
    };

    socket.onclose = function() {
      dispatch(socketConnectionClosed());
    };
  };
}

function socketConnectionInit(socket) {
  return {
    type: SOCKET_CONNECTION_INIT,
    socket,
  };
}

function socketConnectionSuccess() {
  return {
    type: SOCKET_CONNECTION_SUCESS,
  };
}

function socketConnectionError() {
  return {
    type: SOCKET_CONNECTION_ERROR,
  };
}

function socketConnectionClosed() {
  return {
    type: SOCKET_CONNECTION_CLOSED,
  };
}

function socketMessage(data) {
    console.log('socketMessage')
  return {
    type: SOCKET_MESSAGE,
    data,
  };
}

