import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Navbar from '../layout/Navbar';
import Grid from '@material-ui/core/Grid';
import Footer from '../layout/Footer';
import Contributions from './elements/Contributions';
import { getMember } from '../../actions/membersActions';
import { getContributions } from '../../actions/contributionActions';
import MemberContributionItem from './elements/MemberContributionItem';
import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { getLogActivityWithGeoIp } from '../../actions/profileActions';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  icon: {
    fontSize: 56,
  },
});


class Member extends Component {

  componentDidMount() {
        this.props.getMember(this.props.match.params.id);
        this.props.getContributions(this.props.match.params.id);
        this.props.getLogActivityWithGeoIp('Single member page', 'GET', "Website usage")
    }


render() {
    const { classes } = this.props;
    const { member } = this.props;
    const { contributions } = this.props;

return (
        <div className={member}>
      <Grid container spacing={2}>
<div  className="page-content  slideup page-animation" >

    <div className="individual-member-page ">

        <div className="heroshot noPadding col-md-12">
            <img  src={member.member.poster} alt="{{member.shortDesc}}"></img>
            <Grid container
                  direction="row"
                  justify="flex-start"
                  className="text-area max-width">
              <Grid item xs={12} md={8} className="text-left">
              <Icon className={classNames(classes.icon, 'fa fa-quote-left', "text-light-gray")} />
                    <h1 className="text-light-gray" dangerouslySetInnerHTML={{ __html: member.member.description }}></h1>
                    <h4 className="text-white">{member.member.posterTextCol1}<br/>
                        <span className="text-light-gray">{member.member.posterTextCol2}</span>
                        </h4>
                </Grid>
            </Grid>
            <div className="bounce">
            <Icon
                    className={classNames(classes.icon, 'fa fa-angle-down text-white')}
                    />
            </div>
        </div>

        <Grid container
              direction="row"
              justify="flex-start"
              className="max-width about">
          <Grid item xs={10} md={10} className="center-col">
                <h1>{member.member.shortDesc}</h1>
                <a target="_blank" href={member.member.link}>{member.member.link}</a>
                <h4>{member.member.extraDesc}</h4>
            </Grid>
        </Grid>
        <div className="max-width member-contribution-list">
            <h1>Our contributions</h1>
            <Grid container spacing={3}  style={{
              paddingLeft: 15,
              paddingRight: 15
            }}>
                {
                    contributions.contributions.map((contributionItem, i) => {
                        return <MemberContributionItem key={i} contributionItem={contributionItem}  />
                    })
                }
            </Grid>
        </div>

    </div>
    <Footer/>
</div>

<Navbar/>

    </Grid>
</div>


);
}
}

Member.propTypes = {
   member: PropTypes.object.isRequired,
   contributions: PropTypes.object.isRequired,
   getMember: PropTypes.func.isRequired,
   getContributions: PropTypes.func.isRequired,
   getLogActivityWithGeoIp: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    member: state.member,
    contributions: state.contribution
});


Member = withStyles(styles)(Member)

export default connect(mapStateToProps, {getMember, getContributions, getLogActivityWithGeoIp})(withRouter(Member));
