import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Navbar from '../layout/Navbar';
import Footer from '../layout/Footer'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import ReactDom from 'react-dom';
import { getLogActivityWithGeoIp } from '../../actions/profileActions';

class TermsAndConditions extends Component {

  componentDidMount(prevProps) {
        ReactDom.findDOMNode(this).scrollIntoView();
        this.props.getLogActivityWithGeoIp('Terms And Conditions page', 'GET', "Website usage")
  }

  render() {

  return (
<div>
 <Navbar/>
    <div  className="page-content  slideup page-animation" >
      <Grid container className="terms-and-conditions">
     <Grid item md={12} sm={12} xs={12} className="header vcenter">
        <h1 className="text-center text-white">
            Website Terms and Conditions
        </h1>
     </Grid>
     <Grid item md={10} className="center-col max-width content text-left">
        <p>
		These Website Terms and Conditions and the related disclaimer governs your use of our website; by using <b><i>www.iiattsphs.org</i></b> and <b><i>www.savinglivesustainably.org</i></b>, you accept these terms in full. If you disagree with any part of this, do not use the website. The SPHS Task Team and Secretariat reserve the right to modify these terms at any time. You should therefore check periodically for changes. By using this site after any changes are posted, you agree to accept those changes, whether or not you have reviewed them.
    </p><div className="list-style"><p><span>⌧</span> By clicking <span>Yes / I Agree</span> to these Site Terms, user acknowledges and accepts all of the following terms:
    </p><ul><li>
			The informal Interagency Task Team on Sustainable Procurement in the Health Sector (SPHS) is established on an informal basis and voluntary participation of its member agencies.
		</li><li>
			This website is produced to inform discussions around strengthening sustainable procurement in public health sector.
		</li><li>
            SPHS Task Team and Secretariat are not responsible for the specific uploaded content/images and Public Contributions content does not in any way reflect the views of the SPHS Task Team, Secretariat or its affiliates.
        </li><li>
            By sharing any contribution (including any text, photographs, graphics) with the SPHS Task Team and Secretariat you agree to grant them, free of charge, permission to use the material in any way wanted, including modifying or deleting it. In order that your contribution can be used, you confirm that your contribution is your own original work, is not defamatory and does not infringe any laws and that you have the right to give the SPHS Task Team and Secretariat permission to use it for the purposes specified above.
        </li><li>
			SPHS Task Team and Secretariat are not responsible for the contents or reliability of any other websites to which they provide a link and do not necessarily endorse the views expressed within them.
        </li><li>
			Visitors who use this website and rely on any information do so at their own risk. Information on this site is for information purposes only and is not intended to constitute professional advice on any subject; further, information will not always be up to date and cannot necessarily be relied upon.
        </li><li>
            You must not use the website in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity. The majority of content posted in this platform is created by members of the public; the views expressed are theirs and unless specifically stated are not those of the SPHS Task Team and Secretariat. We accept no responsibility for any loss or harm incurred.
        </li><li>
            The SPHS Task Team and Secretariat do not warrant that functions available on this website will be uninterrupted or error free, that defects will be corrected, or that the server that makes it available is free of viruses or bugs. You acknowledge that it is your responsibility to implement sufficient procedures and virus checks (including anti-virus and other security checks) to satisfy your particular requirements for the accuracy of data input and output.
        </li><li>
            SPHS Task Team and Secretariat, and their partners, contractors or content providers shall not be liable for any loss or damage arising from or otherwise in connection with your use of <a href="http://www.iiattsphs.org/" target="_blank"><i><u>www.iiattsphs.org</u></i></a> and <a href="http://www.iiattsphs.org/" target="_blank"><i><u>www.savinglivesustainably.org</u></i></a> or any information, services or content on these websites.
        </li><li>
            Unless stated otherwise, you may access and download the materials located on this website only for non-commercial use.
        </li></ul>
            <div className="clearfix"></div>
            <h3>Disclaimer</h3>
            <p>
            The informal Interagency Task Team on Sustainable Procurement in the Health Sector (SPHS) is established on an informal basis and voluntary participation of its member agencies. This website is produced to inform discussions around strengthening sustainable procurement in public health sector. The content, analysis, opinions and policy recommendations contained on the website (<a href="http://www.iiattsphs.org/" target="_blank"><i><u>www.iiattsphs.org</u></i></a> and <a href="http://www.iiattsphs.org/" target="_blank"><i><u>www.savinglivesustainably.org</u></i></a>) do not necessarily reflect the views of the United Nations Development Programme or other member organizations of the SPHS. Any omissions, inaccuracies and mistakes are responsibility solely of the authors. While we endeavor to keep the information up to date, SPHS website content authors, any of their affiliates, partners, employees or other representatives make no representations, or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with the respect to the website or the information or graphics contained on the website for any purpose. Any reliance you place on such information is therefore at your own risk. Under no circumstances will the United Nations Development Programme or other member organizations of the SPHS be liable for any consequence relating directly or indirectly to any action or inaction you take based on the information, services or other material on this website, nor will we be responsible for any damage or loss related to, the timeliness, accuracy, or completeness of the information, services, products, or other material on this website.</p>
            </div>
        </Grid>
        <Footer/>
      </Grid>
      </div>
  </div>
  );
}
}


TermsAndConditions.propTypes = {
    getLogActivityWithGeoIp: PropTypes.func.isRequired
  };

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {getLogActivityWithGeoIp})(TermsAndConditions);
