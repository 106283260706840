import {
        GET_PROFILE,
        GET_MEMBER_PROFILE,
        GET_PROFILES,
        PROFILE_LOADING,
        CLEAR_CURRENT_PROFILE,
        GET_USER_TRACK_REPORT
        } from '../actions/types';

const initialState = {
    profile: null,
    memberProfile: null,
    profiles: null,
    companyProfiles: null,
    companyPageCount: null,
    customersPageCount: null,
    loading: false,
    userTrackReport: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case PROFILE_LOADING:
            return {
                ...state,
                loading: true
            };
        case GET_PROFILE:
            return {
                ...state,
                profile: action.payload,
                loading: false
            };   

        case GET_MEMBER_PROFILE:
            return {
                ...state,
                memberProfile: action.payload,
                loading: false
            };   

        case GET_PROFILES:
            return {
                ...state,
                profiles: action.payload,
                loading: false
            };            
        
        case CLEAR_CURRENT_PROFILE:
            return {
                ...state,
                profile: null
            };
         case GET_USER_TRACK_REPORT:
            return {
                ...state,
                userTrackReport: action.payload
            };
        default:
            return state;
}
}
