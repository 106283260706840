import isEmpty from '../validation/is-empty';

import { SET_CURRENT_USER, GET_REGISTER_DATA, GET_ERRORS_REGISTRATION, REQUESTED_ROUTE } from '../actions/types';

const initialState = {
  isAuthenticated: false,
  user: {},
  registerData: [],
  registerErrors: [],
  requestedRoute: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload
      };
  case GET_REGISTER_DATA:
        return {
        ...state,
        registerData: action.payload
      };
  case GET_ERRORS_REGISTRATION:
        return {
        ...state,
        registerErrors: action.payload
      };
  case REQUESTED_ROUTE:  
      if(action.payload !== state.requestedRoute){
        return {
        ...state,
        requestedRoute: action.payload
    };
      }
    default:
      return state;
  }
}
