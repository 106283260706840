import {
    CHECK_USER,
    GET_ORGANIZATIONS,
    GET_QUESTIONNAIRE,
    GET_SECTION,
    ADD_FIELD,
    CHANGE_FIELD,
    GET_QUEST_FILL,
    GET_USER_QUESTIONNAIRES,
    GET_ORG_QUESTIONNAIRES,
    GET_PRODUCT,
    GET_CONTACT_QUESTIONNAIRES,
    GET_REPORT_DАТА,
    GET_REPORT_DESCRIPTION,
    GET_CONVENTION_REPORT
} from '../actions/types';

const initialState = {
    checkUser: null,
    organizations: null,
    quest: {},
    section: {},
    questFill: null,
    usersQuests: null,
    orgQuests: null,
    contactQuest: null,
    product: null,
    reportData: null,
    reportDescription: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CHECK_USER:
            return {
                ...state,
                checkUser: action.payload
            };
        case GET_ORGANIZATIONS:
        return {
                ...state,
                organizations: action.payload
            };
        case GET_QUESTIONNAIRE:
        return {
                ...state,
                quest: action.payload
            };  
        case GET_SECTION:
        return {
                ...state,
                section: action.payload
            }; 
        case GET_QUEST_FILL:
        return {
                ...state,
                questFill: action.payload
            };    
        case ADD_FIELD:
        var field = action.payload;
        var section = state.section;
        var fields = [...section.fields, field];
        section.fields = fields;
        return {
                ...state,
                section: section
            }; 
        case GET_USER_QUESTIONNAIRES:
         return {
                ...state,
                usersQuests: action.payload
            };  
        case GET_ORG_QUESTIONNAIRES:
            return {
                ...state,
                orgQuests: action.payload
            };
        case GET_PRODUCT:
            return {
                ...state,
                product: action.payload
            };
        case GET_CONTACT_QUESTIONNAIRES: 
        return {
                ...state,
                contactQuests: action.payload
            };
        case GET_REPORT_DАТА:
        return {
                ...state,
                reportData: action.payload
            };
        case GET_REPORT_DESCRIPTION:
             return {
                ...state,
                reportDescription: action.payload
            };   
        case GET_CONVENTION_REPORT:
            return {
                ...state,
                reportConvention: action.payload
            }; 
        default:
            return state;
}
}
