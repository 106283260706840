import React, { Component } from 'react';
import { Route, Switch, Redirect, NotFoundRoute } from 'react-router-dom';

import PrivateRoute from './components/common/PrivateRoute';

import Landing from './components/homepage/Landing';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import Dashboard from './components/dashboard/Dashboard';
import NotFound from './components/not-found/NotFound';
import WhoWeAre from './components/frontend/WhoWeAre';
import WhatYouCanDo from './components/frontend/WhatYouCanDo';
import KnowledgePractice from './components/frontend/KnowledgePractice';
import Calendar from './components/frontend/Calendar';
import AsiaForum from './components/frontend/AsiaForum';
import AfricaForum from './components/frontend/AfricaForum';
import VirtualForum from './components/frontend/VirtualForum';
import Forum2021 from './components/frontend/Forum2021';
import Forum2022 from './components/frontend/Forum2022';
import News from './components/frontend/News';
import Welcome from './components/dashboard/Welcome'
import MembersList from './components/dashboard/MembersList'
import MemberProfile from './components/dashboard/MemberProfile'
import MembersForum from './components/forums/MembersForum'
import MembersForumPost from './components/forums/MembersForumPost'
import SingleNews from './components/frontend/SingleNews'
import Member from './components/frontend/Member'
import KnowledgePracticeSector from './components/frontend/KnowledgePracticeSector'
import SingleContribution from './components/frontend/elements/SingleContribution'
import TermsAndConditions from './components/frontend/TermsAndConditions'
import Contacts from './components/frontend/Contacts'
import ChangePassword from './components/auth/ChangePassword'
import ContributeProfile from './components/dashboard/ContributeProfile'
import ReadingList from './components/frontend/ReadingList'
import ResetPassword from './components/auth/ResetPassword'
import NewThread from './components/forums/NewThread'
import ResetNewPassword from './components/auth/ResetNewPassword'
import PrivacyPolicy from './components/frontend/PrivacyPolicy'
import ContributorsForum from './components/forums/ContributorsForum'
import ContributorsForumPost from './components/forums/ContributorsForumPost'
import NewContributionThread from './components/forums/NewContributionThread'
import getRoutes from './gridRoutes'
import Grid from './components/admin/grid/Grid'
import EditContribution from './components/admin/contribution/EditContribution'
import Internal from './components/admin/Internal'
import getFormRoutes from './formRoutes';
import Main from './components/admin/form/Main'
import ContributionType from './components/admin/contribution/ContributionType'
import MyContributions from './components/frontend/MyContributions'
import MyContribution from './components/frontend/MyContribution'
import EditMyContribution from './components/frontend/EditMyContribution'
import ContributionMain from './components/admin/form/ContributionMain'
import AccountProfile from './components/admin/account/AccountProfile'
import SustainabilityAssessments from './components/frontend/SustainabilityAssessments'
import CollaborationCalendar from './components/admin/collaborations/CollaborationCalendar'
import HelpPage from './components/admin/HelpPage.js'
import DuplicatedAccounts from './components/admin/account/DuplicatedAccounts'
import ViewAccessData from './components/admin/account/ViewAccessData'
import LinkedinConnect from './components/frontend/LinkedinConnect'
import FileProfile from './components/admin/documents/FileProfile'
import Documents from './components/members/Documents'
import MemberFileProfile  from './components/members/MemberFileProfile'
import RegisterQuestionnaireRespondent from './components/questionnaire/RegisterQuestionnaireRespondent'
import QuestionnaireBuilder from './components/admin/questionnaire/QuestionnaireBuilder'
import FillQuest from './components/admin/questionnaire/FillQuest'
import UserQuestionnaires from './components/questionnaire/UserQuestionnaires'
import QuestResult from './components/admin/questionnaire/QuestResult'
import FillSupplierIndentification from './components/admin/questionnaire/FillSupplierIndentification'
import ProductProfile from './components/admin/Product/ProductProfile'
import QuestionnaireLock from './components/admin/questionnaire/QuestionnaireLock'
import SHiPPSummary from './components/frontend/SHiPPSummary.js'
import Print from './components/admin/grid/Print'
import EAReport from './components/admin/reports/EAReport'
import UserReport from './components/admin/reports/UserReport'
import ResolveDuplicated from './components/admin/account/ResolveDuplicated'
import MembersQuestionnaire from './components/members/MembersQuestionnaire'
import MembersResultQuestionnaire from './components/members/MembersResultQuestionnaire'
import UserTrackReport from './components/admin/reports/UserTrackReport'
import PDFChartReport from './components/admin/reports/PDFChartReport'
import MCReportPdf from './components/admin/reports/MCReportPdf'
import CrmReports from './components/admin/account/CrmReports'
import AccountForm from './components/admin/account/AccountForm'
import GPUConventionsReport from './components/admin/reports/GPUConventionsReport'
import QuestionnaireForm from './components/admin/questionnaire/QuestionnaireForm'

const gridRoutes = getRoutes();
const formRoutes = getFormRoutes();

export default [
        <Switch key="welcome-contribute">
    <Route exact path="/" component={Landing} key="landing"/>,
    <Route exact path="/login" component={Login} key="login"/>,
    <Route exact path="/contribute/register" component={Register} key="register"/>,
    <Route exact path="/who-we-are" component={WhoWeAre} key="WhoWeAre"/>,
    <Route exact path="/knowledge-practice" component={KnowledgePractice} key="KnowledgePractice"/>,
    <Route exact path="/knowledge-practice/search" component={KnowledgePractice} key="KnowledgePracticeSearch"/>,
    <Route exact path="/what-you-can-do" component={WhatYouCanDo} key="WhatYouCanDo"/>,
    <Route exact path="/calendar" component={Calendar} key="Calendar"/>,
    <Route exact path="/asia-forum" component={AsiaForum} key="AsiaForum"/>,
    <Route exact path="/africa-forum" component={AfricaForum} key="AfricaForum"/>,
    <Route exact path="/global-virtual-forum" component={VirtualForum} key="VirtualForum"/>,
    <Route exact path="/global-virtual-forum-2021" component={Forum2021} key="Forum2021"/>,
    <Route exact path="/global-virtual-forum-2022" component={Forum2022} key="Forum2022"/>,
    <Route exact path="/news" component={News} key="News"/>,
    <Route exact path="/news/:id" component={SingleNews} key="SingleNews"/>,
    <Route exact path="/members/:name/:id" component={Member} key="Member"/>,
    <Route exact path="/knowledge-practice/:sector/:id" component={KnowledgePracticeSector} key="KnowledgePracticeSector"/>,
    <Route exact path="/knowledge-practice/contribution/:name/:id" component={SingleContribution} key="SingleContribution"/>,
    <Route exact path="/terms-and-conditions" component={TermsAndConditions} key="TermsAndConditions"/>,
    <Route exact path="/contacts" component={Contacts} key="Contacts"/>,
    <Route exact path="/password-reset" component={ResetPassword} key="ResetPassword"/>,
    <Route exact path="/reset/password" component={ResetNewPassword} key="ResetNewPassword"/>,
    <Route exact path="/privacy-policy" component={PrivacyPolicy} key="PrivacyPolicy"/>,
    <Route exact path="/sustainability-assessments" component={SustainabilityAssessments} key="SustainabilityAssessments"/>,
    <Route exact path="/view/access/data/:token" component={ViewAccessData} key="ViewAccessData" />,
    <Route exact path="/oauth/linkedin-connect" component={LinkedinConnect} key="LinkedinConnect"/>,
    <Route exact path="/register/questionnaire-respondent" component={RegisterQuestionnaireRespondent} key="RegisterQuestionnaireRespondent"/>,


      { gridRoutes.map((route, key) => {
                                                return <PrivateRoute
                                                exact
                                                    key={key}
                                                    path={route.path}
                                                    gridRoute={route.gridRoute}
                                                    gridDataRoute={route.gridDataRoute}
                                                    title={route.title}
                                                    component={Grid}

                                                />
                                            })}
       {
                                            formRoutes.map((route, key) => {

                                                return <PrivateRoute
                                                    key={key}
                                                    path={route.path}
                                                    component={Main}
                                                    form={'initializeFromState2'}
                                                    formRoute={route.formRoute}
                                                    saveRoute={route.saveRoute}
                                                    redirectUrl={route.redirectUrl}
                                                    title={route.title}
                                                    isNew={route.isNew}
                                                    isAdditionalId={route.isAdditionalId}

                                                />
                                                })
        }
      <PrivateRoute exact path="/admin/contributions/choose/type" component={ContributionType} key="chooseType"/>
      <PrivateRoute exact path="/contribute/welcome" component={Welcome} key="welcome1"/>
      <PrivateRoute exact path="/users/member" component={MembersList} key="membersList"/>
      <PrivateRoute exact path="/users/member/:id" component={MemberProfile} key="memberProfile"/>
      <PrivateRoute exact path="/forum" component={MembersForum} key="membersForum"/>
      <PrivateRoute exact path="/forum/thread/:id" component={MembersForumPost} key="membersForumPost"/>
      <PrivateRoute exact path="/forum/new-thread" component={NewThread} key="NewThread"/>,
      <PrivateRoute exact path="/contribute/profile/change-password" component={ChangePassword} key="changePassword"/>
      <PrivateRoute exact path="/contribute/profile" component={ContributeProfile} key="contributeProfile" />
      <PrivateRoute exact path="/reading-list" component={ReadingList} key="ReadingList" />
      <PrivateRoute exact path="/contributors/forum" component={ContributorsForum} key="contributorsForum"/>
      <PrivateRoute exact path="/contributors/forum/thread/:id" component={ContributorsForumPost} key="contributorsForumPost"/>
      <PrivateRoute exact path="/contributors/forum/new-thread" component={NewContributionThread} key="newContributionThread"/>
      <PrivateRoute exact path="/admin/contributions/:id/edit" component={EditContribution} key="editContribution"/>
      <PrivateRoute exact path="/internal" component={Internal} key="internal"/>
      <PrivateRoute exact path="/contribute/my-contributions" component={MyContributions} key="myContributions"/>
      <PrivateRoute exact path="/contribute/my-contributions/:id" component={MyContribution} key="myContribution"/>
      <PrivateRoute exact path="/contribute/my-contribution/edit/:id" component={EditMyContribution} key="editMyContribution"/>
      <PrivateRoute exact path="/crm/accounts/:id/profile" component={AccountProfile} key="AccountProfile"/>
      <PrivateRoute exact path="/admin/collaborations/calendar" component={CollaborationCalendar} key="CollaborationCalendar"/>
      <PrivateRoute exact path="/help" component={HelpPage} key="HelpPage" />
      <PrivateRoute exact path="/admin/accounts/duplicated/:type" component={DuplicatedAccounts} key="DuplicatedAccounts" />
      <PrivateRoute exact path="/admin/documents/file/profile/:id" component={FileProfile} key="FileProfile" />
      <PrivateRoute exact path="/members/documents" component={Documents} key="MembersDocuments" />
      <PrivateRoute exact path="/members/documents/file/profile/:id" component={MemberFileProfile} key="MemberFileProfile" />
       <PrivateRoute exact path="/admin/questionnaire/builder/:id" component={QuestionnaireBuilder} key="QuestionnaireBuilder" />
       <PrivateRoute exact path="/questionnaire/fill/:id/:sectionId" component={FillQuest} key="FillQuest" />
       <PrivateRoute exact path="/user/questionnaires" component={UserQuestionnaires} key="UserQuestionnaires" />
       <PrivateRoute exact path="/admin/questionnaire/result/:part/:id" component={QuestResult} key="QuestResult" />
       <PrivateRoute exact path="/user/questionnaire/indentification/:id" component={FillSupplierIndentification} key="FillSupplierIndentification"/>
       <PrivateRoute exact path="/admin/product/profile/:id" component={ProductProfile} key="ProductProfile" />
       <PrivateRoute exact path="/admin/questionnaire/lock/:id" component={QuestionnaireLock} key="QuestionnaireLock" />
       <PrivateRoute exact path="/admin/financial-report/:Supplier/report/export/html" component={Print} key="Print" />
        <PrivateRoute exact path="/admin/questionnaire/ea-report/:id" component={EAReport} key="EAReport" />
        <PrivateRoute exact path="/user/questionnaire/report/:id" component={UserReport} key="UserReport" />
       <PrivateRoute exact path="/admin/account/resolve/:type" component={ResolveDuplicated} key="ResolveDuplicated" />
       <PrivateRoute exact path="/members/questionnaires" component={MembersQuestionnaire} key="MembersQuestionnaire" />
       <PrivateRoute exact path="/questionnaire/member/result/:part/:id" component={MembersResultQuestionnaire} key="MembersResultQuestionnaire" />
       <PrivateRoute exact path="/admin/user-track/report" component={UserTrackReport} key="UserTrackReport" />
       <PrivateRoute exact path="/admin/pdf/chart/:type" component={PDFChartReport} key="PDFChartReport" />
        <PrivateRoute exact path="/admin/report/export/pdf" component={MCReportPdf} key="MCReportPdf" />
       <PrivateRoute exact path="/admin/crm/reports" component={CrmReports} key="CrmReports" />
       <PrivateRoute exact path="/admin/gpu/conventions/reports" component={GPUConventionsReport} key="GPUConventionsReport" />
                                                <PrivateRoute exact
                                                    key={'contrForm'}
                                                    path='/admin/contributions/create/:type'
                                                    component={ContributionMain}
                                                              form={'initializeFromState2'}
                                                              formRoute='/api/admin/contributions-form/data/'
                                                              saveRoute='/api/admin/contributions-form/create/'
                                                              redirectUrl= '/admin/contributions'
                                                              title={ ['Contribution', '›', 'New']}
                                                              isNew={true}
                                                />
                                                <PrivateRoute exact
                                                    key={'contrFormEdit'}
                                                    component={ContributionMain}
                                                    path='/admin/contributions/edit/:type/:id'
                                                              form={'initializeFromState2'}
                                                              formRoute='/api/admin/contributions-form/data/'
                                                              saveRoute='/api/admin/contributions-form/edit/'
                                                              redirectUrl= '/admin/contributions'
                                                              title={ ['Contribution', '›', 'Edit']}
                                                              isNew={true}
                                                />
                                                <PrivateRoute exact
                                                    key={'accountFormCreate'}
                                                    component={AccountForm}
                                                    path='/admin/accounts/create/:type'
                                                              form={'initializeFromState2'}
                                                              formRoute='/api/admin/accounts-form/data/'
                                                              saveRoute='/api/admin/accounts-form/create/'
                                                              redirectUrl='/admin/accounts/list/:type'
                                                              title={['Account', '›', 'New']}
                                                              isNew={true}
                                                />
                                                 <PrivateRoute exact
                                                    key={'accountFormEdit'}
                                                    component={AccountForm}
                                                    path='/admin/accounts/edit/:type/:id'
                                                              form={'initializeFromState2'}
                                                              formRoute='/api/admin/accounts-form/data/'
                                                              saveRoute='/api/admin/accounts-form/create/'
                                                              redirectUrl='/admin/accounts/list/:type'
                                                              title={ ['Account', '›', 'Edit']}
                                                              isNew={true}

                                                />

                                                <PrivateRoute exact
                                                path="/admin/questionnaire/create"
                                                component={QuestionnaireForm}
                                                key="questFormCreate"
                                                form={'initializeFromState2'}
                                                formRoute='/api/admin/quest-form/data/'
                                                saveRoute='/api/admin/quest-form/create/'
                                                redirectUrl='/admin/questionnaire/active'
                                                title={['Questionnaire', '›', 'New']}
                                                isNew={true}/>
                                                 <PrivateRoute exact
                                                    key={'questFormEdit'}
                                                    path='/admin/questionnaire/edit/:id'
                                                    component={QuestionnaireForm}
                                                form={'initializeFromState2'}
                                                formRoute='/api/admin/quest-form/data/'
                                                saveRoute='/api/admin/quest-form/create/'
                                                redirectUrl='/admin/questionnaire/active'
                                                title={ ['Questionnaire', '›', 'Edit']}
                                                isNew={true}

                                                />
                            <Route exact component={NotFound} key="notFound"/>,
    </Switch>



];
