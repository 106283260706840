import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getThread, downloadAttachment, deleteReply, postThreadReply, createThread, editThread, postEditReply,getReply, setReply } from '../../actions/forumActions';
import { getCurrentProfile } from '../../actions/profileActions';
import Spinner from '../common/Spinner';
import ProfileActions from '../dashboard/ProfileActions';
import Navbar from '../layout/Navbar';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions'
import ThreadReplyForm from "../forms/ThreadReplyForm"
import ThreadForm from "../forms/ThreadForm"
import { withRouter } from 'react-router-dom'



class MembersForumPost extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openReply: false,
            message: null,
            id: null,
            isBeingEdited: null
        };
        this.downloadAttachment = this.downloadAttachment.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        this.props.getCurrentProfile();
        this.props.getThread(this.props.match.params.id, 'contributor', this.props.history);
    }

    downloadAttachment(messageId, file){
        this.props.downloadAttachment(this.props.match.params.id, messageId, file, 'contributor');
    }

    handleDelete(value){
        this.setState({
            open: true ,
            id: value.id
        });
        if(value.isThread === true){
            this.setState({
                message: "Are you sure you wish to delete the whole thread?"
            })
        }
        if(value.isThread === false){
             this.setState({
                message: "Are you sure you wish to delete your reply?"
            })
        }
    }

    delete(){
        this.props.deleteReply(this.props.match.params.id, this.state.id, 'contributor', this.props.history);
        this.setState({
            open: false,
            id: null
        });
        this.props.getThread(this.props.match.params.id, 'contributor', this.props.history);
    }

    handleClose(messageId){
        this.setState({
            open: false
        });
    };

    handleOpenReply = () => {
     this.props.setReply()
        this.setState({
            openReply: true,
            isBeingEdited: null
        });
    }

    handleCloseReply = () => {
        this.setState({
            openReply: false
        });
        this.props.setReply();
    }

    handleSubmitReply = (values) => {
        this.setState({
            openReply: false
        });

     var body = new FormData();
    Object.keys(values).forEach(( key ) => {

        if(key === 'attachments'){
            values.attachments.map(file =>
                    body.append('attachments[]', file.file)
                        )

        }else{
      body.append(key, values[ key ]);
  }
    });
var role=null;
    if(this.props.profile.profile.roles.includes('ROLE_CONTRIBUTOR')){
        role = 'contributor'
    }
        this.props.postThreadReply(body, this.props.match.params.id, 'contributor', role)
        this.props.setReply()
    }

    handleEdit = (id, isThread) => {
        if(!isThread){
          this.props.getReply(id, 'contributor')
        }
         this.setState({
            isBeingEdited: id,
             openReply: false
        });

    }

    handleCancelEdit = (e) => {

    e.preventDefault()
         this.setState({
            isBeingEdited: null,
             openReply: false
        });
         this.props.getThread(this.props.match.params.id, 'contributor', this.props.history);
         this.props.setReply();
    }

      handleOnSubmit = values => {

     var body = new FormData();
    Object.keys(values).forEach(( key ) => {
        if(key === 'attachments'){
            values.attachments.map(file => {
                if(file.file){
                body.append('attachments[]', file.file)
            }else if(file.id){
                body.append('attachments[]', file.id)
            }
                })

        }else if(key === 'members'){
           let orgArray = []
                   values[key].map(obj => (
              orgArray.push(obj.value)
  ))
        body.append(key, orgArray);
        }else if(key === 'contribution'){
             body.append(key, values[key]['value']);
            }else if (key === 'subject' || key === 'message' || key === 'protectAttachments'){
      body.append(key, values[ key ]);
  }

    });

    this.props.editThread(body, this.props.match.params.id, 'contributor', this.props.history)
     this.setState({
            isBeingEdited: null
        });
        setTimeout(() => {
                this.props.getThread(this.props.match.params.id, 'contributor', this.props.history);
              }, 500)

  }

  handleOnSubmitEditReply = values => {

      var body = new FormData();
    Object.keys(values).forEach(( key ) => {
        if(key === 'attachments'){
            values.attachments.map(file => {
                if(file.file){
                body.append('attachments[]', file.file)
            }else if(file.id){
                body.append('attachments[]', file.id)
            }
                })
        }else{
             body.append(key, values[ key ]);
        }

    });

    this.props.postEditReply(body, this.props.match.params.id, this.state.isBeingEdited, 'contributor')
    this.props.setReply()
     this.setState({
            isBeingEdited: null,
            openReply: false
        });
        setTimeout(() => {
                this.props.getThread(this.props.match.params.id, 'contributor', this.props.history);
              }, 500)
  }

    render() {
        const { profile } = this.props.profile;
        const { thread, replies } = this.props.threads.thread;
        let arr = [];
        if(thread){
            arr.push(thread);
        }
        if(replies){
        replies.map(reply => (
            arr.push(reply)
            ))
        }


        let dashboardContent;
        if (profile === null) {
            dashboardContent = <Spinner />;
        }else{
            dashboardContent = <ProfileActions profileItem={profile}/>
        }

        return (
          <div className="container-fluid" >
            <div className="page-content slide page-animation">
            <Grid container spacing={0} className="">
                {dashboardContent}

                {thread &&
                    <Grid item container lg={12} style={{padding: 15}}>
                     <h3>Thread: {thread.subject}</h3>
                    </Grid>
                    }

                {thread &&

                   <Grid item container lg={12} style={{padding: 15}}>
                      <ul className="breadcrumb">
  	                 <li>
                         <Link to="/contributors/forum">Back to Forum</Link>
                         </li>
                         <li>
                         <span>{thread.subject}</span>
                         </li>
                      </ul>
                     </Grid>
                 }


                    <Grid item container lg={12} md={12} className="forum-thread">

                    {arr && arr.map(value => (

                        <Grid item container lg={12} md={12}  key={value.id} direction="row"   className="forum-message">

                               {this.state.isBeingEdited == value.id ?

                                  <Grid item lg={10} md={10} style={{borderRight: "1px solid #F2F2F2", padding: 15}} className="">
                                  {value.isThread === true ?
                                      <ThreadForm  threadId={value.id} cancelEdit={this.handleCancelEdit} onSubmit={this.handleOnSubmit} type="contributor"/>
                                      :
                                          <span>
                                      {this.props.threads.reply &&
                                         <ThreadReplyForm type="contributor" threadId={value.id} cancelEdit={this.handleCancelEdit} onSubmit={this.handleOnSubmitEditReply} initialValues={this.props.threads.reply}/>
                                      }
                                          </span>
                                  }
                                  </Grid>
                               :

                                  <Grid item lg={10} md={10} style={{borderRight: "1px solid #F2F2F2", padding: 15}} className="">
                                    <div className="pull-left text-left">
                                        <h4 style={{color: "#2b3238", marginBottom: 40}}>{ value.subject }</h4>
                                        <p className="text-left" dangerouslySetInnerHTML={{__html: value.message}}></p>

                                        {value.attachments &&
                                        <span>
                                       {value.attachments.map((attachment, key) => (
                                        <p className="text-as-link text-left" onClick={() => this.downloadAttachment(value.id, attachment)} style={{cursor: "pointer"}} key={key}>
                                            <i style={{marginRight: 3}} className="fa fa-file"></i>{attachment.name}
                                        </p>
                                        ))}
                                        </span>
                                    }
                                    </div>
                                    <Dialog
                                      open={this.state.open}
                                      onClose={this.handleClose}
                                      aria-labelledby="form-dialog-title"
                                      maxWidth="md"
                                      fullWidth
                                    >
                                    <DialogContent className="modal-content">
                                    <DialogTitle className="modal-header" id="form-dialog-title">{"Delete thread"}</DialogTitle>
                                        <DialogContentText className="modal-body, modal-body" align="justify">
                                            {this.state.message}
                                         </DialogContentText>
                                          <DialogActions className="modal-footer">
                                            <button type="button" className="btn btn-warning pull-left" onClick={() => this.delete(this.props.match.params.id)}><i className="fa fa-trash" style={{marginRight: 3}}></i>Yes</button>
                                            <button type="button" className="btn btn-danger pull-left" onClick={this.handleClose}>No</button>
                                          </DialogActions>

                                        <DialogContentText style={{color: 'red', fontSize: 20}}>
                                         {this.state.errors &&
                                          <span>       {this.state.errors.message}   </span>
                                                }
                                        </DialogContentText>
                                    </DialogContent>
                                   </Dialog>
                                    <div className="pull-right">
                                        <div className="btn-group btn-group-xs">
                                            {value.canEdit === true &&
                                                <button type="button" className="btn btn-default" onClick={() => this.handleEdit(value.id, value.isThread)}><i className="fas fa-pencil-alt"></i></button>
                                            }
                                            {value.canDelete === true && value.isThread === false &&
                                                <button type="button" className="btn btn-warning" onClick={() => this.handleDelete(value)} ><i className="fa fa-times"></i></button>
                                            }
                                            {value.canDelete === true && value.isThread === true &&
                                                <button type="button" className="btn btn-danger" onClick={() => this.handleDelete(value)} ><i className="fa fa-times"></i></button>
                                            }
                                            {value.canDelete === false && value.isThread === true &&
                                                <button type="button" className="btn btn-danger" disabled><i className="fa fa-times"></i></button>
                                            }


                                        </div>
                                    </div>
                                </Grid>
                                }
                               <div className="clearfix"></div>
                                <Grid item lg={2} md={2} className="text-center">
                                    <p>
                                        <Link to={`/users/member/${value.author.id}`}>
                                            <img src={ value.author.avatar}    alt={`${value.author.firstName} ${ value.author.lastName }'s picture`} className="author-avatar"/>
                                        </Link>
                                    </p>
                                    <p>
                                          { value.author.firstName } { value.author.lastName }
                                    </p>
                                    <p style={{color: "#c2c2c2"}} className="font-italic">{value.friendlyCreatedAt}</p>
                                </Grid>

                                <div className="clearfix"></div>
                        </Grid>
                    ))}
                    {!this.state.openReply &&
                      <Grid item lg={12} className="text-left">
                          <div className="btn btn-primary btn-undp" onClick={this.handleOpenReply}>
                              New message
                          </div>
                      </Grid>
                      }

                      {this.state.openReply &&
                      <Grid item container lg={12} className="forum-reply-form" >
                          <Grid item lg={10} >
                            <ThreadReplyForm onSubmit={this.handleSubmitReply} type="contributor"/>
                          </Grid>
                          <Grid item lg={2} className="text-right">
                          <div onClick={this.handleCloseReply} style={{margin: 20}}>
                              <i className="fas fa-times" ></i>
                          </div>
                          </Grid>
                      </Grid>
                      }
              </Grid>
                <Navbar/>
            </Grid>
          </div>
        </div>
        );
    }
}

MembersForumPost.propTypes = {
                getThread: PropTypes.func.isRequired,
                downloadAttachment: PropTypes.func.isRequired,
                auth: PropTypes.object.isRequired,
                profile: PropTypes.object.isRequired,
                postThreadReply: PropTypes.func.isRequired,
                createThread: PropTypes.func.isRequired,
                editThread: PropTypes.func.isRequired,
                threads: PropTypes.object.isRequired,
                postEditReply: PropTypes.func.isRequired,
                getReply: PropTypes.func.isRequired,
                setReply: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
                threads: state.threads,
                profile: state.profile,
                auth: state.auth
});

export default connect(mapStateToProps, { setReply, getCurrentProfile, getThread, downloadAttachment, deleteReply, postThreadReply, createThread, editThread, postEditReply, getReply})(withRouter(MembersForumPost));
