import React from 'react';
import PropTypes from "prop-types";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

 function DialogConfirm(props) {       
    
  return (<Dialog
                        onClose={props.handleNo}
                        aria-labelledby="simple-dialog-title"
                        open={props.openConfirm}
                        maxWidth="sm"
                        fullWidth
                       >
<DialogTitle id="simple-dialog-title"><span className="pull-right pointer" onClick={props.handleNo}>
                                 <i className="far fa-times-circle"></i></span>
                                 </DialogTitle>
                         <DialogContent>
                         <section className="newPanel">
                            <div className="panel-body">
                                <div>
                                    <div className="text-center">
                                        {props.text ?
                                        <h3>
                                            {props.text}
                                        </h3>
                                        :
                                        <h3>
                                            Are you sure?
                                        </h3>}
                                        <button className="button-outlines green text-center"
                                                onClick={props.handleNo}>
                                            No
                                        </button>
                                        <button className="button-primary-edited text-center btn-ok"
                                                onClick={props.handleYes}>
                                           Yes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>
                         </DialogContent>
                      </Dialog>);

 
}


DialogConfirm.propTypes = {
  handleNo: PropTypes.func.isRequired,
  handleYes: PropTypes.func.isRequired,
  openConfirm: PropTypes.bool.isRequired
};

export default DialogConfirm;
    
