import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getRegisterData, registerUser } from '../../actions/authActions';
import Navbar from '../layout/Navbar';
import Grid from '@material-ui/core/Grid';
import Footer from '../layout/Footer'
import { withStyles } from '@material-ui/core/styles'
import ContributeRegister from '../forms/ContributeRegister'
import { toast } from 'react-toastify'
import { getLogActivityWithGeoIp } from '../../actions/profileActions';
const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

class Register extends Component {
  constructor() {
    super();
    this.state = {
        errors: ""
    };
  }

  componentDidMount() {    
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/contribute/welcome');
    }else{
        this.props.getRegisterData()
        this.props.getLogActivityWithGeoIp('Register page', 'GET', "Website usage")
    }
  }

    componentWillReceiveProps(nextProps) {
      if(nextProps.auth.registerErrors && nextProps.auth.registerErrors[0] !== 'undefined' && nextProps.auth.registerErrors[0]){
         if(nextProps.auth.registerErrors[0]["errors"]){
             this.setState({
                 errors: nextProps.auth.registerErrors[0]["errors"]
             })
             let errors = nextProps.auth.registerErrors[0]["errors"];
             if(errors.length > 0){
                 errors.map(err => (
                         toast.error(err.errors[0], {autoClose: 10000})
                            ))
             }
         }
      }
    }

  submit = values => {   
     var body = new FormData();
    Object.keys(values).forEach(( key ) => {
        if(key === 'avatar'){
             body.append('avatar', values.avatar[0].file)
        }else if(key === 'organizationType'){
           let orgArray = []
                   values[key].map(obj => (
              orgArray.push(obj.value)
  ))
        body.append(key, orgArray);
        }else if(key === 'organization'){
            body.append(key, values.organization.label)
        }else{
      body.append(key, values[ key ]);
  }
    });

  this.props.registerUser(body, this.props.history)
  }

  render() {

const { classes } = this.props;
    return (
     <div className={classes.root}>
      <Grid container spacing={0} >
        <div  className="page-content slide  page-animation" >
            <div className="contribute-register">
                <div className="header">
                    <div className="col-md-6 col-md-offset-3">
                        <h1 className="max-width text-center text-white">
                                    Sign up to become a Contributor and join the SPHS Community
                        </h1>
                    </div>
                    <div className="col-md-12 text-center">
                        <h4 className="text-white">All fields are required, unless indicated as optional</h4>
                    </div>
                </div>
                    <div className="registerContibute">
                        <ContributeRegister onSubmit={this.submit}  userData={this.props.location.state ? this.props.location.state.response : null}/>
                    </div>
            </div>
            <Footer/>
        </div>
        <Navbar/>
      </Grid>
     </div>
    );
  }
}

Register.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getRegisterData: PropTypes.func.isRequired,
  registerUser: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  getLogActivityWithGeoIp: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

Register = withStyles(styles)(Register)
export default connect(mapStateToProps, { getRegisterData, registerUser, getLogActivityWithGeoIp })(withRouter(Register));
