import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getHelpCategories, getHelpContent, deleteHelpCategory } from '../../actions/helpActions';
import Grid from '@material-ui/core/Grid';
import Layout from '../../components/admin/layout/Layout'
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ModalConfirm from '../common/ModalConfirm';

class HelpPage extends Component {

  state = {
    anchorEl: null,
  };

    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            openConfirmDialog: false
        }
        this.getContent = this.getContent.bind(this);
        this.deleteHelp = this.deleteHelp.bind(this);
        this.openConfirmDialog = this.openConfirmDialog.bind(this);
        this.closeConfirmDialog = this.closeConfirmDialog.bind(this);
    }

    componentDidMount() {
        this.props.getHelpCategories();
        document.title = 'Help'
    }

    getContent(subCategory) {
        this.props.getHelpContent(subCategory);

        // if (!this.props.categories.content) {
        //     this.setState({ 'content': this.props.categories.categories.data[0].subCategories[0].value });
        // }
        // this.setState({'content' : this.props.categories })

    }

    openConfirmDialog(id) {
        this.setState({ openConfirmDialog: true, deleteId: id });
    }

    closeConfirmDialog() {
        this.setState({ openConfirmDialog: false, deleteId: null });
    }

    deleteHelp() {
        this.props.deleteHelpCategory(this.state.deleteId).then(this.props.getHelpCategories());

        this.setState({ openConfirmDialog: false, deleteId: null })
    }

    handleClick = event => {
      this.setState({ anchorEl: event.currentTarget });
    };

      handleClose = () => {
        this.setState({ anchorEl: null });
      };

    render() {
          const { anchorEl } = this.state;
        const { categories } = this.props.categories;
        let content = this.props.categories.content;

        if (categories && categories.data.length > 0 && content === null) {
            content = {};
            content.data = categories.data[0].subCategories[0].value;
        }

        let menuMobile;

        if (window.innerWidth < 1100) {

          menuMobile = (
            <>
            <center className="menu-button">
              <IconButton
                aria-label="Menu"
                aria-owns={anchorEl ? 'long-menu' : undefined}
                aria-haspopup="true"
                onClick={this.handleClick}
              >
                <MenuIcon style={{ fontSize: 18, color: "#212529" }} />
                <span className="ext-dark-blue-old" >Categories</span>
              </IconButton>

            </center>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
              style={{ top: 0 }}
              height="98%"
              PaperProps={{
                style: {
                padding: "0px 10px"
                }
              }}

            >
            <MenuItem >
              <div style={{width: '100%'}}>
                <span className="pull-right">
                <Icon onClick={this.handleClose}>close</Icon>
                </span>
              </div>
            </MenuItem>

            {categories && categories.data && categories.data.map(category => (
              <MenuItem style={{ display: "contents" }}>
              <span className="list-category">{category.name}</span>
                <>
                    {category.subCategories && category.subCategories.map(subCategory => (
                        <span className="list-item" onClick={() => this.getContent(subCategory.name)}>
                            <span>{subCategory.name}</span>
                            <div className="list-item-icons">
                                <a href={"./help/edit/" + subCategory.id}><i className="ion-edit list-item-icon" id="help-edit" title="Edit"></i></a>
                                <span onClick={() => this.openConfirmDialog(subCategory.id)}><i className="fa fa-trash list-item-icon"></i></span>
                            </div>
                        </span>
                    ))}
                </>
              </MenuItem>
              ))}
            </Menu>
            </>
          )

        } else {
          menuMobile = (
            <>
            {categories && categories.data && categories.data.map(category => (
                <ul>
                    <li className="list-category"><i class="fa fa-angle-down" aria-hidden="true"></i> {category.name}</li>
                    <ul>
                        {category.subCategories && category.subCategories.map(subCategory => (
                            <li className="list-item" onClick={() => this.getContent(subCategory.name)}>
                                <span>{subCategory.name}</span>
                                <div className="list-item-icons">
                                    <a href={"./help/edit/" + subCategory.id}><i className="ion-edit list-item-icon" id="help-edit" title="Edit"></i></a>
                                    <span onClick={() => this.openConfirmDialog(subCategory.id)}><i className="fa fa-trash list-item-icon"></i></span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </ul>
            ))}
            </>
          )
        }

        return (
            <Layout>
                <a href="./help/create" className="button-primary pull-right">
                    <i className="ion-plus-round"></i>
                    <span> New</span>
                </a>

                <Grid container direction="row-reverse">
                  <Grid item xs={12} sm={12} md={5} className="no-gutter">
                     {menuMobile}
                  </Grid>
                    <Grid item xs={12} sm={12} md={7} className="no-gutter">
                        {content && content.data &&
                            <div className="help-content" dangerouslySetInnerHTML={{ __html: content.data }} />
                        }
                    </Grid>
                </Grid>
                {
                    this.state.openConfirmDialog &&
                    <ModalConfirm onClose={this.closeConfirmDialog}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div>
                                    <div className="text-center">

                                        <h3>
                                            Are you sure?
                                        </h3>
                                        <button className="button-outlines green text-center"
                                            onClick={this.closeConfirmDialog}>
                                            No
                                        </button>
                                        <button className="button-primary-edited text-center btn-ok"
                                            onClick={() => this.deleteHelp()}>
                                            Yes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </ModalConfirm>
                }
            </Layout>
        );
    }
}

HelpPage.propTypes = {
    getHelpCategories: PropTypes.func.isRequired,
    getHelpContent: PropTypes.func.isRequired,
    deleteHelpCategory: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    profile: state.profile,
    auth: state.auth,
    settings: state.settings,
    categories: state.help
});

export default connect(mapStateToProps, { getHelpCategories, getHelpContent, deleteHelpCategory })(HelpPage);
