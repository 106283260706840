import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

 class DialogOpenOption extends React.Component {       
  constructor(props) {
    super(props);
    this.state = {name: ''};
  }
  render() {
    return   <Dialog
                        onClose={this.props.closeOption}
                        aria-labelledby="simple-dialog-title"
                        open={this.props.openOption}
                        maxWidth="sm"
                        fullWidth
                        >
                        <DialogTitle id="simple-dialog-title">Edit option<span className="pull-right pointer" onClick={this.props.closeOption}>
                                 <i className="far fa-times-circle"></i></span>
                                 </DialogTitle>
                         <DialogContent>
                         <section className="newPanel">
                            <div className="panel-body">                           
                            
                            <label>Label</label>
                            <input name="label" 
                            type="text"
                            onChange={this.props.handleChangeOption} 
                            value={this.props.option ? this.props.option : ''} 
                            onKeyDown={this.props.handleEnter} 
                            style={{marginBottom: 15}}/>
                            <label>Points</label>
                            <input name="points" 
                            type="text"
                            onChange={this.props.handleChangeOption} 
                            value={this.props.points ? this.props.points : ''} 
                            onKeyDown={this.props.handleEnter} 
                            style={{marginBottom: 15}}/>    
                               
                                <a href="#" onClick={this.props.deleteOption}>        
                                <DeleteOutlineIcon style={{color: 'rgb(220, 0, 78)'}}/>         
                                 </a>
                            </div>
                        </section>
                         </DialogContent>
                      </Dialog>
  }
}

export default DialogOpenOption ;














