import React from 'react';
import { DatePicker } from "@material-ui/pickers";
import FormControl from '@material-ui/core/FormControl';
import {renderFromHelper} from '../../../common/MaterialUiForm'
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
    htmlFontSize: 16,
    fontSize: 16
  },
  typography: {
    fontSize: 16,
  },
});

class DateYearComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: null  
        };
    }

    componentDidMount() {
       
       if(this.props.input.value){
           this.setState({
               value: this.props.input.value
           })
       }else{
           this.setState({
               value: null
           })
       }
    }
    
    componentWillReceiveProps(nextProps){
        
    }

    onChange = (value) => {
        this.props.input.onChange(value)
        this.setState({
           value: value
       })
    }

    render() {
       const {classes, field, label, input, meta: { touched, invalid, error }, custom} = this.props;
            
        return  <FormControl fullWidth style={{fontSize: 16}}>
    <div className="text-left" style={{ fontSize: 14, marginTop: 15, textTransform: 'uppercase' }}>{label}</div>
      <DatePicker
        {...input}
        {...custom}        
        disabled={typeof field !== 'undefined' ? field.disabled : null}
        onChange={this.onChange}
        value={this.state.value }        
        style={{marginTop: 16, marginBottom: 8}}         
         views={["year"]}        
            />
       {renderFromHelper({ touched, error })}
    </FormControl>
    }
}

DateYearComponent = withStyles(styles)(DateYearComponent)

export default DateYearComponent;
