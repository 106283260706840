import React, { Component } from 'react';
import Sectors from './Sectors'
import Sdgs from './Sdgs'
import Contribute from '../Contribute'
import Grid from '@material-ui/core/Grid'
import SocialNetworks from './SocialNetworks'
import SaveForLater from '../SaveForLater'
import LoginForm from '../../../layout/LoginForm'
import Diff from 'react-stylable-diff'

class ContributionPublication extends Component {
  render() {
   const contribution = this.props.contribution;   
return (
        <div>
<div className="col-md-12 right-header">
   {contribution.author && <div className="col-md-12 clearfix" ng-if="">
        <h5 className="text-dark-blue-old">Author</h5>
        {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor ?
        <p className="text-white">
        <Diff type="sentences" inputA={contribution.reflection.author} inputB={contribution.author}/>
        </p>
                          :
                          <p className="text-white">{ contribution.author}</p>
                      }
        
        
    </div>}
    {contribution.projectAtYear && <div className="col-md-12 clearfix" ng-if="">
        <h5 className="text-uppercase project-date text-dark-blue-old pull-left">publication year</h5>
        <h5 className="text-white">
         {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor ?
                        <Diff type="sentences" inputA={contribution.reflection.projectAtYear} inputB={contribution.projectAtYear}/>
                          :
                          <span>{ contribution.projectAtYear}</span>
                      }
        </h5>        
    </div>}
</div>

{contribution.sectors &&
        <Sectors sectors={contribution.sectors}/>
}
{contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor &&
<Diff type="sentences" inputA={contribution.reflection.sectorsText} inputB={contribution.sectorsText}/>
}
 <LoginForm role="filter"/>
 {contribution.href &&
<SocialNetworks url={contribution.href}/>
}
<SaveForLater id={contribution.id} type="contrib"/>

{contribution.sdg &&
<Sdgs sdgs={contribution.sdg}/>
}
{contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor &&
<Diff type="sentences" inputA={contribution.reflection.sdgText} inputB={contribution.sdgText}/>
}
{contribution.publisher &&
<div className="col-xs-12">
    <h4 className="text-uppercase text-gray">publisher</h4>
     {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor ?
                         <p className="text-black" > <Diff type="sentences" inputA={contribution.reflection.publisher} inputB={contribution.publisher}/></p>
                          :
                           <p className="text-black" >{ contribution.publisher}</p>
                      }
      
</div>
}
{contribution.links &&
<div className="col-xs-12">
    <h4 className="text-uppercase text-gray">publication link</h4>
     {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor ?
          <Diff type="sentences" inputA={contribution.reflection.links} inputB={contribution.links}/>
         :
        <a href={contribution.links} target="_blank">{contribution.links}</a>
        }
</div>
}
</div>);
  }
}

export default ContributionPublication;