import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import { getCommon } from '../../actions/memberActions';
import Navbar from '../layout/Navbar';
import Grid from '@material-ui/core/Grid';
import Footer from '../layout/Footer'
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import SpeakersAfricaForum from './elements/SpeakersAfricaForum';
import { config } from '../../config'
import { getLogActivityWithGeoIp } from '../../actions/profileActions';

const ITEM_HEIGHT = 42;

class AfricaForum extends Component {
  constructor(props) {
  super(props);
          this.state = {
          height: '150px'
          }
  }

  componentDidMount() {
      window.scrollTo(0, 0);
      this.props.getLogActivityWithGeoIp('Africa Forum page', 'GET', "Website usage")
  }

  componentWillReceiveProps(nextProps) {

  }

  handleClickOpen = event => {
  event.preventDefault();
          this.setState({ [event.target.id]: true });
  };
          handleClose = event => {
          this.setState({
          [event]: false,
                  password: '',
                  email: ''
          });
          };
          handleExpand = () => {

  if (document.getElementById('expand-more').offsetHeight === 150){
  this.setState({
  height: '250px'
  });
  }
  if (document.getElementById('expand-more').offsetHeight === 250){
  this.setState({
  height: '150px'
  });
  }

  }

  render() {
  let height = this.state.height;
          return (
            <div>
                <Navbar/>
            <div className="page-content  page-animation slideup">
                <Grid container>
                    <Grid item xs={12}>
                        <div className="asia-forum">
                            <div className="heroshot noPadding2"><img src={config.API_URL + "/images/org/fe74b9.jpeg?v1.0"} all="image"/>
                                <div className="text-area">
                                  <div className="text-center  col-md-8 col-md-offset-2">
                                    <img className="asia-forum-logo" src={config.API_URL + "/images/org/f50df5.png?v1.0"} all="image"/>
                                    <h3 className="text-white">
                                      3rd MSD – Manufacturers/ Suppliers Annual General Meeting and <br/>
                                      2nd Saving Lives Sustainably: 2019 Global Forum in Africa</h3>
                                      <hr/>
                                    <h5 className="text-white">
                                      Julius Nyerere International Convention Centre<br/>
                                      Dar es Salaam, Tanzania.<br/>
                                      18-19th July 2019</h5>
                                      <p className="counting_delegates">390 Delegates</p>
                                    </div>
                                </div>
                                <div className="bounce">
                                  <a href="#first-section">
                                    <Icon style={{fontSize: "56px"}} className={'fa fa-angle-down text-white'}/>
                                  </a>
                                </div>
                            </div>
                            <div className="intro">
                                <div className="max-width">
                                <Grid container spacing={0}>
                                        <Grid item md={3} sm={6} xs={12}>
                                        <a className="anchor-link" id="first-section"></a>
                                            <div className="icon-box text-center">
                                                <div className="image">
                                                    <img src={config.API_URL + "/images/org/c9f92c.png?v1.0"} alt="image"/>
                                                </div>
                                                <a className="anchor-link" href="#asia-forum-intro-anchor-1"><h4 className="text-white">SPEAKERS</h4></a>
                                                <i className="fa fa-angle-down" aria-hidden="true"></i>
                                            </div>
                                        </Grid>
                                      <Grid item md={3} sm={6} xs={12}>
                                            <div className="icon-box text-center">
                                                <div className="image">
                                                    <img src={config.API_URL + "/images/org/557693.png?v1.0"} alt="image"/>
                                                </div>
                                                <a className="anchor-link" href="#asia-forum-intro-anchor-2"><h4 className="text-white">PROGRAMME</h4></a>
                                                <i className="fa fa-angle-down" aria-hidden="true"></i>
                                            </div>
                                        </Grid>
                                        <Grid item md={3} sm={6} xs={12}>
                                            <div className="icon-box text-center">
                                                <div className="image">
                                                    <img src={config.API_URL + "/images/org/a30285.png?v1.0"} alt="image"/>
                                                </div>
                                                <a className="anchor-link" href="#asia-forum-intro-anchor-3"><h4 className="text-white">PARTNERS</h4></a>
                                                <i className="fa fa-angle-down" aria-hidden="true"></i>
                                            </div>
                                        </Grid>
                                        <Grid item md={3} sm={6} xs={12}>
                                            <div className="icon-box text-center">
                                                <div className="image">
                                                    <img src={config.API_URL + "/images/Africa/logistics.svg"} alt="image"/>
                                                </div>
                                                <a className="anchor-link" href="#asia-forum-intro-anchor-4"><h4 className="text-white">LOGISTICS</h4></a>
                                                <i className="fa fa-angle-down" aria-hidden="true"></i>
                                            </div>
                                        </Grid>

                                    </Grid>
                                </div>
                            </div>
                            <div className="about">
                                <div className="col-md-8 col-sm-12 col-md-offset-2 text-center">
                                    <h1>Why sustainable production, consumption and disposal?</h1>
                                    <p>
                                        Sustainable production supports companies not only to reduce environmental, health, and safety impacts, but also to save money and enhance competitiveness. Suppliers and manufacturers from across the global healthcare industry face a set of issues, ranging from environmental degradation and antibiotic resistance caused by pollution to the intensive use of water and energy, and from the handling of hazardous waste to occupational health and safety risks. Given their extensive scale and their mission-driven interest in improving human health, suppliers and manufacturers operating in the health sector have the potential to become leaders in the transition of the entire economy towards sustainable products and practices.
                                    </p>
                                </div>
                            </div>

                          <SpeakersAfricaForum/>

                            <div className="programme" style={{padding: "0px 15px 100px"}}>
                                <a className="anchor-link" name="asia-forum-intro-anchor-2">
                                <h1 className="text-center">
                                    <span className="text-black">Programme</span>
                                </h1>
                                </a>
                                <div className="day">
                                    <h1 className="day-title">DAY 1 / 18 JULY</h1>
                                    <div className="max-width more-information">
                                    </div>
                                    <div className="programme-day" style={{minHeight: "475px"}}>
                                        <div className="col-lg-2  col-md-2 col-sm-3 noPadding">
                                            <img src={config.API_URL + "/images/africa-forum-prosperity.png"} all="image"/>
                                            <img src={config.API_URL + "/images/africa-forum-planet.png"} all="image" style={{marginTop: "10px"}}/>
                                        </div>
                                        <div className="col-lg-1 col-md-1"></div>
                                        <div className="col-lg-9 col-md-9 noPadding programme-table-lg" style={{bottom: 0,right: 0}}>
                                            <div style={{margin:"25px 5px 0 0"}}>
                                                <div className="col-sm-12" style={{marginTop: "5px"}}>
                                                    <h1>Forum Day 1: Thursday, 18 July</h1>
                                                    <p><strong>SESSION CO-MODERATORS:</strong> Laurean Rugambwa Bwanakunu, Director General/Chief Executive Officer, Medical Stores Department (MSD) Tanzania and Dr. Rosemary Kumwenda, UN Interagency on Sustainable Procurement in the Health Sector (SPHS) Coordinator</p>
                                                </div>
                                                <div className="col-sm-3" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                    <span>08.00-09.00</span>
                                                </div>
                                                <div className="col-sm-7" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                    <strong>Registration</strong>

                                                </div>
                                                <div className="col-sm-2" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                    <strong>Lobby</strong>
                                                </div>
                                                <div className="col-sm-3" style={{marginTop: "5px"}}>

                                                </div>
                                                <div className="col-sm-7" style={{marginTop: "5px"}}>
                                                    <p>MC calls meeting to order Mr. Taji Liundi and Etty Kusiluka</p>
                                                </div>
                                                <div className="col-sm-2" style={{marginTop: "5px"}}>

                                                </div>
                                                <div className="col-sm-3" style={{marginTop: "5px"}}>
                                                    <span>09.10-10.00</span>
                                                </div>
                                                <div className="col-sm-7" style={{marginTop: "5px"}}>
                                                    <strong>1. Official Opening / Introductory Remarks</strong><br/>
                                                    <p>Moderator: Laurean Bwanakunu, Director-General, Medical Stores Department in Tanzania (MSD)</p>
                                                    <ul>
                                                        <li>Dancilla Mukarubayiza, Deputy Resident Representative, UNDP Tanzania</li>
                                                        <li>Ludvig Bontell, Chargé d’Affaires, Embassy of Sweden in Dar Es Salaam</li>
                                                        <li>Hon. Ummy Mwalimu (MP), Minister of Health, Community Development, Gender, Elderly and Children, United Republic of Tanzania</li>
                                                        <li>Dr. Jean-Paul Digy, Corporate Vice President, Maghreb Region, Novo Nordisk</li>
                                                    </ul>
                                                    <p><b>Rapporteur: Caoimhe Hughes</b></p>
                                                </div>
                                                <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                    <strong>Auditorium: Selous</strong>
                                                </div>
                                                <div className="col-sm-3" style={{marginTop: "5px"}}>

                                                </div>
                                                <div className="col-sm-7" style={{marginTop: "5px"}}>
                                                    <strong>Group photo</strong>
                                                    <p><b>Logistics briefing</b> (Abdul Mwanja, MSD and Ian Milimo, UNDP)</p>
                                                </div>
                                                <div className="col-sm-2" style={{marginTop: "5px"}}>

                                                </div>
                                                <div className="col-sm-3" style={{marginTop: "5px"}}>
                                                    <span>10.00-11.15</span>
                                                </div>
                                                <div className="col-sm-7" style={{marginTop: "5px"}}>
                                                    <strong>2. High level Policy Panel (Davos Style)</strong>
                                                    <p>Moderator: Dr. Rosemary Kumwenda, UN Interagency on Sustainable Procurement in the Health Sector (SPHS) Coordinator</p>
                                                    <p>Key Note Address: Dr. Mandeep Dhaliwal, Director, HIV, Health and Development Practice, UNDP</p>
                                                    <p>Speakers:</p>
                                                    <ul>
                                                      <li>Laurean Rugambwa Bwanakunu, Director General/Chief Executive Officer, Medical Stores Department (MSD) Tanzania</li>
                                                      <li>Dr. Nguyen Thanh Ha, Deputy Director General of Viet Nam Health Environment Agency</li>
                                                      <li>Susan Wilburn, Sustainability Director, Health Care Without Harm (HCWH)</li>
                                                    </ul>
                                                </div>
                                                <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                    <strong>Auditorium: Selous</strong>
                                                </div>
                                                <div className="col-sm-3" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                    <span>11.15-11.45</span>
                                                </div>
                                                <div className="col-sm-7" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                    <strong>Coffee &amp; Networking</strong>
                                                </div>
                                                <div className="col-sm-2" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                    <strong>Lobby</strong>
                                                </div>
                                                <div className="col-sm-3" style={{marginTop: "5px"}}>
                                                    <span>11.45-13.00</span>
                                                </div>
                                                <div className="col-sm-7" style={{marginTop: "5px"}}>
                                                    <strong>3. MSD Business Processes: </strong> Plenary on Prosperity <br/>
                                                    Moderator: Laurean Rugambwa Bwanakunu, Director General/Chief Executive Officer, Medical Stores Department (MSD) Tanzania <p>Speakers:</p>
                                                    <ul>
                                                      <li>Abdul Mwanja, Director of Procurement, Medical Stores Department (MSD) Tanzania</li>
                                                      <li>Sako Mwakalobo, Director of Finance and Planning, Medical Stores Department (MSD) Tanzania</li>
                                                      <li>Victoria Mwanri Elangwa, Director of Human Resources and Administration, Medical Stores Department in Tanzania (MSD)</li>
                                                    </ul>
                                                </div>
                                                <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                    <strong>Auditorium: Selous</strong>
                                                </div>
                                                <div className="col-sm-3" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                    <span>13.00-14.00</span>
                                                </div>
                                                <div className="col-sm-7" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                    <strong>Lunch Break</strong>
                                                </div>
                                                <div className="col-sm-2" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                    <span><strong>Canteen</strong></span>
                                                </div>
                                                <div className="col-sm-3" style={{marginTop: "5px"}}>
                                                    <span>14.00-15.15</span>
                                                </div>
                                                <div className="col-sm-7" style={{marginTop: "5px"}}>
                                                    <strong>4. Parallel sessions A: PROSPERITY </strong> Dimension of Sustainable Development
                                                    <ul>
                                                        <li><b>A.1 Production policies, laws and strategies including trade barriers</b> (Tender model, Total Cost of Ownership, Environmental Compliance Emerging Issues)<br/>
                                                        Moderator: Dr Amitrajit Saha, Team Leader, HIV, Health and Development Team for Africa, UNDP
                                                        <p>Speakers:</p>
                                                          <ul>
                                                            <li>Judit Rius Sanjuan, Policy Specialist, Health Technology Innovation and Access, HIV, Health and Development, UNDP</li>
                                                            <li>Hetal Vithlani, Director - Prince Pharmaceuticals Limited, Mwanza (Tanzania)</li>
                                                          </ul>
                                                          <p><b>Rapporteur: Alexandru Cocirta</b></p>
                                                      </li>
                                                    </ul>
                                                </div>
                                                <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                    <span><strong>Breakout session room: Mikumi</strong></span>
                                                </div>
                                                <div className="col-sm-3" style={{ marginTop: "5px"}}>

                                              </div>
                                                <div className="col-sm-7" style={{ marginTop: "5px"}}>
                                                <ul>
                                                  <li><b>A.2 Access to markets:  Requirements for Marketing Authorization and Importation of Health Products</b>
                                                  <p>Moderator: Prof. Eliangiringa Kaale, Professor in Quality Assurance and Regulatory Affairs and Head of Pharm Lab</p>
                                                  <p>Speakers:</p>
                                                    <ul>
                                                      <li>Hitesh Upreti, CEO and Managing Director, ZENUFA Laboratories Tanzania</li>
                                                      <li>Akida Khea, Acting Director of Medical Products Control, Tanzania Medicine and Medical Devices Authority</li>
                                                      <li>Diana Masalla, Manager Taxpayer Education – Tanzania Revenue Authority (TRA)</li>
                                                    </ul>
                                                  <p><b>Rapporteur: Natalya Lukyanova</b></p>
                                                </li>
                                              </ul>
                                                </div>
                                                <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                    <strong>Breakout session room: Ruaha</strong>
                                                </div>
                                                <div className="col-sm-3" style={{ marginTop: "5px"}}>

                                              </div>
                                                <div className="col-sm-7" style={{ marginTop: "5px"}}>
                                              <ul>
                                                <li><b>A.3 Innovations and Partnerships for Sustainable Production and Health Delivery</b><br/>
                                                  <p>Moderator: Sako Mwakalobo, Director of Finance and Planning, Medical Stores Department (MSD) Tanzania
                                                  <br/>Speakers:</p>
                                                  <ul>
                                                    <li>Professor Sudip Chaudhuri, Professor of Economics, Indian Institute of Management Calcutta </li>
                                                    <li>Allen Cassian Njau, Senior Contract Compliance and Enforcement Officer, Medical Stores Department in Tanzania (MSD)</li>
                                                    <li>Johann Hoffman, Executive Director, Medi-Clave</li>
                                                  </ul>
                                                  <p><b>Rapporteur: Jose Manuel Irizar</b></p>
                                              </li>
                                            </ul>
                                                </div>
                                                <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                    <strong>Auditorium: Selous</strong>
                                                </div>
                                                <div className="col-sm-3" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                    <span>15.15-15.45</span>
                                                </div>
                                                <div className="col-sm-7" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                    <strong>Coffee &amp; Networking</strong>
                                                </div>
                                                <div className="col-sm-2" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                    <strong>Lobby</strong>
                                                </div>
                                                <div className="col-sm-3" style={{marginTop: "5px"}}>
                                                    <span>15.45-17.00</span>
                                                </div>
                                                <div className="col-sm-7" style={{marginTop: "5px"}}>
                                                <strong>5. Parallel sessions B: PLANET</strong> Dimension of Sustainable Development
                                                        <ul>
                                                          <li><strong>B.1 Water Management Pharmaceuticals in the Environment (PiE) / Antimicrobial Resistance (AMR)</strong><br/>
                                                        <p>Moderator: Susan Wilburn, Sustainability Director, Health Care Without Harm (HCWH)
                                                              <br/>Speakers:</p>
                                                                <ul>
                                                                  <li>Suman Sharma, Director Sustainable Antibiotics Program India, Centrient Pharmaceuticals </li>
                                                                  <li>Iris Cherry Panorel, Programme Officer, SIWI</li>
                                                                  <li>Gianluca Magrini, Product Development Specialist, Newster Group</li>
                                                                  </ul>
                                                                  <p><b>Rapporteur: Dao Khanh Tung</b></p>
                                                                </li>
                                                              </ul>
                                                </div>
                                                <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                    <strong>Auditorium: Selous</strong>
                                                </div>
                                                <div className="col-sm-3" style={{marginTop: "5px"}}>

                                                </div>
                                                <div className="col-sm-7" style={{marginTop: "5px"}}>
                                                <ul>
                                                  <li><strong>B.2 Clean Energy</strong>
                                                  <p>Moderator: Dr. Rosemary Kumwenda, UN Interagency on Sustainable Procurement in the Health Sector (SPHS) Coordinator</p>
                                                  <p>Speakers:</p>
                                                    <ul>
                                                      <li>Professor Samwel Manyele, University of Dar es Salaam</li>
                                                      <li>Ramadhan R. Madabida Managing Director, Tanzania Pharmaceutical Industries Limited.</li>
                                                    </ul>
                                                    <p><b>Rapporteur: Jose Manuel Irizar</b></p>
                                                  </li>
                                                </ul>
                                                <p><b>Rapporteur: Caoimhe Hughes</b></p>
                                                </div>
                                                <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                    <strong>Breakout session room: Mikumi</strong>
                                                </div>
                                                <div className="col-sm-3" style={{marginTop: "5px"}}>

                                                </div>
                                                <div className="col-sm-7" style={{marginTop: "5px"}}>
                                                <ul>
                                                  <li><strong>B.3 Process Safety Management (recycling and packaging, chemicals of concern, waste prevention and reduction</strong>
                                                    <br/>
                                                    <p>Moderator:  Dr. Megha Rathi, Sustainable Health in Procurement Project (SHiPP) Coordinator, Health Care Without Harm (HCWH)
                                                    <br/>Speakers:</p>
                                                      <ul>
                                                        <li>Ruth Stringer, Science and Policy Coordinator, Health Care Without Harm (HCWH</li><li>Dr. Miriam Mongi, Environmental Health Practitioner, Sinza Hospital</li>
                                                          <li>Senam Tengey and Johannes Boachie-Yiadom, Zoompak Medical Waste Facility, Ghana</li>
                                                          <li>Johannes Boachie-Yiadom, Operations Supervisor, Zoompak Medical Waste Facility, Ghana</li>
                                                        </ul>
                                                      </li>
                                                    </ul>
                                                    <p><b>Rapporteur: Caoimhe Hughes</b></p>
                                                </div>
                                                <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                    <strong>Breakout session room: Ruaha</strong>
                                                </div>
                                                <div className="col-sm-3" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                    <span>17.00-17.30</span>
                                                </div>
                                                <div className="col-sm-7" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                    <strong>Wrapping up the day</strong>
                                                    <p>Moderator: Laurean Bwanakunu, Director-General, Medical Stores Department in Tanzania (MSD)</p>
                                                </div>
                                                <div className="col-sm-2" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                    <strong>Auditorium: Selous</strong>
                                                </div>
                                                <div className="col-sm-3" style={{marginTop: "5px"}}>
                                                    <span>19.30-21.00</span>
                                                </div>
                                                <div className="col-sm-7" style={{marginTop: "5px"}}>
                                                <strong>Welcome reception</strong><br/>
                                                <strong>Welcome remarks</strong><br/>
                                                Speakers:
                                                <ul>
                                                  <li>Laurean Bwanakunu, Director-General, Medical Stores Department in Tanzania (MSD)</li>
                                                  <li>Dancilla Mukarubayiza, Deputy Resident Representative, UNDP Tanzania</li>
                                                </ul>
                                                </div>
                                                <div className="col-sm-2" style={{marginTop: "5px",paddingBottom: "20px"}}>
                                                    <strong>Lobby</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-sm-12 programme-table-upper">
                                        <div style={{margin:"25px 5px 0 0"}}>
                                            <div className="col-sm-12" style={{marginTop: "5px"}}>
                                                <h1>Forum Day 1: Thursday, 18 July</h1>
                                                <p><strong>SESSION CO-MODERATORS:</strong> Laurean Rugambwa Bwanakunu, Director General/Chief Executive Officer, Medical Stores Department (MSD) Tanzania and Dr. Rosemary Kumwenda, UN Interagency on Sustainable Procurement in the Health Sector (SPHS) Coordinator</p>
                                            </div>
                                            <div className="col-sm-3" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                <span>08.00-09.00</span>
                                            </div>
                                            <div className="col-sm-7" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                <strong>Registration</strong>

                                            </div>
                                            <div className="col-sm-2" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                <strong>Lobby</strong>
                                            </div>
                                            <div className="col-sm-3" style={{marginTop: "5px"}}>

                                            </div>
                                            <div className="col-sm-7" style={{marginTop: "5px"}}>
                                                <p>MC calls meeting to order Mr. Taji Liundi and Etty Kusiluka</p>
                                            </div>
                                            <div className="col-sm-2" style={{marginTop: "5px"}}>

                                            </div>
                                            <div className="col-sm-3" style={{marginTop: "5px"}}>
                                                <span>09.10-10.00</span>
                                            </div>
                                            <div className="col-sm-7" style={{marginTop: "5px"}}>
                                                <strong>1. Official Opening / Introductory Remarks</strong><br/>
                                                <p>Moderator: Laurean Bwanakunu, Director-General, Medical Stores Department in Tanzania (MSD)</p>
                                                <ul>
                                                    <li>Dancilla Mukarubayiza, Deputy Resident Representative, UNDP Tanzania</li>
                                                    <li>Ludvig Bontell, Chargé d’Affaires, Embassy of Sweden in Dar Es Salaam</li>
                                                    <li>Hon. Ummy Mwalimu (MP), Minister of Health, Community Development, Gender, Elderly and Children, United Republic of Tanzania</li>
                                                    <li>Dr. Jean-Paul Digy, Corporate Vice President, Maghreb Region, Novo Nordisk</li>
                                                </ul>
                                                <p><b>Rapporteur: Caoimhe Hughes</b></p>
                                            </div>
                                            <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                <strong>Auditorium: Selous</strong>
                                            </div>
                                            <div className="col-sm-3" style={{marginTop: "5px"}}>

                                            </div>
                                            <div className="col-sm-7" style={{marginTop: "5px"}}>
                                                <strong>Group photo</strong>
                                                <p><b>Logistics briefing</b> (Abdul Mwanja, MSD and Ian Milimo, UNDP)</p>
                                            </div>
                                            <div className="col-sm-2" style={{marginTop: "5px"}}>

                                            </div>
                                            <div className="col-sm-3" style={{marginTop: "5px"}}>
                                                <span>10.00-11.15</span>
                                            </div>
                                            <div className="col-sm-7" style={{marginTop: "5px"}}>
                                                <strong>2. High level Policy Panel (Davos Style)</strong>
                                                <p>Moderator: Dr. Rosemary Kumwenda, UN Interagency on Sustainable Procurement in the Health Sector (SPHS) Coordinator</p>
                                                <p>Key Note Address: Dr. Mandeep Dhaliwal, Director, HIV, Health and Development Practice, UNDP</p>
                                                <p>Speakers:</p>
                                                <ul>
                                                  <li>Laurean Rugambwa Bwanakunu, Director General/Chief Executive Officer, Medical Stores Department (MSD) Tanzania</li>
                                                  <li>Dr. Nguyen Thanh Ha, Deputy Director General of Viet Nam Health Environment Agency</li>
                                                  <li>Susan Wilburn, Sustainability Director, Health Care Without Harm (HCWH)</li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                <strong>Auditorium: Selous</strong>
                                            </div>
                                            <div className="col-sm-3" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                <span>11.15-11.45</span>
                                            </div>
                                            <div className="col-sm-7" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                <strong>Coffee &amp; Networking</strong>
                                            </div>
                                            <div className="col-sm-2" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                <strong>Lobby</strong>
                                            </div>
                                            <div className="col-sm-3" style={{marginTop: "5px"}}>
                                                <span>11.45-13.00</span>
                                            </div>
                                            <div className="col-sm-7" style={{marginTop: "5px"}}>
                                                <strong>3. MSD Business Processes: </strong> Plenary on Prosperity <br/>
                                                Moderator: Laurean Rugambwa Bwanakunu, Director General/Chief Executive Officer, Medical Stores Department (MSD) Tanzania <p>Speakers:</p>
                                                <ul>
                                                  <li>Abdul Mwanja, Director of Procurement, Medical Stores Department (MSD) Tanzania</li>
                                                  <li>Sako Mwakalobo, Director of Finance and Planning, Medical Stores Department (MSD) Tanzania</li>
                                                  <li>Victoria Mwanri Elangwa, Director of Human Resources and Administration, Medical Stores Department in Tanzania (MSD)</li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                <strong>Auditorium: Selous</strong>
                                            </div>
                                            <div className="col-sm-3" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                <span>13.00-14.00</span>
                                            </div>
                                            <div className="col-sm-7" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                <strong>Lunch Break</strong>
                                            </div>
                                            <div className="col-sm-2" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                <span><strong>Canteen</strong></span>
                                            </div>
                                            <div className="col-sm-3" style={{marginTop: "5px"}}>
                                                <span>14.00-15.15</span>
                                            </div>
                                            <div className="col-sm-7" style={{marginTop: "5px"}}>
                                                <strong>4. Parallel sessions A: PROSPERITY </strong> Dimension of Sustainable Development
                                                <ul>
                                                    <li><b>A.1 Production policies, laws and strategies including trade barriers</b> (Tender model, Total Cost of Ownership, Environmental Compliance Emerging Issues)<br/>
                                                    Moderator: Dr Amitrajit Saha, Team Leader, HIV, Health and Development Team for Africa, UNDP
                                                    <p>Speakers:</p>
                                                      <ul>
                                                        <li>Judit Rius Sanjuan, Policy Specialist, Health Technology Innovation and Access, HIV, Health and Development, UNDP</li>
                                                        <li>Hetal Vithlani, Director - Prince Pharmaceuticals Limited, Mwanza (Tanzania)</li>
                                                      </ul>
                                                      <p><b>Rapporteur: Alexandru Cocirta</b></p>
                                                  </li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                <span><strong>Breakout session room: Mikumi</strong></span>
                                            </div>
                                            <div className="col-sm-3" style={{ marginTop: "5px"}}>

                                          </div>
                                            <div className="col-sm-7" style={{ marginTop: "5px"}}>
                                            <ul>
                                              <li><b>A.2 Access to markets:  Requirements for Marketing Authorization and Importation of Health Products</b>
                                              <p>Moderator: Prof. Eliangiringa Kaale, Professor in Quality Assurance and Regulatory Affairs and Head of Pharm Lab</p>
                                              <p>Speakers:</p>
                                                <ul>
                                                  <li>Hitesh Upreti, CEO and Managing Director, ZENUFA Laboratories Tanzania</li>
                                                  <li>Akida Khea, Acting Director of Medical Products Control, Tanzania Medicine and Medical Devices Authority</li>
                                                  <li>Diana Masalla, Manager Taxpayer Education – Tanzania Revenue Authority (TRA)</li>
                                                </ul>
                                              <p><b>Rapporteur: Natalya Lukyanova</b></p>
                                            </li>
                                          </ul>
                                            </div>
                                            <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                <strong>Breakout session room: Ruaha</strong>
                                            </div>
                                            <div className="col-sm-3" style={{ marginTop: "5px"}}>

                                          </div>
                                            <div className="col-sm-7" style={{ marginTop: "5px"}}>
                                          <ul>
                                            <li><b>A.3 Innovations and Partnerships for Sustainable Production and Health Delivery</b><br/>
                                              <p>Moderator: Sako Mwakalobo, Director of Finance and Planning, Medical Stores Department (MSD) Tanzania
                                              <br/>Speakers:</p>
                                              <ul>
                                                <li>Professor Sudip Chaudhuri, Professor of Economics, Indian Institute of Management Calcutta </li>
                                                <li>Allen Cassian Njau, Senior Contract Compliance and Enforcement Officer, Medical Stores Department in Tanzania (MSD)</li>
                                                <li>Johann Hoffman, Executive Director, Medi-Clave</li>
                                              </ul>
                                              <p><b>Rapporteur: Jose Manuel Irizar</b></p>
                                          </li>
                                        </ul>
                                            </div>
                                            <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                <strong>Auditorium: Selous</strong>
                                            </div>
                                            <div className="col-sm-3" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                <span>15.15-15.45</span>
                                            </div>
                                            <div className="col-sm-7" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                <strong>Coffee &amp; Networking</strong>
                                            </div>
                                            <div className="col-sm-2" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                <strong>Lobby</strong>
                                            </div>
                                            <div className="col-sm-3" style={{marginTop: "5px"}}>
                                                <span>15.45-17.00</span>
                                            </div>
                                            <div className="col-sm-7" style={{marginTop: "5px"}}>
                                            <strong>5. Parallel sessions B: PLANET</strong> Dimension of Sustainable Development
                                                    <ul>
                                                      <li><strong>B.1 Water Management Pharmaceuticals in the Environment (PiE) / Antimicrobial Resistance (AMR)</strong><br/>
                                                    <p>Moderator: Susan Wilburn, Sustainability Director, Health Care Without Harm (HCWH)
                                                          <br/>Speakers:</p>
                                                            <ul>
                                                              <li>Suman Sharma, Director Sustainable Antibiotics Program India, Centrient Pharmaceuticals </li>
                                                              <li>Iris Cherry Panorel, Programme Officer, SIWI</li>
                                                              <li>Gianluca Magrini, Product Development Specialist, Newster Group</li>
                                                              </ul>
                                                              <p><b>Rapporteur: Dao Khanh Tung</b></p>
                                                            </li>
                                                          </ul>
                                            </div>
                                            <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                <strong>Auditorium: Selous</strong>
                                            </div>
                                            <div className="col-sm-3" style={{marginTop: "5px"}}>

                                            </div>
                                            <div className="col-sm-7" style={{marginTop: "5px"}}>
                                            <ul>
                                              <li><strong>B.2 Clean Energy</strong>
                                              <p>Moderator: Dr. Rosemary Kumwenda, UN Interagency on Sustainable Procurement in the Health Sector (SPHS) Coordinator</p>
                                              <p>Speakers:</p>
                                                <ul>
                                                  <li>Professor Samwel Manyele, University of Dar es Salaam</li>
                                                  <li>Ramadhan R. Madabida Managing Director, Tanzania Pharmaceutical Industries Limited.</li>
                                                </ul>
                                                <p><b>Rapporteur: Jose Manuel Irizar</b></p>
                                              </li>
                                            </ul>
                                            <p><b>Rapporteur: Caoimhe Hughes</b></p>
                                            </div>
                                            <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                <strong>Breakout session room: Mikumi</strong>
                                            </div>
                                            <div className="col-sm-3" style={{marginTop: "5px"}}>

                                            </div>
                                            <div className="col-sm-7" style={{marginTop: "5px"}}>
                                            <ul>
                                              <li><strong>B.3 Process Safety Management (recycling and packaging, chemicals of concern, waste prevention and reduction</strong>
                                                <br/>
                                                <p>Moderator:  Dr. Megha Rathi, Sustainable Health in Procurement Project (SHiPP) Coordinator, Health Care Without Harm (HCWH)
                                                <br/>Speakers:</p>
                                                  <ul>
                                                    <li>Ruth Stringer, Science and Policy Coordinator, Health Care Without Harm (HCWH</li><li>Dr. Miriam Mongi, Environmental Health Practitioner, Sinza Hospital</li>
                                                      <li>Senam Tengey and Johannes Boachie-Yiadom, Zoompak Medical Waste Facility, Ghana</li>
                                                      <li>Johannes Boachie-Yiadom, Operations Supervisor, Zoompak Medical Waste Facility, Ghana</li>
                                                    </ul>
                                                  </li>
                                                </ul>
                                                <p><b>Rapporteur: Caoimhe Hughes</b></p>
                                            </div>
                                            <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                <strong>Breakout session room: Ruaha</strong>
                                            </div>
                                            <div className="col-sm-3" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                <span>17.00-17.30</span>
                                            </div>
                                            <div className="col-sm-7" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                <strong>Wrapping up the day</strong>
                                                <p>Moderator: Laurean Bwanakunu, Director-General, Medical Stores Department in Tanzania (MSD)</p>
                                            </div>
                                            <div className="col-sm-2" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                <strong>Auditorium: Selous</strong>
                                            </div>
                                            <div className="col-sm-3" style={{marginTop: "5px"}}>
                                                <span>19.30-21.00</span>
                                            </div>
                                            <div className="col-sm-7" style={{marginTop: "5px"}}>
                                            <strong>Welcome reception</strong><br/>
                                            <strong>Welcome remarks</strong><br/>
                                            Speakers:
                                            <ul>
                                              <li>Laurean Bwanakunu, Director-General, Medical Stores Department in Tanzania (MSD)</li>
                                              <li>Dancilla Mukarubayiza, Deputy Resident Representative, UNDP Tanzania</li>
                                            </ul>
                                            </div>
                                            <div className="col-sm-2" style={{marginTop: "5px",paddingBottom: "20px"}}>
                                                <strong>Lobby</strong>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="day">
                                    <h1 className="day-title">DAY 2 / 19 JULY</h1>
                                    <div className="max-width more-information"></div>
                                    <div className="programme-day" style={{height: "200px"}}>
                                        <div className="col-lg-2  col-md-2 col-sm-3 noPadding">
                                            <img src={config.API_URL + "/images/africa-forum-partners.png"} all="image"/>
                                            <img src={config.API_URL + "/images/africa-forum-people.png"} all="image" style={{marginTop: "10px"}}/>
                                            <img src={config.API_URL + "/images/africa-forum-peace.png"} all="image" style={{marginTop: "10px"}}/>
                                        </div>
                                        <div className="col-lg-1 col-md-1 col-sm-1"></div>
                                        <div className="col-lg-9 col-md-9 noPadding programme-table-lg">
                                            <div style={{margin:"25px 5px 25px 0"}}>
                                                <div className="col-sm-12" style={{marginTop: "5px"}}>
                                                    <h1>Forum Day 2: Friday, 19 July</h1>
                                                    <p><strong>SESSION CO-MODERATORS:</strong> Abdul Mwanja, Director of Procurement, Medical Stores Department (MSD) Tanzania</p>
                                                </div>
                                                <div className="col-sm-3" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                    <span>08.00-09.00</span>
                                                </div>
                                                <div className="col-sm-7" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                    <strong>Registration</strong>
                                                </div>
                                                <div className="col-sm-2" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                    <strong>Lobby</strong>
                                                </div>
                                                <div className="col-sm-3" style={{marginTop: "5px"}}>
                                                    <span>09.00-10.00</span>
                                                </div>
                                                <div className="col-sm-7" style={{marginTop: "5px"}}>
                                                <strong>1. PARTNERSHIP Dimension of Sustainable Development: Southern African Development Community (SADC) Pooled Procurement
        </strong><br/><p>Moderator: Abdul Mwanja, Director of Procurement, Medical Stores Department (MSD) Tanzania
        <br/>Speakers:</p><ul><li>Dr. Willy M. Amisi, Senior Program Officer - Health and Nutrition, Southern African Development Community (SADC)</li><li>Marco Masala, Southern African Development Community (SADC) Pooled Procurement Services Project Manager, Medical Stores Department (MSD) Tanzania</li></ul><p><b>Rapporteur: Alexandru Cocirta</b></p>
                                                </div>
                                                <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                    <strong>Auditorium: Selous</strong>
                                                </div>
                                                <div className="col-sm-3" style={{marginTop: "5px"}}>
                                                    <span>10.00-11.15</span>
                                                </div>
                                                <div className="col-sm-7" style={{marginTop: "5px"}}>
                                                <strong>2. Panel Discussion: Opportunities for sustainable production across the health supply chains (Protecting People and Planet)</strong><p>Moderator: Dr. Rosemary Kumwenda, UN Interagency on Sustainable Procurement in the Health Sector (SPHS) Coordinator</p><p>Setting the Stage:<br/> Dorothy Amony, Product Quality Officer, Global Drug Facility Team
        <br/><br/>Speakers:</p><ul><li>Lin Roger Li, Senior Manager, Strategic Sourcing, Sourcing and Supply Chain Department at the Global Fund</li><li>Mesiya&nbsp;Mwangoka, Board Director, UN Global Compact Local Network Tanzania and Founder and Managing Partner, Sustainability Practice</li><li>Natalia Korchakova-Heeb, Managing Director, SDG.17 Consulting GmbH and Member of the Executive Board, PPPinitiativeCoE4healthcare</li></ul><p><b>Rapporteur: Caoimhe Hughes</b></p>
                                                </div>
                                                <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                    <strong>Auditorium: Selous</strong>
                                                </div>
                                                <div className="col-sm-3" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                    <span>11.15-11.45</span>
                                                </div>
                                                <div className="col-sm-7" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                    <strong>Coffee &amp; Networking</strong>
                                                </div>
                                                <div className="col-sm-2" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                    <strong>Lobby</strong>
                                                </div>
                                                <div className="col-sm-3" style={{marginTop:"5px"}}>
                                                    <span>11.45-13.00</span>
                                                </div>
                                                <div className="col-sm-7" style={{marginTop:"5px"}}>
                                                <strong>3. Parallel sessions C: PEOPLE &amp; PEACE</strong><br/> Dimension of Sustainable Development
        <ul>
          <li><strong>C.1 Gender Equality through Women’s Empowerment</strong>
          <br/>
          <p>Why do we need to strengthen gender equality in the global health supply chains, and how do we do it?</p>
          <p>Moderator: Dr. Flora Myamba, Social Protection and Gender Expert, Women and Social Protection Tanzania
            <br/>Speakers:</p>
          <ul>
            <li>Dr. Margaret Agama-Anyetei, African Union</li>
            <li>Dr. Ave Maria Semakafu, Deputy Permanent Secretary for the Ministry of Education, Science and Technology, Tanzania</li>
            <li>Dr. Vicensia Shule, Chair, TGNP Mtandao</li>
          </ul>
          <p><b>Rapporteur: Dao Khanh Tung</b></p>
        </li>
      </ul>
                                                </div>
                                                <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                    <strong>Auditorium: Selous</strong>
                                                </div>
                                                <div className="col-sm-3" style={{marginTop:"5px"}}>

                                                </div>
                                                <div className="col-sm-7" style={{marginTop:"5px"}}>
                                                <ul><li><strong>C.2 Business Ethics and Sustainable Leadership:</strong><br/><p>Moderator: Elfatih Abdelraheem, HIV, Health and Development Policy Specialist and Team Leader for the Arab States, UNDP
                <br/>Speakers:</p><ul><li>Love Potya, East Africa Regional Director, Medical Technology Transfer and Services (MTTS) and Day One Health</li><li>Andriy Goy, Technical Director, Farmak JSC (Ukraine)</li><li>Santie Roy, Western Cape</li></ul><p><b>Rapporteur: Alexandru Cocirta</b></p></li></ul>
                                                </div>
                                                <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                    <strong>Breakout session room: Mikumi</strong>
                                                </div>
                                                <div className="col-sm-3" style={{marginTop:"5px"}}>

                                                </div>
                                                <div className="col-sm-7" style={{marginTop:"5px"}}>
                                                <ul><li><strong>C.3 Occupational Health &amp; Safety:</strong><p>What made you think of investing in Occupational Health and Safety?</p><p>Moderator: Susan Wilburn, Sustainability Director, Health Care Without Harm (HCWH)<br/>
                 Speakers:</p><ul><li>Dr. Lanina Ummulbanin Twaha, Medical doctor, Istiqaama Health Centre, Tanzania</li><li>Beatrice Giordani, International Relations Manager, Newster Group</li><li>Domician Mutayoba Dominic Principal Officer I Drug Control and Enforcement Authority, Tanzania</li></ul><p><b>Rapporteur: Jose Manuel Irizar</b></p></li></ul>
                                                </div>
                                                <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                    <strong>Breakout session rooms: Ruaha</strong>
                                                </div>
                                                <div className="col-sm-3" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                    <span>13.00-14.00</span>
                                                </div>
                                                <div className="col-sm-7" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                    <strong>Lunch Break</strong>
                                                </div>
                                                <div className="col-sm-2" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                    <strong>Canteen</strong>
                                                </div>
                                                <div className="col-sm-3" style={{marginTop: "5px"}}>
                                                    <span>14.00-15.30</span>
                                                </div>
                                                <div className="col-sm-7" style={{marginTop: "5px"}}>
                                                <strong>4. Sustainable Procurement Index for Health</strong><br/>
          Moderator: Dr. Kristian Steele, Associate, ARUP
          <p><b>Rapporteur: Caoimhe Hughes</b></p>
                                                </div>
                                                <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                    <strong>Auditorium</strong>
                                                </div>
                                                <div className="col-sm-3" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                    <span>15.30-16.00</span>
                                                </div>
                                                <div className="col-sm-7" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                    <strong>Coffee &amp; Networking</strong>
                                                </div>
                                                <div className="col-sm-2" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                    <strong>Lobby</strong>
                                                </div>
                                                <div className="col-sm-3" style={{marginTop: "5px"}}>
                                                    <span>16.00-17.30</span>
                                                </div>
                                                <div className="col-sm-7" style={{marginTop: "5px"}}>
                                                <strong>5. Closing Panel: Enabling Leadership for Sustainability</strong><br/><p>Moderator: Laurean Bwanakunu, Director-General, Medical Stores Department in Tanzania (MSD)
        <br/>Speakers:</p><ul><li>Dr. Nguyen Thanh Ha, Deputy Director General of Viet Nam Health Environment Management Agency</li><li>Lin Roger Li, Senior Manager, Strategic Sourcing, Sourcing and Supply Chain Department at the Global Fund</li><li>Chief Guest, Hon. January Y. Makamba, Minister of State, Vice President's Office (Environment and Union Matters), United Republic of Tanzania</li></ul><p><b>Rapporteurs: Natalya Lukyanova and Dao Khanh Tung</b></p>
                                                </div>
                                                <div className="col-sm-2" style={{marginTop: "5px", paddingBottom: "20px"}}>
                                                    <strong>Auditorium: Selous</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-sm-12 programme-table-upper">
                                        <div style={{margin:"25px 5px 25px 0"}}>
                                            <div className="col-sm-12" style={{marginTop: "5px"}}>
                                                <h1>Forum Day 2: Friday, 19 July</h1>
                                                <p><strong>SESSION CO-MODERATORS:</strong> Abdul Mwanja, Director of Procurement, Medical Stores Department (MSD) Tanzania</p>
                                            </div>
                                            <div className="col-sm-3" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                <span>08.00-09.00</span>
                                            </div>
                                            <div className="col-sm-7" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                <strong>Registration</strong>
                                            </div>
                                            <div className="col-sm-2" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                <strong>Lobby</strong>
                                            </div>
                                            <div className="col-sm-3" style={{marginTop: "5px"}}>
                                                <span>09.00-10.00</span>
                                            </div>
                                            <div className="col-sm-7" style={{marginTop: "5px"}}>
                                            <strong>1. PARTNERSHIP Dimension of Sustainable Development: Southern African Development Community (SADC) Pooled Procurement
    </strong><br/><p>Moderator: Abdul Mwanja, Director of Procurement, Medical Stores Department (MSD) Tanzania
    <br/>Speakers:</p><ul><li>Dr. Willy M. Amisi, Senior Program Officer - Health and Nutrition, Southern African Development Community (SADC)</li><li>Marco Masala, Southern African Development Community (SADC) Pooled Procurement Services Project Manager, Medical Stores Department (MSD) Tanzania</li></ul><p><b>Rapporteur: Alexandru Cocirta</b></p>
                                            </div>
                                            <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                <strong>Auditorium: Selous</strong>
                                            </div>
                                            <div className="col-sm-3" style={{marginTop: "5px"}}>
                                                <span>10.00-11.15</span>
                                            </div>
                                            <div className="col-sm-7" style={{marginTop: "5px"}}>
                                            <strong>2. Panel Discussion: Opportunities for sustainable production across the health supply chains (Protecting People and Planet)</strong><p>Moderator: Dr. Rosemary Kumwenda, UN Interagency on Sustainable Procurement in the Health Sector (SPHS) Coordinator</p><p>Setting the Stage:<br/> Dorothy Amony, Product Quality Officer, Global Drug Facility Team
    <br/><br/>Speakers:</p><ul><li>Lin Roger Li, Senior Manager, Strategic Sourcing, Sourcing and Supply Chain Department at the Global Fund</li><li>Mesiya&nbsp;Mwangoka, Board Director, UN Global Compact Local Network Tanzania and Founder and Managing Partner, Sustainability Practice</li><li>Natalia Korchakova-Heeb, Managing Director, SDG.17 Consulting GmbH and Member of the Executive Board, PPPinitiativeCoE4healthcare</li></ul><p><b>Rapporteur: Caoimhe Hughes</b></p>
                                            </div>
                                            <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                <strong>Auditorium: Selous</strong>
                                            </div>
                                            <div className="col-sm-3" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                <span>11.15-11.45</span>
                                            </div>
                                            <div className="col-sm-7" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                <strong>Coffee &amp; Networking</strong>
                                            </div>
                                            <div className="col-sm-2" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                <strong>Lobby</strong>
                                            </div>
                                            <div className="col-sm-3" style={{marginTop:"5px"}}>
                                                <span>11.45-13.00</span>
                                            </div>
                                            <div className="col-sm-7" style={{marginTop:"5px"}}>
                                            <strong>3. Parallel sessions C: PEOPLE &amp; PEACE</strong><br/> Dimension of Sustainable Development
    <ul>
      <li><strong>C.1 Gender Equality through Women’s Empowerment</strong>
      <br/>
      <p>Why do we need to strengthen gender equality in the global health supply chains, and how do we do it?</p>
      <p>Moderator: Dr. Flora Myamba, Social Protection and Gender Expert, Women and Social Protection Tanzania
        <br/>Speakers:</p>
      <ul>
        <li>Dr. Margaret Agama-Anyetei, African Union</li>
        <li>Dr. Ave Maria Semakafu, Deputy Permanent Secretary for the Ministry of Education, Science and Technology, Tanzania</li>
        <li>Dr. Vicensia Shule, Chair, TGNP Mtandao</li>
      </ul>
      <p><b>Rapporteur: Dao Khanh Tung</b></p>
    </li>
  </ul>
                                            </div>
                                            <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                <strong>Auditorium: Selous</strong>
                                            </div>
                                            <div className="col-sm-3" style={{marginTop:"5px"}}>

                                            </div>
                                            <div className="col-sm-7" style={{marginTop:"5px"}}>
                                            <ul><li><strong>C.2 Business Ethics and Sustainable Leadership:</strong><br/><p>Moderator: Elfatih Abdelraheem, HIV, Health and Development Policy Specialist and Team Leader for the Arab States, UNDP
            <br/>Speakers:</p><ul><li>Love Potya, East Africa Regional Director, Medical Technology Transfer and Services (MTTS) and Day One Health</li><li>Andriy Goy, Technical Director, Farmak JSC (Ukraine)</li><li>Santie Roy, Western Cape</li></ul><p><b>Rapporteur: Alexandru Cocirta</b></p></li></ul>
                                            </div>
                                            <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                <strong>Breakout session room: Mikumi</strong>
                                            </div>
                                            <div className="col-sm-3" style={{marginTop:"5px"}}>

                                            </div>
                                            <div className="col-sm-7" style={{marginTop:"5px"}}>
                                            <ul><li><strong>C.3 Occupational Health &amp; Safety:</strong><p>What made you think of investing in Occupational Health and Safety?</p><p>Moderator: Susan Wilburn, Sustainability Director, Health Care Without Harm (HCWH)<br/>
             Speakers:</p><ul><li>Dr. Lanina Ummulbanin Twaha, Medical doctor, Istiqaama Health Centre, Tanzania</li><li>Beatrice Giordani, International Relations Manager, Newster Group</li><li>Domician Mutayoba Dominic Principal Officer I Drug Control and Enforcement Authority, Tanzania</li></ul><p><b>Rapporteur: Jose Manuel Irizar</b></p></li></ul>
                                            </div>
                                            <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                <strong>Breakout session rooms: Ruaha</strong>
                                            </div>
                                            <div className="col-sm-3" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                <span>13.00-14.00</span>
                                            </div>
                                            <div className="col-sm-7" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                <strong>Lunch Break</strong>
                                            </div>
                                            <div className="col-sm-2" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                <strong>Canteen</strong>
                                            </div>
                                            <div className="col-sm-3" style={{marginTop: "5px"}}>
                                                <span>14.00-15.30</span>
                                            </div>
                                            <div className="col-sm-7" style={{marginTop: "5px"}}>
                                            <strong>4. Sustainable Procurement Index for Health</strong><br/>
      Moderator: Dr. Kristian Steele, Associate, ARUP
      <p><b>Rapporteur: Caoimhe Hughes</b></p>
                                            </div>
                                            <div className="col-sm-2" style={{marginTop: "5px"}}>
                                                <strong>Auditorium</strong>
                                            </div>
                                            <div className="col-sm-3" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                <span>15.30-16.00</span>
                                            </div>
                                            <div className="col-sm-7" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                <strong>Coffee &amp; Networking</strong>
                                            </div>
                                            <div className="col-sm-2" style={{backgroundColor: "#ebf0f3", marginTop: "5px"}}>
                                                <strong>Lobby</strong>
                                            </div>
                                            <div className="col-sm-3" style={{marginTop: "5px"}}>
                                                <span>16.00-17.30</span>
                                            </div>
                                            <div className="col-sm-7" style={{marginTop: "5px"}}>
                                            <strong>5. Closing Panel: Enabling Leadership for Sustainability</strong><br/><p>Moderator: Laurean Bwanakunu, Director-General, Medical Stores Department in Tanzania (MSD)
    <br/>Speakers:</p><ul><li>Dr. Nguyen Thanh Ha, Deputy Director General of Viet Nam Health Environment Management Agency</li><li>Lin Roger Li, Senior Manager, Strategic Sourcing, Sourcing and Supply Chain Department at the Global Fund</li><li>Chief Guest, Hon. January Y. Makamba, Minister of State, Vice President's Office (Environment and Union Matters), United Republic of Tanzania</li></ul><p><b>Rapporteurs: Natalya Lukyanova and Dao Khanh Tung</b></p>
                                            </div>
                                            <div className="col-sm-2" style={{marginTop: "5px", paddingBottom: "20px"}}>
                                                <strong>Auditorium: Selous</strong>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sponsors text-center partners ">
                            <a className="anchor-link" name="asia-forum-intro-anchor-3">&nbsp;</a>
                            <a className="anchor-link" name="asia-forum-intro-anchor-4">&nbsp;</a>
                            <img src={config.API_URL + "/images/org/0c1afa.png?v1.0"} alt="sponsors"/>
                                <h3 className="text-gray">
                                            We thank all our partners for their<br/> generosity and dedication to our mission.
                                </h3>
                                <div className="sponsors-box-fixed">
                                    <h5>
                                        The Forum is organized as part of “Strengthening Sustainability in the Health Sector’ Project Supported by SIDA and UNDP  in collaboration with Tanzania Ministry of Health and the Medical Stores Department.
                                    </h5>
                                    <div className="col-sm-3 col-xs-6 noPadding">
                                        <a href="https://www.sida.se/English/" target="_blank" rel="noopener noreferrer"><img src={config.API_URL + "/images/org/1f2cd4.png?v1.0"} alt="Image"/></a>
                                    </div>
                                    <div className="col-sm-3 col-xs-6 noPadding">
                                        <a href="http://www.undp.org" target="_blank" rel="noopener noreferrer"><img src={config.API_URL + "/images/org/0b5e59.jpeg?v1.0"} alt="Image"/></a>
                                    </div>
                                    <div className="col-sm-3 col-xs-6 noPadding">
                                        <a href="https://noharm.org" target="_blank" rel="noopener noreferrer"><img src={config.API_URL + "/images/org/d5cb89.jpeg?v1.0"} alt="Image"/></a>
                                    </div>
                                    <div className="col-sm-3 col-xs-6 noPadding"><a href="" target="_blank"><img src={config.API_URL + "/images/org/962de4.png?v1.0"} alt="Image"/></a></div>
                                </div>
                            </div>
                            <a className="anchor-link" name="media">&nbsp;</a>
                            <div className="about" style={{padding: "50px 0 0 0"}}>
                              <div className="col-md-8 col-md-offset-2 text-center">
                                <h1>Logistics</h1>
                                <a style={{display: "inline", marginRight: 20}} className="background-lightblue blue-button text-white text-uppercase" target="_blank" href="/media/Global_Forum_2019_Logistics_Note_v2.pdf">Logistics Note</a>
                                  <a style={{display: "inline"}} className="background-lightblue blue-button text-white text-uppercase" target="_blank" href="/media/Africa_Forum_2019_List_of_Hotels.pdf">List of hotels</a>
                                </div>
                            </div>
                            <div className="map" style={{background: "#ebf0f3"}}><iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15846.642979134007!2d39.2921311!3d-6.8110559!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5e80e2765959908e!2sJulius+Nyerere+International+Conference+Centre!5e0!3m2!1sen!2sbg!4v1550277865290" style={{border:0, marginTop: 80, height: 300, border: 0, width:"100%"}}></iframe></div>
                            <Footer/>
                        </div>
                    </Grid>
                </Grid>
              </div>
            </div>

        );
    }
}
AfricaForum.propTypes = {
    getLogActivityWithGeoIp: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
        //   member: state.member
    });

export default connect(mapStateToProps, {getLogActivityWithGeoIp})(AfricaForum);
