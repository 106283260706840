 export default function getRoutes() {
    return [

         {
            title: [],
            path: '/admin/accounts/list/:id',
            gridRoute: '/api/admin/accounts-list/grid/',
            gridDataRoute: '/api/admin/accounts-list/data/'
        },
        {
            title: ['Collaborations'],
           path: '/admin/collaborations',
            gridRoute: '/api/admin/collaborations-list/grid/properties',
            gridDataRoute: '/api/admin/collaborations-list'
        },
        {
            title: ['Public contributions'],
            path: '/admin/contributions/review',
            gridRoute: '/api/admin/contributions-list/1/grid/properties',
            gridDataRoute: '/api/admin/contributions-list/1'
        },
        {
            title: ['SPHS contributions'],
            path: '/admin/contributions',
            gridRoute: '/api/admin/contributions-list/0/grid/properties',
            gridDataRoute: '/api/admin/contributions-list/0'
        },
        {
            title: ['News'],
            path: '/admin/news',
            gridRoute: '/api/admin/news-list/grid/properties',
            gridDataRoute: '/api/admin/news-list'
        },
         {
            title: ['Authors'],
            path: '/admin/news-authors',
            gridRoute: '/api/admin/authors-list/grid/properties',
            gridDataRoute: '/api/admin/authors-list'
        },
         {
            title: ['Categories'],
            path: '/admin/news-categories',
            gridRoute: '/api/admin/categories-list/grid/properties',
            gridDataRoute: '/api/admin/categories-list'
        },
        {
            title: ['Users Active'],
            path: '/admin/users/active',
            gridRoute: '/api/admin/users-list/0/grid/properties',
            gridDataRoute: '/api/admin/users-list/0'
        },
        {
            title: ['Users Deleted'],
            path: '/admin/users/deleted',
            gridRoute: '/api/admin/users-list/1/grid/properties',
            gridDataRoute: '/api/admin/users-list/1'
        },
        {
            title: ['Template emails'],
            path: '/admin/templates/emails',
            gridRoute: '/api/admin/templates-email/grid/properties',
            gridDataRoute: '/api/admin/templates-email'
        },
         {
            title: ['Codes'],
            path: '/admin/analysis-codes',
            gridRoute: '/api/admin/codes-list/grid/properties',
            gridDataRoute: '/api/admin/codes-list'
        },
         {
            title: ['Categories'],
            path: '/admin/analysis-categories',
            gridRoute: '/api/admin/category-list/grid/properties',
            gridDataRoute: '/api/admin/category-list'
        },
         {
            title: ['Members'],
            path: '/admin/members',
            gridRoute: '/api/admin/members-list/grid/properties',
            gridDataRoute: '/api/admin/members-list'
        },
        {
            title: ['SDG'],
            path: '/admin/sdgs',
            gridRoute: '/api/admin/sdgs-list/grid/properties',
            gridDataRoute: '/api/admin/sdgs-list'
        },
        {
            title: ['Locations'],
            path: '/admin/locations',
            gridRoute: '/api/admin/locations-list/grid/properties',
            gridDataRoute: '/api/admin/locations-list'
        },
        {
            title: ['Focus areas'],
            path: '/admin/focus-areas',
            gridRoute: '/api/admin/focus-areas/grid/properties',
            gridDataRoute: '/api/admin/focus-areas'
        },
         
        {
            title: ['Lists'],
            path: '/admin/account-lists',
            gridRoute: '/api/admin/lists-account/grid/properties',
            gridDataRoute: '/api/admin/lists-account'
        },
        {
            title: ['Notifications'],
            path: '/admin/notifications',
            gridRoute: '/api/admin/notifications-list/property/grid',
            gridDataRoute: '/api/admin/notifications-list/all'
        },
        {
            title: ['Data retention'],
            path: '/admin/data-retention',
            gridRoute: '/api/admin/data-retention/grid/properties',
            gridDataRoute: '/api/admin/data-retention/accounts'
        },
        {
            title: ['Document Management'],
            path: '/admin/documents',
            gridRoute: '/api/admin/files-list/properties/1/grid/0',
            gridDataRoute: '/api/admin/files-list/grid/1'
        },
         {
            title: ['Resources'],
            path: '/admin/resources',
            gridRoute: '/api/admin/files-list/properties/0/grid/0',
            gridDataRoute: '/api/admin/files-list/grid/0'
        },
        {
            title: ['Deleted Files'],
            path: '/admin/documents/deleted',
            gridRoute: '/api/admin/files-list/properties/0/grid/1',
            gridDataRoute: '/api/admin/files-list-deleted/grid'
        },
         {
            title: ['Questionnaires'],
            path: '/admin/questionnaire/active',
            gridRoute: '/api/admin/quest-list/0/grid/properties',
            gridDataRoute: '/api/admin/quest-list/0/all'
        },
           {
            title: ['Deleted Questionnaires'],
            path: '/admin/questionnaire/deleted',
            gridRoute: '/api/admin/quest-list/1/grid/properties',
            gridDataRoute: '/api/admin/quest-list/1/all'
        },
        {
            title: ['Questionnaire Results'],
            path: '/admin/quest-results',
            gridRoute: '/api/admin/quest-result/properties',
            gridDataRoute: '/api/admin/quest-result/data'
        },
         {
            title: ['Products'],
            path: '/admin/products',
            gridRoute: '/api/admin/products-list/grid/properties',
            gridDataRoute: '/api/admin/products-list'
        },
        {
            title: ['Products Categories'],
            path: '/admin/products-category',
            gridRoute: '/api/admin/products-category/grid/properties',
            gridDataRoute: '/api/admin/products-category'
        },
        {
            title: ['Measures'],
            path: '/admin/measures',
            gridRoute: '/api/admin/measures-list/grid/properties',
            gridDataRoute: '/api/admin/measures-list'
        },
        {
            title: ['Suppliers'],
            path: '/admin/suppliers',
            gridRoute: '/api/admin/suppliers-list/grid/properties',
            gridDataRoute: '/api/admin/suppliers-list'
        },
        {
            title: ['Purchases'],
            path: '/admin/purchases',
            gridRoute: '/api/admin/purchases-list/grid/properties',
            gridDataRoute: '/api/admin/purchases-list'
        },
          {
            title: ['Procurements'],
            path: '/admin/procurements',
            gridRoute: '/api/admin/procurements-list/grid/properties',
            gridDataRoute: '/api/admin/procurements-list'
        },
       /*  {
            title: ['UN SPHS Health Procurement Report'],
            path: '/admin/report/health-procurements',
            gridRoute: '/api/admin/health-procurements-list/grid/properties',
            gridDataRoute: '/api/admin/health-procurements-list'
        },*/
        {
            title: ['UN-SPHS Total Health Procurement Volumes'],
            path: '/admin/report/health-procurements',
            gridRoute: '/api/admin/supplier-profile-report/grid/properties',
            gridDataRoute: '/api/admin/supplier-profile-report'
        },
         {
            title: ['SA Comprehensive Report'],
            path: '/admin/report/financial',
            gridRoute: '/api/admin/financial-report/grid/properties',
            gridDataRoute: '/api/admin/financial-report'
        },
         {
            title: ['Users sessions'],
            path: '/admin/users-sessions/list',
            gridRoute: '/api/admin/user-session/grid/properties',
            gridDataRoute: '/api/admin/user-session'
        },
         {
            title: ['Users activity'],
            path: '/admin/user-activity/list/:id',
            gridRoute: '/api/admin/user-activity/grid/properties/',
            gridDataRoute: '/api/admin/user-activity/'
        },
         {
            title: ['Scores by UNSPSC product family'],
            path: '/admin/report/unspsc-product-family',
            gridRoute: '/api/admin/unspsc-product-family/grid/properties',
            gridDataRoute: '/api/admin/unspsc-product-family'
        },
         {
            title: ['Individual Suppliers Report'],
            path: '/admin/report/individual-suppliers',
            gridRoute: '/api/admin/individual-suppliers-report/grid/properties',
            gridDataRoute: '/api/admin/individual-suppliers-report'
        },
         {
            title: ['Website activity by user'],
            path: '/admin/logger-user/list/:id',
            gridRoute: '/api/admin/user-log-activity/grid/properties/',
            gridDataRoute: '/api/admin/user-log-activity/'
        },
         {
            title: ['User tracking'],
            path: '/admin/user-track/list',
            gridRoute: '/api/admin/user-track/grid/properties',
            gridDataRoute: '/api/admin/user-track'
        },
        {
            title: ['Website usage'],
            path: '/admin/website-usage/list',
            gridRoute: '/api/admin/website-usage/grid/properties',
            gridDataRoute: '/api/admin/website-usage'
        },
        {
            title: ['Website tools usage'],
            path: '/admin/website-tools-usage/list',
            gridRoute: '/api/admin/website-tools-usage/grid/properties',
            gridDataRoute: '/api/admin/website-tools-usage'
        },
         {
            title: ['All suppliers score'],
            path: '/admin/report/all-scores-report',
            gridRoute: '/api/admin/all-scores-report/grid/properties',
            gridDataRoute: '/api/admin/all-scores-report'
        },
        {
            title: ['Supplier country'],
            path: '/admin/report/supplier-country-report',
            gridRoute: '/api/admin/supplier-country-report/grid/properties',
            gridDataRoute: '/api/admin/supplier-country-report'
        },
        {
            title: ['Scores by UNSPSC product segment'],
            path: '/admin/report/unspsc-product-segment',
            gridRoute: '/api/admin/unspsc-product-segment/grid/properties',
            gridDataRoute: '/api/admin/unspsc-product-segment'
        },
         {
            title: ['Members Averages Report'],
            path: '/admin/report/members-averages-report',
            gridRoute: '/api/admin/members-averages-report/grid/properties',
            gridDataRoute: '/api/admin/members-averages-report'
        },
        {
            title: ['Website tools inactive users'],
            path: '/admin/website-tools-inactive/list',
            gridRoute: '/api/admin/website-tools-inactive/grid/properties',
            gridDataRoute: '/api/admin/website-tools-inactive'
        },
         {
            title: ['SA EQ Report'],
            path: '/admin/report/oet-eq-report',
            gridRoute: '/api/admin/oet-eq-report/grid/properties',
            gridDataRoute: '/api/admin/oet-eq-report'
        },
         {
            title: ['EQ MC Results report'],
            path: '/admin/report/mc-report',
            gridRoute: '/api/admin/questionnaire/mc-report/properties',
            gridDataRoute: '/api/admin/questionnaire/mc-report'
        },
         {
            title: ['UNDP GPU Conventions'],
            path: '/admin/report/conventions-report',
            gridRoute: '/api/admin/conventions-report/grid/properties',
            gridDataRoute: '/api/admin/conventions-report'
        },
          {
            title: ['Questionnaire Partitions'],
            path: '/admin/partitions',
            gridRoute: '/api/admin/partition-list/grid/properties',
            gridDataRoute: '/api/admin/partition-list'
        }
    ];
}
