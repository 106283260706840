import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import {onSelect2InputChange, onAddSelectOption} from '../../../../actions/formActions';
import InputLabel from '@material-ui/core/InputLabel'
import {renderFromHelper} from '../../../common/MaterialUiForm'


class Select2 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: null,
            options: []
        }
    }

    componentDidMount() {

        if ((!this.props.input.value && this.props.input.value !== 0 && this.props.input.value !== false)&& (!this.props.field.settings || !this.props.field.settings.emptyOption) && this.props.field.options.length > 0) {
               this.props.input.onChange(this.props.field.options[0]);
               this.setState({
                    value: this.props.field.options[0]
                })
        }
       if(this.props.input.value){
            this.setState({
                    value: this.props.input.value
                })
       }
      
        if(this.props.options && this.props.options[0]){            
            this.setState({
                options: this.props.options
            })
        }

    }

     componentWillReceiveProps(nextProps) {

        if(this.props.field.dependsOn && nextProps.form.initializeFromState2.values && nextProps.form.initializeFromState2.values[this.props.field.dependsOn] && nextProps.form.initializeFromState2.values[this.props.field.dependsOn].value){

            this.setState({
                    options: this.props.options[nextProps.form.initializeFromState2.values[this.props.field.dependsOn].value]
                })
        }

        if(nextProps.options && !this.props.field.dependsOn){
            this.setState({
                options: nextProps.options
            })
        }
     }

    onChange(newValue, actionMeta) {

        if(newValue){
                this.setState({
                    value: newValue
                })
                 this.props.input.onChange(newValue);

        } else {
            this.props.input.onChange('');
             this.setState({
                    value: ''
                })
        }
    }



    render() {
    const { touched, error } = this.props.meta;

       return (<span>
          <InputLabel  style={{
          fontSize: 14,
          marginTop: 15,
          marginBottom: 16,
          lineHeight: 1.5,
          textTransform: "uppercase",
          fontFamily: 'OpenSans ,sans-serif',
          color: "#333333"
        }} >{this.props.field.label}</InputLabel>
              <Select
                value={this.state.value}
                onChange={(value) => this.onChange(value)}
                onBlurResetsInput={false}
                onCloseResetsInput={false}
                isDisabled={this.props.field.disabled ? this.props.field.disabled : false}
                menuPosition= {this.props.field.position}
                menuPlacement={"auto"}
                onFocus={this.props.input.onFocus}
                options={this.state.options !== [] ? this.state.options : this.props.options}
                isMulti={this.props.field.isMulti}
                isClearable={true}
            />

                {error && error !== "undefined" && touched && (
           <span>{renderFromHelper({ touched, error })}</span>
          )}
        </span>);
    }
}

Select2.defaultProps = {
    onNewOptions: () => {},
};

const mapStateToProps = state => ({
    formData:  state.formData ? state.formData : [],
    form: state.form
});

const mapDispatchToProps = dispatch => ({
    onInputChange: (formId, id, value, route) => dispatch(onSelect2InputChange(formId, id, value, route)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Select2);
