import React, { Component }  from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form'
import { renderTextField, renderSelectField, renderCheckbox } from '../common/MaterialUiForm'
import CheckboxGroup from '../common/CheckboxGroup'
import Grid from '@material-ui/core/Grid';
import DropZoneFields from "../file/DropZoneFields";
import { ToastContainer, toast } from 'react-toastify'
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css'
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import IntegrationReactSelect from '../common/IntegrationReactSelect'
import Link from '@material-ui/core/Link';
import Select2Creatable from '../common/Select2Creatable'
import moment from 'moment'


const styles = theme => ({
  root: {
    htmlFontSize: 20,
  }
});

const validate = values => {
  const errors = {}
  const requiredFields = [
    'firstName',
    'lastName',
    'email',
    'organization',
    'position',
    'country',
    'expertise',
    'interests',
    'termsStatus',
    'privacyStatus',
    'avatar'
  ]
  requiredFields.forEach(field => {
    if (!values[field] || (Array.isArray(values[field]) && !values[field].length > 0) ){
      errors[field] = 'This value should not be blank.'
    }
  })
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'Invalid email address'
  }
  return errors
}

class ContributeRegister extends Component {
    constructor(props) {
    super(props);
        this.state = {
         files: [],
         orgTypes: ['foo', 'bar']
        }
}

  componentDidMount() {

      if(this.props.user && this.props.user.avatarPreview){

      let image = {
            name: this.props.user.avatarName,
            preview: this.props.user.avatarPreview,
            size: this.props.user.avatarSize,
            type: this.props.user.avatarType
      }

          this.setState({
              files: [image]
          });
      }
  }

 handleOnDrop = (newImageFile, onChange) => {

 if(newImageFile.length <= 0){
     toast.error('The file must be image under 2MB!')

 }else{
      toast.success('File uploaded successfully')

     const imageFile = {
        file: newImageFile[0],
        name: newImageFile[0].name,
        preview: URL.createObjectURL(newImageFile[0]),
        size: newImageFile[0].size,
        type: newImageFile[0].type
      };

      let files = this.state.files;
      files[0] = imageFile;
     // files.push(imageFile)

      this.setState({ files: files }, () => onChange(files));
 }
  };

   handleOnDelete = (e, onChange) => {
      e.preventDefault()

     let files = this.state.files;
     files.map((fileObj, key) => {
        if(fileObj.preview === e.target.value){
            files.splice(key, 1)
        }
      })

      this.setState({ files: files }, () => onChange(files));

  }

  handleChange = (files) => {

  }

    setStyleOnOpen = () => {
        let scrollElement = document.getElementsByTagName("html");
        scrollElement[0].style.overflow='hidden';
    }

    setStyleOnClose = () => {
          let scrollElement = document.getElementsByTagName("html");
          scrollElement[0].style.overflow='auto';scrollElement[0].style.overflowX='hidden';
    }

  resetForm = () => this.setState({ imageFile: [] }, () => this.props.reset());


render () {
  const { handleSubmit, pristine, reset, submitting, classes } = this.props;
  const { registerData, isAuthenticated } = this.props.auth;

  const { profile } = this.props.profile;
  if(this.props.userData){
    this.props.initialize(this.props.userData)
  }

  return (
   <Grid container className="noPadding" direction="row">
    <form onSubmit={handleSubmit} style={{width:"100%"}}>
 <Grid container  direction="row" className="registerContibute-form m-bot30">
 <Grid md={1} lg={1} item></Grid>
      <Grid md={5} lg={5} xs={12} item className={classNames("p-lr15" , classes.root)}>

        <Field
          name="firstName"
          id="outlined-dense"
          className="textField"
          component={renderTextField}
          label="First Name *"
          variant="outlined"
          FormHelperTextProps={{
          classes:{
              root: classNames("labelRoot", "textRoot")
            }
          }}
          />
          <span>
            <p className="topEmptySpace"></p>
          </span>
        <Field
          name="lastName"
          className={classNames("textField")}
          component={renderTextField}
          label="Last Name *"
          variant="outlined"
          FormHelperTextProps={{
          classes:{
              root: classNames("labelRoot", "textRoot")
            }
          }}
        />
        <Field
          name="email"
          className={classNames("textField")}
          component={renderTextField}
          label="Email *"
          variant="outlined"
          FormHelperTextProps={{
          classes:{
              root: classNames("labelRoot", "textRoot")
            }
          }}
        />
            {registerData.title &&
        <Field
          classes={classes}
          name="title"
          component={renderSelectField}
          onOpen={this.setStyleOnOpen}
          onClose={this.setStyleOnClose}
          label="Title"
          variant="outlined"
          SelectDisplayProps={{style: {
            textAlign: "left",
            lineHeight: "36px",
            fontSize: 14
            }
          }}

        >
          {registerData.title}
        </Field>
        }
               {registerData.countries &&
        <Field
          classes={classes}
          name="country"
          component={renderSelectField}
          onOpen={this.setStyleOnOpen}
          onClose={this.setStyleOnClose}
          label="Country *"
          variant="outlined"
          SelectDisplayProps={{style: {
            textAlign: "left",
            lineHeight: "36px",
            fontSize: 14
            }
          }}
        >
          {registerData.countries}
        </Field>
        }
         <Field
            name="city"
            className={classNames("textField")}
            component={renderTextField}
            label="City"
            variant="outlined"
            variant="outlined"
          />
         <Field
            name="address"
            className={classNames("textField")}
            component={renderTextField}
            label="Address"
            variant="outlined"
          />
          <Field
            name="phone"
            className={classNames("textField")}
            component={renderTextField}
            label="Phone"
            variant="outlined"
          />
          <Field
            name="skype"
            className={classNames("textField")}
            component={renderTextField}
            label="Skype"
            variant="outlined"
          />
          <Field
            name="linkedin"
            className={classNames("textField")}
            component={renderTextField}
            label="Linkedin"
            variant="outlined"
          />
          <Field
            name="website"
            className={classNames("textField")}
            component={renderTextField}
            label="Personal website"
            variant="outlined"
          />
       <div className="pull-left" style={{margin: '20px 20px 20px 0px'}}>
       <Field
        name="avatar"
        label="PHOTO (UP TO 2MB) *"
        component={DropZoneFields}
        type="file"
        files={this.state.files}
        value={this.state.files}
        multiple={false}
        handleOnDrop={this.handleOnDrop}
        handleOnDelete={this.handleOnDelete}
        onChange={files => this.handleChange(this.state.files)}
        preview={true}
        />
       </div>
      </Grid>

       <Grid md={5} lg={5} xs={12} item className="p-lr15">
       {registerData.organization &&
       <Field
               name="organization"
               id="outlined-dense-first"
               type='text'
               component={Select2Creatable}
               label="Organization *"
               variant="outlined"
               className="textField"
               isMulti={false}
               field={registerData.organization}
               options={registerData.organization.options}
             />
             }
             <span>
               <p className="margin-t-15"><i>Please don't register an acronym of your company's name.</i></p>
             </span>

      {registerData.organizationType &&
        <Field
            name="organizationType"
            component={IntegrationReactSelect}
            options={registerData.organizationType}
            clearable={true}
            ismulti={true}
            label="Organization Type"
            form="MaterialUiForm"
          />

        }
         <Field
          name="department"
          className={classNames("textField")}
          component={renderTextField}
          label="Department"
          variant="outlined"
         />
         <Field
          name="position"
          className={classNames("textField")}
          component={renderTextField}
          label="Position *"
          variant="outlined"
          FormHelperTextProps={{
          classes:{
              root: classNames("labelRoot", "textRoot")
            }
          }}
         />
         <Field
            name="organizationAddress"
            className={classNames("textField")}
            component={renderTextField}
            label="Organization address"
            variant="outlined"
          />
         <Field
          name="organizationWebsite"
          className={classNames("textField")}
          component={renderTextField}
          label="Organization website"
          variant="outlined"
          />
         <Field
          name="memberships"
          className={classNames("textField")}
          component={renderTextField}
          label="Memberships"
          variant="outlined"
        />
         {registerData.signatory &&
        <Field
          classes={classes}
          name="signatory"
          component={renderSelectField}
          onOpen={this.setStyleOnOpen}
          onClose={this.setStyleOnClose}
          label="Global Compact Signatory"
          variant="outlined"
          SelectDisplayProps={{style: {
            textAlign: "left",
            lineHeight: "36px",
            fontSize: 14
            }
          }}
        >
          {registerData.signatory}
        </Field>
        }
        <Grid item container  direction="row" >
          <Grid item md={6} sm={12} xs={12} className={classNames("p-lr15" , classes.root)}>
              {registerData.expertise &&

                      <span>
              <label className="text-left text-uppercase" style={{marginTop: 16, marginBottom: 10}}>Expertise *</label>
              <CheckboxGroup name="expertise" options={registerData.expertise} />
                      </span>
              }
          </Grid>
          <Grid item md={6} sm={12} xs={12} className="p-lr15">
                 {registerData.interests &&
                      <span>
              <label className="text-left text-uppercase" style={{marginTop: 16, marginBottom: 10}}>Interests *</label>
                   <CheckboxGroup name="interests" options={registerData.interests}/>
                      </span>
              }
          </Grid>
        </Grid>
  </Grid>

      </Grid>
    <Grid item container  direction="row" className="register-footer">
        <Grid md={2} lg={2} item></Grid>
        <Grid md={8} lg={8} sm={10} xs={12} item className={classNames("p-lr15 center-col text-center" , classes.root)}>
          {!isAuthenticated &&
                  <span>
                  <div><h5 style={{marginBottom: 20, lineHeight: 1.7}}>Images and content uploaded to the SPHS Website have been cleared for public dissemination and meet other SPHS defined <br/> criteria as outlined in the <Link style={{ cursor: 'pointer', textDecoration: "none", color: "#1890ff"}} href="/terms-and-conditions">Site Terms</Link></h5>
            </div>
            <div className="col-md-7 col-md-offset-3">
            <Field
              name="termsStatus"
              component={renderCheckbox}
              label={<label>By registering I agree to the&nbsp;<a target="_blank" href='/terms-and-conditions'>Terms and Conditions</a></label>}
            />
            <Field
              name="privacyStatus"
              component={renderCheckbox}
              label={<label>By registering I confirm that I agree to the storing and processing of my personal data by UNDP as described in the <a target="_blank" href='/privacy-policy'>Privacy Policy</a>.</label>}
            />
            <Field
              name="subscribed"
              component={renderCheckbox}
              label={<label>Yes, I confirm that I would like to subscribe to the SPHS newsletter and receive communications.</label>}
            />
            </div>
            </span>
            }
            <div className="col-md-12">
              <button type="submit" disabled={pristine || submitting} style={{ display: 'inline', margin: 15}}>

              {!isAuthenticated ?
                       <span>Sign Up</span>
                       :
                      <span>Update</span>
              }
              </button>
            </div>
        </Grid>
      </Grid>
    </form>
    <div className="form-group">

</div>
     </Grid>
  );
  }
}


ContributeRegister.propTypes = {
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  //user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile,
    initialValues: state.profile.profile
});

ContributeRegister = withStyles(styles)(ContributeRegister)

export default connect(mapStateToProps, {})(reduxForm({form: 'ContributeRegister', validate})(ContributeRegister))
