import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCurrentProfile } from "../../actions/profileActions";
import { createThread } from "../../actions/forumActions";
import Spinner from "../common/Spinner";
import ProfileActions from "../dashboard/ProfileActions";
import Navbar from "../layout/Navbar";
import Grid from "@material-ui/core/Grid";
import logoSide from "../../img/logo-side-menu.png";
import ThreadForm from "../forms/ThreadForm";

class NewThread extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getCurrentProfile();
  }

  handleOnSubmit = values => {

     var body = new FormData();
    Object.keys(values).forEach(( key ) => {

        if(key === 'attachments'){
            values.attachments.map(file =>
                    body.append('attachments[]', file.file)
                        )

        }else if(key === 'members'){
           let orgArray = []
                   values[key].map(obj => (
              orgArray.push(obj.value)
  ))
        body.append(key, orgArray);
        }else if(key === 'contribution'){
             body.append(key, values[key]['value']);
            }else{
      body.append(key, values[ key ]);
  }
    });
    var role = null;
    if(this.props.profile.profile.roles.includes('ROLE_MEMBER')){
        role = 'member'
    }
    this.props.createThread(body, 'member', this.props.history, role)
  }

  render() {
    const { profile } = this.props.profile;
    let dashboardContent;
    //if (profile === null || loading) {
    if (profile === null) {
      dashboardContent = <Spinner />;
    } else {
      dashboardContent = <ProfileActions profileItem={profile} />;
    }

    return (
      <div className="container-fluid" >
        <div className="page-content slide page-animation">
      <Grid container spacing={0}>
        {dashboardContent}
        <Grid item xs={12} style={{ padding: "20px" }}>
          <div className="forum text-left noPadding">
            <h3 style={{ paddingBottom: "20px" }}>New thread</h3>
            <ul className="breadcrumb">
              <li>
                <Link to="/forum">Back to Forum</Link>{" "}
              </li>
              <li>
                <span>New thread</span>
              </li>
            </ul>
          </div>

          <Grid
            item
            xs={12}
            style={{
              backgroundColor: "#F2F2F2",
              padding: "10px",
              height: "100%"
            }}
          >
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: "white",
                paddingTop: "10px"
              }}
            >
              <ThreadForm onSubmit={this.handleOnSubmit} type="member"/>
            </Grid>
          </Grid>
        </Grid>
        <Navbar />
      </Grid>
    </div>
  </div>
    );
  }
}

NewThread.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  createThread: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  auth: state.auth
});

export default connect( mapStateToProps, { getCurrentProfile, createThread })(NewThread);
