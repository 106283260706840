import React, { Component } from 'react';
import Sectors from './Sectors'
import Sdgs from './Sdgs'
import Contribute from '../Contribute'
import Grid from '@material-ui/core/Grid'
import SocialNetworks from './SocialNetworks'
import SaveForLater from '../SaveForLater'
import LoginForm from '../../../layout/LoginForm'
import Diff from 'react-stylable-diff'

class ContributionStory extends Component {
  render() {
      const contribution = this.props.contribution;   
return (
        <div>
  
    <div className="col-md-12 right-header story" >

</div>

{contribution.sectors &&
        <Sectors sectors={contribution.sectors}/>
}
{contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor &&
<Diff type="sentences" inputA={contribution.reflection.sectorsText} inputB={contribution.sectorsText}/>
}
 <LoginForm role="filter"/>

<SocialNetworks url={contribution.href}/>

<SaveForLater id={contribution.id} type="contrib"/>

{contribution.sdg &&
<Sdgs sdgs={contribution.sdg}/>
}
{contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor &&
<Diff type="sentences" inputA={contribution.reflection.sdgText} inputB={contribution.sdgText}/>
}
{contribution.links &&
<div className="col-xs-12" >
    <h4 className="text-uppercase text-gray">website link</h4>
   {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor ?
          <Diff type="sentences" inputA={contribution.reflection.links} inputB={contribution.links}/>
         :
        <a href={contribution.links} target="_blank">{contribution.links}</a>
        }
</div>
}
</div>);

  }
}

export default ContributionStory;