import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {Field, reduxForm} from 'redux-form';
import IntegrationReactSelect from '../../common/IntegrationReactSelect'
import {renderDatePickerField, renderTextField, renderSelectField, renderCheckbox, renderTmcField, renderPasswordField, radioButton} from '../../common/MaterialUiForm'
import RadioButtonsGroup from '../../common/RadioButtonsGroup'
import Grid from '@material-ui/core/Grid';
import Select2 from '../form/types/Select2';
import Contacts from '../form/types/Contacts';
import Switch from '../form/types/Switch';
import Textarea from '../form/types/Textarea';
import DatePickerComponent from '../form/types/DatePickerComponent'
import DateTimeComponent from '../form/types/DateTimeComponent'
import { toast } from 'react-toastify'
import "../form/redux-forms.css";
import DropZoneComponent from '../../file/DropZoneComponent'
import CheckboxGroupField from '../../common/CheckboxGroupField'
import Select2Creatable from '../../common/Select2Creatable'
import Collection from '../form/types/Collection';
import Sections from '../form/types/Sections'
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

class FieldBuild extends Component {

  render() {
   const { field, formData } = this.props;
 let component = renderTextField;
                                let data = null;
                                if(field.type === 'hidden'){
                                    component =  'input';
                                }
                                if (field.type === 'text'){
                                    component = renderTextField;
                                }
                                if(field.type === 'select2'){
                                    component = Select2;
                                }
                                if(field.type === 'select'){
                                    component = Select2;
                                }
                                if(field.type === 'selectCreatable'){
                                    component = Select2Creatable;
                                }
                                if(field.type === 'collection'){
                                    component = Contacts;
                                    data = formData.data;
                                }
                                 if(field.type === 'collections'){
                                    component = Collection;
                                    data = formData.data;
                                }
                                if(field.type === 'sections'){
                                    component = Sections;
                                    data = formData.data;
                                }
                                 if(field.type === 'checkboxGroup'){
                                    component = CheckboxGroupField;
                                }
                                if(field.type === 'switch'){
                                    component = Switch;
                                }
                                if(field.type === 'textarea'){
                                    component = Textarea;
                                }
                                if(field.type === 'tmc'){
                                    component = renderTmcField;
                                }
                                if(field.type === 'tags'){
                                    component = IntegrationReactSelect;
                                }
                                if(field.type === 'datePicker'){
                                    component = DatePickerComponent;

                                }
                                if(field.type === 'datetimePicker'){
                                    component = DateTimeComponent;
                                }
                                if(field.type === 'radio'){
                                    component = RadioButtonsGroup;
                                }
                                if(field.type === 'password'){
                                    component = renderPasswordField;
                                }
                                let col = 6;
                               if(typeof field.col !== 'undefined'){
                                 col = field.col;
                               }
                               if(field.type === 'file'){
                                     component = DropZoneComponent;
                                     data = formData.data[field.id+'Files'];
                               }

                               if(field.type === 'checkbox'){
                                    component = renderCheckbox;
                                }
    return (

         <Grid md={12} lg={12} item container direction="row" >
                        <Grid item md={1} style={{marginTop: 30}}><DragIndicatorIcon style={{color: '#ccc'}}/></Grid>
                             <Grid item md={10} className="m-all20" style={{marginTop: 25}}>
                               <label>points: {field.points}</label> <h3>{field.header}</h3> 
                                    <Field
                                        data={data}
                                        name={field.id}
                                        field={field}
                                        change={this.changeFieldValue}
                                        component={component}
                                        type={field.type}
                                        hide={field.hide}
                                        placeholder={field.label}
                                        label={field.label}
                                        ismulti={field.ismulti ? field.ismulti : null}
                                        clearable={field.clearable ? field.clearable : null}
                                        options={field.options ? field.options : []}
                                        disabled={field.disabled ? field.disabled : false}
                                        id={field.id}
                                        collectionFields={field.collectionFields ? field.collectionFields : null}
                                        />
                                        
                                </Grid> </Grid>

    )
  }
  }

  FieldBuild.propTypes = {
    field: PropTypes.object.isRequired
  };

  export default FieldBuild;
