import React, { useState, useEffect }  from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import {getDescriptionAdmin, saveDescription} from '../../../actions/gridActions'
import EditIcon from '@material-ui/icons/Edit';

const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});
function Description(props) {
    const [open, setOpen] = useState(false);
    const [description, setDescription] = useState(null);
    const [id, setId] = useState();
    
    useEffect(() => {
    props.getDescriptionAdmin(props.type)
   }, []);
   
   useEffect(() => {
     if(props.questionnaire && props.questionnaire.reportDescription){
        setDescription(props.questionnaire.reportDescription.description)
        setId(props.questionnaire.reportDescription.id)
    }
    console.log('oks')
}, [props.questionnaire.reportDescription])

    function handleChangeDescription (event) {   
        setDescription(event.target.value)
    }
    
    function saveDescription () {        
       var route = 'api/admin/description/create/'
       if(id){
           route = route + id
       }
    props.saveDescription(route, {description: description, type: props.type})
    setOpen(false) 
    }
    
    function closeOption(){
       setOpen(false) 
    }
    
    return (
    
       <Grid container spacing={0} style={{background: 'white'}}>
            <Grid item md={12} lg={12} xs={12}  style={{ margin: 15}}>   
                <div>{description}<EditIcon fontSize="small" onClick={() => setOpen(true)}/></div>
            </Grid>
                <Dialog
                        onClose={() => setOpen(false)}
                        aria-labelledby="simple-dialog-title"
                        open={open}
                        maxWidth="sm"
                        fullWidth
                       >
                       <DialogTitle id="simple-dialog-title">Report Description<span className="pull-right pointer" onClick={closeOption}>
                                 <i className="far fa-times-circle"></i></span>
                                 </DialogTitle>
                         <DialogContent>
                         <section className="newPanel">
                            <div className="panel-body">
                                <div>
                                    <div className="text-center">
                                        <input name="description" 
                            type="text"
                            onChange={handleChangeDescription} 
                            value={description ? description : ''} 
                            style={{marginBottom: 15}}/>
                                       
                                       <Button
                                variant="outlined" 
                                color="primary" 
                                size="small"
                                onClick={saveDescription}>Save</Button> 
                                    </div>
                                </div>
                            </div>
                        </section>
                         </DialogContent>
                      </Dialog>
       </Grid>
      
    );

}

Description.propTypes = {
  questionnaire: PropTypes.object.isRequired,
  getDescriptionAdmin: PropTypes.func.isRequired,
  saveDescription: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  questionnaire: state.questionnaire,
});

Description = withStyles(styles)(Description)

export default connect( mapStateToProps, { getDescriptionAdmin, saveDescription })(withRouter(Description));
