import React, {useState} from 'react';
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';

function SimpleBarChart(props){
	
  	return (
    	<BarChart width={800} height={600} data={props.data}
            margin={{top: 20, right: 30, left: 20, bottom: 100}}>
       <CartesianGrid strokeDasharray="3 3"/>
       <XAxis angle={-45} textAnchor="end" dataKey="name" type="category" interval={0} minTickGap={50}/>        
       <YAxis type="number" domain={[0, 100]}/>
       <Tooltip/>
       <Legend verticalAlign="top" height={60} />       
       <Bar dataKey="section" fill="#82ca9d" />
      </BarChart>
    );  
}
export default SimpleBarChart;