import client from './client';
import { toast } from 'react-toastify';
import { logoutUser } from './authActions'
import setAuthToken from '../utils/setAuthToken';
import Cookies from 'js-cookie'
import axiosGeoIpApi from './axiosGeoIpApi'

import {
  GET_PROFILE,
  GET_MEMBER_PROFILE,
  GET_PROFILES,
  PROFILE_LOADING,
  CLEAR_CURRENT_PROFILE,
  GET_ERRORS,
  SET_CURRENT_USER,
  GET_ERRORS_REGISTRATION,
  GET_USER_TRACK_REPORT
} from './types';

// Get current profile
export const getCurrentProfile = () => dispatch => {
  dispatch(setProfileLoading());
  client
    .get('/api/user')
    .then(res =>
      dispatch({
        type: GET_PROFILE,
        payload: res.data
      })
    )
    .catch(err => {      
        dispatch(logoutUser({}));
       
      dispatch({
        type: CLEAR_CURRENT_PROFILE,
        payload: {}
      })
       window.location.href = '/'
  });
};


// Get profiles by user id
export const getProfilesByUserId = id => dispatch => {
  let route = `/api/user-members/members`;
  if(id){
    route=route+'?search='+id;
  }
  client
    .get(route)
    .then(res =>
      dispatch({
        type: GET_PROFILES,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_PROFILES,
        payload: null
      })
    );
};


// Get profile by
export const getProfileById = id => dispatch => {
  dispatch(setProfileLoading());
  client
    .get(`/api/user/${id}`)
    .then(res =>
      dispatch({
        type: GET_PROFILE,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_PROFILE,
        payload: null
      })
    );
};

// Get profile by
export const getMemberProfile = id => dispatch => {
  dispatch(setProfileLoading());
  client
    .get(`/api/user/member/${id}`)
    .then(res =>
      dispatch({
        type: GET_MEMBER_PROFILE,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_MEMBER_PROFILE,
        payload: null
      })
    );
};

// Profile loading
export const setProfileLoading = () => {
  return {
    type: PROFILE_LOADING
  };
};

// Clear profile
export const clearCurrentProfile = () => {
  return {
    type: CLEAR_CURRENT_PROFILE
  };
};

// Update profile
export const updateProfile = (userData, history) => dispatch => {
      
  client
    .post('/api/user', userData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
})
    .then(res => {        
        dispatch({
        type: GET_ERRORS_REGISTRATION,
        payload: []
      })
     const { message } = res.data;  
     toast.success(message, {autoClose: 10000})     
    })
    .catch(err => {
   if(err.response && err.response.data){
         const { error } = err.response.data;
      
       if(typeof error != 'undefined' && error.message){
           toast.error(error.message, {autoClose: 10000})
       }
      dispatch({
        type: GET_ERRORS_REGISTRATION,
        payload: err.response.data
      })
  }
    });
};

export const getLogActivityWithGeoIp = (action=null, method=null, type=null) => dispatch =>{
    axiosGeoIpApi
            .get("")
            .then(res =>{
       
getLogActivity(res.data, action, method, type)
    })
            .catch(err => {  
            });
}

// Get current profile
function getLogActivity(geoIpInfo, action=null, method=null, type=null) { 
   
  client
    .post('/web/request/log', {userSession: Cookies.get('userSession'), pathname: window.location.href, geoIpInfo: geoIpInfo, action: action, method: method, type: type})
    .then(res =>{
       
    })
    .catch(err => {  
  });
 
};

export const getUserTrackReportAdmin = (start, end) => dispatch =>{
    client
    .post('/api/admin/get-user-track-report', {start: start, end: end})
    .then(res =>{
         dispatch({
        type: GET_USER_TRACK_REPORT,
        payload: res.data
      })       
    })
    .catch(err => {  
  });
 
};

