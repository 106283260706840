import React, {Component} from 'react';
import {connect} from "react-redux";
import {Button, Dialog, DialogActions, DialogTitle, DialogContent} from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';
import { withStyles, Theme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    }
  });



class SpeakersModalSmall extends Component {

    constructor(props) {
        super(props);
        this.state = {
              open: false
        }
    }

    handleClickOpen = event => {
    event.preventDefault();
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({
        open: false
    });
  };

    render() {

   const { classes } = this.props;
    return (
          <span>

        <div className="speaker-slide">
          <a href="" onClick={this.handleClickOpen}  className="text-uppercase">
             <Avatar alt= {this.props.speaker.name} src={this.props.speaker.image} className={classes.large} />
          </a>
          <div className="speaker-info">
            <span className="speaker-name text-ellipsis">{this.props.speaker.name}</span>
            <span className="speaker-title text-ellipsis">{this.props.speaker.title}</span>
          </div>
        </div>
  <Dialog open={this.state.open}
          onClose={this.handleClose}>
       <DialogTitle className=" text-center" style={{ background: "#203648", color: 'white', fontSize: '16px', textAlign: 'center'}} disableTypography={true}>
           {this.props.speaker.name}</DialogTitle>
       <DialogContent style={{ textAlign: 'center', marginTop: '10px'}}>
           {this.props.speaker.bio}
           {this.props.speaker.links &&
                <div  dangerouslySetInnerHTML={{__html: this.props.speaker.links}} style={{margin: 15}}></div>
                    }
                    </DialogContent>
            <DialogActions>
            <Button onClick={this.handleClose} color="primary" style={{fontSize: 14}} className="btn btn-secondary">
              Close
            </Button>
        </DialogActions>
   </Dialog>
   </span>
   );
   }
   }
SpeakersModalSmall = withStyles(styles)(SpeakersModalSmall)
export default connect()(SpeakersModalSmall);
