import client from './client';
import moment from 'moment'
import {
  ADD_NOTIFICATION_TO_LIST,
  ADD_NOTIFICATION,
  GET_CURRENT_NOTIFICATIONS,
  COUNT_UNREAD,
  GET_ADMINS,
  CURRENT_RECEIVER
} from './types';
import { config } from '../config'

// Get current notifications
export const sendNotification = (data, socket) => dispatch => {  
       let url = '/api/admin/notifications-form/create/';
       if(typeof data.id !== 'undefined'){
           url = url + data.id
       }       
    //send to api and after success send to socket
    client
       .post(url, data)
       .then(res => {
           data = res.data
           data.serverType = ADD_NOTIFICATION  
           data.apiId = config.API_ID          
           socket.socket.send(JSON.stringify(data))
            dispatch({
              type: ADD_NOTIFICATION,
              payload: res.data
            })
        })
        .catch(err => {       
          console.log('error')
      });
    };

export const receivedNotification = (data) => dispatch => {  
    var dateTime = new Date();
    dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss"); 
   
    //if notification is for this user add or change notification
      dispatch({
        type: ADD_NOTIFICATION,
        payload: data
      })
};


export const getCurrentNotifications = (data) => dispatch => {  
    client
     .get('/api/admin/notifications-list/current/notifications')
     .then(res => {
        dispatch({
          type: GET_CURRENT_NOTIFICATIONS,
          payload: res.data.notifications
        })
        dispatch({
          type: COUNT_UNREAD,
          payload: res.data.notificationCount
        })
         dispatch({
          type: GET_ADMINS,
          payload: res.data.admins
        })
        dispatch({
          type: CURRENT_RECEIVER,
          payload: res.data.receiver
        })
      })
        .catch(err => {       
          console.log('error')       
    });
};

export const changeStatusNotification = (notification, status, socket) => dispatch => {  
       let url = '/api/admin/notifications-list/'+status+'/change/status';
        let  data = notification;    
      
    //send to api and after success send to socket
    client
       .post(url, {body: JSON.stringify(data.id)})
       .then(res => {
         
           data.serverType = ADD_NOTIFICATION  
           data.status = status
           socket.socket.send(JSON.stringify(data))
           
            dispatch({
              type: ADD_NOTIFICATION,
              payload: data
            })
        })
        .catch(err => {       
          console.log('error')   
  
  });
};

