import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';

class Sdgs extends Component {
    render() {

        return (

              <Grid  container spacing={2} className="noPadding sdgs" >
                <Grid item md={12} xs={12}>
                <h4 className="text-uppercase text-gray">Sustainable Development Goals</h4>
                </Grid>
               {this.props.sdgs.map(sdg => (
                <Grid item md={4} sm={3} xs={6} key={sdg.id}>
                
                    <img src={ sdg.photo} className={classNames( sdg.description, !sdg.active ? 'desaturate' : '') } title={ sdg.name +'-'+ sdg.description} alt={ sdg.name }/>
              
                </Grid>
                ))}

              </Grid>

                );
    }
}

export default Sdgs;
