import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";

class ComponentToPrint extends Component {   
    
    render() {     
    return (           
      <Grid container spacing={0} >    
          {this.props.children}
      </Grid>
    );
  }
}

export default ComponentToPrint;
