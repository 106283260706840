import client from './client';

import {
  GET_HELP_CATEGORIES,
  GET_HELP_CONTENT,
  DELETE_HELP_CATEGORY
} from './types';

// Get help categories
export const getHelpCategories = () => dispatch => {  
  client
    .get('/api/admin/help/categories')
    .then(res =>
      dispatch({
        type: GET_HELP_CATEGORIES,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_HELP_CATEGORIES,
        payload: {}
      })
       
  });
};


// Get help content
export const getHelpContent = (name) => dispatch => {
  client
    .get('/api/admin/help/content/'+name)
    .then(res =>
      dispatch({
        type: GET_HELP_CONTENT,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_HELP_CONTENT,
        payload: {}
      })
       
  });
};

// Get help categories
export const deleteHelpCategory = (id) => dispatch => {
          return new Promise((resolve, reject) => {
            client
                    .post('/api/admin/help/delete/'+id)
                    .then(response => response.data)
                    .then(response => {
                        dispatch({
                            type: DELETE_HELP_CATEGORY,
                            payload: response.data
                        });
                        resolve(response.data);
                    }
                    );
        })
};

