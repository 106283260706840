import React, { Component }  from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form'
import RadioButtonsGroup from '../../common/RadioButtonsGroup'
import CheckboxGroupField from '../../common/CheckboxGroupField'
import Grid from '@material-ui/core/Grid';
import { ToastContainer, toast } from 'react-toastify'
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';


const styles = theme => ({
  root: {
    htmlFontSize: 20,
  },
  label: {
    textTransform: 'none',
  },
  });

  const validate = values => {
  const errors = {}
  const requiredFields = [
    'organizationMain',
    'organizationMerge'
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'This value should not be blank.'
    }
  })

  return errors
}

class DuplicatedForm extends Component {
    constructor(props) {
     super(props);

     }

       render () {
  const { handleSubmit, pristine, reset, submitting, classes } = this.props;
  const options = Object.keys(this.props.duplicatedOrganizations).map(k => {
     var label = this.props.duplicatedOrganizations[k][this.props.type];
             if(this.props.type === 'email'){
                 label = this.props.duplicatedOrganizations[k]['firstName']+' '+this.props.duplicatedOrganizations[k]['lastName']+' - '+this.props.duplicatedOrganizations[k][this.props.type];
             }
      return {value: this.props.duplicatedOrganizations[k].id,
      label: label}
  })
  const accountType = this.props.type === 'companyName' ? 'organization' : 'contact';
  return (
 <Grid item md={12} lg={12} xs={12} className="duplicateRecords-form">
 <form className={classes.form} noValidate onSubmit={handleSubmit} style={{marginBottom: 30}}>
         <Field
           name="organizationMain"
           id="outlined-dense-first"
           type='text'
           component={RadioButtonsGroup}
           label={"Choose main "+accountType}
           variant="outlined"
           className="textField"
           options={options}
         />
         <hr/>
         <Field
           name="organizationMerge"
           id="outlined-dense-first"
           type='text'
           component={CheckboxGroupField}
           label={"Choose "+accountType+"s to merge"}
           variant="outlined"
           className="textField"
           options={options}
         />
      <DialogActions  style={{justifyContent: 'center'}}>
         <button type="submit" name="next" className="button-primary" style={{float: 'center'}}>Next</button>
      </DialogActions>
          </form>
            </Grid>

  );
  }
}
DuplicatedForm.propTypes = {
  duplicatedOrganizations:    PropTypes.object.isRequired,
  duplicatedItem: PropTypes.string.isRequired
};

const mapStateToProps = state => ({

});

DuplicatedForm = withStyles(styles)(DuplicatedForm)

export default connect(mapStateToProps, {})(reduxForm({form: 'DuplicatedForm', enableReinitialize: true, keepDirtyOnReinitialize: true, validate})(DuplicatedForm))
