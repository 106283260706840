import React, { PureComponent } from 'react';
import {Radar, RadarChart, PolarGrid, Legend,
        PolarAngleAxis, PolarRadiusAxis} from 'recharts';


export default class RadarsChart extends React.Component {
    constructor() {
        super();
    }
    render() {
        var data = this.props.data.map((row) => {
            return {
                AnswerId: row.AnswerId,
                Average: parseInt(row.Average).toFixed(2),
                Name: row.Name,
                Score: parseInt(row.Score).toFixed(2)
            }
        })
        console.log(data)
        return (
                <RadarChart  outerRadius={150} width={800} height={600} data={data} margin={{left: 300, right: 300}}>
                    <PolarGrid />
                    <PolarAngleAxis dataKey="Name" tickLine={true} tick={{fontSize: 14, width: 250 }}/>
                    <PolarRadiusAxis/>
                    <Radar name="Performance" dataKey="Score" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6}/>
                </RadarChart>
                                );
                    }
                }
