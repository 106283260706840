import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../common/Spinner';
import ContributionItem from './ContributionItem';
import Grid from '@material-ui/core/Grid';


class Contributions extends Component {
constructor(props) {
super(props);
        this.state = {
         total: 0,
         limit: 15,
         offset: 0
        }
}

componentDidMount() {

}

componentWillReceiveProps(nextProps) {
    if(nextProps.contribution.total){
        this.setState({
            total: parseInt(nextProps.contribution.total)
           })
    }
    if(nextProps.contribution.offset){
        this.setState({
            offset: parseInt(nextProps.contribution.offset)
        })
    }
}

loadMore = (e) => {
     e.preventDefault();
     this.props.onLoadMore(this.state.offset+15)
}

render() {

    const {contributions, total } = this.props.contribution;
    let listContributions;
    if (contributions && contributions.length > 0) {
        listContributions = contributions.map((contributionItem, i) => (
            <ContributionItem key={i} contributionItem={contributionItem}  />
        ));
    } else {
        listContributions = '';
    }

    return (
        <div ng-show="searching" className="sector-contributions">
            {contributions &&
            <div>
                <filters-results>
                  <Grid container spacing={3} style={{
                    paddingLeft: 15,
                    paddingRight: 15
                  }}>
                  {listContributions}
                  </Grid>
                  <Grid container spacing={3} style={{
                    paddingLeft: 15,
                    paddingRight: 15
                  }}>
                      {(this.state.total - this.state.limit) > this.state.offset &&
                    <Grid item md={4} xs={12}  className="text-center center-col">
                        <br/>
                        <button className="btn text-uppercase blue-button" onClick={this.loadMore}>Load more</button>
                        <br/>
                        <br/>
                    </Grid>
                    }
                    </Grid>
                </filters-results>
            </div>
            }
        </div>
    );

}

}

Contributions.propTypes = {
        contribution: PropTypes.object.isRequired,
        onLoadMore: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
        contribution: state.contribution
});

export default connect(mapStateToProps, {})(Contributions);
