import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextTruncate from 'react-text-truncate';

class MemberContributionItem extends Component {



    render() {
        return (
               <Grid item md={4} sm={6} xs={12} className="text-left">
                {
                    this.props.contributionItem &&
                    <div className="project-content" >
                        <div className="contribution-preview">
                            <a style={{ color: 'black'}}  href={this.props.contributionItem.href}>
                                <img  alt="contribution" src={this.props.contributionItem.photo}/>
                                <h4>
                                <TextTruncate
                                  line={1}
                                  truncateText="…"
                                  text={this.props.contributionItem.projectTitle}
                                  containerClassName="projectTitle text-center text-uppercase "/>
                                </h4>
                            </a>
                        </div>
                    </div>
                }
               </Grid>
                );
    }
}

MemberContributionItem.propTypes = {
    contributionItem: PropTypes.object.isRequired,
};


export default connect()(MemberContributionItem);
