import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import logoSide from '../../img/logo-side-menu.png';
import Navbar from '../layout/Navbar';
import { withRouter } from 'react-router-dom';
import { getCurrentProfile } from '../../actions/profileActions';
import ProfileActions from './ProfileActions';
import Spinner from '../common/Spinner';
import { config } from '../../config';

class Welcome extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
         this.props.getCurrentProfile();
    }

    componentWillReceiveProps(nextProps) {

    }

    render() {

    const { profile, loading } = this.props.profile;
    let dashboardContent;
    if (profile === null) {
        dashboardContent = <Spinner />;
    }else{
        dashboardContent = <ProfileActions profileItem={profile}/>
    }


        return (
            <div className="container-fluid" >

            <div className="page-content  slide page-animation">
            {dashboardContent}
                <div className="clearfix "></div>
                <div>
                    <div className="col-lg-12">
                        <div style={{position: 'fixed', bottom: '0px', right: '10px', minWidth: '400px', zIndex: 9000}}>
                            <div className="system-alert "></div>
                        </div>
                        </div>
                </div>
              <div className="welcome-page page-content ">
                        <div className="container text-center">
                        <img className="welcome-page-logo" src={config.API_URL +"/images/150x150/0ccae9.png?v1.0"} alt="logo" style={{width: 150, height: 150}}/>

                        <h1>Welcome!</h1><h4 className="col-md-10 col-md-offset-1">
                            As a member of the informal Interagency SPHS Task Team,
                            you are now able to use the redesigned online platform, as an Interagency Forum in which we can all connect
                            and share knowledge on ideas, projects, events and best practices related to the SPHS.  Kindly always clearly
                            label all files you may upload.
                        </h4>
                        <h4 className="col-md-10 col-md-offset-1">
                            You can explore our key discussions (Members Forum), SPHS key knowledge products, as well as upload
                            contributions to the public platform. This platform has been redesigned to ease your participation and
                            enhance our collaboration on the SPHS. </h4>
                            <h4 className="col-md-10 col-md-offset-1">
                            For further information on the platform or for help with technical issues, please contact the Secretariat:
                            <a href="mailto:info@savinglivesustainably.org " target="_top"> info@savinglivesustainably.org</a>
                        </h4>
                    </div>
                </div>
            </div>




         <Navbar/>
        </div>



        );
    }
}

Welcome.propTypes = {
                getCurrentProfile: PropTypes.func.isRequired,
                auth: PropTypes.object.isRequired,
                profile: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
                profile: state.profile,
                auth: state.auth
});

export default connect(mapStateToProps, { getCurrentProfile })(withRouter(Welcome));
