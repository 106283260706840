import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import MenuNavbarFooter from './MenuNavbarFooter';
import CookieConsent from "react-cookie-consent";


const styles = theme => ({
  disclaimers:{
    backgroundColor: '#203648'
  },
  contentText: {
    background: "#ebf0f3",
  },
});

class Footer extends React.Component {

    constructor() {
        super();
        this.state = {
            open: false
        };
    }

    handleClickOpen = event => {
        event.preventDefault();
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({
            open: false,
            password: '',
            email: ''
        });
    };

    render(){

      const { classes } = this.props;
          return (

    <div className="footer-new">
            <div className="background-dark-blue footer-top">
                <div className="max-width text-center">
                    <ul>
                      <li>
                          <a className="text-light-gray" href="/shipp/shipp.html">
                              SHiPP
                          </a>
                      </li>
                        <li>
                            <a className="text-light-gray" href="/who-we-are">
                                Who We Are
                            </a>
                        </li>
                        <li>
                            <a className="text-blue2" href="/knowledge-practice">
                                <div>Knowledge and Practice</div>
                            </a>
                        </li>
                        <li>
                            <a className="text-blue2" href="/what-you-can-do">
                              Global Contributions Map
                            </a>
                        </li>
                        <li>
                            <a className="text-blue2" href="/sustainability-assessments">
                                Sustainability Assessments
                            </a>
                        </li>
                        <li>
                            <a className="text-blue2" href="/calendar">
                                Event Calendar
                            </a>
                        </li>
                        <li>
                            <span className="text-blue2">
                              <MenuNavbarFooter/>
                            </span>
                        </li>
                        <li><a className="text-blue2" href="/news" target="_self">
                                News and Stories
                            </a>
                        </li>
                        <li>
                            <a className="text-blue2" href="/contacts">
                                Contact Us
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="line">
                </div>
                <Grid item md={12} className="subscribe">
                    <h3 className="text-center text-uppercase">
                        Sign up to our newsletter</h3>
                        <Button className="text-uppercase blue-button" href="/contribute/register">
                        subscribe*
                        </Button>
                     <p className="text-center text-dark-gray">
                        *Clicking "Subscribe" indicates agreement to our conditions</p>
                </Grid>
            </div>
            <Grid item md={12} className="footer-bottom">
                <h3 className="text-white text-center text-uppercase">
                    Join our international network today</h3>
                <h4 className="text-center">
                    Take action. Share your experience. Contribute to Saving Lives Sustainably.</h4>
                <div className="footer-social-bar text-center">
                    <a title="Twitter" href="https://twitter.com/search?vertical=default&amp;q=%23act4health" target="_blank">
                        <i className="fab fa-twitter"></i>
                    </a>
                    <a title="Linkedin" href="https://www.linkedin.com/company/11201509" target="_blank">
                        <i className="fab fa-linkedin" aria-hidden="true"></i>
                    </a>
                    <a title="ISSUU" href="https://issuu.com/informal_int_task_team_sphs" target="_blank">
                        <img className="issuu" src="/images/org/58c833.png?v1.0"/>
                    </a>
                    <a title="Slideshare" href="http://www.slideshare.net/UNSustainableProcurementHealthSector" target="_blank">
                        <i className="fab fa-slideshare"></i>
                    </a>
                    <a title="Youtube" href="https://www.youtube.com/channel/UCX2UQcpPViZVot-0wfBIU-Q" target="_blank">
                        <i className="fab fa-youtube" aria-hidden="true"></i>
                    </a>
                    <a title="Flickr" href="https://www.flickr.com/photos/un_sphs/" target="_blank">
                        <i className="fab fa-flickr"></i>
                    </a>
                    <a title="Subscribe" href="/contribute/register" target="_blank">
                        <i className="far fa-envelope"></i>
                    </a>
                </div>
                <p className="text-center text-dark-gray">
                    United Nations Development Programme (UNDP), {new Date().getFullYear()}. All rights reserved. <sup>©</sup>
                    <br/>
                    <span disclaimers-modal="" onClick={this.handleClickOpen}>Disclaimers</span>|
                    <Link to="/terms-and-conditions" >Terms and Conditions</Link>|
                    <Link to="/privacy-policy">Privacy Policy</Link>
                </p>
                <p className="text-center text-dark-gray">
                    <div style={{
                        textAlign:'center', 
                        marginBottom: 10,
                        color: '#aaa'}}> 
                        Powered by <a style={{ 
                            color:'#777',
                             textDecoration:'underline'}}
                     href="https://composity.com/" title="Composity Business Software - ERP, CRM, eCommerce, Helpdesk">Composity Business Software</a>
                </div></p>
            </Grid>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth
        >
        <DialogTitle id="form-dialog-title" classes={{root:classes.disclaimers}}> <h4 className="text-white modal-title">Disclaimer</h4></DialogTitle>
        <DialogContent classes={{root:classes.contentText}}>
            <DialogContentText className="modal-body" align="justify" size="large" style={{fontSize: 14, color: '#333', fontFamily: 'OpenSans'}}>
              The informal Interagency Task Team on Sustainable Procurement in the Health Sector (SPHS) is established on an informal basis and voluntary participation of its member agencies. This website is produced to inform discussions around strengthening sustainable procurement in public health sector. The content, analysis, opinions and policy recommendations contained on the website  (<a href="www.iiattsphs.org">www.iiattsphs.org</a> and <a href="www.savinglivesustainably.org">www.savinglivesustainably.org</a>) do not necessarily reflect the views of the United Nations Development Programme or other member organizations of the SPHS. Any omissions, inaccuracies and mistakes are responsibility solely of the authors. While we endeavor to keep the information up to date, SPHS website content authors, any of their affiliates, partners, employees or other representatives make no representations, or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with the respect to the website or the information or graphics contained on the website for any purpose. Any reliance you place on such information is therefore at your own risk. Under no circumstances will the United Nations Development Programme or other member organizations of the SPHS be liable for any consequence relating directly or indirectly to any action or inaction you take based on the information, services or other material on this website, nor will we be responsible for any damage or loss related to, the timeliness, accuracy, or completeness of the information, services, products, or other material on this website.
            </DialogContentText>
            <DialogContentText style={{color: 'red', fontSize: 20}}>
             {this.state.errors &&
              <span>       {this.state.errors.message}   </span>
                    }
            </DialogContentText>
        </DialogContent>
       </Dialog>
       <CookieConsent
              buttonText="I accept cookies"
              buttonClasses="blue-button text-white text-uppercase"
              buttonStyle={{ backgroundColor: "#1cbbb4", padding: "10px 20px" }}
              style={{ background: "#fff", boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)" }}
              contentStyle={{color: "#7a7a7a", fontSize: 14 }}
              expires={365}
          >
           This site uses cookies to offer you a better browsing experience.
       </CookieConsent>
    </div>

  );
    }
}

Footer = withStyles(styles)(Footer)
export default Footer;
