import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Field, reduxForm} from 'redux-form';
import { change } from 'redux-form';
import validate from '../form/validator';
import {
onLoadForm as onLoadForm, onSave
        } from "../../../actions/formActions";
import FormLoader from "../form/FormLoader";
import {onHideMessage, onShowMessage} from "../../../actions/gridActions";
import {FORM_SHOW_LOADER} from "../../../actions/types";
import IntegrationReactSelect from '../../common/IntegrationReactSelect'
import {renderDatePickerField, renderTextField, renderSelectField, renderCheckbox, renderTmcField, renderPasswordField, radioButton} from '../../common/MaterialUiForm'
import RadioButtonsGroup from '../../common/RadioButtonsGroup'
import Grid from '@material-ui/core/Grid';
import Select2 from './types/Select2';
import Contacts from '../form/types/Contacts';
import Switch from '../form/types/Switch';
import Textarea from '../form/types/Textarea';
import DatePickerComponent from '../form/types/DatePickerComponent'
import DateTimeComponent from '../form/types/DateTimeComponent'
import { toast } from 'react-toastify'
import "../form/redux-forms.css";
import DropZoneComponent from '../../file/DropZoneComponent'
import CheckboxGroupField from '../../common/CheckboxGroupField'
import Select2Creatable from '../../common/Select2Creatable'
import Collection from '../form/types/Collection';
import Sections from '../form/types/Sections'
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

class Form extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
            submitting: false,
            fields: []
            };
            this.updateForm = this.updateForm.bind(this);
            this.changeFieldValue = this.changeFieldValue.bind(this);
        }

        changeFieldValue(id, value) {
            this.props.change(id, value);
        }

        componentDidMount() {
            this.updateForm();
        }

        componentWillReceiveProps(nextProps) {
           if(nextProps.formProperties.form && nextProps.formProperties.form.fields){

           }
        }

        componentDidUpdate(prevProps) {
            if (this.props.match && prevProps.match.params.id !== this.props.match.params.id) {
                this.updateForm();
            }
        }

        updateForm() {
            this.props.onLoader(this.props.form, true);
            if (this.props.title && this.props.alone) {
                    document.title = this.props.title;
            }
            this.props.onLoadForm(this.props.form, this.props.formRoute);
        }



 resetForm = () => this.props.reset();

render() {
    let {handleSubmit, handleSave, formProperties, pristine, reset, submitting, invalid, valid, formData} = this.props;
    const questions = formProperties.form && formProperties.form.questions ? formProperties.form.questions : [];
    let returnUrl = null;
    
    if(this.props.returnUrl){
        returnUrl = this.props.returnUrl
    }else{
        returnUrl = formProperties.form ? formProperties.form.returnUrl : null;
        }
    returnUrl = returnUrl ? returnUrl : '#';
   
    if(formProperties.form){
    
}
    let title = formProperties.form && formProperties.form.title ? formProperties.form.title : '';
    
    let partionName = formProperties.form && formProperties.form.name ? formProperties.form.name : (this.props.section ? this.props.section.partition : null);
    let customButtons = formProperties.form && formProperties.form.customButtons ? formProperties.form.customButtons : null;
    let buttons = '';
    let disabled = pristine || submitting;
    if(formProperties.form && formProperties.form.submitActive){
        disabled = false;
    }

    if(customButtons){
     buttons = customButtons.map((button, i) => (
    <button onClick={handleSubmit(values =>
          this.props.onSubmit({
            ...values,
            button: button.value
          }))} name={button.name} key={i}  value={button.value} className={button.class}>{button.label}</button>
        ));
    }

    return (
        <div>
            {this.props.alone && <div className="dashboard">

            </div>}
            <div id="reactForm">
                <div className="row m-bot15 cmxform form-horizontal bucket-form ng-pristine ng-valid" style={{margin: 40}}>
                    <div className="col-lg-12">
                {returnUrl && this.props.isResult && <h2>     <a href={returnUrl}>
                            {!this.props.popup && <i className="ion-ios-arrow-back" style={{marginRight: 10}}></i>}</a>
                                {partionName && partionName }</h2> }
                   { partionName && !this.props.isResult && <h2> { partionName }</h2> }
                    <section className={formProperties.form && formProperties.form.noBackground ? '' : 'panel'} style={{background: 'white'}}>
                    {this.props.alone &&
                            <header className="panel-heading"
                                    style={{display: formProperties.form && formProperties.form.noBackground ? 'none' : 'block'}}>
                               {title && <h3>{title}</h3>  }
                            </header>}
                             <div className="panel-body">
                            {this.props.isResult && <Grid md={12} lg={12} item className="text-center">
                         {this.props.backUrl && <a href={this.props.backUrl} className="btn btn-default"><ArrowBackIcon/></a>}
                         {this.props.nextUrl && <a href={this.props.nextUrl} className="btn btn-default"><ArrowForwardIcon/></a>}
                                </Grid>}
                                {formProperties.form && formProperties.form.breadcrumb && formProperties.form.breadcrumb.fields.length > 0 &&
                                    <div className="status-container">
                                        <div className="status-breadcrumb">
                                            {
                                                formProperties.form.breadcrumb.fields.map((element, key) => {
                                                    return <a
                                                        className={formProperties.form.breadcrumb.target in this.props.formData.data && element.value === this.props.formData[formProperties.form.breadcrumb.target] ? 'active' : ''}
                                                        href="#" key={key}
                                                        onClick={() => this.onBreadcrumbClick(element, formProperties.form.breadcrumb.target)}>
                                        <span className="breadcrumb__inner">
                                            <span className="breadcrumb__desc">{element.label}</span>
                                        </span>
                                                    </a>
                                                })
                                            }
                                        </div>
                                    </div>
                                }

                            <form onSubmit={e => {e.preventDefault()}}>
                             <Grid container direction="row" spacing={4}>
                              <Grid md={12} lg={12} container direction="row" item >
                            {questions.length > 0 && questions.map((question, k) => (
                     <Grid md={12} lg={12} container direction="row" item key={k} className="pull-left" style={{padding: 15}}>
             <Grid md={12} lg={12} item>   {question.partitionName && <h2>{question.partitionName}</h2>}
              {question.sectionName && <h3>{question.sectionName}</h3>}
                                        </Grid>
                         <h4 style={{marginRight: 15}}>{question.name}</h4>
                             <Grid md={12} lg={12} container direction="row" item key={k} className="pull-left">
                                {question.fields && question.fields.length > 0 && question.fields.map((field, index) => {
                                let component = renderTextField;
                                let data = null;
                                if(field.type === 'hidden'){
                                    component =  'input';
                                }
                                if (field.type === 'text'){
                                    component = renderTextField;
                                }
                                if(field.type === 'select2'){
                                    component = Select2;
                                }
                                if(field.type === 'select'){
                                    component = Select2;
                                }
                                if(field.type === 'selectCreatable'){
                                    component = Select2Creatable;
                                }
                                if(field.type === 'collection'){
                                    component = Contacts;
                                    data = formData.data;
                                }
                                 if(field.type === 'collections'){
                                    component = Collection;
                                    data = formData.data;
                                }
                                if(field.type === 'sections'){
                                    component = Sections;
                                    data = formData.data;
                                }
                                 if(field.type === 'checkboxGroup'){
                                    component = CheckboxGroupField;
                                }
                                if(field.type === 'switch'){
                                    component = Switch;
                                }
                                if(field.type === 'textarea'){
                                    component = Textarea;
                                }
                                if(field.type === 'tmc'){
                                    component = renderTmcField;
                                }
                                if(field.type === 'tags'){
                                    component = IntegrationReactSelect;
                                }
                                if(field.type === 'datePicker'){
                                    component = DatePickerComponent;

                                }
                                if(field.type === 'datetimePicker'){
                                    component = DateTimeComponent;
                                }
                                if(field.type === 'radio'){
                                    component = RadioButtonsGroup;
                                }
                                if(field.type === 'password'){
                                    component = renderPasswordField;
                                }
                                let col = 6;
                               if(typeof field.col !== 'undefined'){
                                 col = field.col;
                               }
                               if(field.type === 'file'){
                                     component = DropZoneComponent;
                                     data = formData.data[field.id+'Files'];
                               }

                               if(field.type === 'checkbox'){
                                    component = renderCheckbox;
                                }
                               if(this.props.submitButton === false){
                                    field.disabled = true;
                                }

                                return <Grid item md={12} key={index} className="m-20">
                                <h4 className="no-gutter">{field.header}{field.header && field.required ? <b>*</b> : <span></span>}</h4>
                                    <Field
                                        data={data}
                                        name={field.id}
                                        field={field}
                                        change={this.changeFieldValue}
                                        component={component}
                                        type={field.type}
                                        hide={field.hide}
                                        placeholder={field.label}
                                        label={field.label}
                                        ismulti={field.ismulti ? field.ismulti : null}
                                        clearable={field.clearable ? field.clearable : null}
                                        options={field.options ? field.options : []}
                                        disabled={field.disabled ? field.disabled : false}
                                        id={field.id}
                                        collectionFields={field.collectionFields ? field.collectionFields : null}
                                        download={this.props.download}
                                        />
                                </Grid>
                                })
                                }
                                </Grid>
                                 </Grid>

                               ))}
                               </Grid>
                                {customButtons ?
                                <Grid md={12} lg={12} item className="text-center">
                                  {buttons}
                                  </Grid>
                                :
                                <Grid md={12} lg={12} item className="text-center">
                         {this.props.backUrl && <a href={this.props.backUrl} className="btn btn-default"><ArrowBackIcon/></a>}
                         {this.props.submitButton && <span>
                                <button  onClick={handleSubmit(values => this.props.handleSave(values, this.props))}
                                type="submit" disabled={disabled } style={{ display: 'inline', margin: 15}} className={"btn btn-default"} >
                                   SAVE
                                </button>
                                <button  onClick={handleSubmit(values =>this.props.onSubmit(values, this.props))}
                                type="submit" disabled={disabled } style={{ display: 'inline', margin: 15}} className={"button-submit"} >
                                   SUBMIT
                                </button>
                              </span>}
                              {this.props.nextUrl && this.props.isResult && <a href={this.props.nextUrl} className="btn btn-default"><ArrowForwardIcon/></a>}
                                </Grid>
                                }

                                 </Grid>
                            </form>

                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
    }
}



const mapStateToProps = (state, ownProps) => ({
    settings: state.settings,
    formProperties: state.formProperties && state.formProperties[ownProps.form] ? state.formProperties[ownProps.form] : [],
    formData: state.formData ? state.formData : [],//state.form && state.form[ownProps.form] ? state.form[ownProps.form].values : [],
    initialValues: state.formData ? state.formData.data : [],
});

const mapDispatchToProps = dispatch => ({
onLoadForm: (name, route) => dispatch(onLoadForm(name, route)),
//save: (formId, route, data) => dispatch(onSave(formId, route, data)),
showMessage: (text, isError) => dispatch(onShowMessage(text, isError)),
hideMessage: () => dispatch(onHideMessage()),
onLoader: (formId, value) => dispatch({
    type: FORM_SHOW_LOADER,
    payload: {formId, value}
})
});


Form = reduxForm({
    form: 'initializeFromState2', // a unique identifier for this form
    enableReinitialize: true,
    touchOnChange: true,
   // validate: validate
})(Form);

Form = connect(mapStateToProps, mapDispatchToProps)(Form);

export default Form;
