import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser, requestedPath } from '../../actions/authActions';
import { postCreateContribution, setContribution, getUserFormContribution } from '../../actions/contributionActions';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import ContributionEvent from '../forms/contribution/ContributionEvent'
import ContributionProject from '../forms/contribution/ContributionProject'
import ContributionPublication from '../forms/contribution/ContributionPublication'
import ContributionStory from '../forms/contribution/ContributionStory'
import ContributionTool from '../forms/contribution/ContributionTool'
import ContributionTraining from '../forms/contribution/ContributionTraining'
import moment from 'moment'
import { config } from '../../config'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    htmlFontSize: 20

  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    flexBasis: 200,
    fontSize: 20,
    background: "white",
    borderRadius: 4,
    marginLeft: 0,
  },
  label:{
      fontSize: 20
  },
    button: {
        background: '#1cbbb4',
        borderRadius: '3px',
        color: '#fff',
        textTransform: 'uppercase'
    },
  inputRoot: {
    fontSize: 16,
  },
  textRoot:{
    background: "#ebf0f3",
    margin: 0,
    paddingTop: 10,
    fontSize: 14,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: '100%',
    fullWidth: true,
    maxWidth: true
  },
  formControl: {
  //  marginTop: theme.spacing(2),
    minWidth: 320,
    fullWidth: true,
    width: '100%'
  },
  formControlLabel: {
      fullWidth: true,
      maxWidth: true
 //   marginTop: theme.spacing(1),
  },
  dialodActions: {
    flex: '0 0 auto',
     margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
},
  content: {
    padding: "50px 30px",
    background: "#ebf0f3",
    overflow: "hidden"
  },
  input: {
    background: "white",
    border: "1px solid #ccc",
    paddingLeft: 10,
    height: 48,
    lineHeight: 35,
    borderRadius: 4,
    boxShadow: "none"
  }

});


class LoginForm extends React.Component {

  constructor() {
    super();
    this.state = {
       open: false,
       showPassword: false,
       password: '',
       email: '',
       errors: '',
       emailError: '',
       passwordError: '',
       openForm: false,
       type: "",
       openContrib: false
    };
  }

   componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  //start main contribution form
  handleCloseContrib = () => {
       this.setState({ openContrib: false, type: '' })
       this.setStyleOnClose()
       if(this.props.match.params.id){
       this.props.getUserFormContribution(this.props.match.params.id);
   }
  }

    submit = values => {
     var body = new FormData();
      var formDataField = {};
   const formData = Object.assign(formDataField, values);
    Object.keys(values).forEach(( key ) => {

        if(key === 'photo'){

          let files = formData['photo'];

          if(typeof files === 'number'){
              body.append('photo'+'[]', files)
          }
          if(typeof files === 'object' && files.length > 0){
             files.map((file, i) => {
                 body.append('photo'+'[]', formData['photo'][i]['file'])
             })
         }

             //body.append('photo', values.photo.file)
        }else if(['region', "analyses", "sectors", "sdg"].includes(key)){
           let orgArray = []
                   values[key].map(obj => (
              orgArray.push(obj.value)
  ))
        body.append(key, orgArray);
        }else{
      body.append(key, values[ key ]);
  }
    });
    body.append('type', this.state.type);
  this.props.postCreateContribution(body, this.props.history)
  this.setStyleOnClose()
  }
  //end main contribution form

//start type contribution forms actions
  handleClickOpen = event => {
    event.preventDefault();    
    if(this.props.auth.isAuthenticated){
        this.setState({ openForm: true });
    }else{
        this.setState({ open: true });
        if(event.target.id === '/members/questionnaires'){
            this.props.requestedPath(event.target.id)
        }
    }
this.setStyleOnOpen();
  };

  handleCloseForm = () => {
    this.setState({
        openForm: false,
    });
    this.setStyleOnClose()
  };

   handleChooseType = event => {
    this.setState({ type: event.target.value });

  };

  handleSubmitType = () => {
     this.setState({  openForm: false, openContrib: true })
     this.setStyleOnClose()
     this.props.setContribution()
  }

//end contribution forms actions

//start login form actions
  handleClose = () => {
    this.setState({
        open: false,
        password: '',
        email: ''
    });
    this.setStyleOnClose()
  };

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

   keyPress = e => {
      if(e.keyCode === 13){

         this.handleSubmit(e)
      }
   }

  handleSubmit = event => {
    event.preventDefault();
    let userData = {}
    if(this.state.email){
        userData.username = this.state.email
         this.setState({
            emailError: null
        })
    }else{
        this.setState({
            emailError: 'This field can not be blank!'
        })
    }

    if(this.state.password){
        userData.password = this.state.password
        this.setState({
            passwordError: null
        })
    }else{
        this.setState({
            passwordError: 'This field can not be blank!'
        })
    }
    if(this.state.password && this.state.email){
       this.setState({
              password: '',
              //email: ''
          })

        this.props.loginUser(userData, this.props.history, this.props.auth.requestedRoute);

    }
    this.setStyleOnClose()
  }
//end login form actions

    setStyleOnOpen = () => {
        let scrollElement = document.getElementsByTagName("html");
        scrollElement[0].style.overflow='hidden';
    }

    setStyleOnClose = () => {
          let scrollElement = document.getElementsByTagName("html");
          scrollElement[0].style.overflow='auto';scrollElement[0].style.overflowX='hidden';
    }

  render() {

  const { classes } = this.props;

    return (
    <span >
          {this.props.role ==='contribute' ?

            <h3 className="text-uppercase blue-button" onClick={this.handleClickOpen} style={{width: 200, textAlign: 'center'}}>
              Contribute</h3>

           : this.props.role ==='filter' ?
            <Grid lg={12} sm={12} item>
            <div className="blue-button" onClick={this.handleClickOpen}>
           <img src={config.API_URL +"/bundles/contribution/images/icons/cloud-icon-01.png"} alt="logo"/>
           <span>Contribute</span>
           </div>
           </Grid>
           : this.props.role === 'oportunity' ?
                <h3 className="blue-button" onClick={this.handleClickOpen}>
                <i className="ion-ios-plus-outline"></i><span>Submit an opportunity</span></h3>
             : this.props.role === 'sustainability' ?
                 <a href="" onClick={this.handleClickOpen}  style={{ color: "rgb(82, 182, 199)", textDecoration: "underline"}} id={'/members/questionnaires'}>
                Environmental Sustainability Profiles, Scores and Reports
              </a>    
           :
           <span className="">
              <a href="" onClick={this.handleClickOpen}  className="text-uppercase login-link login-linkNew">
                Members area
              </a>
              <a href="" onClick={this.handleClickOpen}  className="text-uppercase login-link login-linkNew">
                Login
              </a>
             <a className="text-uppercase login-link login-linkNew" href="/contribute/register">register</a>
          </span>
       }
        <span>
       <Dialog
          open={this.state.openForm}
          onClose={ this.setStyleOnClose}
          aria-labelledby="form-dialog-title"
          fullWidth
        >
       <div className="modal-header">
        <DialogTitle id="form-dialog-title" className="modal-title" style={{padding: 0}}>New Public Contribution</DialogTitle>
         <IconButton color="inherit" onClick={this.handleCloseForm} aria-label="Close"  className="modal-button-close" style={{    float: "right"}}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent className="modal-body" classes={{root:classes.content}}>
         <form className={classes.form} noValidate>
          <FormControl className={classes.formControl}>
           <InputLabel htmlFor="type" style={{fontSize: 14, display: "contents"}}>Please specify the type of your contribution</InputLabel>
           <Grid lg={12} sm={12} xs={10} item>
              <Select
                fullWidth
                value={this.state.type}
                onChange={this.handleChooseType}
                className={classes.input}
                inputProps={{
                  name: 'type',
                  id: 'type',
                }}
                SelectDisplayProps={{
                  style: {fontSize: 14,backgroundColor: "transparent"}
                }}
              >
                <MenuItem value="Project"  style={{marginLeft: 15, fontSize: 14}}>Project</MenuItem>
                <MenuItem value="Event" style={{marginLeft: 15, fontSize: 14}}>Event</MenuItem>
                <MenuItem value="Story" style={{marginLeft: 15, fontSize: 14}}>Success story</MenuItem>
                <MenuItem value="Publication" style={{marginLeft: 15, fontSize: 14}}>Publication</MenuItem>
                <MenuItem value="Training" style={{marginLeft: 15, fontSize: 14}}>Training</MenuItem>
                <MenuItem value="Tool" style={{marginLeft: 15, fontSize: 14}}>Tool</MenuItem>
              </Select>
                </Grid>
            </FormControl>
          </form>

        </DialogContent>
        <DialogActions className="modal-footer" style={{margin: 0, borderRadius: "0 0 3px 3px", background: "#d5e3eb", borderTop: "1px solid #909ba4"}}>
              <button onClick={this.handleSubmitType} color="primary" className="green-button" style={{fontSize: 14}} disabled={this.state.type ? false : true}>
                Next
              </button>
        </DialogActions>
      </Dialog>
        </span>
      <Dialog
        open={this.state.openContrib}
        onClose={this.setStyleOnClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xl"
        disableEnforceFocus
        scroll="paper"
      >
      <div>
       <div className="modal-header">
        <DialogTitle id="form-dialog-title" className="modal-title" style={{padding: 0}}>New Public Contribution {this.state.type}</DialogTitle>
         <IconButton color="inherit" onClick={this.handleCloseContrib} aria-label="Close"  className="modal-button-close" style={{    float: "right"}}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent className="modal-body"  classes={{root:classes.content}} >
        <div>
        {this.state.type === 'Event' &&
        <ContributionEvent onSubmit={this.submit} initialValues={{ projectAt: moment(), projectTo: moment()}}/>
        }
        {this.state.type === 'Project' &&
        <ContributionProject onSubmit={this.submit} initialValues={{ projectAt: moment(), projectTo: moment()}}/>
        }
        {this.state.type === 'Publication' &&
        <ContributionPublication onSubmit={this.submit} initialValues={{ projectAt: moment()}}/>
        }
        {this.state.type === 'Story' &&
        <ContributionStory onSubmit={this.submit}/>
        }
        {this.state.type === 'Training' &&
        <ContributionTraining onSubmit={this.submit} initialValues={{ projectAt: moment(), projectTo: moment()}}/>
        }
        {this.state.type === 'Tool' &&
        <ContributionTool onSubmit={this.submit} initialValues={{ projectAt: moment()}}/>
        }
        </div>
        </DialogContent>
        </div>
      </Dialog>

        <div className={classes.root}>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth
          className="login-modal"
        >
      <Grid container>
        <Grid lg={11} xs={11} item style={{background: "#203648"}}>
          <DialogTitle id="form-dialog-title" style={{fontSize: 20}} className={classes.root, "modal-header"}>Login form
         </DialogTitle>
         </Grid>
         <Grid lg={1} xs={1} item className="loginModal-close">
         <DialogActions>
           <Button  onClick={this.handleClose} size="large" className="text-white uppercase"><Icon size="16" className="fa fa-times-circle"/></Button>
           </DialogActions>
        </Grid>
         <Grid container item  className="loginModal-content">
            <Grid lg={6} md={6} sm={12} xs={12} item >
                  <DialogContent>
                    <DialogContentText style={{fontSize: 16}}>
                      Log in with your SPHS account
                    </DialogContentText>
                    <DialogContentText style={{fontSize: 14}}>
                     {Object.keys(this.state.errors).length > 0 &&
                       (<p className="label label-danger margin-b-15">Invaid credentials.</p>)
                            }
                    </DialogContentText>
                <TextField
                  id="outlined-email-input"
                  placeholder="Email"
                  className={classNames(classes.margin, classes.textField)}
                  error={Boolean(this.state.emailError)}
                  helperText={this.state.emailError}
                  value={this.state.email}
                  type="email"
                  name="email"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  onChange={this.handleChange('email')}
                  InputProps={{
                    classes: { root: classes.inputRoot
                  }
                  }}
                    InputLabelProps={{
            style: {
             fontSize: 16
            }
          }}
                  FormHelperTextProps={{
                  classes:{
                      root: classNames("labelRoot", classes.textRoot)
                  }
                  }}
                  onKeyDown={this.keyPress}
                />
                <DialogContentText style={{fontSize: 16}}>
                  <Link to="/password-reset" onClick={this.setStyleOnClose}>Can't access your account?</Link>
                </DialogContentText>
                   <TextField
                  id="outlined-adornment-password"
                  className={classNames(classes.margin, classes.textField)}
                  error={Boolean(this.state.passwordError)}
                  helperText={this.state.passwordError}
                  variant="outlined"
                  type={this.state.showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  name="password"
                  fullWidth
                  value={this.state.password}
                  onChange={this.handleChange('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          className="icon-login"
                        >
                          {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    classes:{root:("labelRoot", classes.inputRoot)}
                  }}
                  InputLabelProps={{
            style: {
             fontSize: 16
            }
          }}
                FormHelperTextProps={{
                classes:{
                      root:  classNames("labelRoot", classes.textRoot)
                  }
                  }}
                  onKeyDown={this.keyPress}
                />
                  </DialogContent>
              </Grid>
              <Grid lg={6} md={6} sm={12} xs={12} item >
                <DialogContent>
                  <DialogContentText style={{fontSize: 16}}>
                  Log in with your social account
                  </DialogContentText>
                  <Button
                    className="btn-linkedin"
                    size="large"
                    href="https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86rfrpa0ssnnzp&redirect_uri=https%3A%2F%2Fsavinglivesustainably.org%2Foauth%2Flinkedin-connect&state=r4-HAqXtac3JmpU5mb_NoDOvtJPhjs9xMnK00OjYlFM&scope=r_liteprofile%20r_emailaddress" target="_blank"
                    >
                    <Icon style={{ fontSize: 14, marginRight: 10 }} className="fab fa-linkedin"/>linkedin</Button>
                </DialogContent>
              </Grid>
          </Grid>
              <Grid lg={12} sm={12} xs={12} item >
                <DialogActions className="loginModal-footer">
                  <Button onClick={this.handleSubmit} color="primary" className="btn btn-primary" style={{fontSize: 14}} >
                    Login
                  </Button>
                  <span>or</span>
                  <Button component={Link} to="/contribute/register" color="default" style={{fontSize: 16}} onClick={this.setStyleOnClose}>
                    Sign up
                  </Button>
                </DialogActions>
              </Grid>
         </Grid>
        </Dialog>
        </div>
      </span>
    );
  }
}

LoginForm.propTypes = {
  loginUser: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  postCreateContribution: PropTypes.func.isRequired,
  setContribution: PropTypes.func.isRequired,
  getUserFormContribution: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  errors: state.errors,
  auth: state.auth,
  contribution: state.contribution
});

LoginForm = withStyles(styles)(LoginForm)

export default connect(mapStateToProps, { loginUser, postCreateContribution, setContribution, getUserFormContribution, requestedPath })(withRouter(LoginForm));
