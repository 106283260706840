import Ajv from 'ajv';

function getErrors(values, schema) {
    let ajvValidator = (new Ajv({allErrors: true})).compile(schema);
    let valid = ajvValidator(values);

    let errors = {};
    if (!valid) {
        ajvValidator.errors.map(error => {
            let key = error.dataPath;
            if (error.keyword === 'required')
                key = error.params.missingProperty;

            if (key.charAt(0) === '.')
                key = key.substr(1);

            errors[key] = error.keyword;
        });
    }
    let errorsAdditional = {};
    let requiredFields = typeof schema.required !== 'undefined' ? schema.required : [];

    requiredFields.forEach(field => {
        if(field === 'photo'){
           
            if (!values[field]) {
            errorsAdditional[field] = 'required'
        } else if (typeof values[field] === 'object' && Object.entries(values[field]).length === 0) {
          
            errorsAdditional[field] = 'required'
        }
        }
        if (!values[field] && values[field] === 'generalTerms') {
            errorsAdditional[field] = 'You have not agreed to the site terms'
        } else if (!values[field] && values[field] === 'imageTerms') {
            errorsAdditional[field] = 'You have not agreed to the content terms'
        } else if (!values[field]) {
            errorsAdditional[field] = 'required'
        } else if (typeof values[field] === 'object' && Object.entries(values[field]).length === 0) {
            errorsAdditional[field] = 'required'
        }

        if (field === 'city' && !/^[a-zA-Z0-9\'\-\ ]+$/.test(values[field])) {            
            errorsAdditional[field] = 'invalid';
        }        
    })
    
    return errorsAdditional;

    //  return errors;
}

export default function validate(values, props) {
    return getErrors(values, props.formProperties.schema ? props.formProperties.schema : {});
}

function checkCustomRules(field, errors, data) {
    if ('customRules' in field) {
        field.customRules.forEach(rule => {
            if (rule.type === 'sameAs' && field.id in data) {
                if (!(rule.field in data)) {
                    if (!(field.id in errors))
                        errors[field.id] = [];
                    errors[field.id].push('sameAs');
                } else if (data[rule.field] !== data[field.id]) {
                    if (!(field.id in errors))
                        errors[field.id] = [];
                    errors[field.id].push('sameAs');
                }
            }

            if (rule.type === 'dateRange' && field.id in data) {
                let date = data[field.id];
                if (date.indexOf('/') !== -1) {
                    date = date.split('/');
                    date = new Date(date[2], parseInt(date[1]) - 1, date[0]);
                } else {
                    date = new Date(date);
                }

                let startDate = new Date(rule.startDate);
                let endDate = new Date(rule.endDate);
                if (date > endDate || date < startDate) {
                    if (!(field.id in errors))
                        errors[field.id] = [];
                    errors[field.id].push('dateRange');
                }
            }
        });
    } else if ('fields' in field) {
        field.fields.forEach(innerField => {
            errors = checkCustomRules(innerField, errors, data);
        });
    }

    return errors;
}