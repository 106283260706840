import client from './client';
import { toast } from 'react-toastify';

import {
    GET_COLLABORATION,
  GET_COLLABORATIONS,
  GET_COLLABORATIONS_FORM_DATA,
  GET_CALENDAR_COLLABORATIONS
} from './types';

// Get 
export const getCollaborationsForAccount = (accountId) => dispatch => {  
  client
    .get('/api/admin/collaborations-account/get/'+accountId)
    .then(res =>
      dispatch({
        type: GET_COLLABORATIONS,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_COLLABORATIONS,
        payload: {}
      })       
  });
};

export const getCollaborationsFormData = () => dispatch => {  
  client
    .get('/api/admin/collaborations-form/form/data')
    .then(res =>
      dispatch({
        type: GET_COLLABORATIONS_FORM_DATA,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_COLLABORATIONS_FORM_DATA,
        payload: {}
      })       
  });
};

export const postCollaborationCreate = (data, accountId, id = null) => dispatch => {  
    let url = '/api/admin/collaboration-form/create/'+accountId;
    if(id){
        url = url + '/' + id;
    }    
  client
    .post(url, data)
    .then(res => {
      dispatch({
        type: GET_COLLABORATION,
        payload: res.data
      })
  })
    .catch(err => {    
        console.log('error')
           
  });
};

export const postCollaborationLineCreate = (data) => dispatch => {  
    var url = '/api/admin/collaboration-line/create/';
    if(data.id){
        url = url + data.id;
    }
  client
    .post(url, data)
    .then(res => {
      dispatch({
        type: GET_COLLABORATION,
        payload: res.data
      })
  })
    .catch(err => {    
        console.log('error')            
  });
};

export const getCalendarCollaborations = () => dispatch => {  
  client
    .get('/api/admin/collaborations-calendar/data')
    .then(res =>
      dispatch({
        type: GET_CALENDAR_COLLABORATIONS,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_CALENDAR_COLLABORATIONS,
        payload: []
      })       
  });
};

export const getDeleteCollaborationLine = (id) => dispatch => {  
  client
    .get('/api/admin/collaborations-delete/line/'+id)
    .then(res =>
      dispatch({
        type: GET_COLLABORATION,
        payload: res.data
      })
    )
    .catch(err => {       
      console.log('error')     
  });
};





