import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { postEditContribution, getUserFormContribution } from '../../actions/contributionActions';
import Navbar from '../layout/Navbar';
import Grid from '@material-ui/core/Grid';
import Footer from '../layout/Footer'
import { withStyles } from '@material-ui/core/styles'
import { toast } from 'react-toastify'
import ContributionEvent from '../forms/contribution/ContributionEvent'
import ContributionProject from '../forms/contribution/ContributionProject'
import ContributionPublication from '../forms/contribution/ContributionPublication'
import ContributionStory from '../forms/contribution/ContributionStory'
import ContributionTool from '../forms/contribution/ContributionTool'
import ContributionTraining from '../forms/contribution/ContributionTraining'
import { getCurrentProfile } from '../../actions/profileActions';
import ProfileActions from '../dashboard/ProfileActions';
import Spinner from '../common/Spinner';

const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

class EditMyContribution extends Component {
  constructor() {
    super();
    this.state = {
        errors: "",
        contribution: {}
    };
  }

  componentDidMount() {
    this.props.getCurrentProfile();
    this.props.getUserFormContribution(this.props.match.params.id);
  }
  
  componentWillReceiveProps(nextProps) {
       
   
       if(nextProps.contribution.contribution){
console.log(nextProps.contribution.contribution)
this.setState({
    contribution: nextProps.contribution.contribution
})
       }
    }

    submit = values => {   
       
     var body = new FormData();
      var formDataField = {};
   const formData = Object.assign(formDataField, values);
    Object.keys(values).forEach(( key ) => {       
                
        if(key === 'photo'){

          let files = formData['photo'];

          if(typeof files === 'number'){
              body.append('photo'+'[]', files)
          }
          if(typeof files === 'object' && files.length > 0){                          
             files.map((file, i) => {
                 body.append('photo'+'[]', formData['photo'][i]['file'])
             })        
         }
        
             //body.append('photo', values.photo.file)
        }else if(['region', "analyses", "sectors", "sdg"].includes(key)){
           let orgArray = []
                   values[key].map(obj => (
              orgArray.push(obj.value)
  ))
        body.append(key, orgArray);
        }else{
      body.append(key, values[ key ]);
  }
    });
   // body.append('type', this.props.contribution.contribution.contentType);
  this.props.postEditContribution(this.props.match.params.id, body, this.props.history)
  //this.setStyleOnClose()
  }

  render() {
      
  const { classes } = this.props;
 const { contribution } = this.props.contribution;
 
 let contrObject = null;
 let contrReflection = null
 if(this.state.contribution && typeof this.state.contribution.reflection !== 'undefined'){
  contrReflection = this.state.contribution;
  contrObject = this.state.contribution.reflection;
  contrReflection['projectStatus'] = contrReflection['projectStatusName'];
 }
 const { profile, loading } = this.props.profile;
    let dashboardContent;
    if (profile === null || loading) {
        dashboardContent = <Spinner />;
    }else{
        dashboardContent = <ProfileActions profileItem={profile}/>
    }


    return (           
            
            
     <div className={classes.root}>
      <Grid container spacing={0} >
        <div  className="page-content slide  page-animation" >
         {dashboardContent}
            <div className="contribute-registers">
              
                    <div className="registerContibute">                    
     <div className="clearfix "></div>
                <div className="row " style={{marginTop: 50}}>
   <Grid container  direction="row" spacing={4} className="registerContibute-form"> 
   <Grid md={10} lg={10} item className="edit-contribution">
        {contrReflection && contrReflection.contentTypeName === 'Event' &&
        <ContributionEvent onSubmit={this.submit} initialValues={contrReflection }/>
        }
        {contrReflection && contrReflection.contentTypeName === 'Project' &&
        <ContributionProject onSubmit={this.submit}  initialValues={contrReflection }/>
        }
        {contrReflection && contrReflection.contentTypeName === 'Publication' &&
        <ContributionPublication onSubmit={this.submit}  initialValues={contrReflection }/>
        }
        {contrReflection && contrReflection.contentTypeName === 'Story' &&
        <ContributionStory onSubmit={this.submit}  initialValues={contrReflection }/>
        }
        {contrReflection && contrReflection.contentTypeName === 'Training' &&
        <ContributionTraining onSubmit={this.submit}  initialValues={contrReflection }/>
        }
        {contrReflection && contrReflection.contentTypeName === 'Tool' &&
        <ContributionTool onSubmit={this.submit}  initialValues={contrReflection }/>
        }
    </Grid>
    </Grid>
                    </div>
                     </div>
                
            </div>
            <Footer/>
        </div>
        <Navbar/>
      </Grid>
     </div>
    );
  }
}

EditMyContribution.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  getUserFormContribution: PropTypes.func.isRequired,
  postEditContribution: PropTypes.func.isRequired,
  contribution: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  contribution: state.contribution,
  profile: state.profile,
});

EditMyContribution = withStyles(styles)(EditMyContribution)
export default connect(mapStateToProps, { postEditContribution, getUserFormContribution, getCurrentProfile })(withRouter(EditMyContribution));



