import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {getContribution} from '../../../actions/contributionActions';
import ContributionEvent from './contribution/ContributionEvent'
import ContributionProject from './contribution/ContributionProject'
import ContributionStory from './contribution/ContributionStory'
import ContributionPublication from './contribution/ContributionPublication'
import ContributionTraining from './contribution/ContributionTraining'
import ContributionTool from './contribution/ContributionTool'
import FromTheSameAuthor from './contribution/FromTheSameAuthor'

class IndividualContribution extends Component {
    constructor(props) {
        super(props);

        this.state = {
           contribution: null,
           contributions: []
        }
    }

    componentDidMount() {
         this.props.getContribution(this.props.id)
    }

    componentWillReceiveProps(nextProps) {

    if(nextProps.contribution.contribution){
    this.setState({
       contribution: nextProps.contribution.contribution.contribution
    })
    if(nextProps.contribution.contribution.contributions){
       this.setState({
       contributions: nextProps.contribution.contribution.contributions
    })
    }
}
    }

    handleClose(){

    }


render (){
    const { isAuthenticated, user } = this.props.auth;
    return  (
            <div className="individual-contribution-page" >
             {this.state.contribution &&
            <div>

        <div className="blue-area">
        </div>
        <div className="max-width individual-contribution-page-content">

            <div className="col-md-8">
               {this.state.contribution.humanFlag &&
                  <span>
                    <div className="text-white human-flag" >
                        { this.state.contribution.humanFlag}
                    </div>

                    <div className="clearfix" style="margin-bottom: 5px;"></div>
                    {this.state.contribution.isAuthor && this.state.contribution.isPendingAuthorReview &&
                            <span>
                        <button className="btn btn-sm btn-success fa fa-check" style="margin-right: 5px;">Accept changes</button>
                        <button className="btn btn-sm btn-danger fa fa-times" style="margin-right: 5px;"> Reject</button>
                        <button className="btn btn-sm btn-info fas fa-pencil">Edit with changes</button>
                        </span>
                        }
               </span>
               }
                <div className="location">
                    <br/>
                    You are here:
                    {this.state.contribution.regionsText &&
                    <span style={{marginLeft: 10}}>
                                {this.state.contribution.regionsText}
                    </span>
                    }
                </div>
                <h1 className="text-white text-bold">{this.state.contribution.projectTitle}</h1>


                <span className="text-dark-blue-old ng-binding" >Posted on { this.state.contribution.createdAt}</span>
                <img alt="contribution" src={this.state.contribution.photo} />
                <div className="col-md-12 list-style noPadding description" >
                    <p className="description text-left" dangerouslySetInnerHTML={{__html: this.state.contribution.description}}></p>
                </div>
                {this.state.contribution.outcomes &&
                <div className="col-md-12 noPadding outcomes" ng-if="">
                    <h3 className="text-capitalize">outcomes</h3>
                    <p className="description text-left" dangerouslySetInnerHTML={{__html: this.state.contribution.outcomes}}></p>
                </div>
                }
                <div className="white-box col-md-12">
                {this.state.contribution.leadOrganization &&
                    <div className="col-md-12" >
                        <h5 className="text-uppercase text-gray">lead organization</h5>
                        <p >{ this.state.contribution.leadOrganization}</p>

                    </div>
                    }
                    {this.state.contribution.organizationType && this.state.contribution.organizationType.length > 0 &&
                    <div className="col-md-12" >
                        <h5 className="text-uppercase text-gray">organization type</h5>
                        {this.state.contribution.organizationType.map((type, i) => (
                                 <p key={i}>{type.name}</p>
                                 )
                )}



                    </div>
                    }
                    {this.state.contribution.partners &&
                    <div className="col-md-12" ng-if="">
                        <h5 className="text-uppercase text-gray">partners</h5>
                        <p >{ this.state.contribution.partners}</p>

                        <hr/>
                    </div>
                    }
                    {this.state.contribution.budget &&
                    <div className="col-md-12">
                        <h5 className="text-uppercase text-gray">budget</h5>
                        <p>{ this.state.contribution.budget} { this.state.contribution.currency &&
                                <span>{this.state.contribution.currency.sign}</span>}
                        </p>

                        <hr/>
                    </div>
                    }
                    {this.state.contribution.contactName &&
                            <div className="col-md-12" >
                        <h5 className="text-uppercase text-gray">contact name</h5>
                            <p> {this.state.contribution.contactName}</p>

                        <hr/>
                    </div>
                    }
                    {this.state.contribution.contact &&
                    <div className="col-md-12" ng-if="">
                        <h5 className="text-uppercase text-gray">contact email</h5>
                        <p className="text-black">{this.state.contribution.contact}</p>

                        <hr/>
                    </div>
                    }
                    {this.state.contribution.city &&
                    <div className="col-md-12">
                        <h5 className="text-uppercase text-gray">city</h5>
                        <p className="text-black">{this.state.contribution.city}</p>

                    </div>
                    }
                </div>
            </div>
            <div className="col-md-4">
            {this.state.contribution.contentType == 0 &&
                     <ContributionProject contribution={this.state.contribution} />}
            {this.state.contribution.contentType == 1 &&
                    <ContributionEvent contribution={this.state.contribution} />}
            {this.state.contribution.contentType == 2 &&
                     <ContributionStory contribution={this.state.contribution} />}
            {this.state.contribution.contentType == 3 &&
                     <ContributionPublication contribution={this.state.contribution} />}
            {this.state.contribution.contentType == 4 &&
                     <ContributionTraining contribution={this.state.contribution} />}
            {this.state.contribution.contentType == 5 &&
                     <ContributionTool contribution={this.state.contribution} />}

            </div>
            {this.state.contributions.length > 0 &&
            <FromTheSameAuthor contributions={this.state.contributions}/>
            }
        </div>
         </div>
        }
    </div>
                );
}
}

IndividualContribution.propTypes = {
     contribution: PropTypes.object.isRequired,
      id: PropTypes.number.isRequired,
      auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
      contribution: state.contribution,
       auth: state.auth
});

export default connect(mapStateToProps, {getContribution})(IndividualContribution);
