import React from 'react';

export default class ErrorMessage extends React.Component {
    constructor(props) {
        super(props);

        this.errorMessages = {
            'required': 'Field is required',
            'minLength': 'Min length error',
            'maxLength': 'Max length error',
            'sameAs': 'Passwords do not match',
            'dateRange': 'Date not in open dates range',
             'minItems': 'Field is required',
             'invalid': 'The field contains invalid symbol.',
        };

        this.getError = this.getError.bind(this);
    }

    getError() {
        if(!(this.props.error in this.errorMessages) && this.props.message) return this.props.message;
        return this.props.error in this.errorMessages ? this.errorMessages[this.props.error] : this.props.error;
    }

    render() {
        return this.getError();
    }
}
