import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Layout from '../../../components/admin/layout/Layout'
import {
  PieChart, Pie, Sector, Cell,
} from 'recharts';
import ReactSpeedometer from "react-d3-speedometer"
import {getQuestionnaireReport } from '../../../actions/questionnaireActions'
import Navbar from '../../layout/Navbar'
import Footer from '../../layout/Footer'
import VerticalChart from './VerticalChart'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import domtoimage from 'dom-to-image';
import saveAs from 'file-saver'


const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

class UserReportComponent extends Component {
  constructor() {
    super();
    this.state = {

    };
  }

   downloadChart = (id) => {
    domtoimage.toJpeg(document.getElementById(id), { quality: 0.95, bgcolor: '#FFFFFF' })
    .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = id+'.jpeg';
        link.href = dataUrl;
        link.click();
    });
   }

 render() {
    const {questFill} = this.props;
    let reportData = null;
    var qLevel1='';
    var qLevel2='';
    var qLevel3='';
    if(questFill && questFill.reportData){
     reportData =  Object.keys(questFill.reportData).map(key => {
        return <Grid item lg={12} md={12} xs={12} style={{margin: 20}} key={key}>
               <h2>{key+': '}{questFill.reportData[key]['percentValue'] && <span>{questFill.reportData[key]['percentValue']+'%'}</span>}</h2>
                  { Object.keys(questFill.reportData[key]).map(secKey => {
                        return <Grid item lg={12} md={12} xs={12} style={{margin: 20}} key={secKey}>
                            {(key !== secKey) && (secKey !== 'percentValue') && <h3><span  className={(questFill.reportData[key][secKey]['percentValue'] > 50 ? 'highlight-green ' : 'highlight-red' )}>{secKey+': '}{questFill.reportData[key][secKey]['percentValue']+'%'}</span></h3>}
                            {typeof questFill.reportData[key][secKey] === 'object' &&
                            <>  {Object.keys(questFill.reportData[key][secKey]).map(trKey => {
                                return <Grid item lg={12} md={12} xs={12} style={{margin: 20}} key={trKey}>
                           {trKey !== 'percentValue' && <h4>{trKey +'  '+questFill.reportData[key][secKey][trKey]} </h4>}
                            </Grid>;
                                })}</>
                                }
                            </Grid>;
                      })}
                    </Grid>;

        })
    }
     const { classes } = this.props;
return (<>
      {questFill && <Grid container spacing={0} style={{background: 'white', marginTop: 80}}>
          <Grid item lg={12} md={12} xs={12} container style={{margin: 50}}>
      <Grid item lg={12} md={12} xs={12} style={{margin: 20}}>
          <h3>Organization: {questFill.organization}</h3>
          <h4>Focal points: {questFill.users}</h4>
          <h4>Overall score: {questFill.scorePercent+'%'}</h4>
          <h5>Result statement: This company scored in the {questFill.scorePercent} percentile of all respondents.</h5>
      </Grid>
    <Grid item lg={12} md={12} xs={12} id="speedometerChart">
    <IconButton onClick={(id) => this.downloadChart("speedometerChart")}>
                                    <GetAppIcon />
                                </IconButton>
    <ReactSpeedometer
        width={500}
        needleTransitionDuration={3333}
        needleTransition="easeElastic"
        textColor={"#d8dee9"}
        value={questFill.scorePercent * 10}
        customSegmentStops={[0, 250, 750, 1000]}
        segmentColors={["red", "yellow", "#6ad72d"]}
        currentValueText={questFill.scorePercent+'%'}
        customSegmentLabels={[
          {
            text: "0% - 40%",
            position: "INSIDE",
            color: "#d8dee9",
          },
          {
            text: "41% - 70%",
            position: "INSIDE",
            color: "#555",
          },
          {
            text: "71% - 100%",
            position: "INSIDE",
            color: "#555",
          },
        ]}
        ringWidth={47}
      />
      </Grid>
   {questFill.benchmarkChart && <Grid item lg={12} md={12} xs={12} style={{margin: 20}} id="benchmarkChart">
       <IconButton onClick={(id) => this.downloadChart("benchmarkChart")}>
                                    <GetAppIcon />
                                </IconButton>
        <h2>Benchmarking</h2>
        <VerticalChart data={questFill.benchmarkChart}/>
      </Grid>}
     {questFill.performanceChart && <Grid item lg={12} md={12} xs={12} style={{margin: 20}} id="performanceChart">
          <IconButton onClick={(id) => this.downloadChart("performanceChart")}>
                                    <GetAppIcon />
                                </IconButton>
        <h2>Performance</h2>
        <VerticalChart data={questFill.performanceChart}/>
      </Grid>}

          {reportData}

      </Grid>
      </Grid>}
        </>)
    }
}

export default UserReportComponent;
