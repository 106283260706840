import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import {getAddionalData} from '../../../actions/gridActions';

const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

class HealthProcurementReport extends Component {
  constructor() {
    super();
    this.state = {
      valueFilter: ''
    };
  }

     componentDidMount() {
       
    }

    componentWillReceiveProps(nextProps) {
   
    }


  render() {  
     const { classes } = this.props;
     const { addionalData } = this.props.gridProperties;
     
    return (           
      <Grid container spacing={0} >
          <Grid item  md={10}  >
        <Table className={classNames("m-bot30 mobileTable" , classes.table)} aria-label="simple table">
                               <TableHead>
                                <TableRow>
                                  <TableCell>UN health procurement per location</TableCell>
                                  <TableCell>Total health procurement volume (USD)</TableCell>
                                 </TableRow>
                               </TableHead>
                                <TableBody>
                                   {addionalData && addionalData.data && addionalData.data.map((row, key) => (
                                      <TableRow key={key}>
                                      <TableCell>{row.regionName}</TableCell>
                                      <TableCell>{row.value}</TableCell>
                                      </TableRow>
                                   ))}
                           {addionalData && addionalData.pinnedRows && <TableRow>
                                <TableCell><b>{addionalData.pinnedRows.regionName}</b></TableCell>
                                <TableCell>{addionalData.pinnedRows.value}</TableCell>
                            </TableRow>}
                                </TableBody>
                              </Table>  
                               </Grid>
   
      </Grid>
    );
  }
}

HealthProcurementReport.propTypes = {
  getAddionalData:  PropTypes.func.isRequired,
  gridProperties: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
   gridProperties: state.gridProperties,
});

HealthProcurementReport = withStyles(styles)(HealthProcurementReport)

export default connect( mapStateToProps, { getAddionalData })(withRouter(HealthProcurementReport));