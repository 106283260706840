import React from 'react';
import PropTypes from 'prop-types';

import './message.css';

class Message extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="settings_message" style={{display: this.props.show ? 'block' : 'none'}}>
                <p className={this.props.error ? 'error' : ''}>
                    {this.props.message}</p>
            </div>
        );
    }
}

Message.propTypes = {
    show: PropTypes.bool,
    message: PropTypes.string,
    error: PropTypes.bool,
};

Message.defaultProps = {
    show: false,
    message: '',
    error: false,
};

export default Message;
