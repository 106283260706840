import client from './client';

import {
  GET_REGIONS,
  GET_COUNTRIES,
  GET_SELECTED
} from './types';

// 
export const getRegionsFilter = () => dispatch => {  
  client
    .get('/web/filter/regions')
    .then(res =>
      dispatch({
        type: GET_REGIONS,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_REGIONS,
        payload: {}
      })
       
  });
};


export const getCountriesFilter = (id) => dispatch => {  
  client
    .post('/web/filters/countries', {'regionId': id})
    .then(res =>
      dispatch({
        type: GET_COUNTRIES,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_COUNTRIES,
        payload: {}
      })
       
  });
};

export const getSelectedLocationsFilter = (data) => dispatch => {  
  client
    .post('/web/filters/selecteds/locations', {'locations': data})
    .then(res =>
      dispatch({
        type: GET_SELECTED,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_SELECTED,
        payload: {}
      })
       
  });
};






