import React from 'react';
import { Link } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { logoutUser } from '../../../../actions/authActions.js';
import { clearCurrentProfile } from '../../../../actions/authActions.js';
import AssessmentIcon from '@material-ui/icons/Assessment';

const drawerWidth = "100%";

const styles = theme => ({
    root: {
        display: 'flex',
        width: '100%',
    },
    heading: {
        fontSize: 18,
        fontWeight: 300,
        color: "#444",
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: 18,

    },
    appBar: {
        display: 'none',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
});

class MenuMobile extends React.Component {

  constructor(props) {
      super(props);

      this.state = {
        profile: props.profileItem,
       }
    }

     onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
    this.props.clearCurrentProfile();
  }

    state = {
        expanded: null,
    };

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };
    
    change = () => {
        this.props.change()
        
    }

    render() {

        const settings = this.props.settings;
        const { classes } = this.props;
        const { expanded } = this.state;
        const open = settings.sidebar !== 'nav-collapse closed';

        return (
            <div className={classes.root}>
                {settings.sidebar &&
                    <React.Fragment>
                        <CssBaseline />
                        <AppBar position="fixed"><div></div></AppBar>
                        <Drawer
                            className={classes.drawer}
                            variant="persistent"
                            anchor="left"
                            open={open}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            <div className={classes.drawerHeader}>
                                <IconButton onClick={this.props.change}>
                                    <ChevronLeftIcon />
                                </IconButton>
                            </div>
                            <Divider />

                            <Link to={'/internal'} style={{ padding: '15px 20px', fontWeight: 300, color: "#444", fontSize: 18 }} onClick={this.change}>
                                <i className="icon icon-dashboard" style={{ marginRight: 5 }}></i>
                                <span>Dashboard</span>
                            </Link>

                            <ExpansionPanel expanded={expanded === 'crm'} onChange={this.handleChange('crm')}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography className={classes.heading}>
                                        <i className="icon icon-accounts" style={{ marginRight: 5 }}></i>
                                        <span>CRM</span>
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <ul onClick={this.props.change}>
                                        {this.props.settings.lists && this.props.settings.lists.map((list, key) => (
                                            <li className="col-sm-6 marginBottom" key={key}>
                                                <Link to={'/admin/accounts/list/' + list.id}>
                                                    {list.id === 1
                                                        ? <i className="far fa-address-book"></i>
                                                        : <i className="fas fa-university"></i>
                                                    }
                                                    <span style={{ padding: 20 }}>{list.name}</span>
                                                </Link>
                                            </li>
                                        ))}
                                        <li className="col-sm-6 marginBottom">
                                            <Link to={'/admin/collaborations'}>
                                                <i className="fas fa-puzzle-piece"></i>
                                                <span style={{ padding: 20 }}>Collaborations</span>
                                            </Link>
                                        </li>
                                        <li className="col-sm-6 marginBottom">
                                            <Link to={'/admin/collaborations/calendar'}>
                                                <i className="fas fa-calendar-alt"></i>
                                                <span style={{ padding: 20 }}>Collaborations Calendar</span>
                                            </Link>
                                        </li>
                                        <li className="col-sm-6 marginBottom">
                                            <Link to={'/admin/accounts/duplicated/email'}>
                                                <i className="fas fa-copy"></i>
                                                <span style={{ padding: 20 }}>Duplicated Contacts</span>
                                            </Link>
                                        </li>
                                        <li className="col-sm-6 marginBottom">
                                            <Link to={'/admin/accounts/duplicated/companyName'}>
                                                <i className="fas fa-copy"></i>
                                                <span style={{ padding: 20 }}>Duplicated Organizations</span>
                                            </Link>
                                        </li>
                                        <li className="col-sm-6 marginBottom">
                                            <Link to={'/admin/data-retention'}>
                                                <i className="fas fa-trash-alt"></i>
                                                <span style={{ padding: 20 }}>Data retention</span>
                                            </Link>
                                        </li>
                                        {
                                            this.props.settings.consent &&
                                            <li className="col-sm-6 marginBottom">
                                                <Link to={'/crm/accounts/for_deleting'}>
                                                    <i className="fa fa-fw fa-circle-o-notch"></i>
                                                    <span>Data Retention Expired</span>
                                                </Link>
                                            </li>
                                        }
                                        <li className="col-sm-6 marginBottom">
                                            <Link to={'/admin/account-lists'}>
                                                <i className="fa fa-list"></i>
                                                <span style={{ padding: 20 }}>Lists</span>
                                            </Link>
                                        </li>
                                         <li className="col-sm-6 marginBottom" >
                                            <Link title="CRM Reporting" to={'/admin/crm/reports'}>
                                                <AssessmentIcon/>
                                                <span style={{ padding: 20 }}>CRM Reporting</span>
                                            </Link>
                                        </li>
                                    </ul>

                                </ExpansionPanelDetails>
                            </ExpansionPanel>

                            <ExpansionPanel expanded={expanded === 'website'} onChange={this.handleChange('website')}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography className={classes.heading}>
                                        <i data-icon="h" className="icon" style={{ marginRight: 5 }}></i>
                                        <span>Website</span>
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <ul onClick={this.props.change}>
                                        <li className="col-sm-6 marginBottom">
                                            <Link to={'/admin/contributions'}>
                                                <i className="fas fa-circle-notch"></i>
                                                <span style={{ padding: 20 }}>SPHS Contributions</span>
                                            </Link>
                                        </li>
                                        <li className="col-sm-6 marginBottom">
                                            <Link to={'/admin/contributions/review'}>
                                                <i className="fas fa-circle-notch"></i>
                                                <span style={{ padding: 20 }}>Public Contributions</span>
                                            </Link>
                                        </li>
                                        <li className="col-sm-6 marginBottom">
                                            <Link to={'/admin/news'}>
                                                <i className="fas fa-microphone"></i>
                                                <span style={{ padding: 20 }}>News</span>
                                            </Link>
                                        </li>
                                        <li className="col-sm-6 marginBottom">
                                            <Link to={'/admin/users/active'}>
                                                <i className="fas fa-users"></i>
                                                <span style={{ padding: 20 }}>Users</span>
                                            </Link>
                                        </li>
                                        <li className="col-sm-6 marginBottom" >
                        <Link title="news" to={'/admin/users-sessions/list'}>
                           <i className="fa fa-history"></i>
                            <span style={{ padding: 20 }}>Users log</span>
                        </Link>
                    </li>
                     <li className="col-sm-6 marginBottom" >
                        <Link title="news" to={'/admin/user-track/list'}>
                           <i className="fa fa-history"></i>
                            <span style={{ padding: 20 }}>User tracking</span>
                        </Link>
                    </li>
                     <li className="col-sm-6 marginBottom" >
                        <Link title="news" to={'/admin/website-usage/list'}>
                           <i className="fa fa-history"></i>
                            <span style={{ padding: 20 }}>Website usage</span>
                        </Link>
                    </li>
                     <li className="col-sm-6 marginBottom" >
                        <Link title="news" to={'/admin/website-tools-usage/list'}>
                           <i className="fa fa-history"></i>
                            <span style={{ padding: 20 }}>Website tools usage</span>
                        </Link>
                    </li>
                     <li className="col-sm-6 marginBottom" >
                        <Link title="news" to={'/admin/website-tools-inactive/list'}>
                           <i className="fa fa-history"></i>
                            <span style={{ padding: 20 }}>Website tools inactive users</span>
                        </Link>
                        </li>
                    <li className="col-sm-6 marginBottom" >
                        <Link title="news" to={'/admin/user-track/report'}>
                           <AssessmentIcon/>
                            <span style={{ padding: 20 }}>User track report</span>
                        </Link>
                    </li>
                                    </ul>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>

                            <ExpansionPanel expanded={expanded === 'oet'} onChange={this.handleChange('oet')}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography className={classes.heading}>
                                        <i className="icon glyphicon glyphicon-th-list" style={{ marginRight: 5 }}></i>
                                        <span>SA</span>
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <ul onClick={this.props.change}>
                                        <li className="col-sm-6 marginBottom">
                                            <Link to={'/admin/questionnaire/active'}>Questionnaires</Link>
                                        </li>
                                        <li className="col-sm-6 marginBottom">
                                            <Link to={'/admin/quest-results'}>Results</Link>
                                        </li>
                                        <li className="col-sm-6 marginBottom">
                                            <Link to={'/admin/products'}>Products</Link>
                                        </li>
                                        <li className="col-sm-6 marginBottom">
                                            <Link to={'/admin/products-category'}>Products Categories</Link>
                                        </li>                                       
                                        <li className="col-sm-6 marginBottom">
                                            <Link to={'/admin/procurements'}>Procurements</Link>
                                        </li>
                                    </ul>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>

                            <ExpansionPanel expanded={expanded === 'documents'} onChange={this.handleChange('documents')}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography className={classes.heading}>
                                        <i className="icon icon-documents" style={{ marginRight: 5 }}></i>
                                        <span>Documents</span>
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <ul onClick={this.props.change}>
                                        <li className="col-sm-6 marginBottom">
                                            <Link to={'/admin/documents'}>
                                                <i className="far fa-file"></i>
                                                <span style={{ paddingLeft: 20 }}>Document Management</span>
                                            </Link>
                                        </li>
                                        <li className="col-sm-6 marginBottom">
                                            <Link to={'/admin/resources'}>
                                                <i className="far fa-file"></i>
                                                <span style={{ paddingLeft: 20 }}>Resources</span>
                                            </Link>
                                        </li>
                                        <li className="col-sm-6 marginBottom">
                                            <Link to={'/admin/documents/deleted'}>
                                                <i className="far fa-file"></i>
                                                <span style={{ paddingLeft: 20 }}>Deleted files</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>

                            <ExpansionPanel expanded={expanded === 'reports'} onChange={this.handleChange('reports')}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography className={classes.heading}>
                                        <i className="icon icon-reports" style={{ marginRight: 5 }}></i>
                                        <span>Reports</span>
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                   <ul onClick={this.props.change}>
                                             <li className="col-sm-6 marginBottom">
                                                  <a className="draggable" title={"UN SPHS Health Procurement Report"}
                                                     href={ '/admin/report/health-procurements'}>
                                                      <span>UN SPHS Health Procurement Report</span>
                                                  </a>
                                              </li>
                                              <li className="col-sm-6 marginBottom">
                                                  <a className="draggable" title={"SA Comprehensive Report"}
                                                     href={ '/admin/report/financial'}>
                                                      <span>SA Comprehensive Report</span>
                                                  </a>
                                              </li>
                                              <li className="col-sm-6" style={{padding: "5px 0"}}>
                                                <a className="draggable" title={"UNDP GPU Conventions"}
                                                   href={'/admin/gpu/conventions/reports'}>
                                                    <span>UNDP GPU Conventions</span>
                                                </a>
                                            </li>
                                                <li className="col-sm-6 marginBottom">
                                                  <span><hr style={{borderTop: "1px solid #203648"}}/></span>
                                              </li>
                                                <li className="col-sm-6 marginBottom">
                                                  <h4>
                                                    SA Questionnaire Reports
                                                  </h4>
                                              </li>
                                                <li className="col-sm-6 marginBottom">
                                                  <a className="draggable" title={"Scores by UNSPSC product family"}
                                                     href={ '/admin/report/unspsc-product-family'}>
                                                      <span>Scores by UNSPSC product family</span>
                                                  </a>
                                              </li>
                                                <li className="col-sm-6 marginBottom">
                                                  <a className="draggable" title={"Individual Suppliers Report"}
                                                     href={ '/admin/report/individual-suppliers'}>
                                                      <span>Individual Suppliers Report</span>
                                                  </a>
                                              </li>
                                              <li className="col-sm-6 marginBottom">
                                                  <a className="draggable" title={"All suppliers score"}
                                                     href={'/admin/report/all-scores-report'}>
                                                      <span>All suppliers score</span>
                                                  </a>
                                              </li>
                                              <li className="col-sm-6 marginBottom">
                                                  <a className="draggable" title={"Supplier country"}
                                                     href={'/admin/report/supplier-country-report'}>
                                                      <span>Supplier country</span>
                                                  </a>
                                              </li>
                                                <li className="col-sm-6 marginBottom">
                                                  <a className="draggable" title={"Scores by UNSPSC product segment"}
                                                     href={'/admin/report/unspsc-product-segment'}>
                                                      <span>Scores by UNSPSC product segment</span>
                                                  </a>
                                              </li>
                                              <li className="col-sm-6 marginBottom">
                                                  <a className="draggable" title={"Members Averages Report"}
                                                     href={'/admin/report/members-averages-report'}>
                                                      <span>Members Averages Report</span>
                                                  </a>
                                              </li>
                                              <li className="col-sm-6 marginBottom">
                                                  <a className="draggable" title={"SA EQ Report"}
                                                     href={'/admin/report/oet-eq-report'}>
                                                      <span>SA EQ Report</span>
                                                  </a>
                                              </li>
                                                <li className="col-sm-6 marginBottom">
                                                  <a className="draggable" title={"EQ MC Results report"}
                                                     href={'/admin/report/mc-report'}>
                                                      <span>EQ MC Results report</span>
                                                  </a>
                                              </li>
                                  </ul>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>

                            <Link to={'/help'} style={{ padding: '15px 20px', fontWeight: 300, color: "#444", fontSize: 18 }} onClick={this.change}>
                                <i className="icon glyphicon glyphicon-info-sign" style={{ marginRight: 5 }}></i>
                                <span>Help</span>
                            </Link>

                            <ExpansionPanel expanded={expanded === 'administration'} onChange={this.handleChange('administration')}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography className={classes.heading}>
                                        <i className="icon icon-administration" style={{ marginRight: 5 }}></i>
                                        <span>Administration</span>
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>

                                    <ul onClick={this.props.change}>
                                        <li className="col-sm-6 marginBottom">
                                            <Link to={'/admin/members'}>Members</Link>
                                        </li>
                                        <li className="col-sm-6 marginBottom">
                                            <Link to={'/admin/sdgs'}>SDG</Link>
                                        </li>
                                        <li className="col-sm-6 marginBottom">
                                            <Link to={'/admin/locations'}>Locations</Link>
                                        </li>
                                        <li className="col-sm-6 marginBottom">
                                            <Link to={'/admin/focus-areas'}>Focus areas</Link>
                                        </li>
                                        <li className="col-sm-6 marginBottom">
                                            <Link to={'/admin/analysis-codes'}>Codes</Link>
                                        </li>
                                        <li className="col-sm-6 marginBottom">
                                            <Link to={'/admin/analysis-categories'}>Categories</Link>
                                        </li>
                                        <li className="col-sm-6 marginBottom">
                                            <Link to={'/admin/templates/emails'}>Emails</Link>
                                        </li>
                                    </ul>

                                </ExpansionPanelDetails>
                            </ExpansionPanel>

                            <ExpansionPanel expanded={expanded === 'sphs-admin'} onChange={this.handleChange('sphs-admin')}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography className={classes.heading}>
                                        <i className="icon icon-administration" style={{ marginRight: 5 }}></i>
                                        <span>Profile</span>
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>

                                    <ul onClick={this.props.change}>
                                        <li className="col-sm-6 marginBottom">
                                            <Link to={'/contribute/profile'}>Account</Link>
                                        </li>
                                        <li className="col-sm-6 marginBottom">
                                            <a className="logout" href="#" onClick={this.onLogoutClick} target="_self">Sign out</a>
                                        </li>
                                    </ul>

                                </ExpansionPanelDetails>
                            </ExpansionPanel>




                        </Drawer>
                    </React.Fragment>
                }
            </div>
        );
    }
}

export default withStyles(styles)(MenuMobile);
