import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Layout from '../../../components/admin/layout/Layout'
import {
  PieChart, Pie, Sector, Cell,
} from 'recharts';
import ReactSpeedometer from "react-d3-speedometer"
import {getQuestionnaireReport } from '../../../actions/questionnaireActions'
import VerticalChart from './VerticalChart'
import RadarsChart from './RadarsChart'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

class EAReportComponent extends Component {
  constructor() {
    super();
    this.state = {
      
    };
  }
  render() {
    const {questFill} = this.props; 
     const { classes } = this.props;
      let reportData = null;
    var qLevel1='';
    var qLevel2='';
    var qLevel3='';
    if(questFill && questFill.reportData){
     reportData =  Object.keys(questFill.reportData).map(key => { 
        return <Grid item lg={12} md={12} xs={12} style={{margin: 20}} key={key}>
               <h2>{key}{questFill.reportData[key]['percentValue'] ? <span>{': '+questFill.reportData[key]['percentValue']+'%'}</span> : <>{questFill.reportData[key][key] && questFill.reportData[key][key]['percentValue'] && <span>{': '+questFill.reportData[key][key]['percentValue']+'%'}</span> }</>}</h2>
                  { Object.keys(questFill.reportData[key]).map(secKey => {
                      
                        return <Grid item lg={12} md={12} xs={12} style={{margin: 20}} key={secKey}>
                            {(key !== secKey) && (secKey !== 'percentValue') && <h3>{secKey+': '}{questFill.reportData[key][secKey]['percentValue']+'%'}</h3>}
                            {typeof questFill.reportData[key][secKey] === 'object' &&
                            <>  {Object.keys(questFill.reportData[key][secKey]).map(trKey => {
                                return <Grid item lg={12} md={12} xs={12} style={{margin: 20}} key={trKey}>
                           {trKey !== 'percentValue' && <h4>{questFill.reportData[key][secKey][trKey] === "0" && <CheckBoxOutlineBlankIcon/>}{questFill.reportData[key][secKey][trKey] === "1" && <CheckBoxIcon/>}{trKey} </h4>}
                            </Grid>;
                                })}</>
                                }
                            </Grid>;
                      })}                          
                    </Grid>;   
        
        })                                               
    }    
    return (<>{questFill && <Grid container spacing={0} style={{background: 'white'}}>
          <Grid item lg={6} md={6} xs={6} container> 
      <Grid item lg={12} md={12} xs={12} style={{margin: 20}}>    
          <h3>Organization: {questFill.organization}</h3>
          <h4>Focal points: {questFill.users}</h4>
          <h4>Overall score: {questFill.scorePercent+'%'}</h4>
          <h5>Result statement: This company scored in the {questFill.scorePercent} percentile of all respondents.</h5>
      </Grid>
     {questFill.benchmarkChart && <Grid item lg={12} md={12} xs={12} style={{margin: 20}}>
        <h2>Benchmarking</h2>
        <VerticalChart data={questFill.benchmarkChart}/>
      </Grid>}
      <Grid item lg={2} md={2} xs={6}></Grid>
     {questFill.performanceChart && <Grid item lg={8} md={8} xs={12} style={{margin: 20}}>
        <h2>Performance</h2>
        <RadarsChart data={questFill.performanceChart}/>
      </Grid>}
        {reportData}
        {questFill.finalComment && <Grid item lg={8} md={8} xs={12} style={{margin: 20}}>
        <h2>Final Comment</h2>
        {questFill.finalComment}
      </Grid>}
      </Grid>
      </Grid>}</>);
  }
}

export default  EAReportComponent;