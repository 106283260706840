import React, { Component }  from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form'
import { renderTextField } from '../common/MaterialUiForm'
import CheckboxGroup from '../common/CheckboxGroup'
import Grid from '@material-ui/core/Grid';
import { ToastContainer, toast } from 'react-toastify'
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css'
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';




const styles = theme => ({
  root: {
    htmlFontSize: 20,
  }
,
  textField: {
    background: "white",
    borderRadius: 4,
  },
  labelRoot: {
    fontSize: 14,
},
  textRoot:{
    margin: 0,
    paddingTop: 10,
    fontSize: 14,
},
  formControl: {
    background: "#ffffff",
    width: "100",
    padding: "0 15px",
    height: 48,
    borderRadius: 4,
    border: "1px solid #c7c7c7",
    margin: "0 0 15px 0"
  },
});

const validate = values => {

  const errors = {}
  const requiredFields = [
    'current_password',
    'first',
    'second'
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'This value should not be blank.'
    }
  })
  if (values.first && values.second) {
      if (values.first !== values.second) {
    errors.second = 'Passwords must match.'
      }
  }
  return errors
}

class ChangePasswordForm extends Component {
    constructor(props) {
     super(props);
        this.state = {
        showCurrentPassword: false,
        showFirstPassword: false,
        showSecondPassword: false
        }

}

 handleClickShowPassword = () => {
    this.setState(state => ({ showCurrentPassword: !state.showCurrentPassword }));
  };

 handleClickFirstShowPassword  = () => {
    this.setState(state => ({ showFirstPassword: !state.showFirstPassword }));
  };

  handleClickSecondShowPassword   = () => {
    this.setState(state => ({ showSecondPassword: !state.showSecondPassword }));
  };

  resetForm = () => this.setState({ imageFile: [] }, () => this.props.reset());

    render () {
  const { handleSubmit, pristine, reset, submitting, classes } = this.props;
  const { user } = this.props.auth;


  return (
   <Grid container className="noPadding" direction="row" spacing={0}>
    <form onSubmit={handleSubmit} >
 <Grid container  direction="row" spacing={0} style={{
   padding:15
 }} >
 <Grid md={1} lg={1} item></Grid>
      <Grid md={10} lg={10} item className={classes.root}>
        <Field
          name="current_password"
          id="outlined-dense"
          type={this.state.showCurrentPassword ? 'text' : 'password'}
          className={classNames(classes.textField)}
          component={renderTextField}
          label="Current password *"
          variant="outlined"
          InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={this.handleClickShowPassword}
                        >
                          {this.state.showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    classes: {
                    root: classes.labelRoot
                  }
                  }}
         InputLabelProps={{
            style: {
             fontSize: 16
            }
          }}
          FormHelperTextProps={{
          classes:{
              root: (classes.labelRoot, classes.textRoot)
            }
          }}
          />
        <Field
          name="first"
          id="outlined-dense-first"
          type={this.state.showFirstPassword ? 'text' : 'password'}
          className={classNames(classes.textField)}
          component={renderTextField}
          label="New password *"
          variant="outlined"
           InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={this.handleClickFirstShowPassword}
                        >
                          {this.state.showFirstPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    classes: {
                    root: classes.labelRoot
                  }
                  }}
         InputLabelProps={{
            style: {
             fontSize: 16
            }
          }}
          FormHelperTextProps={{
          classes:{
              root: (classes.labelRoot, classes.textRoot)
            }
          }}
        />

        <Field
          name="second"
         type={this.state.showSecondPassword ? 'text' : 'password'}
          id="outlined-dense-second"
          className={classNames(classes.textField)}
          component={renderTextField}
          label="Confirm new password *"
          variant="outlined"
           InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={this.handleClickSecondShowPassword}
                        >
                          {this.state.showSecondPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    classes: {
                    root: classes.labelRoot
                  }
                  }}
          InputLabelProps={{
            style: {
             fontSize: 16
            }
          }}
          FormHelperTextProps={{
          classes:{
              root: (classes.labelRoot, classes.textRoot)
            }
          }}
          />

  </Grid>

      </Grid>
    <Grid item container  direction="row" spacing={4} className="register-footer">
        <Grid md={3} lg={3} item></Grid>
        <Grid md={8} lg={8} sm={10} xs={10} item className="center-col">

            <button type="submit" disabled={pristine || submitting} style={{ display: 'inline', margin: 15}} className="blue-button ">
                Submit
            </button>
            <button type="button" disabled={pristine || submitting} onClick={reset} style={{ margin: 15}} color="default" onClick={this.resetForm}>
                Clear Fields
            </button>
        </Grid>
      </Grid>
    </form>
    <div className="form-group">

</div>
     </Grid>
  );
  }
}


ChangePasswordForm.propTypes = {
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

ChangePasswordForm = withStyles(styles)(ChangePasswordForm)

export default connect(mapStateToProps, {})(reduxForm({form: 'ChangePasswordForm', validate})(ChangePasswordForm))
