import React from 'react';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import {onSelect2InputChange} from '../../../../actions/formActions';
import Select2 from './Select2';
import showAToast from '../../../../helpers/showAToast'

class Sections extends React.Component {
        constructor(props) {
        super(props);
                this.state = {
                values: [],
                rows: [],
                options: [],
                field: null
                }
        }

    componentDidMount() {       
        this.setState({
            options: this.props.field.options,
            field: this.props.field
        })
        if (this.props.data && typeof this.props.data[this.props.field.id] !== 'undefined'){
                var rows = []
            if (this.props.data[this.props.field.id].length > 0){
                this.props.data[this.props.field.id].map(row => (
                    rows.push(1)
                ))
            }

            this.setState({
                rows: rows,
                values: this.props.data[this.props.field.id] ? this.props.data[this.props.field.id] : []
            })
        }
    }
    
    componentWillReceiveProps(nextProps) {        
        if(Object.keys(nextProps.form['initializeFromState2'].values).length ===  0){
             this.setState({
                 values: []
             })
        }
    }
    
    onChange = (e, name, k, key) => {           
        var values = this.state.values;
      
        if (typeof values[k] === 'undefined'){
            values[k] = {}
        }
        if(e.value && typeof e.value !== 'undefined'){       
                values[k][key] = e.value;       
        }else{
            if(name === 'points'){
               var pointsValue = e.target.value ? parseInt(e.target.value) : '';
               
               if(pointsValue && Number.isInteger(pointsValue)){
                   values[k][key] = pointsValue
               }else if(pointsValue === ''){
                  values[k][key] = pointsValue
               }else{
                showAToast("Please add the section points as a whole number.", 'errorPoints', 'error')
                 values[k][key] = e.target.value
            }
                 
            }else{
                values[k][key] = e.target.value;
            }
        }
        
        this.setState({
            values: values
        })
       
        this.props.input.onChange(values)
    }

    addField = () => {
        var rows = this.state.rows;
        rows.push(1)
        this.setState({
            rows: rows
        })
    }

    removeField = (k) => {
        var rows = this.state.rows;
        var values = this.state.values;
        rows.splice(k, 1);
        values.splice(k, 1);
     
        this.setState({
            rows: rows,
            values: values
        })
        this.props.input.onChange(values)
    }

render() {
    const {field, label, input, meta: { touched, invalid, error }, custom} = this.props;
   
    return <>
        {this.state.rows.length > 0 &&
    <>{this.state.rows.map((val, k) => (
    <Grid container direction="row" spacing={4} key={k}>
        {this.state.field && this.state.field.collectionFields && Object.keys(field.collectionFields).length > 0 &&
        <>{Object.keys(this.state.field.collectionFields).map(key => {      
    
            if (this.state.field.collectionFields[key].type === 'select' && this.state.options && this.state.options[this.state.field.collectionFields[key].name]){
            return <Grid item md={this.state.field.collectionFields[key].col ? this.state.field.collectionFields[key].col : 6} key={key}>
            {this.state.field.collectionFields[key].label && <label>{this.state.field.collectionFields[key].label}</label>}
            
            <select className={this.state.values[k] && this.state.values[k][key] ? "form-control form-contacts" : "form-control form-contacts"}
            key={key}
            name={this.state.field.collectionFields[key].name}
            value={this.state.values[k] && this.state.values[k][key] ? this.state.values[k][key] : ''}
            onChange={(e) => this.onChange(e, this.state.field.collectionFields[key].name, k, key)}
            style={this.state.values[k] && this.state.values[k][key] ? {height: 60}: {height: 60, borderColor: 'rgb(244, 67, 54)'}}
            >
                {this.state.field.collectionFields[key].empty && typeof this.state.field.collectionFields[key].empty !== 'undefined' &&
                <option value="" disabled defaultValue>{this.state.field.collectionFields[key].placeholder}</option>
                }
                {this.state.options[this.state.field.collectionFields[key].name].map((option, i) => (
                <option value={option.value} key={i}>{option.label}</option>
                                    ))}
            </select>
            {this.state.values[k] && this.state.values[k][key] ? <span></span> : <span style={{color: 'rgb(244, 67, 54)', fontSize: 14}}>This field is required!</span> }
        </Grid>
        }

        if(this.state.field.collectionFields[key].type === 'text'){
        var value = null;
       if(this.state.field.collectionFields[key].name === 'sectionName'){
        if(typeof this.state.values[k] === 'undefined'){
            value = false
           
        }else if(this.state.values[k] && !this.state.values[k][key]){
        value = false
       
        }else if(this.state.values[k] && this.state.values[k][key] === ''){
       
        }else if(this.state.values[k] && this.state.values[k][key]){
       value = true
        
                            }
        }
         return <Grid item md={this.state.field.collectionFields[key].col ? this.state.field.collectionFields[key].col : 6} key={key}>
            {this.state.field.collectionFields[key].label && <label>{this.state.field.collectionFields[key].label}</label>}
            <input type="text" 
            className="form-control"
            onChange={(e) => this.onChange(e, this.state.field.collectionFields[key].name, k, key)} 
            name={this.state.field.collectionFields[key].name} 
            placeholder={this.state.field.collectionFields[key].placeholder && this.state.field.collectionFields[key].placeholder} 
            value={this.state.values[k] ? this.state.values[k][key] : ''} 
            style={this.state.field.collectionFields[key].required && !value ? {height: 60, borderColor: 'rgb(244, 67, 54)'} : {height: 60}}
            />
         {this.state.field.collectionFields[key].required && !value && <span style={{color: 'rgb(244, 67, 54)', fontSize: 14}}>This field is required!</span> }
         
                            </Grid>
        }
         if(this.state.field.collectionFields[key].type === 'hidden'){
         return <Grid item md={2} key={key}>
          <input type="hidden" onChange={(e) => this.onChange(e, this.state.field.collectionFields[key].name, k, key)} name={this.state.field.collectionFields[key].name} value={this.state.values[k] ? this.state.values[k][key] : ''}/>
        </Grid>
        }
        })}</>
        }
        
        <Grid item md={6}>
            <Button size="small" variant="outlined" color="secondary" onClick = {() => this.removeField(k)}>{field.buttonName ? <span>Remove {field.buttonName}</span> : <span>Remove field</span>}</Button>
            <br/><br/>
        </Grid>
    </Grid>
                                    ))}
    </>
    }
    <Button variant="outlined" color="primary" onClick = {this.addField}>
    {field.buttonName ? <span>Add {field.buttonName}</span> : <span>Add field</span>}
    </Button>
    </>
  }
}


Sections.defaultProps = {
    onNewOptions: () => {},
};

const mapStateToProps = state => ({
    form: state.form
});

const mapDispatchToProps = dispatch => ({
    onInputChange: (formId, id, value, route) => dispatch(onSelect2InputChange(formId, id, value, route)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sections);
