import React from 'react';

export default class Popup extends React.Component {

    constructor(props) {
        super(props);

//        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleEscClick = this.handleEscClick.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('keydown', this.handleEscClick);
    }

//    handleClickOutside(event) {
//        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
//            this.props.onClose();
//        }
//    }

    handleEscClick(e) {
        if (e.keyCode === 27) {
            this.props.onClose();
        }
    }

    render() {

        const overlayStyle = {
            backgroundColor: '#000',
            position: 'absolute', 'top': '0px',
            left: '0px', width: '100%',
            height: '100%',
            zIndex: '2000',
            opacity: 0.4
        };

        return (
            <div className={"modal fade bd-example-modal-lg " + (this.props.large ? 'x-lg' : '') + " in"}
                 aria-hidden="false" style={{display: "block"}}>

                <div style={overlayStyle}></div>

                <div className="modal-dialog modal-lg">
                    <div className="modal-content" ref={this.setWrapperRef}>
                        <div className="modal-header"><h5 style={{fontSize: 18, fontWeight: 600}}className="modal-title text-white">{this.props.title}</h5>
                            <button type="button" className="close" onClick={this.props.onClose}>
                                <span aria-hidden="true" className="ion-android-close" style={{fontSize: 18}}></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {this.props.children}
                        </div>
                        <div className="modal-footer">

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
