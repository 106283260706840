import React, {Component} from 'react';
import {connect} from "react-redux";
import {Button, Dialog, DialogActions, DialogTitle, DialogContent} from "@material-ui/core";
import PropTypes from 'prop-types';

class SpeakersModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
              open: false
        }
    }

    handleClickOpen = event => {
    event.preventDefault();
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({
        open: false
    });
  };

    render() {

    return (
          <span>
        <a href="" onClick={this.handleClickOpen}  className="text-uppercase login-link">
        <img src={this.props.speaker.image} all="image" alt="speaker" style={{width: 240, height: 240}}/>
        </a>
  <Dialog open={this.state.open}
          onClose={this.handleClose}>
       <DialogTitle className=" text-center" style={{ background: "#203648", color: 'white', fontSize: '16px', textAlign: 'center'}} disableTypography={true}>
           {this.props.speaker.name}</DialogTitle>
       <DialogContent style={{ textAlign: 'center', marginTop: '10px'}}>
           {this.props.speaker.text}
           {this.props.speaker.links &&
                <div  dangerouslySetInnerHTML={{__html: this.props.speaker.links}} style={{margin: 15}}></div>
                    }
                    </DialogContent>
            <DialogActions>
            <Button onClick={this.handleClose} color="primary" style={{fontSize: 14}} className="btn btn-secondary">
              Close
            </Button>
        </DialogActions>
   </Dialog>
   </span>
   );
   }
   }

export default connect()(SpeakersModal);
