import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import isEmpty from '../../validation/is-empty';
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import { Resizable, ResizableBox } from 'react-resizable';
import Truncate from 'react-truncate';

class MemberItem extends Component {
    constructor(props) {
        super(props);
       
        this.state = {
       
        }
    }

  render() {
    
    return (
          <span>  {this.props.memberItem &&
        <Grid item md={3} sm={4} xs={12} className="five-column">
            <div className="member">
              <a href={`/members/${this.props.memberItem.name.toLowerCase()}/${this.props.memberItem.id}`}>
              <h2> 
              <Truncate lines={2}>{ this.props.memberItem.name}</Truncate>              
                  </h2>
                  <hr/>
                <div>
                 <Truncate lines={2} className="member-desc">{ this.props.memberItem.shortDesc}</Truncate> 
                </div>
               </a>
            </div>
        </Grid>}</span>
    );
  }
}

MemberItem.propTypes = {
  memberItem: PropTypes.object.isRequired
};

export default MemberItem;
