import React, { Component } from 'react';
import Sectors from './Sectors'
import Sdgs from './Sdgs'
import Contribute from '../Contribute'
import Grid from '@material-ui/core/Grid'
import SocialNetworks from './SocialNetworks'
import SaveForLater from '../SaveForLater'
import LoginForm from '../../../layout/LoginForm'
import Diff from 'react-stylable-diff'

class ContributionTraining extends Component {
  render() {
      
  const contribution = this.props.contribution;   
return (
        <div>
    <div className="col-md-12 right-header" >
   {contribution.organizers &&
    <div className="col-md-12 clearfix" >
        <h5 className="text-dark-blue-old">Organizers</h5>
        {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor ?
        <p className="text-white" >
        <Diff type="sentences" inputA={contribution.reflection.organizers} inputB={contribution.organizers}/>
        </p>
                          :
                         <p className="text-white" >{ contribution.organizers}</p>   
                      }    
            
        <hr className="blue-line col-md-12 noPadding"/>
    </div>}
    <div className="col-md-12 clearfix">
    
        <div className="col-md-9 noPadding" >
              {contribution.projectAt &&
            <div className="col-md-12 noPadding clearfix" ng-if="">
                <div className="col-xs-6 noPadding ">
                    <h5 className="text-uppercase project-date text-dark-blue-old pull-left">training start: </h5>
                </div>
                <div className="col-xs-6 noPadding ">
                    <h5 className="text-white pull-left">                    
                         {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor ?
                        <Diff type="sentences" inputA={contribution.reflection.projectAt} inputB={contribution.projectAt}/>
                          :
                          <span>{ contribution.projectAt}</span>
                      }                 
                        </h5>
                    </div>                  
                </div>
                }
                {contribution.projectTo &&
                <div className="col-md-12 noPadding clearfix">
                    <div className="col-xs-6 noPadding ">
                        <h5 className="text-uppercase project-date text-dark-blue-old pull-left">training end: </h5>
                    </div>
                    <div className="col-xs-6 noPadding ">
                        <h5 className="text-white pull-left" >
                           {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor ?
                        <Diff type="sentences" inputA={contribution.reflection.projectTo} inputB={contribution.projectTo}/>
                          :
                          <span>{ contribution.projectTo}</span>
                      }
                            {!contribution.projectTo && <span ng-if="!contribution.projectTo">N/A</span>}
                        </h5>
                    </div>
                </div>
                }
            </div>
            
        </div>
        <hr className="blue-line col-md-12 noPadding"/>
    </div>

{contribution.sectors &&
        <Sectors sectors={contribution.sectors}/>
}
{contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor &&
<Diff type="sentences" inputA={contribution.reflection.sectorsText} inputB={contribution.sectorsText}/>
}
 <LoginForm role="filter"/>
{contribution.href &&
<SocialNetworks url={contribution.href}/>
}
<SaveForLater id={contribution.id} type="contrib"/>

{contribution.sdg &&
<Sdgs sdgs={contribution.sdg}/>
}
{contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor &&
<Diff type="sentences" inputA={contribution.reflection.sdgText} inputB={contribution.sdgText}/>
}
{contribution.agenda &&        
<div className="col-xs-12 customScroll" style={{ maxHeight: '400px', overflowY: 'auto'}}>
    <h4 className="text-uppercase text-gray">agenda</h4>
    {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor ?
        <span>
          <p className="description text-left" dangerouslySetInnerHTML={{__html: contribution.reflection.agenda}}></p>
          <p className="description text-left" dangerouslySetInnerHTML={{__html: contribution.agenda}}></p>
       </span>
         :
       <p className="description text-left" dangerouslySetInnerHTML={{__html: contribution.agenda}}></p>
        }
    
</div>
}
{contribution.links &&
<div className="col-xs-12">
    <h4 className="text-uppercase text-gray">website link</h4>    
   {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor ?
          <Diff type="sentences" inputA={contribution.reflection.links} inputB={contribution.links}/>
         :
        <a href={contribution.links} target="_blank">{contribution.links}</a>
        }
</div>
}
</div>);



  }
}

export default ContributionTraining;