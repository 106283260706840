import React, { Component }  from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form'
import { renderTextField, renderSelectField } from '../../common/MaterialUiForm'
import Grid from '@material-ui/core/Grid';
import { ToastContainer, toast } from 'react-toastify'
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css'
import DateTimeComponent from '../form/types/DateTimeComponent'
import Textarea from '../form/types/Textarea'
import IntegrationReactSelect from '../../common/IntegrationReactSelect'
import { getCollaborationsFormData } from "../../../actions/collaborationActions";
import Select2 from '../../common/Select2'
import { change } from 'redux-form';
import moment from 'moment'

const styles = theme => ({
  root: {
    htmlFontSize: 20,
  }
  });

  const validate = values => {
  const errors = {}
  const requiredFields = [
    'followUpDate'
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'This value should not be blank.'
    }
  })

  return errors
}
class CollaborationLineForm extends Component {
    constructor(props) {
     super(props);

     }
 componentWillReceiveProps(nextProps) {
   
 }
     componentWillMount () { 
         if(this.props.initialValues){
             let initials = this.props.initialValues;
             initials.collaborationId = this.props.collaborationId
             initials.followUpDate = new Date(moment(initials.followUpDate, "DD-MM-YYYY HH:ss:ii").format())
             this.props.initialize(initials)    
         
     }else{
         this.props.initialize({ collaborationId: this.props.collaborationId }) 
     }
     }

       render () {
  const { handleSubmit, pristine, reset, submitting, classes } = this.props;

  return (
   <Grid container className="noPadding" direction="row" spacing={0}>
    <form onSubmit={handleSubmit} style={{display: 'flex', flexWrap: 'wrap', width: '100%'}}>
     <Grid container  direction="row" className="registerNotification-form">
<Grid md={1} lg={1} item className="text-center"></Grid>

      <Grid md={10} lg={10} xs={12} item className={classes.root} >
         <Field
           name="followUpDate"
           id="outlined-dense-first"
           type='text'
           component={DateTimeComponent}
           label="Follow Up Date *"
           variant="outlined"
           className="textField"
         />
         <Field
               name="activities"
               id="outlined-dense-first"
               type='text'
               component={Textarea}
               label="Activities *"
               variant="outlined"
               className="textField"
             />
             <Field
               name="comments"
               id="outlined-dense-first"
               type='text'
               component={Textarea}
               label="Comments *"
               variant="outlined"
               className="textField"
             />
             <span style={{display: 'none'}}>
             <Field
               name="collaborationId"
               id="outlined-dense-first"
               type='hidden'
               component={renderTextField}
             /></span>
        </Grid>
<Grid md={1} lg={1} item className="text-center"></Grid>

             <Grid md={12} lg={12} xs={12} item className="text-center" >
                  <button type="submit" disabled={pristine || submitting} className="button-submit">
                     {this.props.initialValues ?
                     <span>Edit</span>
                     :
                      <span>Create</span>
                    }
                 </button>
                  </Grid>
        </Grid>
        </form>
        </Grid>
  );
  }
}
CollaborationLineForm.propTypes = {
  collaborationId:    PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
 collaboration: state.collaboration
});

CollaborationLineForm = withStyles(styles)(CollaborationLineForm)

export default connect(mapStateToProps, {})(reduxForm({form: 'CollaborationLineFrom', enableReinitialize: true, keepDirtyOnReinitialize: true, validate})(CollaborationLineForm))
