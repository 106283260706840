import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import Popup from '../../../common/Popup';


export default class PopupReports extends Component {
    render() {
        const id = 'popupReports';
        return (
            <Popup locale={this.props.settings.locale}
                   title={'Reports'}
                   onClose={() => this.props.closePopup('popupReports')}>
                <ul className="">
                            <li className="col-sm-6">
                                <a className="draggable" title={"UN SPHS Health Procurement Report"}
                                   href={ '/admin/report/health-procurements'}>
                                    <span>UN SPHS Health Procurement Report</span>
                                </a>
                            </li>
                            <li className="col-sm-6">
                                <a className="draggable" title={"SA Comprehensive Report"}
                                   href={ '/admin/report/financial'}>
                                    <span>SA Comprehensive Report</span>
                                </a>
                            </li>
                             <li className="col-sm-6" style={{padding: "5px 0"}}>
                                <a className="draggable" title={"UNDP GPU Conventions"}
                                   href={'/admin/gpu/conventions/reports'}>
                                    <span>UNDP GPU Conventions</span>
                                </a>
                            </li>
                            <li className="col-sm-12">
                                <span><hr style={{borderTop: "1px solid #203648"}}/></span>
                            </li>
                            <li className="col-sm-12" style={{padding: "10px 0"}}>
                                <h4>
                                  SA Questionnaire Reports
                                </h4>
                            </li>
                            <li className="col-sm-6" style={{padding: "5px 0"}}>
                                <a className="draggable" title={"Scores by UNSPSC product family"}
                                   href={ '/admin/report/unspsc-product-family'}>
                                    <span>Scores by UNSPSC product family</span>
                                </a>
                            </li>
                              <li className="col-sm-6" style={{padding: "5px 0"}}>
                                <a className="draggable" title={"Individual Suppliers Report"}
                                   href={ '/admin/report/individual-suppliers'}>
                                    <span>Individual Suppliers Report</span>
                                </a>
                            </li>
                            <li className="col-sm-6" style={{padding: "5px 0"}}>
                                <a className="draggable" title={"All suppliers score"}
                                   href={'/admin/report/all-scores-report'}>
                                    <span>All suppliers score</span>
                                </a>
                            </li>
                             <li className="col-sm-6" style={{padding: "5px 0"}}>
                                <a className="draggable" title={"Supplier country"}
                                   href={'/admin/report/supplier-country-report'}>
                                    <span>Supplier country</span>
                                </a>
                            </li>
                            <li className="col-sm-6" style={{padding: "5px 0"}}>
                                <a className="draggable" title={"Scores by UNSPSC product segment"}
                                   href={'/admin/report/unspsc-product-segment'}>
                                    <span>Scores by UNSPSC product segment</span>
                                </a>
                            </li>
                             <li className="col-sm-6" style={{padding: "5px 0"}}>
                                <a className="draggable" title={"Members Averages Report"}
                                   href={'/admin/report/members-averages-report'}>
                                    <span>Members Averages Report</span>
                                </a>
                            </li>
                            <li className="col-sm-6" style={{padding: "5px 0"}}>
                                <a className="draggable" title={"SA EQ Report"}
                                   href={'/admin/report/oet-eq-report'}>
                                    <span>SA EQ Report</span>
                                </a>
                            </li>
                            <li className="col-sm-6" style={{padding: "5px 0"}}>
                                <a className="draggable" title={"EQ MC Results report"}
                                   href={'/admin/report/mc-report'}>
                                    <span>EQ MC Results report</span>
                                </a>
                            </li>
                            
                </ul>
            </Popup>
        )
    }
}
