import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getNews } from '../../actions/newsActions';
import Spinner from '../common/Spinner';
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';


import NewsItem from './NewsItem'


class News extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
       this.props.getNews(0, 3)
    }

    componentWillReceiveProps(nextProps) {

    }

    render() {

    const { news, loading } = this.props.news;

    let newsItem;
    if (news === null || loading) {
        newsItem = <Spinner />;
      } else {
        if (news && news.length > 0) {
          newsItem = news.map(singleNews => (
            <NewsItem key={singleNews.id} news={singleNews}  />
          ));
        } else {
          newsItem = <h4>News not found...</h4>;
        }
      }

        return (
                <div className="homepage-news">
                <div className="max-width">
                    <h1 className="text-center text-black">News &amp; Stories</h1>
                    <Grid
                      container
                      >
                    {newsItem}
                    <div style={{clear: 'both' }}>
                    </div>
                    </Grid>
                    <a className="blue-button" href='/news'>
                    ALL NEWS AND STORIES
                    </a>
                </div>
              </div>


                );
    }
}

News.propTypes = {
    getNews: PropTypes.func.isRequired,
    news: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
        news: state.news
    });

export default connect(mapStateToProps, {getNews})(News);
