import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Layout from '../../../components/admin/layout/Layout'
import {
  PieChart, Pie, Sector, Cell,
} from 'recharts';
import ReactSpeedometer from "react-d3-speedometer"
import {getQuestionnaireReportAdmin } from '../../../actions/questionnaireActions'
import VerticalChart from './VerticalChart'
import EAReportComponent from './EAReportComponent'
import Button from '@material-ui/core/Button';
import ReactToPrint, { PrintContextConsumer }  from "react-to-print";

const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

class EAReport extends Component {
  constructor() {
    super();
    this.state = {
      
    };
  }

     componentDidMount() {
       this.props.getQuestionnaireReportAdmin(this.props.match.params.id)
    }

    componentWillReceiveProps(nextProps) {

    }


  render() {
    const {questFill} = this.props.questionnaire;
    console.log(questFill)
     const { classes } = this.props;
    return (   
     <Layout>     
          <Grid container spacing={0} style={{background: 'white'}}>
 <Grid item md={12} lg={12} xs={6} className="text-right" style={{marginTop: 20, background: 'white', marginRight: 20 }}> 
     <ReactToPrint content={() => this.componentRef}>
          <PrintContextConsumer>
            {({ handlePrint }) => (
             <Button onClick={handlePrint} variant="outlined"> Print</Button>
            )}
          </PrintContextConsumer>
        </ReactToPrint>
         </Grid><Grid item md={2} lg={2} xs={6}></Grid>
                  <EAReportComponent ref={el => (this.componentRef = el)} questFill={questFill}/>
                          </Grid>
      </Layout>
    );
  }
}

EAReport.propTypes = {
  questionnaire: PropTypes.object.isRequired,
  getQuestionnaireReportAdmin: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  questionnaire: state.questionnaire,
});

EAReport = withStyles(styles)(EAReport)

export default connect( mapStateToProps, { getQuestionnaireReportAdmin })(withRouter(EAReport));
