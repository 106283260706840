import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import AssessmentIcon from '@material-ui/icons/Assessment';
import Popup from '../../../common/Popup';


export default class PopupAccounts extends Component {
    render() {
        return (
            <Popup locale={this.props.settings.locale}
                   title={ 'Lists'}
                   onClose={() => this.props.closePopup('popupAccounts')}>
                <ul>
                    {
                        this.props.settings.lists &&
                        this.props.settings.lists.map((list, key) => {
                            return <li className="col-sm-6 marginBottom" key={key}
                                       onClick={() => this.props.closePopup('popupAccounts')}>
                                <Link className="draggable"
                                      title={ list.name}
                                      to={'/admin/accounts/list/' + list.id}>
                                      {list.id===1 ?
                                        <i className="far fa-address-book"></i>
                                          :
                                        <i className="fas fa-university"></i>
                                  }
                                    <span style={{ padding: 20 }}>{ list.name}</span>
                                </Link>
                            </li>
                        })
                    }
                    <li className="col-sm-6 marginBottom" onClick={() => this.props.closePopup('popupAccounts')}>
                        <Link title="collaborations" to={'/admin/collaborations'}>
                            <i className="fas fa-puzzle-piece"></i>
                            <span style={{ padding: 20 }}>Collaborations</span>
                        </Link>
                    </li>
                     <li className="col-sm-6 marginBottom" onClick={() => this.props.closePopup('popupAccounts')}>
                        <Link title="calendar" to={'/admin/collaborations/calendar'}>
                            <i className="fas fa-calendar-alt"></i>
                            <span style={{ padding: 20 }}>Collaborations Calendar</span>
                        </Link>
                    </li>
                     <li className="col-sm-6 marginBottom" onClick={() => this.props.closePopup('popupAccounts')}>
                       <a title="duplicated" href={'/admin/accounts/duplicated/email'}>
                           <i className="fas fa-copy"></i>
                           <span style={{ padding: 20 }}>Duplicated Contacts</span>
                       </a>
                   </li>
                    <li className="col-sm-6 marginBottom" onClick={() => this.props.closePopup('popupAccounts')}>
                        <a title="duplicated" href={'/admin/accounts/duplicated/companyName'}>
                            <i className="fas fa-copy"></i>
                            <span style={{ padding: 20 }}>Duplicated Organizations</span>
                        </a>
                    </li>

                    <li className="col-sm-6 marginBottom" onClick={() => this.props.closePopup('popupAccounts')}>
                        <Link title="retention" to={'/admin/data-retention'}>
                            <i className="fas fa-trash-alt"></i>
                            <span style={{ padding: 20 }}>Data retention</span>
                        </Link>
                    </li>
                    {
                        this.props.settings.consent &&
                        <li className="col-sm-6 marginBottom" onClick={() => this.props.closePopup('popupAccounts')}>
                            <Link title={"Accounts"} to={'/crm/accounts/for_deleting'}>
                                <i className="fa fa-fw fa-circle-o-notch"></i>
                                <span>Data Retention Expired</span>
                            </Link>
                        </li>
                    }
                    <li className="col-sm-6 marginBottom" onClick={() => this.props.closePopup('popupAccounts')}>
                       <Link title="lists" to={'/admin/account-lists'}>
                           <i className="fa fa-list"></i>
                           <span style={{ padding: 20 }}>Lists</span>
                       </Link>
                   </li>
                    <li className="col-sm-6 marginBottom" onClick={() => this.props.closePopup('popupAccounts')}>
                       <Link title="CRM Reporting" to={'/admin/crm/reports'}>
                           <AssessmentIcon/>
                           <span style={{ padding: 20 }}>CRM Reporting</span>
                       </Link>
                   </li>
                </ul>
            </Popup>
        )
    }
}
