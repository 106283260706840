import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

 class DialogAddQuest extends React.Component {       
  constructor(props) {
    super(props);
    this.state = {
        question: '',
        id: null
    };
  }
  
  componentDidMount() {
       
    }    
     componentWillReceiveProps(nextProps) {      
     if(nextProps.question){
           this.setState({
               question: nextProps.question.name ? nextProps.question.name : '',
               id: nextProps.question.id ?  nextProps.question.id : null
           })
       }
    }
    
    handleChangeQuestion = (event) => {   
        this.setState({
            question: event.target.value
        })
    }
    
    saveQuest = () => {        
    if(this.state.question){
        var data = {
            name: this.state.question,
            id: this.state.id
        };
    
        this.props.saveQuest(data)
    }
    }
    
  render() {
            return  <Dialog
                        onClose={this.props.handleCloseAddQuest}
                        aria-labelledby="simple-dialog-title"
                        open={this.props.openAddQuest}
                        maxWidth="sm"
                        fullWidth
                        >
                        <DialogTitle id="simple-dialog-title">{this.state.id ? 'Edit' : 'Add'} question<span className="pull-right pointer" onClick={this.props.handleCloseAddQuest}>
                                 <i className="far fa-times-circle"></i></span>
                                 </DialogTitle>
                        <DialogContent> 
                        <label>Question</label>
                            <input name="question" 
                            type="text"
                            onChange={this.handleChangeQuestion} 
                            value={this.state.question ? this.state.question : ''} 
                            style={{marginBottom: 15}}/>
                                <Button
                                variant="outlined" 
                                color="primary" 
                                size="small"
                                onClick={this.saveQuest}>Save</Button>    
                       </DialogContent>
                        </Dialog>;
  }
}

export default DialogAddQuest ;











