import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import isEmpty from '../../validation/is-empty';
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Truncate from 'react-truncate';
import { Resizable, ResizableBox } from 'react-resizable';
import { config } from '../../config'


class NewsItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }



  render() {
    //const { news } = this.props;
    return (
        <Grid item  lg={6} md={6} sm={6} xs={12} style={{ margin: "20px 0"}}>
            <div className="item background-white">
                <div className="item-image">
                    <a className="post-list-item" href={`/news/${this.props.news.id}`}>
                        <img src={this.props.news.photo} />
                    </a>
                </div>
                <div className="item-body" style={{position: "relative"}}>
                    <a className="post-list-item" href={`/news/${this.props.news.id}`}>
                        <h3>{this.props.news.title}
                       </h3>
                    </a>

                    <div className="item-description">
                        <div>
                         <Truncate lines={5} ellipsis={'...'} className="description text-left"><span dangerouslySetInnerHTML={{ __html: this.props.news.previewText } } /></Truncate>
                       </div>
                    </div>
                    <Grid container spacing={3} style={{marginTop: 10}}>
                        <Grid item md={6} xs={12} className="item-created">

                            <div className=" text-gray post-list-creaded text-uppercase">
                                {   this.props.news.author &&
                                    <div>By {this.props.news.author}</div>
                                }
                                <div>{this.props.news.humanCreatedAt}</div>

                            </div>
                        </Grid>
                        <Grid item md={4}  sm={12}  xs={12}>
                            <div className="category">
                                <div className={this.props.news.categoryName}>
                                <p>{this.props.news.categoryName}</p></div>
                            </div>
                        </Grid>
                    </Grid>

                </div>
            </div>
        </Grid>


    );
  }
}

NewsItem.propTypes = {
  news: PropTypes.object.isRequired
};

export default NewsItem;
