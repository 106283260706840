import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from "@material-ui/core/Grid";
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import { Link } from 'react-router-dom';

 class Questionnaires extends React.Component {
  constructor(props) {
    super(props);

  }
  render() {
    return  <Grid item md={10} lg={10} xs={12} className="text-center center-col">
                <h3 className="m-top20">Questionnaires</h3>
                {this.props.questionnaires && this.props.questionnaires.length > 0 &&
               <Table className='m-bot30 mobileTable' aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                  <TableCell>Questionnaire</TableCell>
                                  <TableCell>Start Date</TableCell>
                                  <TableCell>Status</TableCell>
                                  <TableCell>Product</TableCell>
                                 {this.props.report && <TableCell>Report</TableCell>}
                                 </TableRow>
                               </TableHead>
                                 <TableBody>
      {this.props.questionnaires.map((quest, k) => (

                                      <TableRow key={k}>
                                        <TableCell>
                                        <a href={"/admin/questionnaire/result/1/"+quest.id}>{quest.name}</a></TableCell>
                                        <TableCell>{(quest.startDate ? quest.startDate : '')}</TableCell>
                                        <TableCell>{(quest.status ? quest.status : "--")}</TableCell>
                                        <TableCell>{(quest.product ? <Link to={'/admin/product/profile/'+quest.productId}>{quest.product}</Link> : '')}</TableCell>
                                        {this.props.report && <TableCell>{quest.scorePercent && <a href={'/user/questionnaire/report/'+quest.id}>Report</a>}</TableCell> }       
                                      </TableRow>

      ))}
        </TableBody>
       </Table>}
        </Grid>

  }
}

export default Questionnaires ;
