import client from './client';
import { toast } from 'react-toastify'

import {
  GET_TYPES,
  GET_CONTRIBUTION,
  GET_CONTRIBUTIONS,
  GET_CONTRIBUTIONS_TOTAL,
  GET_CONTRIBUTIONS_OFFSET,
  GET_CONTRIBUTION_EVENTS,
  LOAD_MORE_CONTRIBUTIONS,
  GET_SECTOR,
  GET_CONTRIBUTION_FORM_DATA,
  GET_CONTRIBUTIONS_MAP,
  GET_ERRORS_CONTRIBUTION
} from './types';

// Get types
export const getTypes = () => dispatch => {  
  client
    .get('/web/filter/types')
    .then(res =>
      dispatch({
        type: GET_TYPES,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_TYPES,
        payload: {}
      })
       
  });
};

export const getContributionsFilter = (data, load) => dispatch => {  
  
  client
    .post('/web/filters/sectors/areas', data)
    .then(res => {
        if(load){
           dispatch({
        type: LOAD_MORE_CONTRIBUTIONS,
        payload: res.data.contributions ? res.data.contributions : []
      })            
        }else{
      dispatch({
        type: GET_CONTRIBUTIONS,
        payload: res.data.contributions ? res.data.contributions : []
      })
        }
        if(res.data.sector){
          dispatch({
        type: GET_SECTOR,
        payload: res.data.sector 
      })  
        }
       dispatch({
        type: GET_CONTRIBUTIONS_TOTAL,
        payload: res.data.total
      })
      dispatch({
        type: GET_CONTRIBUTIONS_OFFSET,
        payload: res.data.offset
      })
    })
    .catch(err => {       
      dispatch({
        type: GET_CONTRIBUTIONS,
        payload: []
      })
       
  });
};


export const getContributionsEvents = (data) => dispatch => {  
  client
    .post('/web/filters/calendars', data)
    .then(res => {
      dispatch({
        type: GET_CONTRIBUTION_EVENTS,
        payload: res.data.dates ? res.data.dates : []
      })
       dispatch({
        type: GET_CONTRIBUTIONS_TOTAL,
        payload: res.data.total
      })
     // dispatch({
    //    type: GET_CONTRIBUTIONS_OFFSET,
     //   payload: res.data.offset
     // })
    })
    .catch(err => {       
      dispatch({
        type: GET_CONTRIBUTION_EVENTS,
        payload: []
      })
 });
};

export const getContributions = (id) => dispatch => {  
  client
    .get('/web/contributions/'+id+'/member')
    .then(res =>
      dispatch({
        type: GET_CONTRIBUTIONS,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_CONTRIBUTIONS,
        payload: []
      })
       
  });
};

export const getContribution = (id) => dispatch => {  
  client
    .get('/web/contributions/'+id)
    .then(res =>
      dispatch({
        type: GET_CONTRIBUTION,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_CONTRIBUTION,
        payload: []
      })
       
  });
};

export const getUserContribution = (id) => dispatch => {  
  client
    .get('/api/contribution-api/'+id+'/get')
    .then(res =>
      dispatch({
        type: GET_CONTRIBUTION,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_CONTRIBUTION,
        payload: []
      })
       
  });
};

export const getUserFormContribution = (id) => dispatch => {  
  client
    .get('/api/contribution-api/'+id+'/form')
    .then(res =>
      dispatch({
        type: GET_CONTRIBUTION,
        payload: res.data.contribution
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_CONTRIBUTION,
        payload: []
      })
       
  });
};

export const setContributions = () => dispatch => {    
   dispatch({
        type: GET_CONTRIBUTIONS,
        payload: []
      })
 };
 
 export const setContribution = () => dispatch => {    
   dispatch({
        type: GET_CONTRIBUTION,
        payload: {}
      })
 };
 
 export const getMapContributionsFilter = (data) => dispatch => {  
  client
    .post('/web/filters/maps/sectors/areas', data)
    .then(res => {
      
      dispatch({
        type: GET_CONTRIBUTIONS_MAP,
        payload: res.data.contributions ? res.data.contributions : []
      })
        
        if(res.data.sector){
          dispatch({
        type: GET_SECTOR,
        payload: res.data.sector 
      })  
        }
       dispatch({
        type: GET_CONTRIBUTIONS_TOTAL,
        payload: res.data.total
      })
      dispatch({
        type: GET_CONTRIBUTIONS_OFFSET,
        payload: res.data.offset
      })
    })
    .catch(err => {       
      dispatch({
        type: GET_CONTRIBUTIONS,
        payload: []
      })
       
  });
};

export const getFormData = () => dispatch => {  
  client
    .get('/api/contribution-api/form/data')
    .then(res =>
      dispatch({
        type: GET_CONTRIBUTION_FORM_DATA,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_CONTRIBUTION_FORM_DATA,
        payload: []
      })
       
  });
};


  
export const postCreateContribution = (data, history) => dispatch => {  
  client
    .post('/api/contribution-api/create', data)
    .then(res => {
    toast.success(res.data.message, {autoClose: 10000})
      dispatch({
        type: GET_CONTRIBUTION,
        payload: res.data
      })
      if(res.data && res.data.contribution){
      window.location = '/contribute/my-contributions/' +res.data.contribution.id; 
  }
  })
    .catch(err => {      
       if(err.response && err.response.data){
           const { message, error } = err.response.data;
           if(message && message !== 'undefined'){
               toast.error(message, {autoClose: 10000})
           }
            if(error && error !== 'undefined' && error.length > 0){
                error.map(value => {
                    toast.error(value.message, {autoClose: 10000})                    
                })            
           }
       }
      dispatch({
        type: GET_CONTRIBUTION,
        payload: {}
      })
       
  });
};

export const getUserContributions = () => dispatch => {  
  client
    .get('/api/contribution-api/user/contributions')
    .then(res =>
      dispatch({
        type: GET_CONTRIBUTIONS,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_CONTRIBUTIONS,
        payload: []
      })
       
  });
};


export const postEditContribution = (id, data, history) => dispatch => {  
  client
    .post('/api/contribution-api/' +id+'/edit', data)
    .then(res => {
    toast.success(res.data.message, {autoClose: 10000})
      dispatch({
        type: GET_CONTRIBUTION,
        payload: res.data
      })
      
      window.location = '/contribute/my-contributions/' +id;
  
  })
    .catch(err => {      
       if(err.response && err.response.data){
           const { message, error } = err.response.data;
           if(message && message !== 'undefined'){
               toast.error(message, {autoClose: 10000})
           }
            if(error && error !== 'undefined' && error.length > 0){
                error.map(value => {
                    toast.error(value.message, {autoClose: 10000})                    
                })            
           }
       }
   
       
  });
};







