import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import ContributionForm from './ContributionForm';
import Layout from '../../../components/admin/layout/Layout'
import { onSave } from "../../../actions/formActions";
import { SubmissionError } from 'redux-form'
const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

class ContributionMain extends Component {
  constructor() {
    super();
    this.state = {
      formRoute: null,
      saveRoute: null
    };
  }

     componentDidMount() {
         
         let formRoute = this.props.formRoute;
         let saveRoute = this.props.saveRoute;
         if (typeof this.props.match.params.type !== 'undefined') {
                saveRoute = saveRoute + this.props.match.params.type + '/';
            }
        if (this.props.saveRoute[this.props.saveRoute.length - 1] === '/' && typeof this.props.match.params.id !== 'undefined') {
                saveRoute = saveRoute + this.props.match.params.id;
        } 
         if (typeof this.props.match.params.type !== 'undefined') {
                formRoute = formRoute + this.props.match.params.type + '/';
            }
        if (typeof this.props.match.params.id !== 'undefined') {
                formRoute = formRoute + this.props.match.params.id;
            }
        this.setState({
            saveRoute: saveRoute,
            formRoute: formRoute
        })
    }

    componentWillReceiveProps(nextProps) {

    }
    
    validate = (values, requiredFields) => {
  var errors = {}  
  requiredFields.forEach(field => {
     if (!values[field] && values[field] === 'generalTerms') {
            errors[field] = 'You have not agreed to the site terms'
        } else if (!values[field] && values[field] === 'imageTerms') {
            errors[field] = 'You have not agreed to the content terms'
        } else if (!values[field] || (Array.isArray(values[field]) && !values[field].length > 0) ) {
            errors[field] = 'required'
        } else if (typeof values[field] === 'object' && Object.entries(values[field]).length === 0) {
            errors[field] = 'required'
        }

        if (field === 'city' && !/^[a-zA-Z0-9\'\-\ ]+$/.test(values[field])) {            
            errors[field] = 'invalid';
        } 
  })
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'Invalid email address'
  }
  return errors
}

    submit = values  => {
        var requiredFields = [];
        if(this.props.formProperties['initializeFromState2'].schema && this.props.formProperties['initializeFromState2'].schema.required){
            requiredFields = this.props.formProperties['initializeFromState2'].schema.required;
        }
         this.validate(values, requiredFields)
         var errors = this.validate(values, requiredFields)   

     
         if(Object.keys(errors).length > 0){
             throw new SubmissionError(errors)
         }else if(values){
           // this.sendValues(values, url)
         }    
        
    let data = {}
   var formDataField = {};
   const formData = Object.assign(formDataField, values);

    let fields = this.props.formProperties['initializeFromState2'].form.fields;
    let isForm = false;
    if(typeof fields !== 'undefined'){
        var body = new FormData();
        Object.keys(fields).forEach(( key ) => {            
            let field = fields[key]
            if(field.type === 'switch' &&  (formData[field.id] == null || formData[field.id] == false)){                    
                    body.append(field.id, 0);  
                    formData[field.id] = 0;
            }else if(field.type === 'switch' && (formData[field.id] !== null || formData[field.id] !== false)){                 
                body.append(field.id, 1);  
                    formData[field.id] = 1;
            }
            if(field.id in formData){
                if(field.type === 'tags' ){
                    let tagsArray = []
                    if(Array.isArray(formData[field.id])){
                   formData[field.id].map(obj => (
                    tagsArray.push(obj.value)
                   ))
                      body.append(field.id, tagsArray);
                      formData[field.id] = tagsArray;
                  }else{
                      body.append(field.id, formData[field.id].value);  
                      formData[field.id] = formData[field.id].value
                  }
                  
                }else if(field.type === 'file'){
                     isForm = true;
                     let files = formData[field.id];
                    
                     if(typeof files === 'number'){
                         body.append(field.id+'[]', files)
                     }
                     if(typeof files === 'object' && files.length > 0){                          
                        files.map((file, key) => {
                            body.append(field.id+'[]', formData[field.id][key]['file'])
                        })        
                    }
                }else if(field.type !== 'switch'){
                    body.append(field.id, formData[ field.id ]);
                }

                if(field.type === 'collection'){
                  isForm = true;
                  if(field.collectionType === 'contacts'){
                    let contacts = JSON.stringify({'contacts': formData[ field.id ]});
                    body.append(field.id, contacts);
                  }else if(field.collectionType === 'address'){
                    let addresses = JSON.stringify({'addresses': formData[ field.id ]});
                    body.append(field.id, addresses);
                  }

                }
            }else{
                /*if(field.type === 'switch' && field.required && formData[field.id] == null){
                    body.append(field.id, false);  
                    formData[field.id] = false;
            }*/
            }
        });
        
        if (isForm) {
           data = body;
        }else{
            data = formData
        }
        let redirectUrl = this.props.redirectUrl;
        if(typeof this.props.formProperties['initializeFromState2'] !== 'undefined' && typeof this.props.formProperties['initializeFromState2'].form.redirectUrl !== 'undefined'){
             redirectUrl = this.props.formProperties['initializeFromState2'].form.redirectUrl;
        }
    
        this.props.onSave(this.state.saveRoute, data, isForm, redirectUrl, this.props.history);
      
     }
    }
  render() {    

     const { classes } = this.props;

    return (
            <Layout>
    {this.state.formRoute && 
                          <ContributionForm 
                            onSubmit={this.submit} 
                            form={'initializeFromState2'}
                            formRoute={this.state.formRoute}
                            saveRoute={this.state.saveRoute}
                            title={this.props.title}
                            isNew={this.props.isNew}
                            isAdditionalId={this.props.isAdditionalId}
                          />

        }                 
</Layout>
    );
  }
}

ContributionMain.propTypes = {
  onSave: PropTypes.func.isRequired,
  formProperties: PropTypes.object.isRequired
};

const mapStateToProps = state => ({    
  formProperties: state.formProperties
});

ContributionMain = withStyles(styles)(ContributionMain)

export default connect( mapStateToProps, { onSave })(withRouter(ContributionMain));
