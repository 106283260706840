import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Field, reduxForm} from 'redux-form';
import { change } from 'redux-form';
import validate from '../form/validator';
import {
onLoadForm as onLoadForm, onSave
        } from "../../../actions/formActions";
import FormLoader from "../form/FormLoader";
import {onHideMessage, onShowMessage} from "../../../actions/gridActions";
import {FORM_SHOW_LOADER} from "../../../actions/types";
import IntegrationReactSelect from '../../common/IntegrationReactSelect'
import {renderDatePickerField, renderTextField, renderSelectField, renderCheckbox, renderTmcField, renderPasswordField, radioButton} from '../../common/MaterialUiForm'
import RadioButtonsGroup from '../../common/RadioButtonsGroup'
import Grid from '@material-ui/core/Grid';
import Select2 from './types/Select2';
import Contacts from '../form/types/Contacts';
import Switch from '../form/types/Switch';
import Textarea from '../form/types/Textarea';
import DatePickerComponent from '../form/types/DatePickerComponent'
import DateTimeComponent from '../form/types/DateTimeComponent'
import { toast } from 'react-toastify'
import "../form/redux-forms.css";
import DropZoneComponent from '../../file/DropZoneComponent'
import CheckboxGroupField from '../../common/CheckboxGroupField'
import Select2Creatable from '../../common/Select2Creatable'
import Collection from '../form/types/Collection';
import Sections from '../form/types/Sections'
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FieldBuild from './FieldBuild';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {deleteQuestion} from "../../../actions/questionnaireActions";
import DialogConfirmDeleteQuest from './Helpers/DialogConfirmDeleteQuest'
import showAToast from '../../../helpers/showAToast'
import DialogRemoveDependency from './Helpers/DialogRemoveDependency'

class FormBuilder extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
            submitting: false,
            questions: [],
            openConfirmDelete: false,
            questionId: null
            };

            this.updateForm = this.updateForm.bind(this);
            this.changeFieldValue = this.changeFieldValue.bind(this);
        }

        changeFieldValue(id, value) {
            this.props.change(id, value);
        }

        componentDidMount() {
            this.updateForm();            
        }

   componentWillReceiveProps(nextProps) {
       if(nextProps.formProperties.form && nextProps.formProperties.form.fields){
           this.setState({
               questions: nextProps.formProperties.form.fields
           })
       }
    }

        componentDidUpdate(prevProps) {
            if (this.props.match && prevProps.match.params.id !== this.props.match.params.id) {
                this.updateForm();
            }
        }

        updateForm() {
            this.props.onLoader(this.props.form, true);
            if (this.props.title && this.props.alone) {                   
                    document.title = this.props.title;
            }

            this.props.onLoadForm(this.props.form, this.props.formRoute);
        }


    resetForm = () => this.props.reset();

deleteQuestion = (questionId) => {
   
   if(this.props.questionnaire.quest.locked === 'locked'){
           showAToast("This questionnaire is locked.", 'locked', 'error')                   
      }else{
   this.setState({
       openConfirmDelete: true,
       questionId: questionId
   })
      }
  }
  
  confirmDeleteQuest = () => {      
      this.props.deleteQuestion(this.state.questionId)
      this.setState({
       openConfirmDelete: false,
       questionId: null
   })
  
    setTimeout(() => {
    this.props.onLoadForm(this.props.form, this.props.formRoute)
    }, 3000)
  }
  
  closeDeleteQuest = () => {
      this.setState({
       openConfirmDelete: false,
       questionId: null
   })
  }


render() {
    let {handleSubmit, formProperties, pristine, reset, submitting, invalid, valid, formData} = this.props;
    const questions = formProperties.form && formProperties.form.fields ? formProperties.form.fields : [];
   
    let returnUrl = formProperties.form ? formProperties.form.returnUrl : null;
    returnUrl = returnUrl ? returnUrl : '#';
    let title = formProperties.form && formProperties.form.title ? formProperties.form.title : '';
    let customButtons = formProperties.form && formProperties.form.customButtons ? formProperties.form.customButtons : null;
    let buttons = '';
    let disabled = pristine || submitting;
    if(formProperties.form && formProperties.form.submitActive){
        disabled = false;  
    }
    
    if(customButtons){
     buttons = customButtons.map((button, i) => (
    <button onClick={handleSubmit(values =>
          this.props.onSubmit({
            ...values,
            button: button.value
          }))} name={button.name} key={i}  value={button.value} className={button.class}>{button.label}</button>
        ));
    }
    

    return (
        <div>
            {this.props.alone && <div className="dashboard">
                <div className="moduleSubMenu backMenu">
                   <div className="col-sm-12">
                        <h3 className="pull-left">
                            <Link to={returnUrl}>
                            {!this.props.popup && <i className="ion-ios-arrow-back"></i>}</Link> {!formProperties.showLoader && title}
                        </h3>
 
                        {
                                formProperties.form && formProperties.form.tabs &&
                        <ul className="nav navbar-nav pull-right">
                            {
                                formProperties.form.tabs.map((tab, key) => {
                                return <li className="nav-item" key={key}>
                                <Link className={tab.active ? 'active' : ''} to={tab.link}>
                                { tab.label}
                                </Link>
                            </li>
                            })
                            }
                        </ul>
                        }
                    </div>
                </div>
            </div>}
            <div id="reactForm">
                <div className="row m-bot15 cmxform form-horizontal bucket-form ng-pristine ng-valid">
                    <div className="col-lg-12">
                        <section  style={{background: 'white'}}>
                             {this.props.alone &&<header className="panel-heading"
                                    style={{display: formProperties.form && formProperties.form.noBackground ? 'none' : 'block'}}>
                                { formProperties.showLoader ? '...' : formProperties.isEdit ? 'Edit' : 'New'}                                
                                {formProperties.form && formProperties.form.buttonLinkId && 
                                        <a href={formProperties.form.buttonLinkId.url} className="btn btn-default" style={{float: 'right'}}>
                                {formProperties.form.buttonLinkId.label}
                                </a>}
                                
                            </header>}
                             <div className="panel-body">
                                {formProperties.form && formProperties.form.breadcrumb && formProperties.form.breadcrumb.fields.length > 0 &&
                                    <div className="status-container">
                                        <div className="status-breadcrumb">
                                            {
                                                formProperties.form.breadcrumb.fields.map((element, key) => {
                                                    return <a
                                                        className={formProperties.form.breadcrumb.target in this.props.formData.data && element.value === this.props.formData[formProperties.form.breadcrumb.target] ? 'active' : ''}
                                                        href="#" key={key}
                                                        onClick={() => this.onBreadcrumbClick(element, formProperties.form.breadcrumb.target)}>
                                        <span className="breadcrumb__inner">
                                            <span className="breadcrumb__desc">{element.label}</span>
                                        </span>
                                                    </a>
                                                })
                                            }
                                        </div>
                                    </div>
                                }

                            <form onSubmit={handleSubmit}>
                             <Grid container direction="row" spacing={4}>                             
                              <Grid md={12} lg={12} container direction="row" item >                             
                            {this.state.questions.length > 0 && this.state.questions.map((question, k) => (
                     <Grid md={12} lg={12} container direction="row" item key={k} className={this.props.questionId === question.id ? 'builder-quest-selected' : ''} style={{ border: '1px solid #ccc', borderRadius: 16, padding: 15}}> 
                         <h3 style={{marginRight: 15}} >{question.name}</h3>
                             <Button 
                                variant="outlined" 
                                color="primary" 
                                size="small"
                                onClick={this.props.editQuestion(question)} 
                                >Edit</Button>
                                <Button 
                                variant="outlined" 
                                color="primary" 
                                size="small"
                                onClick={(id) => this.props.handleDrawerOpen(question.id)} 
                                >Add field</Button>
                                <Button 
                                variant="outlined" 
                                color="secondary" 
                                size="small"
                                onClick={(id) => this.deleteQuestion(question.id)} 
                                >Delete</Button>
                                <DialogConfirmDeleteQuest 
                                confirmDeleteQuest={this.confirmDeleteQuest}
                                closeDeleteQuest={this.closeDeleteQuest}
                                openConfirmDelete={this.state.openConfirmDelete}
                                questionId={this.state.questionId}
                                />
                          {question.fieldsBuilder.length > 0 && question.fieldsBuilder.map((field, index) => {
                                let component = renderTextField;
                                let data = null;
                                if(field.type === 'hidden'){
                                    component =  'input';
                                }
                                if (field.type === 'text'){
                                    component = renderTextField;
                                }
                                if(field.type === 'select2'){
                                    component = Select2;
                                }
                                if(field.type === 'select'){
                                    component = Select2;
                                }
                                if(field.type === 'selectCreatable'){
                                    component = Select2Creatable;
                                }
                                if(field.type === 'collection'){
                                    component = Contacts;
                                    data = formData.data;
                                }
                                 if(field.type === 'collections'){
                                    component = Collection;
                                    data = formData.data;
                                }
                                if(field.type === 'sections'){
                                    component = Sections;
                                    data = formData.data;
                                }
                                 if(field.type === 'checkboxGroup'){
                                    component = CheckboxGroupField;
                                }
                                if(field.type === 'switch'){
                                    component = Switch;
                                }
                                if(field.type === 'textarea'){
                                    component = Textarea;
                                }
                                if(field.type === 'tmc'){
                                    component = renderTmcField;
                                }
                                if(field.type === 'tags'){
                                    component = IntegrationReactSelect;
                                }
                                if(field.type === 'datePicker'){
                                    component = DatePickerComponent;

                                }
                                if(field.type === 'datetimePicker'){
                                    component = DateTimeComponent;
                                }
                                if(field.type === 'radio'){
                                    component = RadioButtonsGroup;
                                }
                                if(field.type === 'password'){
                                    component = renderPasswordField;
                                }
                                let col = 6;
                               if(typeof field.col !== 'undefined'){
                                 col = field.col;
                               }
                               if(field.type === 'file'){
                                     component = DropZoneComponent;
                                     data = formData.data[field.id+'Files'];
                               }

                               if(field.type === 'checkbox'){
                                    component = renderCheckbox;
                                }
        return <Grid md={12} lg={12} item container key={index} className={this.props.fieldId === field.fieldId ? 'builder-row-selected' : ''}>
            
                    <Grid md={12} lg={12} item container direction="row" >
                        <Grid item md={1} style={{marginTop: 30}}></Grid>
                             <Grid item md={10} className="m-all20" style={{marginTop: 25}}>
                                <h3>{field.header}{field.header && field.required ? <b>*</b> : <span></span>}</h3> 
                                {field.dependency && <span><h5>Field depend on: {field.dependency.header ? field.dependency.header : field.dependency.label}</h5>
                                <DialogRemoveDependency field={field} updateForm={this.updateForm}/>
                                </span>}
                                    <Field
                                        data={data}
                                        name={field.id}
                                        field={field}
                                        change={this.changeFieldValue}
                                        component={component}
                                        type={field.type}
                                        hide={field.hide}
                                        placeholder={field.label}
                                        label={field.label}
                                        ismulti={field.ismulti ? field.ismulti : null}
                                        clearable={field.clearable ? field.clearable : null}
                                        options={field.optionsBulder ? field.optionsBulder : []}
                                        disabled={field.disabled ? field.disabled : false}
                                        id={field.id}
                                        collectionFields={field.collectionFields ? field.collectionFields : null}
                                        />
                                        {field.points && <h5>Points: {field.points}</h5>}
                                </Grid> 
                    </Grid>
                                   <Grid item md={2}  className="m-all20">
                                        <a href="#" onClick={this.props.handleDrawerRightOpen(field, question)}>        
                                        <SettingsIcon/>         
                                         </a>
                                         <a href="#" onClick={this.props.handleDeleteOpen(field.fieldId)}>        
                                        <DeleteOutlineIcon style={{color: 'rgb(220, 0, 78)'}}/>         
                                         </a>
                                    </Grid>
        </Grid>
        })}   </Grid> 
                               ))}                  
                               </Grid>

                                {customButtons ?
                                <Grid md={12} lg={12} item className="text-center">
                                  {buttons}
                                  </Grid>
                                :
                               <Grid md={12} lg={12} item className="text-center">
                               
                                </Grid>
                                }
                                 </Grid>
                            </form>

                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
    }
}



const mapStateToProps = (state, ownProps) => ({
    settings: state.settings,
    formProperties: state.formProperties && state.formProperties[ownProps.form] ? state.formProperties[ownProps.form] : [],
    formData: state.formData ? state.formData : [],//state.form && state.form[ownProps.form] ? state.form[ownProps.form].values : [],
    initialValues: state.formData ? state.formData.data : [],
    questionnaire: state.questionnaire,
});

const mapDispatchToProps = dispatch => ({
onLoadForm: (name, route) => dispatch(onLoadForm(name, route)),
deleteQuestion: (id) => dispatch(deleteQuestion(id)),
//save: (formId, route, data) => dispatch(onSave(formId, route, data)),
showMessage: (text, isError) => dispatch(onShowMessage(text, isError)),
hideMessage: () => dispatch(onHideMessage()),
onLoader: (formId, value) => dispatch({
    type: FORM_SHOW_LOADER,
    payload: {formId, value}
})
});


FormBuilder = reduxForm({
    // form: 'initializeFromState', // a unique identifier for this form
    enableReinitialize: true,
    touchOnChange: true,
    validate: validate
})(FormBuilder);

FormBuilder = connect(mapStateToProps, mapDispatchToProps)(FormBuilder);

export default FormBuilder;
