import React, {Component} from 'react';
import Tooltip from '@material-ui/core/Tooltip';

class IfCellRenderer extends Component {

    constructor(props) {
        super(props);

        this.generateRoute = this.generateRoute.bind(this);
    }

    generateRoute(link) {
        if (!('params' in link)) {
            return link.url;
        }

        var url = link.url;

        link.params.map(param => {           
            if (param.source in this.props || param.source in this.props.data) {
                if('inProps' in param){
                    url = url.replace('{' + param.target + '}', this.props.data[param.source]);
                }
                else {
                    url = url.replace('{' + param.target + '}', this.props[param.source]);
                }
            }
        });

        return url;
    }

    render() {
        const data = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex).data;
        let access = true;
        console.log(this.props.ifColumn)
        console.log(data[this.props.ifColumn])
        return (
            <span>    
    {this.props.link && data[this.props.ifColumn] ?
    <a href={this.generateRoute(this.props.link)} target="_blank" rel="noopener noreferrer">{this.props.value}</a>
        :
    <span></span>}          
          </span>
        );
    }
}

export default IfCellRenderer;
