import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {exportPdfDataButton} from '../../../actions/gridActions';
import Button from '@material-ui/core/Button';
import ReactToPrint, { PrintContextConsumer }  from "react-to-print";
import MCReportPdfComponent from './MCReportPdfComponent'
import { Link } from 'react-router-dom';

const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

class MCReportPdf extends Component { 
  constructor() {
    super();
  }
   
  render() { 
  
     const { classes } = this.props;
 
    return (           
          <Grid container spacing={0} > 
      <Grid item md={1} lg={1} xs={6}>
      <Link to={this.props.location.myProps ? this.props.location.myProps.path : '/internal'} className="btn btn-default" style={{margin: 30}}>Back</Link></Grid>
      <Grid item md={10} lg={10} xs={6} className="text-right m-top20"> 
     <ReactToPrint content={() => this.componentRef}>
          <PrintContextConsumer>
            {({ handlePrint }) => (
             <Button onClick={handlePrint} variant="outlined"> Print</Button>
            )}
          </PrintContextConsumer>
        </ReactToPrint>
        </Grid>
       {this.props.location.myProps && <MCReportPdfComponent ref={el => (this.componentRef = el)} pdfData={this.props.location.myProps} />}       
        
      </Grid>
    );
  }
}

MCReportPdf.propTypes = {
  gridProperties: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  gridProperties: state.gridProperties,
});

MCReportPdf = withStyles(styles)(MCReportPdf)

export default connect( mapStateToProps, {})(withRouter(MCReportPdf));
