 import {
    GET_HELP_CATEGORIES,
    GET_HELP_CONTENT,
    DELETE_HELP_CATEGORY
} from '../actions/types';

const initialState = {
    categories: null,
    content: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_HELP_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            };
        case GET_HELP_CONTENT:
            return {
                ...state,
                content: action.payload
            };
        default:
            return state;
	}
}
