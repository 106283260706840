import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import {onSelect2InputChange, onAddSelectOption} from '../../../../actions/formActions';
import InputLabel from '@material-ui/core/InputLabel'
import {renderFromHelper} from '../../../common/MaterialUiForm'


class Select2 extends React.Component {

    constructor(props) {
        super(props);

        this.loadOptions = this.loadOptions.bind(this);
    }

    componentDidMount() {
        
        if ((!this.props.input.value && this.props.input.value !== 0 && this.props.input.value !== false)&& (!this.props.field.settings || !this.props.field.settings.emptyOption) && this.props.field.options.length > 0) {
          this.props.input.onChange(this.props.field.options[0].value);
        }else{
          //  this.props.input.onChange('');
        }
    }

    onChange(newValue, actionMeta) {
        if (newValue) {
            this.props.input.onChange(newValue.value);

           if (this.props.field.options.length > 0) {
                this.props.onNewOptions(this.props.field.options, newValue);
            }
        } else {
            this.props.input.onChange('');
        }



    }

    loadOptions(inputValue, callback) {
        if (!inputValue) inputValue = -1;
        let hasSettings = this.props.field.settings && this.props.field.settings.optionsUrl;
        this.props.onInputChange('initializeFromState2', this.props.field.id, inputValue, this.props.field.settings.optionsUrl)
        .then(response => {
                let options = [];
                if (response) {
                    options = response.map(option => {
                        return {
                            ...option,
                            'value': option.value,
                            'label': option.label
                        };
                    });
                }
                this.options = options;
                if(options.length > 0) this.props.onNewOptions(options);
                else if(this.props.field.options.length > 0) this.props.onNewOptions(this.props.field.options);

                if(!hasSettings) {
                    let ops = this.props.field.options;
                    ops = ops.filter(item => item.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1);
                    callback(ops);
                }
                else callback(options ? options : this.props.field.options);
            });
    }

    render() {
    const { touched, error } = this.props.meta;
   
       return (<span>
          <InputLabel  style={{
          fontSize: 14,
          marginTop: 15,
          marginBottom: 16,
          textTransform: "uppercase",
          fontFamily: 'OpenSans ,sans-serif',
          color: "#333333"
        }} >{this.props.field.label}</InputLabel>
        {this.props.field.settings && this.props.field.settings.optionsUrl ?
             <AsyncSelect
                isClearable
                cacheOptions
                onChange={(value) => this.onChange(value)}
                onBlurResetsInput={false}
                onCloseResetsInput={false}
                isDisabled={this.props.field.disabled ? this.props.field.disabled : false}
                onFocus={this.props.input.onFocus}
                menuPosition= {this.props.field.position}
                menuPlacement={"auto"}
                loadOptions={this.loadOptions}
                defaultOptions={this.props.field.options}
                value={this.props.field.options.find(o => o.value === this.props.input.value)}
                isClearable={true}
            />
       :
             <Select
                value={this.props.field.options.find(o => o.value === this.props.input.value)}
                onChange={(value) => this.onChange(value)}
                onBlurResetsInput={false}
                onCloseResetsInput={false}
                isDisabled={this.props.field.disabled ? this.props.field.disabled : false}
                menuPosition= {this.props.field.position}
                menuPlacement={"auto"}
                onFocus={this.props.input.onFocus}
                options={this.props.field.options}
                isMulti={this.props.field.isMulti}
                isClearable={true}
            />

        }
                {error && error !== "undefined" && touched && (
           <span>{renderFromHelper({ touched, error })}</span>
          )}
        </span>);
    }
}

Select2.defaultProps = {
    onNewOptions: () => {},
};

const mapStateToProps = state => ({
    formData: state,
});

const mapDispatchToProps = dispatch => ({
    onInputChange: (formId, id, value, route) => dispatch(onSelect2InputChange(formId, id, value, route)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Select2);
