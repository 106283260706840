import React from "react";
import PropTypes from "prop-types";
import Photo from '@material-ui/icons/AddAPhoto';

const Placeholder = ({ getInputProps, getRootProps, error, touched }) => (
  <div
    {...getRootProps()}

  >
    <input {...getInputProps()} />
    <button type="button" className="upload-btn btn btn-success"><i style={{marginRight: 5}} className="fa fa-upload"></i> Upload 
                </button>

  </div>
);

Placeholder.propTypes = {
  error: PropTypes.string,
  getInputProps: PropTypes.func.isRequired,
  getRootProps: PropTypes.func.isRequired,
  touched: PropTypes.bool
};

export default Placeholder;
