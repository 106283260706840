import React, { Component } from 'react';
import Sectors from './Sectors'
import Sdgs from './Sdgs'
import Contribute from '../Contribute'
import Grid from '@material-ui/core/Grid'
import SocialNetworks from './SocialNetworks'
import SaveForLater from '../SaveForLater'
import LoginForm from '../../../layout/LoginForm'
import Diff from 'react-stylable-diff'

class ContributionEvent extends Component {

  render() {
   const contribution = this.props.contribution;


    return(
  <div>

    <div className="col-md-12 right-header"  >
    {contribution.organizers &&
    <div className="col-md-12" >
        <h5 className="text-dark-blue-old">Organizers</h5>
        <p className="text-white" >{ contribution.organizers}</p>

        <hr className="blue-line col-md-12 noPadding"/>
    </div>
    }
    <div className="col-md-12">
        <div className="col-sm-8 col-md-12 col-lg-8 noPadding" >
            {contribution.projectAt &&
            <div className="col-md-12 noPadding clearfix" >
                <div className="col-xs-6 noPadding ">
                    <h5 className="text-uppercase noPadding text-left project-date text-dark-blue-old pull-left">Event start: </h5>
                </div>
                <div className="col-xs-6 noPadding ">
                    <h5 className="text-white noPadding text-left  pull-left" >
                        {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor ?
                        <Diff type="sentences" inputA={contribution.reflection.projectAt} inputB={contribution.projectAt}/>
                          :
                          <span>{ contribution.projectAt}</span>
                      }
                    </h5>
                </div>
            </div>
            }
            {contribution.projectTo &&
            <div className="col-md-12 noPadding clearfix">
                <div className="col-xs-6 noPadding ">
                    <h5 className="text-uppercase noPadding project-date text-left text-dark-blue-old pull-left">Event end: </h5>
                </div>
                <div className="col-xs-6 noPadding ">
                    <h5 className="text-white pull-left">
                       {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor ?
                        <Diff type="sentences" inputA={contribution.reflection.projectTo} inputB={contribution.projectTo}/>
                          :
                          <span>{ contribution.projectTo}</span>
                      }
                        {contribution.projectTo && <span>N/A</span>}
                    </h5>
                </div>

            </div>
            }
        </div>

        <hr className="blue-line col-sm-12 col-md-12 noPadding"/>
    </div>
</div>
{contribution.sectors &&
        <Sectors sectors={contribution.sectors}/>
}
{contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor &&
<Diff type="sentences" inputA={contribution.reflection.sectorsText} inputB={contribution.sectorsText}/>
}
 <LoginForm role="filter"/>
{contribution.href &&
<SocialNetworks url={contribution.href}/>
}
{contribution.id &&
<SaveForLater id={contribution.id} type="contrib"/>
}
{contribution.sdg &&
<Sdgs sdgs={contribution.sdg}/>
}
{contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor &&
<Diff type="sentences" inputA={contribution.reflection.sdgText} inputB={contribution.sdgText}/>
}
{contribution.links &&
<div className="col-xs-12">
    <h4 className="text-uppercase text-gray"> event link </h4>
     {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor ?
          <Diff type="sentences" inputA={contribution.reflection.links} inputB={contribution.links}/>
         :
        <a href={contribution.links} target="_blank">{contribution.links}</a>
        }

</div>
}
</div>);
  }
}

export default ContributionEvent;
