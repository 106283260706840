import React, {Component} from 'react';
import {Link} from 'react-router-dom';

export default class MenuContent extends Component {

    render() {

        const settings = this.props.settings;
        const menuIconsTheme = this.props.theme;

        let departments = null;
        let employees = null;

        if ('personnel' in settings.plan) {
            departments = settings.plan.personnel.departments;
            employees = settings.plan.personnel.employees;
        }

        return (
                <div className="panel-body">
                    <li>
                        <a className="draggable" id="dashboard" href={'/internal'}
                           title={'Dashboard'}>
                            <i style={menuIconsTheme} className="icon icon-dashboard"></i>
                            <span>Dashboard</span>
                        </a>
                    </li>
                    <span className="border-line"></span>
                    <li>
                        <a id="customersSuppliers" onClick={this.props.onPopupAccounts}
                           title={'CRM'}>
                            <i style={menuIconsTheme} className="icon icon-accounts"></i>
                            <span>CRM</span>
                        </a>
                    </li>
                    <span className="border-line"></span>

                    <li>
                        <a id="customersSuppliers" onClick={this.props.onPopupWebsite}
                           title={'Website'}>
                           <i style={menuIconsTheme} className="glyphicon glyphicon-list-alt" aria-hidden="true"></i>
                            <span>Website</span>
                        </a>
                    </li>
                    <span className="border-line"></span>
                   <li>
                        <a id="oet" onClick={this.props.onPopupQuest}
                           title={'SA'}>
                            <i style={menuIconsTheme} className="icon glyphicon glyphicon-th-list"></i>
                            <span>SA</span>
                        </a>
                    </li>
                    <span className="border-line"></span>
                    <li>
                        <a className="draggable"
                           onClick={this.props.onPopupResources}
                           title={'Documents'}>
                            <i style={menuIconsTheme} className="icon icon-documents"></i>
                            <span>Documents</span>
                        </a>
                    </li>

                    <span className="border-line"></span>
                    <li>
                        <a className="draggable"
                           onClick={this.props.onPopupReports}
                           title={'Reports'}>
                            <i style={menuIconsTheme} className="icon icon-reports"></i>
                            <span>Reports</span>
                        </a>
                    </li>
                    <span className="border-line"></span>
                   <li>
                        <Link className="draggable" id="oet" to={'/help'}
                           title={'Help'}>
                            <i style={menuIconsTheme} className="icon glyphicon glyphicon-info-sign"></i>
                            <span>Help</span>
                        </Link>
                    </li>
                    <span className="border-line"></span>
                    <li>
                        <a id="administration"
                           onClick={this.props.onPopupAdministration}
                           title={'Administration'}>
                            <i style={menuIconsTheme} className="icon icon-administration"></i>
                            <span>Administration</span>
                        </a>
                    </li>
                    <span className="border-line"></span>
                </div>);
    }
}
