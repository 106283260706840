export const API_URL = 'https://api.savinglivesustainably.org';

const prod = { 
  API_URL: 'https://api.savinglivesustainably.org',
  API_ID: 'c807b8e792d7b9e61b1efa733b38c5fc'
};

const dev = { 
  API_URL:'http://localhost:8000',
  API_ID: 'c807b8e792d7b9e61b1efa733b38c5fc'
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
