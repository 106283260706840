import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {getQuestionnaireFillMember} from '../../actions/questionnaireActions'
import Form from '../../components/admin/questionnaire/Form'
import { onSave, onSaveData } from "../../actions/formActions";
import Navbar from '../layout/Navbar';
import {Link} from 'react-router-dom';
import { getLogActivityWithGeoIp, getCurrentProfile } from '../../actions/profileActions';
import ProfileActions from '../dashboard/ProfileActions';
import Spinner from '../common/Spinner';

const drawerWidth = 300;
const styles = theme => ({
  root: {
    flexGrow: 1,
  },
   drawerPaper: {
    width: drawerWidth,
    marginTop: 100,
 //   background: '#fff',
   // color: 'white'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
   paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width: '100%',
  //  height: '100%',
    color: '#000000de'
  },
    extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'fixed',
    top: theme.spacing(15),
    left: theme.spacing(2),
    background: '#203648',
  },
});

class MembersResultQuestionnaire extends Component {
  constructor() {
    super();
    this.state = {
      logged: false
    };
  }

     componentDidMount() {
        this.props.getCurrentProfile();
        this.props.getQuestionnaireFillMember(this.props.match.params.id)
    }
    
      componentWillReceiveProps(nextProps) {
        if(nextProps.profile && nextProps.profile.profile && nextProps.profile.profile.roles.includes('ROLE_MEMBER') && !this.state.logged){
            this.props.getLogActivityWithGeoIp('Members Result Questionnaire', 'GET', "Members SA tool")
            this.setState({
                logged: true
            })
        }
 }

handleSave = (values, props) => {
}

submit = (values, props) => {

}

handleClickSection = (id) => event => {
    event.preventDefault()
}
  render() {
     const { classes } = this.props;
     const {questFill} = this.props.questionnaire;
     let url = null;
     let backUrl = null; 
     let nextUrl = null; 
     const { profile } = this.props.profile;
    let dashboardContent;
    if (profile === null) {
        dashboardContent = <Spinner />;
    }else{
        dashboardContent = <ProfileActions profileItem={profile}/>
    }
     if(this.props.match.params.part == 1){
        url = '/api/supplier-quest/data/'+ this.props.match.params.id;
        nextUrl = '/questionnaire/member/result/2/'+this.props.match.params.id
     }else{
         url = '/api/member/quest/form-result/data/' +  this.props.match.params.id;
         backUrl = '/questionnaire/member/result/1/'+this.props.match.params.id
     }

    return (
             <Grid container spacing={0} direction="row">
   <div className="container-fluid" >
            <div className="page-content  slide page-animation">
            {dashboardContent}
                <Grid container className={classes.root} direction="row" justify="flex-start">
                    <Grid item  md={1}></Grid>
                     {questFill && <Grid item container md={10}  >
                             <Form
                                      onSubmit={this.submit}
                                      title={null}
                                      form={'initializeFromState2'}
                                      formRoute={url}
                                      saveRoute={''}
                                      section={questFill.section}
                                      alone={false}
                                      nextUrl={nextUrl}
                                      backUrl={backUrl}
                                      isNew={true}
                                      handleSave={this.handleSave}
                                      submitButton={false}
                                      isResult={true}
                                      download={true}
                                      returnUrl={"/members/questionnaires"}
                                    />
                          </Grid>}
               <Grid item  md={2} style={{margin: 60}}> </Grid>     
               </Grid>
        </div>
                 <Navbar/>
                </div>
      </Grid>
    );
  }
}

MembersResultQuestionnaire.propTypes = {
  questionnaire: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getQuestionnaireFillMember: PropTypes.func.isRequired, 
  formProperties: PropTypes.object.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  getLogActivityWithGeoIp: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  questionnaire: state.questionnaire,
  formProperties: state.formProperties,
  profile: state.profile
});

MembersResultQuestionnaire = withStyles(styles)(MembersResultQuestionnaire)

export default withRouter(connect( mapStateToProps, { getQuestionnaireFillMember,  getCurrentProfile, getLogActivityWithGeoIp })(MembersResultQuestionnaire));
