import React from "react";
import PropTypes from "prop-types";
import { renderFromHelper } from '../../../common/MaterialUiForm'

const ShowError = ({ error, touched }) =>
  error && error !== "undefined" && touched ? (
           <span>{renderFromHelper({ touched, error })}</span>
          )
  :
          null
  ;

ShowError.propTypes = {
  error: PropTypes.string,
  touched: PropTypes.bool
};

export default ShowError;