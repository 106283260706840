import React, { Component } from 'react';
import Sectors from './Sectors'
import Sdgs from './Sdgs'
import SocialNetworks from './SocialNetworks'
import Contribute from '../Contribute'
import Grid from '@material-ui/core/Grid'
import SaveForLater from '../SaveForLater'
import LoginForm from '../../../layout/LoginForm'
import Diff from 'react-stylable-diff'

class ContributionTool extends Component {
  render() {
      const contribution = this.props.contribution;   
return (
        <div>
  
    <div className="col-md-12 right-header" >
    <div className="col-md-12 clearfix">
       {contribution.projectStatusName && <div className="col-md-5 col-sm-5 noPadding" >
        {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor ?
                         <p className="white-label">
                         <Diff type="sentences" inputA={contribution.reflection.projectStatusName} inputB={contribution.projectStatusName}/>
                         </p>
                          :
                         <p className="white-label">{ contribution.projectStatusName}</p>
                      }
           
        </div>}
        <div className="col-md-7 col-sm-7" >
            {contribution.projectAt && <div className="col-md-12 noPadding clearfix" >
                <h5 className="text-uppercase project-date text-dark-blue-old pull-left">date: </h5>
                <h5 className="text-white" >                   
                     {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor ?
                        <Diff type="sentences" inputA={contribution.reflection.projectAt} inputB={contribution.projectAt}/>
                          :
                          <span>{ contribution.projectAt}</span>
                      }
                </h5>
            </div>}
        </div>
    </div>
</div>

{contribution.sectors &&
        <Sectors sectors={contribution.sectors}/>
}
{contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor &&
<Diff type="sentences" inputA={contribution.reflection.sectorsText} inputB={contribution.sectorsText}/>
}
 <LoginForm role="filter"/>
{contribution.href &&
<SocialNetworks url={contribution.href}/>
}
<SaveForLater id={contribution.id} type="contrib"/>

{contribution.sdg &&
<Sdgs sdgs={contribution.sdg}/>
}
{contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor &&
<Diff type="sentences" inputA={contribution.reflection.sdgText} inputB={contribution.sdgText}/>
}
{contribution.publisher &&
<div className="col-xs-12" >
    <h4 className="text-uppercase text-gray">developer</h4>
    {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor ?
          <p className="text-black" >
          <Diff type="sentences" inputA={contribution.reflection.publisher} inputB={contribution.publisher}/>
          </p>
         :
        <p className="text-black" >{ contribution.publisher}</p>
        }
    
   
</div>
}
{contribution.links &&
<div className="col-xs-12" >
    <h4 className="text-uppercase text-gray">link</h4>
   {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor ?
          <Diff type="sentences" inputA={contribution.reflection.links} inputB={contribution.links}/>
         :
        <a href={contribution.links} target="_blank">{contribution.links}</a>
        }
</div>
}
</div>);
  }
}

export default ContributionTool;