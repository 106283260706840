import React from "react";
import PropTypes from "prop-types";
import DropZone from "react-dropzone";
import ImagePreview from "./ImagePreview";
import Placeholder from "./placeholder";
import ShowError from "./showError";
//import './style.css'
const imagefile = null;
const DropZoneFields = ({
  handleOnDrop,
  handleOnDelete,
  input: { onChange },
  files,
  label,
  accept,
  multiple,
  preview,
  meta: { error, touched },
  ...custom
}) => (
  <div className="preview-container">

       <div>{label}</div>
    <DropZone
      accept={ accept ? accept : "" }
      className="upload-container"
      onDrop={file => handleOnDrop(file, onChange)}
      maxSize={2000000}
      multiple={multiple}
     value={multiple ? files : files[0]}
    >

      {({ getRootProps, getInputProps }) =>
        files && files.length > 0 ? (
        <span>
        <Placeholder
            error={error}
            touched={touched}
            getInputProps={getInputProps}
            getRootProps={getRootProps}
          />

          {files.map((file, key) => (
        <span key={key}>
       {preview ?
         <ImagePreview file={file}  key={key}  />
         :
        <h5 style={{marginTop: 10}}>{file.name}</h5>
     }
         <button type="button" className="dropzone-button" onClick={file => handleOnDelete(file, onChange)} value={file.preview} >x</button>

      </span>
        ))}
        </span>
        ) : (
        <span>

          <Placeholder
            error={error}
            touched={touched}
            getInputProps={getInputProps}
            getRootProps={getRootProps}
          />


          </span>
        )
      }
    </DropZone>
    <ShowError error={error} touched={touched} />
  </div>
);

DropZoneFields.propTypes = {
  error: PropTypes.string,
  handleOnDrop: PropTypes.func.isRequired,
  handleOnDelete: PropTypes.func.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  touched: PropTypes.bool
};

export default DropZoneFields;
