import React from 'react';
import { DateTimePicker } from "@material-ui/pickers";
import FormControl from '@material-ui/core/FormControl';
import {renderFromHelper} from '../../../common/MaterialUiForm'
import moment from 'moment';

class DateTimeComponent extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            value:  null
        };       
    }

componentDidMount() {
       if(this.props.input.value){
           this.setState({
               value: this.props.input.value
           })
       }else{
           this.setState({
               value: null
           })
       }
    }

    onChange = (value) => {
       this.props.input.onChange(value)
        this.setState({
           value: value//.format("DD-MM-YYYY")
       })
    }

    render() {
        const {label, input, meta: { touched, invalid, error }, custom} = this.props;

        return  <FormControl fullWidth style={{fontSize: 16}} >
    <div className="text-left" style={{ fontSize: 14, marginTop: 15, textTransform: 'uppercase' }}>{label}</div>
        <DateTimePicker
         {...input}
          {...custom}
        autoOk
        ampm={false}  
        value={this.state.value}
        onChange={this.onChange} 
        format="DD-MM-YYYY HH:mm:ss"
      />
       {renderFromHelper({ touched, error })}
    </FormControl>
    }
}



export default DateTimeComponent;