import client from './client';

import {
  GET_SINGLE_NEWS,
  GET_NEWS,
  NEWS_LOADING,
  GET_ERRORS,
  GET_NEWS_CATEGORIES,
  GET_NEWS_OFFSET,
  GET_NEWS_TOTAL,
  CLEAR_NEWS
} from './types';
export const clearNews = () => dispatch => {
    dispatch({
        type: CLEAR_NEWS,
        payload: null
      })
}
// Get current profile
export const getNews = (offset, limit, category) => dispatch => {
  dispatch(setNewsLoading());
  
  client
    .get('/web/news', {params: {offset: offset, limit: limit, category: category}})
    .then(res => {
       
        dispatch({
        type: GET_NEWS_OFFSET,
        payload: res.data.offset
      })
      dispatch({
        type: GET_NEWS_TOTAL,
        payload: res.data.total
      })
      dispatch({
        type: GET_NEWS,
        payload: res.data.news
      })
      
  })
    .catch(err => {       
      dispatch({
        type: GET_NEWS,
        payload: {}
      })
     
  });
};


// Get current news
export const getSingleNews = (id) => dispatch => {
  dispatch(setNewsLoading());
  client
    .get('/web/news/'+id )
    .then(res =>
      dispatch({
        type: GET_SINGLE_NEWS,
        payload: res.data
      })
    )
    .catch(err => {
      dispatch({
        type: GET_SINGLE_NEWS,
        payload: {}
      })
  });
};

// Get news categories
export const getNewsCategories = () => dispatch => {
  client
    .get('/web/news/all/categories')
    .then(res =>
      dispatch({
        type: GET_NEWS_CATEGORIES,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_NEWS_CATEGORIES,
        payload: {}
      })
       
  });
};

// News loading
export const setNewsLoading = () => {
  return {
    type: NEWS_LOADING
  };
};