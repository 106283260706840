import client from './client';
import { toast } from 'react-toastify'

import {
  FILE_PROFILE,
  GET_USER_MEMBERS,
  GET_USER_MEMBERS_FILES,
  ADD_USER_MEMBERS_FILE,
  REMOVE_USER_MEMBERS_FILE
} from './types';



export const replaceFile = (route, data, history, id) => dispatch => {  
  client
    .post('/api/members/files/replace/file', data)
    .then(res => {
        if(res.data.message){
         toast.success(res.data.message, {autoClose: 10000})
        }
             dispatch({
        type: FILE_PROFILE,
        payload: res.data
      })
    })          
    .catch(err => {       
       if(err.response.data && err.response.data.error){
        toast.error(err.response.data.error, {autoClose: 10000})
    }
  });
};

// Get 
export const getFile = (route) => dispatch => {  
  client
    .get(route)
    .then(res =>
      dispatch({
        type: FILE_PROFILE,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: FILE_PROFILE,
        payload: []
      })
       
  });
};

export const downloadFile = (route, name) => dispatch => {
        client
                .get(route, {responseType: 'blob'})
                .then(res => {       
                    console.log(res.data)
                    var blob = new Blob([res.data], {type: res.headers['content-type'+";charset=utf-8"]});
                    var downloadUrl = URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = downloadUrl;
                    a.download = name;
                    document.body.appendChild(a);
                    a.click();
                })
                .catch(err => {
                    toast.error('Download error!', {autoClose: 10000})
                });
    };
    
export const changePrivacy = (data, history, id) => dispatch => {
    client
    .post('/api/admin/files/change/file/privacy', data)
    .then(res => (    
       dispatch({
        type: FILE_PROFILE,
        payload: res.data
      })
  ))          
    .catch(err => {
        console.log('err.response.data')
      
  });
    };
    
    export const getUserMembers = () => dispatch => {  
  client
    .get('/api/user/members/form')
    .then(res =>
      dispatch({
        type: GET_USER_MEMBERS,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_USER_MEMBERS,
        payload: []
      })
       
  });
};

export const getUserMemberFiles = () => dispatch => {  
  client
    .get('/api/members/files/get/data')
    .then(res =>
      dispatch({
        type: GET_USER_MEMBERS_FILES,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_USER_MEMBERS_FILES,
        payload: []
      })
       
  });
};
    
export const uploadButton = (form) => dispatch => { 
    client
           .post("/api/members/files/upload/1", form,  {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
           .then(res => {     
                dispatch({
                  type: ADD_USER_MEMBERS_FILE,
                  payload: res.data
                })
       toast.success('Upload success!', {autoClose: 10000})
           })
           .catch(err => {
               console.log('err')
               toast.error('Upload error - your file is too big or in wrong format.', {autoClose: 10000})
           });
};    

export const getDeleteFile = (id) => dispatch => {  
  client
    .get('/api/members/files/get/delete/'+id)
    .then(res =>
      dispatch({
        type: REMOVE_USER_MEMBERS_FILE,
        payload: res.data
      })
    )
    .catch(err => {       
     toast.error('Delete error.', {autoClose: 10000})
       
  });
};

export const unlockFile = (id) => dispatch => {  
  client
    .get('/api/members/files/get/unlock/'+id)
    .then(res =>
      dispatch({
        type: FILE_PROFILE,
        payload: res.data
      })
    )
    .catch(err => {       
     toast.error('Unlock error.', {autoClose: 10000})
       
  });
};

export const sendMessage = (data) => dispatch => {
  client
           .post("/api/members/files/send/message", data)
           .then(res => {
               toast.success('Success!', {autoClose: 10000})
           })
           .catch(err => {
               console.log('err')
    });

};

export const sendFile = (data) => dispatch => {
    client
           .post("/api/members/files/send/file", data)
           .then(res => {
               toast.success('Success!', {autoClose: 10000})
           })
           .catch(err => {
               console.log('err')
    });

};

export const uploadExcelFile = (route, form) => dispatch => { 
    client
           .post(route, form,  {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
           .then(res => {     
                /*dispatch({
                  type: ADD_USER_MEMBERS_FILE,
                  payload: res.data
                })*/
       toast.success('Upload success!', {autoClose: 10000})
           })
           .catch(err => {
             toast.error('Upload error - your file is too big or in wrong format.', {autoClose: 10000})
           });
};    
