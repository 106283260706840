import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {exportPdfDataButton} from '../../../actions/gridActions';


const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

class MCReportPdfComponent extends Component {   
    
    render() {     
    return (           
      <Grid container spacing={0} >    
          {this.props.pdfData &&
   <div  className="container-fluid" style={{width: 1024, margin: '0 auto'}}>
    <div className="row" style={{ margin: 20 }}>
        <div className="col align-self-center">
            <div className="text-center" style={{ fontFamily: 'Montserrat', fontSize: 24, fontWeight: 'bold'}}>
                {this.props.pdfData.title}</div>
        </div>
    </div>

    <div className="row" style={{marginTop: 20}}>
        <div className="col-md-12">
            <table className="table table-bordered" style={{ tableLayout:'fixed', fontSize: 12, fontFamily: 'Open Sans' }}>          
                <tbody style={{ fontFamily: 'Open Sans' }}>                    
                    {this.props.pdfData.columns.map((header, k) => (                            
                            <tr className="thead-light" style={{fontFamily: 'Open Sans' }} key={k}>                            
                                <th width="10%" >{header.headerName}</th>
                                 <td>{this.props.pdfData.data[header.field]}</td> 
                                                                  
                            </tr>   
                            ))}                 
                </tbody>

            </table>
        </div>
        <div className="col-md-6">-</div>
    </div>
</div>}
      </Grid>
    );
  }
}

export default MCReportPdfComponent;
