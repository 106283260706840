import React, { Component }  from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form'
import { change } from 'redux-form';
import RadioButtonsGroup from '../../common/RadioButtonsGroup'
import CheckboxGroupField from '../../common/CheckboxGroupField'
import Grid from '@material-ui/core/Grid';
import { ToastContainer, toast } from 'react-toastify'
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IntegrationReactSelect from '../../common/IntegrationReactSelect'
import {renderDatePickerField, renderTextField, renderSelectField, renderCheckbox, renderTmcField, renderPasswordField, radioButton} from '../../common/MaterialUiForm'
import Select2 from '../questionnaire/types/Select2';
import Switch from '../form/types/Switch';
import Textarea from '../form/types/Textarea';
import DatePickerComponent from '../form/types/DatePickerComponent'
import DateTimeComponent from '../form/types/DateTimeComponent'
import DropZoneComponent from '../form/file/DropZoneComponent'
import Select2Creatable from '../../common/Select2Creatable'
import DateYearComponent from '../form/types/DateYearComponent'

const styles = theme => ({
  root: {
    htmlFontSize: 20,
  },
  label: {
    textTransform: 'none',
  },
  });



class MergeForm extends Component {
    constructor(props) {
     super(props);     
     }
     
    changeFieldValue = (id, value) => {
        this.props.change(id, value);
    }
        
render () {
  const { handleSubmit, pristine, reset, submitting, classes } = this.props;

  const accountType = this.props.type === 'companyName' ? 'organization' : 'contact';
  return (
 <Grid item md={12} lg={12} xs={12} container className="duplicateRecords-form">
     
 <form className={classes.form} noValidate onSubmit={handleSubmit} style={{marginBottom: 30, width: '100%'}}>
     
      {this.props.formFields &&  this.props.formFields.map((field, k) => {
          
                              let component = renderTextField;
                                let data = null;
                                if(field.type === 'hidden'){
                                    component =  'input';
                                }
                                if (field.type === 'text'){
                                    component = renderTextField;
                                }
                                if(field.type === 'select2'){
                                    component = Select2;
                                }
                                if(field.type === 'select'){
                                    component = Select2;
                                }
                                if(field.type === 'selectCreatable'){
                                    component = Select2Creatable;
                                }                              
                              
                                 if(field.type === 'checkboxGroup'){
                                    component = CheckboxGroupField;
                                }
                                if(field.type === 'switch'){
                                    component = Switch;
                                }
                                if(field.type === 'textarea'){
                                    component = Textarea;
                                }
                               
                                if(field.type === 'tags'){
                                    component = IntegrationReactSelect;
                                }
                                if(field.type === 'datePicker'){
                                    component = DatePickerComponent;

                                }
                                if(field.type === 'datetimePicker'){
                                    component = DateTimeComponent;
                                }
                                if(field.type === 'dateYearPicker'){
                                    component = DateYearComponent;
                                }
                                if(field.type === 'radio'){
                                    component = RadioButtonsGroup;
                                }
                               
                                let col = 6;
                               if(typeof field.col !== 'undefined'){
                                 col = field.col;
                               }
                               if(field.type === 'file'){
                                     component = DropZoneComponent;
                                     data = field.avatarFiles;
                               }

                               if(field.type === 'checkbox'){
                                    component = renderCheckbox;
                                }
           return    <Grid item md={6} lg={6} xs={6} key={k} style={{marginTop: 20}}>
               <h3>{field.labelD}</h3>    
           <h4>Main account {field.labelD}: {field.mainAccount}</h4>
               
                    <label>{field.labelC}</label> 
                    <Field
                name={field.id}
                id={field.id}
                change={this.changeFieldValue}                
                field={field}
                component={component}                
                variant="outlined"         
                options={field.options} 
                data={data}        
         /></Grid>})}
      <DialogActions style={{justifyContent: 'center'}}>      
         <button type="submit" className="button-primary" >Finish and merge</button>
      </DialogActions>
          </form>
            </Grid>
);
  }
}


MergeForm.propTypes = {
  formFields:    PropTypes.array.isRequired,
 // mainAccount: PropTypes.string.isRequired
};

const mapStateToProps = state => ({

});

MergeForm = withStyles(styles)(MergeForm)

export default connect(mapStateToProps, {})(reduxForm({form: 'initializeFromState2', enableReinitialize: true, keepDirtyOnReinitialize: true})(MergeForm))
