import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUserContributions, setContributions } from '../../actions/contributionActions';
import { getCurrentProfile } from '../../actions/profileActions';
import Spinner from '../common/Spinner';
import ProfileActions from '../dashboard/ProfileActions';
import Navbar from '../layout/Navbar';
import Footer from '../layout/Footer';
import Grid from '@material-ui/core/Grid';
import logoSide from '../../img/logo-side-menu.png';
import Button from '@material-ui/core/Button';
import moment from 'moment';

class MyContributions extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getCurrentProfile();
        this.props.getUserContributions();
    }

    componentWillUnmount(){
        this.props.setContributions();
    }


    render() {
        const { contributions } = this.props.contribution;
         const { profile } = this.props.profile;

        let dashboardContent;
        //if (profile === null || loading) {
        if (profile === null) {
            dashboardContent = <Spinner />;
        } else {
            dashboardContent = <ProfileActions profileItem={profile} />
        }

        return (
            <Grid container spacing={0}>
                {dashboardContent}
                <Grid item container lg={12} style={{ padding: "15px 15px 100px 15px" }} >
                    <div className="forum text-left" style={{width: '100%'}}>
                        <h3 style={{ paddingBottom: "20px" }}>My contributions</h3>

                        <Grid item container lg={12} className="forum-threads"  direction="row">
                            <Grid item container lg={12} className="threads-head"  direction="row">
                              <Grid item lg={4} md={4} className="col-md-4">Title</Grid>
                               <Grid item lg={2} md={2} className="col-md-2">Type</Grid>
                               <Grid item lg={3} md={3} className="col-md-3">Status</Grid>
                               <Grid item lg={3} md={3} className="col-md-3">Created at</Grid>
                            </Grid>
                            {contributions && contributions.map(contribution => (
                                <Grid item container lg={12} className="thread-row"  key={contribution.id} direction="row">
                        <Grid item lg={4} md={4} className="col-md-4">
                        <i className="fa fa-lg fa-fw fa-chevron-right" style={{marginLeft: 25}}></i>
                            <a href={'/contribute/my-contributions/' + contribution.id}>
                                                {contribution.projectTitle}
                            </a>
                         </Grid>
                               <Grid item lg={2} md={2} className="col-md-2">{contribution.contentTypeName}</Grid>
                               <Grid item lg={3} md={3} className="col-md-3">{contribution.flagName}</Grid>
                               <Grid item lg={3} md={3} className="col-md-3">{contribution.createdAt}</Grid>

                                </Grid>
                            ))}

                        </Grid>
                    </div>
                </Grid>
                <Navbar />
            </Grid>
        );
    }
}

MyContributions.propTypes = {
    getUserContributions: PropTypes.func.isRequired,
    setContributions: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    profile: state.profile,
    contribution: state.contribution,
    auth: state.auth
});

export default connect(mapStateToProps, { getUserContributions, setContributions, getCurrentProfile })(MyContributions);
