import React, { Component } from 'react';

export default class ConventionsCellRenderer extends Component {
        constructor(props) {
        super(props);
}
 

  render() {
       var color = 'red';  
       const data = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex).data;
     
      if(this.props.colDef.field === 'stockholmYes' && typeof data['stockholmA'] === 'undefined' &&  typeof data['stockholmB'] === 'undefined' && typeof data['stockholmC'] === 'undefined'){
          color = '#32CD32'
      }
       if(this.props.colDef.field === 'minamataYes' && typeof data['minamataNo'] === 'undefined'){
          color = '#32CD32'
      }
        if(this.props.colDef.field === 'viennaYes' && typeof data['viennaNo'] === 'undefined'){
          color = '#32CD32'
      }
       if(this.props.colDef.field === 'baselYes' && typeof data['baselNo'] === 'undefined'){
          color = '#32CD32'
      }
       if(this.props.colDef.field === 'roterdamYes' && typeof data['roterdamNo'] === 'undefined'){
          color = '#32CD32'
      }
    return (
       <div style={{background: color}} className="text-center">  
   {typeof this.props.value === 'undefined' ?
   <span>0.00</span>
   :
      <span> {this.props.value}    </span>
      }
      </div>
    );
  }
}