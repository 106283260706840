import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getThreads } from '../../actions/forumActions';
import { getCurrentProfile } from '../../actions/profileActions';
import Spinner from '../common/Spinner';
import ProfileActions from '../dashboard/ProfileActions';
import Navbar from '../layout/Navbar';
import Footer from '../layout/Footer';
import Grid from '@material-ui/core/Grid';
import logoSide from '../../img/logo-side-menu.png';
import Button from '@material-ui/core/Button';
import { getLogActivityWithGeoIp } from '../../actions/profileActions';


class ContributorsForum extends Component {

    constructor(props) {
        super(props);
         this.state = {
            logged: false
        };
    }

    componentDidMount() {
        this.props.getCurrentProfile();
        this.props.getThreads(1, 'contributor');
        
    }
    
   componentWillReceiveProps(nextProps) {
        if(nextProps.profile && nextProps.profile.profile && nextProps.profile.profile.roles.includes('ROLE_CONTRIBUTOR') && !this.state.logged){
            this.props.getLogActivityWithGeoIp('Contributors Forum', 'GET', "Contributors' forum")
            this.setState({
                logged: true
            })
        }
 }
    render() {
        const { profile } = this.props.profile;
        const { threads } = this.props.threads.threads;

        let dashboardContent;
        //if (profile === null || loading) {
        if (profile === null) {
            dashboardContent = <Spinner />;
        } else {
            dashboardContent = <ProfileActions profileItem={profile} />
        }

        return (
          <div className="container-fluid" >
            <div className="page-content slide page-animation">
            <Grid container spacing={0}>
                {dashboardContent}
                <Grid item container lg={12} style={{ padding: "15px 15px 100px 15px" }} >
                    <div className="forum text-left" style={{width: '100%'}}>
                        <h3 style={{ paddingBottom: "20px" }}>Contributors forum</h3>
                        <Button className="btn btn-primary btn-undp" href="/contributors/forum/new-thread" target="_self">
                            New thread
                        </Button>
                        <Grid item container lg={12} className="forum-threads">
                            <Grid container className="threads-head">
                                <Grid item lg={6} xs={5}>Topic</Grid>
                                <Grid item lg={2} xs={3}>Author</Grid>
                                <Grid item lg={2} xs={2}>Replies</Grid>
                                <Grid item lg={2} xs={2}>Created at</Grid>
                            </Grid>
                            {threads && threads.map(thread => (
                                <Grid container className="thread-row" ng-repeat="thread in threads" key={thread.id}>
                                    <Grid item lg={6} xs={5}>
                                        <i className="fa fa-lg fa-fw fa-chevron-right"></i>
                                        <a href={'/contributors/forum/thread/' + thread.id}>
                                            {thread.subject}
                                        </a>
                                    </Grid>
                                    <Grid item lg={2} xs={3}>{thread.author.firstName} {thread.author.lastName}</Grid>
                                    <Grid item lg={2} xs={2}>{thread.replyCount}</Grid>
                                    <Grid item lg={2} xs={2}>{thread.friendlyCreatedAt}</Grid>
                                </Grid>
                            ))}

                        </Grid>
                    </div>
                </Grid>
                <Navbar />
            </Grid>
          </div>
          </div>
        );
    }
}

ContributorsForum.propTypes = {
    getThreads: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    getLogActivityWithGeoIp: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    profile: state.profile,
    threads: state.threads,
    auth: state.auth
});

export default connect(mapStateToProps, { getThreads, getCurrentProfile, getLogActivityWithGeoIp })(ContributorsForum);
