import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import {getMapFilter} from '../../actions/sectorsActions'
import moment from 'moment'
import {DateRangePicker} from 'react-dates'
import LoginForm from '../layout/LoginForm'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import {DatePicker as DatePickerComponent} from 'antd';
import '../admin/grid/grid-style.css';
const { RangePicker } = DatePickerComponent;


class MapFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            explore: [],
            sectors: [],
            presence: [],
            areCountries: false,
            startDate: null,//moment('01.01.1990','DD.MM.YYYY'),
            endDate: null,//moment().add(1, 'd'),
            datesClosed: true,
            type: null,
            filter: {
                type: [0, 1, 2, 3, 4, 5],
                map: true,
                limit: 1000,
                startDate: null,
                endDate: null,
            }

        }
    }

      onDatesFocusChange = (focusedInput) => {
        if (this.state.datesClosed) {
            focusedInput = 'startDate';
            this.setState({datesClosed: false});
        }

        this.setState({focusedInput})
    }

     handleChangeEvent = (event) => {
         
        let filter = this.state.filter;
        let start =  event[0];
        let end = event[1];
        if(end && typeof end !== 'undefined'){
         filter['endDate'] = end.format("YYYY-MM-DD");
        }else{
            filter['endDate'] = null;
        }
        if(start && typeof start !== 'undefined'){
         filter['startDate'] = start.format("YYYY-MM-DD");
        }else{
            filter['startDate'] = null;
        }
        this.props.onChange(filter)
        this.setState({
            startDate: event.length === 2 ? start : null,//moment('01.01.1990','DD.MM.YYYY'),
            endDate: event.length === 2 ? end : null,//moment().add(1, 'd'),
            filter: filter
        });
    }

    componentDidMount() {
       this.props.getMapFilter()
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.sector.mapFilter){
            nextProps.sector.mapFilter.map(row =>{
                  if(row.id === "explore"){
                      this.setState({
                          explore: row.children
                      })
                  }
                   if(row.id === "focus-area"){
                      this.setState({
                          sectors: row.children
                      })
                  }
                   if(row.id === "sphs-presence"){
                      this.setState({
                          presence: row.children
                      })
                  }
            })
        }
    }

     handleClickOpen = () => {
         this.setState({
        open: true
    });
  }

  handleClose = () => {
    this.setState({
        open: false
    });
  }

    handleClickFilterExplore = (value) => {
        if(value === 'explore'){
         let   filter = {
            type: [0, 1, 2, 3, 4, 5],
            map: true,
            limit: 1000,
            startDate: this.state.startDate,
            endDate: this.state.endDate
        };
        this.setState({
            filter: filter,
            type: null,
        })
            this.props.onChange(filter)
        }else{
        let explore = this.state.explore;
        Object.keys(explore).map(key => {
            var exploreType = explore[key];
            if(exploreType.id === value && exploreType.name === 'Success story'){
                this.setState({
                    type: 'Success stories',
                });
            }else if(exploreType.id === value && exploreType.name !== 'Success story'){
                this.setState({
                    type: exploreType.name+'s',
                });
            }
        });

        let filter = {
            type: [value],
            map: true,
            limit: 1000,
            startDate: this.state.startDate,
            endDate: this.state.endDate
        }
        this.setState({
            filter: filter
        })
        this.props.onChange(filter)
        }
        this.setState({
            open: false,
            legendSelected: value
        });
    }

     handleClickFilterSectors = (value) => {

        if(value === 'focus-area'){
            this.props.onChange(this.state.filter)
        }else{
            let filter = {
                sector: [value],
                map: true,
                limit: 1000,
                startDate: this.state.startDate,
                endDate: this.state.endDate
            }
            this.props.onChange(filter)
        }
        this.setState({
            open: false,
            type: null
        });
    }

    handleClickFilterPresence = (value) => {

       this.props.onChangePresence(value)
       this.setState({
            open: false,
            type: null
        });
    }

    render() {
    let explore;
    if(this.state.explore.length > 0){
    explore = this.state.explore.map(type => (

            <div className="col-md-12 dropparrent" key={type.id}>
                        <a href="#"  onClick={() => {this.handleClickFilterExplore(type.id)}}>
                        <span className={type.name}>
                        <i></i></span>{type.name}
                            </a>
            </div>
    ));
        }
    let sectors;
    if(this.state.sectors.length > 0){
        sectors = this.state.sectors.map(sector => (
            <div className="col-md-6 dropparrent" key={sector.sectorId}>
                <a href="#"  onClick={() => {this.handleClickFilterSectors(sector.sectorId)}}>
                <span className={sector.name}><i></i></span>
                {sector.name}
                </a>
            </div>
        ))
    }

    let presence;
     if(this.state.presence.length > 0){
        presence = this.state.presence.map((presence, i) => (
                    <div className="col-md-12 dropparrent" key={i}>
                        <a href="#"  onClick={() => {this.handleClickFilterPresence(presence.id)}}>
                            <span className={presence.name}>
                            <i></i>
                            </span>
                            {presence.name}
                        </a>
                    </div>
            ))
     }
        return (
        <div className="filter-area" >
          <div className="max-width">
          <Grid item container spacing={0} style={{paddingLeft: 15, paddingRight: 15}}>
            <Grid lg={10} md={9} sm={12} xs={12} item>
                    <div className="filter-box pull-left">
                    <div className="filter-box-label" onClick={this.handleClickOpen}>
                    <header >Select</header><i className="ion-android-menu" aria-hidden="true"></i>
                    </div>
                    <div className="close-filter " >
                    </div>
                    <Dialog
                        fullWidth={true}
                        maxWidth={'xl'}
                        open={this.state.open}
                        onClose={this.handleClose}
                        aria-labelledby="max-width-dialog-title"
                        PaperProps={{
                             style: {
                                background: '#26475a',
                                width: '100%',
                                marginTop: '30px',
                                borderBottom: '5px solid #5b7e9b',
                                borderRadius: 0,
                                borderWidth: '0 0 5px 0',
                                paddingBottom: '30px',
                                display: 'table',
                                left: 0,
                                zIndex: 2000
                            }
                        }}
                    >
                        <DialogContent >

                           <div  className="customScroll ">
                                <div className="max-width">
                                    <div className="col-md-3 text-left">
                                        <a href="#" className="filter-element-title" onClick={() => {this.handleClickFilterExplore('explore') }}>
                                                Explore Contributions
                                        </a>
                                       {explore}
                                    </div>

                                    <div className="col-md-5 text-left">
                                        <a href="#" className="filter-element-title" onClick={() => {this.handleClickFilterSectors('focus-area')}} >
                                        Explore Sectors
                                        </a>
                                        {sectors}
                                    </div>

                                    <div className="col-md-4 text-left">
                                        <a href="#" className="filter-element-title" onClick={() => {this.handleClickFilterPresence('sphs-presence')}}>
                                            Explore our Presence
                                        </a>
                                        {presence}
                                        <div className="pull-right margin-t-15 dropparrent" style={{    lineHeight: "inherit"}}>
                                            <a href="https://esa.un.org/MigFlows/Definition%20of%20regions.pdf" target="_blank" rel="noopener noreferrer">
                                            The United Nations Geoscheme system has been used for the Region and Country mappings.
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleClose} color="primary" variant="text" style={{color: 'white'}}>
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                    </div>
                    <div className="date-range pull-left">
                                    <RangePicker
                                        defaultValue={[this.state.startDate, this.state.endDate]}
                                        onChange={this.handleChangeEvent}
                                        format = {'DD.MM.YYYY'}
                                        focusedInput={this.state.focusedInput}
                                        onFocusChange={(focusedInput) => this.onDatesFocusChange(focusedInput)}

                                    />
                                </div>

                        <div className="legend pull-left">
                          <div className="legendItem" onClick={() => {this.handleClickFilterExplore(1)}}>
                           <span className={this.state.legendSelected === 1 ? "Event legendSelected" : "Event"}><i></i></span><p>Event</p>
                          </div>
                          <div className="legendItem" onClick={() => {this.handleClickFilterExplore(0)}}>
                           <span className={this.state.legendSelected === 0 ? "Project legendSelected" : "Project"}><i></i></span><p>Project</p>
                          </div>
                          <div className="legendItem" onClick={() => {this.handleClickFilterExplore(3)}}>
                           <span className={this.state.legendSelected === 3 ? "Publication legendSelected" : "Publication"}><i></i></span><p>Publication</p>
                          </div>
                          <div className="legendItem" onClick={() => {this.handleClickFilterExplore(2)}}>
                           <span className={this.state.legendSelected === 2 ? "Success story legendSelected" : "Success story"}><i></i></span><p>Success story</p>
                          </div>
                          <div className="legendItem" onClick={() => {this.handleClickFilterExplore(5)}}>
                           <span className={this.state.legendSelected === 5 ? "Tool legendSelected" : "Tool"}><i></i></span><p>Tool</p>
                          </div>
                          <div className="legendItem" onClick={() => {this.handleClickFilterExplore(4)}}>
                           <span className={this.state.legendSelected === 4 ? "Training legendSelected" : "Training"}><i></i></span><p>Training</p>
                          </div>
                        </div>
            </Grid>
            <Grid item lg={2}  md={3} sm={12} xs={12}>
               <LoginForm role="oportunity"/>
            </Grid>
            <Grid item lg={4}  md={4} sm={12} xs={12}>
            {this.props.filterTotal &&
            <div ng-if="total > 0" className="ng-scope">
            <h6 ng-if="!areCountries" className="pull-left"> {this.props.filterTotal}  </h6>
            </div>
            }
            </Grid>
          </Grid>
         </div>
        </div>
                                );
                    }
                }
MapFilter.propTypes = {
    sector: PropTypes.object.isRequired,
    getMapFilter: PropTypes.func.isRequired,
    onChange:  PropTypes.func.isRequired,
    onChangePresence:  PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    sector: state.sector
});

export default connect(mapStateToProps, {getMapFilter})(MapFilter);
