import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import { getCommon } from '../../actions/memberActions';
import Navbar from '../layout/Navbar';
import Grid from '@material-ui/core/Grid';
import Footer from '../layout/Footer'
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import SpeakersOnlineForum from './elements/SpeakersOnlineForum';
import { config } from '../../config'

const ITEM_HEIGHT = 42;

class VirtualForum extends Component {
  constructor(props) {
  super(props);
          this.state = {
          height: '150px'
          }
  }

  componentDidMount() {
      window.scrollTo(0, 0);
  }

  componentWillReceiveProps(nextProps) {

  }

  handleClickOpen = event => {
  event.preventDefault();
          this.setState({ [event.target.id]: true });
  };
          handleClose = event => {
          this.setState({
          [event]: false,
                  password: '',
                  email: ''
          });
          };
          handleExpand = () => {

  if (document.getElementById('expand-more').offsetHeight === 150){
  this.setState({
  height: '250px'
  });
  }
  if (document.getElementById('expand-more').offsetHeight === 250){
  this.setState({
  height: '150px'
  });
  }

  }

  render() {
  let height = this.state.height;
          return (
            <div>
                <Navbar/>
            <div className="page-content  page-animation slideup">
                <Grid container>
                    <Grid item xs={12}>
                        <div className="asia-forum virtual-forum">
                            <div className="heroshot noPadding2"><img src="https://api.savinglivesustainably.org/images/4243x2855/249edc.jpeg" all="image"/>
                                  <div className="text-center">
                                   <div className="onlyMobile">
                                      <img className="asia-forum-logo" src="https://api.savinglivesustainably.org/images/842x341/bc9daf.png" all="image"/>
                                      <h3 className="text-white text-bold">
                                        The 3<sup>rd</sup> Saving Lives Sustainably: Global Virtual Forum 2020</h3>
                                      <h4 className="text-white"><i>Recovering Better After COVID-19 with Sustainable Production and Procurement of Health Commodities</i></h4>
                                      <h4 className="text-white">
                                      18-19 November 2020</h4>
                                    <h4 className="text-white">
                                      Time:	11:30-16:15 CET</h4>
                                    </div>
                                    <div style={{paddingBottom: 60}}>
                                        <h2 className="text-bold">440 Delegates, 85 countries, 6,000 + views</h2>
                                        <h2><a className="external-link" href="https://savinglivesustainably.org/news/122" target="_blank">Global Forum 2020 Report</a></h2>
                                        <h2><a className="external-link" href="https://www.youtube.com/watch?t=1879&v=ckhWYqPQnS4" target="_blank">Watch the Global Forum 2020 sessions</a></h2>
                                    </div>
                                  </div>
                                {/*}<div className="bounce">
                                  <a href="#first-section">
                                    <Icon style={{fontSize: "56px"}} className={'fa fa-angle-down text-white'}/>
                                  </a>
                                </div>*/}
                            </div>
                            <div className="intro">
                                <div className="max-width">
                                <Grid container spacing={0}>
                                        <Grid item md={4} sm={4} xs={12}>
                                        <a className="anchor-link" id="first-section"></a>
                                            <div className="icon-box text-center">
                                                <div className="image">
                                                    <img src={config.API_URL + "/images/org/c9f92c.png?v1.0"} alt="image"/>
                                                </div>
                                                <a className="anchor-link" href="#asia-forum-intro-anchor-1"><h4 className="text-white">Featuring</h4></a>
                                                <i className="fa fa-angle-down" aria-hidden="true"></i>
                                            </div>
                                        </Grid>
                                      <Grid item md={4} sm={4} xs={12}>
                                            <div className="icon-box text-center">
                                                <div className="image">
                                                    <img src={config.API_URL + "/images/org/557693.png?v1.0"} alt="image"/>
                                                </div>
                                                <a className="anchor-link" href="#asia-forum-intro-anchor-2"><h4 className="text-white">Programme & Speakers</h4></a>
                                                <i className="fa fa-angle-down" aria-hidden="true"></i>
                                            </div>
                                        </Grid>
                                        <Grid item md={4} sm={4} xs={12}>
                                            <div className="icon-box text-center">
                                                <div className="image">
                                                    <img src={config.API_URL + "/images/org/a30285.png?v1.0"} alt="image"/>
                                                </div>
                                                <a className="anchor-link" href="#asia-forum-intro-anchor-3"><h4 className="text-white">Partners & Logistics</h4></a>
                                                <i className="fa fa-angle-down" aria-hidden="true"></i>
                                            </div>
                                        </Grid>


                                    </Grid>
                                </div>
                            </div>
                            <div className="about">
                                <div className="col-md-8 col-sm-12 col-md-offset-2 text-center">
                                  <p>
                                  The 3rd Saving Lives Sustainably: Sustainable Production in the Health Sector Global Forum 2020 is taking place on 18-19 November 2020. The virtual event is a part of International Conferences Program, honoring <a className="external-link" href="https://g20.org/en/Pages/home.aspx" target="_blank">G20 Saudi Presidency Year 2020</a>, and organised by the <a className="external-link" href="https://www.sfda.gov.sa/en" target="_blank">Saudi Food and Drug Authority (SFDA)</a>, G20 Saudi Secretariat,  <a className="external-link" href="https://www.undp.org/" target="_blank">United Nations Development Programme (UNDP)</a>, <a className="external-link" href="https://savinglivesustainably.org" target="_blank">Secretariat of the UN informal Interagency Task Team on Sustainable Procurement in the Health Sector (SPHS)</a> and <a className="external-link" href="https://noharm.org/" target="_blank">Health Care Without Harm (HCWH)</a>, with the support from the <a className="external-link" href="https://www.sida.se/English/" target="_blank">Swedish International Development Cooperation Agency (Sida)</a>.
                                  </p>
                                    <p>
                                    The Forum is an opportunity for suppliers, manufacturers, policymakers, technical experts, academia, civil society organizations and others active in the global health sector to explore the front-running sustainable and ethical consumption and production patterns and the latest public and private innovations on the sustainable procurement and manufacturing of health commodities.
                                    </p>
                                </div>
                                <div className="col-md-8 col-sm-12 col-md-offset-2 text-center">
                                    <h1>Why is Sustainable Production Important?</h1>
                                    <p>
                                      Sustainable production supports companies not only to reduce environmental, health, and safety impacts, but also to save resources and enhance competitiveness. Suppliers and manufacturers from across the global health care industry face a set of issues, especially with the COVID-19 pandemic, ranging from environmental degradation and antibiotic resistance caused by pollution to the intensive use of water and energy, and from the handling of hazardous waste to occupational health and safety risks. Given their extensive scale and their mission-driven interest in improving human health, suppliers and manufacturers operating in the health sector have the potential to become leaders in the transition of the entire economy towards sustainable products and practices.
                                    </p>
                                </div>
                                <div className="col-md-8 col-sm-12 col-md-offset-2 text-center">
                                    <h1>Suppliers and Manufacturers’ Contributions</h1>
                                    <p>
                                      We are inviting companies that plan to attend the 2020 Global Forum to contribute to the event in different ways - big or small. For example, by nominating company representatives to deliver a presentation or share a case study on the benefits of introducing more sustainable practices. All interested parties are invited to share ideas on how they can contribute to shaping the programme and discussions at the conference. Suppliers and manufacturers will play a major role, sharing concrete examples and case studies of the benefits and opportunities in introducing more sustainable production, procurement and disposal practices in the health sector. Forum organizers encourage interested organizations to nominate relevant speakers and topics.
                                    </p>
                                </div>
                                <div className="col-md-8 col-sm-12 col-md-offset-2 text-center">
                                    <h1>Target Audience</h1>
                                    <p>
                                      Suppliers and manufacturers, policymakers, technical experts, academia, and civil society organizations in the global health sector. On average, the Forum brings together around 400 delegates. Delegates are drawn from both developing and developed countries including from the 10 Sustainable Health in Procurement Project (SHiPP) countries.

                                    </p>
                                    <h3 className="text-bold">Interested?</h3> <a className="blue-button text-white text-uppercase" href="mailto:ian.milimo@undp.org"> Click here </a>
                                </div>
                            </div>
                          <SpeakersOnlineForum />
                            <div className="sponsors text-center partners ">
                            <a className="anchor-link" name="asia-forum-intro-anchor-3">&nbsp;</a>
                            <a className="anchor-link" name="asia-forum-intro-anchor-4">&nbsp;</a>
                            <img src={config.API_URL + "/images/org/0c1afa.png?v1.0"} alt="sponsors"/>
                                <h3 className="text-gray">
                                            We thank all our partners for their<br/> generosity and dedication to our mission.
                                </h3>
                                <h3 className="text-gray">
                                  The 3rd Saving Lives Sustainably: Sustainable Production in the Health Sector Global Forum 2020 is a virtual event and will be streaming live. Please register here. To follow the schedule, speakers and engage in discussion with other participants, please download <a href="https://trello.com/c/nufDmLof/15-event-app-1-download-socio-to-your-smartphone-via-https-socioevents-app-get" target="_blank">Socio</a> on your smartphone, type "Global Forum 2020" into the search bar and launch the event app.
                                </h3>
                                <div className="sponsors-box-fixed">
                                    <h5>
                                      THE FORUM IS ORGANIZED AS PART OF THE SUSTAINABLE HEALTH IN PROCUREMENT PROJECT (SHiPP), SUPPORTED BY THE SWEDISH INTERNATIONAL DEVELOPMENT COOPERATION AGENCY (SIDA), UNITED NATIONS DEVELOPMENT PROGRAMME (UNDP) AND HEALTH CARE WITHOUT HARM (HCWH)
                                    </h5>
                                  <div className="sponsors-box row justify-content-md-center align-items-center no-gutter">

                                    <div className="col noPadding">
                                        <a href="https://www.sfda.gov.sa/en" target="_blank"><img style={{width:"200px"}} src="https://api.savinglivesustainably.org/images/848x506/9b9d20.png" alt="Image"/></a>
                                    </div>
                                    <div className="col noPadding">
                                        <a href="https://www.sida.se/English/" target="_blank"><img style={{width:"180px"}} src="https://api.savinglivesustainably.org/images/589x214/f99fe8.png" alt="Image"/></a>
                                    </div>
                                    <div className="col noPadding">
                                        <a href="http://www.undp.org" target="_blank"><img style={{width:"50%"}} src="https://api.savinglivesustainably.org/images/1200x2430/6a3aa7.png" alt="Image"/></a>
                                    </div>
                                    <div className="col noPadding">
                                        <a href="https://noharm.org" target="_blank"><img src="https://api.savinglivesustainably.org/images/1059x1078/2ee580.png" alt="Image"/></a>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <a className="anchor-link" name="media">&nbsp;</a>
                            <Footer/>
                        </div>
                    </Grid>
                </Grid>
              </div>
            </div>

        );
    }
}
VirtualForum.propTypes = {

};

const mapStateToProps = state => ({
        //   member: state.member
    });

export default connect(mapStateToProps, {})(VirtualForum);
