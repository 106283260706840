import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Field, reduxForm} from 'redux-form';
import { change } from 'redux-form';
import validate from './validator';
import {
onLoadForm as onLoadForm, onSave
        } from "../../../actions/formActions";
import FormLoader from "../form/FormLoader";
import {onHideMessage, onShowMessage} from "../../../actions/gridActions";
import {FORM_SHOW_LOADER} from "../../../actions/types";
import IntegrationReactSelect from '../../common/IntegrationReactSelect'
import {renderDatePickerField, renderTextField, renderSelectField, renderCheckbox, renderTmcField, renderPasswordField, radioButton} from '../../common/MaterialUiForm'
import RadioButtonsGroup from '../../common/RadioButtonsGroup'
import Grid from '@material-ui/core/Grid';
import Select2 from './types/Select2';
import Contacts from './types/Contacts';
import Switch from './types/Switch';
import Textarea from './types/Textarea';
import DatePickerComponent from './types/DatePickerComponent'
import DateTimeComponent from './types/DateTimeComponent'
import { toast } from 'react-toastify'
import "./redux-forms.css";
import DropZoneComponent from './file/DropZoneComponent'
import CheckboxGroupField from '../../common/CheckboxGroupField'
import Select2Creatable from '../../common/Select2Creatable'
import Collection from './types/Collection';
import Sections from './types/Sections'
import DateYearComponent from './types/DateYearComponent'
import DialogConfirm from '../../common/DialogConfirm'
import { unlockButton } from "../../../actions/questionnaireActions";

class Form extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
            submitting: false,
            unlockRoute: null,
            openConfirm: false
            };

            this.updateForm = this.updateForm.bind(this);
            this.changeFieldValue = this.changeFieldValue.bind(this);
        }

        changeFieldValue(id, value) {
            this.props.change(id, value);
        }

        componentDidMount() {
            this.updateForm();
        }

   componentWillReceiveProps(nextProps) {
       if(this.props.formProperties && typeof this.props.formProperties.form !== 'undefined'){

       }
    }

        componentDidUpdate(prevProps) {
            if (this.props.match && prevProps.match.params.id !== this.props.match.params.id) {
                this.updateForm();
            }
        }

        updateForm() {
            this.props.onLoader(this.props.form, true);
            if (this.props.title) {
                    let titleArr = this.props.title;
                    titleArr.map((item, key) => titleArr[key] = item);
                    document.title = titleArr.join(' ');
            }

            this.props.onLoadForm(this.props.form, this.props.formRoute);
        }
        
    handleNo = () => {
         this.setState({
             openConfirm: false,
             unlockRoute: null
         })
    }
    
    openConfirm = (route) => {
        this.setState({
             openConfirm: true,
             unlockRoute: route
         })
    }
    
    handleYes = () => {
        this.props.unlockButton(this.state.unlockRoute)
         this.setState({
             openConfirm: false,
             unlockRoute: null
         })        
          setTimeout(() => {
         this.updateForm()
          }, 3000)
    }

    resetForm = () => this.props.reset();

render() {
    let {handleSubmit, formProperties, pristine, reset, submitting, invalid, valid, formData } = this.props;

    const fields = formProperties.form && formProperties.form.fields ? formProperties.form.fields : [];
    let returnUrl = formProperties.form ? formProperties.form.returnUrl : null;
    returnUrl = returnUrl ? returnUrl : '#';
    let title = formProperties.form && formProperties.form.title ? formProperties.form.title.join(' ') : '';
    let customButtons = formProperties.form && formProperties.form.customButtons ? formProperties.form.customButtons : null;
    let buttons = '';
    let disabled = pristine || submitting;
    if(formProperties.form && formProperties.form.submitActive){
        disabled = false;
    }

    var locked = null;
   if(formProperties.form && formProperties.form.locked && formProperties.form.locked === 'locked'){
        disabled = true;
        locked = 'This form is locked.'
    }

    if(customButtons){
     buttons = customButtons.map((button, i) => (
<button onClick={handleSubmit(values =>
          this.props.onSubmit({
            ...values,
            button: button.value
          }))} name={button.name} key={i}  value={button.value} className={button.class}>{button.label}</button>
        ));
    }

    return (
        <div>
<DialogConfirm handleNo={this.handleNo} handleYes={this.handleYes} openConfirm={this.state.openConfirm}/>
            <div className="dashboard">
                <div className="moduleSubMenu backMenu">
                    <div className="col-sm-12">
                        <h3 className="pull-left">
                            <a href={returnUrl}>
                            {!this.props.popup && <i className="ion-ios-arrow-back"></i>}</a> {!formProperties.showLoader && title}
                        </h3>

                        {
                                formProperties.form && formProperties.form.tabs &&
                        <ul className="nav navbar-nav pull-right">
                            {
                                formProperties.form.tabs.map((tab, key) => {
                                return <li className="nav-item" key={key}>
                                <Link className={tab.active ? 'active' : ''} to={tab.link}>
                                { tab.label}
                                </Link>
                            </li>
                            })
                            }
                        </ul>
                        }
                    </div>
                </div>
            </div>
            <div id="reactForm">
                <div className="row m-bot15 cmxform form-horizontal bucket-form ng-pristine ng-valid">
                    <div className="col-lg-12">
                        <section className={formProperties.form && formProperties.form.noBackground ? '' : 'panel'} style={{background: 'white'}}>
                            <header className="panel-heading"
                                    style={{display: formProperties.form && formProperties.form.noBackground ? 'none' : 'block'}}>
                                { formProperties.showLoader ? '...' : formProperties.isEdit ? 'Edit' : 'New'}

                                {formProperties.form && formProperties.form.buttonLinkId &&
                                        <a href={formProperties.form.buttonLinkId.url} className="btn btn-default" style={{float: 'right'}}>
                                {formProperties.form.buttonLinkId.label}
                                </a>}
                                {this.props.auth.user.roles.includes('ROLE_SUPER_ADMIN') && locked && formProperties.form && formProperties.form.buttonUnlock &&
                                  <a href="#" onClick={(route) => this.openConfirm(formProperties.form.buttonUnlock.route)} className="btn btn-default" style={{marginLeft: 15}}>{formProperties.form.buttonUnlock.label }</a>      
                                    }
                            </header>
                             <div className="panel-body">
                              {locked && <div className="text-center" style={{background: '#ccc', height: 50, padding: 15}}>{locked}</div>}
                                {formProperties.form && formProperties.form.breadcrumb && formProperties.form.breadcrumb.fields.length > 0 &&
                                    <div className="status-container">
                                        <div className="status-breadcrumb">
                                            {
                                                formProperties.form.breadcrumb.fields.map((element, key) => {
                                                    return <a
                                                        className={formProperties.form.breadcrumb.target in this.props.formData.data && element.value === this.props.formData[formProperties.form.breadcrumb.target] ? 'active' : ''}
                                                        href="#" key={key}
                                                        onClick={() => this.onBreadcrumbClick(element, formProperties.form.breadcrumb.target)}>
                                        <span className="breadcrumb__inner">
                                            <span className="breadcrumb__desc">{element.label}</span>
                                        </span>
                                                    </a>
                                                })
                                            }
                                        </div>
                                    </div>
                                }

                            <form onSubmit={handleSubmit}>
                             <Grid container direction="row" justify="center" spacing={4}>

                                {fields.map((field, key) => {
                                let component = renderTextField;
                                let data = null;
                                if(field.type === 'hidden'){
                                    component =  'input';
                                }
                                if (field.type === 'text'){
                                    component = renderTextField;
                                }
                                if(field.type === 'select2'){
                                    component = Select2;
                                }
                                if(field.type === 'select'){
                                    component = Select2;
                                }
                                if(field.type === 'selectCreatable'){
                                    component = Select2Creatable;
                                }
                                if(field.type === 'collection'){
                                    component = Contacts;
                                    data = formData.data;
                                }
                                 if(field.type === 'collections'){
                                    component = Collection;
                                    data = formData.data;
                                }
                                if(field.type === 'sections'){
                                    component = Sections;
                                    data = formData.data;
                                }
                                 if(field.type === 'checkboxGroup'){
                                    component = CheckboxGroupField;
                                }
                                if(field.type === 'switch'){
                                    component = Switch;
                                }
                                if(field.type === 'textarea'){
                                    component = Textarea;
                                }
                                if(field.type === 'tmc'){
                                    component = renderTmcField;
                                }
                                if(field.type === 'tags'){
                                    component = IntegrationReactSelect;
                                }
                                if(field.type === 'datePicker'){
                                    component = DatePickerComponent;

                                }
                                if(field.type === 'datetimePicker'){
                                    component = DateTimeComponent;
                                }
                                if(field.type === 'dateYearPicker'){
                                    component = DateYearComponent;
                                }
                                if(field.type === 'radio'){
                                    component = RadioButtonsGroup;
                                }
                                if(field.type === 'password'){
                                    component = renderPasswordField;
                                }
                                let col = 6;
                               if(typeof field.col !== 'undefined'){
                                 col = field.col;
                               }
                               if(field.type === 'file'){
                                     component = DropZoneComponent;
                                     data = formData.data[field.id+'Files'];
                               }

                               if(field.type === 'checkbox'){
                                    component = renderCheckbox;
                                }
                                return <Grid item md={col} xs={11} key={key} className="m-all20">
                                    <Field
                                        data={data}
                                        name={field.id}
                                        field={field}
                                        change={this.changeFieldValue}
                                        component={component}
                                        type={field.type}
                                        hide={field.hide}
                                        placeholder={field.label}
                                        label={field.label}
                                        ismulti={field.ismulti ? field.ismulti : null}
                                        clearable={field.clearable ? field.clearable : null}
                                        options={field.options ? field.options : []}
                                        disabled={field.disabled ? field.disabled : false}
                                        id={field.id}
                                        collectionFields={field.collectionFields ? field.collectionFields : null}
                                        />
                                </Grid>

                                })
                                }

                                {customButtons ?
                                <Grid md={12} lg={12} item className="text-center">
                                  {buttons}
                                  </Grid>
                                :
                               <Grid md={12} lg={12} item className="text-center">

                                <button  onClick={handleSubmit(values =>
                                this.props.onSubmit({
                                  ...values,
                                  button: 'save'
                                }))}
                                type="submit" disabled={disabled } style={{ display: 'inline', margin: 15}} className={"button-submit"} >
                                    SUBMIT
                                </button>
                                <button type="button" className="btn btn-default" disabled={pristine || submitting} onClick={reset} style={{ display: 'inline', margin: 15}} onClick={this.resetForm}>
                                    CLEAR VALUES
                                </button>
                                </Grid>
                                }

                                 </Grid>
                            </form>

                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
    }
}



const mapStateToProps = (state, ownProps) => ({
    settings: state.settings,
    formProperties: state.formProperties && state.formProperties[ownProps.form] ? state.formProperties[ownProps.form] : [],
    formData: state.formData,//state.form && state.form[ownProps.form] ? state.form[ownProps.form].values : (state.formData ? state.formData.data : []),
    initialValues: state.formData ? state.formData.data : [],
     auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
onLoadForm: (name, route) => dispatch(onLoadForm(name, route)),
unlockButton: (route) => dispatch(unlockButton(route)),
//save: (formId, route, data) => dispatch(onSave(formId, route, data)),
showMessage: (text, isError) => dispatch(onShowMessage(text, isError)),
hideMessage: () => dispatch(onHideMessage()),
onLoader: (formId, value) => dispatch({
    type: FORM_SHOW_LOADER,
    payload: {formId, value}
})
});


Form = reduxForm({
    form: 'initializeFromState2', // a unique identifier for this form
    enableReinitialize: true,
    touchOnChange: true,
 //   validate: validate
})(Form);

Form = connect(mapStateToProps, mapDispatchToProps)(Form);

export default Form;
