import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {COMMENTS_WIDGET_LOADED, COMMENTS_WIDGET_UNLOADED, ADD_COMMENT} from '../../../actions/types';
import {loadComments, addComment } from '../../../actions/commentActions';
import CommentsByDate from './CommentsByDate';

import './comments.css';

class Comments extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            text: ''
        };

        this.addComment = this.addComment.bind(this);
        this.handleNewCommentChange = this.handleNewCommentChange.bind(this);
    }

    componentWillMount() {
        this.props.onLoad(this.props.id, this.props.type);
    }


    addComment(e) {
        e.preventDefault();
        this.setState({
            text: ''
        });
        this.props.addComment(this.props.id, this.state.text, this.props.type);
    }

    handleNewCommentChange(e) {
        this.setState({
            text: e.target.value
        });
    }

    render() {
        const {comments } = this.props.comment

        return  <div id="comments">
            <div className="col-md-12">
                <form>
                    <input className="form-control"
                           placeholder={ "Type a message here"} type="text"
                           value={this.state.text}
                           onChange={this.handleNewCommentChange}
                           onKeyPress={event => {
                               if (event.key === 'Enter') {
                                   this.addComment(event)
                               }
                           }}
                    />
                    <button className="form-control post-button" type="button" onClick={this.addComment}>
                    Add comment
                    </button>
                    <div className="clearfix"></div>
                </form>
            </div>
            {typeof comments !== 'undefined' &&
                <span>{(comments.parsed || []).map((comment, i) => {
                    return (
                         <CommentsByDate key={i} date={comment.date} list={comment.list} type={this.props.type}/> 
                    )
                })}</span>
            }
        </div>;
    }
}

const mapStateToProps = state => ({
    comment: state.comment,
});



const mapDispatchToProps = dispatch => ({
    onLoad: (id, type) =>
        dispatch(loadComments(id, type)),
    onUnload: () =>
        dispatch({type: COMMENTS_WIDGET_UNLOADED}),
    addComment: (id, text, type) =>
        dispatch(addComment(id, text, type))
});

export default connect(mapStateToProps, mapDispatchToProps)(Comments);
