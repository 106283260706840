import client from './client';
import { toast } from 'react-toastify'

import {
        COMMENTS_WIDGET_LOADED,
        COMMENTS_WIDGET_UNLOADED,
        ADD_COMMENT,
        EDIT_COMMENT,
        DELETE_COMMENT
        } from './types';

export const loadComments  = (accountId, type) => dispatch => {
    
     client
                .get('/api/'+type+'-comments/' + accountId + '/list')
                .then(res =>
                    dispatch({
                        type: COMMENTS_WIDGET_LOADED, 
                        comments: res.data
                    })
                )
                .catch(err => {
                    dispatch({type: COMMENTS_WIDGET_LOADED, comments: []})

                });
}

export const addComment  = (accountId, text, type) => dispatch => {
            client
                .post('/api/'+type+'-comments/'+accountId+'/create', {comment: text})
                .then(res => {
                   dispatch({
                       type: ADD_COMMENT, 
                       comment: res.data.comment
                    })
                })
                .catch(err => {
                    dispatch({
                        type: ADD_COMMENT,
                        payload: {}
                    })

                });   
}

export const deleteComment = (id, type) => dispatch => {
            client
                .get('/api/'+type+'-comments/' + id + '/delete')
                .then(res =>
                    dispatch({
                        type: DELETE_COMMENT, 
                        id: id
                    })
                )
                .catch(err => {
                    console.log('error')
                });
   
}

export const editComment = ( id, text, type) => dispatch => {
            client
                .post('/api/'+type+'-comments/'+id + '/edit', {comment: text})
                .then(res => {
                   dispatch({
                       type: EDIT_COMMENT, 
                       id: id, 
                       message: text
                    })
                })
                .catch(err => {
                    console.log('error')

                });
   
}