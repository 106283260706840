import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Field, reduxForm} from 'redux-form';
import { change } from 'redux-form';
import validate from './validator';
import {
onLoadForm as onLoadForm, onSave
        } from "../../../actions/formActions";
import FormLoader from "../form/FormLoader";
import {onHideMessage, onShowMessage} from "../../../actions/gridActions";
import {FORM_SHOW_LOADER} from "../../../actions/types";
import IntegrationReactSelect from '../../common/IntegrationReactSelect'
import {renderDatePickerField, renderTextField, renderSelectField, renderCheckbox, renderTmcField, renderPasswordField, radioButton} from '../../common/MaterialUiForm'
import RadioButtonsGroup from '../../common/RadioButtonsGroup'
import Grid from '@material-ui/core/Grid';
import Select2 from './types/Select2';
import Contacts from './types/Contacts';
import Switch from './types/Switch';
import Textarea from './types/Textarea';
import DatePickerComponent from './types/DatePickerComponent'
import DateTimeComponent from './types/DateTimeComponent'
import { toast } from 'react-toastify'
import "./redux-forms.css";
import DropZoneComponent from './file/DropZoneComponent'
import CheckboxGroupField from '../../common/CheckboxGroupField'
import Diff from 'react-stylable-diff'


class ContributionForm extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
            submitting: false,
            };

            this.updateForm = this.updateForm.bind(this);
            this.changeFieldValue = this.changeFieldValue.bind(this);
        }

        changeFieldValue(id, value) {
            this.props.change(id, value);
        }

        componentDidMount() {
            this.updateForm();
        }

   componentWillReceiveProps(nextProps) {

       if(this.props.formProperties && typeof this.props.formProperties.form !== 'undefined'){

       }
    }

        componentDidUpdate(prevProps) {
            if (this.props.match && prevProps.match.params.id !== this.props.match.params.id) {
                this.updateForm();
            }
        }

        updateForm() {
            this.props.onLoader(this.props.form, true);
            if (this.props.title) {
                    let titleArr = this.props.title;
                    titleArr.map((item, key) => titleArr[key] = item);
                    document.title = titleArr.join(' ');
            }

            this.props.onLoadForm(this.props.form, this.props.formRoute);
        }


    resetForm = () => this.props.reset();

render() {
    let {handleSubmit, formProperties, pristine, reset, submitting, invalid, valid, formData, touch } = this.props;

let contribution = formData.data
    const fields = formProperties.form && formProperties.form.fields ? formProperties.form.fields : [];
    let returnUrl = formProperties.form ? formProperties.form.returnUrl : null;
    returnUrl = returnUrl ? returnUrl : '#';
    let title = formProperties.form && formProperties.form.title ? formProperties.form.title.join(' ') : '';
    let customButtons = formProperties.form && formProperties.form.customButtons ? formProperties.form.customButtons : null;
    let buttons = '';
    if(customButtons){
     buttons = customButtons.map((button, i) => (
<button onClick={handleSubmit(values =>
          this.props.onSubmit({
            ...values,
            button: button.value
          }))} name={button.name} key={i}  value={button.value} className={button.class}>{button.label}</button>
        ));
    }
    var tmcFields = ["description", "outcomes", "lessons", "agenda"];
    return (
        <div>
            <div className="dashboard">
                <div className="moduleSubMenu backMenu">
                    <div className="col-sm-12">
                        <h3 className="pull-left">
                            <Link to={returnUrl}>
                            {!this.props.popup && <i className="ion-ios-arrow-back"></i>}</Link> {!formProperties.showLoader && title}
                        </h3>

                        {
                                formProperties.form && formProperties.form.tabs &&
                        <ul className="nav navbar-nav pull-right">
                            {
                                formProperties.form.tabs.map((tab, key) => {
                                return <li className="nav-item" key={key}>
                                <Link className={tab.active ? 'active' : ''} to={tab.link}>
                                { tab.label}
                                </Link>
                            </li>
                            })
                            }
                        </ul>
                        }
                    </div>
                </div>
            </div>
            <div id="reactForm">
                <div className="row m-bot15 cmxform form-horizontal bucket-form ng-pristine ng-valid">
                    <div className="col-lg-12">
                        <section className={formProperties.form && formProperties.form.noBackground ? '' : 'panel'} style={{background: 'white'}}>
                            <header className="panel-heading"
                                    style={{display: formProperties.form && formProperties.form.noBackground ? 'none' : 'block'}}>
                                { formProperties.showLoader ? '...' : formProperties.isEdit ? 'Edit' : 'New'}
                            </header>
                             <div className="panel-body">
                                {formProperties.form && formProperties.form.breadcrumb && formProperties.form.breadcrumb.fields.length > 0 &&
                                    <div className="status-container">
                                        <div className="status-breadcrumb">
                                            {
                                                formProperties.form.breadcrumb.fields.map((element, key) => {
                                                    return <a
                                                        className={formProperties.form.breadcrumb.target in this.props.formData.data && element.value === this.props.formData[formProperties.form.breadcrumb.target] ? 'active' : ''}
                                                        href="#" key={key}
                                                        onClick={() => this.onBreadcrumbClick(element, formProperties.form.breadcrumb.target)}>
                                        <span className="breadcrumb__inner">
                                            <span className="breadcrumb__desc">{element.label}</span>
                                        </span>
                                                    </a>
                                                })
                                            }
                                        </div>
                                    </div>
                                }

                            <form onSubmit={handleSubmit}>
                             <Grid container direction="row" spacing={4} justify="center">
                                {fields.map((field, key) => {

                                let component = renderTextField;
                                let data = null;
                                if(field.type === 'hidden'){
                                    component =  'input';
                                }
                                if (field.type === 'text'){
                                    component = renderTextField;
                                }
                                if(field.type === 'select2'){
                                    component = Select2;
                                }
                                if(field.type === 'select'){
                                    component = Select2;
                                }
                                if(field.type === 'collection'){
                                    component = Contacts;
                                    data = formData.data;
                                }
                                 if(field.type === 'checkboxGroup'){
                                    component = CheckboxGroupField;
                                }
                                if(field.type === 'switch'){
                                    component = Switch;
                                }
                                if(field.type === 'textarea'){
                                    component = Textarea;
                                }
                                if(field.type === 'tmc'){
                                    component = renderTmcField;
                                }
                                if(field.type === 'tags'){
                                    component = IntegrationReactSelect;
                                }
                                if(field.type === 'datePicker'){
                                    component = DatePickerComponent;
                                }
                                if(field.type === 'datetimePicker'){
                                    component = DateTimeComponent;
                                }
                                if(field.type === 'radio'){
                                    component = RadioButtonsGroup;
                                }
                                if(field.type === 'password'){
                                    component = renderPasswordField;
                                }
                                let col = 6;
                               if(typeof field.col !== 'undefined'){
                                 col = field.col;
                               }
                               if(field.type === 'file'){
                                     component = DropZoneComponent;
                                     data = formData.data[field.id+'Files'];
                               }

                               if(field.type === 'checkbox'){
                                    component = renderCheckbox;
                                }
                                return <Grid item md={col} xs={10} key={key}  className="m-all20">
                                    <Field
                                        data={data}
                                        name={field.id}
                                        field={field}
                                        label={field.label}
                                        change={this.changeFieldValue}
                                        component={component}
                                        type={field.type}
                                        hide={field.hide}
                                        placeholder={field.label}
                                        label={field.label}
                                        ismulti={field.ismulti ? field.ismulti : null}
                                        clearable={field.clearable ? field.clearable : null}
                                        options={field.options ? field.options : []}
                                        disabled={field.disabled ? field.disabled : false}
                                        touch={touch}
                                        />

                                         {contribution.reflection && typeof field.textOption !== 'undefined' && contribution[field.textOption] !== contribution.reflection[field.textOption] &&
                                                 <span>
                                         {tmcFields.includes(field.id) ?
                                         <span>
        <p className="description text-left" style={{backgroundColor: '#c9eed3'}}  dangerouslySetInnerHTML={{__html:contribution.reflection[field.textOption] }}></p>
        <p className="description text-left" style={{backgroundColor: '#cccc00'}} dangerouslySetInnerHTML={{__html: contribution[field.textOption]}}></p>
        </span>
                                         :
                                        <Diff type="sentences" inputA={contribution.reflection[field.textOption]} inputB={contribution[field.textOption]}/>
                                       }
                                        </span>
                                    }
                                </Grid>

                                })
                                }

                                {customButtons ?
                                <Grid md={12} lg={12} xs={10} item className="text-center customButtons">
                                  {buttons}
                                  </Grid>
                                :
                               <Grid md={12} lg={12} item className="text-center">
                                <button  onClick={handleSubmit(values =>
                                this.props.onSubmit({
                                  ...values,
                                  button: 'save'
                                }))}
                                type="submit" disabled={pristine || submitting} style={{ display: 'inline', margin: 15}} className={"button-submit"} >
                                    Submit
                                </button>
                                <button type="button" className="btn btn-default" disabled={pristine || submitting} onClick={reset} style={{ display: 'inline', margin: 15}} onClick={this.resetForm}>
                                    Clear Values
                                </button>
                                </Grid>
                                }

                                 </Grid>
                            </form>

                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
    }
}



const mapStateToProps = (state, ownProps) => ({
    settings: state.settings,
    formProperties: state.formProperties && state.formProperties[ownProps.form] ? state.formProperties[ownProps.form] : [],
    formData: state.formData,//state.form && state.form[ownProps.form] ? state.form[ownProps.form].values : [],
    initialValues: state.formData ? state.formData.data : [],
});

const mapDispatchToProps = dispatch => ({
onLoadForm: (name, route) => dispatch(onLoadForm(name, route)),
//save: (formId, route, data) => dispatch(onSave(formId, route, data)),
showMessage: (text, isError) => dispatch(onShowMessage(text, isError)),
hideMessage: () => dispatch(onHideMessage()),
onLoader: (formId, value) => dispatch({
    type: FORM_SHOW_LOADER,
    payload: {formId, value}
})
});


ContributionForm = reduxForm({
    form: 'initializeFromState2', // a unique identifier for this form
    enableReinitialize: true,
    touchOnChange: true,
    touchOnBlur: true,
    //validate: validate,
    destroyOnUnmount: false,
    enableReinitialize: true
})(ContributionForm);

ContributionForm = connect(mapStateToProps, mapDispatchToProps)(ContributionForm);

export default ContributionForm;
