import {
    GET_ORG_TYPES
} from '../actions/types';

const initialState = {
    orgTypes: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ORG_TYPES:
            return {
                ...state,
                orgTypes: action.payload
            };

        default:
            return state;
}
}
