import React, { PureComponent } from 'react';
import {
  ComposedChart, Line, Area, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
  Legend, Cell
} from 'recharts';

const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#E9967A', '#FF69B4', '#4169E1', '#FFFF00', '#FF6347', '#663399'];

const getPercent = (value, total) => {
	const ratio = total > 0 ? value / total : 0;
  
  return toPercent(ratio, 2);
};

const toPercent = (decimal, fixed = 0) => {
	return decimal+"%";
};
const renderTooltipContent = (o) => {
	const { payload, label } = o;
  const total = payload ? payload.reduce((result, entry) => (result + entry.value), 0) : 0;
  
  return (
  	<div className="customized-tooltip-content">
    	<p className="total"></p>
     {payload && <ul className="list">
      	{
        	payload.map((entry, index) => (
          	<li key={`item-${index}`} style={{color: (entry.color==='#413ea0' ? '#141300' : entry.color)}}>{console.log(entry.color)}
            	{`${entry.name}: ${entry.value+'%'}`}
            </li>
          ))
        }
      </ul>}
    </div>
  );
};

export default class VerticalChartColor extends React.Component {
 constructor() {
    super();
  }
  render() {
      
      var ticks = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    return (
            <ResponsiveContainer width={'100%'} height={700}>
      <ComposedChart
        layout="vertical"
        width={'100%'}
        height={400}
        data={this.props.data}
        margin={{
          top: 20, right: 40, bottom: 20, left: 150,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis  type="number" ticks={ticks} tickCount={11} tickFormatter={toPercent}/>
        <YAxis angle={-45} textAnchor="end" dataKey="name" type="category"  tick={{ fontSize: 14, width: 300 }}/>
       <Tooltip content={renderTooltipContent}/>
        <Legend />        
        
          <Bar dataKey={this.props.dataKey} fill="#8884d8"  >
         {
            this.props.data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % 20]}/>
            ))
          }
       </Bar>       
      </ComposedChart>
      </ResponsiveContainer>        
    );
  }
}