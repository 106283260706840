import moment from 'moment'
import {
ADD_NOTIFICATION,
        ADD_NOTIFICATION_TO_LIST,
        GET_CURRENT_NOTIFICATIONS,
        COUNT_UNREAD,
        GET_ADMINS,
        CURRENT_RECEIVER
        } from '../actions/types';

const initialState = {
    currentNotifications: [],
    countNotifications: 0,
    admins: [],
    currentReceiver: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CURRENT_NOTIFICATIONS:
            return {
                ...state,
                currentNotifications: action.payload
            };
        case COUNT_UNREAD:
            return {
                ...state,
                countNotifications: action.payload
            }
        case GET_ADMINS:
            return {
                ...state,
                admins: action.payload
            }
        case CURRENT_RECEIVER:
            return {
                ...state,
                currentReceiver: action.payload
            }
        case ADD_NOTIFICATION:
            let newNotification = null;
            let newData = true;
            if (typeof action.payload === 'string') {
                newNotification = JSON.parse(action.payload)
            } else {
                newNotification = action.payload;
            }
            let currents = state.currentNotifications;
            let  currentsNew = []; 
            let currentRead = [];
            let currentUnread = [];
            let unread = 0;
            var dateTime = new Date();
            dateTime = moment(dateTime).format("YYYY-MM-DD");
            let notDate = moment(newNotification.date).format("YYYY-MM-DD");
            
            //if notification is for this user and is for now
          if(newNotification.receiversArray.includes(state.currentReceiver) && notDate <= dateTime ){
              //if current notifications lenght is more than null
               if (currents.length > 0) {
                   currents.map((value) => {
                        //if notification exist in array - it is edited
                        if (value.id == newNotification.id) {
                            //if is unread
                            if(newNotification.status == 2){
                              currentUnread.push(newNotification)
                            }else{
                                //if is read
                               currentRead.push(newNotification)
                            }
                            newData = false;
                          //  return newNotification;
                        } else {
                            //if notification is not in notifications
                            //if is unread
                            if(value.status == 2){
                              currentUnread.push(value)
                            }else{
                                //if is read
                                currentRead.push(value)
                            }
                        }
                    })
                    //if notification is new
                    if (newData) {
                        if(newNotification.status == 2){
                            //if is unread
                            currentUnread = [newNotification, ...currentUnread]
                           // currentUnread.push(newNotification)
                        }else{
                            //if is read
                            currentRead.push(newNotification)
                        }
                    }
                } else {
                    //notifications array is empty and add notification                 
                    if(newNotification.status == 2){
                        currentUnread.push(newNotification)
                    }else{
                        currentRead.push(newNotification)
                    }
                }
                
                currentsNew = currentUnread.concat(currentRead)
                unread = currentUnread.length;
          }else{
              //notification is not for this user and date and notifications are not changed
              //check if is removed from this user or this date              
                  state.currentNotifications.map(notif => {
                      if(notif.id === newNotification.id){
                            if(newNotification.receiversArray.includes(state.currentReceiver) && moment(newNotification.date).format("YYYY-MM-DD") <= dateTime){
                          if(newNotification.status == 2){
                        currentUnread.push(newNotification)
                    }else{
                        currentRead.push(newNotification)
                    }
                      }
                      }else{
                      if(notif.receiversArray.includes(state.currentReceiver) && moment(notif.date).format("YYYY-MM-DD") <= dateTime){
                          if(notif.status == 2){
                        currentUnread.push(notif)
                    }else{
                        currentRead.push(notif)
                    }
                      }
                  }
                  })
                 
                currentsNew = currentUnread.concat(currentRead)
                unread = currentUnread.length;
                
          }
         
            return {
                ...state,
                currentNotifications: currentsNew,
                countNotifications: unread
            };
        default:
            return state;
}
}
