import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import DuplicatedForm from './DuplicatedForm'
import MergeForm from './MergeForm';
import Layout from '../../../components/admin/layout/Layout'
import { getDuplicatedAccountsResolve, resolveDuplicatedAccounts, postGetDuplicateForm } from "../../../actions/accountActions";
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

class ResolveDuplicated extends Component {
  constructor() {
    super();
    this.state = {
      previosForm: true,
      formFields: null,
      organizationMain: null,
      organizationMerge: null,
      organizationMainName: null,
    };
  }

     componentDidMount() {
       const queryString = window.location.search;
       const urlParams = new URLSearchParams(queryString);
       const ids = urlParams.get('ids')
       this.props.getDuplicatedAccountsResolve(this.props.match.params.type, ids)
    }
    
      submitChooseOrganization = (values) => {
        this.setState({
             previosForm: false,
             organizationMain: values.organizationMain,
             organizationMerge: values.organizationMerge
        });
        this.props.postGetDuplicateForm(values, this.props.match.params.type)     
    }

    submit = (values, props) => {   
          let data = [];
          let formData = Object.assign({}, values);            
              Object.keys(values).map(k => {
             if(values[k].length >= 1){
                let array = []
                 values[k].map(row => {
                     array.push(row.value)
                 })
                 formData[k] = array;              
                 array = []
             }else{
                 formData[k] = values[k]['value'];
             }
              })
              formData.organizationMain = this.state.organizationMain;
              formData.organizationMerge = this.state.organizationMerge;
            
          this.props.resolveDuplicatedAccounts(formData, this.props.match.params.type, '/admin/accounts/list/2')
      }

  render() {
      const { classes } = this.props;
    return (    
             <Layout>
      <Grid container spacing={0} >
       <Grid item md={10} lg={10} xs={10} >
      <Paper style={{width: '100%', margin: 30, padding: 30}}>
      <h3>Resolve Duplicated</h3>
      {this.props.account.duplicatedAccounts && this.props.account.duplicatedAccounts.result && this.state.previosForm &&
                  <DuplicatedForm onSubmit={this.submitChooseOrganization}
                  duplicatedOrganizations={this.props.account.duplicatedAccounts.result}
                  duplicatedItem={''}
                  type={this.props.match.params.type}/>
                  }
                  {!this.state.previosForm && this.props.account.duplicatedForm && this.props.account.duplicatedForm.form &&
                           <MergeForm 
                            onSubmit={this.submit} 
                            formFields={this.props.account.duplicatedForm.form}
                            form={'initializeFromState2'}
                            />}
         </Paper> 
          </Grid>
      </Grid>
       </Layout>
    );
  }
}
ResolveDuplicated.propTypes = {
  getDuplicatedAccountsResolve: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  postGetDuplicateForm: PropTypes.func.isRequired,
  resolveDuplicatedAccounts: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  account: state.account,
});

ResolveDuplicated = withStyles(styles)(ResolveDuplicated)

export default connect( mapStateToProps, { getDuplicatedAccountsResolve, postGetDuplicateForm, resolveDuplicatedAccounts })(withRouter(ResolveDuplicated));
