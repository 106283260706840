import React, { Component, useEffect } from 'react';
import ReactDom from 'react-dom';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Navbar from '../layout/Navbar';
import Footer from '../layout/Footer'
import { withStyles } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import { getLogActivityWithGeoIp } from '../../actions/profileActions';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  titlePage: {
    paddingTop: 50,
    textAlign: 'center',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tab: {
    background: '#eeeeee',
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
    boxShadow: '2px 2px 0 rgba(0,0,0,0.1)',
    padding: 15,
    borderRadius: 3,
    marginTop: 30,
  },
  td: {
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: 8,
    },
  title: {
    display: 'block',
    fontSize: 30,
    fontWeight: 300,
    lineheight: 36,
    margin: '30px 0',
    color: '#1cbbb4',
  },
  icon: {
    color: '#1cbbb4'
  }
}));

function SHiPPSummary(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  
  useEffect(() => {
   props.getLogActivityWithGeoIp('SHiPP Summary page', 'GET', "Website usage")
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Navbar/>
        <div className="page-content page-animation slideup">
         <div className="max-width">
          <h1 className={classes.titlePage}>Sustainable Health in Procurement Project </h1>
          <div className={classes.tabsPage, "tabsPage"}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
              >
                <Tab className={classes.tab} label="1. Project information and objectives" {...a11yProps(0)} />
                <Tab className={classes.tab} label="2. 2018-2019 Results" {...a11yProps(1)} />
                <Tab className={classes.tab} label="3. Project outcomes" {...a11yProps(2)} />
                <Tab className={classes.tab} label="4. A vision for the future" {...a11yProps(3)} />
                <Tab className={classes.tab} label="5. Publications" {...a11yProps(4)} />
                <Tab className={classes.tab} label="6. Workplans and Reports" {...a11yProps(5)} />
                <Tab className={classes.tab} label="7. Success stories" {...a11yProps(6)} />
                <Tab className={classes.tab} label="8. Photos from events and projects" {...a11yProps(7)} />
                <Tab className={classes.tab} label="9. Contact Details" {...a11yProps(8)} />
              </Tabs>
              <TabPanel value={value} index={0}>
                <Grid container spacing={8} className="container">
                  <Grid item md={12} xs={12}>
                  <h2><strong>1. Project information and objectives</strong></h2>
                    <table className={classes.table}>
                      <tr style={{background: "#f3f6f8"}}>
                        <td className={classes.td}><strong>Project Location</strong></td>
                        <td className={classes.td}>Global and in 10 selected project countries (EECA: Moldova and Ukraine, Africa: South Africa, Tanzania and Zambia, Asia: China, India, Vietnam, Latin America: Argentina, Brazil) with scale-up in LAC and SE Asia</td>
                      </tr>
                      <tr>
                        <td className={classes.td}><strong>Project Duration</strong></td>
                        <td className={classes.td}>Four years, 2018-2021</td>
                      </tr>
                      <tr style={{background: "#f3f6f8"}}>
                        <td className={classes.td}><strong>Development Objective</strong></td>
                        <td className={classes.td}>Promote sustainability in the health sector supply chain to improve human health and reduce greenhouse gases, resource depletion, and chemical pollution in developing countries</td>
                      </tr>
                      <tr>
                        <td className={classes.td}><strong>Programme Objective</strong></td>
                        <td className={classes.td}>Strengthen sustainable procurement in the UN system and in strategic countries to leverage purchasing power and drive policy and market demand for sustainable manufacturing and waste management in the health sector</td>
                      </tr>
                    </table>
                   <div style={{marginTop:20}}>
                      <Grid container spacing={8}>
                        <Grid item md={12} xs={12}>
                           <h2 className={classes.title}>What is Sustainability in the health sector?</h2>
                           <p>Sustainability is the capability to equitably meet essential human needs of the present without compromising the ability of future generations to meet their own needs by preserving and protecting the ecosystems and natural resources.</p>
                         </Grid>
                         <Grid item md={7} xs={12}>
                            <img className="img-responsive" src="https://api.savinglivesustainably.org/images/873x775/ee880d.png" alt="What is Sustainability" />
                          </Grid>
                       </Grid>
                    </div>
                    <div>
                      <h2 className={classes.title}>Why sustainability </h2>
                      <div>
                        <Icon className={classNames(classes.icon, 'fa fa-quote-left', "text-light-gray")} />
                        <p>“Climate Change is the greatest health threat of the 21st Century”</p>
                        <span>Lancet Commission</span>
                      </div>
                        <hr style={{width: 50}} />
                      <div>
                        <Icon className={classNames(classes.icon, 'fa fa-quote-left', "text-light-gray")} />
                        <p>“Climate change is the greatest health opportunity of the 21th century”</p>
                        <span>Dr. Margaret Chan, Former Director General, WHO</span>
                      </div>
                        <hr style={{width: 50}} />
                      <p>Roughly a quarter (1/4) of all human diseases and death on the planet can be attributed to what the WHO broadly defines as environmental factors.  These include unsafe drinking water, poor sanitation and hygiene, indoor and outdoor air pollution, workplace hazards, industrial accidents, automobile accidents, climate change, poor land use practices and natural resource management. For children, the rate of environmentally caused deaths is as high as 36 percent.  Environmental health factors play a significantly larger role in developing countries, where poor water quality and the lack of sanitation, along with indoor and outdoor air pollution make major contributions to mortality.</p>
                      <p>The Health sector should lead by example to protect People and the Planet </p>
                    </div>
                  </Grid>
                  </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid container spacing={8} className="container">
                  <Grid item md={12} xs={12}>
                    <h2><strong>2. 2018-2019 Results</strong></h2>
                      <div>
                        <h2 className={classes.title}>A. Global Manufacturers Forums</h2>
                        <div>
                          <p><Link to="/asia-forum">1st Global manufacturers</Link> forum successfully held in manila Philippines under the theme: Sustainable Production in the Health Sector: Fostering social, economic and environmental benefit. The event was co-organized with the Asia Development Bank </p>
                          <p><Link to="/africa-forum">2nd Saving Lives Sustainably:</Link> 2019 Global Forum in Africa was held in Dar es Salaam, Tanzania under the theme, Promote Investments in Sustainable Manufacturing, Supply, Procurement and Safe Disposal of Health Commodities.</p>
                          <p>The third Manufacturers forum is being planned. </p>
                        </div>
                      </div>
                    </Grid>
                  <Grid item md={12} xs={12}>
                    <h2 className={classes.title}>B. Policies and Strategies for sustainable procurement </h2>
                    <div>
                      <p><strong>Argentina</strong> has developed an annex to the National Manual for Sustainable Procurement (2017) focusing on sustainable purchasing in the health sector. Through this annex, UNDP has demonstrated that sustainability goes beyond the environmental dimensions and includes gender and human rights. </p>
                      <p><strong>Vietnam</strong>: Prime Minister’s Directive on reducing plastic waste in the health sector.</p>
                      <p><strong>Ukrainian</strong> hospitals Assessment of health care waste management </p>
                      <p><strong>Ukraine</strong>: The sustainability of health care procurement: sustainability questionnaire of UNDP suppliers and manufacturers of health care products</p>
                      <p><strong>China</strong>: Developed the sustainable procurement standard for health care institutions as tool to guide in sustainable procurement and for health sector reforms.</p>
                      <p><strong>India</strong>: Green hospital standard developed and Public health standard guidelines for sustainable procurement – includes climate resilience and sustainability language. </p>
                      <p><strong>India</strong>: National green accreditation standards</p>
                      <p><strong>India</strong>: established guidelines and criteria for Bio-Medical Waste and Infection Control Management.</p>
                      <p><strong>HCWH Europe</strong>  policy brief Setting Sustainability Criteria in Healthcare Tenders to explain the EU public procurement strategy</p>
                      <p><strong>Brazil</strong>: National Plan for Minamata Convention Implementation in Health Care Sector. The plan represents the national policy for mercury medical devices banishment and medical mercury waste management</p>
                      <p><strong>Colombia</strong>: Ordinance N. 0108/2019, a strategy of sustainable public procurement adopted.</p>
                      <p><strong>South Africa</strong>: incorporated sustainability dimensions into the existing Integrated Pest Management (IPM) policy</p>
                      <p><strong>South Africa Bongani Hospital</strong>: 2019-2020 acquisition plan for biogases and air quality monitoring approved</p>
                      <p><strong>Vietnam</strong>: Public- private sector engagement platform on sustainability in the health sector</p>
                      <p><strong>Moldova</strong>: National Baseline Assessment to secure a more sustainable procurement practices in healthcare sector</p>
                    </div>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <h2 className={classes.title}>C. Investment in energy efficient products and technologies.</h2>
                    <div>
                      <p><strong>Tanzania</strong>: assessment of energy efficiency and waster saving devices in the health facilities</p>
                      <p><strong>Moldova</strong>: Introducing renewable energy sources for the hospital system </p>
                      <p><strong>Latin American</strong>, Tracking Tool: the SHiPP developed a sustainable procurement tracking tool to Measure GHG emissions.</p>
                      <p><strong>Brazil</strong>: data system with information about sustainable practices</p>
                      <p><strong>Brazil</strong>: “Friend to the Environment” Award is granted annually by the São Paulo State Department of Health to the health organizations.</p>
                      <p><strong>Market research</strong>: identify potential alternatives, a list of biocidal active ingredients used worldwide was created</p>
                      <p><strong>India</strong>: compact hazard analysis of the disinfectants for substitution </p>
                      <p><strong>South Africa</strong>: reduced PVC procurement and toxic disinfectants from the health sector</p>
                      <p><strong>India</strong>: Manufacturers invested in non- PVC based medical products such blood packs</p>
                      <p><strong>South Africa</strong>: removal of hazardous materials such as PVC recovered for recycling is transforming markets.</p>
                      <p><strong>Global</strong>: The Chemicals of Concern to Health and Environment document published, translated in 3 languages (Spanish, Portuguese and Russian)  </p>
                      <p><strong>South Africa</strong>: Baseline assessment of mercury devices for the replacement with alternatives</p>
                      <p><strong>Zambia</strong>: Leadership for development and sustainable procurement in the health sector coordination mechanism.</p>
                    </div>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <h2 className={classes.title}>D. Leveraging existing partnerships </h2>
                    <div>
                        <p><strong>SADC</strong>: Guidelines for operationalization of the pooled procurement services of SADC’s 16 countries.</p>

                        <p>UNDP Global Fund Health Implementation support Solar for Health (S4H) and Health Procurement Quality Assurance Policy implementation capacity development conducted in Argentina, Ukraine, Moldova, Vietnam  leading to joint policy and strategy quick wins. </p>

                        <p>HCWH has produced a set of health sector sustainability case studies from Europe and the United States that shared best practices and lessons learned, which are being replicated by SHiPP countries. For example, Moldova and Ukraine have produced case studies on ethical procurement highlighted in the Global Network on Anti- corruption and Transparency in the health sector first report 2019.</p>

                        <p>HCWH- ARUP: Health cares’ climate footprint methodology: how the health sector contributes to the global climate crisis and opportunities for action.</p>

                        <p><strong>Zambia and Tanzania</strong>:  UNDP, WHO, HCWH GEF project Reducing UPOPs and Mercury Releases from the Health Sector Health care waste technical guidelines and affordable non incineration technologies developed.</p>

                        <p><strong>Nature, Climate, and Energy</strong>: joint planning and conceptualization, planning, programming and reporting of results </p>
                    </div>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Grid container className="container">
                  <Grid item md={12} xs={12}>
                    <h2><strong>3. Project outcomes</strong></h2>
                      <Grid item md={4} xs={12}>
                         <img className="img-responsive" src="https://api.savinglivesustainably.org/images/269x353/3b957d.png" alt="Project outcomes" />
                       </Grid>
                    <Grid item md={7} xs={12}>
                        <div>
                          <p><strong>Protect health from climate change</strong> by reducing the greenhouse gas emissions from the health supply chain contributing to the health sector portion of NDCs.</p>
                          <p><strong>Reduce the toxicity of health</strong> products throughout their life cycle.</p>
                          <p><strong>Manage resources</strong> to <strong>reduce waste</strong>, implement the circular economy and prevent pollution.</p>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Grid container className="container">
                  <Grid item md={12} xs={12}>
                    <h2><strong>4. A vision for the future</strong></h2>
                      <Grid item md={6} xs={12}>
                         <img className="img-responsive" src="https://api.savinglivesustainably.org/images/960x578/4a9297.png" alt="Vision for the future" />
                       </Grid>
                    <Grid item md={12} xs={12}>
                        <div>
                          <p>By harnessing its tremendous purchasing power in many countries, the health sector can impact the supply chain, <strong>compelling manufacturers to provide safer, more environmentally sustainable products</strong>, produced under <strong>ethical and healthy working conditions</strong>. The <strong>health systems can also manage health care waste disposal in an environmentally sustainable</strong> way by considering the <strong>whole product life cycle</strong>.  Ultimately, the health sector can help shift the markets so that the health commodities—as well as thousands of related products—are more widely available, not only to hospitals, but to all consumers, helping <strong>achieve the Sustainable Development Goals by promoting greater health, environmental and social sustainability</strong>.</p>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
              </TabPanel>
              <TabPanel value={value} index={4}>
                <h2><strong>5. Publications</strong></h2>
              </TabPanel>
              <TabPanel value={value} index={5}>
                <h2><strong>6. Workplans and Reports</strong></h2>
              </TabPanel>
              <TabPanel value={value} index={6}>
                <h2><strong>7. Success stories</strong></h2>
              </TabPanel>
              <TabPanel value={value} index={7}>
                <h2><strong>8. Photos from events and projects</strong></h2>
              </TabPanel>
              <TabPanel value={value} index={8}>
                <h2><strong>9. Contact Details</strong></h2>
                <div>
                  <p>Dr Rosemary Kumwenda, Regional HIV/Health Team leader, SPHS Coordinator  <a href="mailto:rosemary.kumwenda@undp.org">rosemary.kumwenda@undp.org</a></p>
                  <p>Ian Milimo, Project Manager, <a href="mailto:ian.milimo@undp.org">ian.milimo@undp.org</a></p>
                  <p>Nevra Gomdeniz, Communication Specialist <a href="mailto:nevra.gomdeniz@undp.org">nevra.gomdeniz@undp.org</a></p>
                </div>
              </TabPanel>
            </div>
         </div>
      <Footer/>
  </div>
</div>

  );
}
SHiPPSummary.propTypes = {
    getLogActivityWithGeoIp: PropTypes.func.isRequired
};

const mapStateToProps = state => ({});

SHiPPSummary = withStyles(styles)(SHiPPSummary)

export default connect(mapStateToProps, {getLogActivityWithGeoIp})(SHiPPSummary);
