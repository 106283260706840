import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {exportPdfDataButton} from '../../../actions/gridActions';


const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

class ComponentToPrint extends Component {   
    
    render() {     
    return (           
      <Grid container spacing={0} >    
          {this.props.pdfData &&
   <div className="col-lg-12 col-md-12 col-xs-12 col-centered printDatasource" dangerouslySetInnerHTML={{ __html: this.props.pdfData }} />}
      </Grid>
    );
  }
}

export default ComponentToPrint;
