import {
    GET_SECTOR,
    GET_SECTORS,
    SECTOR_LOADING,
    GET_SECTORS_FILTER,
    GET_MAP_FILTER
} from '../actions/types';

const initialState = {
    sector: null,
    sectors: null,
    sectorsFilter: null,
    mapFilter: null,
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SECTOR_LOADING:
            return {
                ...state,
                loading: true
            };
        case GET_SECTOR:
            return {
                ...state,
                sector: action.payload,
                loading: false
            };

        case GET_SECTORS:
            return {
                ...state,
                sectors: action.payload,
                loading: false
            };
        case GET_SECTORS_FILTER:
           return {
                ...state,
                sectorsFilter: action.payload,
                loading: false
            };
        case GET_MAP_FILTER:
             return {
                ...state,
                mapFilter: action.payload
            };
        default:
            return state;
}
}
