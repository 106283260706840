import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

class Sectors extends Component {
    render() {

        return (
                <Grid md={12} sm={6} xs={12} item className="focus-areas" style={{textAlign:"left"}}>

                        <h4 id="contributionInformationTitle" className="text-uppercase text-gray">
                            Focus Areas
                        </h4>
                        {this.props.sectors.map(sector => (
                            <span className={sector.name.toLowerCase()}  key={sector.id} style={{float: "none", display:"inline-block"}}>
                                <a className="icon text-bluegreen" href={`/knowledge-and-practice/${sector.name.toLowerCase()}/${sector.id}`}>
                                    <img src={sector.icon}/>
                                    <span className="popup">{sector.name}
                                        <i className="ion-android-arrow-dropdown"></i>
                                    </span>
                                </a>
                            </span>
                        ))}


                 </Grid>

                );
    }
}

export default Sectors;
