import React from 'react';
import {Link} from 'react-router-dom';

export default () => {
  return (
    <div className="text-center">
      <h1 className="display-4">Page Not Found</h1>
      <p>Sorry, this page does not exist</p>
      <Link to="/">Try this</Link>
    </div>
  );
};
