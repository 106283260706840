import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';

import $scriptjs from 'scriptjs';

class SocialNetworks extends Component {
    handleYammer = () => {


    }

      componentDidMount() {
         $scriptjs('https://c64.assets-yammer.com/assets/platform_social_buttons.min.js', function() {
            var options = {
                                    customButton: true,
                                    classSelector: 'yammer',
                                    defaultMessage: 'Share this'

                };
window.yam.platform.yammerShare();
});
    }


    render() {
const { classes } = this.props;
        return (
                <Grid container className="project-social-networks">
                    <h4 id="contributionInformationTitle" className="text-uppercase text-gray">
                            Social media
                        </h4>
                      <Grid container className="noPadding">
                            <Grid item sm={2} xs={4}>
                              <TwitterShareButton url={this.props.url} className={"social-icon social, twitter"} >
                              <Icon className={'fab fa-twitter'}
                              />
                              </TwitterShareButton>
                            </Grid>
                            <Grid item sm={2} xs={4}>

                              <FacebookShareButton
                                  url={this.props.url}
                                  imageURL={this.props.imageURL}
                                  quote={this.props.title}
                                  className={"social-icon social, facebook"}>
                              <Icon className={'fab fa-facebook'}
                              />
                              </FacebookShareButton>
                            </Grid>
                            <Grid item sm={2} xs={4}>
                              <EmailShareButton url={this.props.url}
                                  className={"social-icon social, email"}
                                  subject={this.props.title}
                                  body={'Hey, I found an interesting story I think you should know about. '}
                                  openWindow={true}
                                  >
                                  <Icon className={'far fa-envelope'}
                                  />
                              </EmailShareButton>
                            </Grid>
                            <Grid item sm={2} xs={4}>
                              <LinkedinShareButton
                                url={this.props.url}
                                className={"social-icon social, linkedin"}>
                              <Icon className={'fab fa-linkedin'}
                              />
                              </LinkedinShareButton>
                            </Grid>
                            <Grid item sm={2} xs={4}>
                                <a  className="yammer social-icon" id="yj-share-button" href="#" onClick={this.handleYammer}></a>
                            </Grid>
                    </Grid>
                </Grid>

                );
    }
}

export default SocialNetworks;
