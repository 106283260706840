import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Navbar from '../layout/Navbar';
import Grid from '@material-ui/core/Grid';
import Footer from '../layout/Footer'
import { withStyles } from '@material-ui/core/styles'
import Filters from '../filter/Filters'
import {getContributionsEvents} from '../../actions/contributionActions'
import ContributionEvents from './elements/ContributionEvents'
import { config } from '../../config'
import { getLogActivityWithGeoIp } from '../../actions/profileActions';

const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

class Calendar extends Component {
        constructor(props) {
        super(props);
            this.state = {
                contributions: [],
                filter: []
            }
        }

        componentDidMount() {
            this.props.getLogActivityWithGeoIp('Calendar page', 'GET', "Website usage")
        }

        componentWillReceiveProps(nextProps) {

        }

        handleChangeFilter = value => {

         this.props.getContributionsEvents(value)
         this.setState({
             filter: value
         })
     }

        render() {
    const { classes } = this.props;

        return (
        <div className={classes.root}>
            <Grid container spacing={0}>
                <div  className="page-content slideup page-animation" >
                  <div className="event-calendar">

                      <div className="heroshot">
                        <img src={config.API_URL +"/images/org/6ec2b7.jpeg?v1.0"} alt="calendar"/>
                        <Grid container direction="row" justify="center" className="center-col">
                          <Grid item md={8} xs={12} className="text-area text-center">
                              <img src={config.API_URL +"/images/org/828dc1.png?v1.0"} alt="calendar"/>
                              <h1 className="text-white text-bold">Event Calendar</h1>
                              <h2 className="text-white">Be a part of the change you want to see
                                  <br/>and take your commitment to the next level
                              </h2>
                          </Grid>
                      </Grid>
                      </div>
                      <Grid lg={12} sm={12} item>
                        <div className="filter-area">

                          <Filters onChange={this.handleChangeFilter}
                            filterTotal={this.props.contribution.total ? String(this.props.contribution.total) : String(0)}
                            filterOffset={0}
                            calendar={true}
                            id={""}/>

                        </div>
                      </Grid>
                      {this.props.contribution.events.length > 0 &&
                        <ContributionEvents onLoadMore={this.handleLoadMore} />
                      }
                  </div>

                  <Footer/>
                </div>

              <Navbar/>
            </Grid>
        </div>
);
}
}

Calendar.propTypes = {
    classes: PropTypes.object.isRequired,
    contribution: PropTypes.object.isRequired,
    getContributionsEvents: PropTypes.func.isRequired,
    getLogActivityWithGeoIp: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    contribution: state.contribution
});

Calendar = withStyles(styles)(Calendar)
export default connect(mapStateToProps, { getContributionsEvents, getLogActivityWithGeoIp })(Calendar);
