import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import Navbar from "../layout/Navbar";
import Grid from "@material-ui/core/Grid";
import Footer from "../layout/Footer";
import TextField from "@material-ui/core/TextField";
import { resetPassword, clearErrors, clearMessage } from "../../actions/authActions";
import Message from '../common/Message'
import { getLogActivityWithGeoIp } from '../../actions/profileActions';

class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      email: ""
    };
  }
  
   componentDidMount(prevProps) {
        this.props.getLogActivityWithGeoIp('Reset Password page', 'GET', "Website usage")
  }

  componentWillUnmount() {
      this.props.clearErrors()
      this.props.clearMessage()
   }

  handleOnChange = e => {
    this.setState({ email: e.target.value });
  };

  handleOnSubmit = e => {
    e.preventDefault();

    this.props.resetPassword(this.state.email);
    this.setState({ email: "" });
  };

  render() {
    const TextFieldStyle = {
      color: "red",
      width: "30%",
      background: "white"
    };

    const pStyle = {
      width: "30%",
      margin: "30px auto"
    };

    return (
      <div>
       <Grid container spacing={0} >
         <div  className="page-content  slideup page-animation" >
          <div className="contribute-register">
          {Object.entries(this.props.message).length === 0 ?
             <div >
            
                 <div className="header">
                     <div className="col-md-6 col-md-offset-3">
                         <h1 className="max-width text-center text-white">
                                     Sign up to become a Contributor and join the SPHS Community
                         </h1>
                     </div>
                     <div className="col-md-12 text-center">
                         <h4>All fields are required, unless indicated as optional</h4>
                     </div>
                 </div>
                  
                     <div className="resetPassword">
                     {Object.entries(this.props.errors).length > 0 &&
                     <Message show={true} message={this.props.errors.error} error={true}/>
                     }
                    
                        <h2 className="text-center">Reset Password</h2>
                        <p style={pStyle}>After entering your email address you will receive a link to create a new password via email.</p>

                        <form className="text-center" onSubmit={this.handleOnSubmit}>
                          <div>
                            <TextField
                              name="email"
                              id="outlined-dense"
                              placeholder="User email"
                              onChange={this.handleOnChange}
                              value={this.state.email}
                              required
                              style={TextFieldStyle}
                              variant="outlined"
                            />
                          </div>
                          <button type="submit" style={{ display: 'inline', margin: 15}}>
                              Reset password
                          </button>
                        </form>
                       </div>
                         
                       
                 </div>
                 :
                          <Message show={true} message={this.props.message.message} error={false}/>    
                          }
                                  </div>
                       <Footer/>
                     </div>
                 <Navbar/>
             </Grid>
         </div>
    );
  }
}

ResetPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  clearMessage: PropTypes.func.isRequired, 
  clearErrors: PropTypes.func.isRequired,         
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  getLogActivityWithGeoIp: PropTypes.func.isRequired, 
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  message: state.message
});

export default connect(
  mapStateToProps,
  { resetPassword, clearMessage, clearErrors, getLogActivityWithGeoIp }
)(reduxForm({ form: "ResetPassword" })(ResetPassword));
