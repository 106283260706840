export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const GET_MESSAGE = 'GET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const REQUESTED_ROUTE = 'REQUESTED_ROUTE';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_MEMBER_PROFILE = 'GET_MEMBER_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND';
export const CLEAR_CURRENT_PROFILE = 'CLEAR_CURRENT_PROFILE';
export const GET_REGISTER_DATA = 'GET_REGISTER_DATA';
export const GET_ERRORS_REGISTRATION = 'GET_ERRORS_REGISTRATION';

//members
export const GET_MEMBER = 'GET_MEMBER';
export const GET_MEMBERS = 'GET_MEMBERS';
export const MEMBER_LOADING = 'MEMBER_LOADING';
//news
export const GET_SINGLE_NEWS = 'GET_SINGLE_NEWS';
export const GET_NEWS = 'GET_NEWS';
export const NEWS_LOADING = 'NEWS_LOADING';
export const GET_NEWS_CATEGORIES = 'GET_NEWS_CATEGORIES';
export const GET_NEWS_TOTAL = 'GET_NEWS_TOTAL';
export const GET_NEWS_OFFSET = 'GET_NEWS_OFFSET';
export const CLEAR_NEWS = 'CLEAR_NEWS';
//sectors (focus-area)
export const GET_SECTOR = 'GET_SECTOR';
export const GET_SECTORS = 'GET_SECTORS';
export const SECTOR_LOADING = 'SECTOR_LOADING';
export const GET_SECTORS_FILTER = 'GET_SECTORS_FILTER';
export const GET_MAP_FILTER = 'GET_MAP_FILTER';
//sdg
export const GET_SDGS = 'GET_SDGS';
//codes
export const GET_ORG_TYPES = 'GET_ORG_TYPES';
//regions
export const GET_REGIONS = 'GET_REGIONS';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_SELECTED = 'GET_SELECTED'
//contributions
export const GET_TYPES = 'GET_TYPES';
export const GET_CONTRIBUTIONS = 'GET_CONTRIBUTIONS';
export const GET_CONTRIBUTION = 'GET_CONTRIBUTION';
export const GET_CONTRIBUTIONS_TOTAL = 'GET_CONTRIBUTIONS_TOTAL';
export const GET_CONTRIBUTIONS_OFFSET = 'GET_CONTRIBUTIONS_OFFSET';
export const GET_CONTRIBUTION_EVENTS = 'GET_CONTRIBUTION_EVENTS';
export const LOAD_MORE_CONTRIBUTIONS = 'LOAD_MORE_CONTRIBUTIONS';
export const GET_CONTRIBUTIONS_MAP = 'GET_CONTRIBUTIONS_MAP';
export const GET_CONTRIBUTION_FORM_DATA = 'GET_CONTRIBUTION_FORM_DATA';
export const GET_ERRORS_CONTRIBUTION = 'GET_ERRORS_CONTRIBUTION';
//user setup
export const SAVE_FOR_LATER_LIST = 'SAVE_FOR_LATER_LIST';
export const SAVE_FOR_LATER_LOADING = 'SAVE_FOR_LATER_LOADING';
//account
export const GET_CONTACT_COUNTRIES = 'GET_CONTACT_COUNTRIES';
//forum
export const GET_THREADS = 'GET_THREADS';
export const GET_THREAD = 'GET_THREAD';
export const DOWNLOAD_ATTACHMENT = 'DOWNLOAD_ATTACHMENT';
export const DELETE_REPLY = 'DELETE_REPLY';
export const GET_THREAD_FORM_DATA = 'GET_THREAD_FORM_DATA';
export const SET_NEW_REPLY = 'SET_NEW_REPLY';
export const GET_REPLY = 'GET_REPLY';
//setups
export const GET_CONTACTS_MAP = 'GET_CONTACTS_MAP';

// GRID ACTION TYPES
export const GRID_MOUNT = 'GRID_MOUNT';
export const GRID_PROPERTIES_LOADED = 'GRID_PROPERTIES_LOADED';
export const GRID_API_LOAD = 'GRID_API_LOAD';
export const GRID_DATA_LOADED = 'GRID_DATA_LOADED';
export const GRID_HEADER_SHOW_LOADER = 'GRID_HEADER_SHOW_LOADER';

export const GRID_ROWS_SELECTED = 'GRID_ROWS_SELECTED';
export const GRID_ROWS_SELECTED_CLEAR = 'GRID_ROWS_SELECTED_CLEAR';

export const GRID_HEADER_SHOW_OPTIONS = 'GRID_HEADER_SHOW_OPTIONS';
export const GRID_HEADER_OPTION_SELECTED = 'GRID_HEADER_OPTION_SELECTED';
export const GRID_HEADER_OPTION_LABEL_CHANGED = 'GRID_HEADER_OPTION_LABEL_CHANGED';

export const GRID_BULK_ACTION_OPEN = 'GRID_BULK_ACTION_OPEN';
export const GRID_BULK_OPEN = 'GRID_BULK_OPEN';
export const GRID_BULK_ACTION_CLICK = 'GRID_BULK_ACTION_CLICK';

export const GRID_QUICK_FILTER_CHANGE = 'GRID_QUICK_FILTER_CHANGE';
export const GRID_DATES_CHANGE = 'GRID_DATES_CHANGE';

export const GRID_COLUMNS_SHOW = 'GRID_COLUMNS_SHOW';
export const GRID_COLUMNS_CHECK = 'GRID_COLUMNS_CHECK';
export const GRID_COLUMNS_UPDATE = 'GRID_COLUMNS_UPDATE';

export const GRID_CONFIRM_DIALOG_SHOW = 'GRID_CONFIRM_DIALOG_SHOW';
export const GRID_ADDITIONAL_DATA = 'GRID_ADDITIONAL_DATA'
// END GRID ACTION TYPES

// START POPUP MESSAGE ACTIONS
export const POPUP_MESSAGE_SHOW = 'POPUP_MESSAGE_SHOW';
export const POPUP_MESSAGE_HIDE = 'POPUP_MESSAGE_HIDE';
// END POPUP MESSAGE ACTIONS

//MENU
export const SETTINGS_LOAD = 'SETTINGS_LOAD';
export const SETTINGS_SIDEBAR_CHANGE = 'SETTINGS_SIDEBAR_CHANGE';

// START FORM ACTION TYPES
export const FORM_MOUNT = 'FORM_MOUNT';
export const FORM_LOADED = 'FORM_LOADED';
export const FORM_SHOW_LOADER = 'FORM_SHOW_LOADER';
export const FORM_INITIAL_VALIDATION = 'FORM_INITIAL_VALIDATION';
export const FORM_SAVE_VALIDATE = 'FORM_SAVE_VALIDATE';
export const FORM_EMPTY_VALID = 'FORM_EMPTY_VALID';

export const FORM_DATA_LOAD = 'FORM_DATA_LOAD';
export const FORM_DATA_VALIDATION = 'FORM_DATA_VALIDATION';

export const FORM_FIELD_UPDATE = 'FORM_FIELD_UPDATE';
export const FORM_FIELD_VALIDATE = 'FORM_FIELD_VALIDATE';
export const FORM_FIELD_LOSE_FOCUS = 'FORM_FIELD_LOSE_FOCUS';

export const FORM_FIELD_SELECT2_OPTIONS = 'FORM_FIELD_SELECT2_OPTIONS';
export const FORM_FIELD_SELECT2_ADD_OPTIONS = 'FORM_FIELD_SELECT2_ADD_OPTIONS';
export const IMAGE_UPLOAD_SUCCESSFUL = 'IMAGE_UPLOAD_SUCCESSFUL';
export const IMAGES_UPLOAD_SUCCESSFUL = 'IMAGES_UPLOAD_SUCCESSFUL';
export const FORM_REMOVE_IMAGE = 'FORM_REMOVE_IMAGE';
export const FORM_SAVE_DISABLE = 'FORM_SAVE_DISABLE';
export const FORM_SAVE_ENABLE = 'FORM_SAVE_ENABLE';
// END FORM ACTION TYPES

//ACCOUNT
export const GET_ACCOUNT = 'GET_ACCOUNT';
export const GET_DUPLICATED_ACCOUNTS = "GET_DUPLICATED_ACCOUNTS";
export const RESOLVE_DUPLICATED_ACCOUNTS = "RESOLVE_DUPLICATED_ACCOUNTS";
//COMMENTS
export const COMMENTS_WIDGET_LOADED = 'COMMENTS_WIDGET_LOADED';
export const COMMENTS_WIDGET_UNLOADED = 'COMMENTS_WIDGET_UNLOADED';
export const ADD_COMMENT = 'ADD_COMMENT';
export const EDIT_COMMENT = 'EDIT_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
//socket
export const SOCKET_CONNECTION_INIT = 'SOCKET_CONNECTION_INIT';
export const SOCKET_CONNECTION_SUCESS = 'SOCKET_CONNECTION_SUCCESS';
export const SOCKET_CONNECTION_ERROR = 'SOCKET_CONNECTION_ERROR';
export const SOCKET_CONNECTION_CLOSED = 'SOCKET_CONNECTION_CLOSED';
export const SOCKET_MESSAGE = 'SOCKET_MESSAGE';
//notifications
export const ADD_NOTIFICATION_TO_LIST = 'ADD_NOTIFICATION_TO_LIST';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const GET_CURRENT_NOTIFICATIONS = 'GET_CURRENT_NOTIFICATIONS';
export const COUNT_UNREAD = 'COUNT_UNREAD';
export const CURRENT_RECEIVER = 'CURRENT_RECEIVER';
//users
export const GET_ADMINS = 'GET_ADMINS';
//collaborations
export const GET_COLLABORATIONS = 'GET_COLLABORATIONS';
export const GET_COLLABORATIONS_FORM_DATA = 'GET_COLLABORATIONS_FORM_DATA';
export const GET_COLLABORATION = 'GET_COLLABORATION';
//help
export const GET_HELP_CATEGORIES = 'GET_HELP_CATEGORIES';
export const GET_HELP_CONTENT = 'GET_HELP_CONTENT';
export const DELETE_HELP_CATEGORY = 'DELETE_HELP_CATEGORY';

export const GET_CALENDAR_COLLABORATIONS = 'GET_CALENDAR_COLLABORATIONS';
//documents
export const FILE_PROFILE = 'FILE_PROFILE';
export const GET_USER_MEMBERS = 'GET_USER_MEMBERS';
export const GET_USER_MEMBERS_FILES = 'GET_USER_MEMBERS_FILES';
export const ADD_USER_MEMBERS_FILE= 'ADD_USER_MEMBERS_FILE';
export const REMOVE_USER_MEMBERS_FILE = 'REMOVE_USER_MEMBERS_FILE';
//questionnaire
export const CHECK_USER = 'CHECK_USER';
export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS';
export const GET_QUESTIONNAIRE = 'GET_QUESTIONNAIRE';
export const GET_SECTION = 'GET_SECTION';
export const ADD_SECTION = 'ADD_SECTION';
export const DELETE_SECTION = 'DELETE_SECTION';
export const ADD_FIELD = 'ADD_FIELD';
export const DELETE_FIELD = 'DELETE_FIELD';
export const CHANGE_FIELD = 'CHANGE_FIELD';
export const GET_QUEST_FILL = 'GET_QUEST_FILL';
export const GET_USER_QUESTIONNAIRES = 'GET_USER_QUESTIONNAIRES';
export const GET_ORG_QUESTIONNAIRES = 'GET_ORG_QUESTIONNAIRES';
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_CONTACT_QUESTIONNAIRES = 'GET_CONTACT_QUESTIONNAIRES';
export const GET_PDF_DATA = 'GET_PDF_DATA';
export const GET_DUPLICATED_FORM = 'GET_DUPLICATED_FORM';
export const GET_EXCEL_DATA = 'GET_EXCEL_DATA';
export const GET_USER_TRACK_REPORT = 'GET_USER_TRACK_REPORT'
export const GET_REPORT_DАТА = 'GET_REPORT_DАТА'
export const GET_REPORT_DESCRIPTION = 'GET_REPORT_DESCRIPTION';
export const GET_CRM_REPORT = 'GET_CRM_REPORT';
export const GET_CONVENTION_REPORT = 'GET_CONVENTION_REPORT';
