import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import { Editor } from "@tinymce/tinymce-react";
import DropZoneFields from "../file/DropZoneFields";
import {
  renderTextField,
  renderSelectField,
  renderTmcField,
  renderCheckbox
} from "../common/MaterialUiForm";
import IntegrationReactSelect from "../common/IntegrationReactSelect";
import { withStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import { getReply } from '../../actions/forumActions'
import ImagePreview from "../file/ImagePreview";

const styles = {
  inputFields: {
    height: 50,
    width: "100%"
  },
  inputLabels: {
    fontSize: 14,
    paddingTop: 15,
    paddingRight: 5,
    paddingBottom: 10,
    textTransform: 'uppercase'
  },
  inputColumns: {
    padding: "10px 30px"
  },
  labelsColumns: {
    textAlign: "right",
    paddingRight: 5
  },
  selectButtons: {
    margin: 8
  },
  input: {
    height: "auto !important"
  },
  label: {
      fontSize: 14,
      textTransform: 'uppercase'
  },
  tmc: {
      height: 300
  }
};

const validate = values => {
  const errors = {};
  const requiredFields = ["message"];
  
  requiredFields.forEach(field => {
    if (!values[field]) {
        
      errors[field] = "required";
    }
  });

  return errors;
};



class ThreadReplyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: []
    };
  }

  componentDidMount() {
     if(this.props.threadId){
       //this.props.getReply(this.props.threadId)
     }else{
         this.setState({
               files: []
           })
     }

  }

   componentWillReceiveProps(nextProps) {
       if(nextProps.threads.reply && this.props.threadId){
            this.setState({
               files: nextProps.threads.reply.attachments
            })
       }
    }


  handleOnDrop = (newImageFile, onChange) => {
    if (newImageFile.length <= 0) {
      toast.error("The file must be image under 2MB!");
    } else {
      toast.success("File uploaded successfully");

      const imageFile = {
        file: newImageFile[0],
        name: newImageFile[0].name,
        preview: URL.createObjectURL(newImageFile[0]),
        size: newImageFile[0].size,
        type: newImageFile[0].type
      };
    
      let files = this.state.files;
      files.push(imageFile)

      this.setState({ files: files }, () => onChange(files));
    }
  };

  handleOnDelete = (e, onChange) => {
      e.preventDefault()

      let files = this.state.files;
    
     files.map((fileObj, key) => {
        
          if(fileObj.preview === e.target.value){
                      
              files.splice(key, 1)
             
          }
      })
      this.setState({ files: files }, () => onChange(files));

  }

  handleChange = (files) => {

  }

  resetForm = () => this.setState({ files: [] }, () => this.props.reset());


  render() {
     const { handleSubmit, pristine, submitting, classes, threads } = this.props;

    return (
      <form style={{padding: 14}} onSubmit={handleSubmit}>
        <Grid container spacing={5} >
              <Grid item xs={8} sm={8}  container style={{width: '100%', height: '100%'}}>
                 <Field
                    name="message"
                    component={renderTmcField}
                    label="Post a reply"
                    variant="outlined"
                    style={{width: '100%', height: '100%'}}
                    className="tmc-reply"
                    field={{id: 'messageReply'}}
                  />
              </Grid>

              <Grid item xs={2} style={{ textAlign: 'left', marginTop: 14 }} >
                <InputLabel htmlFor="contribution">
                  {"Attachments    (up to 2MB)"}
                </InputLabel>
                 <Field
                    name="attachments"
                    component={DropZoneFields}
                    type="file"
                    files={this.state.files}
                    value={this.state.files}
                    multiple={true}
                    handleOnDrop={this.handleOnDrop}
                    handleOnDelete={this.handleOnDelete}
                    onChange={files => this.handleChange(this.state.files)}
                    preview={false}
                  />

               </Grid>
               <Grid item xs={2} style={{ paddingTop: 23, textAlign: 'left', color: 'rgba(0, 0, 0, 0.54)' }} >
               {this.props.type==='member' &&       
                <Field
                  name="protectAttachments"
                  component={renderCheckbox}
                  label="Confidential"
                />
                }
              </Grid>

        <Grid item xs={12} sm={12} className="text-left action-buttons">

          <button type="submit" className="btn btn-primary btn-undp" disabled={ submitting} >
            POST
          </button>
      {this.props.threadId &&
          <button type="button" className="btn btn-default" onClick={this.props.cancelEdit} >
              Cancel
            </button>
          }
          </Grid>
        </Grid>
      </form>
    );
  }
}

ThreadReplyForm.propTypes = {
  threads: PropTypes.object.isRequired,

};


ThreadReplyForm = reduxForm({
  form: 'ThreadReplyForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
 // touchOnChange: true,
//  touchOnBlur: true,
  validate
})(ThreadReplyForm)


ThreadReplyForm = connect(
  state => ({
    threads: state.threads    
  }),
  {  }
)(ThreadReplyForm)

export default ThreadReplyForm


