import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Popup from '../../../common/Popup';
import AssessmentIcon from '@material-ui/icons/Assessment';

export default class PopupWebsite extends Component {
    render() {
        const settings = this.props.settings;
        const id = 'popupWebsite';
        return (
                <Popup locale={this.props.settings.locale}
                   title={ 'Website'}
                   onClose={() => this.props.closePopup('popupWebsite')}>
                <ul>

                    <li className="col-sm-6 marginBottom" onClick={() => this.props.closePopup('popupWebsite')}>
                        <Link title="SPHS Contributions" to={'/admin/contributions'}>
                            <i className="fas fa-circle-notch"></i>
                            <span style={{ padding: 20 }}>SPHS Contributions</span>
                        </Link>
                    </li>
                     <li className="col-sm-6 marginBottom" onClick={() => this.props.closePopup('popupWebsite')}>
                        <Link title="Public Contributions" to={'/admin/contributions/review'}>
                            <i className="fas fa-circle-notch"></i>
                            <span style={{ padding: 20 }}>Public Contributions</span>
                        </Link>
                    </li>
                     <li className="col-sm-6 marginBottom" onClick={() => this.props.closePopup('popupWebsite')}>
                        <Link title="news" to={'/admin/news'}>
                           <i className="fas fa-microphone"></i>
                            <span style={{ padding: 20 }}>News</span>
                        </Link>
                    </li>
                     <li className="col-sm-6 marginBottom" onClick={() => this.props.closePopup('popupWebsite')}>
                        <Link title="users" to={'/admin/users/active'}>
                            <i className="fas fa-users"></i>
                            <span style={{ padding: 20 }}>Users</span>
                        </Link>
                    </li>
                      <li className="col-sm-6 marginBottom" onClick={() => this.props.closePopup('popupWebsite')}>
                        <Link title="news" to={'/admin/users-sessions/list'}>
                           <i className="fa fa-history"></i>
                            <span style={{ padding: 20 }}>Users log</span>
                        </Link>
                    </li>
                     <li className="col-sm-6 marginBottom" onClick={() => this.props.closePopup('popupWebsite')}>
                        <Link title="news" to={'/admin/user-track/list'}>
                           <i className="fa fa-history"></i>
                            <span style={{ padding: 20 }}>User tracking</span>
                        </Link>
                    </li>
                     <li className="col-sm-6 marginBottom" onClick={() => this.props.closePopup('popupWebsite')}>
                        <Link title="news" to={'/admin/website-usage/list'}>
                           <i className="fa fa-history"></i>
                            <span style={{ padding: 20 }}>Website usage</span>
                        </Link>
                    </li>
                     <li className="col-sm-6 marginBottom" onClick={() => this.props.closePopup('popupWebsite')}>
                        <Link title="news" to={'/admin/website-tools-usage/list'}>
                           <i className="fa fa-history"></i>
                            <span style={{ padding: 20 }}>Website tools usage</span>
                        </Link>
                    </li>
                     <li className="col-sm-6 marginBottom" onClick={() => this.props.closePopup('popupWebsite')}>
                        <Link title="news" to={'/admin/website-tools-inactive/list'}>
                           <i className="fa fa-history"></i>
                            <span style={{ padding: 20 }}>Website tools inactive users</span>
                        </Link>
                        </li>
                    <li className="col-sm-6 marginBottom" onClick={() => this.props.closePopup('popupWebsite')}>
                        <Link title="news" to={'/admin/user-track/report'}>
                           <AssessmentIcon/>
                            <span style={{ padding: 20 }}>User track report</span>
                        </Link>
                    </li>
                </ul>
            </Popup>
        )
    }
}
