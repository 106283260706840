import client from './client';
import {
    SETTINGS_LOAD,
    SETTINGS_SIDEBAR_CHANGE,
} from "../actions/types";

export const  onSidebarMount = () => dispatch => {
  
        return new Promise((resolve, reject) => {
            client
                .get('/api/admin/user/sidebar' )
               // .then(response => response.data)
                .then(response => {
                        dispatch({
                            type: SETTINGS_LOAD,
                            payload: response.data
                        });
                        resolve();
                    }
                )
                .catch(err => {
                    if(err.response && err.response.data && err.response.data.code === 401){
                        window.location = '/login'
                    }
                })
        });
    
}

export const onSidebarChange = (sidebar) => dispatch => {
  
        dispatch({
            type: SETTINGS_SIDEBAR_CHANGE,
            payload: {sidebar}
        });

        return new Promise((resolve, reject) => {
            client
               .post('/api/admin/user/sidebar/change', {'sidebarClass': sidebar})
                .then(response => response.data)
                .then(response => {
                        resolve();
                    }
                );
        });
    
}