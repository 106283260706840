import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { getProduct } from "../../../actions/questionnaireActions";
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';
import Layout from '../../../components/admin/layout/Layout'
import { Link } from 'react-router-dom';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: "20px 10px"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width: '100%',
    height: '100%',
    color: '#000000de'
  },
  labelData:{
    padding: "8px 17px 8px 0",
    color: "#777",
    textAlign: "right",
    width: "initial",
    fontWeight: "normal"
  },
  bigAvatar:{
    width: 100,
   height: 100,
  },
  formGroup: {
      margin: 15,
      marginLeft: 50,
      width: '60%'
  }
});

class ProductProfile extends Component {
  constructor() {
    super();
    this.state = {
      
    };
  }

     componentDidMount() {
       this.props.getProduct(this.props.match.params.id)
    }

    componentWillReceiveProps(nextProps) {

    }


  render() {
   const { product } = this.props.questionnaire;   
   const { classes } = this.props;

  return (   
     <Layout>        
       {product && <Grid container className={classNames(classes.root)} spacing={2} direction="row" justify="flex-start"  >
                    <Grid item md={12} lg={12} xs={12} className="profile-breadcrumb">
                      <div className="profile-breadcrumb">
                        <Link to={"/admin/products"}><i className="fa fa-fw fa-chevron-left"></i>Products</Link>
                       </div>
                    </Grid>
                    
                     <Grid md={6} lg={6} xs={12} item container justify="flex-start" direction="row">
                     <Paper className={classes.paper} >
                     
                     <Grid item md={12} lg={12} xs={12} container direction="row" justify="flex-start" className="profile-information">
                            <Grid md={12} lg={12} xs={12} item className="profile-desk">
                            <h1 className="accountTitle">{ product.title }</h1>
                                 <div className="m-top30">
                                    {product.no &&
                                            <p><span className={classes.labelData}>No: </span>{ product.no }<br/></p>
                                    }
                                    {product.title &&
                                            <p><span className={classes.labelData}>Name: </span>{ product.title }<br/></p>
                                    }
                                     {product.supplierItemNo &&
                                            <p><span className={classes.labelData}>Supplier Product No: </span>{ product.supplierItemNo }<br/></p>
                                    }
                                   {product.price &&
                                            <p><span className={classes.labelData}>Price: </span>{ product.price }<br/></p>
                                    }
                                    {product.currencyName &&
                                            <p><span className={classes.labelData}>Currency: </span>{ product.currencyName }<br/></p>
                                    }
                                    {product.weight &&
                                           <p><span className={classes.labelData}>Weight: </span>{ product.weight }<br/></p>
                                    }
                                    {product.measureName &&
                                           <p><span className={classes.labelData}>Measure: </span>{ product.measureName }<br/></p>
                                    }
                                    {product.categoryName &&
                                           <p><span className={classes.labelData}>Product family: </span>{ product.categoryName }<br/></p>
                                    }
                                    {product.segmentName &&
                                             <p><span className={classes.labelData}>Segment: </span>{ product.segmentName }<br/></p>
                                    }
                                    {product.status &&
                                           <p><span className={classes.labelData}>Status: </span>{ product.status }<br/></p>
                                    }
                                    {product.currentQuantity &&
                                           <p><span className={classes.labelData}>Current Quantity: </span>{ product.currentQuantity }<br/></p>
                                    }
                                    {product.currentValue &&
                                           <p><span className={classes.labelData}>Current Value: </span>{ product.currentValue }<br/></p>
                                    }
                                    {product.countStock &&
                                             <p><span className={classes.labelData}>Goods or service?: </span>{ product.countStock }<br/></p>
                                    }
                                     {product.createdAt &&
                                            <p><span className={classes.labelData}>Created at: </span>{ product.createdAt }<br/></p>
                                    }
                                    {product.createdByName &&
                                            <p><span className={classes.labelData}>Created by: </span>{ product.createdByName }<br/></p>
                                    }
                                     {product.accountName &&
                                            <p><span className={classes.labelData}>Supplier: </span>{ product.accountName }<br/></p>
                                    }
                                     {product.createdByName &&
                                            <p><span className={classes.labelData}>Created by: </span>{ product.accountName }<br/></p>
                                    }
                                    {product.shortDescription &&
                                     <p><span className={classes.labelData}>Short Description: </span>{ product.shortDescription }<br/></p>}
                                      {product.facilityName &&
                                              <div>
                                      <h4>Manufacturing Information</h4>
                                     <p><span className={classes.labelData}>Facility Name </span>{ product.facilityName }<br/></p>
                                     </div>}
                                   {product.facilityCity &&
                                            <p><span className={classes.labelData}>Facility City: </span>{ product.facilityCity }<br/></p>
                                    }
                                    {product.facilityCountry &&
                                            <p><span className={classes.labelData}>Facility Country: </span>{ product.facilityCountry }<br/></p>
                                    }
                                    {product.facilityEmployees &&
                                            <p><span className={classes.labelData}>Facility Employees: </span>{ product.facilityEmployees }<br/></p>
                                    }
           
                                {product.facilityPackagingName &&
                                    <div>
                                      <h4>Assembly/Formulation/Packaging Information (if different from above)</h4>
                                     <p><span className={classes.labelData}>Facility Name </span>{ product.facilityPackagingName }<br/></p>
                                     </div>}
                                      {product.facilityPackagingCity &&
                                            <p><span className={classes.labelData}>Facility City: </span>{ product.facilityPackagingCity }<br/></p>
                                    }
                                     {product.facilityPackagingCountry &&
                                            <p><span className={classes.labelData}>Facility Country: </span>{ product.facilityPackagingCountry }<br/></p>
                                    }
                                     {product.facilityPackagingEmployees &&
                                            <p><span className={classes.labelData}>Facility Employees: </span>{ product.facilityPackagingEmployees }<br/></p>
                                    }
                                </div>
                            </Grid>
                            
                              </Grid>
                     
                    </Paper>   
                    </Grid>
                     <Grid md={6} lg={6} xs={12} item container justify="flex-start" direction="row">
                     <Paper className={classes.paper} >
                     
                     <Grid item md={12} lg={12} xs={12} container direction="row" justify="flex-start" className="profile-information">
                    
                     {product.longDescription && 
                                  <p>
                                      <h3>Description </h3> 
                                       <Grid md={12} lg={12} xs={12} item className="profile-desk">
                                      <span dangerouslySetInnerHTML={{__html: product.longDescription}}></span>
                                          </Grid>
                                  </p>}
                      
                     </Grid>
                    </Paper>   
                    </Grid>
      </Grid>}
      </Layout>
    );
  }
}

ProductProfile.propTypes = {
   questionnaire: PropTypes.object.isRequired,
  getProduct: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  questionnaire: state.questionnaire,
});

ProductProfile = withStyles(styles)(ProductProfile)

export default connect( mapStateToProps, { getProduct })(withRouter(ProductProfile));
