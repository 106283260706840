import React, { Component } from 'react';

export default class HtmlRenderer extends Component {
        
    constructor(props) {
         super(props);
        
          this.state = {
            value: this.props.value,
        };
    }

     render() {
        return (
            <span dangerouslySetInnerHTML={{__html: this.state.value}}></span>
        );
    }
}  