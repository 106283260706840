import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../../actions/authActions';
import TextFieldGroup from '../common/TextFieldGroup';
import { Link } from 'react-router-dom';
import logo from '../../img/logo.png';
import { getLogActivityWithGeoIp } from '../../actions/profileActions';
//import '../../css/login.css';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/contribute/welcome');
    }else{
        this.props.getLogActivityWithGeoIp('Login page', 'GET', "Website usage")
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push('/contribute/welcome');
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const userData = {
      username: this.state.email,
      password: this.state.password
    };

    this.props.loginUser(userData, this.props.history, this.props.auth.requestedRoute);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;
    let globalError  = null;
    if (Object.keys(errors).length > 0 && errors.message) {
      globalError =  errors.message;
    }

    return (
      <div className="container-fluid" >


            <article className="login">
             <Link to="/" >
                     <img src={logo} alt="logo" width="150" height="53"/>
             </Link>

                <h1 className="text-center">Login Account</h1>

              { globalError && (<p className="label label-danger margin-b-15">Invaid credentials.</p>) }
              <form onSubmit={this.onSubmit}>
                <TextFieldGroup
                  placeholder="Email"
                  name="email"
                  type="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  error={errors.email}
                />
                <a className="pull-left" href="/password-reset" >Forgot it?</a>
                <TextFieldGroup
                  placeholder="Password"
                  name="password"
                  type="password"
                  value={this.state.password}
                  onChange={this.onChange}
                  error={errors.password}
                  style={{
                    border: "1px solid #c7c7c7",
                  }}
                />
                <input type="submit" className="blue-button" value="Sign in"/> <br/>
                <p style={{ marginTop: 20 }}>Don't have an account?</p>
            <Link to="/contribute/register">Create Account</Link>
              </form>

            </article>


      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getLogActivityWithGeoIp: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { loginUser, getLogActivityWithGeoIp })(Login);
