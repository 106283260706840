import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Layout from '../../../components/admin/layout/Layout'
import {
  PieChart, Pie, Sector, Cell,
} from 'recharts';
import {getReportData } from '../../../actions/questionnaireActions'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import Button from '@material-ui/core/Button';
import ReactToPrint, { PrintContextConsumer }  from "react-to-print";
import PDFChartReportComponent from './PDFChartReportComponent'

const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

class PDFChartReport extends Component {
  constructor() {
    super();    
  }

     componentDidMount() {
       this.props.getReportData(this.props.match.params.type)
    }

  render() {
    const {reportData} = this.props.questionnaire;
    
     const { classes } = this.props;
    return (   
    <Layout>
    <Grid container spacing={0} >        
     {reportData && <div className="page-content page-animation slideup" style={{top: 0}} >
     <Grid item md={10} lg={10} xs={6} className="text-right" style={{marginTop: 10 }}> 
     <ReactToPrint content={() => this.componentRef}>
          <PrintContextConsumer>
            {({ handlePrint }) => (
             <Button onClick={handlePrint} variant="outlined"> Print</Button>
            )}
          </PrintContextConsumer>
        </ReactToPrint>
         </Grid><Grid item md={2} lg={2} xs={6}></Grid>
         
              <PDFChartReportComponent ref={el => (this.componentRef = el)} data={reportData}/>       
    </div>}
     </Grid>
     </Layout>
    );
  }
}

PDFChartReport.propTypes = {
  questionnaire: PropTypes.object.isRequired,
  getReportData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  questionnaire: state.questionnaire,
});

PDFChartReport = withStyles(styles)(PDFChartReport)

export default connect( mapStateToProps, { getReportData })(withRouter(PDFChartReport));
