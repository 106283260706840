import React, { Component }
from 'react';
import PropTypes from 'prop-types';
import SpeakersModalSmall from './SpeakersModalSmall';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import { config } from '../../../config';
import moment from 'moment-timezone';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import TimezonePicker from 'react-timezone';
import axios from 'axios';


class Forum2021Programme extends Component {

 constructor(props) {
        super(props);
            this.state = {
            timezone: 'Europe/Istanbul',
            locale: null
        }
    }

    componentDidMount() {
        axios('data/'+this.props.locale+'forum2021.json',{
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
         }
      })
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          this.setState({
            data: response
          })
        })
        this.setState({
          locale: this.props.locale
        })
      }

      componentDidUpdate() {
        if(this.props.locale !== this.state.locale){
          axios('data/'+this.props.locale+'forum2021.json',{
            headers : {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
          })
            .then((response) => {
              return response.data;
            })
            .then((response) => {
              this.setState({
                data: response
              })
            })

            this.setState({
              locale: this.props.locale
            })
        }

              }

    onChange = (value) => {

        this.setState({
            timezoneValue: value,
            timezone: value ? value : null
        })
    }

  render() {

          return (
            <div>
              <div className="programme">
                  <a className="anchor-link" name="asia-forum-intro-anchor-2">
                  <h1 className="text-center">
                      <span className="text-black">{this.state.locale === 'ES' ? 'Programa y panelistas' : 'Programme & Speakers'}</span>
                  </h1>
                  </a>

                  <Grid container className="intro" style={{marginTop:50}}>
                    <Grid item md={12} lg={12} xs={12} className="text-right" container>
                      <Grid item md={4} lg={4} xs={6}></Grid>
                      <Grid item md={3} lg={3} xs={6} className="text-left">
                       <h3 className="text-white">Select your time zone:</h3>
                     <TimezonePicker
                      className="timezone-picker"
                      value={this.state.timezone}
                      onChange={timezone => this.onChange(timezone)}
                      inputProps={{
                        placeholder: 'Select Timezone...',
                        name: 'timezone',
                      }}
                      /></Grid>
                       </Grid>
                      </Grid>

                      <div className="day">
                        <h1 className="day-title">{this.state.locale === 'ES' ? 'Día 1: 17 de noviembre' : 'DAY 1: 17 November 2021'}</h1>
                        <div className="programme-day" style={{minHeight: "475px"}}>
                          <div className="col-lg-1 col-md-1 col-xs-12"></div>
                          <div className="col-lg-9 col-md-9 col-xs-12 " style={{bottom: 0,right: 0}}>
                            <div style={{margin:"25px 5px 0 0"}}>
                              <div className="col-sm-12" style={{marginTop: "5px"}}>
                                <h1>{this.state.locale === 'ES' ? 'Día 1: 17 de noviembre (miércoles)' : 'DAY 1: 17 November 2021 (Wednesday)'}</h1>
                              </div>
                              {this.state.data && this.state.data.programDay1 && this.state.data.programDay1.map(program1 => {
                              return (
                              <div className="col-sm-12 col-xs-12" style={{ marginTop: "5px"}}>
                                <div className="col-sm-3 col-xs-12" style={{backgroundColor: program1.color}}>
                                  {program1.timestart && program1.timeend  ?
                                  <span>{moment.tz(program1.timestart, this.state.timezone).format('HH:mm')} - {moment.tz(program1.timeend, this.state.timezone).format('HH:mm')}</span>
                                  :
                                  <span  style={{height: 20}}>  </span>}
                                </div>

                                <div className="col-sm-9 col-xs-12" style={{backgroundColor: program1.color}}>
                                  <a href={program1.link} className="external-link" target="_blank"><strong>{program1.title}</strong></a>
                                </div>
                                { program1.subtitle }
                                <div className="col-md-3 col-xs-12"></div>
                                <div className="col-md-9 col-sm-12 col-xs-12">
                                  <div className="speakers-agenda row">
                                    {program1.speakers && program1.speakers.map( speaker => (
                                    <div className="speaker-modal">
                                      <SpeakersModalSmall speaker={speaker} />
                                    </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                              )
                              })}
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="day">
                        <h1 className="day-title">{this.state.locale === 'ES' ? 'Día 2: 18 de noviembre' : 'DAY 2: 18 November 2021'}</h1>
                        <div className="programme-day" style={{minHeight: "475px"}}>
                          <div className="col-lg-1 col-md-1 col-xs-12"></div>
                          <div className="col-lg-9 col-md-9 col-xs-12 " style={{bottom: 0,right: 0}}>
                            <div style={{margin:"25px 5px 0 0"}}>
                              <div className="col-sm-12" style={{marginTop: "5px"}}>
                                <h1>{this.state.locale === 'ES' ? 'Día 2: 18 de noviembre (jueves)' : 'DAY 2: 18 November 2021 (Thursday)'}</h1>
                              </div>
                              {this.state.data && this.state.data.programDay2 && this.state.data.programDay2.map(program1 => {
                              return (
                              <div className="col-sm-12" style={{ marginTop: "5px"}}>
                                <div className="col-sm-3" style={{backgroundColor: program1.color}}>
                                  {program1.timestart && program1.timeend   ?
                                  <span>{moment.tz(program1.timestart, this.state.timezone).format('HH:mm')} - {moment.tz(program1.timeend, this.state.timezone).format('HH:mm')}</span>
                                  :
                                  <span  style={{height: 20}}>  </span>}
                                </div>

                                <div className="col-sm-9" style={{backgroundColor: program1.color}}>
                                  <a href={program1.link} className="external-link" target="_blank"><strong>{program1.title}</strong></a>
                                </div>
                                { program1.subtitle }
                                <div className="col-sm-3"></div>
                                <div className="col-sm-9">
                                  <div className="speakers-agenda row">
                                    {program1.speakers && program1.speakers.map( speaker => (
                                    <div className="speaker-modal">
                                      <SpeakersModalSmall speaker={speaker} />
                                    </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                              )
                              })}
                            </div>
                          </div>
                        </div>
                      </div>



              </div>
          </div>


        );
    }
}

export default Forum2021Programme
