import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {getCrmReport} from '../../../actions/accountActions'
import Layout from '../../../components/admin/layout/Layout'
import {DatePicker as DatePickerComponent} from 'antd';
import moment from 'moment'
import VerticalChartColor from '../reports/VerticalChartColor'
import SimplePieChart from '../reports/SimplePieChart'
import ComponentToPrint from './ComponentToPrint'
import ReactToPrint, { PrintContextConsumer }  from "react-to-print";
import Button from '@material-ui/core/Button';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

const { RangePicker } = DatePickerComponent;

const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

function CrmReports(props){
    const [start, setStart] = useState(moment().subtract(30, 'd'));
    const [end, setEnd] = useState(moment());
    const [focusedInput, setFocusedInput] = useState(false);

    useEffect(() => {
        props.getCrmReport(start.format(), end.format())
    }, [])

       function handleDatesChange(event) {
        setStart(event[0])
        setEnd(event[1])
        let start = event[0];
        let end = event[1];
        props.getCrmReport(start.format(), end.format())
    }
    const componentRef = useRef([]);

  useEffect(() => {
    componentRef.current = new Array(7);
}, [])


    return (
        <Layout>
               <Grid container className={props.classes.root} spacing={2}  style={{background: 'white'}}>
                       <Grid item md={12} lg={12} xs={12} className="profile-breadcrumb" style={{margin: 10}}>
                         <div className="profile-breadcrumb">
                         <a href={"/internal"}><i className="fa fa-fw fa-chevron-left"></i></a>CRM Reporting
                          </div>
                       </Grid>
                        <Grid item md={3} lg={3} xs={6} className="text-center" style={{ margin: 30}}>
                <RangePicker
                    defaultValue={[start, end]}
                    onChange={handleDatesChange}
                    format = {'DD.MM.YYYY'}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => {
                        setFocusedInput(focusedInput)
                    }}/>
            </Grid>
                       {props.account.crmReport && <Grid container style={{padding: 15}}>

                       {props.account.crmReport.contacts && <Grid item md={12} lg={12} xs={12} container >
                      <Grid item md={12} lg={12} xs={12} >
                       <h4>Contacts</h4>
                       </Grid>
                       <Grid item md={3} lg={3} xs={6}>
                       <h3>{props.account.crmReport.contacts.totalContacts}</h3>
                       <p>Total No of Contacts</p></Grid>
                       <Grid item md={3} lg={3} xs={6}><h3>{props.account.crmReport.contacts.totalOrgs}</h3>
                       <p>Total No of Organizations</p></Grid>
                       <Grid item md={3} lg={3} xs={6}><h3>{props.account.crmReport.contacts.contacts}</h3>
                       <p>Contacts</p></Grid>
                       <Grid item md={3} lg={3} xs={6}><h3>{props.account.crmReport.contacts.orgs}</h3>
                       <p>Organizations</p></Grid>
                       </Grid>}

                       {props.account.crmReport.organizationType && <Grid item md={6} lg={6} xs={12} style={{marginTop: 30}} className="text-right">

                       <div style={{padding: "0px 10px"}}>
                         <ReactToPrint
                           trigger={() => <PhotoCameraIcon/>}
                           content={() => componentRef.current[0]}
                         />
                       <ComponentToPrint ref = {el => componentRef.current[0] = el}>
                         <h4 className="text-left">Organization type</h4>
                         <VerticalChartColor data={props.account.crmReport.organizationType} dataKey="type"/>
                       </ComponentToPrint>
                       </div>
                       </Grid>}

                       {props.account.crmReport.expertize && <Grid item md={6} lg={6} xs={12} style={{marginTop: 30}} className="text-right">
                        <div style={{padding: "0px 10px"}}>
                           <ReactToPrint
                        trigger={() => <PhotoCameraIcon/>}
                        content={() => componentRef.current[1]}
                      />
                       <ComponentToPrint ref = {el => componentRef.current[1] = el}>
                       <h4 className="text-left">Areas of expertise</h4>
                       <VerticalChartColor data={props.account.crmReport.expertize} dataKey="expertize"/>
                      </ComponentToPrint>
                       </div>
                       </Grid>}

                       {props.account.crmReport.interests && <Grid item md={6} lg={6} xs={12} style={{marginTop: 30}} className="text-right">
                       <div style={{padding: "0px 10px"}}>
                           <ReactToPrint
                        trigger={() => <PhotoCameraIcon/>}
                        content={() => componentRef.current[2]}
                      />
                       <ComponentToPrint ref = {el => componentRef.current[2] = el}>
                       <h4 className="text-left">Areas of interests</h4>
                       <VerticalChartColor data={props.account.crmReport.interests} dataKey="interests"/>
                        </ComponentToPrint>
                      </div>
                       </Grid>}
                       {props.account.crmReport.regions && <Grid item md={6} lg={6} xs={12} style={{marginTop: 30}} className="text-right">
                       <div style={{padding: "0px 10px"}}>
                     <ReactToPrint
                        trigger={() => <PhotoCameraIcon/>}
                        content={() => componentRef.current[3]}
                      />
                       <ComponentToPrint ref = {el => componentRef.current[3] = el}>
                       <h4 className="text-left">Regions</h4>
                       <SimplePieChart data={props.account.crmReport.regions}/>
                        </ComponentToPrint>
                      </div>
                       </Grid>}

                        {props.account.crmReport.topLocations && <Grid item md={6} lg={6} xs={12} style={{marginTop: 30}} className="text-right">
                         <div style={{padding: "0px 10px"}}>
                      <ReactToPrint
                        trigger={() => <PhotoCameraIcon/>}
                        content={() => componentRef.current[4]}
                      />
                       <ComponentToPrint ref = {el => componentRef.current[4] = el}>
                       <h4 className="text-left">Top 20 countries</h4>
                       <SimplePieChart data={props.account.crmReport.topLocations}/>
                        </ComponentToPrint>
                  </div>
                       </Grid>}

                        {props.account.crmReport.globalCompact && <Grid item md={6} lg={6} xs={12} style={{marginTop: 30}} className="text-right">
                         <div style={{padding: "0px 10px"}}>
                      <ReactToPrint
                        trigger={() => <PhotoCameraIcon/>}
                        content={() => componentRef.current[5]}
                      />
                       <ComponentToPrint ref = {el => componentRef.current[5] = el}>
                        <h4 className="text-left">Global Compact Signatories</h4>
                       <SimplePieChart data={props.account.crmReport.globalCompact}/>
                        </ComponentToPrint>
                           </div>
                       </Grid>}

                        {props.account.crmReport.globalCompactYes && <Grid item md={6} lg={6} xs={12} style={{marginTop: 30}} className="text-right">
                       <div style={{padding: "0px 10px"}}>
                         <ReactToPrint
                        trigger={() => <PhotoCameraIcon/>}
                        content={() => componentRef.current[6]}
                      />
                       <ComponentToPrint ref = {el => componentRef.current[6] = el}>
                        <h4 className="text-left">Global Compact Yes</h4>
                       <SimplePieChart data={props.account.crmReport.globalCompactYes}/>
                        </ComponentToPrint>
                                           </div>
                       </Grid>}

                       </Grid>}
               </Grid>
        </Layout>
       );

}

CrmReports.propTypes = {
  account: PropTypes.object.isRequired,
  getCrmReport: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  account: state.account,
});

CrmReports = withStyles(styles)(CrmReports)

export default connect( mapStateToProps, { getCrmReport })(withRouter(CrmReports));
