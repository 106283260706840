import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentProfile } from '../../actions/profileActions';
import Spinner from '../common/Spinner';
import ProfileActions from './ProfileActions';
import Navbar from '../layout/Navbar';
import Footer from '../layout/Footer';
import Grid from '@material-ui/core/Grid';
import logoSide from '../../img/logo-side-menu.png';
import LoginForm from '../layout/LoginForm'

class Dashboard extends Component {

    componentDidMount() {
       this.props.getCurrentProfile();
    }


        render() {
        const { user } = this.props.auth;
        const { profile, loading } = this.props.profile;
        let dashboardContent;
        if (profile === null || loading) {
        dashboardContent = <Spinner />;
        } else {
        // Check if logged in user has profile data
        if (Object.keys(profile).length > 0) {

        dashboardContent = (
<div>
    <p className="lead text-muted">
        Welcome, user!
    </p>
    <ProfileActions profile={profile}/>

    <div style={{ marginBottom: '60px' }} />

</div>
                        );
                } else {
        // User is logged in but has no profile
        dashboardContent = (
<div>
    <p className="lead text-muted">Welcome {user.username}</p>
    <p>You have not yet setup a profile, please add some info</p>
    <Link to="/" className="btn btn-lg btn-info">
    Create Profile
    </Link>
</div>
                );
        }
        }
        return (
<div className="container-fluid" >
            <div className="page-content slide page-animation">
                <div className="col-md-12 frontend-user-panel ">
                    <div className="frontend-user-panel-account">
                        <div className="container">
                            <div className="col-md-12">
                                <h1>
                                    <span className="ng-binding">Composity User</span>
                                </h1>
                                <a className="logout" href="/logout" target="_self">Sign out</a>
                                <div className="pull-right">
                                    <LoginForm role="contribute"/></div>
                                    <div className="members-menu">
                                        <Link to={"/forum/contributions.html"}>Contributors forum</Link>
                                        <Link to={"/forum.html"}>Members forum</Link>
                                        <Link to={"/users/member"}>Members list</Link>
                                        <Link to={"#"}>Sustainable assessments</Link>
                                        <Link to={"#"}>Document мanagement</Link>
                                    </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="col-md-12 frontend-user-panel-menu">
                                <Link to={"/contribute/profile"}>Your profile</Link>
                                <Link to={"/contribute/profile/change-password"}>Change password</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix "></div>
                <div>
                    <div className="col-lg-12">
                        <div style={{position: 'fixed', bottom: '0px', right: '10px', minWidth: '400px', zIndex: 9000}}>
                            <div className="system-alert "></div>
                        </div>
                        </div>
                        </div>
                        <div className="welcome-page page-content ">
                        <div className="container text-center">
                        <img className="welcome-page-logo" src={logoSide} alt="logo" style={{width: 150, height: 150}}/>
                        <h1>Welcome!</h1><h4 className="col-md-10 col-md-offset-1">
                            As a member of the informal Interagency SPHS Task Team,
                            you are now able to use the redesigned online platform, as an Interagency Forum in which we can all connect
                            and share knowledge on ideas, projects, events and best practices related to the SPHS.  Kindly always clearly
                            label all files you may upload.
                        </h4>
                        <h4 className="col-md-10 col-md-offset-1">
                            You can explore our key discussions (Members Forum), SPHS key knowledge products, as well as upload
                            contributions to the public platform. This platform has been redesigned to ease your participation and
                            enhance our collaboration on the SPHS. </h4>
                            <h4 className="col-md-10 col-md-offset-1">
                            For further information on the platform or for help with technical issues, please contact the Secretariat:
                            <a href="mailto:info@savinglivesustainably.org " target="_top"> info@savinglivesustainably.org</a>
                        </h4>
                    </div>
                </div>
            </div>




         <Navbar/>
        </div>
);
}
}

Dashboard.propTypes = {
                getCurrentProfile: PropTypes.func.isRequired,
                auth: PropTypes.object.isRequired,
                profile: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
                profile: state.profile,
                auth: state.auth
});

export default connect(mapStateToProps, { getCurrentProfile })(Dashboard);
