import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import Popup from '../../../common/Popup';


export default class PopupQuest extends Component {
    render() {
        const id = 'popupQuest';
         const settings = this.props.settings;
        return (
            <Popup locale={this.props.settings.locale}
                   title={'Sustainability Assessment Tool'}
                   onClose={() => this.props.closePopup('popupQuest')}>
                <ul className="">
                 <li className="col-sm-6" >
                                <Link className="draggable" title={"Questionnaires"}
                                   to={ '/admin/questionnaire/active'}>
                                    <span>Questionnaires</span>
                                </Link>
                            </li>
                            <li className="col-sm-6" >
                                <Link className="draggable" title={"Questionnaire Partitions"}
                                   to={ '/admin/partitions'}>
                                    <span>Questionnaire Partitions</span>
                                </Link>
                            </li>
                            <li className="col-sm-6" >
                                <Link className="draggable" title={"Results"}
                                   to={ '/admin/quest-results'}>
                                    <span>Results</span>
                                </Link>
                            </li>
                             <li className="col-sm-6" >
                                <Link className="draggable" title={"Products"}
                                   to={ '/admin/products'}>
                                    <span>Products</span>
                                </Link>
                            </li>
                            <li className="col-sm-6" >
                                <Link className="draggable" title={"Products Categories"}
                                   to={ '/admin/products-category'}>
                                    <span>Products Categories</span>
                                </Link>
                            </li>
                            {/*<li className="col-sm-6" >
                                <Link className="draggable" title={"Units"}
                                   to={ '/admin/measures'}>
                                    <span>Units</span>
                                </Link>
                            </li>*/}
                            <li className="col-sm-6" >
                                <Link className="draggable" title={"Procurements"}
                                   to={ '/admin/procurements'}>
                                    <span>Procurements</span>
                                </Link>
                            </li>
                </ul>
            </Popup>
        )
    }
}
