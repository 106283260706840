import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

class Section extends Component {
  constructor() {
    super();
    this.state = {
      
    };
  }

     componentDidMount() {
       
    }

    componentWillReceiveProps(nextProps) {

    }


  render() {
  
   
     const { classes } = this.props;



    return (           
      <Grid container spacing={0} >
   
      </Grid>
    );
  }
}

Section.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

Section = withStyles(styles)(Section)

export default connect( mapStateToProps, {  })(withRouter(Section));
