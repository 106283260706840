import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Layout from '../../../components/admin/layout/Layout'
import Dropzone from "react-dropzone";
import { uploadExcelFile } from '../../../actions/documentActions'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom';
import { getConventionsReportData } from '../../../actions/questionnaireActions'
import PieCharts from './PieChart'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

class GPUConventionsReport extends Component {
  constructor() {
    super();
    this.state = {
      count: 10
    };
  }

    componentDidMount() {
       this.props.getConventionsReportData()
    }

      handleChange = (file) => {
       if(typeof file[0] !== 'undefined'){
         var body = new FormData();
         body.append('file', file[0])
         this.props.uploadExcelFile('/api/admin/import/excel', body)
         this.props.getConventionsReportData()
       }
     }

    onDropRejected = () => {
         toast.error('Upload error - your file is too big or in wrong format.', {autoClose: 10000})
    }

    changeSelect = (event) => {
        this.setState({
            count: event.target.value
        })
    }

  render() {

     const { classes } = this.props;
     const { reportConvention } = this.props.questionnaire;

    return (      <Layout>
      <Grid container spacing={0} style={{background: 'white'}}>
            <Grid item md={12} lg={12} xs={12} className="text-center">
                     <h1 className="m-top30">UNDP GPU Conventions</h1>
                     <Grid item md={12} lg={12} xs={12} className="text-center">
                     <Link to={'/admin/report/conventions-report'}>UNDP GPU Conventions</Link><br/>
                     <a href={'/template/Template_UNDP GPU Conventions.xlsx'}>Template_UNDP GPU Conventions</a>
            </Grid>
                       <div className="m-all30">
                                            <Dropzone
                                            maxSize={20000000}
                                            onDrop={acceptedFiles => this.handleChange(acceptedFiles)}
                                            onDropRejected={this.onDropRejected}
                                            >
                                            {({getRootProps, getInputProps}) => (
                                              <section style={{width: "fit-content",margin: "0 auto"}}>
                                                <div {...getRootProps()}>
                                                  <input {...getInputProps()} />
                                                  <button type="button" className="upload-btn btn btn-success">
                                                  <i style={{marginRight: 5}} className="fa fa-upload"></i> {'Upload file'}</button>
                                                </div>
                                              </section>
                                            )}
                                          </Dropzone>
                        </div>
{reportConvention && <Grid container style={{padding: "0px 15px"}}>
  <Grid container style={{marginTop: 50}}>
    <Grid item md={12} lg={12} xs={12}>
      <h3 className="m-top30"><b>STOCKHOLM CONVENTION</b></h3>
    </Grid>
    <Grid item md={6} lg={6} xs={12}>
      <h4 style={{marginTop: 20}}><b>% of procurements</b></h4>
      <PieCharts data={reportConvention.stocholmCount}/>
    </Grid>
    <Grid item md={6} lg={6} xs={12}>
      <h4 style={{marginTop: 20}}><b>% of procurements volume</b></h4>
      <PieCharts data={reportConvention.stocholmSum}/>
    </Grid>
  </Grid>
  <Grid container style={{marginTop: 50}}>
    <Grid item md={12} lg={12} xs={12}>
     <h3 className="m-top30"><b>MINAMATA CONVENTION</b></h3>
    </Grid>
    <Grid item md={6} lg={6} xs={12}>
       <h4 style={{marginTop: 20}}><b>% of procurements</b></h4>
      <PieCharts data={reportConvention.minamataCount}/>
    </Grid>
    <Grid item md={6} lg={6} xs={12}>
      <h4 style={{marginTop: 20}}><b>% of procurements volume</b></h4>
      <PieCharts data={reportConvention.minamataSum}/>
    </Grid>
  </Grid>
  <Grid container style={{marginTop: 50}}>
    <Grid item md={12} lg={12} xs={12}>
     <h3 className="m-top30"><b>VIENNA CONVENTION AND MONTREAL PROTOCOL</b></h3>
    </Grid>
       <Grid item md={6} lg={6} xs={12}>
         <h4 style={{marginTop: 20}}><b>% of procurements</b></h4>
         <PieCharts data={reportConvention.viennaCount}/>
      </Grid>
       <Grid item md={6} lg={6} xs={12}>
         <h4 style={{marginTop: 20}}><b>% of procurements volume</b></h4>
         <PieCharts data={reportConvention.viennaSum}/>
       </Grid>
   </Grid>
   <Grid container style={{marginTop: 50}}>
     <Grid item md={12} lg={12} xs={12}>
      <h3 className="m-top30"><b>BASEL CONVENTION</b></h3>
    </Grid>
      <Grid item md={6} lg={6} xs={12}>
       <h4 style={{marginTop: 20}}><b>% of procurements</b></h4>
       <PieCharts data={reportConvention.baselCount}/>
    </Grid>
    <Grid item md={6} lg={6} xs={12}>
      <h4 style={{marginTop: 20}}><b>% of procurements volume</b></h4>
      <PieCharts data={reportConvention.baselSum}/>
    </Grid>
  </Grid>
  <Grid container style={{marginTop: 50}}>
    <Grid item md={12} lg={12} xs={12}>
     <h3 className="m-top30"><b>ROTTERDAM CONVENTION</b></h3>
    </Grid>
      <Grid item md={6} lg={6} xs={12}>
        <h4 style={{marginTop: 20}}><b>% of procurements</b></h4>
        <PieCharts data={reportConvention.roterdamCount}/>
      </Grid>
      <Grid item md={6} lg={6} xs={12}>
        <h4 style={{marginTop: 20}}><b>% of procurements volume</b></h4>
        <PieCharts data={reportConvention.roterdamSum}/>
      </Grid>
  </Grid>
  <Grid container style={{marginTop: 50}}>
    <Grid item md={1} lg={1} style={{marginTop: 10}}></Grid>
    <Grid item md={8} lg={8} xs={12} ><h3 className="pull-left"><b>Top list of products that do not comply with the International Convention</b></h3></Grid>
    <Grid item md={2} lg={2} xs={12}>
                    <select className="pull-right" value={this.state.count} onChange={this.changeSelect}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>
    </Grid>
  </Grid>
  <Grid container style={{marginTop: 10}}>
                    <Grid item md={1} lg={1} style={{marginTop: 10}}></Grid>
                    <Grid item md={2} lg={2} xs={6} style={{marginTop: 10}}>
                    <Table className={classNames("m-bot30 mobileTable", classes.table)} aria-label="simple table" style={{border: '1px solid #A9A9A9'}}>
                     <TableHead>
                        <TableRow>
                          <TableCell align="center" colSpan={2}>
                            STOCKHOLM CONVENTION
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Product</TableCell>
                          <TableCell align="right">Procurement Volume (USD)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                       {reportConvention.productsSt && reportConvention.productsSt.map((row, key) => (
                         <>
                          {this.state.count > key && <TableRow key={key}>
                          <TableCell align="right">{row.product}</TableCell>
                          <TableCell align="right">{row.stockholm}</TableCell>
                          </TableRow>}
                          </>
                       ))}
                    </TableBody>
                   </Table>
                   </Grid>

                    <Grid item md={2} lg={2} xs={6} style={{marginTop: 10}}>
                    <Table className={classNames("m-bot30 mobileTable" , classes.table)} aria-label="simple table"  style={{border: '1px solid #A9A9A9'}}>
                     <TableHead>
                        <TableRow>
                          <TableCell align="center" colSpan={2}>
                            MINAMATA CONVENTION
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Product</TableCell>
                          <TableCell align="right">Procurement Volume (USD)</TableCell>
                        </TableRow>
                     </TableHead>
                            <TableBody>
                           {reportConvention.productsMin && reportConvention.productsMin.map((row, key) => (
                             <>
                              {this.state.count > key &&  <TableRow key={key}>
                              <TableCell align="right">{row.product}</TableCell>
                              <TableCell align="right">{row.minamata}</TableCell>
                              </TableRow>}
                              </>
                           ))}
                        </TableBody>
                   </Table>
                   </Grid>
                   <Grid item md={2} lg={2} xs={6} style={{marginTop: 10}}>
                   <Table className={classNames("m-bot30 mobileTable" , classes.table)} aria-label="simple table" style={{border: '1px solid #A9A9A9'}}>
                     <TableHead>
                        <TableRow>
                          <TableCell align="center" colSpan={2}>
                            VIENNA CONVENTION
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Product</TableCell>
                          <TableCell align="right">Procurement Volume (USD)</TableCell>
                        </TableRow>
                     </TableHead>
                            <TableBody>
                           {reportConvention.productsVien && reportConvention.productsVien.map((row, key) => (
                          <>
                              {this.state.count > key && <TableRow key={key}>
                              <TableCell align="right">{row.product}</TableCell>
                              <TableCell align="right">{row.vienna}</TableCell>
                              </TableRow>}
                              </>
                           ))}
                        </TableBody>
                   </Table>
                    </Grid>

                     <Grid item md={2} lg={2} xs={6} style={{marginTop: 10}}>
                    <Table className={classNames("m-bot30 mobileTable" , classes.table)} aria-label="simple table" style={{border: '1px solid #A9A9A9'}}>
                     <TableHead>
                        <TableRow>
                          <TableCell align="center" colSpan={2}>
                            BASEL CONVENTION
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Product</TableCell>
                          <TableCell align="right">Procurement Volume (USD)</TableCell>
                        </TableRow>
                     </TableHead>
                            <TableBody>
                           {reportConvention.productsBas && reportConvention.productsBas.map((row, key) => (
                            <>
                              {this.state.count > key &&  <TableRow key={key}>
                              <TableCell align="right">{row.product}</TableCell>
                              <TableCell align="right">{row.basel}</TableCell>
                              </TableRow>}
                              </>
                           ))}
                        </TableBody>
                   </Table>
                   </Grid>
                   <Grid item md={2} lg={2} xs={6} style={{marginTop: 10}}>
                   <Table className={classNames("m-bot30 mobileTable" , classes.table)} aria-label="simple table" style={{border: '1px solid #A9A9A9'}}>
                     <TableHead>
                        <TableRow>
                          <TableCell align="center" colSpan={2}>
                            ROTTERDAM CONVENTION
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Product</TableCell>
                          <TableCell align="right">Procurement Volume (USD)</TableCell>
                        </TableRow>
                     </TableHead>
                    <TableBody>
                       {reportConvention.productsRot && reportConvention.productsRot.map((row, key) => (
                        <>
                          {this.state.count > key &&  <TableRow key={key}>
                          <TableCell align="right">{row.product}</TableCell>
                          <TableCell align="right">{row.rotterdam}</TableCell>
                          </TableRow>}
                          </>
                       ))}
                    </TableBody>
                   </Table>
                    </Grid>
                    </Grid>

                     </Grid>}
                     </Grid>
      </Grid>
       </Layout>
    );
  }
}

GPUConventionsReport.propTypes = {
  uploadExcelFile: PropTypes.func.isRequired,
  getConventionsReportData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  questionnaire: state.questionnaire,
});

GPUConventionsReport = withStyles(styles)(GPUConventionsReport)

export default connect( mapStateToProps, { uploadExcelFile, getConventionsReportData })(withRouter(GPUConventionsReport));
