import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Comment from './Comment';

class CommentsByUser extends React.Component {

    componentDidMount() {
    }

    render() {
        return  <div>
            <div className="single-message">
                <div className="col-md-1 col-xs-2 image-container">
                   <img src={this.props.list.length ? this.props.list[0].avatar : ''}/>
                </div>
                <div className="col-md-11 col-xs-10 content-container">
                    <div
                        className="author">{this.props.list.length ? this.props.list[0].createdBy : ''}</div>
                    {
                        this.props.list.map(function (comment, i) {
                            return (
                                <Comment comment={comment} key={i} type={this.props.type}/>
                            )
                        }.bind(this))
                    }
                </div>


                <div className="clearfix"></div>
            </div>
        </div>
    }
}

const mapStateToProps = state => ({
   
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CommentsByUser);
