import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {onSelect2InputChange, onAddSelectOption} from '../../actions/formActions';
import InputLabel from '@material-ui/core/InputLabel'
import {renderFromHelper} from './MaterialUiForm'
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
//
const createOption = (label: string) => ({
  label,
  value: 'newCreatedOption',
});

class Select2Creatable extends React.Component {

    constructor(props) {
        super(props);
        this.loadOptions = this.loadOptions.bind(this);
    }

    componentDidMount() {
        if ((!this.props.input.value && this.props.input.value !== 0 && this.props.input.value !== false)&& (!this.props.field.settings || !this.props.field.settings.emptyOption) && this.props.field.options.length > 0) {
           //  this.props.input.onChange(this.props.field.options[0].value);
        }
    }

    onChange(newValue, actionMeta) {        
        if (newValue) {
            this.props.input.onChange(newValue);

            if (this.props.field.options.length > 0) {
                this.props.onNewOptions(this.props.field.options, newValue);
            }
        } else {
            this.props.input.onChange('');
        }
    }

     handleCreate = (inputValue: any) => {
     this.setState({ isLoading: true });


      const newOption = createOption(inputValue);
      
     let value = this.props.input.value;
     if(this.props.field.isMulti){
         if(value){
             value.push(newOption)
        }else{
            value = [newOption]
        }
     }else{
         value = newOption
     }

      this.props.onNewOptions([...this.props.field.options, newOption]);
      
      this.props.input.onChange(value);

  };

    loadOptions(inputValue, callback) {  
        if (!inputValue) inputValue = -1;
        let hasSettings = this.props.field.settings && this.props.field.settings.optionsUrl;
        this.props.onInputChange(this.props.meta.form, this.props.field.id, inputValue, this.props.field.settings.optionsUrl)
        .then(response => {
                let options = [];
                if (response) {
                    options = response.map(option => {
                        return {
                            ...option,
                            'value': option.value,
                            'label': option.label
                        };
                    });
                }
                this.options = options;
                if(options.length > 0) this.props.onNewOptions(options);
                else if(this.props.field.options.length > 0) this.props.onNewOptions(this.props.field.options);

                if(!hasSettings) {
                    let ops = this.props.field.options;
                    ops = ops.filter(item => item.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1);
                    callback(ops);
                }
                else callback(options ? options : this.props.field.options);
            });
    }

    render() {
    const { meta: {touched, error}, input } = this.props;

       return (<span>
          <InputLabel  style={{
          fontSize: 14,
          marginTop: 15,
          marginBottom: 16,
          textTransform: "uppercase",
          fontFamily: 'OpenSans ,sans-serif',
          color: "#333333"}} >{this.props.field.label}</InputLabel>
        {this.props.field.settings && this.props.field.settings.optionsUrl ?
         <AsyncCreatableSelect
                isMulti={this.props.field.isMulti}
                cacheOptions
                defaultOptions
                cacheOptions
                onChange={(value) => this.onChange(value)}
                onBlurResetsInput={false}
                onCloseResetsInput={false}
                isDisabled={this.props.field.disabled ? this.props.field.disabled : false}
                onFocus={this.props.input.onFocus}
                menuPosition= {this.props.field.position}
                menuPlacement={"auto"}
                loadOptions={this.loadOptions}
                defaultOptions={this.props.field.options}
                value={this.props.input.value}
                onCreateOption={this.handleCreate}
                style={{zIndex: 10000}}
                isClearable={true}    
      />

       :
                 <CreatableSelect
                isMulti={this.props.field.isMulti}
                value={this.props.input.value}
                onChange={(value) => this.onChange(value)}
                onBlurResetsInput={false}
                onCloseResetsInput={false}
                isDisabled={this.props.field.disabled ? this.props.field.disabled : false}
                menuPosition= {this.props.field.position}
                menuPlacement={"auto"}
                onFocus={this.props.input.onFocus}
                options={this.props.field.options}
                isClearable={true}
                onCreateOption={this.handleCreate}

      />


        }
                {error && error !== "undefined" && touched && (
           <span>{renderFromHelper({ touched, error })}</span>
          )}
        </span>);
    }
}

Select2Creatable.defaultProps = {
    onNewOptions: () => {},
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    onInputChange: (formId, id, value, route) => dispatch(onSelect2InputChange(formId, id, value, route)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Select2Creatable);
