import React, {Component} from 'react';
import {Field} from "redux-form";
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox'
import InputLabel from '@material-ui/core/InputLabel'
import {renderFromHelper} from './MaterialUiForm'

export default class CheckboxGroupField extends Component {
    render() {
        const    {input, meta, options, label } = this.props;
        const {name, onChange, onBlur, onFocus} = input;
        const {touched, error} = meta;
        const inputValue = input.value;

         const checkboxes = options.map(({label, value}, index) => {

      const handleChange = (event) => {
        const arr = [...inputValue];
        if (event.target.checked) {
          arr.push(String(value));
        }
        else {
          arr.splice(arr.indexOf(String(value)), 1);
        }
        onBlur(arr);
        return onChange(arr);
      };

      let checked = inputValue.includes(value);
      if(!checked){
          checked = inputValue.includes(String(value));
      }

       let disabled = false;
       if(this.props.field && this.props.field.disabled){
        disabled = this.props.field.disabled;  
       }
      return (
        <label key={`checkbox-${index}`} style={{display: 'block'}}>
          <Checkbox disabled={disabled} style={{padding: '3px'}} name={`${name}[${index}]`} value={String(value)} checked={checked} onChange={handleChange} onFocus={onFocus} />
          <span>{label}</span>
        </label>
      );
    });
        return <div>
         <InputLabel  style={{
          fontSize: 14,
          marginTop: 15,
          marginBottom: 16,
          textTransform: "uppercase",
          fontFamily: 'OpenSans ,sans-serif',
          color: "#333333"}} >{label}</InputLabel>
        <div>{checkboxes}</div>
        {error && error !== "undefined" && touched && (
           <span>{renderFromHelper({ touched, error })}</span>
          )}
      </div>;
    }
}
