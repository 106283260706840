import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { reduxForm } from "redux-form"
import Grid from "@material-ui/core/Grid"
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { viewAccountData, getProfileData } from "../../../actions/accountActions"
import Navbar from '../../layout/Navbar'
import Footer from '../../layout/Footer'
import classNames from 'classnames'
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Spinner from '../../common/Spinner'
import { getLogActivityWithGeoIp } from '../../../actions/profileActions';
import Results from './Results'

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: 20
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width: '100%',
    height: '100%'
  },
  list: {
    width: 1000,
  },
   labelData:{
    padding: "8px 17px 8px 0",
    color: "#777",
    textAlign: "right",
    width: "initial",
    fontWeight: "normal"
  },
});

class ViewAccessData extends Component {
  constructor() {
    super();
    this.state = {
    };
  }

     componentDidMount() {
         this.props.viewAccountData(this.props.match.params.token)
         this.props.getLogActivityWithGeoIp('Contacts page', 'GET', "Website usage")
    }

    getProfileData = (e) => {
         e.preventDefault();
     if(this.props.account.account){
         this.props.getProfileData(this.props.account.account.id);
     }
    }

    render() {
    const { classes } = this.props;
    const { account } = this.props.account;
    const columns = ['action', 'country', 'pathName', 'start', 'end', 'ip', 'timezone'];

    return (
      <div className="contacts">
         <Grid container className={classNames(classes.root, 'contacts-content')} direction="row" justify="flex-start">
         <Container maxWidth="lg">
        {account ?
        <Grid item container md={12} lg={12} xs={12} className="p-lr30" direction="row">
        <Grid item md={12} className="text-center">
        <h1>Welcome, {account.firstName} {account.lastName}</h1>
        <p>You can view and download stored data by the informal Interagency Task Team on Sustainable Procurement in the Health Sector (SPHS).</p>
        <p>The data presented on this page includes all available personal information by the time the request was submitted {account.dateRequested}.</p>
        </Grid>
        <Grid item md={6} lg={6} xs={12} className="main-box p-lr30 m-bot30">
            <h2 className="m-top30">Information</h2>
            {account.firstName && <p>First name: {account.firstName}</p>}
            {account.lastName && <p>Last name: {account.lastName}</p>}
            {account.parent && <p>Organization: {account.parent}</p>}
            {account.position && <p>Position: {account.position}</p>}
            {account.department && <p>Department: {account.department}</p>}
            {account.email && <p>Email: {account.email}</p>}
            {account.phone && <p>Phone: {account.phone}</p>}
            {account.contactPhone &&<p>Contact Phone: { account.contactPhone }</p>}
         {account.contactWebsite && <p>Contact Website: { account.contactWebsite }</p>}
         {account.contactLinkedIn && <p>LinkedIn: { account.contactLinkedIn }</p>}
         {account.contactSkype && <p>Contact Skype: { account.contactSkype }</p>}
          {account.addresses && account.addresses.length > 0  &&
            <div>{account.addresses.map((address, i) => (
            <div key={i}>
            {address.city &&
                   <p>City: {address.city}</p>
           }
           {address.country &&
                   <p>Country:  {address.country}</p>
           }
           {address.addrLine1 &&
                   <p>Address:  {address.addrLine1}</p>
           }
           </div>
           ))}</div>
         }
         </Grid>
         <Grid item md={6} lg={6} xs={12} className="main-box p-lr30 m-bot30">
            <h2 className="m-top30">Consent</h2>
    {account.consents.length > 0 && account.consents.map((consent, key) => {
     return <p key={key}>{consent.type} - Yes</p>
    })}
        <div style={{marginTop: 100}}>
            <div className="bottom-text">
            <h3>Download your data</h3>
            <p>Export an archive of your entire account's data to your device.</p>
                <Button href="#" variant="outlined" color="primary" onClick={this.getProfileData}>
                   Download
                </Button>
                <p style={{marginTop: 10}}>Note: Downloading your data does not delete it from SPHS’s servers.</p>
                <p  style={{marginTop: 30}}>This page will expire on the {account.dateExpired}.</p>
            </div>
          </div>
        </Grid>
       
         </Grid>
         :
         <Spinner/>
         }
          </Container>
           {account && account.userActivityData &&
                <Grid item md={12} lg={12} xs={12} className="main-box p-lr30 m-bot30">                
                  <Results data={account.userActivityData} columns={columns}/>                 
                </Grid>
                }
         </Grid>
         <Navbar/>
          <Footer/>
      </div>
    );
  }
}

ViewAccessData.propTypes = {
  viewAccountData: PropTypes.func.isRequired,
  getProfileData: PropTypes.func.isRequired,
  account:    PropTypes.object.isRequired,
  getLogActivityWithGeoIp: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
 account: state.account
});

ViewAccessData = withStyles(styles)(ViewAccessData)

export default connect( mapStateToProps, { viewAccountData, getProfileData, getLogActivityWithGeoIp })(withRouter(ViewAccessData));
