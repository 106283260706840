import client from './client';

import {
  GET_SECTOR,
  GET_SECTORS,
  GET_SECTORS_FILTER,
  GET_MAP_FILTER,
  SECTOR_LOADING,
  GET_ERRORS
} from './types';

// Get sectors
export const getSectors = () => dispatch => {    
  dispatch(setSectorLoading());
  client
    .get('/web/sectors')
    .then(res =>
      dispatch({
        type: GET_SECTORS,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_SECTORS,
        payload: {}
      })
       
  });
};

export const getSectorsFilter = () => dispatch => {    
  dispatch(setSectorLoading());
  client
    .get('/web/filter/focus/area')
    .then(res =>
      dispatch({
        type: GET_SECTORS_FILTER,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_SECTORS_FILTER,
        payload: {}
      })
       
  });
};

export const getMapFilter = () => dispatch => {    
  dispatch(setSectorLoading());
  client
    .get('/web/filter/interactive/map')
    .then(res =>
      dispatch({
        type: GET_MAP_FILTER,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_MAP_FILTER,
        payload: {}
      })
       
  });
};



// Sector loading
export const setSectorLoading = () => {
  return {
    type: SECTOR_LOADING
  };
};