import React, { useState, useEffect } from 'react';
import Layout from '../../../components/admin/layout/Layout'
import { getLockQuestionnaire, getQuestionnaire } from '../../../actions/questionnaireActions'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper';
import {Link} from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { amber } from '@material-ui/core/colors'
import classNames from 'classnames';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
   paper: {
    padding: 15,
    margin: 15,
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width: '100%',
    height: '100%',
    color: '#000000de'
  },
  warning: {
    backgroundColor: amber[700],
    marginBottom: 15,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
   message: {
    display: 'flex',
    alignItems: 'center',
  },
    });
    
function QuestionnaireLock(props) {  
 const [open, setOpen] = useState(false);
 useEffect(() => {
   props.getQuestionnaire(props.match.params.id)
  }, []);
  
  function handleConfirm() {console.log(props.history)
     props.getLockQuestionnaire(props.match.params.id, props.history)
     setOpen(false)     
  }
  
  return (
     <Layout>
     <Grid container className={props.classes.root} spacing={2} direction="row" justify="flex-start"  >
                    <Grid item md={12} lg={12} xs={12} className="profile-breadcrumb">
                      <div className="p-all20">
                        <Link to={"/admin/questionnaire/active"}><i className="fa fa-fw fa-chevron-left"></i>Questionnaires</Link>
                       </div>
                    </Grid>                    
                     <Grid md={12} lg={12} xs={12} item container justify="flex-start" direction="row">
                     <Paper className={props.classes.paper} >
                      <Grid container item md={12} lg={12} >
                        <Grid container item md={12} lg={12} >
                           <SnackbarContent
                                className={props.classes.warning}
                                message={
                                    <span id="client-snackbar" className={props.classes.message}>
                                      <WarningIcon className={classNames(props.classes.icon, props.classes.iconVariant)} />
                                      If the questionnaire is locked no further changes can be made. The questionnaire cannot be unlocked for editing!
                                    </span>
                                  }
                           />                        
                        </Grid>
                         <Grid container item md={12} lg={12} >
                           {props.questionnaire.quest && <h4>Questionnaire name: {props.questionnaire.quest.name}</h4>} 
                             </Grid>
                         <Grid container item md={12} lg={12} >
                            {props.questionnaire.quest.workName && <h4>Questionnaire alias: {props.questionnaire.quest.workName}</h4>} 
                           </Grid>
                            <Grid container item md={12} lg={12} >
                           <Button 
                            variant="outlined" 
                            color="secondary" 
                            size="small"
                            onClick={() => setOpen(true)} 
                             className={'m-all30'}>
                             Lock questionnaire
                           </Button>
                           </Grid>
                      </Grid>
                             </Paper>
                     </Grid>
                     </Grid>
                      <Dialog
                        onClose={() => setOpen(false)}
                        aria-labelledby="simple-dialog-title"
                        open={open}
                        maxWidth="sm"
                        fullWidth
                       >

                         <DialogContent>
                         <section className="newPanel">
                            <div className="panel-body">
                                <div>
                                    <div className="text-center">

                                        <h3>
                                            Are you sure?
                                        </h3>
                                        <button className="button-outlines green text-center"
                                                onClick={() => setOpen(false)}>
                                            No
                                        </button>
                                        <button className="button-primary-edited text-center btn-ok"
                                                onClick={handleConfirm}>
                                           Yes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>
                         </DialogContent>
                      </Dialog>
    </Layout>
  );
}

QuestionnaireLock.propTypes = {
    getLockQuestionnaire: PropTypes.func.isRequired,
    getQuestionnaire: PropTypes.func.isRequired,
    questionnaire: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
        questionnaire: state.questionnaire
    });

QuestionnaireLock = withStyles(styles)(QuestionnaireLock)

export default connect(mapStateToProps, {getLockQuestionnaire, getQuestionnaire})(withRouter(QuestionnaireLock));

