import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'
import { renderFromHelper } from './MaterialUiForm'

const styles = theme => ({
  formControl: {
    margin: theme.spacing(0.5),
  }
});


class RadioButtonsGroup extends React.Component {
    constructor(props) {
            super(props);
            this.state = {
                value: ''
            }
        }

        componentDidMount() {

        if(this.props.meta.initial){
this.setState({value: this.props.meta.initial})
        }
        }

   handleChange = event => {
    this.setState({value: event.target.value});
  };

  render(){

const { classes, input, label, options, meta: {error, touched}, rest } = this.props;
let disabled = false;
       if(this.props.field && this.props.field.disabled){
        disabled = this.props.field.disabled;  
       }
  return (
        <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel style={{fontSize: 14, marginTop: 15, marginBottom: 16, textTransform: "uppercase",fontFamily: 'OpenSans ,sans-serif',
        color: "#333333"}}>{label}</FormLabel>
        <RadioGroup aria-label={label} name={input.name} value={this.state.value}  {...input}>
        {options.map((field, key) => {
          return <FormControlLabel
          value={String(field.value)}
          key={key}
          control={<Radio />}
          label={field.label}
          checked={field.value == String(this.state.value) ? true : false}
          onChange={this.handleChange}
          style={{fontSize: 14,fontFamily: 'OpenSans ,sans-serif'}}
          disabled={disabled}    
          />
          })}

        </RadioGroup>
        {renderFromHelper({ touched, error })}
      </FormControl>

  );
    }
  }

  RadioButtonsGroup = withStyles(styles)(RadioButtonsGroup)
  export default RadioButtonsGroup;
