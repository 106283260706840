import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentProfile, getMemberProfile } from '../../actions/profileActions';
import Spinner from '../common/Spinner';
import ProfileActions from './ProfileActions';
import Navbar from '../layout/Navbar';
import Grid from '@material-ui/core/Grid';
import logoSide from '../../img/logo-side-menu.png';



class MemberProfile extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getCurrentProfile();
        this.props.getMemberProfile(this.props.match.params.id);
    }


    render() {
        const { profile, loading, memberProfile } = this.props.profile;
        let dashboardContent;
        let profileAnalyses=[];
        if (profile === null || loading) {
            dashboardContent = <Spinner />;
        }else{
            dashboardContent = <ProfileActions profileItem={profile}/>
        }

        if(memberProfile){
            profileAnalyses = Object.entries(memberProfile.analyses).map(function([key,value]){
                                                    return <div className="col-md-12">
                                                        <div className="col-md-4">
                                                            <strong>{ key }:</strong>
                                                        </div>
                                                        <div className="col-md-8">
                                                           <p>{ value }</p>
                                                        </div>
                                                    </div>
                                                })

        }


        return (
      <div className="container-fluid" >
        <div className="page-content slide page-animation">
            <Grid container spacing={0}>
                <div>
                    <div className="page-content user-member-list">
                        {dashboardContent}
                        <div className="clearfix"></div>
                        <div className="user-member-user" style={{minHeight: 695}}>
                            <div className="max-width">
                                {memberProfile &&
                                    <div className="no-padding" >
                                        <div className="knowledge-and-practicee">
                                            <img className="leading-image col-md-2" src={ memberProfile.photo }/>

                                            <div className="col-md-9 text-left" style={{marginLeft: 15}}>
                                                <h2>{ memberProfile.name }</h2>

                                                <p>
                                                    <a href="mailto:{ memberProfile.email }?Subject=Hello%20" target="_top">{ memberProfile.email }</a>
                                                </p>
                                                <div className="col-md-6" >
                                                    {profileAnalyses}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <Navbar/>
                </div>
            </Grid>
          </div>
        </div>
        );
    }
}

MemberProfile.propTypes = {
                getCurrentProfile: PropTypes.func.isRequired,
                getMemberProfile: PropTypes.func.isRequired,
                auth: PropTypes.object.isRequired,
                profile: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
                profile: state.profile,
                auth: state.auth
});

export default connect(mapStateToProps, { getCurrentProfile, getMemberProfile })(MemberProfile);
