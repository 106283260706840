import client from './client';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify'
import { getLogActivityWithGeoIp } from './profileActions'

        import {
        GET_ERRORS,
                SET_CURRENT_USER,
                GET_REGISTER_DATA,
                GET_ERRORS_REGISTRATION,
                GET_MESSAGE,
                CLEAR_ERRORS,
                CLEAR_MESSAGE,
                REQUESTED_ROUTE
                } from './types';

// Register User
export const registerUser = (userData, history) => dispatch => {

        client
                .post('/web/registers/registrations', userData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
dispatch(getLogActivityWithGeoIp('register', 'POST', 'User tracking'));
                    dispatch({
                        type: GET_ERRORS_REGISTRATION,
                        payload: []
                    })
                    const {token, message} = res.data;
                    // Set token to ls
                    localStorage.setItem('jwtToken', token);
                    // Set token to Auth header
                    setAuthToken(token);
                    // Decode token to get user data
                    const decoded = jwt_decode(token);
                    // Set current user
                    dispatch(setCurrentUser(decoded));
                    toast.success(message, {autoClose: 10000})
                    history.push('/contribute/welcome')

                })
                .catch(err => {
                    if (err.response && err.response.data) {
                        const {error} = err.response.data;

                        if (typeof error != 'undefined' && error.message) {
                            toast.error(error.message, {autoClose: 10000})
                        }
                        dispatch({
                            type: GET_ERRORS_REGISTRATION,
                            payload: err.response.data
                        })
                    }
                });
    };

// Login - Get User Token
export const loginUser = (userData, history, path) => dispatch => {

        client
                .post('/login', userData)
                .then(res => {
                    dispatch(getLogActivityWithGeoIp('login', 'POST', 'User tracking'));
                    // Save to localStorage
                    const {token} = res.data;
                    // Set token to ls
                    localStorage.setItem('jwtToken', token);
                    // Set token to Auth header
                    setAuthToken(token);
                    // Decode token to get user data
                    const decoded = jwt_decode(token);
                    // Set current user
                    dispatch(setCurrentUser(decoded));
                    if(path){
                        dispatch(clearRequestedPath())
                      history.push(path)
                    }else{
                    history.push('/contribute/welcome')
                }
                })
                .catch(err => {
                    dispatch({
                        type: GET_ERRORS,
                        payload: err.response.data
                    })
                });
    };
export const sendLogin = (userData) => dispatch => {

        client
                .post('/login', userData)
                .then(res => {
                    dispatch(getLogActivityWithGeoIp('login', 'POST', 'User tracking'));
                    // Save to localStorage
                    const {token} = res.data;
                    // Set token to ls
                    localStorage.setItem('jwtToken', token);
                    // Set token to Auth header
                    setAuthToken(token);
                    // Decode token to get user data
                    const decoded = jwt_decode(token);
                    // Set current user
                    dispatch(setCurrentUser(decoded));
                    
                    if (decoded.roles.includes('ROLE_QUESTIONNAIRE')) {
                        toast("An admin will send you questionnaire.", {autoClose: 10000,  position: "top-center",})
                        dispatch({
                            type: GET_MESSAGE,
                            payload: {message: 'Password success'}
                        })
                    } else {
                        dispatch({
                            type: GET_MESSAGE,
                            payload: {message: 'Password success without role'}
                        })
                    }
                })
                .catch(err => {
                    dispatch({
                        type: GET_ERRORS,
                        payload: {error: 'Invaid credentials.'}
                    })
                });
    };
export const loginLinkedin = (code, history) => dispatch => {

        client
                .get('/web/oauth/linkedin-connect?code=' + code)
                .then(res => {
                    dispatch(getLogActivityWithGeoIp('login', 'POST', 'User tracking'));
                    const {token} = res.data;
                    const {userMissed} = res.data;
                    const {error} = res.data;
                    if (typeof token !== 'undefined') {
                        // Set token to ls
                        localStorage.setItem('jwtToken', token);
                        // Set token to Auth header
                        setAuthToken(token);
                        // Decode token to get user data
                        const decoded = jwt_decode(token);
                        // Set current user
                        dispatch(setCurrentUser(decoded));
                        dispatch(getLogActivityWithGeoIp('Linkedin login', 'POST', 'User tracking'));
                        history.push('/contribute/welcome')
                    } else if (typeof userMissed !== 'undefined') {
                        toast.error(userMissed, {autoClose: 10000})
                        const {user} = res.data;
                        if (user) {
                            history.push('/contribute/register', {response: user})
                        } else {
                            history.push('/contribute/register')
                        }
                    } else {
                        toast.error('Try again!', {autoClose: 10000})
                        history.push('/login')
                    }
                })
                .catch(err => {
                    toast.error('Try again!', {autoClose: 10000})
                    history.push('/login')
                });


    };

// Set logged in user
export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};

// Log user out
export const logoutUser = () => dispatch => {
        // Remove token from localStorage
        localStorage.removeItem('jwtToken');
        // Remove auth header for future requests
        setAuthToken(false);
        // Set current user to {} which will set isAuthenticated to false
        dispatch(setCurrentUser({}));
        window.location = '/login'
    };


// Register data
export const getRegisterData = () => dispatch => {

        client
                .get('/web/register/form/data')
                .then(res => {
                    dispatch({
                        type: GET_REGISTER_DATA,
                        payload: res.data
                    })
                })
                .catch(err =>
                    dispatch({
                        type: GET_ERRORS,
                        payload: err.response.data
                    })
                );
    };


export const changePassword = (userData, id, history) => dispatch => {

        client
                .post('/api/change-password/' + id + '/change', userData)
                .then(res => {

                    toast.success(res.data, {autoClose: 10000})
                    history.push('/contribute/welcome')
                })
                .catch(err => {

                    toast.error(err.response.data.message, {autoClose: 10000})
                    dispatch({
                        type: GET_ERRORS,
                        payload: err.response.data
                    })
                });
    };

export const resetNewPassword = (userData, history) => dispatch => {
        client
                .post('/password/reset/confirm', userData)
                .then(res => {
                    toast.success(res.data, {autoClose: 10000})
                })
                .catch(err => {
                    toast.error(err.response.data, {autoClose: 10000, className: 'toast-error-wide'})
                });
    };

// Reset password
export const resetPassword = (email) => dispatch => {
        client
                .post('/password/request/reset', {email: email})
                .then(res => {
                    if (res.data) {
                        dispatch({
                            type: GET_MESSAGE,
                            payload: {message: res.data}
                        })
                    }

                    //  toast.success(res.data, {autoClose: 20000})
                    // history.push('/contribute/welcome')
                })
                .catch(err => {

                    if (err.response.data === "resetting.password_already_requested") {
                        dispatch({
                            type: GET_ERRORS,
                            payload: {error: "Password already requested!"}
                        })
                    } else if (err.response.data === "User not recognised") {
                        dispatch({
                            type: GET_ERRORS,
                            payload: {error: "User not recognised."}
                        })
                    }

                });

    };

export const requestedPath = (path) => dispatch => {
     dispatch({
            type: REQUESTED_ROUTE,
            payload: path
        })
};

export const clearRequestedPath = () => dispatch => {
     dispatch({
            type: REQUESTED_ROUTE,
            payload: null
        })
};

export const clearErrors = () => dispatch => {
        dispatch({
            type: CLEAR_ERRORS,
            payload: {}
        })
    };

export const clearMessage = () => dispatch => {
        dispatch({
            type: CLEAR_MESSAGE,
            payload: {}
        })
    };

    export const setMessage = (message) => dispatch => {
        dispatch({
            type: GET_MESSAGE,
            payload: {message: message}
        })
    };
