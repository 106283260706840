import client from './client';

import {
  GET_ORG_TYPES
} from './types';

// Get current sdg
export const getOrgTypes = () => dispatch => {  
  client
    .get('/web/filter/organization/type')
    .then(res =>
      dispatch({
        type: GET_ORG_TYPES,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_ORG_TYPES,
        payload: {}
      })
       
  });
};






