import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getSectorItem } from '../../../actions/sectorsActions';
import Grid from '@material-ui/core/Grid';



class SectorItem extends Component {

    render() {

        return (
               <Grid item md={3} sm={6} xs={12}>
                  {this.props.sectorItem &&

                                        <div className="sector">
                                            <a  href={this.props.sectorItem.href} >
                                                <img  src={this.props.sectorItem.photo} alt={`${this.props.sectorItem.name}'s picture`}/>
                                                <div className="center-align">
                                                    <img className="icon" alt={`${this.props.sectorItem.name}'s icon`} src={this.props.sectorItem.icon}/>
                                                    <h4 >{this.props.sectorItem.name}</h4>
                                                </div>
                                            </a>
                                        </div>

                    }

              </Grid>
                );
    }
}

SectorItem.propTypes = {
    sectorItem: PropTypes.object.isRequired,
};


export default connect()(SectorItem);
