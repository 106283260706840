import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import logo from '../../img/logo.png';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuList from '@material-ui/core/MenuList';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  paper: {
    top: 0,
  },
  rootList: {
    display: "flex",
    margin: "0 auto",
    width: "100%",
  },
  rootItem:{
    fontSize: 14,
    color: "#abc8db",
    marginLeft: 20,
    background: '#213648',
    textDecoration: 'none',
    fontFamily: "OpenSans, sans-serif",
    '&:hover, &:focus': {
      backgroundColor: "transparent",
      outline: "none",
    }
  },
   rootItemSmall:{
    fontSize: 14,
    color: "#abc8db",
    height: 20,
    marginLeft: 20,
    background: '#213648',
    textDecoration: 'none',
    fontFamily: "OpenSans, sans-serif",
    margin: "0 auto",
    '&:hover, &:focus': {
      color: "#abc8db",
     backgroundColor: "transparent",
     outline: "none",
   }
 },
  bottomArea: {
    position: 'fixed',
    padding: 0,
    bottom: 0,
    width: '100%',
    height: '12%',
    background: '#33ad50',
    background: '-webkit-linear-gradient(right,#33ad50,#069c95)',
    background: '-o-linear-gradient(left,#33ad50,#069c95)',
    background: '-moz-linear-gradient(left,#33ad50,#069c95)',
    background: 'linear-gradient(to left,#33ad50,#069c95)',
    marginTop: '30px'
},
link: {
    color: '#abc8db',
    padding: '35px',
    fontSize: '22px',
    '&:hover': {
       color: '#abc8db',
      textDecoration: 'none',
    }
},
rootSmallItem:{
    color: '#abc8db',
    fontSize: '14px',
    textDecoration: 'none',
    fontFamily: "OpenSans, sans-serif",
    marginLeft: 20,
    '&:hover': {
      background: '#213648',
      textDecoration: 'none',
    }
  }
});



const ITEM_HEIGHT = 48;


class MenuNavbar extends Component {

    state = {
        anchorEl: null,
        height: 0,
        showThirdMenu: false
    };

    componentDidMount() {
  this.updateWindowDimensions();
  window.addEventListener('resize', this.updateWindowDimensions);
  window.addEventListener('scroll', this.handleScroll);
  window.addEventListener('mousedown', this.handleClickOutside);
}

componentWillUnmount() {
  window.removeEventListener('resize', this.updateWindowDimensions);
}

updateWindowDimensions = () => {
  this.setState({ height: window.innerHeight });
}

    handleClick = event => {
        event.preventDefault()

     this.setState({ anchorEl: event.currentTarget });
    };
    handleClose = () => {
        this.setState({ anchorEl: null , showThirdMenu: false});
    };

      showMenu = () => {
          this.setState(state => ({ showThirdMenu: true }));
        };

      handleScroll = () => {
        this.setState({  anchorEl: null  , showThirdMenu: false});
      }


  render() {
   const { classes } = this.props;

      let itemClass = classes.rootItem
      if(this.state.height < 820){
          itemClass = classes.rootItemSmall
      }
  return (
    <div style={{top: 0}} >

        <center   className="menu-button">
        <a
        href="#"
          onClick={this.handleClick}
          className="login-link">
       Global forums
     </a>
        </center>

        <Menu
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleClose}
            style={{top: 45, left: 0, right: 0}}
            height="30%"
            autoFocus= { false }
            PaperProps={{
            style: {
              background: '#213648',
              position: 'absolute',
              overflow:'hidden',
              width: "100%",
              maxWidth: "100%",
            }
          }}
          MenuListProps={{  className: classes.rootList }}
            >

            {this.state.showThirdMenu === false &&
              <span className="third-menu">
              <MenuItem onClick={this.handleClose} component={Link} to="/global-virtual-forum" classes={{root: itemClass}}>Global Forum 2020</MenuItem>
              <MenuItem onClick={this.handleClose} component={Link} to="/africa-forum" classes={{root: itemClass}}>Africa forum 2019</MenuItem>
              <MenuItem onClick={this.handleClose} component={Link} to="/asia-forum" classes={{root: itemClass}}>Asia forum 2018</MenuItem></span>
            }
        </Menu>
    </div>





                        );
                }
                }


MenuNavbar = withStyles(styles)(MenuNavbar)
export default MenuNavbar;
