import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames';
import Navbar from '../layout/Navbar';
import Grid from '@material-ui/core/Grid';
import Footer from '../layout/Footer'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import Icon from '@material-ui/core/Icon';
import { getMembers } from '../../actions/membersActions';
import MembersItem from './elements/MembersItem';
import Link from '@material-ui/core/Link';
import { config } from '../../config';
import { getLogActivityWithGeoIp } from '../../actions/profileActions';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  icon: {
    fontSize: 56,
  },
});


class WhoWeAre extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        this.props.getMembers();
        window.resizeTo(250, 250);
        this.props.getLogActivityWithGeoIp('Who We Are page', 'GET', "Website usage")
    }

    render() {
        const { classes } = this.props;
        const { members} = this.props.member;
        let images = ['/images/org/203345.png?v1.0', '/images/org/fbf125.png?v1.0', '/images/org/10dad3.png?v1.0', '/images/org/099868.png?v1.0', '/images/org/d20eed.png?v1.0',  '/images/org/af3078.png?v1.0', '/images/org/7a70ab.png?v1.0', '/images/org/21d99d.png?v1.0' , '/images/org/3baf34.png?v1.0', '/images/org/ab22d6.png?v1.0']

        return (
                       <div className={classes.root}>
      <Grid container spacing={0}>
<div  className="page-content  slideup page-animation" >

    <div className="who-we-are-page ">
    <div className="heroshot">
        <img src={config.API_URL + "/images/org/580cde.png?v1.0"} alt="member"/>
          <Grid container
                direction="row"
                justify="flex-start"
                className="text-area max-width">
            <Grid item xs={12} md={8} className="text-left">
              <Icon className={classNames(classes.icon, 'fa fa-quote-left', "text-light-gray")} />
                    <h1 className="text-light-gray">
                        By engaging with Suppliers and Manufacturers to Promote Environmentally and Socially Responsible Procurement of Health Commodities, we can save lives sustainably and protect the planet.
                    </h1>
                    <h4 className="text-white">
                        <a className="text-light-gray" href="/news/79" style={{textDecoration: "underline"}}>
                    Statement of Intent by the Heads of Agencies/Principles</a>
                    </h4>
                </Grid>
        </Grid>
        <div className="bounce">
        <a href="#first-section">
          <Icon className={classNames(classes.icon, 'fa fa-angle-down text-white')}/>
        </a>
        </div>
          </div>
        <div className="max-width about text-center">
            <a className="anchor-link" id="first-section"><h1>Who we are</h1></a>

            <h4>The UN informal Interagency Task Team on Sustainable Procurement in
            <br/> the Health Sector (SPHS) was established in May 2012 in Copenhagen, Denmark,
            <br/>and is currently hosted by the United Nations Development Programme (UNDP)
            <br/> Istanbul Regional Hub. We bring together seven UN agencies and three multilateral
            <br/> health financing institutions operating in the global health aid market. Our annual<br/> cumulative purchasing power is estimated to be $5 billion, which represents a sizable <br/> portion of some segments of the global pharmaceutical and other health products<br/> markets.
            </h4>
        </div>
        <div className="noPadding image-area">
            {
                members &&

                    <div className="col-md-12 noPadding">
                    {
                        members.map((memberItem, key) => {

                            return <MembersItem key={key} index={key} memberItem={memberItem} image={images[key]} />
                        })
                    }
                    </div>

            }
        </div>
        <div className="about background-left">
            <div className="max-width">
                <Grid container direction="row" className="container">
                    <Grid item md={8} sm={6} className="text-left">
                        <h2>Our vision</h2>
                        <h4>By leveraging our members' normative mandate, joint procurement
                        volumes and technical expertise, we are committed to lowering the
                        environmental and social impact of our procurement, contributing to
                        a more sustainable global health sector and thereby to inclusive
                        sustainable economies, with the final aim of improving human health
                        and well-being.</h4>
                        <Grid  item md={6} className="white-box">
                            <h5 className="text-uppercase">OUR VALUE REFERENCES<hr/></h5>
                            <a className="text-uppercase" href="https://www.un.org/Depts/ptd/about-us/un-supplier-code-conduct" target="_blank" >
                                UN SUPPLIER CODE OF CONDUCT
                            </a>
                            <a className="text-uppercase" href="https://www.unglobalcompact.org/what-is-gc/mission/principles" target="_blank" >
                                THE TEN PRINCIPLES OF THE UN GLOBAL COMPACT
                            </a>
                        </Grid>
                    </Grid>
                    <Grid item md={4} sm={6} xs={8} className="center-col">
                        <img src={config.API_URL + "/images/org/abe248.png?v1.0"} alt="image"/>
                    </Grid>
                </Grid>
            </div>
        </div>
        <div className="about background-right">
            <div className="max-width">
                <Grid container direction="row-reverse" className="container">
                    <Grid item md={8} sm={6} className="text-left">
                        <h2>What we do</h2>
                        <h4>The SPHS acts as a driver for transformational change towards
                        more sustainable health systems and inclusive green and social
                        economies. We facilitate and coordinate the introduction of
                        sustainable procurement and production practices in the global
                        health sector. We actively work in the field of sustainable
                        procurement both within the United Nations system and at a
                        governmental level, and facilitate awareness-raising and capacity
                        building as well as develop guidance on sustainable procurement.
                        </h4>
                      <Grid  item md={6} className="white-box">
                            <h5 className="text-uppercase">
                            KEY DOCUMENTS AND DATA
                            <hr/>
                            </h5>
                            <a className="text-uppercase" href="https://issuu.com/informal_int_task_team_sphs/docs/sphs-6-pager" target="_blank" >
                                OUR HIGHLIGHTS
                            </a>
                            <a className="text-uppercase" href="/media/SPHS_Platform_UN_procurement_Stats.pdf" target="_blank" >
                              UN SPHS Member Agencies Health Procurement and Supply Statistics
                            </a>
                            </Grid>
                        </Grid>
                        <Grid item md={4} sm={6} xs={8} className="center-col">
                          <img src={config.API_URL + "/images/org/3afbfb.png?v1.0"} alt="image"/>
                        </Grid>
                </Grid>
            </div>
        </div>
        <div className="about background-left" style={{backgroundPosition: '110% 60px', paddingBottom: '50px'}}>
            <div className="max-width">
            <Grid container direction="row" className="container">
                <Grid item md={8} sm={6} className="text-left">
                        <h2>How we get there</h2>
                        <h4>With an international network of more than 3,700 leaders and
                            technical experts, we work towards a more sustainable global health
                            sector. By contributing to 11 Sustainable Development Goals, we
                            take an inclusive approach in our engagement with suppliers and
                            manufacturers. This is based on systematic consultation, ongoing
                            dialogue and collaboration on cross-cutting and front-running
                            innovative projects and programmes.
                        </h4>
                        <Grid  item md={6} className="white-box">
                            <h5 className="text-uppercase">KEY DOCUMENTS
                                <hr/>
                            </h5>
                            <a className="text-uppercase" href="https://drive.google.com/file/d/0B7mQ_2UCdoL4cDFkcVJBMHRqX28/view?usp=sharing" target="_blank" >
                                OUR ROADMAP
                            </a>
                            <a className="text-uppercase" href="#">
                                ENGAGEMENT STRATEGY WITH SUPPLIERS AND
                                MANUFACTURERS ON GREEN PROCUREMENT
                            </a>
                            </Grid>
                        </Grid>
                    <Grid item md={4} sm={6} xs={8} className="center-col">
                        <img src={config.API_URL + "/images/org/30b877.png?v1.0"} alt="image"/>
                    </Grid>
                    </Grid>
                </div>
            </div>
    </div>


        <Footer/>
    </div>

    <Navbar/>
    </Grid>
</div>



        );
    }
}

WhoWeAre.propTypes = {
    getMembers: PropTypes.func.isRequired,
    member: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    getLogActivityWithGeoIp: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    member: state.member
});

WhoWeAre = withStyles(styles)(WhoWeAre)

export default connect(mapStateToProps, { getMembers, getLogActivityWithGeoIp })(WhoWeAre);
