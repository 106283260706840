import React, {Component} from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {setPdfData} from '../../../actions/gridActions'
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { withRouter } from 'react-router-dom'
import { Redirect } from "react-router-dom";

class DataTransferCellRenderer extends Component {

    constructor(props) {
        super(props);
        this.generateRoute = this.generateRoute.bind(this);        
    }
   
      generateRoute(link) {  
        if (!('params' in link)) {
            return link.url;
        }
        var url = link.url;
        link.params.map(param => {           
            if (param.source in this.props || param.source in this.props.data) {
                if('inProps' in param){
                    url = url.replace('{' + param.target + '}', this.props.data[param.source]);
                }
                else {
                    url = url.replace('{' + param.target + '}', this.props[param.source]);
                }
            }
        });

        return url;
    }
 

     render(){
        const {data} = this.props;
        let link = this.generateRoute(this.props.link);
       
        return (
            <span>
    <Tooltip placement="top" title={this.props.tooltipText ? this.props.tooltipText : ""} >    
    <Link to={{
        pathname: link,
        myProps: {
            path: window.location.pathname,
            title: this.props.title+' for '+this.props.value,
            data: data,
            columns: this.props.columnApi.columnController.columnDefs
        }
    }}>{this.props.value}</Link>
    </Tooltip>               
          </span>
        );
    }
}



const mapStateToProps = state => ({});

export default connect( mapStateToProps, { setPdfData }, null, { forwardRef: true })(DataTransferCellRenderer);