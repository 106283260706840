import {
    GET_THREADS,
    GET_THREAD,
    GET_THREAD_FORM_DATA,
    SET_NEW_REPLY,
    DELETE_REPLY,
    GET_REPLY
} from '../actions/types';

const initialState = {
    threads: [],
    thread: [],
    formThread: null,
    reply: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_THREADS:
            return {
                ...state,
                threads: action.payload,
            };
        case GET_THREAD:
            return {
                ...state,
                thread: action.payload,
            };
             case GET_REPLY:
            return {
                ...state,
                reply: action.payload,
            };
        case GET_THREAD_FORM_DATA:
             return {
                ...state,
                formThread: action.payload,
            };
        case SET_NEW_REPLY:
            let thread = state.thread;
            if(thread){
                let replies = thread.replies;
                replies.push(action.payload)
                thread['replies'] = replies                
            }
            return {
                ...state,
                thread: thread,
            };   
        case DELETE_REPLY:
             let message = state.thread;
            if(message){
                let replies = message.replies;
                replies.map((reply, key) => {                    
                        if(action.payload.replyId === String(reply.id)){
                            replies.splice(key, 1)
                        }
                    })
                message['replies'] = replies
            }
            return {
                ...state,
                thread: message,
            };   
        default:
            return state;
    }
}