import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Navbar from '../layout/Navbar';
import Grid from '@material-ui/core/Grid';
import Footer from '../layout/Footer'
import { postSaveForLater } from '../../actions/userSetupActions'
import { withStyles } from '@material-ui/core/styles'
import ContributionItem from './elements/ContributionItem';
import NewsItem from './elements/NewsItem'
import { config } from '../../config'

const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

class ReadingList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSavedForLater: false
        }
    }

    componentDidMount() {
            this.props.postSaveForLater({details: true})

    }

    componentWillReceiveProps(nextProps) {
     
    }



    render() {
     const { classes } = this.props;

        return (
          <div className={classes.root}>
            <Grid container spacing={0}>
              <div className="page-content slideup page-animation" >
                <div className="news-list">
                  <div className="heroshot">
                    <img src={config.API_URL +"/images/org/007f0f.jpeg?v1.0"} all="image"/>
                    <div className="text-area text-center col-md-8 col-md-offset-2">
                    <img src={config.API_URL +"/images/org/9cfcf5.png?v1.0"} all="image"/>
                    <h1 className="text-center text-white"><span className="pointer">Reading List</span></h1>
                    <h2 className="text-white">Explore the news and stories on sustainable healthcare practices around the world</h2>
                    </div>
    </div>

                   <div className="max-width">

            <div className="clearfix"></div>
      {this.props.userSetup.saveForLater && this.props.userSetup.saveForLater.news &&
         <Grid item container spacing={3} direction="row" style={{
           paddingLeft: 15,
           paddingRight: 15,
           marginTop: 30
         }}>
        {this.props.userSetup.saveForLater.news.map((singleNews, i)=>(
             <NewsItem key={singleNews.id} newsItem={singleNews}/>
                        ))
        }
        </Grid>
        }

                <div className="post-list-item" ng-repeat-start="news in savedForLaterList.news">

                </div>


            <div className="clearfix"></div>


{this.props.userSetup.saveForLater && this.props.userSetup.saveForLater.contrib.length > 0 &&
  <Grid container spacing={3} direction="row" style={{
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 30
  }}>
        {this.props.userSetup.saveForLater.contrib.map((contributionItem, i)=>(
             <ContributionItem key={i} contributionItem={contributionItem} key={i} />
                        ))

        }
          </Grid>
        }



    </div>

                  </div>

                  <Footer/>
                </div>

              <Navbar/>
            </Grid>
        </div>
                                );
                    }
                }
ReadingList.propTypes = {
    classes: PropTypes.object.isRequired,
    postSaveForLater: PropTypes.func.isRequired,
    userSetup: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
        userSetup: state.userSetup
});
ReadingList = withStyles(styles)(ReadingList)
export default connect(mapStateToProps, {postSaveForLater})(ReadingList);
