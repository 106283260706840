import client from './client';

import {
  GET_SDGS
} from './types';

// Get current sdg
export const getSdgsFilter = () => dispatch => {  
  client
    .get('/web/filter/sdg')
    .then(res =>
      dispatch({
        type: GET_SDGS,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_SDGS,
        payload: {}
      })       
  });
};




