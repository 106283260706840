import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {getUserMemberFiles, uploadButton, getDeleteFile} from '../../actions/documentActions'
import ProfileActions from '../dashboard/ProfileActions';
import Spinner from '../common/Spinner';
import Navbar from '../layout/Navbar';
import CustomCellRenderer from '../admin/grid/CustomCellRenderer';
import SingleLinkRenderer from '../admin/grid/SingleLinkRenderer';
import { toast } from 'react-toastify';
import Dropzone from "react-dropzone";
import { config } from '../../config'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { getLogActivityWithGeoIp, getCurrentProfile } from '../../actions/profileActions';

const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

class Documents extends Component {
  constructor() {
    super();
    this.state = {
         rowData: [],
         logged: false
    };
  }

     componentDidMount() {
       this.props.getCurrentProfile();
       this.props.getUserMemberFiles();
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.profile && nextProps.profile.profile && nextProps.profile.profile.roles.includes('ROLE_MEMBER') && !this.state.logged){
            this.props.getLogActivityWithGeoIp('Landing on document page', 'GET', "Documents tool")
            this.setState({
                logged: true
            })
        }
    
        if(nextProps.documents.userMemberFiles){
          this.setState({
             rowData: nextProps.documents.userMemberFiles
          })
      }
    }

     handleChange = (file) => {
         let gridApi;
        if(typeof file[0] !== 'undefined'){
         var body = new FormData();
         body.append('file', file[0])
         this.props.uploadButton(body)
           if(this.props.profile.profile && this.props.profile.profile.roles.includes('ROLE_MEMBER')){
              this.props.getLogActivityWithGeoIp('Upload file', 'GET', "Documents tool")
           }
        // this.props.getUserMemberFiles();
        }
     }

    onDropRejected = () => {
         toast.error('Upload error - your file is too big or in wrong format.', {autoClose: 10000})
    }



  render() {
    const { classes } = this.props;
    const { userMemberFiles } = this.props.documents;
    const { profile } = this.props.profile;
    var roles = '';
    let dashboardContent;
    if (profile === null ) {
        dashboardContent = <Spinner />;
    }else{
       roles = profile.roles;
       dashboardContent = <ProfileActions profileItem={profile}/>
    }
     const customRenderers = {
            linksRenderer: CustomCellRenderer,
            singleLinkRenderer: SingleLinkRenderer
        };

    return (
         <div className="container-fluid" >
            <div className="page-content slide page-animation">
             {dashboardContent}
                <div className="clearfix"></div>
                 <div className="welcome-page page-content">
                <Grid container spacing={0}>
                     <Grid item md={12} lg={12} xs={12} className="text-center">
                     <h1 className="m-top30">Members' Document Collaboration</h1>
                       <div className="m-all30">
                                            <Dropzone
                                           maxSize={20000000}
                                            onDrop={acceptedFiles => this.handleChange(acceptedFiles)}
                                            onDropRejected={this.onDropRejected}
                                            >
                                            {({getRootProps, getInputProps}) => (
                                              <section style={{width: "fit-content",margin: "0 auto"}}>
                                                <div {...getRootProps()}>
                                                  <input {...getInputProps()} />
                                                  <button type="button" className="upload-btn btn btn-success">
                                                  <i style={{marginRight: 5}} className="fa fa-upload"></i> {'Upload file'}</button>
                                                </div>
                                              </section>
                                            )}
                                          </Dropzone>

                                           </div>
                     </Grid>
                     <Grid item md={10} lg={10} xs={10} className="text-center center-col">
                      <h4 style={{lineHeight: "25px"}}> This is a secure Members’ area for document management and collaboration for you to<br/> access, view, download, edit and share documents with other Members.<br/><br/>
                      By continuing to use this tool,you agree not to share documents beyond the platform<br/>without obtaining permission of the document owners and authors.</h4>
                      </Grid>
            <Grid item md={8} lg={8} xs={10} className="text-center center-col">
                <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Image</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Locked for editing</TableCell>
                        <TableCell>Edit Rights</TableCell>
                        <TableCell>View</TableCell>

                    </TableRow>
                </TableHead>
                 {this.state.rowData.length > 0 &&
                <TableBody>
                 {this.state.rowData.map((file, i) => (
                <TableRow key={i}>
                <TableCell style={{width: 50, heigt: 50}}><img src={config.API_URL+'/documents/file/'+file.id+'/thumb'} style={{width: 50,borderRadius: "50%", border: "1px solid #f2f2f2"}}/></TableCell>
                <TableCell ><a href={'/members/documents/file/profile/'+file.id} style={{color: 'grey'}}>{file.name}</a><p style={{color: 'grey'}}>{file.created}</p></TableCell>
               <TableCell>{file.startEdit ? <span>{file.startEdit}</span> : <span>-</span>}</TableCell>
               <TableCell>{(roles.includes('ROLE_SUPER_ADMIN') || roles.includes('ROLE_ADMIN') || file.edit) ? <i className="fas fa-check"></i> : <i className="fas fa-times"></i>}</TableCell>
                <TableCell  style={{width: 100}}>
                <a href={'/members/documents/file/profile/'+file.id} style={{marginRight: 10}}><i className="fas fa-eye"></i></a>
                 </TableCell>
                </TableRow>
                ))}
                </TableBody>
                }
            </Table>

                     </Grid>
                </Grid>
                 </div>
             </div>
           <Navbar/>
        </div>
    );
  }
}

Documents.propTypes = {
  getUserMemberFiles: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  uploadButton: PropTypes.func.isRequired,
  getDeleteFile: PropTypes.func.isRequired,
  documents: PropTypes.object.isRequired,
   profile: PropTypes.object.isRequired,
   getLogActivityWithGeoIp: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    profile: state.profile,
    documents: state.documents,
});

Documents = withStyles(styles)(Documents)

export default connect( mapStateToProps, {getUserMemberFiles, getCurrentProfile, uploadButton, getDeleteFile, getLogActivityWithGeoIp })(withRouter(Documents));
