import React, { Component }  from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Layout from '../../../components/admin/layout/Layout'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import Header from "../grid/Header"
import Paper from '@material-ui/core/Paper'
import {getCalendarCollaborations} from "../../../actions/collaborationActions";
import 'react-big-calendar/lib/css/react-big-calendar.css'
import classNames from 'classnames';
import './calendar.css'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  calendar:{
    margin: "28px 12px",
    borderRadius: 4,
    paddingTop: 15,
    boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)"
  }
  });

 const localizer = momentLocalizer(moment);
 const myEventsList = [
  {
    id: 0,
    title: 'All Day Event very long title',
    allDay: true,
    start: new Date(2019, 11, 5),
    end: new Date(2019, 11, 5),
  },];

class CollaborationCalendar extends Component {

    constructor(props) {
        super(props);
    }

     componentDidMount() {
         this.props.getCalendarCollaborations()
    }

    render() {
        const { classes, collaboration: {calendarCollaborations} } = this.props;

         return (
                 <Layout>
           <Grid container className={classes.root} direction="row" justify="flex-start"  >
           <Grid item md={12} lg={12} xs={12} container className="text-center">
                  <Grid item md={12} lg={12} xs={12} container className={classNames(classes.calendar, "text-center examples")}>
                <div className="example">
                 <Calendar
                   localizer={localizer}
                   events={calendarCollaborations}
                   startAccessor="start"
                   endAccessor="end"
                   style={{height: 500}}
                   onSelectEvent={(value) => {
                    window.open('/crm/accounts/'+value.accountId+'/profile', '_blank');
                  }}
                 />
                 </div>

             </Grid>
           </Grid>
            </Grid>
            </Layout>
           );
    }
}

CollaborationCalendar.propTypes = {
 collaboration:    PropTypes.object.isRequired,
 classes:    PropTypes.object.isRequired,
 getCalendarCollaborations: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
 collaboration: state.collaboration
});
CollaborationCalendar = withStyles(styles)(CollaborationCalendar)
export default connect( mapStateToProps, { getCalendarCollaborations })(withRouter(CollaborationCalendar));
