import React from "react";
import PropTypes from "prop-types";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const dataSet1 = [
    {
        name: "Johson",
        amount: 30000,
        sex: 'M',
        is_married: true
    },
    {
        name: "Monika",
        amount: 355000,
        sex: 'F',
        is_married: false
    },
    {
        name: "John",
        amount: 250000,
        sex: 'M',
        is_married: false
    },
    {
        name: "Josef",
        amount: 450500,
        sex: 'M',
        is_married: true
    },
    {
        headerName: "Supplier Name",
        field: "supplierName",
    }
];

class DownloadExcelAdd extends React.Component {
     constructor() {
        super();
      }
  
    render() {
       
     let addionalData = [];    
       let data = []
        if(this.props.addionalData && this.props.addionalData.data && this.props.addionalData.data.length > 0){
            this.props.addionalData.data.map(row => (
              addionalData.push(row)      
                    ))
         addionalData.push(this.props.addionalData.pinnedRows)
       }
        let columns = []
        if(this.props.columns){
          this.props.columns.map((column, key)=> {
                if(column.children && column.children.length > 0){
                    var i;
                    for (i = 0; i < column.children.length; i++) {
                        columns.push(column.children[i]);
                      }
                }else{
                    columns.push(column);
                }
            })
        }
        return (
            <ExcelFile element={<button  className="btn btn-success" style={{width: '80%', marginRight: 20}}>Export Excel</button>}>
                
               {(addionalData &&  addionalData.length > 0) &&
                   <ExcelSheet data={addionalData} name={'Total UN global health procurement volume'}>                
                    <ExcelColumn label={'UN health procurement per location'} value={'regionName'}/>
                    <ExcelColumn label={'Total health procurement volume (USD)'} value={'value'} />
                </ExcelSheet>}
            {this.props.data && this.props.columns &&
                <ExcelSheet data={this.props.data} name={this.props.title}>
                {columns && columns.map((column, key)=> (
                    <ExcelColumn label={column.headerName} value={column.field} key={key}/>
                        ))}
                </ExcelSheet>}
            </ExcelFile>
        );
    }
}

DownloadExcelAdd.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    addionalData: PropTypes.object.isRequired,
    filename:  PropTypes.string.isRequired,
};



export default DownloadExcelAdd;