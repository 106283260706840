import {
GET_COLLABORATIONS,
        GET_COLLABORATIONS_FORM_DATA,
        GET_COLLABORATION,
        GET_CALENDAR_COLLABORATIONS
        } from '../actions/types';

const initialState = {
    collaborations: [],
    formData: [],
    calendarCollaborations: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_COLLABORATIONS:
            return {
                ...state,
                collaborations: action.payload
            };
        case GET_COLLABORATION:
            let collaborations = state.collaborations;
            let collArray = [];
            let newCollab = action.payload;
            let edit = false;
            if (collaborations.length > 0) {
                collaborations.map(collaboration => {
                    if (collaboration.id === newCollab.id) {
                        if (newCollab.status === 1) {
                            collArray.unshift(newCollab)
                        } else {
                            collArray.push(newCollab)
                        }
                        edit = true;
                    } else {
                        collArray.push(collaboration)
                    }
                })
            }
            if (!edit) {
                if (newCollab.status === 1) {
                    collArray.unshift(newCollab)
                } else {
                    collArray.push(newCollab)
                }
            }

            return {
                ...state,
                collaborations: collArray
            };
        case GET_COLLABORATIONS_FORM_DATA:
            return {
                ...state,
                formData: action.payload
            };
        case GET_CALENDAR_COLLABORATIONS:
            return {
                ...state,
                calendarCollaborations: action.payload
            };
        default:
            return state;
}
}