import React from 'react';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';

class Switch extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            value: this.props.input.value ? this.props.input.value : false
        };

        this.onChange = this.onChange.bind(this);
    }
    
     componentDidMount(){
         
     }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.input.value
            });
        }

        if(this.props.input.value === undefined) {
            this.props.input.onChange(false);
        }
    }

    onChange(e) {
        this.setState({
            value: !this.state.value
        }, function () {
            this.props.input.onChange(this.state.value);
        });
        if (this.props.field.hasOwnProperty('connectedField')) {
            for (let i = 0; i < this.props.fields.length; i++) {
                if (this.props.fields[i]['id'] == this.props.field['connectedField']) {
                    if (this.state.value == true) {
                    this.props.fields[i]['disabled'] = false;
                } else {
                    this.props.fields[i]['disabled'] = true;
                }
                }
            }
        }
    }

    render() {
        return <Grid item md={12} style={{marginTop: 20}}>
            <Grid item md={12} style={{marginTop: 0, marginBottom: 20}}>
            <div style={{ fontSize: 14, textTransform: 'uppercase'}}>{this.props.field.label}</div>
            </Grid>
            <div className="switchComponent text-left" style={{marginTop: 10, marginBottom: 30}}>
            <input
                checked={this.props.input.value}
                onChange={this.onChange}
                type="checkbox"
                id={'check-status' + this.props.field.id}
                label='status'
                disabled={this.props.field.disabled}
            />
            <label htmlFor={'check-status' + this.props.field.id}></label>
        </div>
        </Grid>
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Switch);