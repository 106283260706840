import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames';
import { config } from '../../../config'

const styles = theme => ({
  icon: {
    fontSize: 50,
    color: "rgba(255,255,255,0.6)",
  },
});

class MembersItem extends Component {

   constructor(props) {
    super(props);
  }


    render() {
    const { classes } = this.props;


      return (
              <span>
          <div className="col-md-3 col-sm-4 col-xs-12 noPadding">
            <div className="noPadding image-area-thumb text-left" style={{position: "relative", }}>
              <img src={config.API_URL + this.props.image} alt="member" />
              <a className="image-hover" href={this.props.memberItem.href}>
                <h3 className="text-white">{this.props.memberItem.name}</h3><hr/>
                <p>{this.props.memberItem.shortDesc}</p>
              </a>
            </div>
          </div>

             {this.props.index==4 &&

            <div className="col-md-3 col-sm-4 col-xs-12 noPadding">
              <div className="image-area-blue-box background-light_blue text-left" style={{position: "relative"}}>
              <Icon className={classNames(classes.icon, 'fa fa-quote-left', "text-light-gray")} />
                <p>
                  Innovation and procurement are viable, tested and proven policy<br/>
                  options to achieve sustainable growth in the developed world and, increasingly, in the developing world as well.
                  <br/>
                </p>
                <hr style={{width: '50px'}}/>
                <span className="text-white">Ban Ki-Moon,<span><br/>
                <span style={{color: '#78e0e3', fontSize: '16px'}}>United Nations Secretary-General </span>
                </span>
                </span>
              </div>
            </div>

          }
           {this.props.index==5 &&

            <div className="col-md-3 col-sm-4 col-xs-12 noPadding">
              <div className="image-area-blue-box background-light_blue text-left" style={{position: "relative",}}>
                <Icon className={classNames(classes.icon, 'fa fa-quote-left', "text-light-gray")} />
                <p style={{fontSize: '16px'}}>We are committed to working with partners in governments,
                  private sector and international organizations to achieve more effective procurement,
                  drive innovative solutions, and continue to support in-country supply chains and pharmaceutical management.</p>
              </div>
            </div>

        }
          </span>
          );
    }
}

MembersItem.propTypes = {
    memberItem: PropTypes.object.isRequired,
};

MembersItem = withStyles(styles)(MembersItem)

export default connect()(MembersItem);
