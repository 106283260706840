import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Navbar from '../layout/Navbar';
import Grid from '@material-ui/core/Grid';
import Footer from '../layout/Footer';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { config } from '../../config';
import { compose, withProps, withHandlers, withStateHandlers } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  Polygon,
  Rectangle
} from "react-google-maps";
import stylesArray from './elements/mapStyles'
import axios from 'axios'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { getMapContributionsFilter, setContributions } from '../../actions/contributionActions'
import {getMapContacts, setMapContacts} from '../../actions/setupActions'
import { getContactCountries, setContactCountries } from '../../actions/accountActions'
import IndividualContribution from './elements/IndividualContribution'
import MapFilter from '../filter/MapFilter'
import { getLogActivityWithGeoIp } from '../../actions/profileActions';
import '../../css/map.css';



const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}


const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");

const MapWithAMarkerClusterer = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyArOQ46-h5Pr9ursM4RGClTJiCCP73t0Kg&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div className='map-height' />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withHandlers({
    onMarkerClustererClick: () => (markerClusterer) => {
      const clickedMarkers = markerClusterer.getMarkers()
    }
  }),
  withStateHandlers(() => ({
  isOpen: false,
  infoIndex: null,
  open: false
}), {
  showInfo: ({ isOpen, infoIndex }) => (index) => ({
    isOpen: infoIndex !== index || !isOpen,
    infoIndex: index
  }),
  handleClickOpen: ({ open, inx }) => (inxInfo) => ({
    open: inxInfo !== inx || !open,
    inx: inxInfo
  })
}),
  withScriptjs,
  withGoogleMap
)(props =>
  <GoogleMap
    defaultZoom={2.5}
    defaultCenter={{ lat: 30, lng: 0 }}
    defaultOptions={{
        backgroundColor: '#FFF8DC',
        scrollwheel: true,
        panControl: true,
        scaleControl: true,
        streetViewControl: false,
        disableDoubleClickZoom: true,
        overviewMapControl: false,
        zoomControl: true,
        mapTypeControl: false,
        maxZoom: 22,
        minZoom: 2.5,
        styles: stylesArray,

        }}
  >

  {props.contactCountries.length > 0 &&
    props.contactCountries.map((value, index) => {

        if(value.geometry && value.geometry !== 'undefined' && value.geometry !== null){

         if(Array.isArray(value.geometry)){
        let pol;
           return pol =  value.geometry.map((polygon, index) => {
                   let bounds=[]

         let coord = polygon.coordinates[0];

         for (var i in coord) {
                                bounds.push({lat: coord[i][1], lng: coord[i][0]});
                            }
         return (
                            <Polygon
                                key={index}
                                paths={bounds}
                                options={{
                                    strokeWeight: 0,
                                    fillColor: '#ffffe6',
                                    fillOpacity: 0.6,
                            }}
                            >

                 </Polygon>
                        )
                                        })
         }else{
            let bounds=[]
            let coord = value.geometry.coordinates[0];
            for (var i in coord) {
                                   bounds.push({lat: coord[i][1], lng: coord[i][0]});
                               }
            return (
                               <Polygon
                                   key={index}
                                   paths={bounds}
                                   options={{
                                       strokeWeight: 0,
                                       fillColor: '#ffffe6',
                                       fillOpacity: 0.6,
                               }}
                               >
                               </Polygon>
                           )
           }
}
                    })


             }
               {props.markers && props.markersSphs &&
                            props.markers.map((marker, index) => (
              <Marker onClick={() => props.showInfo(index)}
              key={index}
              position={{ lat: parseFloat(marker.location.lat), lng: parseFloat(marker.location.lng) }}
              icon={marker.icon}
              options={{ id: marker.id, pos: { lat: parseFloat(marker.location.lat), lng: parseFloat(marker.location.lng) } }}
                    >  <>
  {(props.isOpen && props.infoIndex === index) &&
    <InfoWindow onCloseClick={props.showInfo} className="col-md-12 noPadding open-contribution">
        <span>
         {marker.contributions.map((contribution, inx) =>
         <Grid item container lg={12} key={inx}>

          <Grid lg={10} md={10} item  className="text-center">
        {contribution.link &&
        <a href={contribution.link} target="_blank">
        {contribution.name}
        </a>
        }

        <br/>
        <div style={{marginLeft: '20px'}}>

        {contribution.city &&
                <span>City: {contribution.city}</span>
                }
        <br/><br/>
        <i className="ion-location"></i>
        {contribution.country}
        </div>

        </Grid>
        </Grid>
        )}

      </span>
    </InfoWindow>}
</>
</Marker>
))
                                                        }
    {!props.markersSphs && !props.markersCountry  &&

    <MarkerClusterer
      onClick={props.onMarkerClustererClick}
      averageCenter
      enableRetinaIcons
      gridSize={60}
      onClusteringEnd={props.onClusteringEnd}
     maxZoom={22}
    ><span>
      {props.markers.map((marker, index) => {

            return  <Marker onClick={() => props.showInfo(index)}
              key={index}
              position={{ lat: parseFloat(marker.location.lat), lng: parseFloat(marker.location.lng) }}
              options={{ id: marker.id, pos: { lat: parseFloat(marker.location.lat), lng: parseFloat(marker.location.lng) } }}
                    >
  {(props.isOpen && props.infoIndex === index) &&
    <InfoWindow onCloseClick={props.showInfo} options={{maxWidth: 450}} className="col-md-12 noPadding open-contribution">
        <span>
         {marker.contributions.map((contribution, inx) =>
         <Grid item container lg={12} key={inx}>
            <Grid lg={2} item >
            <a href={contribution.href} target="_blank">
            <div style={{maxWidth: '40px'}} className="">
                {contribution.icon &&
                    <img src={config.API_URL +contribution.icon} width={40} height={50} style={{textAlign: 'center', marginLeft: '20px'}}/>
                     }
                     </div>
             </a>
            </Grid>
          <Grid lg={9} item  className="text-left" style={{marginLeft: '20px'}}>
        {contribution.link &&
        <a href={contribution.link} target="_blank">
        {contribution.name}
        </a>
        }
        {contribution.projectTitle &&
       <a href={contribution.href} target="_blank" className="text-left"
         style={{
             textDecoration: 'underline',
             outline: "none",
             color: '#1890ff',
             fontSize: 18,
             fontWeight: 400,
             fontFamily: "Montserrat, sans-serif",
             lineheight: 20,
             textTransform: "uppercase"
             }}>
             {contribution.projectTitle}
       </a>

        }
        <br/>
        <div>
        {contribution.submittedByName &&
            <span>submitted by {contribution.submittedByName}</span>}<br/><br/>
        {contribution.city &&
                <span>City: {contribution.city}</span>
                }
        <br/><br/>
        <i className="ion-location"></i>  {contribution.regionText && !contribution.link &&
        contribution.regionText
                                }
                     {contribution.link &&
        contribution.country
                                     }
        </div>
      {props.inx === contribution.id && props.open &&
        <Dialog
          fullScreen
          maxWidth="xl"
          open={props.open}
          onClose={props.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar style={{position: 'relative', backgroundColor: '#1cbbb4', textFloat: 'right', boxShadow: 'none'}}>
          <Grid container direction="row">
            <Grid lg={11} item ><div></div></Grid>
            <Grid lg={1} item >
              <IconButton color="inherit" onClick={props.handleClickOpen} aria-label="Close" style={{    float: "right"}}>
                <CloseIcon />
              </IconButton>
            </Grid>
           </Grid>
          </AppBar>
          <IndividualContribution id={contribution.id} />

        </Dialog>
        }
        </Grid>
        </Grid>
        )}

      </span>
    </InfoWindow>}

</Marker>

})}
      </span>
    </MarkerClusterer>

    }
  </GoogleMap>
);





class WhatYouCanDo extends Component {
    constructor(props) {
        super(props);
        this.state = {
           markers: [],
           markersSphs: false,
           markersCountry: false,
           contactCountries: [],
           filters: {
               map: true,
               limit: 1000
           }
        }
    }
     componentWillMount() {
    this.setState({ markers: [] })
  }

    componentDidMount() {
        this.props.getMapContributionsFilter(this.state.filters)
        this.props.getLogActivityWithGeoIp('What You Can Do page', 'GET', "Website usage")
    }

    componentWillReceiveProps(nextProps) {
       if(nextProps.contribution.contributionsMap){
          this.setState({ markers: nextProps.contribution.contributionsMap, markersSphs: false, markersCountry: false });
       }
       if(nextProps.account.contactCountries && nextProps.account.contactCountries.length > 0){

          this.setState({ contactCountries: nextProps.account.contactCountries, markersCountry: true });
       }

       if(nextProps.setups.contactsMap && nextProps.setups.contactsMap.length > 0){

          this.setState({ markers: nextProps.setups.contactsMap, markersSphs: true});
       }
    }

     handleChangeFilter = value => {

       this.props.setContactCountries()
       this.props.setMapContacts()
       setTimeout(() => {
                 this.props.getMapContributionsFilter(value)
              }, 1000)

         this.setState({
             filter: value,
             contactCountries: [],
             markersSphs: false,
             markersCountry: false
         })

     }

     handleChangePresence = value => {

      if(value === 'sphs-secretariat-members'){
          this.props.setContributions()
           this.props.setContactCountries()
          setTimeout(() => {
              this.props.getMapContacts()
              }, 300)
              this.setState({
                  markersCountry: false
              })
      }
      if(value === 'crm-countries'){
          this.props.setMapContacts()
           this.props.setContributions()
          setTimeout(() => {
              this.props.getContactCountries()
              }, 300)
        this.setState({
            markersSphs: false,
            markersCountry: true,
            markers: []
        })
      }

      if(value === 'sphs-presence'){
           this.props.setContributions()
            setTimeout(() => {
              this.props.getMapContacts()
              this.props.getContactCountries()
              }, 300)
              this.setState({
            markersSphs: true,
            markersCountry: true
        })
      }

     }


    render() {
const { classes } = this.props;

        return (
            <div className={classes.root}>
      <Grid container spacing={0}>
      <div  className="page-content  slideup page-animation" >
       <div className="interactive-map">
       <div className="interactive-map-header">
             <h1>Global Contributions Map</h1>
                 </div>
        <MapFilter onChange={this.handleChangeFilter} filterTotal={this.props.contribution.total} onChangePresence={this.handleChangePresence}/>
        <MapWithAMarkerClusterer markers={this.state.markers} contactCountries={this.state.contactCountries} markersSphs={this.state.markersSphs} markersCountry={this.state.markersCountry}/>
        </div>
        <Footer/>
        </div>

        <Navbar/>
                    </Grid>
            </div>

        );
    }
}

WhatYouCanDo.propTypes = {
     classes: PropTypes.object.isRequired,
     getMapContributionsFilter: PropTypes.func.isRequired,
     contribution: PropTypes.object.isRequired,
     account: PropTypes.object.isRequired,
     getContactCountries: PropTypes.func.isRequired,
     setContactCountries: PropTypes.func.isRequired,
     setups: PropTypes.object.isRequired,
     getMapContacts: PropTypes.func.isRequired,
     setContributions: PropTypes.func.isRequired,
     setMapContacts: PropTypes.func.isRequired,
     getLogActivityWithGeoIp: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
     contribution: state.contribution,
     account : state.account,
     setups: state.setups
});

WhatYouCanDo = withStyles(styles)(WhatYouCanDo)

export default connect(mapStateToProps, { getLogActivityWithGeoIp, setMapContacts, setContributions, getMapContacts, getMapContributionsFilter, getContactCountries, setContactCountries })(withRouter(WhatYouCanDo));
