import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import Spinner from '../../common/Spinner';
import {replaceFile, getFile, downloadFile, changePrivacy, getUserMembers, unlockFile, sendMessage, sendFile } from '../../../actions/documentActions'
import Layout from '../../../components/admin/layout/Layout'
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles'
import Dropzone from "react-dropzone"
import Avatar from '@material-ui/core/Avatar';
import { toast } from 'react-toastify'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {config} from '../../../config'
import Select from 'react-select'
import Comments from '../comments/Comments'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: "20px 10px"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width: '100%',
    height: '100%',
    color: '#000000de'
  },
   bigAvatar:{
    width: 100,
   height: 100,
  },
  labelData:{
    padding: "8px 17px 8px 0",
    color: "#777",
    textAlign: "right",
    width: "initial",
    fontWeight: "normal"
  },
  toolbar: {
    alignItems: 'center',
    padding: 0,
  },
   margin: {
    margin: theme.spacing(1),
  },
  checkbox: {
   cursor: "pointer"
  }
});

class FileProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
        file: null,
        openReplace: false,
        openPrivacy: false,
        privacyValue: false,
        userMembers: [],
        openSendMessage: false,
        sendMessage: '',
        openConfirm: false,
        sendUsers: [],
        openSendFile: false,
        sendText: '',
        errorSendFile: ''
        }
    }

    componentDidMount() {
       this.props.getFile('/api/members/files/get/profile/'+this.props.match.params.id)
       this.props.getUserMembers()
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.documents.file){
                this.setState({
                    privacyValue: nextProps.documents.file.privacy,
                    userMembers: nextProps.documents.file.userMembers
                })
            }
    }

     openConfirmOverride = event => {
        event.preventDefault()
        this.setState({
            openConfirm: true
        })

    }
    closeConfirm = () => {
        this.setState({
            openConfirm: false
        })
    }

    overrideLock = event => {
        event.preventDefault()
        this.props.unlockFile(this.props.match.params.id)
        this.setState({
            openConfirm: false
        })

    }


    handleSubmit = event => {
        event.preventDefault()
        if(this.state.file){
            var body = new FormData();
            body.append('file', this.state.file)
            body.append('fileToReplace', this.props.documents.file.id)
            this.props.replaceFile('/api/members/files/replace/file', body, this.props.history, this.props.documents.file.id)
            this.setState({
            file: '',
            openReplace: false
        })
        }else{
            toast.error('Please, upload file!', {autoClose: 10000})
        }
    }



     handleChange = (file) => {
        if(typeof file[0] !== 'undefined'){
            this.setState({
                file: file[0]
            });
        }
     }

    onDropRejected = () => {
         toast.error('Upload error - your file is too big or in wrong format.', {autoClose: 10000})
    }

     clickFileEditDownload = (route) => event => {
        event.preventDefault()
        if(this.props.documents.file.name){
           this.props.downloadFile(route, this.props.documents.file.name)
            setTimeout(() => {
           this.props.getFile('/api/members/files/get/profile/'+this.props.match.params.id)
       }, 3000)
        }
    }

    clickFileDownload = (route, name) => event => {
        event.preventDefault()
        this.props.downloadFile(route, name)

    }

    closeReplace = () => {
         this.setState({
            file: '',
            openReplace: false
        })
    }

    openDialogReplace = () => {
         this.setState({
            openReplace: true
        })
    }

    openDialogPrivacy = () => {
         this.setState({
            openPrivacy: true
        })
    }

    closePrivacy = () => {
         this.setState({
            openPrivacy: false
        })
    }

    handleSubmitPrivacy = event => {
        event.preventDefault()
        var userMembers = []
        if(this.state.userMembers.length > 0){
            this.state.userMembers.map(member => (
                   userMembers.push(member.value)
            ))
        }
        var data = {
            privacyValue: this.state.privacyValue,
            fileId: this.props.documents.file.id,
            userMembers: userMembers
        };
        this.props.changePrivacy(data, this.props.histpry, this.props.documents.file.id)
         this.setState({
            openPrivacy: false
        })
    }

    handleChangePrivacy = event => {
      this.setState({privacyValue: event.target.value});
    };
    onChangeUserMembers = (value) => {
        this.setState({userMembers: value});
    }

    openDialogSendMessage = (e) => {
        e.preventDefault()
         this.setState({
            openSendMessage: true
        })
    }

    closeSendMessage = () => {
         this.setState({
            openSendMessage: false,
            sendMessage: ''
        })
    }

     handleSubmitSendMessage = event => {
          event.preventDefault()
         var data = {
            email: this.props.documents.file.startEditEmail,
            fileId: this.props.match.params.id,
            message: this.state.sendMessage,
            type: 'admin',
            fileName: this.props.documents.file.name
        }

         this.props.sendMessage(data);
          this.setState({
            openSendMessage: false,
            sendMessage: ''
        })
     }

     handleChangeMessage = e => {
         this.setState({
            sendMessage: e.target.value
        })
     }


      openDialogSendFile = (e) => {
       e.preventDefault()
         this.setState({
            openSendFile: true
        })
    }

    closeSendFile = () => {
         this.setState({
            openSendFile: false,
            sendText: '',
            sendUsers: [],
            errorSendFile: '',
        })
    }

     handleSubmitSendFile = event => {
         event.preventDefault()
         var sendUsers = []
         if(this.state.sendUsers.length > 0){
          this.state.sendUsers.forEach((obj, k) => {
            return sendUsers.push(obj.value);
          })
           var dataFile = {
            fileId: this.props.match.params.id,
            fileName: this.props.documents.file.name,
            message: this.state.sendText,
            sendUsers: sendUsers
        }

         this.props.sendFile(dataFile);
          this.setState({
            openSendFile: false,
            sendText: '',
            errorSendFile: '',
            sendUsers: []
        })
    }else{
        this.setState({
            errorSendFile: 'Please, choose receivers!',
        })
    }
     }

     handleChangeTextFile = e => {
         this.setState({
            sendText: e.target.value
        })
     }

     onChangeSendUsers = (value) => {
       if(value && typeof value === 'object' && value.length > 0){
        this.setState({
            sendUsers: value,
            errorSendFile: ''
        });
        }else{
            this.setState({
                sendUsers: []
            })
        }
    }

    render() {
        const { classes } = this.props;
        const {file, userMembers} = this.props.documents;
        let extensions = ['doc', 'docx', 'xlsx', 'xls', 'csv', 'pdf'];
        let privacyOptions = [
            {label: 'Admin', value: 1},
            {label: 'Logged', value: 2},
            {label: 'Public', value: 3}
        ]
        return (
        <Layout>
            <Grid container className={classNames(classes.root)} spacing={2} direction="row" justify="flex-start"  >
                    <Grid item md={12} lg={12} xs={12} className="profile-breadcrumb">
                      <div className="profile-breadcrumb">
                        <a href={"/admin/documents"}><i className="fa fa-fw fa-chevron-left"></i>Files</a>
                       </div>
                    </Grid>
                    <Grid item md={12} lg={12} xs={12} container spacing={2}>
                      <Grid md={6} lg={6} xs={12} item container justify="flex-start" direction="row">
                        <Paper className={classes.paper}>
                       {file &&
                         <Grid item md={12} lg={12} xs={12} container direction="row" justify="flex-start" className="profile-information align-center">
                        {(file.isImage || file.isPdf ||  extensions.includes(file.ext)) &&
                          <Grid lg={2} md={3} xs={4} item className="profile-pic">
                            <Avatar alt={file.name} src={file.thumb} className={classes.bigAvatar} />
                          </Grid>
                          }

                            <Grid lg={10} md={9} xs={8} item>
                            <h1 className="accountTitle">{ file.name }</h1>
                            </Grid>

                             <Grid md={12} lg={12} xs={12} item>

    <Toolbar className={classes.toolbar, "toolbar"}>
           {!file.isImage && !file.startEdit &&
          <Button href=""
  onClick={this.clickFileEditDownload('/api/members/document/start-edit/'+this.props.match.params.id+'/download')}
  variant="outlined"
   className={classes.margin}>Edit</Button>
           }
           <Button href='' onClick={this.clickFileDownload('/api/members/documents/files/'+this.props.match.params.id+'/download', this.props.documents.file.name)}
        variant="outlined"
        className={classes.margin}>Download</Button>
         {this.props.auth.user.username === file.startEditEmail &&
                                 <Button href='' variant="outlined" onClick={this.openDialogReplace} className={classes.margin} color="primary">
                         Replace file</Button>
                                 }
        <Button variant="outlined" color="primary" onClick={this.openDialogSendFile} className={classes.margin} target="_blank">
            Send file
        </Button>
        <Button variant="outlined" color="secondary" onClick={this.openDialogPrivacy} className={classes.margin}>
            Change privacy
        </Button>

        {(file.isImage || file.isPdf) && file.privacy === 3 &&
          <Button variant="outlined" color="primary" href={config.API_URL+"/documents/file/"+file.hash+"/full/hash"} className={classes.margin} target="_blank">
            Preview
        </Button>
        }
    </Toolbar>
                </Grid>
                          <Grid md={12} lg={12} xs={12} item className="profile-desk" style={{marginLeft:10}}>
                                 <div className="m-top30">
                                     {file.startEdit &&
                                            <p><span className={classes.labelData}>File is locked for editing by: </span>
                                    {(this.props.auth.user.username !== file.startEditEmail) ?
                                    <a href="#" onClick={this.openDialogSendMessage}>{ file.startEdit }</a>
                                    :
                                     <span>{ file.startEdit }</span>}

                                    <Button variant="outlined" type='button' onClick={this.openConfirmOverride}  style={{marginLeft: 30}}>
                                        Override lock</Button>
                                            <br/></p>
                                    }
                                  {file.startEditDate &&
                                         <p><span className={classes.labelData}>Start edit date: </span>
                                 { file.startEditDate }<br/></p>
                                 }
                                 <p><span className={classes.labelData}>Privacy: </span>{ file.privacyName }<br/></p>
                                 {file.userMembers && file.userMembers.length > 0 &&
                                         <p><span className={classes.labelData}>Share for edit: </span>{ file.userMembers.map((member, k) => {
                                         return  <span key={k}>{member.label} {k !== (file.userMembers.length - 1) && <span>, </span> }</span>
                                          }) }<br/></p>
                                 }

                                      {file.created &&
                                                <p><span className={classes.labelData}>Created at: </span>{ file.created }<br/></p>
                                        }
                                     {file.createdBy &&
                                                <p><span className={classes.labelData}>Created by: </span>{ file.createdBy }<br/></p>
                                        }
                                        {file.modified &&
                                                <p><span className={classes.labelData}>Modified at: </span>{ file.modified }<br/></p>
                                        }
                                        {file.modifiedBy &&
                                                <p><span className={classes.labelData}>Modified by: </span>{ file.modifiedBy }<br/></p>
                                        }


                                 </div>
                            </Grid>
                             <Comments id={this.props.match.params.id} type="file"/>
                          </Grid>
                       }
                        </Paper>
                      </Grid>
                      <Grid md={6} lg={6} xs={12} item container justify="flex-start" direction="row">
                        <Paper className={classes.paper}>
                        {file && file.versions && file.versions.length > 0 &&
                                <Grid md={12} lg={12} xs={12} item className="profile-desk" style={{marginLeft:10}}>
                                 <h3>File versions</h3>
                                 {file.versions.map((version, k) =>
                                  <div className="m-top30" key={k}>
                                          <p><span className={classes.labelData}>Version { version.version }: </span>{ version.name }<br/></p>
                                          <p><span className={classes.labelData}>Created by: </span>{ version.createdBy }<br/></p>
                                          <p><span className={classes.labelData}>Created at: </span>{ version.created }<br/></p>
                                          {version.last &&
                                                  <p><span className={classes.labelData}>Last: </span><i className="fas fa-check"></i><br/></p>}
                                          <Button href='' onClick={this.clickFileDownload('/api/members/documents/files/'+version.id+'/version/download', version.name)} variant="outlined">Download</Button>
                                  </div>
                                  )}

                            </Grid>
                        }
                        </Paper>
                      </Grid>
                   </Grid>
            </Grid>
                            <Dialog
                                onClose={this.closeReplace}
                                aria-labelledby="simple-dialog-title"
                                open={this.state.openReplace}
                                maxWidth="sm"
                                fullWidth
                                BackdropProps={{
                                classes: {
                                 root: classes.rootDialog
                                }
                               }}>
                                 <DialogTitle id="simple-dialog-title">Replace file <span className="pull-right pointer" onClick={this.closeReplace}>
                                 <i className="far fa-times-circle"></i></span>
                                 </DialogTitle>
                                 <DialogContent>
                                   <form method="post" onSubmit={this.handleSubmit}>
                                    <label>Upload edited file version</label>
                                <div style={{display: "flex", margin: "20px 0"}}>
                                     <Dropzone
                                            maxSize={20000000}
                                            onDrop={acceptedFiles => this.handleChange(acceptedFiles)}
                                            onDropRejected={this.onDropRejected}
                                            >
                                            {({getRootProps, getInputProps}) => (
                                              <section>
                                                <div {...getRootProps()}>
                                                  <input {...getInputProps()} />
                                                  <button type="button" className="upload-btn btn btn-success">
                                                  <i style={{marginRight: 5}} className="fa fa-upload"></i> Upload </button>
                                                </div>
                                              </section>
                                            )}
                                          </Dropzone>
                                        <button type="submit" className="btn btn-default" style={{marginLeft: 15}}>Submit</button>
                                      </div>
                                    </form>
                                   </DialogContent>
                                </Dialog>
                                   <Dialog
                                onClose={this.closePrivacy}
                                aria-labelledby="simple-dialog-title"
                                open={this.state.openPrivacy}
                                maxWidth="md"
                                fullWidth
                                className="customDialog"
                                BackdropProps={{
                                classes: {
                                 root: classes.rootDialog
                                }
                               }}
                               >
                                 <DialogTitle id="simple-dialog-title">Change privacy of the file
                                 <span className="pull-right pointer" onClick={this.closePrivacy}>
                                 <i className="far fa-times-circle"></i></span>
                                 </DialogTitle>
                                 <DialogContent>
                                 <form method="post" style={{minHeight: 300}} onSubmit={this.handleSubmitPrivacy}>
                                   <Grid
                                      container
                                      direction="row"
                                    >
                                           <Grid md={6} lg={6} xs={12} item>
                                            <div className="m-bot15">
       <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel style={{fontSize: 14, marginTop: 15, marginBottom: 16, textTransform: "uppercase",fontFamily: 'OpenSans ,sans-serif',
        color: "#333333"}}>{'Choose privacy'}</FormLabel>
        <RadioGroup aria-label={'Choose privacy'} name="privacy" value={this.state.privacyValue} style={{display: "inline"}} >
        {privacyOptions.map((field, key) => {
          return <FormControlLabel
          value={String(field.value)}
          key={key}
          control={<Radio />}
          label={field.label}
          checked={field.value == String(this.state.privacyValue) ? true : false}
          onChange={this.handleChangePrivacy}
          style={{fontSize: 14,fontFamily: 'OpenSans ,sans-serif'}}
          />
          })}

        </RadioGroup>

      </FormControl>

                                         </div>
                                         </Grid>

                                      <Grid md={12} lg={12} xs={12} item>
                                       <div className="m-bot15">
                                         <label>Share for edit: </label>
                                         {this.props.documents.userMembers && this.props.documents.userMembers.length > 0 &&
                                                 <Select
                                                    isMulti
                                                    value={this.state.userMembers}
                                                    onChange={(value) => this.onChangeUserMembers(value)}
                                                    onBlurResetsInput={false}
                                                    onCloseResetsInput={false}
                                                    menuPlacement={"auto"}
                                                    options={userMembers}
                                                    className="select2-position"
                                                />
                                        }
                                         </div>
                                        </Grid>
                                        <Grid md={12} lg={12} xs={12} item>
                                         <div className="m-bot30" style={{position:"relative",top: 100}}>
                                          <button type="submit" className="button-primary" style={{marginLeft: 0}}>CHANGE</button>
                                          </div>
                                         </Grid>
                                      </Grid>
                                   </form>
                                 </DialogContent>
                                </Dialog>
                     <Dialog
                        onClose={this.closeConfirm}
                        aria-labelledby="simple-dialog-title"
                        open={this.state.openConfirm}
                        maxWidth="sm"
                        fullWidth
                        BackdropProps={{
                        classes: {
                         root: classes.rootDialog
                        }
                       }}>

                         <DialogContent>
                         <section className="newPanel">
                            <div className="panel-body">
                                <div>
                                    <div className="text-center">

                                        <h3>
                                            Are you sure?
                                        </h3>
                                        <button className="button-outlines green text-center"
                                                onClick={this.closeConfirm}>
                                            No
                                        </button>
                                        <button className="button-primary-edited text-center btn-ok"
                                                onClick={this.overrideLock}>
                                           Yes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>
                         </DialogContent>
                      </Dialog>
                             {this.props.documents.file &&
                                 <Dialog
                                onClose={this.closeSendMessage}
                                aria-labelledby="simple-dialog-title"
                                open={this.state.openSendMessage}
                                maxWidth="md"
                                fullWidth
                                BackdropProps={{
                                classes: {
                                 root: classes.rootDialog
                                }
                               }}>
                                 <DialogTitle id="simple-dialog-title">
                                Send message to {this.props.documents.file.startEdit} to unlock this file:
                                <span className="pull-right pointer" onClick={this.closeSendMessage}>
                                 <i className="far fa-times-circle"></i></span>
                                 </DialogTitle>
                                 <DialogContent>
                                   <form method="post" onSubmit={this.handleSubmitSendMessage}>
                                    <label> </label>
                                      <textarea onChange={this.handleChangeMessage} value={this.state.sendMessage}></textarea>
                                        <button type="submit" className="button-primary m-top30" style={{marginLeft:0}}>SEND</button>
                                    </form>
                                   </DialogContent>
                                </Dialog>}
                                      {this.props.documents.file &&
                                 <Dialog
                                onClose={this.closeSendFile}
                                aria-labelledby="simple-dialog-title"
                                open={this.state.openSendFile}
                                maxWidth="md"
                                 scroll={'paper'}
                                fullWidth
                                PaperProps={{
                                    classes: {
                                 root: classes.paperDialog
                                 }
                                    }}
                                BackdropProps={{
                                classes: {
                                 root: classes.rootDialog
                                }
                               }}>
                                 <DialogTitle id="simple-dialog-title">
                                Send file
                                <span className="pull-right pointer" onClick={this.closeSendFile}>
                                 <i className="far fa-times-circle"></i></span>
                                 </DialogTitle>
                                 <DialogContent>
                                  <Grid container   direction="column" justify="space-between" alignItems="stretch"  >
                                  {this.state.errorSendFile &&  <Grid md={12} lg={12} xs={12} item>
                                       <div style={{color: 'red', fontSize: 18}}>
                                  {this.state.errorSendFile}
                                  </div>
                                        </Grid>}
                                   <form method="post" onSubmit={this.handleSubmitSendFile} style={{minHeight: 500}}>
                                   <Grid md={12} lg={12} xs={12} item>
                                       <div className="m-all30">
                                    <label>Message: </label>
                                      <textarea onChange={this.handleChangeTextFile} value={this.state.sendText} style={{width: '100%'}}></textarea>
                                       </div>
                                        </Grid>
                                       <Grid md={12} lg={12} xs={12} item>
                                       <div className="m-all30">
                                         <label>Send file to: </label>
                                         {this.props.documents.file.selectData && this.props.documents.file.selectData.length > 0 &&
                                                 <Select
                                                    isMulti
                                                    value={this.state.sendUsers}
                                                    onChange={(value) => this.onChangeSendUsers(value)}
                                                    onBlurResetsInput={false}
                                                    onCloseResetsInput={false}
                                                    menuPlacement={"auto"}
                                                    options={this.props.documents.file.selectData}
                                                    style={{width: '100%'}}
                                                    className="select2-position"
                                                />
                                        }
                                         </div>
                                        </Grid>
                                        <Grid md={12} lg={12} xs={12} item>
                                         <div className="m-all30" style={{position:"relative",top: 100}}>
                                        <button type="submit" className="btn btn-default">Send</button>
                                             </div>
                                        </Grid>
                                    </form>
                                    </Grid>
                                   </DialogContent>
                                </Dialog>}
        </Layout>
        );
    }
}

FileProfile.propTypes = {
    replaceFile: PropTypes.func.isRequired,
    getFile: PropTypes.func.isRequired,
    downloadFile: PropTypes.func.isRequired,
    documents: PropTypes.object.isRequired,
    auth:  PropTypes.object.isRequired,
    changePrivacy: PropTypes.func.isRequired,
    getUserMembers: PropTypes.func.isRequired,
    unlockFile: PropTypes.func.isRequired,
    sendMessage: PropTypes.func.isRequired,
    sendFile: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    documents: state.documents,
    auth: state.auth
});
FileProfile = withStyles(styles)(FileProfile)
export default connect(mapStateToProps, { replaceFile, getFile, downloadFile, changePrivacy, getUserMembers, unlockFile, sendMessage, sendFile })(withRouter(FileProfile));
