import {
    SAVE_FOR_LATER_LIST,
    SAVE_FOR_LATER_LOADING
} from '../actions/types';

const initialState = {
    saveForLater: null,
    saveForLaterLoading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SAVE_FOR_LATER_LIST:
            return {
                ...state,
                saveForLater: action.payload
            };
        case SAVE_FOR_LATER_LOADING:
            return {
                ...state,
                saveForLaterLoading: true
            };
   
        default:
            return state;
    }
}
