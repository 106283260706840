import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import logo from '../../img/logo.png';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuList from '@material-ui/core/MenuList';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  paper: {
    top: 0,
  },
  rootList: {
    display: "flex",
    margin: "0 auto",
    width: "33%",
  },
  rootItem:{
    fontSize: 14,
    color: "#0a0a0a",
    marginLeft: 20,
    background: 'white',
    textDecoration: 'none',
    fontFamily: "OpenSans, sans-serif",
    '&:hover, &:focus': {
      backgroundColor: "transparent",
      outline: "none",
    }
  },
   rootItemSmall:{
    fontSize: 14,
    color: "#0a0a0a",
    height: 20,
    marginLeft: 20,
    background: 'white',
    textDecoration: 'none',
    fontFamily: "OpenSans, sans-serif",
    margin: "0 auto",
    '&:hover, &:focus': {
      color: "#000",
     backgroundColor: "transparent",
     outline: "none",
   }
 },
  bottomArea: {
    position: 'fixed',
    padding: 0,
    bottom: 0,
    width: '100%',
    height: '12%',
    background: '#33ad50',
    background: '-webkit-linear-gradient(right,#33ad50,#069c95)',
    background: '-o-linear-gradient(left,#33ad50,#069c95)',
    background: '-moz-linear-gradient(left,#33ad50,#069c95)',
    background: 'linear-gradient(to left,#33ad50,#069c95)',
    marginTop: '30px'
},
link: {
    color: 'white',
    padding: '35px',
    fontSize: '22px',
    textTransform: 'capitalize',
    '&:hover': {
       color: 'white',
      textDecoration: 'none',
    }
},
rootSmallItem:{
    color: '#999',
    fontSize: '14px',
    textDecoration: 'none',
    fontFamily: "OpenSans, sans-serif",
    marginLeft: 20,
    '&:hover': {
      background: 'white',
      textDecoration: 'none',
    }
  }
});

const ITEM_HEIGHT = 48;

const paperprops = {
  style: {
    background: 'white',
    position: 'absolute',
    overflow:'hidden',
    width: "100%",
    maxWidth: "100%",
    height: 50
  }
}

class MenuNavbar extends Component {

  state = {
      anchorEl: null,
      height: 0,
      showThirdMenu: false,
      showFourMenu: false
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ height: window.innerHeight });
  }

  handleClick = event => {
    event.preventDefault();
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      showThirdMenu: false,
      showFourMenu: false
    });
  };
  showMenu1 = () => {
    this.setState(state => ({ showThirdMenu: true }));
  };

  showMenu2 = () => {
    this.setState(state => ({ showFourMenu: true }));
  };

  handleScroll = () => {
    this.setState({  anchorEl: null, showThirdMenu: false, });
    this.setState({  anchorEl: null, showFourMenu: false, });
  }

  render() {

    const { classes } = this.props;

    let itemClass = classes.rootItem;

    if(this.state.height < 820) {
      itemClass = classes.rootItemSmall;
    }

    return (
      <div style={{top: 0}} >
        <center className="menu-button pull-right">
          <a href="/#" onClick={this.handleClick} className="text-uppercase login-link">
            More <Icon className='fa fa-angle-down' style={{ fontSize: 16 }}/>
          </a>
        </center>

        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
          style={{top: 53, left: 0, right: 0}}
          height="30%"
          autoFocus= { false }
          MenuListProps={{  className: classes.rootList }}
          PaperProps={paperprops}
          >
            <span className="third-menu">
            { !this.state.showThirdMenu && !this.state.showFourMenu &&
              <React.Fragment>
                <MenuItem  component={Link} to="/calendar" className="text-uppercase" classes={{root: itemClass}}>
                  Event calendar
                </MenuItem>
                <MenuItem component={Link} to="#" className="text-uppercase" classes={{root: itemClass}} onClick={this.showMenu1}>
                  Global forums
                </MenuItem>
                <MenuItem component={Link} to="#" className="text-uppercase" classes={{root: itemClass}} onClick={this.showMenu2}>
                  Annual Reports
                </MenuItem>
                <MenuItem  component={Link} to="/news" className="text-uppercase" classes={{root: itemClass}}>
                  News and Stories
                </MenuItem>
              </React.Fragment>
            }
            { this.state.showThirdMenu &&
              (
                <div className="third-menu">
                  <MenuItem  component={Link} to="/asia-forum" className="text-uppercase" classes={{root: itemClass}}>
                    Asia forum 2018
                  </MenuItem>
                  <MenuItem  component={Link} to="/africa-forum" className="text-uppercase" classes={{root: itemClass}}>
                    Africa forum 2019
                  </MenuItem>
                  <MenuItem  component={Link} to="/global-virtual-forum" className="text-uppercase" classes={{root: itemClass}}>
                   Global Forum 2020
                  </MenuItem>
                  <MenuItem  component={Link} to="/global-virtual-forum-2021" className="text-uppercase" classes={{root: itemClass}}>
                   Global Forum 2021
                  </MenuItem>
                  <MenuItem  component={Link} to="/global-virtual-forum-2022" className="text-uppercase" classes={{root: itemClass}}>
                   Global Forum 2022
                  </MenuItem>
                </div>
              )
            }
            { this.state.showFourMenu &&
              (
                <div className="third-menu">
                  <MenuItem  component={Link} to="/news/68" className="text-uppercase" classes={{root: itemClass}}>
                    2016
                  </MenuItem>
                  <MenuItem  component={Link} to="/news/95" className="text-uppercase" classes={{root: itemClass}}>
                    2017
                  </MenuItem>
                  <MenuItem  component={Link} to="/news/105" className="text-uppercase" classes={{root: itemClass}}>
                    2018
                  </MenuItem>
                  <MenuItem   classes={{root: itemClass}}>
                    <a href="/annual-report-2019/index.html" style={{color: "inherit"}} className="text-uppercase">  2019</a>
                  </MenuItem>
                  <MenuItem   classes={{root: itemClass}}>
                    <a href="/annual-report-2020/index.html" style={{color: "inherit"}} className="text-uppercase">  2020</a>
                  </MenuItem>
                  <MenuItem   classes={{root: itemClass}}>
                    <a href="/annual-report-2021/index.html" style={{color: "inherit"}} className="text-uppercase">  2021</a>
                  </MenuItem>
                </div>
              )
            }
          </span>
        </Menu>
      </div>
    );
  }
}


MenuNavbar = withStyles(styles)(MenuNavbar)
export default MenuNavbar;
