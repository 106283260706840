import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Navbar from '../layout/Navbar';
import Grid from '@material-ui/core/Grid';
import { getNews, getNewsCategories, clearNews } from '../../actions/newsActions';
import NewsItem from "./NewsItem";
import Spinner from '../common/Spinner';
import Footer from '../layout/Footer'
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { config } from '../../config'
import { getLogActivityWithGeoIp } from '../../actions/profileActions';

class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            limit: 8,
            offset: 0,
            category: "all"
        }
    }

    componentDidMount() {
        this.props.clearNews()
       let a = this.props.getNews(this.state.offset, this.state.limit, this.state.category);

       this.props.getNewsCategories();
       this.props.getLogActivityWithGeoIp('News page', 'GET', "Website usage")
    }

    componentWillReceiveProps(nextProps) {
           if(nextProps.news.total){
        this.setState({
            total: parseInt(nextProps.news.total)
           })
    }
    if(nextProps.news.offset){
        this.setState({
            offset: parseInt(nextProps.news.offset)
        })
    }
    }

   loadMore = (e) => {
         e.preventDefault();
        this.setState({ offset: this.state.offset+8},() => this.props.getNews(this.state.offset, this.state.limit, this.state.category));
        
    }

    handleCategoryChange = (e) => {        
    this.props.clearNews()
        let id = e.target.id;
        var elems = document.querySelectorAll(".active");

        [].forEach.call(elems, function(el) {
            el.classList.remove("active");
        });
        this.setState({ offset: 0, category : e.target.id},() => this.props.getNews(0, 8, id));

        document.getElementById(e.target.id).classList.add('active');
    }

    render() {
        const { news, total, loading, newsCategories } = this.props.news;

        let newsItem;
        let categoryItem;

            if (news && news.length > 0) {
              newsItem = news.map(singleNews => (
                <NewsItem key={singleNews.id} news={singleNews}/>
              ));
            } else {
              newsItem = <h4>News not found...</h4>;
            }


        if(newsCategories.length > 0){
            categoryItem = newsCategories.map(category => (
                 <li className="text-center" key={category.id}>
                    <a style={{cursor: "pointer"}} id={category.id} onClick={this.handleCategoryChange}>
                       {category.name}
                    </a>
                </li>
            ));
        }
        return (
          <Grid container spaicing={0}>
            <Navbar/>
            <div className="news-list page-content  page-animation slideup">
                <div className="heroshot">
                    <img src={config.API_URL +"/images/org/007f0f.jpeg?v1.0"} alt="image"/>
                    <Grid container direction="row" justify="center" className="center-col">
                      <Grid item md={8} xs={12} className="text-area text-center">
                        <img src={config.API_URL +"/images/org/9cfcf5.png?v1.0"} alt="image"/>
                        <h1 className="text-center text-white">News & Stories</h1>
                        <h2 className="text-white">Explore the news and stories on sustainable health care practices around the world</h2>
                    </Grid>
                      </Grid>
                </div>
                <div className="categories background-white">
                    <div className="max-width">
                        <ul>
                            <li className="text-center">
                                <a style={{cursor: "pointer"}} className='active' onClick={this.handleCategoryChange} id="all">
                                  All
                                </a>
                            </li>
                           {categoryItem}
                        </ul>
                    </div>
                </div>
                <div className="max-width">
                    <Grid container >
                        <Grid item md={8} className="post-list-box">
                            <Grid container spacing={3} style={{
                              paddingLeft: 15,
                              paddingRight: 15
                            }}>
                                {newsItem}
                                <div style={{clear: 'both' }}>
                                </div>
                            </Grid>
                            {(total - this.state.limit) > 0 &&
                                <div className="buttons text-center">
                                    <br/>
                                    <button className="btn text-uppercase blue-button" onClick={this.loadMore}>Load more</button>
                                    <br/>
                                    <br/>
                                </div>
                            }
                            {loading &&
                                    <Spinner/>}
                        </Grid>

                        <Grid item md={4}>
                                <div className="follow-bar col-md-12">
                                  <h3  className="text-left">Follow us</h3>
                                   <a title="Twitter" href="https://twitter.com/search?vertical=default&amp;q=%23act4health" target="_blank">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                    <a title="Linkedin" href="https://www.linkedin.com/company/11201509" target="_blank">
                                        <i className="fab fa-linkedin" aria-hidden="true"></i>
                                    </a>
                                    <a title="ISSUU" href="https://issuu.com/informal_int_task_team_sphs" target="_blank">
                                        <img className="issuu" src={config.API_URL +"/icon.png"}/>
                                    </a>
                                    <a title="Slideshare" href="http://www.slideshare.net/UNSustainableProcurementHealthSector" target="_blank">
                                        <i className="fab fa-slideshare"></i>
                                    </a>
                                    <a title="Youtube" href="https://www.youtube.com/channel/UCX2UQcpPViZVot-0wfBIU-Q" target="_blank">
                                        <i className="fab fa-youtube" aria-hidden="true"></i>
                                    </a>
                                    <a title="Flickr" href="https://www.flickr.com/photos/un_sphs/" target="_blank">
                                        <i className="fab fa-flickr"></i>
                                    </a>
                                    <a title="Subscribe" href="/contribute/register" target="_blank">
                                        <i className="far fa-envelope"></i>
                                    </a>
                                </div>
                                <div className="col-md-12">
                                    <div className="twitter">
                                        <div className="twitter-title">
                                            <h3 className="text-white">Social media feed<br/><span style={{fontSize: "15px", color:"#61d8df"}}>#act4health</span></h3>
                                            <i className="ion-arrow-down-b"></i>
                                        </div>
                                        <TwitterTimelineEmbed
                                            sourceType="profile"
                                            screenName="UN_SPHS"
                                            options={{height: 1200}}
                                          />
                                    </div>
                                </div>
                        </Grid>
                    </Grid>

                </div>
      <Footer/>
            </div>
            </Grid>

        );
    }
}

News.propTypes = {
    getNews: PropTypes.func.isRequired,
    news: PropTypes.object.isRequired,
    clearNews: PropTypes.func.isRequired,
    getLogActivityWithGeoIp: PropTypes.func.isRequired
};


const mapStateToProps = state => ({
        news: state.news
    });

export default connect(mapStateToProps, {getNews, getNewsCategories, clearNews, getLogActivityWithGeoIp})(News);
