import {
    GRID_PROPERTIES_LOADED,
    GRID_HEADER_SHOW_OPTIONS,
    GRID_HEADER_OPTION_LABEL_CHANGED,
    GRID_BULK_OPEN,
    GRID_BULK_ACTION_OPEN,
    GRID_QUICK_FILTER_CHANGE,
    GRID_DATES_CHANGE,
    GRID_HEADER_OPTION_SELECTED,
    GRID_COLUMNS_SHOW,
    GRID_COLUMNS_CHECK,
    GRID_COLUMNS_UPDATE,
    GRID_CONFIRM_DIALOG_SHOW,
    GRID_ROWS_SELECTED,
    GRID_ROWS_SELECTED_CLEAR,
    GRID_ADDITIONAL_DATA,
    GET_PDF_DATA
} from "../actions/types";


export default (state = {}, action) => {
    let header = null;
    let columns = [];
    let bulkActions = state.bulkActions;
   

    switch (action.type) {
        case GRID_PROPERTIES_LOADED:
            return {
                ...state,
                columns: action.payload.columns,
                visibleColumns: action.payload.visibleColumns,
                bulkActions: action.payload.bulkActions,
                header: action.payload.header,
                options: action.payload.options,
            };

        case GRID_ROWS_SELECTED:
            return {
                ...state,
                selectedRowsCount: action.payload.rows
            };

        case GRID_HEADER_OPTION_SELECTED:
            let filters = action.payload.option.filters;           
            if (!filters) return state;
            filters.forEach(filter => {                
                if (action.payload.gridApi.gridCore.columnApi.getColumn(filter.column)) {    
                    let f = action.payload.gridApi.getFilterInstance(filter.column);
                    f.setModel({
                        type: filter.type,
                        filter: filter.value,
                        filterTo: null
                    });                  
                    action.payload.gridApi.onFilterChanged();
                }
            });
            return state;

        case GRID_HEADER_SHOW_OPTIONS:
            header = state.header;
            header.showOptions = 'showOptions' in header ? !header.showOptions : true;
            return {
                ...state,
                header
            };

        case GRID_HEADER_OPTION_LABEL_CHANGED:
            header = state.header;            
            if (!('originalTitle' in header)) header.originalTitle = header.title;
          //  header.title = action.payload.option.label + ' ' + header.originalTitle;
            header.selectedFilter = action.payload.option.label;
            return {
                ...state,
                header
            };

        case GRID_BULK_OPEN:
            bulkActions.forEach((bulk, key) => {
                if (bulk.label === action.payload.bulk.label) {
                    bulkActions[key].showDropdown = 'showDropdown' in bulkActions[key] ? !bulkActions[key].showDropdown : true;
                    if (!bulkActions[key].showDropdown && 'actions' in bulk) {
                        bulk.actions.forEach((option, actionKey) => {
                            bulkActions[key].actions[actionKey].showDropdown = false;
                        });
                    }
                }
            });
            return {
                ...state,
                bulkActions
            };

        case GRID_BULK_ACTION_OPEN:
            bulkActions.forEach((bulk, key) => {
                if (bulk.label === action.payload.bulk.label) {
                    bulk.actions.forEach((option, actionKey) => {
                        if (option.label === action.payload.action.label) {
                            bulkActions[key].actions[actionKey].showDropdown = 'showDropdown' in bulkActions[key].actions[actionKey] ? !bulkActions[key].actions[actionKey].showDropdown : true;
                        }
                    })
                }
            });
            return {
                ...state,
                bulkActions
            };

        case GRID_QUICK_FILTER_CHANGE:
            return {
                ...state,
                quickFilter: action.payload.value
            };

        case GRID_DATES_CHANGE:
            return {
                ...state,
                startDate: action.payload.startDate,
                endDate: action.payload.endDate,
            };

        case GRID_COLUMNS_SHOW:
            return {
                ...state,
                showColumns: 'showColumns' in state ? !state.showColumns : true
            };

        case GRID_COLUMNS_CHECK:
            columns = state.columns;
            const key = action.payload.key;
            const childKey = action.payload.childKey;
            if (childKey) columns[key][childKey] = 'hide' in columns[key][childKey] ? !columns[key][childKey].hide : true;
            else {
                const hide = 'hide' in columns[key] ? !columns[key].hide : true;
                columns[key].hide = hide;
                if ('children' in columns[key]) {
                    columns[key].children.map((child, k) => {
                        columns[key][k] = hide;
                    });
                }
            }
            return {
                ...state,
                columns
            };

        case GRID_COLUMNS_UPDATE:
            //make columns visible or not in grid
            columns = state.columns;
            columns.map(column => {
                //if has children itterate them and check
                if (column.children) {
                    column.children.map(child => {
                        if (!child.hide) {
                            action.payload.gridColumnApi.setColumnVisible(child.field, true);
                        } else {
                            action.payload.gridColumnApi.setColumnVisible(child.field, false);
                        }
                    })
                } else {
                    if (!column.hide) {
                        action.payload.gridColumnApi.setColumnVisible(column.field, true);
                    } else {
                        action.payload.gridColumnApi.setColumnVisible(column.field, false);
                    }
                }
            });
            return state;

        case GRID_CONFIRM_DIALOG_SHOW:
            let confirm = 'confirm' in state ? state['confirm'] : {};
            confirm = {
                show: 'show' in confirm ? !confirm['show'] : true,
                payload: action.payload
            };
            return {
                ...state,
                confirm
            };
            
        case GRID_ADDITIONAL_DATA:
            return {
                ...state,
                addionalData: action.payload
            };   
        case GET_PDF_DATA:  
            return {
                ...state,
                pdfData: action.payload
            };
        default:
            return state;
    }
};