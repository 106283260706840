import React,  { useState } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Button from '@material-ui/core/Button';
import { removeDependency } from '../../../../actions/questionnaireActions'
import showAToast from '../../../../helpers/showAToast'

function DialogRemoveDependency(props) {     
 
  const [open, setOpen] = useState(false);
  
  function handleYes() {
     props.removeDependency(props.field.fieldId)    
     setOpen(false)    
      setTimeout(() => {
           props.updateForm()
      }, 3000)
  }
      
    return  (<div> 
    <Button 
              variant="contained" 
              color="primary" 
              size="small"
              onClick={() => setOpen(true)} 
              >Remove dependency</Button>
            <Dialog
                onClose={() => setOpen(false)}
                disableBackdropClick
                disableEscapeKeyDown
                open={open}
                maxWidth="sm"
                fullWidth
                 scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
                >
                <DialogTitle id="simple-dialog-title">Remove dependency<span className="pull-right pointer" onClick={() => setOpen(false)}>
                         <i className="far fa-times-circle"></i></span>
                         </DialogTitle>
                 <DialogContent>
                 <section className="newPanel">
                    <div className="panel-body">
                        <div>
                            <div className="text-center">
                                <h3>
                                    Are you sure?
                                </h3>
                                <button className="button-outlines green text-center"
                                        onClick={() => setOpen(false)}>
                                    No
                                </button>
                                <button className="button-primary-edited text-center btn-ok"
                                        onClick={handleYes}>
                                   Yes
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                 </DialogContent>
                </Dialog>
   </div>);

}

DialogRemoveDependency.propTypes = {
  removeDependency: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
 // questionnaire: state.questionnaire,
});

export default connect( mapStateToProps, { removeDependency })(DialogRemoveDependency);