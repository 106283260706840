import {VALID, NOT_VALID, NOT_FOCUSED} from './ValidatorTypes';
import Ajv from 'ajv';

class Validator {

    initialValidation(form, schema, data = {}) {
        let defaultValid = NOT_FOCUSED;
        let noData = !data || Object.keys(data).length === 0 || !('id' in data);
        if (!noData) defaultValid = VALID;
        else data = {};

        let result = {};
        form.forEach((field) => {
            result[field.id] = {type: defaultValid};
        });

        return this.validate(form, schema, data, null, result);
    }

    validate(form, schema, data = {}, field = null, state = null) {
        // validate by schema
        let errors = this.getErrors(schema, data, form);
        // get errors for specific field
        if (field) {
            let valid = {};
            if (field in errors && field in state)
                valid = state[field].type === NOT_FOCUSED ? {type: NOT_FOCUSED, errors: errors[field]} : {
                    type: NOT_VALID,
                    errors: errors[field]
                };
            else
                valid = field in state && state[field].type === NOT_FOCUSED ? {type: NOT_FOCUSED} : {type: VALID};

            if (field in errors)
                valid.errors = errors[field];

            return {
                ...state,
                [field]: valid
            };
        }

        // get errors for all fields
        let result = {};
        form.forEach((formField) => {
            if (formField.fields) {
                formField.fields.forEach(innerField => {
                    if (innerField.id in errors && innerField.id in state) {
                        result[innerField.id] = state[innerField.id].type === NOT_FOCUSED ? {
                            type: NOT_FOCUSED,
                            errors: errors[innerField.id]
                        } : {
                            type: NOT_VALID,
                            errors: errors[innerField.id]
                        };
                    }
                });
            }
            else if (formField.id in errors && formField.id in state) {
                result[formField.id] = state[formField.id].type === NOT_FOCUSED ? {
                    type: NOT_FOCUSED,
                    errors: errors[formField.id]
                } : {
                    type: NOT_VALID,
                    errors: errors[formField.id]
                };
            }
            else result[formField.id] = formField.id in state && state[formField.id].type === NOT_FOCUSED ? {type: NOT_FOCUSED} : {type: VALID};

            if (formField.id in errors)
                result[formField.id]['errors'] = errors[formField.id];
        });

        return result;
    }

    getErrors(schema, data, form) {
        let validate = (new Ajv({allErrors: true})).compile(schema);
        let valid = validate(data);
        let errors = {};

        if (!valid) {
            validate.errors.forEach(error => {
                const fromArray = error.dataPath.indexOf('[') !== -1;
                let property = '';
                if (fromArray) property = error.dataPath.substring(1, error.dataPath.indexOf('[') !== -1 ? error.dataPath.indexOf('[') : error.dataPath.length - 1);
                else property = error.dataPath.substring(1, error.dataPath.length);

                let index = '';
                let field = null;


                if (error.keyword === 'required') {
                    if (!fromArray) property = error.params.missingProperty;
                    else field = error.params.missingProperty;
                }

                let keyword = error.keyword === 'minLength' && error.params.limit === 1 ? 'required' : error.keyword;
                if (fromArray) {
                    if (!field) field = error.dataPath.substring(error.dataPath.indexOf(']') + 2, error.dataPath.length);
                    if (field) {
                        index = error.dataPath.substring(error.dataPath.indexOf('[') + 1, error.dataPath.indexOf(']'));
                        if (property in data && index !== data[property].length - 1) {
                            if (!(property in errors))
                                errors[property] = [];

                            errors[property].push({index, field, type: keyword});
                        }
                    }
                }
                else {
                    if (!(property in errors))
                        errors[property] = [];
                    errors[property].push(keyword);
                }
            });
        }

        if (form) {
            form.forEach((field) => {
                errors = checkCustomRules(field, errors, data);
            });
        }

        return Object.keys(errors).length === 0 ? [] : errors;
    }
}

export default new Validator();

function checkCustomRules(field, errors, data) {
    if ('customRules' in field) {
        field.customRules.forEach(rule => {
            if (rule.type === 'sameAs' && field.id in data) {
                if (!(rule.field in data)) {
                    if (!(field.id in errors)) errors[field.id] = [];
                    errors[field.id].push('sameAs');
                }
                else if (data[rule.field] !== data[field.id]) {
                    if (!(field.id in errors)) errors[field.id] = [];
                    errors[field.id].push('sameAs');
                }
            }

            if (rule.type === 'dateRange' && field.id in data) {
                let date = data[field.id];
                if(date.indexOf('/') !== -1) {
                    date = date.split('/');
                    date = new Date(date[2], parseInt(date[1]) - 1, date[0]);
                }
                else {
                    date = new Date(date);
                }

                let startDate = new Date(rule.startDate);
                let endDate = new Date(rule.endDate);
                if(date > endDate || date < startDate) {
                    if (!(field.id in errors)) errors[field.id] = [];
                    errors[field.id].push('dateRange');
                }
            }
        });
    }
    else if ('fields' in field) {
        field.fields.forEach(innerField => {
            errors = checkCustomRules(innerField, errors, data);
        });
    }

    return errors;
}