import React from 'react';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

class Contacts extends React.Component {

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.addNewContact = this.addNewContact.bind(this);
        this.removeRow = this.removeRow.bind(this);
        this.addNewAddress = this.addNewAddress.bind(this);
    }

    onChange(e, collection, value, key) {
        if(collection === 'contacts'){
            if(value === 'label'){
                if(this.props.data.contacts[key].type === undefined){
                    this.props.change('contacts['+key+'][type]', "1");
                }
                this.props.data.contacts[key].label = e.target.value;
                this.props.change('contacts['+key+']['+value+']', e.target.value);
            }
            if(value === 'type'){
                this.props.data.contacts[key].type = e.target.value;
                this.props.change('contacts['+key+']['+value+']', e.target.value);
            }
        }
        else if(collection === 'addresses'){
            if(value === 'addrLine1'){
                this.props.data.addresses[key].addrLine1 = e.target.value;
                this.props.change('addresses['+key+']['+value+']', e.target.value);
            }
            if(value === 'addr2'){
                this.props.data.addresses[key].addrLine2 = e.target.value;
                this.props.change('addresses['+key+']['+value+']', e.target.value);
            }
            if(value === 'city'){
                this.props.data.addresses[key].city = e.target.value;
                this.props.change('addresses['+key+']['+value+']', e.target.value);
            }
            if(value === 'country'){
                this.props.data.addresses[key].country = e.target.value;
                this.props.change('addresses['+key+']['+value+']', e.target.value);
            }
            if(value === 'postCode'){
                this.props.data.addresses[key].postCode = e.target.value;
                this.props.change('addresses['+key+']['+value+']', e.target.value);
            }
            if(value === 'region'){
                this.props.data.addresses[key].region = e.target.value;
                this.props.change('addresses['+key+']['+value+']', e.target.value);
            }

        }

    }

    addNewContact(e) {
        e.preventDefault();
        let contacts = this.props.data.contacts;
        let newContact = {};
        contacts.push(newContact);
        this.setState({
            contacts
        });
    }

    removeRow(type, key) {
        if(type === 'contacts'){
            let contacts = this.props.data.contacts;
            delete contacts[key];
            this.setState({
                contacts
            });
        }else if(type === 'addresses'){
            let addresses = this.props.data.addresses;
            delete addresses[key];
            this.setState({
                addresses
            });
        }
    }

    addNewAddress(e) {
        e.preventDefault();
        let addresses = this.props.data.addresses;
        let newAddress = {};
        addresses.push(newAddress);
        this.setState({
            addresses
        });
    }

    render() {
        if(this.props.field.collectionType === 'contacts'){
            return <React.Fragment>
                {this.props.data.contacts && this.props.data.contacts.length > 0 && this.props.data.contacts.map((contact, key) =>
                            <React.Fragment key={key}>
                              <Grid container direction="row" spacing={4}>
                              <Grid item md={6}>
                                <select className="form-control form-contacts" key={key + 'type'} value={contact.type} onChange={(e) => this.onChange(e, 'contacts', 'type', key)} style={{height: 48}}>
                                    <option value='1'>Email</option>
                                    <option value='2'>Phone</option>
                                    <option value='3'>Mobile</option>
                                    <option value='4'>Google plus</option>
                                    <option value='5'>Facebook</option>
                                    <option value='6'>Linkedin</option>
                                    <option value='7'>Twitter</option>
                                    <option value='8'>Skype</option>
                                    <option value='9'>Fax</option>
                                    <option value='10'>Website</option>
                                    <option value='11'>Other</option>
                                </select>
                                </Grid>
                                <Grid item md={6}>
                                <input className="form-control form-contacts height48" key={key + 'value'} value={contact.label} onChange={(e) => this.onChange(e,'contacts', 'label', key)}/>
                                <br/>
                                <Button size="small" variant="outlined" color="secondary" onClick = {() => this.removeRow('contacts', key)}>Remove contact</Button>
                                <br/><br/>
                                </Grid>
                              </Grid>
                            </React.Fragment>
                            )}
                    <Button variant="outlined" color="primary" onClick = {this.addNewContact}>Add new contact</Button>
            </React.Fragment>
        }
        if(this.props.field.collectionType === 'address'){
            return <React.Fragment>
                {this.props.data.addresses && this.props.data.addresses.length > 0 && this.props.data.addresses.map((address, key) =>
                            <React.Fragment key={key}>
                          <Grid container direction="row" spacing={4}>
                            <Grid item md={6}>
                                <label className="formAddress-label">Country</label>
                                <input className="form-control formAddress height48" key={key + 'country'} value={address.country} onChange={(e) => this.onChange(e, 'addresses', 'country', key)}/>
                                <label className="formAddress-label">Region</label>
                                <input className="form-control formAddress height48" key={key + 'region'} value={address.region} onChange={(e) => this.onChange(e, 'addresses', 'region', key)}/>
                                <label className="formAddress-label">City</label>
                                <input className="form-control formAddress height48" key={key + 'city'} value={address.city} onChange={(e) => this.onChange(e, 'addresses', 'city', key)}/>
                                </Grid>
                                <Grid item md={6}>
                                    <label className="formAddress-label">Address Line 1</label>
                                    <input className="form-control formAddress height48" key={key + 'addrLine1'} value={address.addrLine1} onChange={(e) => this.onChange(e, 'addresses', 'addrLine1', key)}/>
                                    <label className="formAddress-label">Address Line 2</label>
                                    <input className="form-control formAddress height48" key={key + 'addrLine2'} value={address.addrLine2} onChange={(e) => this.onChange(e, 'addresses', 'addr2', key)}/>
                                    <label className="formAddress-label">Post code</label>
                                    <input className="form-control formAddress height48" key={key + 'postCode'} value={address.postCode} onChange={(e) => this.onChange(e, 'addresses', 'postCode', key)} />
                                    <br/>
                                    <Button size="small" variant="outlined" color="secondary" onClick = {() => this.removeRow('addresses', key)}>Remove address</Button>
                                    <br/><br/>
                                </Grid>
                              </Grid>
                            </React.Fragment>
                            )}
                    <Button variant="outlined" color="primary" onClick = {this.addNewAddress}>Add new address</Button>
            </React.Fragment>
        }
    }
}

const mapStateToProps = state => ({
    });

const mapDispatchToProps = dispatch => ({
    });

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
