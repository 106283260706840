import {
    GET_MEMBER,
    GET_MEMBERS,
    MEMBER_LOADING
} from '../actions/types';

const initialState = {
    member: {},
    members: [],
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case MEMBER_LOADING:
            return {
                ...state,
                loading: true
            };
        case GET_MEMBER:
            return {
                ...state,
                member: action.payload,
                loading: false
            };

        case GET_MEMBERS:
            return {
                ...state,
                members: action.payload,
                loading: false
            };

        default:
            return state;
}
}
