import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getSdgsFilter } from '../../actions/sdgActions'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import InputAdornment from '@material-ui/core/InputAdornment';
import {getOrgTypes} from '../../actions/codeActions'
import { getSectorsFilter } from '../../actions/sectorsActions'
import { getTypes, getContributionsFilter } from '../../actions/contributionActions'
import { getRegionsFilter, getCountriesFilter, getSelectedLocationsFilter } from '../../actions/regionActions'
import { withRouter } from 'react-router-dom';
import LoginForm from '../layout/LoginForm';
import classNames from 'classnames';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    ontSize: 22,
    margin: 0,
        height: 48,
  },
   root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: "0 15px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
    maxWidth: 300,
    fontSize: 22,
    float: 'left',
    marginLeft: '15px',
  },
  formLabel: {
      fontSize: 14,
      fontWeight: 300,
  },
  formInput: {
      fontSize: 14,
  },
  dialogTitle:{
    fontSize: 22,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      minWidth: 300,
    },
  },
};

class Filter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            sdg: '',
            type: '',
            region:'',
            country:'',
            countries: [],
            locations: [],
            organizationType: '',
            open: false,
            params: new URLSearchParams(window.location.search),
            offset: 0,
            hasFilter: false,
            selectedLocations: [],
            sector: '',
            activeUp: '',
            activeArch: '',
            filters: {
                id: this.props.id,
                sector: [],
                search: '',
                sdg: [],
                type: [],
                location: [],
                organizationType: [],
                load: false
            }
        }

    }

    componentDidMount() {
       this.props.getSdgsFilter()

       this.props.getOrgTypes()
       this.props.getRegionsFilter()

       if(this.props.calendar){
           this.props.getSectorsFilter()
       }else{
           this.props.getTypes()
       }

    //get params from url and set them in array
    let filters = this.state.filters;
    let keys = []
   if(this.props.id){
       filters['id'] = this.props.id;
   }
    Object.keys(this.state.filters).map(key => {

        let array = filters[key];
         let value;
         if(Array.isArray(array) && this.state.params.has(key+'[]')){
             keys.push(key);
            value = this.state.params.getAll(key+'[]')
            value = value[0].split(",")
            array.push(value)
            filters[key]=array[0]
            if(key === 'location'){
                this.props.getSelectedLocationsFilter(array[0])
            }
         }else{
            if(this.state.params.has(key+'[]')){
                keys.push(key);
              array= this.state.params.get(key+'[]');
              filters[key]=array
              if(array == 1){
             this.setState({
                 activeUp: 'active',
                 activeArch: ''
             })
         }else if(array == 0){
             this.setState({
                  activeUp: '',
                 activeArch: 'active'
             })
         }
            }
         }

    })
    this.setState({ filters: filters})

    if(keys.length > 0){
       this.setState({ hasFilter: true})
    }
    //triger parent function to render data
    this.props.onChange(filters)
    }

    componentWillReceiveProps(nextProps) {
       if(nextProps.region.countriesFilter){
           this.setState({ countries: nextProps.region.countriesFilter });
       }
       if(nextProps.region.selectedFilter){
           this.setState({ selectedLocations: nextProps.region.selectedFilter });
       }
    }

    handleApply = () => {
        let filters = this.state.filters;
         if(this.props.id){
       filters['id'] = this.props.id;
   }
        let array = this.state.filters['location']
          if(this.state.country){
            if(!array.includes(this.state.country.toString())){
              array.push(this.state.country.toString())
              this.setState({ hasFilter: true})
           }
          }else if(this.state.region){
              if(!array.includes(this.state.region.toString())){
              array.push(this.state.region.toString())
              this.setState({ hasFilter: true})
           }
          }
         this.props.getSelectedLocationsFilter(array)
         filters['location']= array;
         this.state.params.set('location[]', array)
         window.history.replaceState({}, '', `${window.location.pathname}?${this.state.params}`);

         this.setState({
            filters: filters,
            open: false,
            country: '',
            region: ''
         });
         //triger parent function to render data
        this.props.onChange(filters)
        this.setStyleOnClose();
    }

     handleClickOpen = () => {
        this.setState({ open: true });
        this.setStyleOnOpen();
      };

      handleClose = () => {
          //close form and clear country and region
        this.setState({
            open: false,
            country: '',
            region: ''
        });
        this.setStyleOnClose();
      };

      removeFilter = (key, value) => event => {
         event.preventDefault();
         
        let filters = this.state.filters;
            let array = []
             if(this.props.id){
                 filters['id'] = this.props.id;
             }
            if(key === 'search'){
                array = ''
            }else{
            this.state.filters[key].map((element) => {
               if(String(element) !== String(value)){
                   array.push(element)
               }
            })
            }

            filters[key]= array;
            if(key === 'locations'){
              this.props.getSelectedLocationsFilter(array)
            }

             this.setState({
                  filters: filters,
                  [key]: ''
            });
             //triger parent function to render data
               this.props.onChange(filters)
               let name = key +'[]'
               if(array.length < 1){
                   this.state.params.delete(name)
               }else{
                 this.state.params.set(name, filters[key])
               }
               window.history.replaceState({}, '', `${window.location.pathname}?${this.state.params}`);

        let keys = []
        if('id' in filters){
           delete filters['id'];
        }
         Object.keys(this.state.filters).map(key => {
             let array = filters[key];
              let value;
              if(Array.isArray(array) && this.state.params.has(key+'[]')){
                  keys.push(key);
              }else{
                 if(this.state.params.has(key+'[]')){
                     keys.push(key);
              }
             }

         })

         if(keys.length == 0){
          this.setState({ hasFilter: false})
         }
         
      }



       handleChange = event => {
           //if region get countries
          if(event.target.name === 'region'){
           this.setState({country: ''})
              this.props.getCountriesFilter(event.target.value)
          }

        this.setState({
              [event.target.name]: event.target.value
        });
        //if is not region or country push value in filters array
        if(event.target.name !== 'region' && event.target.name !== 'country'){

            let filters = this.state.filters;
             if(this.props.id){
       filters['id'] = this.props.id;
   }
            let array = this.state.filters[event.target.name]
            if(event.target.name === 'search'){
                array = event.target.value
            }else{
             if(!array.includes(event.target.value.toString())){
                 array.push(event.target.value.toString())
             }
            }
            filters[event.target.name]= array;

             this.setState({
                  filters: filters
            });
             //triger parent function to render data
               this.props.onChange(filters)
               this.setState({ hasFilter: true})
               let name = event.target.name +'[]'
               this.state.params.set(name, filters[event.target.name])
               window.history.replaceState({}, '', `${window.location.pathname}?${this.state.params}`);
        }
  };

   clearFilter = event => {
       event.preventDefault();
           let filters = {
                search: '',
                sdg: [],
                type: [],
                location: [],
                organizationType: []
            }
             if(this.props.id){
       filters['id'] = this.props.id;
   }

      this.setState({
          filters: filters,
          search: '',
            sdg: '',
            type: '',
            region:'',
            country:'',
            countries: [],
            locations: [],
            organizationType: '',
            params: new URLSearchParams(''),
             hasFilter: false
      },function () {
    window.history.replaceState({}, '', `${window.location.pathname}?${this.state.params}`);
});
              this.props.onChange(filters)
              this.props.getSelectedLocationsFilter([]);

      }

     getSearchEvents  = (value) => event => {
           event.preventDefault();

         let data = {
             target: {
                 value: value,
                 name: 'search'
             }
         }
         if(value == 1){
             this.setState({
                 activeUp: 'active',
                 activeArch: ''
             })
         }else{
             this.setState({
                  activeUp: '',
                 activeArch: 'active'
             })
         }
         this.handleChange(data)
     }


    setStyleOnOpen = () => {
        let scrollElement = document.getElementsByTagName("html");
        scrollElement[0].style.overflow='hidden';
    }

    setStyleOnClose = () => {
          let scrollElement = document.getElementsByTagName("html");
          scrollElement[0].style.overflow='scroll';scrollElement[0].style.overflowX='hidden';
    }

    render() {
        const { classes } = this.props;
        const { sdgs } = this.props.sdg;
        const { types } = this.props.contribution;
        const { orgTypes } = this.props.code;
         const { sectorsFilter } = this.props.sector;
        const { regionsFilter, selectedFilter } = this.props.region;
        const { isAuthenticated, user } = this.props.auth;

        return (
        <div className="max-width">
        <Grid item container lg={12} md={12} xs={12} className={classes.root}>
        {!this.props.calendar &&
                <span className="searchRow">

           <LoginForm role="filter"/>


                <TextField
                 id="outlined-search"
                 label="Search..."
                 type="search"
                 classes={{root: classes.textField}}
                 className={"searchField"}
                 margin="normal"
                 variant="outlined"
                 name="search"
                 fullWidth
                 value={this.state.search}
                 onChange={this.handleChange}
                 InputProps={{  className: classes.formInput }}
                 InputLabelProps={{  className: classes.formLabel }}
                 InputProps={{
                   endAdornment: <InputAdornment position="end"><SearchIcon/></InputAdornment>,
                   style: {fontSize: 12, color: "#c5c4c2", height: 48,}
                  }}
               />

                </span>
                }
              {this.props.calendar &&
                       <span className={"calendar-buttons"}>

        <a href="#" className={classNames("transparent-button upcoming", this.state.activeUp)} onClick={this.getSearchEvents(1)}>Upcoming events</a>
        <a href="#" className={classNames("transparent-button", this.state.activeArch)} onClick={this.getSearchEvents(0)}>Archived events</a>
        <div className="clearfix">
        </div>
        </span>
        }

    <Grid item lg={8} sm={12} >
      <FormControl className={classes.formControl}>
          <InputLabel htmlFor="select-sdg"
          style={{fontSize: 18, color: "#3e5262", fontWeight: 300,lineHeight: 0}}>SDGs</InputLabel>
         {sdgs &&  <Select
            value={this.state.sdg}
            onChange={this.handleChange}
            onOpen={this.setStyleOnOpen}
            onClose={this.setStyleOnClose}
            MenuProps={MenuProps}
            SelectDisplayProps={{style: {fontSize: 14, textAlign: "left" }}}
            inputProps={{
              name: 'sdg',
              id: 'sdg-simple',
              style: {fontSize: 14, padding: 10}
            }}
          >
            {sdgs.map(sdg => (
              <MenuItem key={sdg.id} value={sdg.id} style={{fontSize: 14, padding: 10}} label={sdg.value}>
                {sdg.value}
              </MenuItem>
            ))}
          </Select>}
        </FormControl>
         {!this.props.calendar && types &&
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="select-types" style={{fontSize: 18, color: "#3e5262", fontWeight: 300,lineHeight: 0}}>Types</InputLabel>
          <Select
            value={this.state.type}
            onChange={this.handleChange}
            onOpen={this.setStyleOnOpen}
            onClose={this.setStyleOnClose}
            input={<Input id="select-types" />}
            MenuProps={MenuProps}
          SelectDisplayProps={{style: {fontSize: 14, textAlign: "left" }}}
            inputProps={{
              name: 'type',
              id: 'select-types"',
              style: {fontSize: 14, padding: 10}
            }}
          >
            {types.map(type => (
              <MenuItem key={type.id} value={type.id} style={{fontSize: 14, padding: 10}} label={type.name}>
                {type.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>}
        <FormControl fullWidth  className={classes.formControl} >
          <InputLabel htmlFor="select-organizationType" style={{fontSize: 18, color: "#3e5262", fontWeight: 300,lineHeight: 0}}>Organization types</InputLabel>
         {orgTypes && <Select
            value={this.state.organizationType}
            onChange={this.handleChange}
            onOpen={this.setStyleOnOpen}
            onClose={this.setStyleOnClose}
            input={<Input id="select-organizationType" />}
            MenuProps={MenuProps}
            SelectDisplayProps={{style: {fontSize: 14, textAlign: "left" }}}
            inputProps={{
              name: 'organizationType',
              id: 'organizationType-simple',
            }}
          >
            {orgTypes.map(orgType => (
              <MenuItem key={orgType.id} value={orgType.id} style={{fontSize: 14, padding: 10}} label={orgType.name}>
                {orgType.name}
              </MenuItem>
            ))}
          </Select> }
        </FormControl>
          {this.props.calendar && sectorsFilter &&
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="select-sector" style={{fontSize: 18, color: "#3e5262", fontWeight: 300,lineHeight: 0}}>Focus areas</InputLabel>
          <Select
            value={this.state.sector}
            onChange={this.handleChange}
            onOpen={this.setStyleOnOpen}
            onClose={this.setStyleOnClose}
            input={<Input id="select-sector" />}
            fullWidth
            MenuProps={MenuProps}
            SelectDisplayProps={{style: {fontSize: 14, textAlign: "left" }}}
            inputProps={{
              name: 'sector',
              id: 'select-sector"',
              style: {fontSize: 14, padding: 10}
            }}
          >
            {sectorsFilter.map(sector => (
              <MenuItem key={sector.id} value={sector.id} style={{fontSize: 14, padding: 10}} label={sector.name}>
                {sector.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>}
        <FormControl className={classes.formControl} style={{marginTop: 16, fontSize: 30, borderBottom: "1px solid rgba(0, 0, 0, 0.42)"}}>
        <Button onClick={this.handleClickOpen} style={{fontSize: 18, color: "#3e5262", fontWeight: 300,lineHeight: 1.1, textTransform: "uppercase"}} classes={{ label: 'location-label' }}>
        Locations <ArrowDropDown style={{float: "right"}}/></Button>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth
        >
          <DialogTitle>
          <div className="dialog-title-h2">Choose region</div></DialogTitle>
          <DialogContent>
            <form className={classes.container}>
            { regionsFilter &&
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="region-simple"
                style={{fontSize: 16, color: "#3e5262", fontWeight: 300,lineHeight: 0}}>Select region</InputLabel>
              {regionsFilter &&
                <Select
                  value={this.state.region}
                  onChange={this.handleChange}
                  SelectDisplayProps={{style: {fontSize: 14, textAlign: "left" }}}
                  input={<Input id="region-simple" />}
                  inputProps={{
                    name: 'region',
                    id: 'region-simple',
                    style: {fontSize: 14, padding: 10}
                  }}
                >
                 {regionsFilter.map((region, index) => {
                     let items = [];

                        if(region.children.length > 0){
                         items.push(<MenuItem key={region.id} value={region.id} style={{fontSize: 14, padding: 10}} label={region.name}>{region.name}</MenuItem>)
                            items.push(region.children.map(child => (
                                    <MenuItem key={child.id} value={child.id} style={{fontSize: 14, padding: 10, marginLeft: 15}} label={child.name}>{child.name}</MenuItem>
                                )))


                        }else{
                            items.push(<MenuItem key={region.id} value={region.id} style={{fontSize: 14, padding: 10}} label={region.name}>{region.name}</MenuItem>)
                        }
                return items;
              })
              }
                </Select>
                }
              </FormControl>
              }
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="country-simple" style={{fontSize: 16, color: "#3e5262", fontWeight: 300,lineHeight: 0}}>Select country</InputLabel>
                {this.state.countries &&
                <Select
                  value={this.state.country}
                  onChange={this.handleChange}
                  SelectDisplayProps={{style: {fontSize: 14, textAlign: "left" }}}
                  input={<Input id="country-simple" />}
                  inputProps={{
                    name: 'country',
                    id: 'country-simple',
                    style: {fontSize: 14, padding: 10}
                  }}
                >
                  {this.state.countries.map(country => (
                          <MenuItem key={country.id} value={country.id} style={{fontSize: 14, padding: 10}} label={country.name}>{country.name}</MenuItem>
                  ))}

                </Select>}
              </FormControl>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} style={{ border: 0, padding: "5px 20px", borderRadius: 3, fontSize: 14}}>
              Cancel
            </Button>
            <Button onClick={this.handleApply} style={{background: "#1cbbb4", border: 0, padding: "5px 20px", color: "#fff", borderRadius: 3, fontSize: 14}}>
              Apply
            </Button>
          </DialogActions>
        </Dialog>
       </FormControl>

                </Grid>
                </Grid>

                <Grid item xs={12}  className="used-filters">
                    <div className="col-sm-12" >
                    <h6 className="pull-left ng-binding">Results: {this.props.filterTotal ? this.props.filterTotal : 0}</h6>
                    </div>
                    {this.state.hasFilter &&
                        <div className="used-filters col-xs-12">
                             {Object.keys(this.state.filters).map((key, index) => (

                                <div className="col-md-12" key={index}>
                                    {this.state.filters[key] && this.state.filters[key].length > 0 &&
                                        <span>
                                            {this.props.calendar && key!=="search" &&
                                            <h6 className="pull-left location">{key} :</h6>
                                            }
                                             {!this.props.calendar &&
                                            <h6 className="pull-left location">{key} :</h6>
                                            }
                                            {!this.props.calendar && key==="search" &&
                                             <a href="#" className="" key={index} onClick={this.removeFilter(key, this.state.filters[key])} >
                                                  {this.state.filters[key]}
                                              <span className="ion-android-close"></span>
                                            </a>
                                             }
                                              {!this.props.calendar && key==="type" && types &&
                                                     <span>
                                          {types.map((value, i) => (
                                                  <span key={i}>
                                                {this.state.filters[key].includes(String(value.id)) &&
                                              <a href="#" className=""key={value.id} onClick={this.removeFilter(key, value.id)} >
                                                  {value.name}
                                              <span className="ion-android-close" style={{marginLeft: 5}}></span>
                                            </a>
                                            }
                                            </span>
                                          ))}
                                 </span>
                                 }


                                            {key==="location" &&
                                            <span>
                                          {this.state.selectedLocations.map((value, i) => (
                                              <a href="#" className=""key={i} onClick={this.removeFilter(key, value.id)} >
                                                  {value.name}
                                              <span className="ion-android-close" style={{marginLeft: 5}}></span>
                                            </a>
                                          ))}
                                            </span>
                                            }
                                              {key==="sdg" && sdgs &&
                                            <span>
                                          {sdgs.map((value, i) => (
                                                  <span key={i}>
                                                  {this.state.filters[key].includes(String(value.id)) &&
                                              <a href="#" className=""key={i} onClick={this.removeFilter(key, value.id)} >
                                             <img src={value.photo} style={{width: 20, marginRight: 15}} className={value.description}/> {value.value} 
                                              <span className="ion-android-close" style={{marginLeft: 5}}></span>
                                            </a>
                                            }
                                            </span>
                                          ))}
                                            </span>
                                            }
                                            {key==="organizationType" && orgTypes &&
                                            <span>
                                          {orgTypes.map((org, i) => (
                                                  <span key={i}>
                                                  {this.state.filters[key].includes(String(org.id)) &&
                                              <a href="#" className=""key={i} onClick={this.removeFilter(key, org.id)} >
                                                  {org.name}
                                              <span className="ion-android-close" style={{marginLeft: 5}}></span>
                                            </a>
                                            }
                                            </span>
                                          ))}
                                            </span>
                                            }
                                                {this.props.calendar && key==="sector" && sectorsFilter &&
                                            <span>
                                          {sectorsFilter.map((sector, i) => (
                                                  <span key={i}>
                                                  {this.state.filters[key].includes(String(sector.id)) &&
                                              <a href="#" className=""key={i} onClick={this.removeFilter(key, sector.id)} >
                                                  {sector.name}
                                              <span className="ion-android-close" style={{marginLeft: 5}}></span>
                                            </a>
                                            }
                                            </span>
                                          ))}
                                            </span>
                                            }
                                        </span>
                                     }
                                </div>

                            ))}
                    {this.state.hasFilter &&
                     <a href="#" className="clear-filter" onClick={this.clearFilter}>Clear filter</a>
                    }
                     </div>

                   }








                </Grid>

                   </div>


                );
    }
}

Filter.propTypes = {
  getSdgsFilter: PropTypes.func.isRequired,
  getTypes: PropTypes.func.isRequired,
  getOrgTypes: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  sdg: PropTypes.object.isRequired,
  contribution: PropTypes.object.isRequired,
  code: PropTypes.object.isRequired,
  region: PropTypes.object.isRequired,
  getRegionsFilter: PropTypes.func.isRequired,
  getCountriesFilter: PropTypes.func.isRequired,
  onChange:  PropTypes.func.isRequired,
  filterTotal: PropTypes.string.isRequired,
  filterOffset: PropTypes.number.isRequired,
  calendar: PropTypes.bool.isRequired,
  getSelectedLocationsFilter: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  getSectorsFilter: PropTypes.func.isRequired,
  id: PropTypes.string
};

const mapStateToProps = state => ({
       sdg: state.sdg,
       contribution: state.contribution,
       code: state.code,
       region: state.region,
       auth: state.auth,
       sector: state.sector
    });

Filter = withStyles(styles)(Filter)
export default connect(mapStateToProps, {getSdgsFilter, getTypes, getOrgTypes, getRegionsFilter, getCountriesFilter, getSectorsFilter, getSelectedLocationsFilter })(withRouter(Filter));
