import React, { Component }  from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form'
import { renderTextField, renderSelectField, renderCheckbox, renderSelectChipField, renderDatePickerField, renderTmcField } from '../../common/MaterialUiForm'
import CheckboxGroup from '../../common/CheckboxGroup'
import Grid from '@material-ui/core/Grid';
import DropZoneComponent from '../../file/DropZoneComponent'
import { ToastContainer, toast } from 'react-toastify'
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css'
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import IntegrationReactSelect from '../../common/IntegrationReactSelect'
import { Editor } from '@tinymce/tinymce-react'
import { getFormData } from '../../../actions/contributionActions'
import moment from 'moment'
import Diff from 'react-stylable-diff'
import ContributeModal from './ContributeModal';

const styles = theme => ({
  root: {
    htmlFontSize: 20,
  }
,


});

const validate = values => {

  const errors = {}
  const requiredFields = [
    'projectTitle',
    'projectAt',
    'projectTo',
    'description',
    'city',
    'region',
    'analyses',
    'sectors',
    'sdg',
    "photo",
    "organizers",
    "contactName",
    "contact",
    "agenda"
  ]
  requiredFields.forEach(field => {
   if(!values[field] && values[field]==='generalTerms'){
       errors[field] =  'You have not agreed to the site terms'
   }else if (!values[field] && values[field]==='imageTerms') {
      errors[field] = 'You have not agreed to the content terms'
    }else if (!values[field]) {
      errors[field] = 'This value should not be blank.'
    }
      if (field === 'city' && !/^[a-zA-Z0-9\'\-\ ]+$/.test(values[field])) {
            errors[field] = 'invalid';
     }
  })
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'Invalid email address'
  }

  return errors
}

class ContributionTraining extends Component {

    constructor(props) {
        super(props);
        this.state = {
         imageFile: null,
         projectAt: moment(new Date(), "DD-MM-YYYY").format("DD-MM-YYYY"),
         projectTo: moment(new Date(), "DD-MM-YYYY").format("DD-MM-YYYY"),
         focusedAt: null
        }
    }

  componentDidMount() {
   this.props.getFormData()
  }


   onChange(value) {}

  resetForm = () => this.setState({ imageFile: [] }, () => this.props.reset());

   handleDateAtChange = value => {
       this.setState({
           projectAt: value.format("DD-MM-YYYY")
       })
   }

    handleDateToChange = value => {
       this.setState({
           projectTo: value.format("DD-MM-YYYY")
       })
   }

render () {

  const { handleSubmit, pristine, reset, submitting, classes } = this.props;
  const { profile } = this.props.profile;
  const { formData, contribution } = this.props.contribution;
  const { isAuthenticated } = this.props.auth;


  let photo = null;
    if(contribution && contribution.photoFiles){
        photo = contribution.photoFiles;
    }
    let required = null;
    if(!photo){
        required = value => {
          return value ? undefined : 'This value should not be blank.';
      }
    }

  return (
   <Grid container className="noPadding" direction="row" spacing={4}>
    <form onSubmit={handleSubmit} style={{display: 'flex', flexWrap: 'wrap', width: "100%"}}>
 <Grid container  direction="row" spacing={4} className="registerContibute-form">
 <Grid md={1} lg={1} item></Grid>
      <Grid md={5} lg={5} xs={12} item className={classes.root} >
        <Field
          name="projectTitle"
          id="outlined-dense"
          className={classNames("textField")}
          component={renderTextField}
          label="Training title *"
          variant="outlined"
         InputLabelProps={{
            style: {
             fontSize: 14
            }
          }}
          FormHelperTextProps={{
          classes:{
              root: classNames("labelRoot", "textRoot")
            }
          }}
          />
          {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor &&
              <Diff type="sentences" inputA={contribution.reflection.projectTitle} inputB={contribution.projectTitle}/>
          }
        <Field
          name="projectAt"
          className={classNames("textField")}
          component={renderDatePickerField}
          label="Training start *"
          variant="outlined"
          value={this.state.projectAt}
          onChange={this.handleDateAtChange}
          InputLabelProps={{
            style: {
             fontSize: 16
            }
          }}
          FormHelperTextProps={{
          classes:{
              root: classNames("labelRoot", "textRoot")
            }
          }}
        />
        {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor &&
              <Diff type="sentences" inputA={contribution.reflection.projectAt} inputB={contribution.projectAt}/>
          }
         <Field
          name="projectTo"
          className={classNames("textField")}
          component={renderDatePickerField}
          value={this.state.projectTo}
          onChange={this.handleDateToChange}
          label="Training end *"
          variant="outlined"
         InputLabelProps={{
            style: {
             fontSize: 16
            }
          }}
          FormHelperTextProps={{
          classes:{
              root: classNames("labelRoot", "textRoot")
            }
          }}
        />
         {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor &&
              <Diff type="sentences" inputA={contribution.reflection.projectTo} inputB={contribution.projectTo}/>
          }
          <Field
          classes={classes}
          name="description"
          component={renderTmcField}
          label="Training in brief *"
          variant="outlined"
           field={{id: 'description'}}
          />
          {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor &&
             <span>
        <p className="description text-left" style={{backgroundColor: '#c9eed3'}}  dangerouslySetInnerHTML={{__html:contribution.reflection.description }}></p>
        <p className="description text-left" style={{backgroundColor: '#cccc00'}} dangerouslySetInnerHTML={{__html: contribution.description}}></p>
        </span>
          }
       <Field
          classes={classes}
          name="agenda"
          component={renderTmcField}
          label="Agenda *"
          variant="outlined"
          field={{id: 'agenda'}}
       />
       {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor &&
             <span>
        <p className="description text-left" style={{backgroundColor: '#c9eed3'}}  dangerouslySetInnerHTML={{__html:contribution.reflection.agenda }}></p>
        <p className="description text-left" style={{backgroundColor: '#cccc00'}} dangerouslySetInnerHTML={{__html: contribution.agenda}}></p>
        </span>
          }
           <Field
          name="links"
          id="outlined-dense"
          className={classNames("textField")}
          component={renderTextField}
          label="Website link"
          variant="outlined"
         InputLabelProps={{
            style: {
             fontSize: 14
            }
          }}
          FormHelperTextProps={{
          classes:{
              root: classNames("labelRoot", "textRoot")
            }
          }}
          />
           {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor &&
              <Diff type="sentences" inputA={contribution.reflection.links} inputB={contribution.links}/>
          }

         <Field
            name="city"
            className={classNames("textField")}
            component={renderTextField}
            label="City *"
            variant="outlined"
            variant="outlined"
           InputLabelProps={{
            style: {
             fontSize: 14
            }
          }}
          />
          {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor &&
              <Diff type="sentences" inputA={contribution.reflection.city} inputB={contribution.city}/>
          }
          <span>
            <p className="margin-t-15"><i>In the event that you select "global" or "regional" locations, please also indicate, as applicable, either the city, state and country, or the city and country of the lead organization.</i></p>
          </span>
          {formData && formData.regions &&
          <span>
        <Field
            name="region"
            component={IntegrationReactSelect}
            options={formData.regions}
            clearable={true}
            ismulti={true}
            placeholder="Location"
             placeholder="Location *"
          />
          {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor &&
              <Diff type="sentences" inputA={contribution.reflection.regionText} inputB={contribution.regionText}/>
          }
          </span>
        }
        <span>
          <p className="margin-t-15"><i>The <a href="https://esa.un.org/MigFlows/Definition%20of%20regions.pdf" target="_blank" rel="noopener noreferrer">United Nations Geoscheme system</a> has been used for the Region and Country mappings.</i></p>
        </span>
           {formData && formData.organizationType &&
             <span>
        <Field
            name="analyses"
            component={IntegrationReactSelect}
            options={formData.organizationType}
            clearable={true}
            ismulti={true}
            placeholder="Organization Type"
            label="Organization Type *"
          />
          {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor &&
              <Diff type="sentences" inputA={contribution.reflection.organizationTypesText} inputB={contribution.organizationTypesText}/>
          }
          </span>
        }


        {formData && formData.sectors &&
         <span>
        <Field
            name="sectors"
            component={IntegrationReactSelect}
            options={formData.sectors}
            clearable={true}
            ismulti={true}
            placeholder="Focus area"
            label="Focus area *"
          />
           {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor &&
              <Diff type="sentences" inputA={contribution.reflection.sectorsText} inputB={contribution.sectorsText}/>
          }
          </span>
        }

             {formData && formData.sdgs &&
        <span>
        <Field
            name="sdg"
            component={IntegrationReactSelect}
            options={formData.sdgs}
            clearable={true}
            ismulti={true}
            placeholder="SDGs"
             label="SDGs *"
            className="form-control"
          />
          {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor &&
              <Diff type="sentences" inputA={contribution.reflection.sdgText} inputB={contribution.sdgText}/>
          }
          </span>
        }
        <ContributeModal />
        </Grid>



       <Grid md={5} lg={5} xs={12} item>
         <div style={{margin: '20px 20px 20px 0'}}>
       <Field
          name="photo"
          component={DropZoneComponent}
          type="file"
          text="PHOTO (UP TO 2MB)"
          label="Photo (up to 2MB)"
          multiple={false}
          preview={false}
          data={photo}
        />
       </div>
        <Field
            name="organizers"
            className={classNames("textField")}
            component={renderTextField}
            label="Organizers *"
            variant="outlined"
          InputLabelProps={{
            style: {
             fontSize: 14
            }
          }}
          />
           {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor &&
              <Diff type="sentences" inputA={contribution.reflection.organizers} inputB={contribution.organizers}/>
          }
          <Field
            name="contactName"
            className={classNames("textField")}
            component={renderTextField}
            label="Contact name *"
            variant="outlined"
            InputLabelProps={{
            style: {
             fontSize: 14
            }
          }}
          />
          {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor &&
              <Diff type="sentences" inputA={contribution.reflection.contactName} inputB={contribution.contactName}/>
          }
          <Field
            name="contact"
            className={classNames("textField")}
            component={renderTextField}
            label="Contact email *"
            variant="outlined"
            InputLabelProps={{
            style: {
             fontSize: 14
            }
          }}
          />
          {contribution.reflection && contribution.isPendingAuthorReview && contribution.isAuthor &&
              <Diff type="sentences" inputA={contribution.reflection.contact} inputB={contribution.contact}/>
          }
        <div className="clearfix"></div>
  </Grid>

      </Grid>
    <Grid item container  direction="row" spacing={4} className="register-footer" style={{marginTop: 40}}>
       {!photo &&
                <span>
  <Grid container  direction="row" spacing={0}>
    <Grid md={1} lg={1} xs={1} item></Grid>
        <Grid md={5} lg={5} xs={10} item >
        <div>Images and content uploaded to the SPHS Website have been cleared for public dissemination and meet
            other SPHS defined criteria as outlined in the
            <a href="/terms-and-conditions" target="_blank" style={{ cursor: 'pointer'}} > Site Terms</a>
            </div>
        <Field
              name="imageTerms"
              component={renderCheckbox}
              label="Yes, I agree"
               validate={[required]}
            />
        </Grid>
        <Grid md={5} lg={5} xs={10} item className="list-style">
               <div style={{ marginBottom: '10px' }}>
               <ul>
               <li>You are responsible for all the provided content (both textual and visual)
               as part of the <a href="/terms-and-conditions" target="_blank" style={{ cursor: 'pointer'}} >Site Terms</a>.
               You declare that you are the owner of the content, and have obtained copyright
               license for the images you are uploading.
               </li>
               <li>The attached picture is cleared for public dissemination,
               and I understand that the SPHS Secretariat reserves the right to store and re-purpose this image,
               and to replace or remove any picture submission to align
               the contribution with the goals of the platform.
               </li>
               </ul>
               </div>

            <Field
              name="generalTerms"
              style={{paddingLeft: 40}}
              component={renderCheckbox}
              label="Yes, I agree"
               validate={[required]}
              InputLabelProps={{
                style: {
                  display: "flex"
                }
              }}
            />
            </Grid>
          </Grid>
        </span>
        }
           <Grid md={12} lg={12} xs={10} item className="text-center" style={{paddingBottom: 50}}>
            <button onClick={handleSubmit(values =>
                                this.props.onSubmit({
                                  ...values,
                                  button: 'save'
                                }))}
            type="submit" disabled={pristine || submitting} style={{ display: 'inline', margin: 15}} className="green-button">
                Save
            </button>
            <button type="button" className="btn btn-default" disabled={pristine || submitting} onClick={reset} style={{ display: 'inline', margin: 15}} onClick={this.resetForm}>
                Clear Fields
            </button>
         </Grid>
      </Grid>
    </form>
    <div className="form-group">

</div>
     </Grid>
  );
  }
}


ContributionTraining.propTypes = {
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  getFormData: PropTypes.func.isRequired,
  contribution: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile,
    contribution: state.contribution
});

ContributionTraining = withStyles(styles)(ContributionTraining)

export default connect(mapStateToProps, { getFormData })(reduxForm({form: 'ContributionTraining', enableReinitialize: true, enableReinitialize: true, validate})(ContributionTraining))
