import client from './client';
import { toast } from 'react-toastify'
import showAToast from '../helpers/showAToast'
import {
        FORM_LOADED,
        FORM_DATA_LOAD,
        FORM_FIELD_UPDATE,
        FORM_FIELD_LOSE_FOCUS,
        FORM_DATA_VALIDATION,
        FORM_INITIAL_VALIDATION,
        FORM_FIELD_SELECT2_OPTIONS,
        FORM_FIELD_SELECT2_ADD_OPTIONS,
        IMAGE_UPLOAD_SUCCESSFUL,
        FORM_REMOVE_IMAGE,
        IMAGES_UPLOAD_SUCCESSFUL,
        POPUP_MESSAGE_SHOW,
        POPUP_MESSAGE_HIDE
        } from "./types";

//import saveAs from 'file-saver';



// START FORM ACTIONS
export const onLoadForm = (formId, route) => dispatch => {

        return new Promise((resolve, reject) => {
            client
                    .get(route)
                    .then(response => response.data)
                    .then(response => {
                        dispatch({
                            'type': FORM_DATA_LOAD,
                            payload: {
                                formId,
                                data: response.data,
                            }
                        });
                        dispatch({
                            'type': FORM_LOADED,
                            payload: {
                                formId,
                                form: response.form,
                                schema: response.schema,
                                isEdit: response.isEdit
                            }
                        });
                        resolve(response.data);
                    }
                    );
        })

    }

export const onSave = (route, data, isForm, url, history) => dispatch => { 
            let header = {}
            if(isForm){
                header = {
                         headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    };
                }
            client
                    .post(route, data, header)
                    .then(response => {
                        if(response.data){
                            showAToast(response.data.message, 'success1', 'success')
                            window.location = url;
                        }
                    })                    
                    .catch(err => {
                        let message = 'Bad request.'
                        if(err.response && err.response.data){                           
                            if(Array.isArray(err.response.data)){
                                 err.response.data.map((object) =>{
                                    
                                if (typeof object === 'object' && 'message' in object && 'property_path' in object){
                                         showAToast(object.property_path + ' - ' + object.message, 'error3', 'error')
                                 }else if(typeof object === 'object'){
                                     Object.keys(object).map(key => {
                                          showAToast(object[key], 'error2'+key, 'error')
                                     })
                                 }
                                 })                        
                            }else if('message' in err.response.data){
                                showAToast(err.response.data.message, 'error1', 'error')
                            }else if('error' in err.response.data && typeof err.response.data.error === 'string'){
                                showAToast(err.response.data.error, 'error4', 'error')                                
                            }else if('error' in err.response.data && typeof err.response.data.error === 'object'){     
                                 showAToast(err.response.data.error.message, 'error5', 'error')
                            }else{
                                 showAToast(message, 'error6', 'error')
                            }                         
                        }else{
                            showAToast(message, 'error7', 'error')
                        }
                    
                    });        

}

export const onSaveData = (route, data, isForm) => dispatch => { 
            let header = {}
            if(isForm){
                header = {
                         headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    };
                }
            client
                    .post(route, data, header)
                    .then(response => {
                        if(response.data && response.data.message){
                            showAToast(response.data.message, 'success2', 'success')
                        }
                    })                    
                    .catch(err => {
                        let message = 'Bad request.'
                        showAToast(message, 'error8', 'error')                        
                    });        

}



export const onSelect2InputChange = (formId, id, value, route = null) => dispatch => {
        return new Promise((resolve, reject) => {
            if (route) {
                client
                        .get(route + '?q=' + value)
                        .then(response => response.data)
                        .then(response => {
                            dispatch({
                                type: FORM_FIELD_SELECT2_OPTIONS,
                                payload: {
                                    formId,
                                    options: response,
                                    id: id,
                                    value: value
                                }
                            });
                            resolve(response);
                        });
            } else {
                dispatch({
                    type: FORM_FIELD_SELECT2_OPTIONS,
                    payload: {
                        formId,
                        id: id,
                        options: null,
                        value: value
                    }
                });
                resolve();
            }
        });

    }

export const onAddSelectOption = (formId, properties) => dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: FORM_FIELD_SELECT2_ADD_OPTIONS,
                payload: {formId, properties}
            });

            resolve();
        })

}


export const onUploadImage = (formId, id, file) => dispatch => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('file', file);

            client
                    .post( '/api/settings/image/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => response.data)
                    .then(response => {

                        dispatch({
                            type: IMAGE_UPLOAD_SUCCESSFUL,
                            payload: {
                                formId,
                                image: response,
                                id: id
                            }
                        });

                        resolve(response);
                    })
        });

}
    
    
export const onDeleteImage = (id, formId) => dispatch => { 
    dispatch({
        type: FORM_REMOVE_IMAGE,
        payload: {id: id, formId: formId}
    });
}

export const onFieldChange = (formId, key, value)  => dispatch => {   
        return new Promise((resolve, reject) => {
            dispatch({
                type: FORM_FIELD_UPDATE,
                payload: {key, value, formId}
            });

            resolve();
        })
    
}

export const onUploadImages = (formId, db, id, files) => dispatch => { 

        return new Promise((resolve, reject) => {
            const data = new FormData();
            const h = new Headers();

            for (const file of files) {
              data.append('files[]', file);
            }
            
            client
                .post('/api/settings/images/upload', data, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                .then(response => response.data)
                .then(response => {
                        if (response instanceof Array) {
                            dispatch({
                                type: IMAGES_UPLOAD_SUCCESSFUL,
                                payload: {
                                    formId,
                                    images: response,
                                    id: id
                                }
                            });
                        } else {
                            dispatch({
                                type: POPUP_MESSAGE_SHOW,
                                payload: {
                                    text: response.msg,
                                    error: response.status
                                }
                            });
                            setTimeout(() => dispatch({type: POPUP_MESSAGE_HIDE}), 5000);
                        }
                    resolve(response);
                })
        });
    
}

// END FORM ACTIONS