import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles'
import Navbar from '../layout/Navbar';
import Grid from '@material-ui/core/Grid';
import Footer from '../layout/Footer'
import Sectors from './elements/Sectors'
import Contribute from './elements/Contribute'
import Contributions from './elements/Contributions'
import Filters from '../filter/Filters'
import {getContributionsFilter, setContributions} from '../../actions/contributionActions'
import { withRouter } from 'react-router-dom'
import LoginForm from '../layout/LoginForm'
import { config } from '../../config'
import { getLogActivityWithGeoIp } from '../../actions/profileActions';

const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

class KnowledgePractice extends Component {
    constructor(props) {
        super(props);
        this.state = {
           contributions: [],
           filter: []
        }
    }

    componentDidMount() {
        this.props.getLogActivityWithGeoIp('Knowledge & Practice page', 'GET', "Website usage")
    }

    componentWillReceiveProps(nextProps) {
       if(nextProps.contribution.contributions){

       }
    }

     handleChangeFilter = value => {

    if(this.props.history.location.pathname === '/knowledge-practice/search'){
        value['load'] = true
    }

    this.props.getContributionsFilter(value)
         this.setState({
             filter: value
         })
     }

     handleLoadMore = (offset) => {
         let data = this.state.filter
         if(this.props.history.location.pathname === '/knowledge-practice/search'){
        data['load'] = true
    }
         data['offset'] = offset
          this.props.getContributionsFilter(data, true)
     }

    render() {
const { classes } = this.props;
const { isAuthenticated, user } = this.props.auth;

        return (

    <div className={classes.root}>
      <Grid container spacing={0}>
      <div className="page-content  slideup page-animation">
          <div className="focus-area-page">
            <div className="heroshot">
              <img src={config.API_URL +"/images/org/acbeef.jpeg?v1.0"} alt="kwnowlega&practice"/>
              <Grid container direction="row" justify="center" className="center-col">
                <Grid item md={8} xs={12} className="text-area text-center">
                  <img src={config.API_URL +"/images/org/ddf190.png?v1.0"} alt="kwnowlega&practice"/>
                  <h1 className="text-white text-bold">Knowledge and Practice</h1>
                  <h2 className="text-white">Sharing knowledge and practice to advance sustainability<br/> in the procurement of health commodities</h2>
                </Grid>
                </Grid>
            </div>
        <div className="filter-area">
                <Filters
                onChange={this.handleChangeFilter}
                filterTotal={this.props.contribution.total}
                filterOffset={this.props.contribution.offset}
                calendar={false}
                id={null}/>
        </div>
       {this.props.contribution.contributions.length > 0 &&
       <Contributions onLoadMore={this.handleLoadMore} />
       }
       {this.props.contribution.contributions.length < 1 &&
       <Sectors/>
       }
        <Grid item container md={12} lg={12} className="contribution-area" alignContent="center" alignItems="center" justify="center"  style={{position: 'relative'}}>

         <img src={config.API_URL +"/bundles/contribution/images/icons/icon-gradient.png?v1.0"} className="contribution-img" alt={"Contribute icon"} width="200" style={{width: 200}}/>
       <LoginForm role="contribute"/>

       </Grid>
       </div>
        <Footer/>
        </div>
        <Navbar/>
            </Grid>
        </div>
        );
    }
}


KnowledgePractice.propTypes = {
    classes: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    contribution: PropTypes.object.isRequired,
    getContributionsFilter: PropTypes.func.isRequired,
    setContributions: PropTypes.func.isRequired,
    getLogActivityWithGeoIp: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    contribution: state.contribution
});

KnowledgePractice = withStyles(styles)(KnowledgePractice)

export default connect(mapStateToProps, { getContributionsFilter, setContributions, getLogActivityWithGeoIp })(withRouter(KnowledgePractice));
