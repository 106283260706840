import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Popup from '../../../common/Popup';


export default class PopupAdministration extends Component {
    render() {
        const settings = this.props.settings;
        const id = 'popupAdministration';
        return (
            <Popup locale={this.props.settings.locale}
                   title={ 'Administration'}
                   large={true}
                   onClose={() => this.props.closePopup(id)}>
            
                <ul className="col-sm-4 modal-row">
                    <h3 className="col-sm-12 h4 text-bold">{'SPHS'}</h3>
                    <li className="col-sm-12" onClick={() => this.props.closePopup(id)}>
                        <Link id="Members" className="draggable"
                              title={'Members'}
                              to={'/admin/members'}>
                            <span>Members</span>
                        </Link>
                    </li>
                    <li className="col-sm-12"
                        onClick={() => this.props.closePopup(id)}>
                        <Link id="SDG" className="draggable"
                              title={'SDG'}
                              to={ '/admin/sdgs'}>
                            <span>SDG</span>
                        </Link>
                    </li>
                    <li className="col-sm-12"
                        onClick={() => this.props.closePopup(id)}>
                        <Link id="Locations" className="draggable"
                              title={'Locations'}
                              to={ '/admin/locations'}>
                            <span>Locations</span>
                        </Link>
                    </li>
                    <li className="col-sm-12"
                        onClick={() => this.props.closePopup(id)}>
                        <Link id="Focus areas" className="draggable"
                              title={'Focus areas'}
                              to={ '/admin/focus-areas'}>
                            <span>Focus areas</span>
                        </Link>
                    </li>
                </ul>

                    <ul className="col-sm-4 modal-row">
                        <h3 className="col-sm-12 h4 text-bold">Analysis</h3>
                        <li className="col-sm-12"
                            onClick={() => this.props.closePopup(id)}>
                            <Link id="codes" className="draggable" title="Codes"
                                  to={ '/admin/analysis-codes'}>
                                <span>Codes</span>
                            </Link>
                        </li>
                        <li className="col-sm-12"
                            onClick={() => this.props.closePopup(id)}>
                            <Link id="Categories" title={'Categories'}
                                  className="draggable"
                                  to={'/admin/analysis-categories'}>
                                <span>Categories</span>
                            </Link>
                        </li>
                    </ul>


             <ul className="col-sm-4 modal-row">
                        <h3 className="col-sm-12 h4 text-bold">Templates</h3>
                        <li className="col-sm-12"
                            onClick={() => this.props.closePopup(id)}>
                            <Link id="emails" className="draggable" title="Emails"
                                  to={ '/admin/templates/emails'}>
                                <span>Emails</span>
                            </Link>
                        </li>
                        
                    </ul>

                  

            </Popup>
        )
    }
}
