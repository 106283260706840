import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getSectors } from '../../../actions/sectorsActions';
import Spinner from '../../common/Spinner';
import SectorItem from './SectorItem';
import Grid from '@material-ui/core/Grid'
import { config } from '../../../config'


class Sectors extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        this.props.getSectors()
    }

    componentWillReceiveProps(nextProps) {

    }

    render() {
        const {sectors, loading} = this.props.sector;
        let listSectors;
        if (sectors === null || loading) {
            listSectors = <Spinner />;
        } else {
            if (sectors.length > 0) {
                listSectors = sectors.map(sectorItem => (
                            <SectorItem key={sectorItem.name} sectorItem={sectorItem}  />
                            ));
            } else {
                listSectors = <h4>Sectors not found...</h4>;
            }
        }
        return (
                <Grid container item className="sector-area">
                    {listSectors}
                    <Grid item md={3} sm={6} xs={12} className="sector-content">
                        <img width="100%" src={config.API_URL +"/images/org/03cb04.jpeg?v1.0"} alt={"blue box"}/>
                        <div className="center-align">
                            <a href="/knowledge-practice/search">
                                <h4 className="see-all text-white">SEE ALL</h4>
                            </a>
                        </div>
                    </Grid>
                </Grid>


                );
    }
}

Sectors.propTypes = {
    getSectors: PropTypes.func.isRequired,
    sector: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
        sector: state.sector
    });

export default connect(mapStateToProps, {getSectors})(Sectors);
