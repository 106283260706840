import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Navbar from '../layout/Navbar';
import Footer from '../layout/Footer'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import ReactDom from 'react-dom';
import { getLogActivityWithGeoIp } from '../../actions/profileActions';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
});

class PrivacyPolicy extends Component {

  componentDidMount(prevProps) {
        ReactDom.findDOMNode(this).scrollIntoView();
        this.props.getLogActivityWithGeoIp('Privacy Policy page', 'GET', "Website usage")
  }

render() {
    const { classes } = this.props;

return (

<div className={classes.root}>
  <Navbar/>
    <div className="page-content  page-animation slideup"  >
      <div className="privacyPolicy">
      <Grid container className="terms-and-conditions">
       <Grid item md={12} sm={12} xs={12} className="header vcenter">
          <h1 className="text-center text-white">
              Privacy Policy
          </h1>
       </Grid>
       <Grid item md={10} className="center-col max-width content text-left">
       <div>
        <h2><strong>Our GDPR Commitment</strong></h2>
          <p>The SPHS Secretariat fully complies with the GDPR in the delivery of services to our platform Members and Contributors. We believe this new law is an important step forward to the protection of their data.</p>
          <p>The GDPR (General Data Protection Regulation) is an important piece of legislation that is designed to strengthen and unify data protection laws for all individuals within the European Union. The law affects all who collect, store, transfer or use personal information about European citizens.</p>
        <h2><strong>What has SPHS Done?</strong></h2>
          <p>A summary of actions we have taken to ensure that the SPHS Secretariat is compliant with the GDPR regulations.</p>
          <ul>
              <li>Determined which areas of our business are impacted by GDPR</li>
              <li>Researched the privacy and security measures we have in place</li>
              <li>Developed a strategy to address the applicable GDPR requirements</li>
              <li>Adjusted our privacy policies to meet the requirements</li>
          </ul>
        <h2><strong>What Changes are made for the SPHS Secretariat to be GDPR Compliant?</strong></h2>
          <p>We have taken the needed actions to implement the required changes within our internal processes and procedures, in order to achieve and maintain compliance with GDPR.  We have ensured that our software and data processing practices meet all regulation standards, to support our compliance with the GDPR.</p>
          <h3><strong>User Consent</strong></h3>
          <p>Registration by users on the SPHS platform prompts platform users to first read and understand our Privacy Policy and Website Terms and Conditions, and the ways in which we adhere to standards that ensure our compliance with GDPR regulations.</p>
          <h3><strong>Data Usage</strong></h3>
          <p>As it relates to this platform, we collect the personal data of our users only during user registration for the sole purpose of understanding: their content preferences and interests, in order to effectively gauge and meet those needs. We also collect the personal data of our Newsletter subscribers and our Questionnaire Respondents fort he same purposes.</p>
          <p>We use the contact information of our platform users and subscribers to send information to them when they request it. We use transparent practices and procedures to ensure that personal data is used only for its intended purposes, and are transparent in how we handle and maintain documentation that defines our processes, and use of personal data.</p>
          <h3><strong>Rights of Platform Registrants</strong></h3>
          <p>We advise our platform users of their right to obtain a copy of their personal data as it is held on our platform, and to have their personal data removed from the platform at any time, either upon request or after certain periods of inactivity.</p>
          <p>Platform registrants are invited to request a copy of their personal data as held on the platform directly from the SPHS Secretariat at: <a href="mailto:info@savinglivesustainably.org">info@savinglivesustainably.org</a>. We have created interfaces to allow our clients to address requests from their customers, related to their rights for accessing any personal data that might be stored in our client's workspace.</p>
          <h3><strong>Data Retention</strong></h3>
          <p>The SPHS platform has been set up to automatically identify any registered users who have been inactive for a period of <span className="text-primary">more than 4 years</span>.  In this way, we can identify those users who have been inactive for long periods of time, and whose personal data may then be removed from our contacts list. Close to this cut-off date, platform users will be notified that their personal data will be made inaccessible by the system.</p>
          <p>If the account holder does not respond to the notification and request their data to remain active, it will be removed within 10 days of their reciept of the notice.</p>
          <h3><strong>Data Erasure</strong></h3>
          <p>After the 10 days period is passed and the registered user has not requested to keep their account active, SPHS will mask all personal information collected in the account. This data will be unrecognizable, but still usable for general stats and analysis. This process is irreversible and once encrypted the information can't be retrieved.</p>
          <h3><strong>Data Safety & Security</strong></h3>
          <p>Our users’ data is safe with SPHS. We have undertaken a number of steps to ensure that SPHS and our software developer company, Composity LLC, with explicit permissions for specific development purposes, are the only ones who can access your data. </p>
          <p>Composity LLC servers are positioned in Germany, Europe on the Contabo infrastructure. All workspaces and databases run inside a Contabo VPC, Virtual Private Cloud. Your data is only accessible from the application servers and no outside sources are allowed to connect to the database. Firewalls are in place to block access if any suspicious login activity is detected. Composity transmits data from the user's browsers to their systems using HTTPS. All transfer data is encrypted.</p>
          <p>Platform user data collected in Composity software is owned and used by SPHS. We do not make use of the data stored for any undisclosed purposes.</p>
          <p>We are confident that these changes address all of the requirements of GDPR.</p>
          </div>
        </Grid>
       </Grid>
      </div>
        <Footer/>
    </div>
</div>


);
}
}
PrivacyPolicy.propTypes = {
    getLogActivityWithGeoIp: PropTypes.func.isRequired
  };

const mapStateToProps = state => ({});
PrivacyPolicy = withStyles(styles)(PrivacyPolicy)
export default connect(mapStateToProps, {getLogActivityWithGeoIp})(PrivacyPolicy);