import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { postOverrideAccount } from '../../../actions/accountActions'
import {onLoadForm}  from '../../../actions/formActions';

 class DialogConfirmOverrideQuest extends React.Component {       
  constructor(props) {
    super(props);
    this.state = {
       open: false
    };
  }  
    
    confirmOverride = () => { 
        console.log(this.props.formData) 
        console.log(this.props.form)
        console.log(this.props.formRoute)
      this.props.postOverrideAccount(this.props.formData.data.id)
        setTimeout(() => {
          this.props.onLoadForm(this.props.form, this.props.formRoute)
          }, 3000)
     
      this.setState({
           open: false
       })
  }
  
   openDialog = () => {
       this.setState({
           open: true
       })
   } 
   closeDialog = () => {
       this.setState({
           open: false
       })
   } 
   
  render() {
      
  
    return <div>
        <div className="error-alert">This account is currently being edited by another user. Click here to override lock. 
            <Button 
            variant="outlined" 
            color="secondary" 
            size="small"
            onClick={this.openDialog} 
            style={{marginLeft: 10}}
            >Override</Button></div>
        <Dialog
            onClose={this.closeDialog}
            aria-labelledby="simple-dialog-title"
            open={this.state.open}
            maxWidth="sm"
            fullWidth
           >

        <DialogContent>
        <section className="newPanel">
           <div className="panel-body">
               <div>
                   <div className="text-center">

                       <h3>
                           If you unlock this account, the current user may lose unsaved data. Are you sure you want to proceed?
                       </h3>
                       <button className="button-outlines green text-center"
                               onClick={this.closeDialog}>
                           No
                       </button>
                       <button className="button-primary-edited text-center btn-ok"
                               onClick={this.confirmOverride}>
                          Yes
                       </button>
                   </div>
               </div>
           </div>
       </section>
        </DialogContent>
     </Dialog>
</div>
 }
}

DialogConfirmOverrideQuest.propTypes = {
  formData: PropTypes.object.isRequired,
  postOverrideAccount: PropTypes.func.isRequired,
  onLoadForm: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  formData: state.formData,
});

export default connect( mapStateToProps, { postOverrideAccount, onLoadForm })(DialogConfirmOverrideQuest);
    
