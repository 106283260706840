import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Layout from '../../../components/admin/layout/Layout'
import { getDuplicatedAccounts, resolveDuplicatedAccounts, dismissDuplicatedAccount, postGetDuplicateForm } from "../../../actions/accountActions";
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DuplicatedForm from './DuplicatedForm'
import Spinner from '../../common/Spinner'
import DialogConfirm from '../../common/DialogConfirm'
import MergeForm from './MergeForm';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: 20
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width: '100%',
    height: '100%'
  }
});

class DuplicatedAccounts extends Component {
  constructor() {
    super();
    this.state = {
      duplicatedAccounts: null,
      duplicatedItem: null,
      open: false,
      openConfirm: false,
      dismissId: null,
      previosForm: false,
      formFields: null,
      organizationMain: null,
      organizationMerge: null,
      organizationMainName: null,
      page: 1
    };
  }

     componentDidMount() {
         this.props.getDuplicatedAccounts(this.props.match.params.type)
          document.body.style.overflow = 'auto';
    }

    componentWillReceiveProps(nextProps) {
   
    }

    handleResolve = (key) => event => {
        event.preventDefault()
        this.setState({
                open: true,
                duplicatedItem: key,
                previosForm: true
            })
    }

    closeForm = () => {
        this.setState({
             open: false,
             duplicatedItem: null,
             previosForm: false
        });
    }

    submitChooseOrganization = (values) => {
        this.setState({
             previosForm: false,
             organizationMain: values.organizationMain,
             organizationMerge: values.organizationMerge
        });
        this.props.postGetDuplicateForm(values, this.props.match.params.type)     
    }
    
    handleNo = () => {
         this.setState({
             openConfirm: false,
             dismissId: null,
             duplicatedName: null
         })
    }
    
    openConfirm = (id, name ) => {       
        this.setState({
             openConfirm: true,
             dismissId: id,
             duplicatedName: name
         })
    }
    
    handleYes = () => {
        this.props.dismissDuplicatedAccount(this.state.dismissId)
         this.setState({
             openConfirm: false,
             dismissId: null,
             duplicatedName: null
         })
          setTimeout(() => {
         this.props.getDuplicatedAccounts(this.props.match.params.type)
          }, 3000)
    }
    
      
    submit = (values, props) => {   
        let data = [];
        let formData = Object.assign({}, values);            
            Object.keys(values).map(k => {
           if(values[k].length >= 1){
              let array = []
               values[k].map(row => {
                   array.push(row.value)
               })
               formData[k] = array;              
               array = []
           }else{
               formData[k] = values[k]['value'];
           }
            })
            formData.organizationMain = this.state.organizationMain;
            formData.organizationMerge = this.state.organizationMerge;
        this.props.resolveDuplicatedAccounts(formData, this.props.match.params.type, '/admin/accounts/duplicated/'+this.props.match.params.type)
    }

    render() {
    const { classes } = this.props;
var type = '';
if(this.props.match.params.type === 'companyName'){
    type = "organization"
}else{
    type = "contact"
}
    return (
        <Layout>
<DialogConfirm 
handleNo={this.handleNo} 
handleYes={this.handleYes} 
openConfirm={this.state.openConfirm} 
text={"Are you sure you want to dismiss resolving duplicated  "+ type+ ' ' + this.state.duplicatedName + " from this list?"}/>
                   <Grid container className={classNames(classes.root)} spacing={2} direction="row" justify="flex-start"  >
                   <Dialog
                        onClose={this.closeForm}
                        aria-labelledby="simple-dialog-title"
                        open={this.state.open}
                        maxWidth="md"
                        fullScreen
                        scroll={'paper'}
                        BackdropProps={{
                        classes: {
                         root: classes.rootDialog
                        }
                       }}>
        <DialogTitle id="simple-dialog-title">{this.state.duplicatedItem && this.state.previosForm && <span>{this.state.duplicatedItem}</span>}
        {this.props.account.duplicatedForm && this.props.account.duplicatedForm.title && !this.state.previosForm && <span>
                {this.props.account.duplicatedForm.title}
                </span>}
                <span className="pull-right pointer" onClick={this.closeForm}>
        <i className="far fa-times-circle"></i></span>
        </DialogTitle>
        <DialogContent>
         <div className={classes.list} role="presentation">
         {this.state.previosForm && <DialogContentText>
           Select the duplicate records you'd like to merge. 
           All related records and other related items will be associated with the chosen Master Record.
         </DialogContentText>}
                  <div className={classes.list} role="presentation">
                  {this.state.duplicatedItem && this.state.previosForm &&
                  <DuplicatedForm onSubmit={this.submitChooseOrganization}
                  duplicatedOrganizations={this.props.account.duplicatedAccounts[this.state.duplicatedItem]}
                  duplicatedItem={this.state.duplicatedItem}
                  type={this.props.match.params.type}/>
                  }
                   {this.state.duplicatedItem && !this.state.previosForm && this.props.account.duplicatedForm && this.props.account.duplicatedForm.form &&
                           <MergeForm 
                            onSubmit={this.submit} 
                            formFields={this.props.account.duplicatedForm.form}
                            form={'initializeFromState2'}
                            />}
                   </div>
                </div>
         </DialogContent>
                           </Dialog>

                    <Grid item md={12} lg={12} xs={12} className="profile-breadcrumb">
                      <div className="profile-breadcrumb">

                       </div>
                    </Grid>
                    <Grid item md={12} lg={12} xs={12} container  spacing={2}>
                     <Paper className={classes.paper} >
                    
                     {(this.props.account.duplicatedAccounts && Object.keys(this.props.account.duplicatedAccounts).length > 0) ?
                             <div>
                           
                             {Object.keys(this.props.account.duplicatedAccounts).map(key => {

           return  <div key={key}><h4 className="m-top30">{key}
           <a href="#" onClick={this.handleResolve(key)} className="btn btn-default" style={{marginLeft: 15}}>Resolve</a></h4>
                {Object.keys(this.props.account.duplicatedAccounts[key]).map(k => {
                    return <div key={k}>
                    <a href={'/crm/accounts/'+ this.props.account.duplicatedAccounts[key][k].id +'/profile'} key={k}  target="_blank">
                   {this.props.match.params.type==='email' && this.props.account.duplicatedAccounts[key][k]['firstName'] &&
                           <span>{this.props.account.duplicatedAccounts[key][k]['firstName']}</span>}
                   {this.props.match.params.type==='email' && this.props.account.duplicatedAccounts[key][k]['lastName'] &&
                           <span>{this.props.account.duplicatedAccounts[key][k]['lastName']}</span>}
                   - {this.props.account.duplicatedAccounts[key][k][this.props.match.params.type]}
                    </a>
                    <a href="#" onClick={(id) => this.openConfirm(this.props.account.duplicatedAccounts[key][k].id, this.props.account.duplicatedAccounts[key][k][this.props.match.params.type])} className="btn btn-default" style={{marginLeft: 15}}>Dismiss</a>
                    </div>
                })}
                </div>

                               }) }
                                    
            </div>
                            :
                                    <div>
                            {(this.props.account.duplicatedAccounts && Object.keys(this.props.account.duplicatedAccounts).length === 0 ) ?
                            <h3>No duplicated accounts.</h3>
                            :
                       <Spinner/>
                       }</div>
                     }

                            </Paper>

                    </Grid>
                    </Grid>


        </Layout>
    );
  }
}

DuplicatedAccounts.propTypes = {
  getDuplicatedAccounts: PropTypes.func.isRequired,
  resolveDuplicatedAccounts: PropTypes.func.isRequired,
  account:    PropTypes.object.isRequired,
  dismissDuplicatedAccount: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
 account: state.account,
 form: state.form
});

DuplicatedAccounts = withStyles(styles)(DuplicatedAccounts)

export default connect( mapStateToProps, { getDuplicatedAccounts, resolveDuplicatedAccounts, dismissDuplicatedAccount, postGetDuplicateForm })(withRouter(DuplicatedAccounts));
