import { toast } from 'react-toastify'

 const showAToast = (str, id, type) => { 
     if(type === 'default'){
         toast(str, {
                        position: "top-center",
                         toastId: id,
                        autoClose: 10000,
                        toastClassName: 'Toastify'})
       
     }else{
        toast[type](str, {
                    toastId: id,
                    position: "top-center",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                    });  
                }
    }
    
export default  showAToast;   

