import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getMembers } from '../../actions/membersActions';
import Spinner from '../common/Spinner';
import MemberItem from './MemberItem'
import Grid from '@material-ui/core/Grid'




class Members extends Component {
    constructor(props) {
        super(props);
        this.state = {
        members: []
        }
    }

    componentDidMount() {
        this.props.getMembers()
    }

    componentWillReceiveProps(nextProps) {

          if(nextProps.member.members){
              this.setState({
                  members: nextProps.member.members
              })
          }
    }

    render() {
    const { members, loading } = this.props.member;

        return (

        <div className="homepage-members">
                <Grid container spacing={0}>
                  <Grid item md={8} xs={10} className="center-col">
                    <h1 className="text-black">Members</h1>
                    <p>SPHS members' advancements in introducing<br/>
                        sustainable procurement in the global health aid market</p>
                 </Grid>
                </Grid>
            <Grid item md={12} sm={12} xs={12} >
                <div className="max-width">
                 {this.state.members.length > 0 && this.state.members.map((member, key) => (
                     <MemberItem key={member.id} memberItem={member}  />
                ))}
                {loading &&
                  <Spinner />      }
                </div>
            </Grid>
      </div>



                );
    }
}

Members.propTypes = {
    getMembers: PropTypes.func.isRequired,
    member: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
        member: state.member
});

export default connect(mapStateToProps, {getMembers})(Members);
