import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import { getContributionEvent } from '../../../actions/contributionsActions';
import Spinner from '../../common/Spinner';
import EventItem from './EventItem';
import Grid from '@material-ui/core/Grid';


class ContributionEvents extends Component {
constructor(props) {
super(props);
        this.state = {
         total: 0,
         limit: 15,
         offset: 0
        }
}

componentDidMount() {

}

componentWillReceiveProps(nextProps) {
    if(nextProps.contribution.total){
        this.setState({
            total: parseInt(nextProps.contribution.total)
           })
    }
    if(nextProps.contribution.offset){
        this.setState({
            offset: parseInt(nextProps.contribution.offset)
        })
    }
}


render() {
const {events, total } = this.props.contribution;
        let listContributionEvent;
        if (events && events.length > 0) {
listContributionEvent = events.map((contributionItem, i) => (
<EventItem key={i} contributionItem={contributionItem}  index={i}/>
        ));
} else {
listContributionEvent = '';
}

return (
<div className="max-width preview-member-area">
    {events &&
     <Grid container spacing={3}>                
          {listContributionEvent}  
    </Grid>
    }
</div>
);
}
}

ContributionEvents.propTypes = {
        contribution: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
        contribution: state.contribution
});

export default connect(mapStateToProps, {})(ContributionEvents);
