import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import AnimateHeight from 'react-animate-height';
import ModalConfirm from '../../common/ModalConfirm';
import ModalForm from '../../common/ModalForm';
import {editComment, deleteComment} from '../../../actions/commentActions';

class Comment extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            height: 0, // height of the options menu Edit and Delete
            duration: 500, // duration of the tarnsition
            editPopup: false, // show edit popup
            deleteConfirmation: false, // Are you sure? popup
            message: this.props.comment.message // message content to edit
        };


        this.showOptions = this.showOptions.bind(this);
        this.deleteComment = this.deleteComment.bind(this);
        this.edit = this.edit.bind(this);
        this.handleNoDelete = this.handleNoDelete.bind(this);
        this.handleYesDelete = this.handleYesDelete.bind(this);
        this.handleNoEdit = this.handleNoEdit.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleEditChange = this.handleEditChange.bind(this);
    }

    handleYesDelete() {
        this.setState({
            height: 0,
            duration: 0,
            deleteConfirmation: false
        });

        // TODO: dispatch remove comment
        this.props.deleteComment(this.props.comment.id, this.props.type);
    }

    handleNoDelete() {
        this.setState({
            deleteConfirmation: false
        });
    }

    handleNoEdit() {
        this.setState({
            editPopup: false,
            message: ''
        });
    }

    handleEdit() {
        this.props.editComment(this.props.comment.id, this.state.message, this.props.type);
        this.setState({
            editPopup: false,
            message: ''
        });
    }

    handleEditChange(e) {
        // update state on input change
        this.setState({
            message: e.target.value
        });
    }

    edit() {
        // show edit popup
        this.setState({
            editPopup: true,
            message: this.props.comment.message
        });
    }

    showOptions() {
        // toggle options menu
        this.setState({
            height: !this.state.height ? 'auto' : 0,
            duration: 500
        });
    }

    deleteComment() {
        this.setState({
            deleteConfirmation: true
        });
    }

    render() {

        return <div className="user-comments">
            {
                this.state.deleteConfirmation &&

                <ModalConfirm onClose={this.handleNoDelete}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div>
                                <div className="text-center">
                                    <h3>{'Are you sure?'}</h3>
                                    <button className="button-outlines green text-center"
                                            onClick={this.handleNoDelete}>
                                        {'No'}
                                    </button>
                                    <button className="button-primary-edited text-center btn-ok"
                                            onClick={this.handleYesDelete}>
                                        {'Yes'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </ModalConfirm>
            }

            {
                this.state.editPopup &&

                <ModalConfirm onClose={this.handleNoEdit}>
                    <section className="newPanel">
                        <div className="text-center m-bot15">
                            <div className="p-all20">
                                <h3>{ 'Edit comment'}</h3>
                                <textarea className="form-control" onChange={this.handleEditChange}
                                          value={this.state.message}></textarea>
                                <button className="button-outlines green text-center"
                                        onClick={this.handleNoEdit}>
                                    {'Cancel'}
                                </button>
                                <button className="button-primary-edited text-center btn-ok"
                                        onClick={this.handleEdit}>
                                    {'Save'}
                                </button>
                            </div>
                        </div>
                    </section>
                </ModalConfirm>
            }

            <div className="comment-content" onClick={this.showOptions}>
                    <span>
                        {this.props.comment.message}
                    </span>
                    {this.props.comment.isAuthor &&
                <AnimateHeight
                    duration={this.state.duration}
                    height={this.state.height}>
                    <div className="comment-actions">
                        <a onClick={this.edit}>{'Edit'}</a>&nbsp;&nbsp;<a
                        onClick={this.deleteComment}>{'Delete'}</a>
                    </div>
                </AnimateHeight>
                }
            </div>
            <div
                className="publish-date"> {this.props.comment.time}
            </div>
        </div>
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    editComment: (id, text, type) => dispatch(editComment(id, text, type)),
    deleteComment: (id, type) => dispatch(deleteComment(id, type))
});

export default connect(mapStateToProps, mapDispatchToProps)(Comment);
