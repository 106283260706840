import client from './client';
import { toast } from 'react-toastify'

import {
  GET_CONTACT_COUNTRIES,
  GET_ACCOUNT,
  GET_DUPLICATED_ACCOUNTS,
  RESOLVE_DUPLICATED_ACCOUNTS,
  GET_DUPLICATED_FORM,
  GET_CRM_REPORT
} from './types';

export const getCrmReport = (start, end) => dispatch => {  
  client
    .get('/api/admin/account-report/all?start='+start+'&end='+end)
    .then(res =>
      dispatch({
        type: GET_CRM_REPORT,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_CRM_REPORT,
        payload: {}
      })
       
  });
};

// Get 
export const getContactCountries = () => dispatch => {  
  client
    .get('/web/filter/contact/countries')
    .then(res =>
      dispatch({
        type: GET_CONTACT_COUNTRIES,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_CONTACT_COUNTRIES,
        payload: {}
      })
       
  });
};

// Get 
export const setContactCountries = () => dispatch => {       
      dispatch({
        type: GET_CONTACT_COUNTRIES,
        payload: {}          
  });
};

// Get 
export const getAccount = (id) => dispatch => {  
  client
    .get('/api/admin/account-profile/'+id)
    .then(res =>
      dispatch({
        type: GET_ACCOUNT,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_ACCOUNT,
        payload: {}
      })
       
  });
};

export const getDuplicatedAccounts = (type) => dispatch => {      
    client
    .get('/api/admin/account-duplicate/list/'+type)
    .then(res =>
      dispatch({
        type: GET_DUPLICATED_ACCOUNTS,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_DUPLICATED_ACCOUNTS,
        payload: {}
      })       
  });
    
};

export const postGetDuplicateForm = (values, type) => dispatch => {      
    client
    .post('/api/admin/account-duplicate/get-form/'+type, values)
    .then(res => {
        console.log(res.data)
      dispatch({
        type: GET_DUPLICATED_FORM,
        payload: res.data.duplicatedForm
      })
    })
    .catch(err => {       
      dispatch({
        type: GET_DUPLICATED_FORM,
        payload: {}
      })
       
  });
    
};

export const resolveDuplicatedAccounts = (data, type, route) => dispatch => {  
   console.log(data)
    client
    .post('/api/admin/account-duplicate/resolve/'+type, data)
    .then(res => {
         window.location = route
         toast.success("Resolved!", {autoClose: 10000})     
   })
    .catch(err => {    
         if(err.response && err.response.data){         
         console.log(err.response.data.error)
 toast.error(err.response.data.error, {autoClose: 10000}) 
                }else{
            toast.error("Error!", {autoClose: 10000}) 
        }
  });    
};

export const getDuplicatedAccountsResolve = (type, ids) => dispatch => {  
 client
    .post('/api/admin/account-duplicate/form/resolve/'+type, {ids: ids})
    .then(res =>
      dispatch({
        type: GET_DUPLICATED_ACCOUNTS,
        payload: res.data
      })
    )
    .catch(err => {       
      dispatch({
        type: GET_DUPLICATED_ACCOUNTS,
        payload: {}
      })       
  });  
};

export const dismissDuplicatedAccount = (id) => dispatch => {
     client
        .get('/api/admin/account-duplicate/dismiss/'+id)
        .then(res => {  
              toast.success("Success!", {autoClose: 10000})     
        })
        .catch(err => {
            toast.error('Error!', {autoClose: 10000})
        });
}

export const getProfileData = (id) => dispatch => {
     client
                .get('/web/account/'+id+'/csv', {responseType: 'blob'})
                .then(res => {                  
                    var blob = new Blob([res.data], {type: res.headers['content-type']});
                    var downloadUrl = URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = downloadUrl;
                    a.download = 'accountData.csv';
                    document.body.appendChild(a);
                    a.click();
                })
                .catch(err => {
                    toast.error('Download error!', {autoClose: 10000})
                });
}

export const createDataAccess = (email, id) => dispatch => {
  client
    .post('/api/admin/account-send/data', {email: email, id: id})
    .then(res => {
     
      dispatch({
        type: GET_ACCOUNT,
        payload: res.data
      })
         toast.success("The data was send!", {autoClose: 10000})     
   })
    .catch(err => {       
             
  });
}

export const viewAccountData = (token) => dispatch  => {    
    
     client
    .get('/web/account/view/data/'+token)
    .then(res => {
        if(typeof res.data.account !== 'undefined'){
            dispatch({
              type: GET_ACCOUNT,
              payload: res.data.account
            })
        }else{
         toast.error("Sorry, this link is expired!", {autoClose: 10000})     
     }
   })
    .catch(err => {       
             
  });
}

export const postOverrideAccount = (id) => dispatch => {
    client
        .post('/api/admin/account-form/override/'+id)
        .then(res => {
             
        })
        .catch(err => {
        });
}


