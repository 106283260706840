import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {exportPdfDataButton} from '../../../actions/gridActions';
import Button from '@material-ui/core/Button';
import ReactToPrint, { PrintContextConsumer }  from "react-to-print";
import ComponentToPrint from './ComponentToPrint'

const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});

class Print extends Component { 
  constructor() {
    super();
  }
    componentDidMount() {    
       this.props.exportPdfDataButton('/api/admin/financial-report/'+this.props.match.params.Supplier+'/report/export/pdf')
    }
  render() { 
   const { pdfData } = this.props.gridProperties; 
     const { classes } = this.props;
 
    return (           
      <Grid container spacing={0} > 
          <Grid item md={10} lg={10} xs={6} className="text-right m-top20"> 
     <ReactToPrint content={() => this.componentRef}>
          <PrintContextConsumer>
            {({ handlePrint }) => (
             <Button onClick={handlePrint} variant="outlined"> Print</Button>
            )}
          </PrintContextConsumer>
        </ReactToPrint>
         </Grid><Grid item md={2} lg={2} xs={6}></Grid>
       {pdfData && <ComponentToPrint ref={el => (this.componentRef = el)} pdfData={pdfData} />}       
        
      </Grid>
    );
  }
}

Print.propTypes = {
  gridProperties: PropTypes.object.isRequired,
  exportPdfDataButton: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  gridProperties: state.gridProperties,
});

Print = withStyles(styles)(Print)

export default connect( mapStateToProps, { exportPdfDataButton })(withRouter(Print));
