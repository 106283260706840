import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames';
import Navbar from '../layout/Navbar';
import Grid from '@material-ui/core/Grid';
import Footer from '../layout/Footer'
import Icon from '@material-ui/core/Icon';
import { getCurrentProfile } from '../../actions/profileActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { sendEmail, sendTest, clearCheckUser, sendNotificationQuestionnaire, getOrganizations } from "../../actions/questionnaireActions";
import { sendLogin, clearErrors, setCurrentUser, setMessage } from "../../actions/authActions";
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify'
import DialogContentText from '@material-ui/core/DialogContentText';
import AsyncCreatableSelect from 'react-select/async-creatable';
import {onSelect2InputChange, onAddSelectOption} from '../../actions/formActions';
import { getLogActivityWithGeoIp } from '../../actions/profileActions';
import { Link } from 'react-router-dom';
import LoginForm from '../layout/LoginForm'

const createOption = (label: string) => ({
  label,
  value: 'newCreatedOption',
});

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    marginBottom: 25,
  },
  label: {
      textTransform: 'inherit',
      lineHeight: 1.6
  },
  error: {
      color: 'red',
      marginTop: 10,
      marginBottom: 10,
      fontSize: 18
  },
  toastWidth: {
      width: '500px'
  }
});


class SustainabilityAssessments extends Component {

    constructor() {
        super();
        this.state = {
            openEmail: false,
            openPass: false,
            openTest: false,
            email: '',
            pass: '',
            first: '',
            firstAddional: '',
            second: '',
            third: '',
            fourthProduct: '',
            fourthBranch: '',
            fifth: '',
            checkUser: null,
            error: null,
            organizations: []
        };
  }

     componentDidMount() {
        this.props.getOrganizations();
        this.props.getLogActivityWithGeoIp('Sustainability Assessments page', 'GET', "Website usage")
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.questionnaire.checkUser){
            if(nextProps.questionnaire.checkUser.user){
                this.setState({
                    checkUser: nextProps.questionnaire.checkUser.user,
                    openPass: nextProps.questionnaire.checkUser.user,
                })
            }else if(nextProps.questionnaire.checkUser.user === false){
                this.setState({
                    checkUser: nextProps.questionnaire.checkUser.user,
                    openTest: true,
                    error: ''
                })
            }
        }
        if(nextProps.questionnaire.organizations){
            this.setState({
                organizations: nextProps.questionnaire.organizations.options
            })
        }
        if(nextProps.message.message === 'Password success without role'){
            this.setState({
                openPass: false,
                openTest: true,
                pass: '',
                error: ''
            })
        }

        if(nextProps.message.message === 'Password success'){
            this.setState({
                openPass: false,
                pass: '',
                error: ''
            })
        }

           if(nextProps.message.message === 'ROLE_QUESTIONNAIRE'){
               var user = this.props.auth.user;
               user.roles.push('ROLE_QUESTIONNAIRE')
               this.props.setCurrentUser(user)
               this.props.setMessage('Password success')

        }
    }

    openGetEmail = (e) => {
        e.preventDefault()

        if(this.props.auth.isAuthenticated  && !this.props.auth.user.roles.includes('ROLE_QUESTIONNAIRE')){
            this.setState({
            openTest: true,
             error: ''
        })
        }else if(this.props.auth.isAuthenticated  && this.props.auth.user.roles.includes('ROLE_QUESTIONNAIRE')){

        this.props.sendNotificationQuestionnaire()
            toast('An admin will send you questionnaire.', {
                    position: "top-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                    });
            this.setState({
             error: ''
        })
        }else{
             this.setState({
            openEmail: true,
        })
        }

    }


    closeGetEmail = () => {
        this.setState({
            openEmail: false,
            error: ''
        })
    }
    closePass = () => {
        this.props.clearErrors()
        this.props.clearCheckUser()
        this.setState({
            openPass: false,
            pass: ''
        })
    }
    closeTest = () => {
        this.setState({
            openTest: false,
            first: '',
            firstAddional: '',
            second: '',
            third: '',
            fourthProduct: '',
            fourthBranch: '',
            fifth: '',
            error: ''
        })
    }



    handleSubmitEmail  = event => {
         event.preventDefault()

        if(this.state.email){
            console.log(this.validateEmail(this.state.email))
        if(this.validateEmail(this.state.email)){
            this.props.sendEmail(this.state.email)
            this.setState({
                openEmail: false,
            })
        }else{
            this.setState({
                error: "The email is not valid!",
            })
        }
        }
    }

    validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

     handleSubmitPass  = event => {
         event.preventDefault()
         console.log(this.props.auth.isAuthenticated)
        if(this.state.email && this.state.pass){
             const userData = {
                username: this.state.email,
                password: this.state.pass
              };
            this.props.sendLogin(userData)

        }
    }
    handleSubmitTest  = event => {
         event.preventDefault()

        if(this.state.first && this.state.second  && this.state.third && this.state.fourthProduct && this.state.fourthBranch && this.state.fifth ){
        if(this.state.first==='No' && !this.state.firstAddional){
            this.setState({
               error: 'Please, fill all fields!',
           })
       }else{
       var success = 'no';

       if(this.state.first === "Yes" && this.state.second === "Yes" && this.state.third === "Yes" && this.state.fifth === "No"){
           success = 'yes';
       }
       var email = this.state.email;
       if(this.props.auth.user && this.props.auth.user.username){
           email = this.props.auth.user.username
       }

           var data = {
                first: this.state.first,
                firstAddional: this.state.firstAddional,
                second: this.state.second,
                third: this.state.third,
                fourthProduct: this.state.fourthProduct,
                fourthBranch: this.state.fourthBranch.label,
                fifth: this.state.fifth,
                email: email,
                auth: this.props.auth.isAuthenticated,
                success: success
            }
            this.props.sendTest(data)
            if(this.props.auth.isAuthenticated && success === 'yes'){
                toast('An admin will send you questionnaire.', {
                    position: "top-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                    });
            }else if(this.props.auth.isAuthenticated === false && success === 'yes'){
               this.props.history.push('/register/questionnaire-respondent?email='+this.state.email)
               toast('Please, register as Questionnaire Respondent and an admin will send you questionnaire.', {
                    position: "top-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false
                    });
            }else if(success === 'no'){
                 toast('Thank you for your interest in the Sustainability Assessments questionnaire, and for taking the time to fill our eligibility questions. We see that you are not an approved Producer or Manufacturer of commodities in the Health sector, or that your organizational branch has previously taken the questionnaire within the past 6 months, so you are ineligible to take the questionnaire at this time. In the meantime, however, your submitted information has been sent to the SPHS Secretariat to see if we can collaborate with you in other areas', {
                    position: "top-center",
                    autoClose: 30000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    toastClassName: 'Toastify'
                    });
            }
            this.setState({
                openTest: false,
                first: '',
                firstAddional: '',
                second: '',
                third: '',
                fourthProduct: '',
                fourthBranch: '',
                fifth: '',
                error: ''
            })
        }

        }else{
            this.setState({
               error: 'Please, fill all fields!',
             })
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
     }
     handleChangeSelect = value => {
         this.setState({
            fourthBranch: value
        })
     }

     handleCreate = (inputValue: any) => {
      const newOption = createOption(inputValue);
      this.setState({
          fourthBranch: newOption
      })

  };

     loadOptions = (inputValue, callback) => {
         console.log(inputValue)
        if (!inputValue) inputValue = -1;
        let hasSettings = this.props.questionnaire.organizations.settings && this.props.questionnaire.organizations.settings.optionsUrl;
        this.props.onSelect2InputChange('Test', this.props.questionnaire.organizations.id, inputValue, this.props.questionnaire.organizations.settings.optionsUrl)
        .then(response => {
                let options = [];
                if (response) {
                    options = response.map(option => {
                        return {
                            ...option,
                            'value': option.value,
                            'label': option.label
                        };
                    });
                }
               // this.options = options;
                if(options.length > 0){
                    this.setState({
                        organizations: options
                    })
                }else if(this.state.organizations.length > 0){
                    this.setState({
                        organizations: this.props.questionnaire.organizations.options
                    })
                }

                if(!hasSettings) {
                    let ops = this.props.questionnaire.organizations.options;
                    ops = ops.filter(item => item.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1);
                    callback(ops);
                }
                else callback(options ? options : this.props.questionnaire.organizations.options);
            });
    }


  render() {
const { classes, questionnaire, errors } = this.props;

  return (
    <div>
     <Navbar/>
        <div  className="page-content  slideup page-animation" >
      <Grid container className="assessments-page">
         <Grid item md={12} sm={12} xs={12} className="header vcenter text-center">
            <h1>
                Welcome to the SPHS Secretariat<br/> Sustainability Assessments space!
            </h1>
            <p>Are you a Supplier, Manufacturer, or Procurer of Health Supplies, interested in understanding the<br/> performance of Suppliers and Manufacturers in relation to environmentally sustainable procurement<br/> practice benchmarks?  If so, you may indicate your interest in taking the EA assessment.</p>
              <a className="blue-button text-white text-uppercase" onClick={this.openGetEmail}>I want to take the questionnaire</a>
              {this.props.auth.isAuthenticated ?
              <Link  to="/members/questionnaires" style={{ color: "rgb(82, 182, 199)", textDecoration: "underline"}} title="Environmental Sustainability Profiles, Scores and Reports">Environmental Sustainability Profiles, Scores and Reports</Link>
               :
               <LoginForm role="sustainability"/>   
               }
              <a href="/media/UNDP_Environmental_Questionnaire_Interactive_FINAL.pdf" target="_blank" style={{ color: "rgb(82, 182, 199)", textDecoration: "underline",marginTop: 0}} title="Download file"><Icon className="fa fa-download" style={{ fontSize: 14 }} /> Environmental Sustainability Assessments Questionnaire</a>
         </Grid>
        <Grid container>
         <Grid item md={1} sm={1} xs={1}></Grid>
         <Grid item md={10} sm={10} xs={10} className="content">
          <div>
            <span>
                What are Sustainability Assessments?
            </span>
            <p>Our sustainability assessments tool provides a dynamic and user-friendly mechanism for  Suppliers and Manufacturer organizations to evaluate the production processes they currently use, and how those meet broad environmental sustainability standards and objectives. Our <strong>Sustainability Assessment (EA) Questionnaire</strong> enables interested supplier and manufacturer organizations to assess how they well they perform in relation to other organizations in the health commodities marketplace in meeting environmental sustainability standards.</p>
            <p>Every purchasing decision made by an organization has an impact on the environment, the economy and on society. Guiding that decision towards more sustainable goods and services can help drive markets in the  direction of innovation and sustainability, thereby enabling the transition to a green economy. A significant number of environmental standards and assessment tools for a broad range of products and services in the health sector are already available,  however there has not been any consensus regarding the environmental evaluation of suppliers yet. Therefore, in 2016, UNDP developed a standardized environmental questionnaire to assess the performance of its suppliers and manufacturers in the health sector. UNDP has drawn on internationally recognized environmental standards, reporting systems and assessment tools to develop a comprehensive questionnaire that is applicable to healthcare commodities purchased by UNDP, and on a broader level to any product or service in the health sector.</p>
            <p>This questionnaire is aligned with the United Nations Supplier Code of Conduct and the ten principles of the United Nations Global Compact. This tool is a critical milestone for the development of the Green Procurement Index Health, and provides a foundation for the development of green procurement criteria in the health sector. Through the collection of data on suppliers’ environmental practices, it will be possible to not only define a baseline, but also define a set of realistic environmental criteria to be included in upcoming tenders. Procurement practitioners are able to use the tool as a reference point in their selection of suppliers. It is also a powerful tool to identify best practices as well as areas of need in the support and capacity-building of suppliers in order to reach the UNDP goal of enabling the global transition to a green economy.<br/><br/>
              Additional Resources:  <a href="https://issuu.com/informal_int_task_team_sphs/docs/compliance_with_int_conventions_on_">HEALTHCARE PROCUREMENT AND THE COMPLIANCE WITH INTERNATIONAL ENVIRONMENTAL CONVENTIONS ON CHEMICALS</a></p>
            </div>
            <div>
              <span>
                  Who are Sustainability Assessments for?
              </span>
              <p>The tool and its reporting outputs provides segmented information to <strong>supplier/manufacturer organizations procuring goods and services, procurers (the United Nations Member Agencies), and to the SPHS Secretariat</strong>. The tool facilitates an assessment of the procurement practices of suppliers and manufacturers, the environmental risk they carry, and helps them to evaluate their own practices, as well as seeing how their operations and current practices rank compared to similar organziations. The outputs of the assessments provide segmented information to buyers, suppliers, and the SPHS Secretariat</p>
            </div>
            <div>
              <span>
                How does participating in Sustainability Assessments help my business and customers?
              </span>
              <p><strong>Value Proposition:</strong> Discerning buyers want to understand how the organizations they procure from perform on certain environment risk criteria and standards. An environmentally sustainable operation is an attractive asset for an organization to claim, in order to attract those customers. It is an advantage to your business to demonstrate credibility in this area, as you work towards fulfilling certain standards of environmental performance and sustainability.</p>
              <p className="text-center"><strong>We envision the Social Impact Report assessments to be<br/> the foundation of a future globally recognized Certification of Quality<br/> in meeting key environmental standards.</strong></p>
              <p>Our Sustainability Assessments tool enables you to see where your organization ranks in comparison to competitors and similar organizations procuring the same or similar goods, on specific criteria. Armed with this knowledge, you can make incremental changes and track your progress towards clearly defined goals and have the evidence you need to share your competence in these areas with your customers. </p>
            </div>
            <div>
              <span>
              How do the Assessments work?
              </span>
              <p><strong>Scoring and Methodology:</strong> After identifying the core elements of an environmental impact assessment process and determining the broad categories this questionnaire should cover, specific questions were developed for each of those categories. The categories were selected in accordance with the nine focus areas of the United Nations informal Interagency Task Team on Sustainable Procurement in the Health Sector (SPHS). The questions are divided into two main blocks: manufacturing level for those questions assessing the overall environmental performance of the supplier or manufacturer and product level for the questions referring to the specific healthcare product that is being assessed. Many of the the questions ask respondents to provide documents to support their responses.</p>
              <p>Questionnaire responses are automatically scored, and participants can view the ratings/scores of comparable organizations. The assessments can be taken once a year, to enable participants to track their progress against high, average and low performances in the market and assess how they have improved over time. </p>
              <p>Following the initial launch if the questionnaire, an international consultation and review process took place, inviting the inputs of technical experts in key areas such as carbon footprint, wastewater treatment, hazardous chemical management, and green procurement. The main goal of these consultations were to not only improve the relevance of the questionnaire, but also to further harmonize and standardize it. At the conclusion of this process, a final version was developed, which was accepted and recognized as a key tool by partners and stakeholders.</p>
            </div>
            <div>
              <span>Model Tender Documents</span>
              <p>With the impact of climate change being more and more pronounced, the health sector is proactively using every opportunity at its disposal to reduce its carbon footprint. This is more critical now with available evidence showing that the Health care's climate footprint is equivalent to 4.4% of global net emissions (2 gigatons of carbon dioxide equivalent). By strengthening the entire procurement cycle as an entry point, the health sector can contribute to the reduction of Green House Gases to protect people and planet. In this regard UNDP adopted and promoted the development and publication of Model Tender Documents. For a tender process to be regarded as model, it must integrate environmental and social dimensions of sustainability. Environmental considerations in tender documents demonstrate efforts to reduce pollution, excess water and energy use, among others. Social dimensions include Gender, Human Rights and Anti-Corruption considerations such as encouraging women led and owned enterprise, demonstrate commitment to eliminating all forms of corruption and demonstrate ability to promote Human rights principles in business. This will make the health sector, a major champion of circular approach to sustainable development.</p>
              AN EXAMPLE OF INVITATION TO BID: <a href="https://api.savinglivesustainably.org/documents/file/e857cf8aa3c2371a44d12e1a16fd328b/full/hash" style={{textDecoration: "underline"}}>REDUCING UPOPS AND MERCURY RELEASES FROM THE HEALTH SECTOR IN AFRICA</a><br/><br/>
            </div>
            <div>
              <span>
              Disclaimer:
              </span>
              <p>All questionnaire responses will be held in strict confidence, and all outputs and reports from the questionnaire responses will protect the confidentiality of individual respondents.</p><br/><br/><br/>
            </div>
         </Grid>
         </Grid>
            <Footer/>
          </Grid>
          </div>
                    <Dialog
                        onClose={this.closeGetEmail}
                        aria-labelledby="simple-dialog-title"
                        open={this.state.openEmail}
                        maxWidth="sm"
                        fullWidth
                        BackdropProps={{
                        classes: {
                         root: classes.rootDialog
                        }
                       }}>
                        <DialogTitle id="simple-dialog-title">Please enter your email <span className="pull-right pointer" onClick={this.closeGetEmail}>
                                 <i className="far fa-times-circle"></i></span>
                                 </DialogTitle>
                         <DialogContent>
                         <section className="newPanel">
                            <div className="panel-body">
                            {this.state.error && <div className={classes.error}>{this.state.error}</div>}
                                <form method="post" onSubmit={this.handleSubmitEmail}>
                                    <label> </label>
                                      <input name="email" type="text" onChange={this.handleChange} value={this.state.email} style={{marginBottom: 15}}/>
                                        <button type="submit" className="btn btn-default">Send</button>
                                    </form>
                            </div>
                        </section>
                         </DialogContent>
                      </Dialog>
                      {/* password dialog*/}
                       <Dialog
                        onClose={this.closePass}
                        aria-labelledby="simple-dialog-title"
                        open={this.state.openPass}
                        maxWidth="sm"
                        fullWidth
                        BackdropProps={{
                        classes: {
                         root: classes.rootDialog
                        }
                       }}>
                        <DialogTitle id="simple-dialog-title">Please enter your password to continue <span className="pull-right pointer" onClick={this.closePass}>
                                 <i className="far fa-times-circle"></i></span>
                                 </DialogTitle>
                         <DialogContent>
                         <DialogContentText style={{fontSize: 14}}>
                     {Object.keys(this.props.errors).length > 0 &&
                       (<span className="label label-danger margin-b-15">Invaid credentials.</span>)
                            }
                    </DialogContentText>
                         <section className="newPanel">
                            <div className="panel-body">
                            <a href="/password-reset" onClick={this.setStyleOnClose}>Can't access your account?</a>
                                <form method="post" onSubmit={this.handleSubmitPass}>
                                    <label> </label>
                                      <input name="pass" type="password" onChange={this.handleChange} value={this.state.pass} style={{marginBottom: 15}}/>
                                        <button type="submit" className="btn btn-default">Send</button>
                                    </form>
                            </div>
                        </section>
                         </DialogContent>
                      </Dialog>
                      {/* test dialog*/}
                        <Dialog
                        onClose={this.closeTest}
                        aria-labelledby="simple-dialog-title"
                        open={this.state.openTest}
                        maxWidth="lg"
                        fullWidth
                        BackdropProps={{
                        classes: {
                         root: classes.rootDialog
                        }
                       }}>
                        <DialogTitle id="simple-dialog-title">Eligibility Criteria <span className="pull-right pointer" onClick={this.closeTest}>
                                 <i className="far fa-times-circle"></i></span>
                                 </DialogTitle>
                         <DialogContent>
                         <section className="newPanel">
                            <div className="panel-body">
                            {this.state.error && <div className={classes.error}>{this.state.error}</div>}
                                <form method="post" onSubmit={this.handleSubmitTest}>
                                      <FormControl component="fieldset" className={classes.formControl}>
                                        <FormLabel className={classes.label}>{'1. I am a supplier or manufacturer of health commodities'}</FormLabel>
                                        <RadioGroup aria-label={'first'} name="first" value={this.state.first} style={{display: "inline"}} >
                                        <FormControlLabel
                                          value={'Yes'}
                                          key={1}
                                          control={<Radio />}
                                          label={'Yes'}
                                          checked={'Yes' == String(this.state.first) ? true : false}
                                          onChange={this.handleChange}
                                          style={{fontSize: 14,fontFamily: 'OpenSans ,sans-serif'}}
                                          />
                                          <FormControlLabel
                                          value={'No'}
                                          key={2}
                                          control={<Radio />}
                                          label={'No'}
                                          checked={'No' == String(this.state.first) ? true : false}
                                          onChange={this.handleChange}
                                          style={{fontSize: 14,fontFamily: 'OpenSans ,sans-serif'}}
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                      {this.state.first==='No' &&
                                      <FormControl fullWidth className={classes.formControl}>
                                           <TextField
                                            id="standard-full-width"
                                            label="Why are you interested in this questionnaire?"
                                            name='firstAddional'
                                            value={this.state.firstAddional}
                                            onChange={this.handleChange}
                                            placeholder=" "
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{
                                              shrink: true,
                                              className: classes.secondLabel
                                            }}
                                          />
                                      </FormControl>
                                      }
                                      <FormControl component="fieldset" className={classes.formControl}>
                                        <FormLabel className={classes.label}>{'2. I, and whomever within the organization will work with me \n\
                                        to fill this assessment am approved by our organization to represent \n\
                                        the organizations` health procurement sustainability information accurately'}
                                        </FormLabel>
                                        <RadioGroup aria-label={'second'} name="second" value={this.state.second} style={{display: "inline"}} >
                                        <FormControlLabel
                                          value={'Yes'}
                                          key={1}
                                          control={<Radio />}
                                          label={'Yes'}
                                          checked={'Yes' == String(this.state.second) ? true : false}
                                          onChange={this.handleChange}
                                          style={{fontSize: 14,fontFamily: 'OpenSans ,sans-serif'}}
                                          />
                                          <FormControlLabel
                                          value={'No'}
                                          key={2}
                                          control={<Radio />}
                                          label={'No'}
                                          checked={'No' == String(this.state.second) ? true : false}
                                          onChange={this.handleChange}
                                          style={{fontSize: 14,fontFamily: 'OpenSans ,sans-serif'}}
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                       <FormControl component="fieldset" className={classes.formControl}>
                                        <FormLabel className={classes.label} >{'3. I understand that the SPHS Secretariat is seeking no duplicates per branch location + product.\n\
                                         As far as I know, I am making the only submission per this product and branch of the organisation: \n\
                                            SPHS reserves the right to remove or average duplicate submissions or to follow up with you to query these'}
                                        </FormLabel>
                                        <RadioGroup aria-label={'third'} name="third" value={this.state.third} style={{display: "inline"}} >
                                        <FormControlLabel
                                          value={'Yes'}
                                          key={1}
                                          control={<Radio />}
                                          label={'Yes'}
                                          checked={'Yes' == String(this.state.third) ? true : false}
                                          onChange={this.handleChange}
                                          style={{fontSize: 14,fontFamily: 'OpenSans ,sans-serif'}}
                                          />
                                          <FormControlLabel
                                          value={'No'}
                                          key={2}
                                          control={<Radio />}
                                          label={'No'}
                                          checked={'No' == String(this.state.third) ? true : false}
                                          onChange={this.handleChange}
                                          style={{fontSize: 14,fontFamily: 'OpenSans ,sans-serif'}}
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                     <FormControl component="fieldset" className={classes.formControl} style={{zIndex: 999}}>
                                      <FormLabel className={classes.label}>{'4. Please enter the product name and your organization branch location'}</FormLabel>
                                      <label>Product name</label>
                                       <TextField
                                            id="standard-full-width"

                                            name='fourthProduct'
                                            value={this.state.fourthProduct}
                                            onChange={this.handleChange}
                                            placeholder=" "
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{
                                              shrink: true,
                                              className: classes.secondLabel
                                            }}
                                          />
                                       <label style={{margin: "16px 0px"}}>Branch name</label>
                                          {this.state.organizations &&
                                            <AsyncCreatableSelect
                                                isMulti={false}
                                                cacheOptions
                                                defaultOptions
                                                cacheOptions
                                                onChange={(value) => this.handleChangeSelect(value)}
                                                onBlurResetsInput={false}
                                                onCloseResetsInput={false}
                                                isDisabled={false}
                                                menuPlacement={"auto"}
                                                loadOptions={this.loadOptions}
                                                defaultOptions={this.state.organizations}
                                                value={this.state.fourthBranch}
                                                onCreateOption={this.handleCreate}
                                                style={{zIndex: 10000}}
                                             />
                                           }
                                         </FormControl>
                                             <FormControl component="fieldset" className={classes.formControl}>
                                        <FormLabel className={classes.label} >{'5. Have you or anyone else at your branch location \n\
                                             supplying/manufacturing the same product taken this questionnaire within the past 6 months?'}
                                        </FormLabel>
                                        <RadioGroup aria-label={'fifth'} name="fifth" value={this.state.fifth} style={{display: "inline"}} >
                                        <FormControlLabel
                                          value={'Yes'}
                                          key={1}
                                          control={<Radio />}
                                          label={'Yes'}
                                          checked={'Yes' == String(this.state.fifth) ? true : false}
                                          onChange={this.handleChange}
                                          style={{fontSize: 14,fontFamily: 'OpenSans ,sans-serif'}}
                                          />
                                          <FormControlLabel
                                          value={'No'}
                                          key={2}
                                          control={<Radio />}
                                          label={'No'}
                                          checked={'No' == String(this.state.fifth) ? true : false}
                                          onChange={this.handleChange}
                                          style={{fontSize: 14,fontFamily: 'OpenSans ,sans-serif'}}
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                       <Grid item md={12} sm={12} xs={12} >
                                        <button type="submit" className="btn btn-lg text-uppercase button-primary" style={{marginLeft: 0}}>Send</button>
                                        </Grid>
                                    </form>
                            </div>
                        </section>
                         </DialogContent>
                      </Dialog>

      </div>
      );
    }
    }
SustainabilityAssessments.propTypes = {
  sendEmail: PropTypes.func.isRequired,
  sendLogin: PropTypes.func.isRequired,
  sendTest: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  questionnaire: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  clearErrors: PropTypes.func.isRequired,
  clearCheckUser: PropTypes.func.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  sendNotificationQuestionnaire: PropTypes.func.isRequired,
  getOrganizations: PropTypes.func.isRequired,
  onSelect2InputChange: PropTypes.func.isRequired,
  getLogActivityWithGeoIp: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    questionnaire: state.questionnaire,
    errors: state.errors,
    message: state.message
});

SustainabilityAssessments = withStyles(styles)(SustainabilityAssessments)
export default connect( mapStateToProps, { getLogActivityWithGeoIp, onSelect2InputChange, getOrganizations, sendEmail, sendLogin, sendTest, clearErrors, clearCheckUser, setCurrentUser, setMessage, sendNotificationQuestionnaire })(withRouter(SustainabilityAssessments));
