import { config } from '../../../config';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SpeakersModal from './SpeakersModal';
import Forum2022Programme from './Forum2022Programme';
import axios from 'axios';

class Forum2022Speakers extends Component {



  constructor(props) {
        super(props);
          this.state = {
          openLoadMore: false,
          data: null,
          locale: null
          }
  }

  componentDidMount() {
    axios('data/'+this.props.locale+'forum2022.json',{
      headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    })
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        this.setState({
          data: response
        })
      })
      this.setState({
        locale: this.props.locale
      })
    }

    componentDidUpdate() {
if(this.props.locale !== this.state.locale){
  axios('data/'+this.props.locale+'forum2022.json',{
    headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
     }
  })
    .then((response) => {
      return response.data;
    })
    .then((response) => {
      this.setState({
        data: response
      })
    })

    this.setState({
      locale: this.props.locale
    })
}

      }



  loadMore = () => {
  this.setState({
  openLoadMore: true
          })
          }


  render() {

  return (
    <span>
    <div className="speakers">
      <div className="max-width">
        <div className="speakers-box-fixed">
          <a className="anchor-link" name="asia-forum-intro-anchor-1">
            <h1 className="text-center">
              <span className="text-black">{this.state.locale === 'ES' ? 'Oradores' : 'Featuring'}</span>
            </h1>
          </a>
          <div className="col-md-12">
          {this.state.data && this.state.data.speakers && this.state.data.speakers.map(speaker => {
                return (
                <div className="col-md-4 text-center col-xs-12">
                  <div className="speaker-box" style={{ height:'400px'}}>
                    <SpeakersModal speaker={{image: speaker.image, name: speaker.name, text: speaker.bio}}/>
                    <h3>{speaker.name}</h3>
                    <h4 className="text-gray">
                    {speaker.title}
                    </h4>
                  </div>
                </div>)

              })}
                <div className="clearfix"></div>
            </div>
        </div>
    </div>
  </div>
  {!this.state.openLoadMore &&
              <div id="loadMoreSpeakers" className="speakers" style={{ paddingBottom: '100px' }}>
                  <div className="max-width">
                      <div className="speakers-box-fixed">
                          <a className="blue-button text-white text-uppercase" onClick={this.loadMore}>
                              Load more
                          </a>
                      </div>
                  </div>
              </div>
            }
              {this.state.openLoadMore &&
  <div className="speakers" >
      <div className="max-width">
          <div className="speakers-box-fixed">
              <div className="col-md-12">

          </div>
        </div>
      </div>
    </div>
  }
    <Forum2022Programme locale={this.props.locale}/>
    <div className="speakers container">
      <div className="max-width">
        <div className="speakers-box-fixed">
          <a className="anchor-link" name="asia-forum-intro-anchor-1">
            <h1 className="text-center">
              <span className="text-black">{this.state.locale === 'ES' ? 'Contactos' : 'Contact Persons'}</span>
            </h1>
          </a>
          <div className="row">
          <div className="col-md-12">
              {this.state.data && this.state.data.team && this.state.data.team.map(team => {
                return (
                <div className="col-md-4 text-center col-xs-12">
                  <div className="speaker-box">
                    <SpeakersModal speaker={{image: team.image, name: team.name, text: team.bio}}/>
                    <h3>{team.name}</h3>
                    <h4 className="text-gray">
                    {team.title}
                    </h4>
                  </div>
                </div>)

              })}
          </div>
          </div>
        </div>
      </div>
    </div>
  </span>                                                                                                                                                                                                                                       );
  }
  }

export default Forum2022Speakers
