import React, {Component} from 'react';
import { Link } from 'react-router-dom';

export default class MenuAdministration extends Component {
    render() {
        const settings = this.props.settings;
        const menuIconsTheme = this.props.theme;

        return (
            <div className="panel-body">
               
                    <li>
                        <a href="#">
                            <span>Analysis</span>
                            <i style={menuIconsTheme} className="glyphicon glyphicon-chevron-down"></i>
                        </a>
                        <ul>
                            <li className="col-sm-6">
                                <Link id="codes" title={'Codes'}
                                   to={'/administration/analysis-codes'}>
                                    <span>Codes</span>
                                </Link>
                            </li>
                            <li className="col-sm-6">
                                <Link id="Categories" title={'Categories'}
                                   to={'/administration/analysis-categories'}>
                                    <span>Categories</span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                
                <li>
                    <a href="#">
                        <span>Currencies</span>
                        <i style={menuIconsTheme} className="glyphicon glyphicon-chevron-down"></i>
                    </a>
                    <ul>
                        <li className="col-sm-6">
                            <Link id="Currencies" title={'Currencies'}
                               to={'/administration/currencies'}>
                                <span>Currencies</span>
                            </Link>
                        </li>
                        <li className="col-sm-6">
                            <Link id="Currency Rates" title={'Currency Rates'}
                               to={'/administration/currency-rates'}>
                                <span>Currency Rates</span>
                            </Link>
                        </li>
                    </ul>
                </li>
              
                
                    <li>
                        <Link id="Users" title={'Users'}
                           to={'/administration/users'}>
                            <span>User Management</span>
                        </Link>
                    </li>
                
                    <li>
                        <Link id="System" title={'System'}
                           to={'/settings/general'}>
                            <span>Open period</span>
                        </Link>
                    </li>
                
            </div>
        );
    }
}

