import React, {Component} from 'react';
import {connect} from 'react-redux';
import { logoutUser } from '../../../actions/authActions';
import { clearCurrentProfile } from '../../../actions/profileActions';
import Grid from '@material-ui/core/Grid';
import { initializeSocket, socketMessage } from '../../../reducers/socket.js';
import { sendNotification, getCurrentNotifications, changeStatusNotification } from '../../../actions/notificationActions'
import {getCurrentProfile} from '../../../actions/profileActions'
import moment from 'moment'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import NotificationForm from './NotificationForm'
import Truncate from 'react-truncate';

class Header extends Component {

    constructor(props) {
        super(props);
         this.state = {
            open: false,
            notification: {},
            currentNotifications: []
        }
    }

   componentWillMount() {
    this.props.initializeSocket();
    this.props.getCurrentNotifications();
    this.props.getCurrentProfile()

    this.interval = setInterval(() => {
     this.props.getCurrentNotifications();
    }, 600000);
  }
   componentWillReceiveProps(nextProps) {
       if(nextProps.notification && nextProps.notification.currentNotifications){
           this.setState({
          currentNotifications: nextProps.notification.currentNotifications
      })
       }
   }

  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
    this.props.clearCurrentProfile();
  }

 /* onClick = (e) => {
      var dateTime = new Date();
      dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
      this.props.sendNotification({authorId: this.props.profile.profile.id, title: 'jay', text: 'test2', date: dateTime}, this.props.socket)
  }*/

  openNotificationForm = (notificationObj) => {
      this.setState({
          open: true,
          notification: typeof notificationObj !== 'undefined' ? notificationObj : {}
      })
  }

  closeNotificationForm = () => {
     this.setState({
          open: false,
          notification: {}
      })
  }

   handleOnSubmitNotification = (values) => {
       values.authorId = this.props.profile.profile.id;

     if(typeof values.assignees !== 'undefined' && values.assignees.length > 0){
        let assigneesIds = values.assignees.map(assignee => {
             return assignee.value;
         })
         values.assignees = assigneesIds;
     }

       this.props.sendNotification(values, this.props.socket)
        this.setState({
          open: false,
          notification: {}
      })
   }

   handleReadStatus = (data, status) => {

       this.props.changeStatusNotification(data, status, this.props.socket)
   }

   render() {
       const { socket, notification: {currentNotifications, countNotifications, admins}} = this.props;

return (
<div className="containment">
    <header className="header fixed-top clearfix" data-firstcolor="" data-secondcolor="">
    <Grid container direction="row" spacing={0}>
        <Grid item lg={2} md={2} xs={2} className="brand" style={{ width: '182.5px'}}>
            <a href="/internal" className="logo">
                <img src="/images/org/91ba9b.png?v1.0"  alt="sphs" height="55px" style={{margin: 5}}/>
               </a> <b>SPHS</b>
        </Grid>
        <Grid item lg={5} md={5} xs={5} className="makeMeDroppable no-gutter ui-droppable">
        </Grid>
        <Grid item lg={5} md={5} xs={11}  className="top-nav hr-top-nav">
            <ul className="nav pull-right top-menu">
            <li className="dropdown">
                    <a data-toggle="dropdown" className="dropdown-toggle" href="#" title="Profile">
                        <img src="/images/org/91ba9b.png?v1.0" height="33px" alt="User Logo"/>
                      </a>
                    <ul className="dropdown-menu extended logout">
                        <div className="arrow-up border"></div>
                        <div className="arrow-up"></div>
                        <span className="username">&nbsp; SPHS Admin&nbsp;</span>
                        <li>
                            <a href="/contribute/profile">Account</a>
                        </li>
                        <li><a href="/admin/users/active"> Users</a></li><hr/>
                        <li><a href="#" onClick={this.onLogoutClick} > Sign out</a></li><hr/>
                        {/*<span className="username">&nbsp; SPHS&nbsp;</span>
                        <li></li>
                        <li><a href="/settings/general"> Settings</a></li>
                        <li><a href="/admin/users/active"> Users</a></li><hr/>*/}
                    </ul>
                </li>
            </ul>

            <div className="icon dropdown dropdown-notification-menu">
                <a href="javascript(0)" className="dropdown-toggle" title="Notifications" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    <i className="ion-android-notifications-none"></i><span id="notification_number">{countNotifications}</span>
                </a>
                <ul className="dropdown-menu notification_menu" aria-labelledby="dropdownMenu1">
                  <div className="arrow-help border"></div>
                    <div className="arrow-help"></div>
                    <div className="notification-header">
                      <span className="username">Notifications &nbsp;
                         <span onClick={() => this.openNotificationForm()} style={{ cursor: "pointer"}}>
                          <i className="fas fa-plus-circle" ></i>
                        </span>
                      </span>
                    <span className="clear-all pull-right">
                      <a href="/admin/notifications">See all</a>
                    </span>
                    </div>
                      <div className="notification_array">
                        {this.state.currentNotifications.length > 0 ?
                          <span>
                            {this.state.currentNotifications.map((notificationObj, i) => (
                              <li className="notif" key={i}>
                              <p key={i}>
                              {notificationObj.status == 2 ?
                                <i className="ion-android-bulb unread"></i>
                                :
                                null
                                  }
                                  <span className="notif-edit">
                                  {/*notificationObj.status == 2 ?
                                    <i  className="ion-eye-disabled" title="Mark as read" onClick={() => this.handleReadStatus(notificationObj, 1)}></i>
                                    :
                                    <i  className="ion-eye" title="Mark as unread" onClick={() => this.handleReadStatus(notificationObj, 2)}></i>
                                  */}

                                  <i className="ion-eye" id="notification-edit" title="View" onClick={() => this.openNotificationForm(notificationObj)}></i>
                                  </span>
                                  <Truncate lines={3} ellipsis={'...'} >
                                 <span className="text-left notif-desc" dangerouslySetInnerHTML={{__html: notificationObj.content}}></span>
                                     </Truncate>
                            </p>
                    <p className="notif_date">{moment(notificationObj.date).format("DD-MM-YYYY")}</p>
                    </li>
                    ))}
                    </span>
                    :
                        <li className="notif text-center"><strong>No notifications</strong></li>
                    }
                    </div>
                </ul>
            </div>
      <Dialog onClose={this.closeNotificationForm} aria-labelledby="simple-dialog-title" open={this.state.open} maxWidth="lg" fullWidth>
        <DialogTitle id="simple-dialog-title">Notification <span className="pull-right pointer" onClick={this.closeNotificationForm}><i className="far fa-times-circle"></i></span></DialogTitle>
        <NotificationForm onSubmit={this.handleOnSubmitNotification} admins={admins} initialValues={this.state.notification}/>
      </Dialog>
            <div>
                <div id="notification">
                    <div id="begin">
                        <span className="text-right">
                            <a className="notif-btn-add btn-small" href="#">
                                <i className="ion-android-add"></i>
                            </a>
                        </span>
                    </div>
                </div>

            </div>
            <div className="icon"><a  href={'/admin/collaborations/calendar'} title="Calendar" style={{ background: 'none', border: 'none'}}>
                    <i className="ion-ios-calendar-outline"></i></a>
            </div>
            <div className="icon global-search"><i id="magnifier" className="ion-ios-search first" title="Search"></i>
            </div>
        </Grid>
      </Grid>
    </header>
</div>
);
}
}

const mapStateToProps = state => ({
        socket: state.socket,
        notification: state.notification,
        profile: state.profile
});

const mapDispatchToProps = dispatch => ({
    initializeSocket: () => dispatch(initializeSocket()),
    logoutUser: () => dispatch(logoutUser()),
    clearCurrentProfile: () => dispatch(clearCurrentProfile()),
    sendNotification: (data, socket) => dispatch(sendNotification(data, socket)),
    getCurrentNotifications: () => dispatch(getCurrentNotifications()),
    getCurrentProfile: () => dispatch(getCurrentProfile()),
    changeStatusNotification: (data, status, socket) => dispatch(changeStatusNotification(data, status, socket))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
