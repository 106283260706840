import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import { Editor } from "@tinymce/tinymce-react";
import DropZoneFields from "../file/DropZoneFields";
import {
  renderTextField,
  renderSelectField,
  renderTmcField,
  renderCheckbox
} from "../common/MaterialUiForm";
import IntegrationReactSelect from "../common/IntegrationReactSelect";
import { withStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import { getNewThreadFormData, getThread} from '../../actions/forumActions'
import { withRouter } from 'react-router-dom'
import ImagePreview from "../file/ImagePreview";



const styles = {
  inputFields: {
    height: 50,
    width: "100%"
  },
  inputLabels: {
    fontSize: 14,
    paddingTop: 15,
    paddingRight: 5,
    paddingBottom: 10,
    textTransform: 'uppercase'
  },
  inputColumns: {
    padding: "10px 30px"
  },
  labelsColumns: {
    textAlign: "right",
    paddingRight: 5
  },
  selectButtons: {
    margin: 8
  },
  input: {
    height: "auto !important"
  },
  label: {
      fontSize: 14,
      textTransform: 'uppercase'
  }
};

const required = value => {
    return value ? undefined : 'This value should not be blank.';
}

const validate = values => {
  const errors = {};
  const requiredFields = ["subject", "message"];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = "This value should not be blank.";
    }
  });

  return errors;
};


class ThreadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: []
    };
  }

  componentDidMount() {

      if(this.props.threadId){
       this.props.getThread(this.props.threadId, this.props.type, this.props.history)
   }
  this.props.getNewThreadFormData()

  }

  componentWillReceiveProps(nextProps) {
       if(nextProps.threads.thread && nextProps.threads.thread.thread){
            this.setState({
               files: nextProps.threads.thread.thread.attachments
            })
       }
    }

    handleCancel = (e) => {
        this.props.cancelEdit()
        this.resetForm()
    }

  handleOnDrop = (newImageFile, onChange) => {
    if (newImageFile.length <= 0) {
      toast.error("The file must be image under 2MB!");
    } else {
      toast.success("File uploaded successfully");

      const imageFile = {
        file: newImageFile[0],
        name: newImageFile[0].name,
        preview: URL.createObjectURL(newImageFile[0]),
        size: newImageFile[0].size,
        type: newImageFile[0].type
      };

      let files = this.state.files;
      files.push(imageFile)

      this.setState({ files: files }, () => onChange(files));
    }
  };

  handleOnDelete = (e, onChange) => {
      e.preventDefault()

     let files = this.state.files;
     files.map((fileObj, key) => {
        if(fileObj.preview === e.target.value){
            files.splice(key, 1)
        }
      })

      this.setState({ files: files }, () => onChange(files));

  }

  handleChange = (files) => {

  }

  resetForm = () => this.setState({ files: [] }, () => this.props.reset());


  render() {
     const { handleSubmit, pristine, submitting, classes, threads } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Grid container spacing={5} >
          <Grid item xs={12} sm={6} style={styles.inputColumns}>
            <Field
              name="subject"
              component={renderTextField}
              label="Discussion topic *"
              variant="outlined"
              inputProps={{
                style: {
                 fontSize: 14
                }
              }}
            />
            {threads.formThread && threads.formThread.contributions &&

              <Field
              name="contribution"
              component={IntegrationReactSelect}
              options={threads.formThread.contributions}
              clearable={true}
              ismulti={false}
              label="Contribution (Optional)"
              form="MaterialUiForm"
            />

            }
            <Grid container justify="flex-start" alignItems="center" >
              <Grid item md={6} sm={6} style={{ padding: 10, textAlign: 'left' }} >
            <InputLabel style={styles.inputLabels} htmlFor="contribution">
                  {"Attachments (up to 2MB)"}
                </InputLabel>
              <Field
                    name="attachments"
                    component={DropZoneFields}
                    type="file"
                    files={this.state.files}
                    value={this.state.files}
                    multiple={true}
                    handleOnDrop={this.handleOnDrop}
                    handleOnDelete={this.handleOnDelete}
                    onChange={files => this.handleChange(this.state.files)}
                    preview={false}
                  />

                   </Grid>
                   <Grid item md={12} style={{ padding: 10, textAlign: 'left' }} >
                {this.props.type==='member' &&
                <Field
                  name="protectAttachments"
                  component={renderCheckbox}
                  label="Confidential"
                  className={{label: classes.label}}
                />
                }
              </Grid>
                    </Grid>
          </Grid>

          <Grid item xs={12} sm={6} style={styles.inputColumns}>
           {threads.formThread && threads.formThread.members && this.props.type==='member' &&
            <Field
              name="members"
              component={IntegrationReactSelect}
              options={threads.formThread.members}
              clearable={true}
              ismulti={true}
              label="Visible to *"
              form="MaterialUiForm"
              validate={[required]}
             />
            }
          </Grid>


          <Grid item xs={12} sm={10} md={10} style={styles.inputColumns}>
            <Field
              name="message"
              component={renderTmcField}
              label="Message *"
              variant="outlined"
              field={{id: 'message'}}
            />
          </Grid>
        <Grid item xs={12} sm={12} className="action-buttons">

          <button type="submit" className="btn btn-primary btn-undp" disabled={submitting} >
            POST
          </button>
      {this.props.threadId &&
          <button type="button" className="btn btn-default" onClick={this.props.cancelEdit} >
              Cancel
            </button>
                }
          <button type="button" className="btn btn-default" disabled={pristine || submitting} onClick={this.resetForm}>
            Clear Form
          </button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

ThreadForm.propTypes = {
  getNewThreadFormData: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  threads: PropTypes.object.isRequired,
  getThread: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  threads: state.threads,
  initialValues: state.threads.thread ? state.threads.thread.thread : null
});

ThreadForm = withStyles(styles)(ThreadForm);

export default connect(mapStateToProps, { getNewThreadFormData, getThread })(reduxForm({ form: "ThreadForm", validate })(withRouter(ThreadForm)));
