import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import logoSide from '../../img/logo-side-menu.png';
import Navbar from '../layout/Navbar';
import { withRouter } from 'react-router-dom';
import { getCurrentProfile } from '../../actions/profileActions';
import ProfileActions from '../dashboard/ProfileActions';
import Spinner from '../common/Spinner';
import ContributeRegister from '../forms/ContributeRegister'
import { getRegisterData } from '../../actions/authActions';
import { withStyles } from '@material-ui/core/styles'
import { updateProfile } from '../../actions/profileActions'
import { toast } from 'react-toastify'
import {getProfileData} from '../../actions/accountActions'


const styles = theme => ({
  root: {
    flexGrow: 1,
  }
});


class ContributeProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        this.props.getCurrentProfile();

        this.props.getRegisterData()

    }

    componentWillReceiveProps(nextProps) {
 if(nextProps.auth.registerErrors && nextProps.auth.registerErrors[0] !== 'undefined' && nextProps.auth.registerErrors[0]){
         if(nextProps.auth.registerErrors[0]["errors"]){
             this.setState({
                 errors: nextProps.auth.registerErrors[0]["errors"]
             })
             let errors = nextProps.auth.registerErrors[0]["errors"];
             if(errors.length > 0){
                 errors.map(err => (
                         toast.error(err.errors[0], {autoClose: 10000})
                            ))
             }
         }
      }
    }

      submit = values => {

     var body = new FormData();
    Object.keys(values).forEach(( key ) => {

        if(key === 'avatar'){

        if(typeof values.avatar === 'number' ){
            body.append('avatar', values.avatar)
        }else{
           body.append('avatar', values.avatar[0].file)
        }


        }else if(key === 'organizationType'){
           let orgArray = []
           if(values[key].length > 0){
                   values[key].map(obj => (
              orgArray.push(obj.value)
  ))
           }
        body.append(key, orgArray);
        }else if(key === 'organization'){
        if(values[key]['label']){
            body.append(key,values[key]['label'])
        }
        }else{
      body.append(key, values[ key ]);
  }
    });

  this.props.updateProfile(body, this.props.history)
  }

   getProfileData = (e) => {
         e.preventDefault();
     if(this.props.profile && this.props.profile.profile.accountId){
         this.props.getProfileData(this.props.profile.profile.accountId);
     }
    }


    render() {

    const { profile, loading } = this.props.profile;
    let dashboardContent;
    if (profile === null || loading) {
        dashboardContent = <Spinner />;
    }else{
        dashboardContent = <ProfileActions profileItem={profile}/>
    }


        return (
          <div className="container-fluid" >
            <div className="page-content slide page-animation">
            {dashboardContent}
             <div className="contribute-register">
            <Grid container direction="row" style={{padding: "30px 0 0 0"}}>
             <Grid md={1} lg={1} item></Grid>
              <Grid md={8} lg={8} xs={11} item className="p-lr15">
                <h4 style={{marginBottom: 20}}>Download a file with all available personal information by the time of the request.</h4>
              </Grid>
            </Grid>
            <Grid container direction="row">
               <Grid md={1} lg={1} item></Grid>
              <Grid md={8} lg={8} xs={11} item className="p-lr15">
                <a className="blue-button" href="#"  onClick={this.getProfileData} >Get data</a>
              </Grid>
             </Grid>
              <div className="clearfix"></div>
                <div>

                  <div className="registerContibute">
                  {profile &&
                      <ContributeRegister onSubmit={this.submit} user={profile}/>
                   }
                  </div>

                </div>
              </div>
            </div>
         <Navbar/>
        </div>
        );
    }
}

ContributeProfile.propTypes = {
    getCurrentProfile: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    getRegisterData: PropTypes.func.isRequired,
    updateProfile: PropTypes.func.isRequired,
    getProfileData:  PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    profile: state.profile,
    auth: state.auth
});

ContributeProfile = withStyles(styles)(ContributeProfile)

export default connect(mapStateToProps, { getCurrentProfile, getRegisterData, updateProfile, getProfileData })(withRouter(ContributeProfile));
