import client from './client';
import { toast } from 'react-toastify'
import {config} from '../config'
import showAToast from '../helpers/showAToast'
import saveAs from 'file-saver'

import {
    GRID_PROPERTIES_LOADED,
    GRID_DATA_LOADED,
    GRID_QUICK_FILTER_CHANGE,
    GRID_DATES_CHANGE,
    GRID_ROWS_SELECTED,
    GRID_ROWS_SELECTED_CLEAR,
    GRID_HEADER_OPTION_SELECTED,
    GRID_HEADER_OPTION_LABEL_CHANGED,
    GRID_BULK_OPEN,
    GRID_BULK_ACTION_OPEN,
    GRID_COLUMNS_SHOW,
    GRID_COLUMNS_CHECK,
    GRID_COLUMNS_UPDATE,
    GRID_CONFIRM_DIALOG_SHOW,
    POPUP_MESSAGE_SHOW,
    POPUP_MESSAGE_HIDE,
    GRID_ADDITIONAL_DATA,
    GET_PDF_DATA,
    GET_EXCEL_DATA,
    GET_REPORT_DESCRIPTION
} from "../actions/types";

var gridApi, gridColumnApi;
var waitingForData = true;

export const onGridMount = (route) => dispatch => {
        dispatch({
            type: GRID_ROWS_SELECTED,
            payload: {rows: 0}
        });
        
        return new Promise((resolve, reject) => {
            client
                .get(route)               
                .then(response => {
                    
                        if (response.data.columns) {
                            dispatch({
                                type: GRID_PROPERTIES_LOADED,
                                payload: {
                                    columns: response.data.columns,
                                    visibleColumns: response.data.visibleColumns,
                                    bulkActions: response.data.bulkActions,
                                    header: response.data.header,
                                    options: response.data.options,
                                    pinnedRows: response.pinnedRows ? response.pinnedRows : null
                                }
                            });
                        }
                         resolve();
                    })
                .catch(err => {       
                    console.log(err)       
                });    
                }); 
       
}

export const onGridFetchData = (route) => dispatch => {
 return new Promise((resolve, reject) => {
            client
                .get(route)
                .then(response => {
                 
           if(response.data.pinnedRows){
               dispatch({
                            type: GRID_DATA_LOADED,
                            payload: {
                                data: response.data.data ? response.data.data : response.data,
                                pinnedRows: response.data.pinnedRows ? [response.data.pinnedRows] : null
                            }
                        });
           }else{
                        dispatch({
                            type: GRID_DATA_LOADED,
                            payload: {
                                data: response.data ? response.data : response,
                                pinnedRows: response.pinnedRows ? response.pinnedRows : null
                            }
                        });
                    }
                        if(waitingForData && gridApi) {
                            gridApi.hideOverlay();
                        }
                        waitingForData = false;
                        resolve();
                    }
                )
                .catch(err => {       
                    console.log('err')     
                });  
         })
}

export const onGridEmptyData = () => dispatch => {      
            dispatch({
                type: GRID_DATA_LOADED,
                payload: {data: []}
            });
       
}



export const onGridApiLoad = (grApi, gridColApi) => dispatch => {   
    return new Promise((resolve, reject) => {
            gridApi = grApi;
            gridColumnApi = gridColApi;
            if(waitingForData) {
                gridApi.showLoadingOverlay();
            }        
       resolve();
        });
}

export const onQuickFilterChange = (value) => dispatch => {    
            dispatch({
                type: GRID_QUICK_FILTER_CHANGE,
                payload: {value}
            });
           
        
}

export const onDatesChange = (startDate, endDate, route) => dispatch => {
    
        dispatch({
            type: GRID_DATES_CHANGE,
            payload: {startDate, endDate}
        });
      //  return dispatch(onGridMount(route + '?onlyData=1'));
    
}



export const onRowSelection = (clear = false) => dispatch => {    
            if(clear) {
                gridApi.deselectAll();
            }
            dispatch({
                type: GRID_ROWS_SELECTED, 
                payload: clear ? 0 : {rows: gridApi.getSelectedRows().length}
            });
       
}

export const onHeaderOptionSelected = (option) => dispatch => {
         return new Promise((resolve, reject) => {
            gridApi.setFilterModel(null);
           
            dispatch(
                {
                    type: GRID_HEADER_OPTION_SELECTED,
                    payload: {option, gridApi}
                });

            gridApi.onFilterChanged();

            dispatch(
                {
                    type: GRID_HEADER_OPTION_LABEL_CHANGED,
                    payload: {option}
                });
            
        resolve();
        });
}

export const onOpenBulk = (bulk) => dispatch => {
    
            dispatch(
                {
                    type: GRID_BULK_OPEN,
                    payload: {bulk}
                });
         
        
}


export const onOpenBulkActionList = (bulk, action) => dispatch => {
    
            dispatch(
                {
                    type: GRID_BULK_ACTION_OPEN,
                    payload: {bulk, action}
                });
            
}

export const onBulkActionClick = (action, bulk, data, filter=false, startDate=false, endDate=false, quickFilter=false, sortedCols=false)  => dispatch => {
      dispatch({
            type: GRID_BULK_OPEN,
            payload: {bulk}
        });
        let selectedRows = getSelectedRows();
        return new Promise((resolve, reject) => {           
            if ('confirm' in action){ 
                resolve(dispatch({type: GRID_CONFIRM_DIALOG_SHOW, payload: {action}}));
            }else if('linkRedirect' in action){
              window.location = action.link+'?ids='+selectedRows
            }else{ 
                resolve(handleBulkRowsAction({action}, {data}, {filter}, {startDate}, {endDate}, {quickFilter}, {sortedCols}));
            }
        })      
    
}

export const onConfirmActionTrigger = (action) => dispatch => {   
            return new Promise((resolve, reject) => {
            dispatch({type: GRID_CONFIRM_DIALOG_SHOW});
            if ('button' in action) resolve(handleCellRowAction(action)); // action is triggered by row button
            else resolve(handleBulkRowsAction(action)); // action is triggered by bulk action
        })
       
}

function handleBulkRowsAction(payload, data=false, filter=false, startDate=false, endDate=false, quickFilter=false, sortedCols=false) {
    const action = payload.action;
    let selectedRows = getSelectedRows();
 return new Promise((resolve, reject) => { 
   
    client
            .post(action.route, {
                'body': selectedRows,
                'filterOptions': filter,
                'startDate': startDate,
                'endDate': endDate,
                'quickFilter': quickFilter,
                'sortedCols': sortedCols['sortedCols']
            })
            .then(response => {
                return response.data;
            })
            .then(response => {
                //saveAs(response, "test.pdf", {type: 'application/pdf;charset=utf8'});
                // return;
                if (response.success) {
                    
                    toast.success(response.success, {autoClose: 10000})
                    if (response.download) {
                        if (response.type == 'application/pdf') {
                            var content = response.content;
                            var binary = atob(content.replace(/\s/g, ''));
                            var len = binary.length;
                            var buffer = new ArrayBuffer(len);
                            var view = new Uint8Array(buffer);
                            for (var i = 0; i < len; i++) {
                                view[i] = binary.charCodeAt(i);
                            }

                            let blob = new Blob([view], {type: response.type + ";charset=utf-8"});
                           // saveAs(blob, response.name, {type: 'application/pdf;charset=utf-8'});
                        }
                        else {
                            let blob = new File([response.content], response.name, {type: response.type + ";charset=utf-8"});
                           // saveAs(blob);
                        }
                    }

                        if ('update' in action) {
                        action.update.forEach((field, key) => {
                            let itemsToUpdate = [];
                            gridApi.getSelectedRows().map(rowNode => {
                                if ('resolve_delete' in action) {
                                    if(rowNode[field.field] === "0"){
                                        rowNode[field.field] = "1"
                                    }else{
                                        rowNode[field.field] = "0"
                                    }
                                }else{
                                    rowNode[field.field] = field.value;
                                }
                                itemsToUpdate.push(rowNode);
                            });
                            console.log(itemsToUpdate)
                            gridApi.updateRowData({update: itemsToUpdate});
                            gridApi.deselectAll()
                        });
                    }
                    else if ('remove' in action) {
                        let rowsToRemove = [];
                        gridApi.getSelectedRows().map(rowNode => {
                            rowsToRemove.push(rowNode);
                        });
                        gridApi.updateRowData({remove: rowsToRemove});
                    }
                     return response;
                }
                if (response.redirectUrl) {
                    window.location = response.redirectUrl;
                }
                resolve(response);
                
            })
            .catch(err => {     
      if(typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined' && typeof err.response.data.error !== 'undefined' ){
          toast.error(err.response.data.error, {autoClose: 10000})
      }else{
            toast.error('Error!', {autoClose: 10000})
        }
        });
        })
    
}


function getSelectedRows() {
    let selectedRows = gridApi.getSelectedRows();

    let selectedRowsString = "";
    selectedRows.forEach(function (selectedRow, index) {
        if (index !== 0) {
            selectedRowsString += ",";
        }
        selectedRowsString += selectedRow["id"];
    });

    return selectedRowsString;
}

export const onClickCalculateButtonClick = (route, filter=false, startDate=false, endDate=false, quickFilter=false, sortedCols=false, visibleColumns) => dispatch => {   
    client
            .post(route, {
                    'filterOptions': filter,
                    'startDate': startDate,
                    'endDate': endDate,
                    'quickFilter': quickFilter,
                    'sortedCols': sortedCols['sortedCols'],
                    'visibleColumns': visibleColumns
                })
                .then(response => {
                        dispatch({
                            type: GRID_DATA_LOADED,
                            payload: {
                                data: response.data ? response.data : response,
                                pinnedRows: response.pinnedRows ? response.pinnedRows : null
                            }
                        });
                        if (waitingForData && gridApi) {
                            gridApi.hideOverlay();
                        }
                        waitingForData = false;
                       
                    }
                );
        
}

export const onShowColumns = () => dispatch => {
    
            dispatch({type: GRID_COLUMNS_SHOW});
           
}

export const onCheckColumn = (key, childKey) => dispatch => {
    
            dispatch({
                type: GRID_COLUMNS_CHECK,
                payload: {key, childKey, gridColumnApi}
            });
            dispatch({type: GRID_COLUMNS_UPDATE, payload: {gridColumnApi}});
            
}


export const onColumnUpdate = (route, cols) => dispatch => {
    client
            .post(route,{'columns': cols})
                .then(response => {
                        console.log(response);
                    }
                );
       
}

export const onCellActionTrigger = (button, node, route) => dispatch => {
   
                if ('confirm' in button){
                    dispatch({
                    type: GRID_CONFIRM_DIALOG_SHOW,
                    payload: {button, node, route}
                })
            }else{
                handleCellRowAction({button, node, route})
        }
           
}

function handleCellRowAction(payload) {
    let button = payload.button;
    let node = payload.node;
    let route = payload.route;
   
    return new Promise((resolve, reject) => {
        client
            .get(route)
         //   .then(response => {
           //     return response.data;
           // })
            .then(response => {
                
                if (response.data.success) {
                    var toastId = response.data.success.replace(/ /g,'');

                     showAToast(response.data.success, toastId.slice(0, 6), 'success')       
                    if ('update' in button) {                     
                        if(typeof button.update === 'object' && button.update.field){
                            node.data[button.update.field] = button.update.value;       
                        }else{
                        button.update.forEach((field, key) => {
                            node.data[field.field] = field.value;
                        });
                    }
                   
                        gridApi.updateRowData({update: [node]});
                    }
                    else if ('remove' in button) {
                        let rowsToRemove = [];
                        gridApi.updateRowData({remove: [node.data]});
                    }
                }
                if(response.data.url){
                    window.location=response.data.url;
                }
                resolve(response);
            })
            .catch(err => {               
                if (err.response && err.response.data && err.response.data.error) {
                     showAToast(err.response.data.error, 'errorcellRow', 'error')                   
                }else{
                     showAToast('It is impossible to perform this action!', 'errorcellRow1', 'error')                   
                }
        })
        })
}

// START POPUP MESSAGE
export const onShowMessage = (text, isError) => dispatch => { 
            dispatch({
                type: POPUP_MESSAGE_SHOW, 
                payload: {text, isError}
            });        
}

export const onHideMessage = () => dispatch => { 
            dispatch({type: POPUP_MESSAGE_HIDE});        
}

function convertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line != '') line += ','

            line += array[i][index];
        }

        str += line + '\r\n';
    }

    return str;
}
export const exportButton = (name = null, addionalData = null, title) => dispatch => { 
    var data = [];
   
    if(title){
        data.push({regionName: title})
        data.push({regionName: "", value: ""})
    }
    if(addionalData){
        data.push({regionName: 'UN health procurement per location', value: 'Total health procurement volume (USD)'})
        if(addionalData.data.length > 0){
            addionalData.data.map(row => {
                var regionName = row.regionName ? row.regionName : '-';
                var value =  row.value ? row.value : '-';       
                data.push({regionName: regionName, value:  '"'+value+'"'})
            })
        }
        data.push({regionName: addionalData.pinnedRows.regionName, value: '"'+addionalData.pinnedRows.value+'"'})
        data.push({regionName: "", value: ""})
        
    }
    var csv = convertToCSV(data);
    var  fileName = name ? name : "exportData.xls"
  
    var jsonObject = gridApi.getDataAsCsv({});
  
    var blob = new Blob([csv + jsonObject], { type: 'application/excel;charset=utf-8;' });
    saveAs(blob, fileName, {type: 'application/excel;charset=utf-8;'});
    
  /*   client
           .get(route, {responseType: 'blob'})
           .then(res => {  
               console.log(res.data)
       var fileName = name ? name : 'exportData.csv';
               var blob = new Blob([res.data], {type: res.headers['content-type']});
                saveAs(blob, fileName, {type: res.headers['content-type']});
               */
               
               
             //  var downloadUrl = URL.createObjectURL(blob);
             //  var a = document.createElement("a");
            //   a.href = downloadUrl;
             //  a.download = name ? name : 'exportData.csv';
            //   document.body.appendChild(a);
             //  a.click();
       /*    })
           .catch(err => {
               console.log(err)
               toast.error('Download error!', {autoClose: 10000})
           });*/

};

export const exportExcelButton = (route) => dispatch => {
    client
           .get(route)
           .then(response => {  
     let blob = new File([response.data.content], response.data.name, {type: response.data.type + ";charset=utf-8"});
     saveAs(blob);
     })
           .catch(err => {
               console.log(err)
               toast.error('Download error!', {autoClose: 10000})
           });
}

export const exportPdfButton = (route, name=null) => dispatch => {  
  client
           .get(route, {responseType: 'blob'})
           .then(res => {                  
               var blob = new Blob([res.data], {type: res.headers['content-type']});
               var downloadUrl = URL.createObjectURL(blob);
               var a = document.createElement("a");
               a.href = downloadUrl;
               a.download = name ? name : 'exportData.csv';
               document.body.appendChild(a);
               a.click();
           })
           .catch(err => {
               toast.error('Download error!', {autoClose: 10000})
           });
       }
       
       export const exportPdfDataButton = (route, name=null) => dispatch => {  
    client
           .get(route)
           .then(res => {                  
             // console.log(res.data)
        dispatch({
                        type: GET_PDF_DATA,
                        payload: res.data
                    })
           })
           .catch(err => {
               toast.error('Download error!', {autoClose: 10000})
           });   

       }

export const uploadButton = (route, form) => dispatch => {  
 
        client
           .post(route, form,  {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
           .then(res => {      
       gridApi.updateRowData({add: [res.data], addIndex: 0})
       toast.success('Upload success!', {autoClose: 10000})
           })
           .catch(err => {
               console.log('err')
               toast.error('Upload error - your file is too big or in wrong format.', {autoClose: 10000})
           });
};

export const fileRedirect = (route) => dispatch => { 
        client
           .get(route)
           .then(res => {  
               window.open(res.data);
           })
           .catch(err => {
               console.log(err)
               
           });
};

export const fileDownload = (route, name) => dispatch => {  
            client
                .get(route, {responseType: 'blob'})
                .then(res => {                   
                   var blob = new Blob([res.data], {type: res.headers['content-type']});
                    var downloadUrl = URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = downloadUrl;
                    a.download = name;
                    document.body.appendChild(a);
                    a.click();
                })
               .catch(err => {  
             if(err.response.data.type == 'application/json'){
                 toast.error('Someone else is editing this file!', {autoClose: 10000})
                }else{
                    toast.error('Download error!', {autoClose: 10000})
                }
              });
};

export const exportBulkAction = (route) => dispatch => {  
        client({
            method: 'post',
            url: route,
            data: {
                body: getSelectedRows()
            },
            responseType: 'blob'
          })
           .then(res => {                  
               var blob = new Blob([res.data], {type: res.headers['content-type']});
               var downloadUrl = URL.createObjectURL(blob);
               var a = document.createElement("a");
               a.href = downloadUrl;
               a.download = 'exportData.csv';
               document.body.appendChild(a);
               a.click();
           })
           .catch(err => {
               toast.error('Download error!', {autoClose: 10000})
           });
};


export const getAddionalData = (route = null) => dispatch => { 
    if(route){
            client
                .get(route)
                .then(res => {
                     dispatch({
                        type: GRID_ADDITIONAL_DATA,
                        payload: res.data
                    })
                })
               .catch(err => {

               });
   }else{
        dispatch({
                        type: GRID_ADDITIONAL_DATA,
                        payload: null
                    })
   }
};

export const setPdfData = (data) => dispatch => { 

              dispatch({
                        type: GET_PDF_DATA,
                        payload: data
                    })          
}

export const getDescriptionAdmin = (type) => dispatch => { 
        client
           .get('/api/admin/description/report/'+type)
           .then(res => {                  
              dispatch({
                        type: GET_REPORT_DESCRIPTION,
                        payload: res.data
                    })
           })
           .catch(err => {
              dispatch({
                        type: GET_REPORT_DESCRIPTION,
                        payload: null
                    })
           });
};

export const saveDescription = (route, value) => dispatch => { 
        client
           .post(route, value)
           .then(res => {                  
              dispatch({
                        type: GET_REPORT_DESCRIPTION,
                        payload: res.data
                    })
           })
           .catch(err => {
              dispatch({
                        type: GET_REPORT_DESCRIPTION,
                        payload: null
                    })
               
           });
};