import React, {Component} from 'react';
import {connect} from "react-redux";
import {Button, Dialog, DialogActions, DialogTitle, DialogContent} from "@material-ui/core";
import PropTypes from 'prop-types';

class ContributeModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
              open: false
        }
    }

    handleClickOpen = event => {
    event.preventDefault();
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({
        open: false
    });
  };

    render() {

    return (
    <span>
      <div className="margin-t-15">
        <a href="" onClick={this.handleClickOpen}><i>Why is it important to link your work to the Sustainable Development Goals (SDGs)?</i></a>
      </div>
        <Dialog open={this.state.open}
                onClose={this.handleClose}>
             <DialogContent style={{ textAlign: 'center', marginTop: '10px'}}>
                 By tagging SDGs (<a href="https://sustainabledevelopment.un.org/sdgs">https://sustainabledevelopment.un.org/sdgs</a>) that are relevant to your contribution, you are taking action to link the outcome of your work to the development objectives of the UN organizations. You will be able to track and share your projects in light of joint efforts to help safeguard ecosystems and biodiversity, reduce the generation of all forms of waste, enhance access to quality health-care services, improve water quality by reducing pollution, endeavour to decouple economic growth from environmental degradation, increase inclusion and equal opportunities, integrate climate change measures, and strengthen partnership and knowledge sharing.
                          </DialogContent>
                  <DialogActions>
                  <Button onClick={this.handleClose} color="primary" style={{fontSize: 14}} className="btn btn-secondary">
                    Close
                  </Button>
              </DialogActions>
        </Dialog>
    </span>
   );
   }
   }

export default connect()(ContributeModal);
