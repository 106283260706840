import React, {useState} from 'react';
import { PieChart, Pie, Sector, Cell, Legend, ResponsiveContainer } from 'recharts';
const data02 = [{name: 'A1', value: 100}]
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FFFF00', '#FF69B4', '#4169E1',  
    '#E9967A', '#FF6347', '#663399', '#F0E68C', '#FFF0F5', '#00FF00', '#0000CD', '#C71585',
'#800080', '#FF00FF', '#00FF00', '#008080', '#00FFFF', '#7FFFD4'];

const RADIAN = Math.PI / 180;                    
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload, value }) => {
 
  const radius =20 + innerRadius + (outerRadius - innerRadius);// innerRadius + (outerRadius - innerRadius) * 0.5;
  const x  = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy  + radius * Math.sin(-midAngle * RADIAN);

if(value !== 0){
  return (
     <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="hanging" >
    	{`${(percent * 100).toFixed(0)}%`} {payload.name}
    </text>
  );
}else{
    return <text></text>
}
};

function SimplePieChart(props){
    	return (
                <ResponsiveContainer width={'100%'} height={700}>
    	<PieChart width={'100%'} height={700} style={{border: '1px solid #ccc!important;'}}>
       <Pie data={data02}
       cx={'50%'} 
          cy={'50%'} 
        innerRadius={161} 
        outerRadius={163} 
        fill="#708090" 
        />
        <Pie    
        dataKey="value"
          data={props.data} 
          cx={'50%'} 
          cy={'50%'} 
          labelLine={false}
          label={renderCustomizedLabel}          
          fill="#8884d8"   
          isAnimationActive={false} 
          outerRadius={160}
          stroke={'fill'} 
          fill="#333"
        >
        {
        props.data.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} key={index}/>)
          }
        </Pie>
        <Legend
  payload={
    props.data.map(
      (item, i) => ({
        id: item.name,
        type: "circle",
        value: `${item.name} (${item.value}%)`,
        color: COLORS[i]
      })
    )
  }
/>
      </PieChart>
      </ResponsiveContainer>
    );
  

}
export default SimplePieChart;
 